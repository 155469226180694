// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smart-heading {
  color: #F7FFF7;
  font-family: "SF Pro Display";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 48px;
}

@media (max-width: 768px) {
  .smart-heading {
    font-size: 24px;
    margin-bottom: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/MiroComponents/SmartHeading/SmartHeading.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,6BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AACJ;;AAEA;EACI;IACI,eAAA;IACA,mBAAA;EACN;AACF","sourcesContent":[".smart-heading{\n    color: #F7FFF7;\n    font-family: \"SF Pro Display\";\n    font-size: 36px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n    margin-bottom: 48px;\n}\n\n@media (max-width: 768px){\n    .smart-heading{\n        font-size: 24px;\n        margin-bottom: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
