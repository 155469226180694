// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experiment-lists {
  display: flex;
  flex-direction: column;
  column-gap: 2%;
  row-gap: 20px;
}

.experiment-overview {
  padding: 20px 10px;
  display: none;
}
.experiment-overview.active {
  display: block;
}
.experiment-overview > h2 {
  color: #f6f6f6;
  padding-bottom: 15px;
  font-weight: 400;
  font-size: 23px;
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 0px;
}
.experiment-overview > p {
  color: #f6f6f6;
  padding-bottom: 15px;
  font-weight: 400;
  font-size: 18px;
  font-weight: 400;
  line-height: 110%;
  margin-bottom: 0px;
}
.experiment-overview .add__new__test__form-footer button {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewComponents/ExperimentsList/ExperimentsList.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,cAAA;EACA,aAAA;AACJ;;AACA;EACI,kBAAA;EACA,aAAA;AAEJ;AADI;EACI,cAAA;AAGR;AADI;EACI,cAAA;EACA,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAGR;AADI;EACI,cAAA;EACA,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAGR;AAAQ;EACI,iBAAA;AAEZ","sourcesContent":[".experiment-lists{\n    display: flex;\n    flex-direction: column;\n    column-gap: 2%;\n    row-gap: 20px;\n}\n.experiment-overview{\n    padding: 20px 10px;\n    display: none;\n    &.active{\n        display: block;\n    }\n    &>h2{\n        color: #f6f6f6;\n        padding-bottom: 15px;\n        font-weight: 400;\n        font-size: 23px;\n        font-weight: 500;\n        line-height: 110%;\n        margin-bottom: 0px;\n    }\n    &>p{\n        color: #f6f6f6;\n        padding-bottom: 15px;\n        font-weight: 400;\n        font-size: 18px;\n        font-weight: 400;\n        line-height: 110%;\n        margin-bottom: 0px;\n    }\n    .add__new__test__form-footer{\n        button{\n            margin-left: auto;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
