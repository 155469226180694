import { useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { VscArrowDown, VscArrowUp } from "react-icons/vsc";
import { SIGNIFICANCEDATA } from "../../Graphql/Queries";
import { useCSVDownloader } from "react-papaparse";
import { NormalDist } from "../../Libs/Utilities";
import MyLoader from "../MiroComponents/PreLoader";
import ReactTooltip from "react-tooltip";

export const SignicanceCalculator = ({
  viewId,
  startDate,
  endDate,
  selectedSegments,
  selectedTest,
}) => {
  const [showSignificance, setShowSignificance] = useState(false);
  const [significanceData, setSignificanceData] = useState({});
  const { CSVDownloader } = useCSVDownloader();
  const [significancequery] = useLazyQuery(SIGNIFICANCEDATA);

  const data = useCallback(async () => {
    let metrics = ["ga:users", "ga:transactions", "ga:transactionRevenue"];
    let dimensions = ["ga:clientId"];
    let kameleoonFilters = [];
    // setLoader(true);
    let queryobj = {
      view_id: viewId,
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd"),
    };
    let finalFilters = "";
    if (
      selectedTest?.testtool === "Kameleoon" ||
      selectedTest?.testtool === "kameleoon"
    ) {
      kameleoonFilters =
        selectedTest?.kameleoontestdetails?.controlvariants?.map((itm) => {
          return `ga:dimension${selectedTest?.kameleoontestdetails?.dimension}=@${itm?.name}`;
        });
      if (selectedSegments?.length > 0) {
        finalFilters = `${kameleoonFilters.join(",")};${[
          ...selectedSegments?.slice()?.map((itm) => {
            return `${[itm.key]}${itm?.operator}${itm?.value?.value}`;
          }),
        ].join(";")}`;
      } else {
        finalFilters = `${kameleoonFilters.join(",")}`;
      }
      dimensions = dimensions.concat([
        `ga:dimension${selectedTest?.kameleoontestdetails?.dimension}`,
      ]);
    } else {
      finalFilters = [
        ...selectedSegments?.slice()?.map((itm) => {
          return `${[itm.key]}${itm?.operator}${itm?.value?.value}`;
        }),
      ].join(";");
      dimensions = dimensions.concat(["ga:experimentVariant"]);
    }
    queryobj["filters"] = finalFilters;
    queryobj["dimensions"] = dimensions;
    queryobj["metrics"] = metrics;
    queryobj["codebaseTestId"] = selectedTest?._id;
    try {
      setSignificanceData({
        ...significanceData,
        loading: true,
      });
      const { data, loading, error } = await significancequery({
        variables: {
          options: queryobj,
        },
      });
      setSignificanceData({
        response: data?.getSignificanceData?.response,
        loading,
        error,
      });
    } catch (error) {
      setSignificanceData({
        loading: false,
        error,
      });
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [showSignificance]);

  useEffect(() => {
    if (showSignificance) {
      data();
    }
  }, [showSignificance, data]);

  const pvalue = (value) => {
    return NormalDist(value).toFixed(2);
  };

  if (significanceData?.loading)
    return (
      <div className="visual-box-wrapper">
        <div className="visual-box-inner">
          <MyLoader />
        </div>
      </div>
    );
  if (significanceData?.error)
    return <div> {significanceData?.error?.message} </div>;

  const ShowHideSignificance = ({ significanceData }) => {
    return (
      <div className="time-line-variants d-flex w-100">
        <div className="time-line-variant-heading w-100">
          <div className="variant-performance-cards">
            <div className="single-performance-card">
              <h2 className="performance-card-heading">ARPU</h2>
              <div className="performance-card-numbers">
                {pvalue(Number(significanceData?.response?.ttestARPU))}
              </div>
            </div>
            <div className="single-performance-card">
              <h2 className="performance-card-heading">AOV</h2>
              <div className="performance-card-numbers">
                {pvalue(Number(significanceData?.response?.ttestAOV))}
              </div>
            </div>
          </div>
          <div className="downloadcsvbutton">
            <CSVDownloader
              type="nutton"
              filename={"filename"}
              bom={true}
              config={{
                delimiter: ",",
              }}
              data={significanceData?.response?.experiments}
            >
              Download
            </CSVDownloader>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="experiment-data-inner">
      <div className="experiment-heading-divider significance-heading">
        <div className="section-heading-data">
          <h1 className="section-heading">Significance Calculations</h1>
          <span className="section-heading-subtitle">(to-date)</span>
        </div>
      </div>
      <div className="visual-box-wrapper">
        <div className="visual-box-inner significance-content">
          <h2 className="significance-text">
            This might take longer loading time due to large data sets in Google
            Analytics
          </h2>
          <div className="showhidesignificance-cta">
            <button
              className="btn-as-link show-data-btn"
              onClick={(_) => setShowSignificance(!showSignificance)}
            >
              {!showSignificance ? (
                <>
                  Show Significance Data <VscArrowDown className="ms-2" />
                </>
              ) : (
                <>
                  Hide Significance Data <VscArrowUp className="ms-2" />
                </>
              )}
            </button>
          </div>
        </div>
        <div className="significance-cards">
          {showSignificance && (
            <ShowHideSignificance
              showSignificance={showSignificance}
              significanceData={significanceData}
            />
          )}
        </div>
      </div>
    </div>
  );
};
