import { useLazyQuery, useQuery } from "@apollo/client";
import { GETCLIENTSNAMEANDIDS, MYPROFILE } from "../Graphql/Queries";

// useMyProfile for getting profile
const returnClientOptions = (clients) => {
  return clients?.map((c) => {
    return {
      label: c?.name,
      value: JSON.stringify({
        _id: c?._id,
        gaproperties: c?.gaproperties,
        currency: c?.currency,
        logo: c?.logo,
      }),
    };
  });
};

export function useClients() {
  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
  } = useQuery(MYPROFILE, {
    fetchPolicy: "network-only",
  });
  const isclient = profileData?.role?.some((rl) => rl?.name === "CLIENT");

  const { data, loading, error } = useQuery(GETCLIENTSNAMEANDIDS);
  const clients = returnClientOptions(
    isclient ? profileData?.client : data?.clients?.clients
  );
  return {
    loading: profileLoading || loading,
    error: profileError || error,
    clients,
  };
}
