import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { VscCheck, VscClose, VscReport } from "react-icons/vsc";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import InputEmoji from "react-input-emoji";
import { TickGreen } from "../../../Shared/Icons";

import {
  CHECKREPORTEXISTS,
  CREATENOTIFICATION,
  CREATEREPORT,
  USERSBYCLIENT,
} from "../../../../Graphql/Mutations";

export function CreateReport({
  reportsDetails,
  experimentResults,
  selectedTest,
  client,
  selectedTestId,
  currency,
  from,
  to,
  selectedProperty,
  segmentNameForReport,
  setSelectedTest,
  setSelectedTestId,
}) {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [showModal, setShowModal] = useState(false);
  const [learnings, setLearnings] = useState([]);
  const [notificationText, setNotificationText] = useState("");
  const [segmentName, setSegmentName] = useState(segmentNameForReport ?? "");
  const [createreport] = useMutation(CREATEREPORT);
  const [createNotification] = useMutation(CREATENOTIFICATION);
  const [checkReportExists] = useMutation(CHECKREPORTEXISTS);
  const [usersByClient] = useMutation(USERSBYCLIENT);
  const [conclusion, setConclusion] = useState("");
  const [conclusionFactor, setConclusionFactor] = useState("");

  const btns = ["Winner", "Neutral", "No Uplift"];
  const conclusionfactor = ["Change in CR", "Change in AOV", "Change in RPU"];
  const AddLearnings = () => {
    return (
      <div className="fields-wrapper-group">
        <label>Add Learnings *</label>
        <div>
          <input id="learningText" placeholder="Add lernings from this test" />
          <button
            type="button"
            onClick={(_) => {
              const learningvalue =
                document.querySelector("#learningText").value;
              if (learningvalue.trim() !== "") {
                setLearnings((_learnings) => [..._learnings, learningvalue]);
                document.querySelector("#learningText").value = "";
              }
            }}
          >
            Add
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    return () => {
      setShowModal(false);
      setLearnings([]);
    };
  }, []);

  return (
    <div className="create-reports-wrapper">
      <div className="create-reports-inner">
        {!is_user && (
          <div className="create-report-cta">
            <button
              onClick={async (_) => {
                const { data } = await checkReportExists({
                  variables: {
                    test: selectedTestId,
                  },
                });
                if (!data?.reportExistsForTest) {
                  setShowModal(!showModal);
                } else {
                  toast.error("Report Already Exists for this test");
                }
              }}
            >
              <VscReport /> <span>Create Report</span>
            </button>
          </div>
        )}
      </div>
      {showModal && (
        <div className="create-report-modal">
          <div className="create-report-modal-inner">
            <div className="card">
              <button
                className="close-modal"
                onClick={(_) => {
                  setShowModal(!showModal);
                  setLearnings([]);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <div className="card-body">
                <h3> Save Report</h3>
                <form>
                  <div className="fields-wrapper">
                    <label>Add Segment Name *</label>
                    <div>
                      <input
                        id="segmentName"
                        className="field-control"
                        placeholder="Add segment name"
                        onChange={(e) => setSegmentName(e.currentTarget.value)}
                        defaultValue={segmentName ?? ""}
                      />
                    </div>
                  </div>
                  <AddLearnings />
                  {learnings.length > 0 && (
                    <div className="leanings-preview">
                      <ul>
                        {learnings.map((l, i) => (
                          <li key={i}>
                            {" "}
                            <TickGreen />{" "}
                            <span>
                              {l}{" "}
                              <VscClose
                                onClick={(_) => {
                                  const arr = [...learnings];
                                  arr.splice(i, 1);
                                  setLearnings(arr);
                                }}
                              />
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <div className="fields-wrapper success-creteria">
                    {/* {btns.map((btn, i) => (
                      <button
                        key={i}
                        onClick={(_) => {
                          setConclusion(btn);
                        }}
                        type="button"
                        className={btn.replaceAll(" ", "_").toLowerCase()}
                      >
                        {conclusion === btn && <VscCheck />}
                        {btn}
                      </button>
                    ))} */}

                    {btns.map((btn, i) => (
                      <div className="form-check" key={i}>
                        <input
                          type="radio"
                          name="conclusion-tips"
                          value={btn}
                          className="form-check-input"
                          onClick={(_) => {
                            setConclusion(btn);
                          }}
                        />
                        <label>
                          {conclusion === btn}
                          {btn}
                        </label>
                      </div>
                    ))}
                  </div>

                  {conclusion && (
                    <>
                      <p className="fw-bold text-white">
                        Select KPI for this test to be considered{" "}
                        <span className="text-decoration-underline">
                          {" "}
                          {conclusion}
                        </span>
                      </p>

                      <div className="d-flex align-items-center justify-content-between mb-3">
                        {conclusionfactor.map((btn, i) => (
                          <button
                            key={i}
                            onClick={(_) => {
                              setConclusionFactor(btn);
                            }}
                            type="button"
                            className={`btn btn-outline-dark conclusion-factor-btn ${
                              conclusionFactor === btn &&
                              `bg-primary text-white`
                            }`}
                          >
                            {btn}
                          </button>
                        ))}
                      </div>
                      {conclusionFactor && (
                        <InputEmoji
                          borderRadius={0}
                          borderColor="#dfe2e8"
                          value={notificationText}
                          onChange={setNotificationText}
                          cleanOnEnter
                          // onEnter={handleOnEnter}
                          placeholder="Type a message"
                        />
                      )}
                    </>
                  )}

                  <div className="fields-wrapper">
                    <label>Link to Report (optional)</label>
                    <div>
                      <input
                        id="reportLink"
                        className="field-control"
                        placeholder="Add external report link"
                        type="link"
                      />
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <button
                      type="button"
                      onClick={async (_) => {
                        if (
                          conclusion === "" ||
                          conclusionFactor === "" ||
                          segmentName.trim() === ""
                        ) {
                          toast.error("Please add all madatory fields");
                          return;
                        }
                        const reportLink =
                          document.querySelector("#reportLink");

                        let repdetails = {
                          experimentResults,
                          ["experiments"]: reportsDetails?.experiments,
                          ["conclusionFactor"]: conclusionFactor.toLowerCase(),
                          ["controlVariant"]: reportsDetails?.controlVariant,
                          currency,
                          ["uniqueUsersWithTransactions"]:
                            reportsDetails?.uniqueUsersWithTransactions,
                        };

                        let reportPayload = {
                          conclusion: conclusion.toLowerCase(),
                          learnings,
                          client,
                          test: selectedTestId,
                          gaTestId: selectedTest?.testid,
                          reportDetails: repdetails,
                          from,
                          to,
                          name: `${selectedTest?.name}^report`,
                          createdBy: myProfile?._id,
                          segmentName,
                          viewId: selectedProperty?.value,
                        };
                        if (reportLink.value?.trim() !== "") {
                          reportPayload["reportLink"] = reportLink.value;
                        }
                        try {
                          const { data } = await createreport({
                            variables: {
                              reportPayload,
                            },
                          });
                          const usersbyClient = await usersByClient({
                            variables: {
                              clientId: client,
                            },
                          });
                          await createNotification({
                            variables: {
                              payload: {
                                title: "New Report Created!",
                                body:
                                  notificationText !== ""
                                    ? notificationText
                                    : `Test is ${conclusion}.`,
                                users: usersbyClient?.data?.findClientUsers,
                                moduleData: JSON.stringify({
                                  reportId: data?.createReport?._id,
                                  testId: selectedTestId,
                                }),
                                createdBy: myProfile?._id,
                                module: "report",
                              },
                            },
                          });
                          setShowModal(false);
                          toast.success("Report saved.");
                          // setSelectedTest(null);
                          // setSelectedTestId(null);
                        } catch (error) {
                          toast.error(error?.message);
                        }
                      }}
                    >
                      Create Report
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
