import { useCallback, useEffect, useReducer, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import ExperimentsList from "../../NewComponents/ExperimentsList/ExperimentsList";
import TargetingModuleList from "../../NewComponents/TargetingModuleList/TargetingModuleList";
import useStore from "../../../Store";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import AbTestToolOnBoarding from "../../NewComponents/AbTestToolOnBoarding/AbTestToolOnBoarding";
import Input from "../../MiroComponents/Input/Input";
import { Button } from "../../MiroComponents/Button/Button";
import AbTestOverview from "../../NewComponents/AbTestOverview/AbTestOverview";
import { useLazyQuery } from "@apollo/client";
import {
  ALLABTESTS,
  CLIENTTARGETINGPAGES,
  GETAUDIENCES,
  GETENVIRONMENTS,
  GOALSPAGINATION,
} from "../../../Graphql/Queries";
import useAbTestStore from "../../../AbTestStore";
import MyLoader from "../../MiroComponents/PreLoader";

const defaultState = {
  onBoarding: false,
};
const reducerFunction = (state, action) => {
  switch (action.type) {
    case "SET_ONBOARDING":
      return { ...state, onBoarding: action.payload };
    default:
      return state;
  }
};

export const ABTestingDashboard = () => {
  const [activeTab, setActiveTab] = useState("experiments");
  const { module } = useParams();
  const selectedClient = useStore((state) => state.selectedClient);
  const [state, dispatch] = useReducer(reducerFunction, defaultState);
  const [page, setPage] = useState(1);
  const {
    insights,
    setTests,
    clearInsights,
    setGoals,
    setAudiences,
    setPages,
  } = useAbTestStore();
  const [getTestsQuery, { refetch }] = useLazyQuery(ALLABTESTS);
  const [getAudiencesQuery] = useLazyQuery(GETAUDIENCES);
  const [getPagesQuery] = useLazyQuery(CLIENTTARGETINGPAGES);
  const [getGoalsQuery] = useLazyQuery(GOALSPAGINATION);
  const [getEnvironmentsQuery] = useLazyQuery(GETENVIRONMENTS);
  const getAbTests = useCallback(async () => {
    if (!selectedClient) return;
    try {
      const { data } = await getTestsQuery({
        variables: {
          page,
          size: 10,
          testFilters: [
            {
              client: [selectedClient?.value?._id],
              name: "Test ZD",
              testtool: "Codebase",
            },
          ],
        },
      });
      setTests({
        ...data?.allAbTests,
        refetchTests: refetch,
      });
    } catch (error) {}
  }, [page, selectedClient, getTestsQuery]);

  const getGoalsAudiencesPages = useCallback(async () => {
    const { data: goalsData } = await getGoalsQuery({
      variables: {
        filters: [
          {
            client: selectedClient?.value?._id,
          },
        ],
      },
    });
    const { data: audiencesData } = await getAudiencesQuery({
      variables: {
        filters: [
          {
            client: selectedClient?.value?._id,
          },
        ],
      },
    });
    const { data: pagesData } = await getPagesQuery({
      variables: {
        filters: [
          {
            client: selectedClient?.value?._id,
          },
        ],
      },
    });
    const { data: environmentsData } = await getEnvironmentsQuery({
      variables: {
        filters: [
          {
            client: selectedClient?.value?._id,
          },
        ],
      },
    });
    setGoals(goalsData?.goalsPagination);
    setAudiences(audiencesData?.getAudiences);
    setPages(pagesData?.pages);
  }, [selectedClient]);

  useEffect(() => {
    getAbTests();
    getGoalsAudiencesPages();
  }, [getAbTests, getGoalsAudiencesPages]);

  if (!selectedClient) return <NoDataFound />;
  if (insights.loading) return <MyLoader />;

  return (
    <>
      <AbTestToolOnBoarding state={state} dispatch={dispatch} />
      <div className="default-border default-margin-bottom">
        <div className="default-padding ">
          <div className="d-flex justify-content-between">
            <h2 className="margin-bottom-auto">
              Dashboard {selectedClient?.label}
            </h2>
            <div>
              <Button className="smart-pill small active">
                Get Snippet Here
              </Button>
            </div>
          </div>
        </div>
        <div className="default-padding default-margin-bottom bg-2 big-padding-top big-padding-bottom">
          <form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              console.log("Check Integration");
              const { siteurl } = e.target.elements;
              console.log(siteurl.value);
              // checkURL(siteurl.value);
            }}
          >
            <div className="d-inline-flex full-width default-column-gap align-items-end">
              <Input
                label="Enter site url and get started to optimize your website."
                placeholder="Enter site url"
                name="siteurl"
                type="url"
                wrapperClass="full-width margin-bottom-auto"
                inputWrapperClass="full-width"
              />
              <Button
                style={{
                  whiteSpace: "nowrap",
                }}
                type="submit"
                text="Create Experiment"
                className="smart-button big active"
              />
            </div>
          </form>
        </div>

        <AbTestOverview insights={insights} />
      </div>

      {module === "experiments" && <ExperimentsList />}

      {/* <ExperimentsList
        client={selectedClient?.value?._id}
        setSearchParams={setSearchParams}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        params={_params}
      /> */}
      {/* {activeTab === "targeting" && (
        <TargetingModuleList
          client={selectedClient?.value?._id}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )} */}
    </>
  );
};
