// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.variants-list-inner {
  display: flex;
  align-items: center;
  column-gap: 1%;
  justify-content: space-between;
  margin-bottom: 5px;
}
.variants-list-inner > div.variant-ctas {
  display: inline-flex;
  column-gap: 4px;
}
.variants-list-inner > div.variant-options a {
  color: #f6f6f6;
  font-weight: 400;
  font-size: 18px;
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 0px;
}
.variants-list-inner > div.variant-traffic {
  max-width: 100px;
}
.variants-list-inner > div.variant-name {
  width: 100px;
}
.variants-list-inner > div h4 {
  color: #f6f6f6;
  font-weight: 400;
  font-size: 18px;
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 0px;
}
.variants-list-inner > div .fields-wrapper {
  margin: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  color: #fff;
}
.variants-list-inner > div .fields-wrapper input.field-control.darkinput {
  max-width: fit-content;
}

.experiment-box-footer button {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewComponents/ExperimentDetails/ExperimentDetails.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,8BAAA;EACA,kBAAA;AACJ;AAIQ;EACI,oBAAA;EACA,eAAA;AAFZ;AAMY;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAJhB;AAQQ;EACI,gBAAA;AANZ;AASQ;EACI,YAAA;AAPZ;AAUQ;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AARZ;AAWQ;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;AATZ;AAWY;EACI,sBAAA;AAThB;;AAgBI;EACI,iBAAA;AAbR","sourcesContent":[".variants-list-inner {\n    display: flex;\n    align-items: center;\n    column-gap: 1%;\n    justify-content: space-between;\n    margin-bottom: 5px;\n\n    &>div {\n\n        // flex: 1 1 0;\n        &.variant-ctas {\n            display: inline-flex;\n            column-gap: 4px;\n        }\n\n        &.variant-options {\n            a {\n                color: #f6f6f6;\n                font-weight: 400;\n                font-size: 18px;\n                font-weight: 500;\n                line-height: 110%;\n                margin-bottom: 0px;\n            }\n        }\n\n        &.variant-traffic {\n            max-width: 100px;\n        }\n\n        &.variant-name {\n            width: 100px;\n        }\n\n        h4 {\n            color: #f6f6f6;\n            font-weight: 400;\n            font-size: 18px;\n            font-weight: 500;\n            line-height: 110%;\n            margin-bottom: 0px;\n        }\n\n        .fields-wrapper {\n            margin: 0px 0px;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            column-gap: 4px;\n            color: #fff;\n\n            input.field-control.darkinput {\n                max-width: fit-content;\n            }\n        }\n    }\n}\n\n.experiment-box-footer {\n    button {\n        margin-left: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
