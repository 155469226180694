// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-wrapper {
  margin-bottom: var(--default-margin-bottom);
}
.search-wrapper .search-inner {
  column-gap: 1.06%;
}
.search-wrapper .search-inner .field-wrapper {
  width: 100%;
  margin: 0px 0px;
}
.search-wrapper .search-inner .field-wrapper .input-wrapper {
  width: 100%;
  max-width: 100%;
}
.search-wrapper .search-inner .field-wrapper .input-wrapper > span {
  display: inline-flex;
}
.search-wrapper .search-inner .field-wrapper .input-wrapper > span svg path {
  fill: var(--icons-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/NewComponents/Search/Search.scss"],"names":[],"mappings":"AAAA;EACI,2CAAA;AACJ;AAAI;EACI,iBAAA;AAER;AADQ;EACI,WAAA;EACA,eAAA;AAGZ;AAFY;EACI,WAAA;EACA,eAAA;AAIhB;AAHgB;EACI,oBAAA;AAKpB;AAHwB;EACI,wBAAA;AAK5B","sourcesContent":[".search-wrapper{\n    margin-bottom: var(--default-margin-bottom);\n    .search-inner{\n        column-gap: 1.06%;\n        .field-wrapper{\n            width: 100%;\n            margin: 0px 0px;\n            .input-wrapper{\n                width: 100%;\n                max-width: 100%;\n                &>span{\n                    display: inline-flex;\n                    svg{\n                        path{\n                            fill: var(--icons-color);\n                        }\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
