// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projected-calculated-header {
  display: flex;
  align-content: center;
  justify-content: space-between;
  column-gap: 20px;
}
.projected-calculated-header > div {
  display: inline-flex;
  column-gap: 5px;
  flex-wrap: wrap;
  row-gap: 6px;
}

@media (max-width: 768px) {
  .projected-calculated-header {
    flex-direction: column;
  }
  .projected-calculated-header.overview {
    row-gap: 20px;
  }
  .projected-calculated-header > div {
    width: 100%;
  }
  .projected-calculated-header > div.detail-proj-type-buttons > button {
    max-width: 100%;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/NewComponents/SmartDetailProjections/SmartDetailProjections.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qBAAA;EACA,8BAAA;EACA,gBAAA;AACJ;AAAI;EACI,oBAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;AAER;;AAEA;EACI;IACI,sBAAA;EACN;EAAM;IACI,aAAA;EAEV;EAAM;IACI,WAAA;EAEV;EAAc;IACI,eAAA;IACJ,WAAA;EAEd;AACF","sourcesContent":[".projected-calculated-header{\n    display: flex;\n    align-content: center;\n    justify-content: space-between;\n    column-gap: 20px;\n    &>div{\n        display: inline-flex;\n        column-gap: 5px;\n        flex-wrap: wrap;\n        row-gap: 6px;\n    }\n}\n\n@media (max-width: 768px){\n    .projected-calculated-header{\n        flex-direction: column;\n        &.overview{\n            row-gap: 20px;\n        }\n        &>div{\n            width: 100%;\n            &.detail-proj-type-buttons{\n                &>button{\n                    max-width: 100%;\n                width: 100%;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
