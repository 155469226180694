// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nodata-wrapper .nodata-inner {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-radius: 4px;
  border: 1px solid #FF1212;
  background: rgba(255, 18, 18, 0.16);
  margin-top: 12px;
  margin-bottom: 12px;
}
.nodata-wrapper .nodata-inner .nodata-text h3 {
  color: #fff;
  margin-bottom: 12px;
  line-height: normal;
  font-size: 24px;
}
.nodata-wrapper .nodata-inner .nodata-text p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Components/MiroComponents/NoDataFound/NoDataFound.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mCAAA;EACA,gBAAA;EACA,mBAAA;AAAR;AAEY;EACI,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AAAhB;AAEY;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;AAAhB","sourcesContent":[".nodata-wrapper {\n    .nodata-inner {\n        display: flex;\n        align-items: center;\n        padding: 12px 24px;\n        border-radius: 4px;\n        border: 1px solid #FF1212;\n        background: rgba(255, 18, 18, 0.16);\n        margin-top: 12px;\n        margin-bottom: 12px;\n        .nodata-text {\n            h3 {\n                color: #fff;\n                margin-bottom: 12px;\n                line-height: normal;\n                font-size: 24px;\n            }\n            p{\n                font-size: 16px;\n                font-weight: 400;\n                margin-bottom: 12px;\n                color: #fff;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
