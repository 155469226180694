import { Link } from "react-router-dom";

export const SettingsBar = ({ items, rolesCheck }) => {
  return (
    <div className="setting-bar">
      <ul className="setting-bar-left-inner">
        {items?.map((itm, i) => {
          const found = itm?.rolesAllowed.some(
            (r) => rolesCheck.indexOf(r) >= 0
          );
          if (found) {
            return (
              <li>
                <Link key={i} className="" to={itm.route}>
                  {itm?.icon}
                  <span>{itm?.name}</span>
                </Link>
              </li>
            );
          }
        })}
      </ul>
      {/* <p>
        Need help? Talk to one of our devs to get your help sorted
        <Link className="d-flex" to="/comingsoon">
          Just click here
        </Link>
      </p> */}
    </div>
  );
};
