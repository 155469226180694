export const abTestDataSample = {
  loading: false,
  data: {
    test: {
      _id: "6659c441e393609d8c62e3ac",
      status: "live",
      audiences: [
        {
          _id: "6659c45de393609d8c62e425",
          name: "All Users",
        },
      ],
      pages: [
        {
          _id: "6659c4e6e393609d8c62e427",
          name: "Ab test Page Kinobody",
        },
      ],
      variations: [
        {
          name: "Reference",
          baseline: true,
          traffic_allocation: 50,
          _id: "6659c441e393609d8c62e3ad",
        },
        {
          name: "Variant 1",
          baseline: false,
          traffic_allocation: 50,
          _id: "665d9489640742db3fb162c7",
        },
      ],
      client: {
        _id: "63f5e547802e6b84b9e00e11",
        name: "Kinobody",
      },
      property: {
        label: "KINOBODY - GA4",
        value: "373645924",
      },
      goal: [
        {
          _id: "624ff02ff4ce909c75b03ffc",
          name: "Higher Conversion-Rate",
        },
        {
          _id: "6659c4fee393609d8c62e42d",
          name: "Heading Click",
        },
      ],
      livedate: null,
    },
    dataEvents: [
      {
        id: "clx620fzy02ji12vcit8i9lvy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "oYVbEr8YVt",
            locked: 1717847438,
            timestamp: 1717847437,
          },
        ],
        createdAt: "2024-06-08T11:50:40.124Z",
        updatedAt: "2024-06-08T11:50:40.124Z",
      },
      {
        id: "clx61z7gw02jh12vc3hb67mn1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ccoggsr3nP",
            locked: 1717847382,
            timestamp: 1717847381,
          },
        ],
        createdAt: "2024-06-08T11:49:42.417Z",
        updatedAt: "2024-06-08T11:49:42.417Z",
      },
      {
        id: "clx61yzj202jg12vcz8u7ubcc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; 2109119DI) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.6422.148 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "wcIEyAG7vS",
            locked: 1717847370,
            timestamp: 1717847367,
          },
        ],
        createdAt: "2024-06-08T11:49:32.123Z",
        updatedAt: "2024-06-08T11:49:32.123Z",
      },
      {
        id: "clx61xy6x02jf12vc6vl3hdo4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "agLdMln6F5",
            locked: 1717847323,
            timestamp: 1717847321,
          },
        ],
        createdAt: "2024-06-08T11:48:43.734Z",
        updatedAt: "2024-06-08T11:48:43.734Z",
      },
      {
        id: "clx61wj8602je12vcejkv8gma",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 640dpi; 1440x2796; samsung; SM-S918U1; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MLyRbyRYxm",
            locked: 1717847257,
            timestamp: 1717847255,
          },
        ],
        createdAt: "2024-06-08T11:47:37.683Z",
        updatedAt: "2024-06-08T11:47:37.683Z",
      },
      {
        id: "clx61ux4n02jd12vcngzj2k6n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pjgnEmcKA1",
            locked: 1717847182,
            timestamp: 1717847177,
          },
        ],
        createdAt: "2024-06-08T11:46:22.389Z",
        updatedAt: "2024-06-08T11:46:22.389Z",
      },
      {
        id: "clx61sai602jc12vcqb3nexit",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iXkdDfvUAt",
            locked: 1717847058,
            timestamp: 1717847053,
          },
        ],
        createdAt: "2024-06-08T11:44:19.754Z",
        updatedAt: "2024-06-08T11:44:19.754Z",
      },
      {
        id: "clx61rs2k02jb12vclh8nuprg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Bxml9967M1",
            locked: 1717847035,
            timestamp: 1717847033,
          },
        ],
        createdAt: "2024-06-08T11:43:55.868Z",
        updatedAt: "2024-06-08T11:43:55.868Z",
      },
      {
        id: "clx61rgpz02ja12vcuc9fedeo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "liDiLtv0dZ",
            locked: 1717847020,
            timestamp: 1717847019,
          },
        ],
        createdAt: "2024-06-08T11:43:41.157Z",
        updatedAt: "2024-06-08T11:43:41.157Z",
      },
      {
        id: "clx61qe5502j912vcnzyjbe4s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yT6F5V8lHg",
            locked: 1717846970,
            timestamp: 1717846969,
          },
        ],
        createdAt: "2024-06-08T11:42:51.158Z",
        updatedAt: "2024-06-08T11:42:51.158Z",
      },
      {
        id: "clx61o8nu02j812vcxeuddi1n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VnUxXKX38E",
            locked: 1717846870,
            timestamp: 1717846868,
          },
        ],
        createdAt: "2024-06-08T11:41:10.743Z",
        updatedAt: "2024-06-08T11:41:10.743Z",
      },
      {
        id: "clx61miqx02j712vctnieuf7s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2AoClgM1Nj",
            locked: 1717846790,
            timestamp: 1717846788,
          },
        ],
        createdAt: "2024-06-08T11:39:50.502Z",
        updatedAt: "2024-06-08T11:39:50.502Z",
      },
      {
        id: "clx61lv5x02j612vcbr71g6hl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Xh3Q3R7juC",
            locked: 1717846759,
            timestamp: 1717846758,
          },
        ],
        createdAt: "2024-06-08T11:39:19.939Z",
        updatedAt: "2024-06-08T11:39:19.939Z",
      },
      {
        id: "clx61l3ki02j512vcw7sylsqi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A146U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2208; samsung; SM-A146U; a14xm; mt6833; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "d6IOoA5g7X",
            locked: 1717846723,
            timestamp: 1717846721,
          },
        ],
        createdAt: "2024-06-08T11:38:44.178Z",
        updatedAt: "2024-06-08T11:38:44.178Z",
      },
      {
        id: "clx61l21l02j412vc8gqu6qgi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A146U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2208; samsung; SM-A146U; a14xm; mt6833; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "E66s6NNLOh",
            locked: 1717846721,
            timestamp: 1717846713,
          },
        ],
        createdAt: "2024-06-08T11:38:42.201Z",
        updatedAt: "2024-06-08T11:38:42.201Z",
      },
      {
        id: "clx61kvze02j312vci7w9poua",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S908U; b0q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3nPMW6JGHg",
            locked: 1717846713,
            timestamp: 1717846710,
          },
        ],
        createdAt: "2024-06-08T11:38:34.344Z",
        updatedAt: "2024-06-08T11:38:34.344Z",
      },
      {
        id: "clx61jqm102j212vc19lhu7k0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dYwWCb4YBj",
            locked: 1717846660,
            timestamp: 1717846657,
          },
        ],
        createdAt: "2024-06-08T11:37:40.730Z",
        updatedAt: "2024-06-08T11:37:40.730Z",
      },
      {
        id: "clx61jftr02j112vcfgqold6w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jzH6Nii7x1",
            locked: 1717846646,
            timestamp: 1717846639,
          },
        ],
        createdAt: "2024-06-08T11:37:26.749Z",
        updatedAt: "2024-06-08T11:37:26.749Z",
      },
      {
        id: "clx61hztf02j012vcxebqx2k5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_4_1; en_CA; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "q7JQ1EqHOb",
            locked: 1717846579,
            timestamp: 1717846578,
          },
        ],
        createdAt: "2024-06-08T11:36:19.347Z",
        updatedAt: "2024-06-08T11:36:19.347Z",
      },
      {
        id: "clx61hstp02iz12vch7qokoxc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_4_1; en_CA; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YOaF1fGnNa",
            locked: 1717846570,
            timestamp: 1717846568,
          },
        ],
        createdAt: "2024-06-08T11:36:10.283Z",
        updatedAt: "2024-06-08T11:36:10.283Z",
      },
      {
        id: "clx61hgy402iy12vcwggqya3q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_4_1; en_CA; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CO6QYodhz2",
            locked: 1717846554,
            timestamp: 1717846553,
          },
        ],
        createdAt: "2024-06-08T11:35:54.889Z",
        updatedAt: "2024-06-08T11:35:54.889Z",
      },
      {
        id: "clx61guab02ix12vck2x7vyyn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; moto g fast Build/RPJS31.Q1-53-12-6-12; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (30/11; 280dpi; 720x1450; motorola; moto g fast; rav; qcom; en_US; 606558548)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "G1O64pHPt0",
            locked: 1717846525,
            timestamp: 1717846516,
          },
        ],
        createdAt: "2024-06-08T11:35:25.521Z",
        updatedAt: "2024-06-08T11:35:25.521Z",
      },
      {
        id: "clx61fw3k02iw12vcd2qxva59",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 640dpi; 1440x2796; samsung; SM-S908U; b0q; qcom; en_US; 606558557)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JaOuCEBm4D",
            locked: 1717846480,
            timestamp: 1717846478,
          },
        ],
        createdAt: "2024-06-08T11:34:41.216Z",
        updatedAt: "2024-06-08T11:34:41.216Z",
      },
      {
        id: "clx61fry902iv12vc7f5tnf8h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "odFGcV2sFK",
            locked: 1717846475,
            timestamp: 1717846474,
          },
        ],
        createdAt: "2024-06-08T11:34:35.841Z",
        updatedAt: "2024-06-08T11:34:35.841Z",
      },
      {
        id: "clx61fqwc02iu12vc763em5d9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "OtX586Wb9N",
            locked: 1717846474,
            timestamp: 1717846473,
          },
        ],
        createdAt: "2024-06-08T11:34:34.474Z",
        updatedAt: "2024-06-08T11:34:34.474Z",
      },
      {
        id: "clx61ellu02it12vcsm01fv19",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YXI3TUcgfZ",
            locked: 1717846420,
            timestamp: 1717846419,
          },
        ],
        createdAt: "2024-06-08T11:33:40.959Z",
        updatedAt: "2024-06-08T11:33:40.959Z",
      },
      {
        id: "clx61e51702is12vckb6t6a83",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A528B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.152 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/467.0.0.46.83;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bTMnWiYwPu",
            locked: 1717846399,
            timestamp: 1717846395,
          },
        ],
        createdAt: "2024-06-08T11:33:19.482Z",
        updatedAt: "2024-06-08T11:33:19.482Z",
      },
      {
        id: "clx61dyd802ir12vcj3fxfkhi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909) NW/3",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ESow3tCLi6",
            locked: 1717846390,
            timestamp: 1717846389,
          },
        ],
        createdAt: "2024-06-08T11:33:10.845Z",
        updatedAt: "2024-06-08T11:33:10.845Z",
      },
      {
        id: "clx61dqzp02iq12vcph0sz938",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-A217F Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.152 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/467.0.0.46.83;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KHsNetm2DO",
            locked: 1717846381,
            timestamp: 1717846376,
          },
        ],
        createdAt: "2024-06-08T11:33:01.282Z",
        updatedAt: "2024-06-08T11:33:01.282Z",
      },
      {
        id: "clx61d4yj02ip12vcqd0f14nx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "dbpLT5B9a9",
            locked: 1717846352,
            timestamp: 1717846351,
          },
        ],
        createdAt: "2024-06-08T11:32:32.731Z",
        updatedAt: "2024-06-08T11:32:32.731Z",
      },
      {
        id: "clx61d04x02io12vc7erfkkxm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qB37ZliWP7",
            locked: 1717846346,
            timestamp: 1717846344,
          },
        ],
        createdAt: "2024-06-08T11:32:26.479Z",
        updatedAt: "2024-06-08T11:32:26.479Z",
      },
      {
        id: "clx61beow02in12vcj8auafjt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "K1oU3iRiwH",
            locked: 1717846271,
            timestamp: 1717846270,
          },
        ],
        createdAt: "2024-06-08T11:31:12.033Z",
        updatedAt: "2024-06-08T11:31:12.033Z",
      },
      {
        id: "clx61b8qo02im12vccew7n273",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "P6dLCbw5y2",
            locked: 1717846263,
            timestamp: 1717846262,
          },
        ],
        createdAt: "2024-06-08T11:31:04.318Z",
        updatedAt: "2024-06-08T11:31:04.318Z",
      },
      {
        id: "clx61a7c202il12vcknwrqz2g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7bZPksy6kX",
            locked: 1717846215,
            timestamp: 1717846213,
          },
        ],
        createdAt: "2024-06-08T11:30:15.843Z",
        updatedAt: "2024-06-08T11:30:15.843Z",
      },
      {
        id: "clx61a5qq02ik12vcnvsw8dm9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "88YEx3OF1O",
            locked: 1717846212,
            timestamp: 1717846211,
          },
        ],
        createdAt: "2024-06-08T11:30:13.779Z",
        updatedAt: "2024-06-08T11:30:13.779Z",
      },
      {
        id: "clx61a0uf02ij12vcbhx4c2mg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "eTYhhtyioN",
            locked: 1717846207,
            timestamp: 1717846206,
          },
        ],
        createdAt: "2024-06-08T11:30:07.432Z",
        updatedAt: "2024-06-08T11:30:07.432Z",
      },
      {
        id: "clx61a01n02ii12vc2e7osh3u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "owwVxwSE0S",
            locked: 1717846205,
            timestamp: 1717846202,
          },
        ],
        createdAt: "2024-06-08T11:30:06.393Z",
        updatedAt: "2024-06-08T11:30:06.393Z",
      },
      {
        id: "clx619o4402ih12vc3dta1gei",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S911U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2214; samsung; SM-S911U; dm1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iLFd8UffTw",
            locked: 1717846190,
            timestamp: 1717846188,
          },
        ],
        createdAt: "2024-06-08T11:29:50.933Z",
        updatedAt: "2024-06-08T11:29:50.933Z",
      },
      {
        id: "clx619ix802ig12vc6svbw66c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "XkDlK2R2GJ",
            locked: 1717846183,
            timestamp: 1717846182,
          },
        ],
        createdAt: "2024-06-08T11:29:44.205Z",
        updatedAt: "2024-06-08T11:29:44.205Z",
      },
      {
        id: "clx619iqg02if12vcon5og32p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "foa4jJ74pj",
            locked: 1717846184,
            timestamp: 1717846183,
          },
        ],
        createdAt: "2024-06-08T11:29:43.960Z",
        updatedAt: "2024-06-08T11:29:43.960Z",
      },
      {
        id: "clx619i7b02ie12vcu89ibnd5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DNgrt55hJl",
            locked: 1717846182,
            timestamp: 1717846181,
          },
        ],
        createdAt: "2024-06-08T11:29:43.270Z",
        updatedAt: "2024-06-08T11:29:43.270Z",
      },
      {
        id: "clx619an202id12vca1jryvub",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 309.1.1.28.108 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 537288535) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0pbT2K9qYE",
            locked: 1717846173,
            timestamp: 1717846171,
          },
        ],
        createdAt: "2024-06-08T11:29:33.471Z",
        updatedAt: "2024-06-08T11:29:33.471Z",
      },
      {
        id: "clx61935p02ic12vcjjejhrb0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 309.1.1.28.108 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 537288535)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DgO4cqEooA",
            locked: 1717846163,
            timestamp: 1717846162,
          },
        ],
        createdAt: "2024-06-08T11:29:23.770Z",
        updatedAt: "2024-06-08T11:29:23.770Z",
      },
      {
        id: "clx618nov02ib12vcrx9yafk0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21C66 Instagram 326.0.3.30.91 (iPhone14,3; iOS 17_2_1; en_US; en; scale=3.00; 1284x2778; 585196636)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "W1pWzkbU3o",
            locked: 1717846140,
            timestamp: 1717846139,
          },
        ],
        createdAt: "2024-06-08T11:29:03.724Z",
        updatedAt: "2024-06-08T11:29:03.724Z",
      },
      {
        id: "clx6188f202ia12vc9x8pqiwj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad11,6; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZIYsDbBTQY",
            locked: 1717846123,
            timestamp: 1717846121,
          },
        ],
        createdAt: "2024-06-08T11:28:43.932Z",
        updatedAt: "2024-06-08T11:28:43.932Z",
      },
      {
        id: "clx617jzb02i912vc1w0xw9r7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "A6tmRDkYsB",
            locked: 1717846092,
            timestamp: 1717846090,
          },
        ],
        createdAt: "2024-06-08T11:28:12.261Z",
        updatedAt: "2024-06-08T11:28:12.261Z",
      },
      {
        id: "clx6174ip02i812vcu7csaith",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone15,2; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "oJAk30v5ND",
            locked: 1717846071,
            timestamp: 1717846070,
          },
        ],
        createdAt: "2024-06-08T11:27:52.223Z",
        updatedAt: "2024-06-08T11:27:52.223Z",
      },
      {
        id: "clx616pp902i712vcekynonqw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone15,2; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zgR1LPt3Dx",
            locked: 1717846052,
            timestamp: 1717846050,
          },
        ],
        createdAt: "2024-06-08T11:27:33.022Z",
        updatedAt: "2024-06-08T11:27:33.022Z",
      },
      {
        id: "clx616oy002i612vcsr3to0po",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "OVwmGZbqFe",
            locked: 1717846050,
            timestamp: 1717846049,
          },
        ],
        createdAt: "2024-06-08T11:27:32.041Z",
        updatedAt: "2024-06-08T11:27:32.041Z",
      },
      {
        id: "clx616oxq02i512vckdz7gb6t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Q03SH8aj5Q",
            locked: 1717846051,
            timestamp: 1717846050,
          },
        ],
        createdAt: "2024-06-08T11:27:32.026Z",
        updatedAt: "2024-06-08T11:27:32.026Z",
      },
      {
        id: "clx615yzf02i412vcv08s3pyp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "oqf2FCKnqW",
            locked: 1717846016,
            timestamp: 1717846015,
          },
        ],
        createdAt: "2024-06-08T11:26:58.393Z",
        updatedAt: "2024-06-08T11:26:58.393Z",
      },
      {
        id: "clx615n4q02i312vc049if8sc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G736U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 331.0.0.37.90 Android (34/14; 450dpi; 1080x2207; samsung; SM-G736U; xcoverpro2; qcom; en_US; 598808625)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "mzpM1jXTlM",
            locked: 1717846002,
            timestamp: 1717845998,
          },
        ],
        createdAt: "2024-06-08T11:26:43.035Z",
        updatedAt: "2024-06-08T11:26:43.035Z",
      },
      {
        id: "clx615k7w02i212vcb9veatob",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 290.1.0.16.77 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 491279855)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "D5KTf485ug",
            locked: 1717845998,
            timestamp: 1717845997,
          },
        ],
        createdAt: "2024-06-08T11:26:39.259Z",
        updatedAt: "2024-06-08T11:26:39.259Z",
      },
      {
        id: "clx615cur02i112vc9gd7n4y3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4BzHywTpfn",
            locked: 1717845983,
            timestamp: 1717845965,
          },
        ],
        createdAt: "2024-06-08T11:26:29.716Z",
        updatedAt: "2024-06-08T11:26:29.716Z",
      },
      {
        id: "clx6158iw02i012vcwzmuh8zy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "hRaQ8FIjib",
            locked: 1717845983,
            timestamp: 1717845982,
          },
        ],
        createdAt: "2024-06-08T11:26:24.102Z",
        updatedAt: "2024-06-08T11:26:24.102Z",
      },
      {
        id: "clx614um102hz12vcdf92tdjk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kugIfnIfzX",
            locked: 1717845965,
            timestamp: 1717845962,
          },
        ],
        createdAt: "2024-06-08T11:26:06.071Z",
        updatedAt: "2024-06-08T11:26:06.071Z",
      },
      {
        id: "clx614kmy02hy12vc1pdt3f4h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wiCbbm050Q",
            locked: 1717845953,
            timestamp: 1717845951,
          },
        ],
        createdAt: "2024-06-08T11:25:53.147Z",
        updatedAt: "2024-06-08T11:25:53.147Z",
      },
      {
        id: "clx614kea02hx12vczumq1s8x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dBZr5f0ZvV",
            locked: 1717845952,
            timestamp: 1717845950,
          },
        ],
        createdAt: "2024-06-08T11:25:52.834Z",
        updatedAt: "2024-06-08T11:25:52.834Z",
      },
      {
        id: "clx614gb802hw12vcjrr2ucdr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lcPBORmum3",
            locked: 1717845946,
            timestamp: 1717845945,
          },
        ],
        createdAt: "2024-06-08T11:25:47.538Z",
        updatedAt: "2024-06-08T11:25:47.538Z",
      },
      {
        id: "clx613reg02hv12vct43n6w7w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hi5WQFuHZq",
            locked: 1717845914,
            timestamp: 1717845911,
          },
        ],
        createdAt: "2024-06-08T11:25:15.253Z",
        updatedAt: "2024-06-08T11:25:15.253Z",
      },
      {
        id: "clx613bxr02hu12vckozdwxdq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_1_2; es_CY; es; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "a3AFpYmg2l",
            locked: 1717845895,
            timestamp: 1717845894,
          },
        ],
        createdAt: "2024-06-08T11:24:55.213Z",
        updatedAt: "2024-06-08T11:24:55.213Z",
      },
      {
        id: "clx612rnc02ht12vc1oj4zbig",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_1_2; es_CY; es; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VKVblu3ySm",
            locked: 1717845868,
            timestamp: 1717845866,
          },
        ],
        createdAt: "2024-06-08T11:24:28.918Z",
        updatedAt: "2024-06-08T11:24:28.918Z",
      },
      {
        id: "clx612bkg02hs12vcsfio0wzu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A356U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 331.0.0.37.90 Android (34/14; 450dpi; 1080x2116; samsung; SM-A356U1; a35x; s5e8835; en_US; 598808625)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZB4ND2Htfo",
            locked: 1717845847,
            timestamp: 1717845844,
          },
        ],
        createdAt: "2024-06-08T11:24:08.078Z",
        updatedAt: "2024-06-08T11:24:08.078Z",
      },
      {
        id: "clx610iw102hr12vc8qrll9l7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,3; iOS 16_7_8; en_MY; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ZpLUK23onV",
            locked: 1717845763,
            timestamp: 1717845760,
          },
        ],
        createdAt: "2024-06-08T11:22:44.254Z",
        updatedAt: "2024-06-08T11:22:44.254Z",
      },
      {
        id: "clx60zku902hq12vcr4l7wdqy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "grbeUll0lf",
            locked: 1717845719,
            timestamp: 1717845718,
          },
        ],
        createdAt: "2024-06-08T11:22:00.130Z",
        updatedAt: "2024-06-08T11:22:00.130Z",
      },
      {
        id: "clx60zjxg02hp12vc41tyz72p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qjNPCGJySU",
            locked: 1717845718,
            timestamp: 1717845716,
          },
        ],
        createdAt: "2024-06-08T11:21:58.946Z",
        updatedAt: "2024-06-08T11:21:58.946Z",
      },
      {
        id: "clx60z3jq02ho12vcdi9fir9x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "V1Aqh9gMlJ",
            locked: 1717845697,
            timestamp: 1717845695,
          },
        ],
        createdAt: "2024-06-08T11:21:37.716Z",
        updatedAt: "2024-06-08T11:21:37.716Z",
      },
      {
        id: "clx60xybq02hn12vcjrqheu21",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yeopEKoguT",
            locked: 1717845643,
            timestamp: 1717845642,
          },
        ],
        createdAt: "2024-06-08T11:20:44.292Z",
        updatedAt: "2024-06-08T11:20:44.292Z",
      },
      {
        id: "clx60wg4q02hm12vcf79q68pi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nk13Eyqnpu",
            locked: 1717845573,
            timestamp: 1717845569,
          },
        ],
        createdAt: "2024-06-08T11:19:34.056Z",
        updatedAt: "2024-06-08T11:19:34.056Z",
      },
      {
        id: "clx60u5vx02hl12vckegd6hh1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "23LZWsFPT2",
            locked: 1717845466,
            timestamp: 1717845463,
          },
        ],
        createdAt: "2024-06-08T11:17:47.469Z",
        updatedAt: "2024-06-08T11:17:47.469Z",
      },
      {
        id: "clx60txyz02hk12vcod2yj1zz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N970U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 420dpi; 1080x2064; samsung; SM-N970U; d1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0rdfNGIjw9",
            locked: 1717845464,
            timestamp: 1717845460,
          },
        ],
        createdAt: "2024-06-08T11:17:37.208Z",
        updatedAt: "2024-06-08T11:17:37.208Z",
      },
      {
        id: "clx60te1402hj12vc2yb2cfoz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.1.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ZCblrCPImc",
            locked: 1717845428,
            timestamp: 1717845426,
          },
        ],
        createdAt: "2024-06-08T11:17:11.369Z",
        updatedAt: "2024-06-08T11:17:11.369Z",
      },
      {
        id: "clx60tch002hi12vcuygbpjui",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "X4O5W0hCBW",
            locked: 1717845430,
            timestamp: 1717845429,
          },
        ],
        createdAt: "2024-06-08T11:17:09.345Z",
        updatedAt: "2024-06-08T11:17:09.345Z",
      },
      {
        id: "clx60t3of02hh12vcmar5gxah",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nn8GevfMBg",
            locked: 1717845414,
            timestamp: 1717845413,
          },
        ],
        createdAt: "2024-06-08T11:16:57.951Z",
        updatedAt: "2024-06-08T11:16:57.951Z",
      },
      {
        id: "clx60sze702hg12vc65a4gb8m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_AU; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fFhmZ9kQrV",
            locked: 1717845411,
            timestamp: 1717845409,
          },
        ],
        createdAt: "2024-06-08T11:16:52.399Z",
        updatedAt: "2024-06-08T11:16:52.399Z",
      },
      {
        id: "clx60szbu02hf12vcc1sa8lyg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tMpI43pcxV",
            locked: 1717845411,
            timestamp: 1717845410,
          },
        ],
        createdAt: "2024-06-08T11:16:52.313Z",
        updatedAt: "2024-06-08T11:16:52.313Z",
      },
      {
        id: "clx60slfe02he12vctygcixcm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; CPH2515 Build/RKQ1.211119.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2161; OnePlus; CPH2515; OP5958L1; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "bADGFLFnmd",
            locked: 1717845393,
            timestamp: 1717845392,
          },
        ],
        createdAt: "2024-06-08T11:16:34.298Z",
        updatedAt: "2024-06-08T11:16:34.298Z",
      },
      {
        id: "clx60sio602hd12vc3zhof830",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; CPH2515 Build/RKQ1.211119.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2161; OnePlus; CPH2515; OP5958L1; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9JWshRzw6C",
            locked: 1717845389,
            timestamp: 1717845386,
          },
        ],
        createdAt: "2024-06-08T11:16:30.723Z",
        updatedAt: "2024-06-08T11:16:30.723Z",
      },
      {
        id: "clx60rtsw02hc12vc0zj32ayn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "thp4O0dvKF",
            locked: 1717845357,
            timestamp: 1717845353,
          },
        ],
        createdAt: "2024-06-08T11:15:58.496Z",
        updatedAt: "2024-06-08T11:15:58.496Z",
      },
      {
        id: "clx60rivq02hb12vcvukuqvvm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,3; iOS 16_7_8; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "G4whUQ1UqW",
            locked: 1717845342,
            timestamp: 1717845340,
          },
        ],
        createdAt: "2024-06-08T11:15:44.342Z",
        updatedAt: "2024-06-08T11:15:44.342Z",
      },
      {
        id: "clx60ribi02ha12vcqanhdi1g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aIqYdMDZu3",
            locked: 1717845343,
            timestamp: 1717845342,
          },
        ],
        createdAt: "2024-06-08T11:15:43.613Z",
        updatedAt: "2024-06-08T11:15:43.613Z",
      },
      {
        id: "clx60re9502h912vcs1wc9z6n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S926U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2127; samsung; SM-S926U; e2q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CouxsmqXfA",
            locked: 1717845337,
            timestamp: 1717845334,
          },
        ],
        createdAt: "2024-06-08T11:15:38.345Z",
        updatedAt: "2024-06-08T11:15:38.345Z",
      },
      {
        id: "clx60r67402h812vcdlvtj8c5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ttiKzsdd6Q",
            locked: 1717845326,
            timestamp: 1717845316,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Yyl8InGpIG",
            locked: 1717845326,
            timestamp: 1717845321,
          },
        ],
        createdAt: "2024-06-08T11:15:27.902Z",
        updatedAt: "2024-06-08T11:15:27.902Z",
      },
      {
        id: "clx60qlno02h712vcgvkiidw9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F79 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5; en_DK; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ZOiFZqeNFw",
            locked: 1717845301,
            timestamp: 1717845299,
          },
        ],
        createdAt: "2024-06-08T11:15:01.281Z",
        updatedAt: "2024-06-08T11:15:01.281Z",
      },
      {
        id: "clx60o60i02h612vcmyjrsdcr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YK9JvllR6w",
            locked: 1717845187,
            timestamp: 1717845185,
          },
        ],
        createdAt: "2024-06-08T11:13:07.698Z",
        updatedAt: "2024-06-08T11:13:07.698Z",
      },
      {
        id: "clx60ny2p02h512vclowgau0h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NelX6hSXDl",
            locked: 1717845176,
            timestamp: 1717845175,
          },
        ],
        createdAt: "2024-06-08T11:12:57.406Z",
        updatedAt: "2024-06-08T11:12:57.406Z",
      },
      {
        id: "clx60m96j02h412vcmc9xdv1u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A546U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2125; samsung; SM-A546U; a54x; s5e8835; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "7iwSDHSMQ7",
            locked: 1717845098,
            timestamp: 1717845097,
          },
        ],
        createdAt: "2024-06-08T11:11:38.491Z",
        updatedAt: "2024-06-08T11:11:38.491Z",
      },
      {
        id: "clx60m5s902h312vcncfei2eu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A546U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2125; samsung; SM-A546U; a54x; s5e8835; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "g69TUz3BxK",
            locked: 1717845093,
            timestamp: 1717845089,
          },
        ],
        createdAt: "2024-06-08T11:11:34.086Z",
        updatedAt: "2024-06-08T11:11:34.086Z",
      },
      {
        id: "clx60kt3t02h212vcnx8t5e9m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G981U1 Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2178; samsung; SM-G981U1; x1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "zwY6HuOZSO",
            locked: 1717845031,
            timestamp: 1717845030,
          },
        ],
        createdAt: "2024-06-08T11:10:31.001Z",
        updatedAt: "2024-06-08T11:10:31.001Z",
      },
      {
        id: "clx60kr4p02h112vc8dwe3gth",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Yln09RlXBY",
            locked: 1717845028,
            timestamp: 1717845026,
          },
        ],
        createdAt: "2024-06-08T11:10:28.441Z",
        updatedAt: "2024-06-08T11:10:28.441Z",
      },
      {
        id: "clx60kcf002h012vckl41lcum",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G981U1 Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2178; samsung; SM-G981U1; x1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vi3b5Xyivy",
            locked: 1717845009,
            timestamp: 1717844996,
          },
        ],
        createdAt: "2024-06-08T11:10:09.369Z",
        updatedAt: "2024-06-08T11:10:09.369Z",
      },
      {
        id: "clx60jzo302gz12vcximfhv65",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_NO; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "z7TPgnF4pH",
            locked: 1717844992,
            timestamp: 1717844990,
          },
        ],
        createdAt: "2024-06-08T11:09:52.848Z",
        updatedAt: "2024-06-08T11:09:52.848Z",
      },
      {
        id: "clx60imn302gy12vczrgh04pt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 333.1.4.32.95 (iPhone16,2; iOS 17_3_1; en_US; en; scale=3.00; 1290x2796; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "nOwc45t1J5",
            locked: 1717844929,
            timestamp: 1717844928,
          },
        ],
        createdAt: "2024-06-08T11:08:49.310Z",
        updatedAt: "2024-06-08T11:08:49.310Z",
      },
      {
        id: "clx60ijj402gx12vcshnwpcqv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 333.1.4.32.95 (iPhone16,2; iOS 17_3_1; en_US; en; scale=3.00; 1290x2796; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ol9JAlJoqr",
            locked: 1717844925,
            timestamp: 1717844924,
          },
        ],
        createdAt: "2024-06-08T11:08:45.280Z",
        updatedAt: "2024-06-08T11:08:45.280Z",
      },
      {
        id: "clx60iaoj02gw12vc42wqfjcg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 333.1.4.32.95 (iPhone16,2; iOS 17_3_1; en_US; en; scale=3.00; 1290x2796; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PUaM7p5ozE",
            locked: 1717844913,
            timestamp: 1717844912,
          },
        ],
        createdAt: "2024-06-08T11:08:33.809Z",
        updatedAt: "2024-06-08T11:08:33.809Z",
      },
      {
        id: "clx60h1v002gv12vcwchb0b9w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "CSASmhsX1J",
            locked: 1717844855,
            timestamp: 1717844851,
          },
        ],
        createdAt: "2024-06-08T11:07:35.724Z",
        updatedAt: "2024-06-08T11:07:35.724Z",
      },
      {
        id: "clx60gx8i02gu12vcsstgdl4r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EWsYctVSLH",
            locked: 1717844849,
            timestamp: 1717844846,
          },
        ],
        createdAt: "2024-06-08T11:07:29.728Z",
        updatedAt: "2024-06-08T11:07:29.728Z",
      },
      {
        id: "clx60fv6302gt12vcv4d1znkr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A536V Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2177; samsung; SM-A536V; a53x; s5e8825; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fB7HsAfiLo",
            locked: 1717844800,
            timestamp: 1717844795,
          },
        ],
        createdAt: "2024-06-08T11:06:40.393Z",
        updatedAt: "2024-06-08T11:06:40.393Z",
      },
      {
        id: "clx60f61202gs12vcf9bg0acd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ggDVyZNd3t",
            locked: 1717844767,
            timestamp: 1717844765,
          },
        ],
        createdAt: "2024-06-08T11:06:07.811Z",
        updatedAt: "2024-06-08T11:06:07.811Z",
      },
      {
        id: "clx60ed3302gr12vc9mmkjbmm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SVlbk6ze5i",
            locked: 1717844729,
            timestamp: 1717844727,
          },
        ],
        createdAt: "2024-06-08T11:05:30.301Z",
        updatedAt: "2024-06-08T11:05:30.301Z",
      },
      {
        id: "clx60bx5302gq12vc4x6peec9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "n6D3wRovZR",
            locked: 1717844615,
            timestamp: 1717844614,
          },
        ],
        createdAt: "2024-06-08T11:03:36.325Z",
        updatedAt: "2024-06-08T11:03:36.325Z",
      },
      {
        id: "clx60b4gj02gp12vcuvk29ddl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1 Ddg/17.5",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "f3GNBbVltB",
            locked: 1717844579,
            timestamp: 1717844578,
          },
        ],
        createdAt: "2024-06-08T11:02:59.155Z",
        updatedAt: "2024-06-08T11:02:59.155Z",
      },
      {
        id: "clx60b3wg02go12vc4srk5pm4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1 Ddg/17.5",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aKpGI4EJKU",
            locked: 1717844577,
            timestamp: 1717844566,
          },
        ],
        createdAt: "2024-06-08T11:02:58.430Z",
        updatedAt: "2024-06-08T11:02:58.430Z",
      },
      {
        id: "clx60apyc02gn12vccn3g44h5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "EdSvGRqcuD",
            locked: 1717844560,
            timestamp: 1717844558,
          },
        ],
        createdAt: "2024-06-08T11:02:40.354Z",
        updatedAt: "2024-06-08T11:02:40.354Z",
      },
      {
        id: "clx609sj102gm12vcljfn5680",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N975U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 420dpi; 1080x2156; samsung; SM-N975U; d2q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TzkZS1R6JC",
            locked: 1717844516,
            timestamp: 1717844514,
          },
        ],
        createdAt: "2024-06-08T11:01:57.035Z",
        updatedAt: "2024-06-08T11:01:57.035Z",
      },
      {
        id: "clx609erc02gl12vclz6f4d7d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A516V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 241.1.0.18.114 Android (33/13; 420dpi; 1080x2186; samsung; SM-A516V; a51xq; qcom; en_US; 379517355)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "V456YYCnDE",
            locked: 1717844499,
            timestamp: 1717844498,
          },
        ],
        createdAt: "2024-06-08T11:01:39.192Z",
        updatedAt: "2024-06-08T11:01:39.192Z",
      },
      {
        id: "clx609bkq02gk12vcll4hu0y5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A516V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 241.1.0.18.114 Android (33/13; 420dpi; 1080x2186; samsung; SM-A516V; a51xq; qcom; en_US; 379517355)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "BTBrsb6RLd",
            locked: 1717844494,
            timestamp: 1717844490,
          },
        ],
        createdAt: "2024-06-08T11:01:35.064Z",
        updatedAt: "2024-06-08T11:01:35.064Z",
      },
      {
        id: "clx608cm102gj12vcvow8m25j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-N950U Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (28/9; 420dpi; 1080x2094; samsung; SM-N950U; greatqlte; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Dpr52yPC3R",
            locked: 1717844448,
            timestamp: 1717844447,
          },
        ],
        createdAt: "2024-06-08T11:00:49.752Z",
        updatedAt: "2024-06-08T11:00:49.752Z",
      },
      {
        id: "clx608ava02gi12vct64uf4e1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 263.1.0.14.103 (iPhone13,4; iOS 17_5_1; en_US; en-US; scale=3.00; 1284x2778; 428326971)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QuVAsGwTr5",
            locked: 1717844446,
            timestamp: 1717844444,
          },
        ],
        createdAt: "2024-06-08T11:00:47.494Z",
        updatedAt: "2024-06-08T11:00:47.494Z",
      },
      {
        id: "clx60811h02gh12vcntad33qr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "i3qKepJKSf",
            locked: 1717844434,
            timestamp: 1717844432,
          },
        ],
        createdAt: "2024-06-08T11:00:34.757Z",
        updatedAt: "2024-06-08T11:00:34.757Z",
      },
      {
        id: "clx6080bu02gg12vcukc9xk13",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-N950U Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (28/9; 420dpi; 1080x2094; samsung; SM-N950U; greatqlte; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FHJA2UNCBE",
            locked: 1717844432,
            timestamp: 1717844425,
          },
        ],
        createdAt: "2024-06-08T11:00:33.832Z",
        updatedAt: "2024-06-08T11:00:33.832Z",
      },
      {
        id: "clx6063ga02gf12vczarg9bil",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 325.0.3.34.90 (iPad12,1; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 584303227)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "kLCePxCVkc",
            locked: 1717844344,
            timestamp: 1717844343,
          },
        ],
        createdAt: "2024-06-08T10:59:04.568Z",
        updatedAt: "2024-06-08T10:59:04.568Z",
      },
      {
        id: "clx605v4n02ge12vccejodjb2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad7,6; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0NuKHe2j9D",
            locked: 1717844332,
            timestamp: 1717844328,
          },
        ],
        createdAt: "2024-06-08T10:58:53.784Z",
        updatedAt: "2024-06-08T10:58:53.784Z",
      },
      {
        id: "clx605p9c02gd12vcpeuya2pt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 325.0.3.34.90 (iPad12,1; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 584303227)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GpNj96E5ih",
            locked: 1717844325,
            timestamp: 1717844323,
          },
        ],
        createdAt: "2024-06-08T10:58:46.174Z",
        updatedAt: "2024-06-08T10:58:46.174Z",
      },
      {
        id: "clx6046xv02gc12vcru1mbwoh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6RISJw1AEA",
            locked: 1717844255,
            timestamp: 1717844254,
          },
        ],
        createdAt: "2024-06-08T10:57:35.779Z",
        updatedAt: "2024-06-08T10:57:35.779Z",
      },
      {
        id: "clx6045g002gb12vcfk9ih4fc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QMAwX5apr5",
            locked: 1717844253,
            timestamp: 1717844252,
          },
        ],
        createdAt: "2024-06-08T10:57:33.838Z",
        updatedAt: "2024-06-08T10:57:33.838Z",
      },
      {
        id: "clx603j6y02ga12vc8gd6fgsk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ii0YsJKFwy",
            locked: 1717844224,
            timestamp: 1717844223,
          },
        ],
        createdAt: "2024-06-08T10:57:04.999Z",
        updatedAt: "2024-06-08T10:57:04.999Z",
      },
      {
        id: "clx602nd202g912vcqj61vvg2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 560dpi; 1440x3120; samsung; SM-S928U; e3q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MUlgePTmbU",
            locked: 1717844183,
            timestamp: 1717844181,
          },
        ],
        createdAt: "2024-06-08T10:56:23.748Z",
        updatedAt: "2024-06-08T10:56:23.748Z",
      },
      {
        id: "clx601qf702g812vc23hqo1c4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ze76kZFofr",
            locked: 1717844140,
            timestamp: 1717844139,
          },
        ],
        createdAt: "2024-06-08T10:55:41.056Z",
        updatedAt: "2024-06-08T10:55:41.056Z",
      },
      {
        id: "clx601a9i02g712vcknzzamyr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1 Ddg/17.5",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "elDRxw9tqR",
            locked: 1717844120,
            timestamp: 1717844118,
          },
        ],
        createdAt: "2024-06-08T10:55:20.119Z",
        updatedAt: "2024-06-08T10:55:20.119Z",
      },
      {
        id: "clx60176802g612vc70h82m02",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1 Ddg/17.5",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SYR6Kc0WF0",
            locked: 1717844115,
            timestamp: 1717844114,
          },
        ],
        createdAt: "2024-06-08T10:55:16.110Z",
        updatedAt: "2024-06-08T10:55:16.110Z",
      },
      {
        id: "clx5zyw2v02g512vc6fiabd0z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rS0fQHKmaK",
            locked: 1717844007,
            timestamp: 1717844006,
          },
        ],
        createdAt: "2024-06-08T10:53:28.421Z",
        updatedAt: "2024-06-08T10:53:28.421Z",
      },
      {
        id: "clx5zxwwd02g412vcs7bwbonb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "J3IU3hIwX8",
            locked: 1717843962,
            timestamp: 1717843961,
          },
        ],
        createdAt: "2024-06-08T10:52:42.829Z",
        updatedAt: "2024-06-08T10:52:42.829Z",
      },
      {
        id: "clx5zxuow02g312vcmzhml9d0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0ZX8Iy6Qzp",
            locked: 1717843959,
            timestamp: 1717843958,
          },
        ],
        createdAt: "2024-06-08T10:52:39.965Z",
        updatedAt: "2024-06-08T10:52:39.965Z",
      },
      {
        id: "clx5zx96d02g212vcuw4qiqsi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 601420801)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "AVOHhS2DWw",
            locked: 1717843931,
            timestamp: 1717843930,
          },
        ],
        createdAt: "2024-06-08T10:52:12.083Z",
        updatedAt: "2024-06-08T10:52:12.083Z",
      },
      {
        id: "clx5zwy0b02g112vc0w223cc8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 601420801)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FigZZ3bSMn",
            locked: 1717843916,
            timestamp: 1717843915,
          },
        ],
        createdAt: "2024-06-08T10:51:57.611Z",
        updatedAt: "2024-06-08T10:51:57.611Z",
      },
      {
        id: "clx5zwudy02g012vcbyywyn9b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AGpaoZheaK",
            locked: 1717843912,
            timestamp: 1717843911,
          },
        ],
        createdAt: "2024-06-08T10:51:52.916Z",
        updatedAt: "2024-06-08T10:51:52.916Z",
      },
      {
        id: "clx5zv09j02fz12vcg74oea3w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yWeBGZFqHR",
            locked: 1717843826,
            timestamp: 1717843825,
          },
        ],
        createdAt: "2024-06-08T10:50:27.224Z",
        updatedAt: "2024-06-08T10:50:27.224Z",
      },
      {
        id: "clx5zuued02fy12vcz2omaw33",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "sv5nkfEsax",
            locked: 1717843820,
            timestamp: 1717843819,
          },
        ],
        createdAt: "2024-06-08T10:50:19.621Z",
        updatedAt: "2024-06-08T10:50:19.621Z",
      },
      {
        id: "clx5zut6m02fx12vc0hpdxnc8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QI1krHFWng",
            locked: 1717843818,
            timestamp: 1717843816,
          },
        ],
        createdAt: "2024-06-08T10:50:18.044Z",
        updatedAt: "2024-06-08T10:50:18.044Z",
      },
      {
        id: "clx5zu7hi02fw12vc2l04g57j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "OcW5Otwicf",
            locked: 1717843789,
            timestamp: 1717843788,
          },
        ],
        createdAt: "2024-06-08T10:49:49.924Z",
        updatedAt: "2024-06-08T10:49:49.924Z",
      },
      {
        id: "clx5zt4mt02fv12vcorqoavmu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-S767VL Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/103.0.5060.53 Mobile Safari/537.36 Instagram 317.0.0.34.109 Android (28/9; 280dpi; 720x1280; samsung; SM-S767VL; j7topeltetfnvzw; exynos7885; en_US; 563459852)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "tlLVD1FtEh",
            locked: 1717843779,
            timestamp: 1717843777,
          },
        ],
        createdAt: "2024-06-08T10:48:59.574Z",
        updatedAt: "2024-06-08T10:48:59.574Z",
      },
      {
        id: "clx5zt43t02fu12vchzjcq1hy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "geCJSYx3ab",
            locked: 1717843738,
            timestamp: 1717843737,
          },
        ],
        createdAt: "2024-06-08T10:48:58.888Z",
        updatedAt: "2024-06-08T10:48:58.888Z",
      },
      {
        id: "clx5zszmz02ft12vcs4nxxj5f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1oBU3n72pQ",
            locked: 1717843732,
            timestamp: 1717843731,
          },
        ],
        createdAt: "2024-06-08T10:48:53.099Z",
        updatedAt: "2024-06-08T10:48:53.099Z",
      },
      {
        id: "clx5zsr5g02fs12vcetxbl7bh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-S767VL Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/103.0.5060.53 Mobile Safari/537.36 Instagram 317.0.0.34.109 Android (28/9; 280dpi; 720x1280; samsung; SM-S767VL; j7topeltetfnvzw; exynos7885; en_US; 563459852)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "i3ZKRgog1n",
            locked: 1717843760,
            timestamp: 1717843748,
          },
        ],
        createdAt: "2024-06-08T10:48:42.097Z",
        updatedAt: "2024-06-08T10:48:42.097Z",
      },
      {
        id: "clx5zrhaf02fr12vcbtlq54un",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KOcB1QmwJB",
            locked: 1717843662,
            timestamp: 1717843661,
          },
        ],
        createdAt: "2024-06-08T10:47:42.660Z",
        updatedAt: "2024-06-08T10:47:42.660Z",
      },
      {
        id: "clx5zqe4r02fq12vc76e1ge0z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "vnSYhfylMG",
            locked: 1717843611,
            timestamp: 1717843610,
          },
        ],
        createdAt: "2024-06-08T10:46:51.914Z",
        updatedAt: "2024-06-08T10:46:51.914Z",
      },
      {
        id: "clx5zq9dt02fp12vcaesvwt2f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vb00ywFsDw",
            locked: 1717843604,
            timestamp: 1717843602,
          },
        ],
        createdAt: "2024-06-08T10:46:45.761Z",
        updatedAt: "2024-06-08T10:46:45.761Z",
      },
      {
        id: "clx5zq83v02fo12vct1vt0gez",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LETjc2gHMq",
            locked: 1717843604,
            timestamp: 1717843602,
          },
        ],
        createdAt: "2024-06-08T10:46:44.107Z",
        updatedAt: "2024-06-08T10:46:44.107Z",
      },
      {
        id: "clx5zq2eu02fn12vc8ddsrzc2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PN9OeKgW66",
            locked: 1717843596,
            timestamp: 1717843594,
          },
        ],
        createdAt: "2024-06-08T10:46:36.723Z",
        updatedAt: "2024-06-08T10:46:36.723Z",
      },
      {
        id: "clx5zox9l02fm12vc0fppewl8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "f3YH0IrBnj",
            locked: 1717843543,
            timestamp: 1717843542,
          },
        ],
        createdAt: "2024-06-08T10:45:43.398Z",
        updatedAt: "2024-06-08T10:45:43.398Z",
      },
      {
        id: "clx5znyka02fl12vco2189lq9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "u09cbu83oB",
            locked: 1717843498,
            timestamp: 1717843496,
          },
        ],
        createdAt: "2024-06-08T10:44:58.423Z",
        updatedAt: "2024-06-08T10:44:58.423Z",
      },
      {
        id: "clx5zng2t02fk12vc1h52wndn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "m50qptnb0A",
            locked: 1717843473,
            timestamp: 1717843472,
          },
        ],
        createdAt: "2024-06-08T10:44:34.466Z",
        updatedAt: "2024-06-08T10:44:34.466Z",
      },
      {
        id: "clx5zld4b02fj12vcucqij268",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NphpKQk71A",
            locked: 1717843376,
            timestamp: 1717843375,
          },
        ],
        createdAt: "2024-06-08T10:42:57.320Z",
        updatedAt: "2024-06-08T10:42:57.320Z",
      },
      {
        id: "clx5zi5l802fi12vcr1mpnv3b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20A362 Instagram 332.0.2.29.94 (iPhone14,2; iOS 16_0; en_US; en; scale=3.00; 1170x2532; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "eOysM86Bjv",
            locked: 1717843227,
            timestamp: 1717843226,
          },
        ],
        createdAt: "2024-06-08T10:40:27.596Z",
        updatedAt: "2024-06-08T10:40:27.596Z",
      },
      {
        id: "clx5zi20o02fh12vcx5ib3z4p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20A362 Instagram 332.0.2.29.94 (iPhone14,2; iOS 16_0; en_US; en; scale=3.00; 1170x2532; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WZiuVUA73L",
            locked: 1717843222,
            timestamp: 1717843219,
          },
        ],
        createdAt: "2024-06-08T10:40:22.966Z",
        updatedAt: "2024-06-08T10:40:22.966Z",
      },
      {
        id: "clx5zezim02fg12vcearakjrl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "JAjR70uCrL",
            locked: 1717843079,
            timestamp: 1717843078,
          },
        ],
        createdAt: "2024-06-08T10:37:59.758Z",
        updatedAt: "2024-06-08T10:37:59.758Z",
      },
      {
        id: "clx5zewo302ff12vck8attwki",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AfKRhzrrPx",
            locked: 1717843075,
            timestamp: 1717843074,
          },
        ],
        createdAt: "2024-06-08T10:37:56.065Z",
        updatedAt: "2024-06-08T10:37:56.065Z",
      },
      {
        id: "clx5ze28o02fe12vclncjlvl0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G781U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2186; samsung; SM-G781U; r8q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TNHvyU8vzQ",
            locked: 1717843035,
            timestamp: 1717843033,
          },
        ],
        createdAt: "2024-06-08T10:37:16.629Z",
        updatedAt: "2024-06-08T10:37:16.629Z",
      },
      {
        id: "clx5zbhi502fd12vcl58pvpps",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MfmU5qLj9m",
            locked: 1717842915,
            timestamp: 1717842914,
          },
        ],
        createdAt: "2024-06-08T10:35:16.442Z",
        updatedAt: "2024-06-08T10:35:16.442Z",
      },
      {
        id: "clx5zanhi02fc12vcw2h6jgai",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 275.1.0.17.100 (iPhone11,8; iOS 17_4_1; en_US; en-US; scale=2.00; 828x1792; 458156275)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KhOqNhmcSb",
            locked: 1717842877,
            timestamp: 1717842875,
          },
        ],
        createdAt: "2024-06-08T10:34:37.540Z",
        updatedAt: "2024-06-08T10:34:37.540Z",
      },
      {
        id: "clx5z9d8c02fb12vc0wrzxjhy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,1; iOS 16_7_8; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hGHtRh606J",
            locked: 1717842816,
            timestamp: 1717842814,
          },
        ],
        createdAt: "2024-06-08T10:33:37.593Z",
        updatedAt: "2024-06-08T10:33:37.593Z",
      },
      {
        id: "clx5z8vfn02fa12vcsqur6jtk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OHgoXp00FU",
            locked: 1717842793,
            timestamp: 1717842792,
          },
        ],
        createdAt: "2024-06-08T10:33:14.531Z",
        updatedAt: "2024-06-08T10:33:14.531Z",
      },
      {
        id: "clx5z8qf002f912vc4mkn9h9a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FYAdLxoe92",
            locked: 1717842787,
            timestamp: 1717842786,
          },
        ],
        createdAt: "2024-06-08T10:33:08.026Z",
        updatedAt: "2024-06-08T10:33:08.026Z",
      },
      {
        id: "clx5z683502f812vcmt38gs67",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 330.0.0.40.92 Android (34/14; 450dpi; 1080x2222; samsung; SM-S928U; e3q; qcom; en_US; 596227443)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IiAYVuvLt6",
            locked: 1717842669,
            timestamp: 1717842668,
          },
        ],
        createdAt: "2024-06-08T10:31:10.958Z",
        updatedAt: "2024-06-08T10:31:10.958Z",
      },
      {
        id: "clx5z4nk902f712vc2nae2gd2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cDMO0BEUDH",
            locked: 1717842597,
            timestamp: 1717842596,
          },
        ],
        createdAt: "2024-06-08T10:29:57.705Z",
        updatedAt: "2024-06-08T10:29:57.705Z",
      },
      {
        id: "clx5z4mub02f612vcx5auftld",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_5_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qkXScmvPLx",
            locked: 1717842596,
            timestamp: 1717842594,
          },
        ],
        createdAt: "2024-06-08T10:29:56.771Z",
        updatedAt: "2024-06-08T10:29:56.771Z",
      },
      {
        id: "clx5z4mu002f512vcmyd0k7ch",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "YTRwNmn6Jj",
            locked: 1717842596,
            timestamp: 1717842595,
          },
        ],
        createdAt: "2024-06-08T10:29:56.760Z",
        updatedAt: "2024-06-08T10:29:56.760Z",
      },
      {
        id: "clx5z4idp02f412vcmyzwjkcz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RTNjzgI2D1",
            locked: 1717842590,
            timestamp: 1717842589,
          },
        ],
        createdAt: "2024-06-08T10:29:50.989Z",
        updatedAt: "2024-06-08T10:29:50.989Z",
      },
      {
        id: "clx5z4hn102f312vcpenjxtsl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vPAY7wfuE7",
            locked: 1717842589,
            timestamp: 1717842588,
          },
        ],
        createdAt: "2024-06-08T10:29:50.027Z",
        updatedAt: "2024-06-08T10:29:50.027Z",
      },
      {
        id: "clx5z2iwk02f212vc88t7oiym",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.6312.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IG6D6eBOdp",
            locked: 1717842497,
            timestamp: 1717842495,
          },
        ],
        createdAt: "2024-06-08T10:28:18.354Z",
        updatedAt: "2024-06-08T10:28:18.354Z",
      },
      {
        id: "clx5z210i02f112vcsfvrdzvi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A156U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2125; samsung; SM-A156U; a15x; mt6835; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "e38uH4ZDc2",
            locked: 1717842474,
            timestamp: 1717842470,
          },
        ],
        createdAt: "2024-06-08T10:27:55.169Z",
        updatedAt: "2024-06-08T10:27:55.169Z",
      },
      {
        id: "clx5z1ype02f012vczxeogsej",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "LKZmh3Wevl",
            locked: 1717842472,
            timestamp: 1717842470,
          },
        ],
        createdAt: "2024-06-08T10:27:52.179Z",
        updatedAt: "2024-06-08T10:27:52.179Z",
      },
      {
        id: "clx5z1wmk02ez12vc4gsjlxkb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ltVsXkrhmx",
            locked: 1717842468,
            timestamp: 1717842467,
          },
        ],
        createdAt: "2024-06-08T10:27:49.484Z",
        updatedAt: "2024-06-08T10:27:49.484Z",
      },
      {
        id: "clx5z1uej02ey12vczhtx0z1f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; moto g stylus 5G - 2023 Build/T1TGNS33.60-67-6-1-1-4; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 400dpi; 1080x2170; motorola; moto g stylus 5G - 2023; genevn; qcom; en_US; 606558499)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tyym3k58uz",
            locked: 1717842466,
            timestamp: 1717842463,
          },
        ],
        createdAt: "2024-06-08T10:27:46.603Z",
        updatedAt: "2024-06-08T10:27:46.603Z",
      },
      {
        id: "clx5z1oj902ex12vc9pzwiuvt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,1; iOS 17_5_1; fi_FI; fi; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OtWKfRuFq3",
            locked: 1717842458,
            timestamp: 1717842457,
          },
        ],
        createdAt: "2024-06-08T10:27:38.994Z",
        updatedAt: "2024-06-08T10:27:38.994Z",
      },
      {
        id: "clx5z0skb02ew12vctgwgr86t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "H9vBmNllYK",
            locked: 1717842417,
            timestamp: 1717842416,
          },
        ],
        createdAt: "2024-06-08T10:26:57.560Z",
        updatedAt: "2024-06-08T10:26:57.560Z",
      },
      {
        id: "clx5z0fh802ev12vcceuhthpu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 324.0.4.26.52 (iPhone16,2; iOS 17_3_1; en_US; en; scale=3.00; 1290x2796; 580117525)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tR61iYQiDX",
            locked: 1717842400,
            timestamp: 1717842398,
          },
        ],
        createdAt: "2024-06-08T10:26:40.604Z",
        updatedAt: "2024-06-08T10:26:40.604Z",
      },
      {
        id: "clx5z0b7j02eu12vc5ttkgyia",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G991U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2194; samsung; SM-G991U; o1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vxb1ulEXsP",
            locked: 1717842394,
            timestamp: 1717842392,
          },
        ],
        createdAt: "2024-06-08T10:26:35.072Z",
        updatedAt: "2024-06-08T10:26:35.072Z",
      },
      {
        id: "clx5z08za02et12vctak7beeh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "jceGQBLAaD",
            locked: 1717842391,
            timestamp: 1717842390,
          },
        ],
        createdAt: "2024-06-08T10:26:32.181Z",
        updatedAt: "2024-06-08T10:26:32.181Z",
      },
      {
        id: "clx5z06o202es12vc2ye25s8r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "z8vYrOdZxo",
            locked: 1717842388,
            timestamp: 1717842387,
          },
        ],
        createdAt: "2024-06-08T10:26:29.186Z",
        updatedAt: "2024-06-08T10:26:29.186Z",
      },
      {
        id: "clx5yzve802er12vc604tt0v4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9nJLmn37fd",
            locked: 1717842373,
            timestamp: 1717842371,
          },
        ],
        createdAt: "2024-06-08T10:26:14.573Z",
        updatedAt: "2024-06-08T10:26:14.573Z",
      },
      {
        id: "clx5yzey802eq12vcohl4fwm7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PMzDzu0cyu",
            locked: 1717842352,
            timestamp: 1717842351,
          },
        ],
        createdAt: "2024-06-08T10:25:53.262Z",
        updatedAt: "2024-06-08T10:25:53.262Z",
      },
      {
        id: "clx5ywpjm02ep12vc0k1xm1zc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "39f3va7Sjo",
            locked: 1717842226,
            timestamp: 1717842222,
          },
        ],
        createdAt: "2024-06-08T10:23:47.024Z",
        updatedAt: "2024-06-08T10:23:47.024Z",
      },
      {
        id: "clx5yufmy02eo12vc3aec6a99",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "uTrEXopTXi",
            locked: 1717842120,
            timestamp: 1717842118,
          },
        ],
        createdAt: "2024-06-08T10:22:00.872Z",
        updatedAt: "2024-06-08T10:22:00.872Z",
      },
      {
        id: "clx5yqoir02en12vcc4jp1hmq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GMRmYUQDzO",
            locked: 1717841945,
            timestamp: 1717841943,
          },
        ],
        createdAt: "2024-06-08T10:19:05.764Z",
        updatedAt: "2024-06-08T10:19:05.764Z",
      },
      {
        id: "clx5yqicj02em12vcm9nt21y4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone14,7; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "M5SJZp6dqc",
            locked: 1717841937,
            timestamp: 1717841936,
          },
        ],
        createdAt: "2024-06-08T10:18:57.761Z",
        updatedAt: "2024-06-08T10:18:57.761Z",
      },
      {
        id: "clx5ypup502el12vcmwestpvd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36 Edg/124.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "BCoy1GdK8U",
            locked: 1717841907,
            timestamp: 1717841906,
          },
        ],
        createdAt: "2024-06-08T10:18:27.113Z",
        updatedAt: "2024-06-08T10:18:27.113Z",
      },
      {
        id: "clx5yppfn02ek12vcrynhrtox",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "qmZKwBerVr",
            locked: 1717841900,
            timestamp: 1717841899,
          },
        ],
        createdAt: "2024-06-08T10:18:20.291Z",
        updatedAt: "2024-06-08T10:18:20.291Z",
      },
      {
        id: "clx5ypmob02ej12vcg1bc3bfb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vpR0Ysvmnl",
            locked: 1717841895,
            timestamp: 1717841892,
          },
        ],
        createdAt: "2024-06-08T10:18:16.713Z",
        updatedAt: "2024-06-08T10:18:16.713Z",
      },
      {
        id: "clx5yp80d02ei12vc8694nyf3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "w5JE3cVNfc",
            locked: 1717841876,
            timestamp: 1717841874,
          },
        ],
        createdAt: "2024-06-08T10:17:57.707Z",
        updatedAt: "2024-06-08T10:17:57.707Z",
      },
      {
        id: "clx5you9a02eh12vc35t53m9c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36 Edg/124.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "riZ4DYD5uC",
            locked: 1717841859,
            timestamp: 1717841856,
          },
        ],
        createdAt: "2024-06-08T10:17:39.884Z",
        updatedAt: "2024-06-08T10:17:39.884Z",
      },
      {
        id: "clx5ymx7f02eg12vcke8r8yo4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JoSoVPfElp",
            locked: 1717841769,
            timestamp: 1717841768,
          },
        ],
        createdAt: "2024-06-08T10:16:10.393Z",
        updatedAt: "2024-06-08T10:16:10.393Z",
      },
      {
        id: "clx5ymjst02ef12vcfr8ehauf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; T767W Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 320dpi; 720x1454; TCL; T767W; Buffalo_TMO; mt6833; es_MX; 606558405)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9jVVXElL8E",
            locked: 1717841752,
            timestamp: 1717841748,
          },
        ],
        createdAt: "2024-06-08T10:15:53.019Z",
        updatedAt: "2024-06-08T10:15:53.019Z",
      },
      {
        id: "clx5ygndo02ee12vczvg2nvy5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G998U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2199; samsung; SM-G998U; p3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "q9cdZpyMEc",
            locked: 1717841477,
            timestamp: 1717841470,
          },
        ],
        createdAt: "2024-06-08T10:11:17.722Z",
        updatedAt: "2024-06-08T10:11:17.722Z",
      },
      {
        id: "clx5yeun802ed12vc4w0ohf1k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "mCIpOMjfji",
            locked: 1717841393,
            timestamp: 1717841392,
          },
        ],
        createdAt: "2024-06-08T10:09:53.828Z",
        updatedAt: "2024-06-08T10:09:53.828Z",
      },
      {
        id: "clx5yepif02ec12vcu8wvi88h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CuF84IfH5P",
            locked: 1717841386,
            timestamp: 1717841384,
          },
        ],
        createdAt: "2024-06-08T10:09:47.172Z",
        updatedAt: "2024-06-08T10:09:47.172Z",
      },
      {
        id: "clx5ydk0e02eb12vcut582jtj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G973U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 254.0.0.19.109 Android (31/12; 420dpi; 1080x2042; samsung; SM-G973U; beyond1q; qcom; en_US; 402584978)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2bTkFnXwhE",
            locked: 1717841330,
            timestamp: 1717841326,
          },
        ],
        createdAt: "2024-06-08T10:08:53.388Z",
        updatedAt: "2024-06-08T10:08:53.388Z",
      },
      {
        id: "clx5yd10d02ea12vct20vdnzy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "w3Ccu8sWaK",
            locked: 1717841307,
            timestamp: 1717841306,
          },
        ],
        createdAt: "2024-06-08T10:08:28.762Z",
        updatedAt: "2024-06-08T10:08:28.762Z",
      },
      {
        id: "clx5yaomh02e912vc30im0tft",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G75 Instagram 332.0.2.29.94 (iPhone14,8; iOS 16_6; en_US; en; scale=3.00; 1284x2778; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "6s31u03iTQ",
            locked: 1717841198,
            timestamp: 1717841196,
          },
        ],
        createdAt: "2024-06-08T10:06:39.399Z",
        updatedAt: "2024-06-08T10:06:39.399Z",
      },
      {
        id: "clx5ya04502e812vcf82ksz6d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vqZ5w4GJ32",
            locked: 1717841167,
            timestamp: 1717841165,
          },
        ],
        createdAt: "2024-06-08T10:06:07.637Z",
        updatedAt: "2024-06-08T10:06:07.637Z",
      },
      {
        id: "clx5y9t2r02e712vcr8sy7ff9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 510dpi; 1080x2106; samsung; SM-S901U1; r0q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XNDDBswyOX",
            locked: 1717841158,
            timestamp: 1717841156,
          },
        ],
        createdAt: "2024-06-08T10:05:58.513Z",
        updatedAt: "2024-06-08T10:05:58.513Z",
      },
      {
        id: "clx5y94dv02e612vcid7jh490",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G981V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 540dpi; 1080x2160; samsung; SM-G981V; x1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "eKG6klFWkr",
            locked: 1717841126,
            timestamp: 1717841124,
          },
        ],
        createdAt: "2024-06-08T10:05:26.512Z",
        updatedAt: "2024-06-08T10:05:26.512Z",
      },
      {
        id: "clx5y8rxm02e512vcvfsunuwn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N981U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 450dpi; 1080x2173; samsung; SM-N981U; c1q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YfkT6WM5J1",
            locked: 1717841109,
            timestamp: 1717841107,
          },
        ],
        createdAt: "2024-06-08T10:05:10.375Z",
        updatedAt: "2024-06-08T10:05:10.375Z",
      },
      {
        id: "clx5y842f02e412vc754gy86g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "m0kRlLwsfT",
            locked: 1717841078,
            timestamp: 1717841072,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Tvl9jy3lA6",
            locked: 1717841078,
            timestamp: 1717841076,
          },
        ],
        createdAt: "2024-06-08T10:04:39.444Z",
        updatedAt: "2024-06-08T10:04:39.444Z",
      },
      {
        id: "clx5y6i2w02e312vc7y71vidr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "1eSON4Skg3",
            locked: 1717841004,
            timestamp: 1717841003,
          },
        ],
        createdAt: "2024-06-08T10:03:24.297Z",
        updatedAt: "2024-06-08T10:03:24.297Z",
      },
      {
        id: "clx5y6app02e212vcm1213yny",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ZMR0ILVhnM",
            locked: 1717840992,
            timestamp: 1717840990,
          },
        ],
        createdAt: "2024-06-08T10:03:14.746Z",
        updatedAt: "2024-06-08T10:03:14.746Z",
      },
      {
        id: "clx5y5i7302e112vcvsdfucc9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "SI6vGZ18X9",
            locked: 1717840957,
            timestamp: 1717840953,
          },
        ],
        createdAt: "2024-06-08T10:02:37.792Z",
        updatedAt: "2024-06-08T10:02:37.792Z",
      },
      {
        id: "clx5y5deu02e012vcvw297q2z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901W Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S901W; r0q; qcom; en_CA; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "rbXN2zGcze",
            locked: 1717840951,
            timestamp: 1717840943,
          },
        ],
        createdAt: "2024-06-08T10:02:31.591Z",
        updatedAt: "2024-06-08T10:02:31.591Z",
      },
      {
        id: "clx5y57sx02dz12vchjoxfz5v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901W Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S901W; r0q; qcom; en_CA; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mNpfJv9BJ5",
            locked: 1717840942,
            timestamp: 1717840934,
          },
        ],
        createdAt: "2024-06-08T10:02:24.318Z",
        updatedAt: "2024-06-08T10:02:24.318Z",
      },
      {
        id: "clx5y3hxc02dy12vcct2titzo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "3syjqhgLvs",
            locked: 1717840864,
            timestamp: 1717840861,
          },
        ],
        createdAt: "2024-06-08T10:01:04.128Z",
        updatedAt: "2024-06-08T10:01:04.128Z",
      },
      {
        id: "clx5y3fmc02dx12vcoe588pwh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "n7udh31KpZ",
            locked: 1717840860,
            timestamp: 1717840859,
          },
        ],
        createdAt: "2024-06-08T10:01:01.138Z",
        updatedAt: "2024-06-08T10:01:01.138Z",
      },
      {
        id: "clx5y0b5p02dw12vcpvzczyw3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QcEvpansMZ",
            locked: 1717840714,
            timestamp: 1717840712,
          },
        ],
        createdAt: "2024-06-08T09:58:35.386Z",
        updatedAt: "2024-06-08T09:58:35.386Z",
      },
      {
        id: "clx5xzszo02dv12vckrijetqo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "lFojlKTDnp",
            locked: 1717840691,
            timestamp: 1717840690,
          },
        ],
        createdAt: "2024-06-08T09:58:11.844Z",
        updatedAt: "2024-06-08T09:58:11.844Z",
      },
      {
        id: "clx5xzkp502du12vcyu903egx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "m7BTN3ltr0",
            locked: 1717840680,
            timestamp: 1717840679,
          },
        ],
        createdAt: "2024-06-08T09:58:01.096Z",
        updatedAt: "2024-06-08T09:58:01.096Z",
      },
      {
        id: "clx5xzh0v02dt12vc373yssgb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "d76jsnO1Bz",
            locked: 1717840675,
            timestamp: 1717840669,
          },
        ],
        createdAt: "2024-06-08T09:57:56.335Z",
        updatedAt: "2024-06-08T09:57:56.335Z",
      },
      {
        id: "clx5xz68n02ds12vclw6khhug",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Xp5w1VAOZH",
            locked: 1717840661,
            timestamp: 1717840656,
          },
        ],
        createdAt: "2024-06-08T09:57:42.356Z",
        updatedAt: "2024-06-08T09:57:42.356Z",
      },
      {
        id: "clx5xyxps02dr12vcfcb8d3td",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 330.0.3.29.91 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0NEuTR9b72",
            locked: 1717840650,
            timestamp: 1717840649,
          },
        ],
        createdAt: "2024-06-08T09:57:31.312Z",
        updatedAt: "2024-06-08T09:57:31.312Z",
      },
      {
        id: "clx5xyski02dq12vc2kef6pz3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "2ASaQb0ged",
            locked: 1717840644,
            timestamp: 1717840643,
          },
        ],
        createdAt: "2024-06-08T09:57:24.643Z",
        updatedAt: "2024-06-08T09:57:24.643Z",
      },
      {
        id: "clx5xynat02dp12vcgw04dq9l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "btkPO22LpX",
            locked: 1717840637,
            timestamp: 1717840635,
          },
        ],
        createdAt: "2024-06-08T09:57:17.811Z",
        updatedAt: "2024-06-08T09:57:17.811Z",
      },
      {
        id: "clx5xy1s002do12vcb6w8ypft",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A236U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 301.1.0.33.110 Android (33/13; 540dpi; 1080x2181; samsung; SM-A236U; a23xq; qcom; en_US; 517986696)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DoHyyrzOTD",
            locked: 1717840609,
            timestamp: 1717840604,
          },
        ],
        createdAt: "2024-06-08T09:56:49.917Z",
        updatedAt: "2024-06-08T09:56:49.917Z",
      },
      {
        id: "clx5xs1rf02dn12vcf2birpdi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "vLsDpq7O0d",
            locked: 1717840329,
            timestamp: 1717840328,
          },
        ],
        createdAt: "2024-06-08T09:52:09.963Z",
        updatedAt: "2024-06-08T09:52:09.963Z",
      },
      {
        id: "clx5xrvsz02dm12vcoryr3don",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qXtXrYaTgq",
            locked: 1717840321,
            timestamp: 1717840320,
          },
        ],
        createdAt: "2024-06-08T09:52:02.240Z",
        updatedAt: "2024-06-08T09:52:02.240Z",
      },
      {
        id: "clx5xr96x02dl12vcunk2w27r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_3_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YDs6pc993I",
            locked: 1717840292,
            timestamp: 1717840290,
          },
        ],
        createdAt: "2024-06-08T09:51:32.935Z",
        updatedAt: "2024-06-08T09:51:32.935Z",
      },
      {
        id: "clx5xql9202dk12vcnsi8ggro",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "trGof2G9QQ",
            locked: 1717840261,
            timestamp: 1717840260,
          },
        ],
        createdAt: "2024-06-08T09:51:01.910Z",
        updatedAt: "2024-06-08T09:51:01.910Z",
      },
      {
        id: "clx5xqjwl02dj12vcmp70525z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JRCCCsanQT",
            locked: 1717840259,
            timestamp: 1717840258,
          },
        ],
        createdAt: "2024-06-08T09:51:00.161Z",
        updatedAt: "2024-06-08T09:51:00.161Z",
      },
      {
        id: "clx5xpfr002di12vci8otnh2y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A136U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 300dpi; 720x1465; samsung; SM-A136U; a13x; mt6833; en_US; 606558423)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "XkYoR49CCB",
            locked: 1717840207,
            timestamp: 1717840198,
          },
        ],
        createdAt: "2024-06-08T09:50:08.122Z",
        updatedAt: "2024-06-08T09:50:08.122Z",
      },
      {
        id: "clx5xp2aj02dh12vcky7xu8qr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "V1UOtUEQ0L",
            locked: 1717840190,
            timestamp: 1717840188,
          },
        ],
        createdAt: "2024-06-08T09:49:50.682Z",
        updatedAt: "2024-06-08T09:49:50.682Z",
      },
      {
        id: "clx5xonbs02dg12vczuie4muu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "95HLJNBLyc",
            locked: 1717840170,
            timestamp: 1717840169,
          },
        ],
        createdAt: "2024-06-08T09:49:31.285Z",
        updatedAt: "2024-06-08T09:49:31.285Z",
      },
      {
        id: "clx5xnkot02df12vc2j5j88r4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Zhd4U2rV0L",
            locked: 1717840121,
            timestamp: 1717840120,
          },
        ],
        createdAt: "2024-06-08T09:48:41.210Z",
        updatedAt: "2024-06-08T09:48:41.210Z",
      },
      {
        id: "clx5xmlbq02de12vcv78qpqzt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gOd9zQxIeb",
            locked: 1717840075,
            timestamp: 1717840062,
          },
        ],
        createdAt: "2024-06-08T09:47:55.382Z",
        updatedAt: "2024-06-08T09:47:55.382Z",
      },
      {
        id: "clx5xmiy502dd12vcfxx5b96y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 320.0.2.29.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 569998124)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "sYuCnk0oWC",
            locked: 1717840071,
            timestamp: 1717840070,
          },
        ],
        createdAt: "2024-06-08T09:47:52.299Z",
        updatedAt: "2024-06-08T09:47:52.299Z",
      },
      {
        id: "clx5xl3y902dc12vc9j1v2gsw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F731U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 480dpi; 1080x2402; samsung; SM-F731U; b5q; qcom; en_US; 604247760)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "sSlQTbujvi",
            locked: 1717840005,
            timestamp: 1717840003,
          },
        ],
        createdAt: "2024-06-08T09:46:46.207Z",
        updatedAt: "2024-06-08T09:46:46.207Z",
      },
      {
        id: "clx5xjbnj02db12vc00tri7nl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "qQPjPuBwc8",
            locked: 1717839922,
            timestamp: 1717839921,
          },
        ],
        createdAt: "2024-06-08T09:45:22.877Z",
        updatedAt: "2024-06-08T09:45:22.877Z",
      },
      {
        id: "clx5xizut02da12vcpopdqrtv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "v6vE1kLN6P",
            locked: 1717839906,
            timestamp: 1717839905,
          },
        ],
        createdAt: "2024-06-08T09:45:07.587Z",
        updatedAt: "2024-06-08T09:45:07.587Z",
      },
      {
        id: "clx5xidh002d912vce5fku21w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "5pDmmNUIIJ",
            locked: 1717839878,
            timestamp: 1717839877,
          },
        ],
        createdAt: "2024-06-08T09:44:38.578Z",
        updatedAt: "2024-06-08T09:44:38.578Z",
      },
      {
        id: "clx5xi16v02d812vci4yi5u6v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nmhXXWISq4",
            locked: 1717839862,
            timestamp: 1717839861,
          },
        ],
        createdAt: "2024-06-08T09:44:22.661Z",
        updatedAt: "2024-06-08T09:44:22.661Z",
      },
      {
        id: "clx5xguqz02d712vccm98or7n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_CH; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "QPt6pFU7Nd",
            locked: 1717839807,
            timestamp: 1717839806,
          },
        ],
        createdAt: "2024-06-08T09:43:27.659Z",
        updatedAt: "2024-06-08T09:43:27.659Z",
      },
      {
        id: "clx5xgnvm02d612vclfs1wtvr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_CH; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gsux7r39oJ",
            locked: 1717839798,
            timestamp: 1717839797,
          },
        ],
        createdAt: "2024-06-08T09:43:18.752Z",
        updatedAt: "2024-06-08T09:43:18.752Z",
      },
      {
        id: "clx5xg5az02d512vcsjeyyyqf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "e3fDdgYrzA",
            locked: 1717839773,
            timestamp: 1717839770,
          },
        ],
        createdAt: "2024-06-08T09:42:54.539Z",
        updatedAt: "2024-06-08T09:42:54.539Z",
      },
      {
        id: "clx5x7ylx02d412vc3mwgqnfw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "obDjJdT6Bq",
            locked: 1717839391,
            timestamp: 1717839390,
          },
        ],
        createdAt: "2024-06-08T09:36:32.755Z",
        updatedAt: "2024-06-08T09:36:32.755Z",
      },
      {
        id: "clx5x6ghn02d312vch64toic4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Eg7Ykt4NsC",
            locked: 1717839321,
            timestamp: 1717839319,
          },
        ],
        createdAt: "2024-06-08T09:35:22.617Z",
        updatedAt: "2024-06-08T09:35:22.617Z",
      },
      {
        id: "clx5x5ggv02d212vcl68ftj1x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qi5Mo4lQFJ",
            locked: 1717839275,
            timestamp: 1717839269,
          },
        ],
        createdAt: "2024-06-08T09:34:35.935Z",
        updatedAt: "2024-06-08T09:34:35.935Z",
      },
      {
        id: "clx5x5dtq02d112vc279snnyk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YOceyy9yx0",
            locked: 1717839271,
            timestamp: 1717839269,
          },
        ],
        createdAt: "2024-06-08T09:34:32.508Z",
        updatedAt: "2024-06-08T09:34:32.508Z",
      },
      {
        id: "clx5x4s5v02d012vc622wm82c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "u79BrxGu7z",
            locked: 1717839249,
            timestamp: 1717839248,
          },
        ],
        createdAt: "2024-06-08T09:34:04.435Z",
        updatedAt: "2024-06-08T09:34:04.435Z",
      },
      {
        id: "clx5x4jl302cz12vce4qd3fne",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "eCuzr7P0UL",
            locked: 1717839236,
            timestamp: 1717839232,
          },
        ],
        createdAt: "2024-06-08T09:33:53.317Z",
        updatedAt: "2024-06-08T09:33:53.317Z",
      },
      {
        id: "clx5x10en02cy12vcfugc0ekn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 14_7_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/18G82 Instagram 313.0.2.9.339 (iPhone12,1; iOS 14_7_1; en_US; en; scale=2.00; 828x1792; 553462334)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SjP7Y7dYNE",
            locked: 1717839068,
            timestamp: 1717839066,
          },
        ],
        createdAt: "2024-06-08T09:31:08.493Z",
        updatedAt: "2024-06-08T09:31:08.493Z",
      },
      {
        id: "clx5x0h8v02cx12vczmilxgof",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; moto g power (2022) Build/S3RQS32.20-42-10-1-3-17; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 280dpi; 720x1439; motorola; moto g power (2022); tonga; mt6765; en_US; 606558496)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "eBOTmjRoey",
            locked: 1717839041,
            timestamp: 1717839039,
          },
        ],
        createdAt: "2024-06-08T09:30:43.663Z",
        updatedAt: "2024-06-08T09:30:43.663Z",
      },
      {
        id: "clx5x0erj02cw12vc5nx5qzjw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; moto g power (2022) Build/S3RQS32.20-42-10-1-3-17; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 280dpi; 720x1439; motorola; moto g power (2022); tonga; mt6765; en_US; 606558496)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "h24FHHpkiI",
            locked: 1717839037,
            timestamp: 1717839023,
          },
        ],
        createdAt: "2024-06-08T09:30:40.445Z",
        updatedAt: "2024-06-08T09:30:40.445Z",
      },
      {
        id: "clx5wymrp02cv12vc2pf1bewi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "QwKruImx4T",
            locked: 1717838955,
            timestamp: 1717838954,
          },
        ],
        createdAt: "2024-06-08T09:29:17.509Z",
        updatedAt: "2024-06-08T09:29:17.509Z",
      },
      {
        id: "clx5wyjin02cu12vcya6vdykr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pWxavXlHZX",
            locked: 1717838951,
            timestamp: 1717838948,
          },
        ],
        createdAt: "2024-06-08T09:29:13.293Z",
        updatedAt: "2024-06-08T09:29:13.293Z",
      },
      {
        id: "clx5wu4mg02ct12vc6vhis2ng",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A325F Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2194; samsung; SM-A325F; a32; mt6769t; en_GB; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "zlArcnZxsn",
            locked: 1717838741,
            timestamp: 1717838740,
          },
        ],
        createdAt: "2024-06-08T09:25:47.368Z",
        updatedAt: "2024-06-08T09:25:47.368Z",
      },
      {
        id: "clx5wu4m902cs12vclymove33",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A325F Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2194; samsung; SM-A325F; a32; mt6769t; en_GB; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4Yq2r35oz6",
            locked: 1717838738,
            timestamp: 1717838734,
          },
        ],
        createdAt: "2024-06-08T09:25:47.359Z",
        updatedAt: "2024-06-08T09:25:47.359Z",
      },
      {
        id: "clx5wshq102cr12vccnzg60zd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.89 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "XsHHTdfkvP",
            locked: 1717838670,
            timestamp: 1717838661,
          },
        ],
        createdAt: "2024-06-08T09:24:31.030Z",
        updatedAt: "2024-06-08T09:24:31.030Z",
      },
      {
        id: "clx5wrn6e02cq12vcbhhnacum",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VkwJasxvu8",
            locked: 1717838631,
            timestamp: 1717838629,
          },
        ],
        createdAt: "2024-06-08T09:23:51.447Z",
        updatedAt: "2024-06-08T09:23:51.447Z",
      },
      {
        id: "clx5wrlbq02cp12vct6uf86rt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "OaGAUhyY3u",
            locked: 1717838662,
            timestamp: 1717838658,
          },
        ],
        createdAt: "2024-06-08T09:23:49.046Z",
        updatedAt: "2024-06-08T09:23:49.046Z",
      },
      {
        id: "clx5wrfqb02co12vcbt4v7loo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "l7U7TF8sQ8",
            locked: 1717838655,
            timestamp: 1717838652,
          },
        ],
        createdAt: "2024-06-08T09:23:41.788Z",
        updatedAt: "2024-06-08T09:23:41.788Z",
      },
      {
        id: "clx5wpu4p02cn12vcntcw79j1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G781V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 450dpi; 1080x2177; samsung; SM-G781V; r8q; qcom; en_US; 606558408)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DU733G9rmv",
            locked: 1717838546,
            timestamp: 1717838544,
          },
        ],
        createdAt: "2024-06-08T09:22:27.142Z",
        updatedAt: "2024-06-08T09:22:27.142Z",
      },
      {
        id: "clx5wplkc02cm12vcprpc0q7n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ThEy5aZPlw",
            locked: 1717838535,
            timestamp: 1717838534,
          },
        ],
        createdAt: "2024-06-08T09:22:16.045Z",
        updatedAt: "2024-06-08T09:22:16.045Z",
      },
      {
        id: "clx5wphse02cl12vc3utj6t3y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "mNjcEv7pC4",
            locked: 1717838531,
            timestamp: 1717838530,
          },
        ],
        createdAt: "2024-06-08T09:22:11.151Z",
        updatedAt: "2024-06-08T09:22:11.151Z",
      },
      {
        id: "clx5wpepp02ck12vc63f9xrq4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5CSVsjtUCG",
            locked: 1717838525,
            timestamp: 1717838524,
          },
        ],
        createdAt: "2024-06-08T09:22:07.166Z",
        updatedAt: "2024-06-08T09:22:07.166Z",
      },
      {
        id: "clx5wpad502cj12vcbw3co1jt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LCrBi32PXZ",
            locked: 1717838520,
            timestamp: 1717838519,
          },
        ],
        createdAt: "2024-06-08T09:22:01.527Z",
        updatedAt: "2024-06-08T09:22:01.527Z",
      },
      {
        id: "clx5wn70j02ci12vcelk6fg2f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "N3gGGCBQQ3",
            locked: 1717838414,
            timestamp: 1717838411,
          },
        ],
        createdAt: "2024-06-08T09:20:23.872Z",
        updatedAt: "2024-06-08T09:20:23.872Z",
      },
      {
        id: "clx5wldve02ch12vcymgkyfab",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G981V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2178; samsung; SM-G981V; x1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ShUM8Q7eJC",
            locked: 1717838339,
            timestamp: 1717838338,
          },
        ],
        createdAt: "2024-06-08T09:18:59.448Z",
        updatedAt: "2024-06-08T09:18:59.448Z",
      },
      {
        id: "clx5wks0c02cg12vcp2kg1h56",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G981V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2178; samsung; SM-G981V; x1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "EyvxHHRPZv",
            locked: 1717838310,
            timestamp: 1717838304,
          },
        ],
        createdAt: "2024-06-08T09:18:31.116Z",
        updatedAt: "2024-06-08T09:18:31.116Z",
      },
      {
        id: "clx5wkmi302cf12vcdntabs0k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "oMuj1efNNM",
            locked: 1717838304,
            timestamp: 1717838303,
          },
        ],
        createdAt: "2024-06-08T09:18:23.980Z",
        updatedAt: "2024-06-08T09:18:23.980Z",
      },
      {
        id: "clx5wkhhq02ce12vcrdd8f58c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qzPGOBcQYZ",
            locked: 1717838297,
            timestamp: 1717838294,
          },
        ],
        createdAt: "2024-06-08T09:18:17.483Z",
        updatedAt: "2024-06-08T09:18:17.483Z",
      },
      {
        id: "clx5wk0oh02cd12vc9ku3ldqq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A146U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (34/14; 450dpi; 1080x2208; samsung; SM-A146U; a14xm; mt6833; en_US; 601420827)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "z4cRHnFN30",
            locked: 1717838275,
            timestamp: 1717838271,
          },
        ],
        createdAt: "2024-06-08T09:17:55.697Z",
        updatedAt: "2024-06-08T09:17:55.697Z",
      },
      {
        id: "clx5wjzw902cc12vchgd28q02",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LmOksJGx25",
            locked: 1717838273,
            timestamp: 1717838271,
          },
        ],
        createdAt: "2024-06-08T09:17:54.679Z",
        updatedAt: "2024-06-08T09:17:54.679Z",
      },
      {
        id: "clx5wgsiz02cb12vcif2txj96",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "gSkA4ym2Gc",
            locked: 1717838124,
            timestamp: 1717838123,
          },
        ],
        createdAt: "2024-06-08T09:15:25.160Z",
        updatedAt: "2024-06-08T09:15:25.160Z",
      },
      {
        id: "clx5wgavf02ca12vcfuf4lkfv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NLuqDBF5aB",
            locked: 1717838100,
            timestamp: 1717838099,
          },
        ],
        createdAt: "2024-06-08T09:15:02.280Z",
        updatedAt: "2024-06-08T09:15:02.280Z",
      },
      {
        id: "clx5wel5l02c912vcjtqpafpo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "BAf6nf4iWM",
            locked: 1717838021,
            timestamp: 1717838020,
          },
        ],
        createdAt: "2024-06-08T09:13:42.295Z",
        updatedAt: "2024-06-08T09:13:42.295Z",
      },
      {
        id: "clx5we2hk02c812vcq1dwubap",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bXaJ1Esuy4",
            locked: 1717837997,
            timestamp: 1717837996,
          },
        ],
        createdAt: "2024-06-08T09:13:18.102Z",
        updatedAt: "2024-06-08T09:13:18.102Z",
      },
      {
        id: "clx5wdknb02c712vcz3y91qrs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UCYNmAz2y1",
            locked: 1717837973,
            timestamp: 1717837972,
          },
        ],
        createdAt: "2024-06-08T09:12:54.980Z",
        updatedAt: "2024-06-08T09:12:54.980Z",
      },
      {
        id: "clx5wb84402c612vcqa90eua5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "T56FZK5VBx",
            locked: 1717837864,
            timestamp: 1717837862,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "qKdMBg7eZ8",
            locked: 1717837864,
            timestamp: 1717837863,
          },
        ],
        createdAt: "2024-06-08T09:11:05.426Z",
        updatedAt: "2024-06-08T09:11:05.426Z",
      },
      {
        id: "clx5wa1bw02c512vc4hx1r7fj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "w3TeMmHfzq",
            locked: 1717837808,
            timestamp: 1717837795,
          },
        ],
        createdAt: "2024-06-08T09:10:09.981Z",
        updatedAt: "2024-06-08T09:10:09.981Z",
      },
      {
        id: "clx5w9uqx02c412vc1kmjpl7d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MoXSPFbwHV",
            locked: 1717837800,
            timestamp: 1717837799,
          },
        ],
        createdAt: "2024-06-08T09:10:01.445Z",
        updatedAt: "2024-06-08T09:10:01.445Z",
      },
      {
        id: "clx5w8vzn02c312vcx9mkgycx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36 OPR/109.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "pnIPCIyxo1",
            locked: 1717837755,
            timestamp: 1717837754,
          },
        ],
        createdAt: "2024-06-08T09:09:16.404Z",
        updatedAt: "2024-06-08T09:09:16.404Z",
      },
      {
        id: "clx5w8q9102c212vcoqqstkqj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36 OPR/109.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "D4U4daYTYD",
            locked: 1717837747,
            timestamp: 1717837741,
          },
        ],
        createdAt: "2024-06-08T09:09:08.962Z",
        updatedAt: "2024-06-08T09:09:08.962Z",
      },
      {
        id: "clx5w7zgp02c112vcc5twty1r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S908U; b0q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6AXWt4cu0K",
            locked: 1717837713,
            timestamp: 1717837711,
          },
        ],
        createdAt: "2024-06-08T09:08:34.249Z",
        updatedAt: "2024-06-08T09:08:34.249Z",
      },
      {
        id: "clx5w7yn702c012vc989ua9og",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uXZQe7GnDT",
            locked: 1717837712,
            timestamp: 1717837709,
          },
        ],
        createdAt: "2024-06-08T09:08:33.184Z",
        updatedAt: "2024-06-08T09:08:33.184Z",
      },
      {
        id: "clx5w70qu02bz12vc0mecut6t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6.6 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HLJPtE9RA1",
            locked: 1717837668,
            timestamp: 1717837662,
          },
        ],
        createdAt: "2024-06-08T09:07:49.253Z",
        updatedAt: "2024-06-08T09:07:49.253Z",
      },
      {
        id: "clx5w6oxg02by12vcz2a8enpp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G75 Instagram 288.1.0.16.65 (iPhone15,2; iOS 16_6; en_US; en; scale=3.00; 1179x2556; 486850870)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "4oUtLSKMsm",
            locked: 1717837653,
            timestamp: 1717837652,
          },
        ],
        createdAt: "2024-06-08T09:07:33.937Z",
        updatedAt: "2024-06-08T09:07:33.937Z",
      },
      {
        id: "clx5w64zb02bx12vcerfsdhut",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Z3778wdQrq",
            locked: 1717837628,
            timestamp: 1717837627,
          },
        ],
        createdAt: "2024-06-08T09:07:08.084Z",
        updatedAt: "2024-06-08T09:07:08.084Z",
      },
      {
        id: "clx5w5v0d02bw12vcoo1sq8r8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "u3FfE5mnVF",
            locked: 1717837615,
            timestamp: 1717837613,
          },
        ],
        createdAt: "2024-06-08T09:06:55.165Z",
        updatedAt: "2024-06-08T09:06:55.165Z",
      },
      {
        id: "clx5w5mz002bv12vcs52xwyt2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G75 Instagram 288.1.0.16.65 (iPhone15,2; iOS 16_6; en_US; en; scale=3.00; 1179x2556; 486850870)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rAyj3D7sbC",
            locked: 1717837604,
            timestamp: 1717837602,
          },
        ],
        createdAt: "2024-06-08T09:06:44.746Z",
        updatedAt: "2024-06-08T09:06:44.746Z",
      },
      {
        id: "clx5w4ty302bu12vcqdyljhdw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "wurrTsvQKK",
            locked: 1717837566,
            timestamp: 1717837564,
          },
        ],
        createdAt: "2024-06-08T09:06:07.129Z",
        updatedAt: "2024-06-08T09:06:07.129Z",
      },
      {
        id: "clx5w41vi02bt12vclar8hwli",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F721U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2402; samsung; SM-F721U; b4q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "trCdCEN1oY",
            locked: 1717837530,
            timestamp: 1717837529,
          },
        ],
        createdAt: "2024-06-08T09:05:30.748Z",
        updatedAt: "2024-06-08T09:05:30.748Z",
      },
      {
        id: "clx5w3oc002bs12vcpgc81zyt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F721U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2402; samsung; SM-F721U; b4q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zWkvlp3NmO",
            locked: 1717837512,
            timestamp: 1717837508,
          },
        ],
        createdAt: "2024-06-08T09:05:13.198Z",
        updatedAt: "2024-06-08T09:05:13.198Z",
      },
      {
        id: "clx5w0l0i02br12vc7e0njuhq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G973U1 Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 314.0.0.20.114 Android (31/12; 420dpi; 1080x2042; samsung; SM-G973U1; beyond1q; qcom; en_US; 556277190)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "twUTe6kAMC",
            locked: 1717837369,
            timestamp: 1717837363,
          },
        ],
        createdAt: "2024-06-08T09:02:48.926Z",
        updatedAt: "2024-06-08T09:02:48.926Z",
      },
      {
        id: "clx5vygpp02bq12vca4my07ob",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; RMX1911 Build/QKQ1.200209.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/114.0.5735.61 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vYQF6CVyBm",
            locked: 1717837268,
            timestamp: 1717837260,
          },
        ],
        createdAt: "2024-06-08T09:01:10.043Z",
        updatedAt: "2024-06-08T09:01:10.043Z",
      },
      {
        id: "clx5vx04o02bp12vcmt979di6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "c5vLmD4gWL",
            locked: 1717837201,
            timestamp: 1717837200,
          },
        ],
        createdAt: "2024-06-08T09:00:01.897Z",
        updatedAt: "2024-06-08T09:00:01.897Z",
      },
      {
        id: "clx5vwumj02bo12vc61y8xmb1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "E1eA1fiQLH",
            locked: 1717837194,
            timestamp: 1717837193,
          },
        ],
        createdAt: "2024-06-08T08:59:54.760Z",
        updatedAt: "2024-06-08T08:59:54.760Z",
      },
      {
        id: "clx5vw7gk02bn12vcvx67bn1u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "unh3RMC6no",
            locked: 1717837164,
            timestamp: 1717837162,
          },
        ],
        createdAt: "2024-06-08T08:59:24.738Z",
        updatedAt: "2024-06-08T08:59:24.738Z",
      },
      {
        id: "clx5vue5r02bm12vc4o31ldk7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 330.0.3.29.91 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hrurLyxgcT",
            locked: 1717837078,
            timestamp: 1717837077,
          },
        ],
        createdAt: "2024-06-08T08:58:00.108Z",
        updatedAt: "2024-06-08T08:58:00.108Z",
      },
      {
        id: "clx5vtok402bl12vc68b9fjua",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "oBUdH0bc0r",
            locked: 1717837046,
            timestamp: 1717837045,
          },
        ],
        createdAt: "2024-06-08T08:57:26.933Z",
        updatedAt: "2024-06-08T08:57:26.933Z",
      },
      {
        id: "clx5vtlvv02bk12vc6fo9842m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YerrBDtuhg",
            locked: 1717837042,
            timestamp: 1717837040,
          },
        ],
        createdAt: "2024-06-08T08:57:23.465Z",
        updatedAt: "2024-06-08T08:57:23.465Z",
      },
      {
        id: "clx5vshmw02bj12vcnzbwwsp4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_GB; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AIasCr5lmz",
            locked: 1717836989,
            timestamp: 1717836988,
          },
        ],
        createdAt: "2024-06-08T08:56:31.301Z",
        updatedAt: "2024-06-08T08:56:31.301Z",
      },
      {
        id: "clx5vrsmk02bi12vcblmuh90l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6i1WqHQB8g",
            locked: 1717836956,
            timestamp: 1717836954,
          },
        ],
        createdAt: "2024-06-08T08:55:58.889Z",
        updatedAt: "2024-06-08T08:55:58.889Z",
      },
      {
        id: "clx5vqttb02bh12vcvzi1dn2d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "DYF2LVRcFA",
            locked: 1717836913,
            timestamp: 1717836906,
          },
        ],
        createdAt: "2024-06-08T08:55:13.775Z",
        updatedAt: "2024-06-08T08:55:13.775Z",
      },
      {
        id: "clx5vqm1m02bg12vczh6s8392",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "70q2N4lhTj",
            locked: 1717836903,
            timestamp: 1717836900,
          },
        ],
        createdAt: "2024-06-08T08:55:03.703Z",
        updatedAt: "2024-06-08T08:55:03.703Z",
      },
      {
        id: "clx5vq8u202bf12vcpj44vnag",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_4_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "XueC7BIDjF",
            locked: 1717836886,
            timestamp: 1717836885,
          },
        ],
        createdAt: "2024-06-08T08:54:46.587Z",
        updatedAt: "2024-06-08T08:54:46.587Z",
      },
      {
        id: "clx5vq78502be12vcuhnyakl4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_4_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Ayrf1JlZGD",
            locked: 1717836884,
            timestamp: 1717836882,
          },
        ],
        createdAt: "2024-06-08T08:54:44.499Z",
        updatedAt: "2024-06-08T08:54:44.499Z",
      },
      {
        id: "clx5vmz9l02bd12vcrqw77t1h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G988U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2200; samsung; SM-G988U; z3q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kPUAcOzpmp",
            locked: 1717836733,
            timestamp: 1717836727,
          },
        ],
        createdAt: "2024-06-08T08:52:14.214Z",
        updatedAt: "2024-06-08T08:52:14.214Z",
      },
      {
        id: "clx5vlrdp02bc12vcz5o09jhm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hTsFSXkoit",
            locked: 1717836676,
            timestamp: 1717836675,
          },
        ],
        createdAt: "2024-06-08T08:51:17.339Z",
        updatedAt: "2024-06-08T08:51:17.339Z",
      },
      {
        id: "clx5vko1k02bb12vcwb2nr0lv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "vFx1GgSqZp",
            locked: 1717836626,
            timestamp: 1717836625,
          },
        ],
        createdAt: "2024-06-08T08:50:26.357Z",
        updatedAt: "2024-06-08T08:50:26.357Z",
      },
      {
        id: "clx5vkazk02ba12vcolkt1qfo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "r0eZf2Nyvw",
            locked: 1717836609,
            timestamp: 1717836607,
          },
        ],
        createdAt: "2024-06-08T08:50:09.437Z",
        updatedAt: "2024-06-08T08:50:09.437Z",
      },
      {
        id: "clx5vj64m02b912vcw72w90ud",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rIdgkGxnRX",
            locked: 1717836556,
            timestamp: 1717836554,
          },
        ],
        createdAt: "2024-06-08T08:49:16.486Z",
        updatedAt: "2024-06-08T08:49:16.486Z",
      },
      {
        id: "clx5vj40i02b812vcm0cj5izt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G991U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2176; samsung; SM-G991U; o1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LOJw2bZVah",
            locked: 1717836553,
            timestamp: 1717836551,
          },
        ],
        createdAt: "2024-06-08T08:49:13.745Z",
        updatedAt: "2024-06-08T08:49:13.745Z",
      },
      {
        id: "clx5vj0q902b712vcm1bbczty",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ndW1y2WETH",
            locked: 1717836547,
            timestamp: 1717836545,
          },
        ],
        createdAt: "2024-06-08T08:49:09.489Z",
        updatedAt: "2024-06-08T08:49:09.489Z",
      },
      {
        id: "clx5vipc802b612vccveqrx95",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "v1lwdXwEuU",
            locked: 1717836533,
            timestamp: 1717836521,
          },
        ],
        createdAt: "2024-06-08T08:48:54.725Z",
        updatedAt: "2024-06-08T08:48:54.725Z",
      },
      {
        id: "clx5vgpjz02b512vcodk802pu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/99.0.4844.47 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "K5j72yeFSs",
            locked: 1717836440,
            timestamp: 1717836437,
          },
        ],
        createdAt: "2024-06-08T08:47:21.693Z",
        updatedAt: "2024-06-08T08:47:21.693Z",
      },
      {
        id: "clx5vegn302b412vcardeaezv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21B91 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_1_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NSCkmC5nK0",
            locked: 1717836336,
            timestamp: 1717836334,
          },
        ],
        createdAt: "2024-06-08T08:45:36.828Z",
        updatedAt: "2024-06-08T08:45:36.828Z",
      },
      {
        id: "clx5vdqr302b312vc4m7mff4m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 327.1.6.30.88 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HZxZk1VKQK",
            locked: 1717836302,
            timestamp: 1717836301,
          },
        ],
        createdAt: "2024-06-08T08:45:03.277Z",
        updatedAt: "2024-06-08T08:45:03.277Z",
      },
      {
        id: "clx5vdf7e02b212vcrah4ntmp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 309.1.1.28.108 (iPhone15,2; iOS 16_1_2; en_US; en; scale=3.00; 1179x2556; 537288535)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "qxNNbD2X6G",
            locked: 1717836288,
            timestamp: 1717836287,
          },
        ],
        createdAt: "2024-06-08T08:44:48.315Z",
        updatedAt: "2024-06-08T08:44:48.315Z",
      },
      {
        id: "clx5vdepr02b112vcptejlvrz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 309.1.1.28.108 (iPhone15,2; iOS 16_1_2; en_US; en; scale=3.00; 1179x2556; 537288535)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rcPXGAyTZI",
            locked: 1717836287,
            timestamp: 1717836286,
          },
        ],
        createdAt: "2024-06-08T08:44:47.677Z",
        updatedAt: "2024-06-08T08:44:47.677Z",
      },
      {
        id: "clx5vcnym02b012vc0jz3b1gr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21C66 Instagram 332.0.2.29.94 (iPhone16,1; iOS 17_2_1; en_US; en; scale=3.00; 1179x2556; 600349675) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "R20dVPiOjx",
            locked: 1717836252,
            timestamp: 1717836250,
          },
        ],
        createdAt: "2024-06-08T08:44:13.006Z",
        updatedAt: "2024-06-08T08:44:13.006Z",
      },
      {
        id: "clx5vclrn02az12vcdaw65o7x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.5938.132 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fMsO9diK7X",
            locked: 1717836247,
            timestamp: 1717836245,
          },
        ],
        createdAt: "2024-06-08T08:44:10.161Z",
        updatedAt: "2024-06-08T08:44:10.161Z",
      },
      {
        id: "clx5vbsuj02ay12vc6aqcrab9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WfSWQ5QYAs",
            locked: 1717836212,
            timestamp: 1717836210,
          },
        ],
        createdAt: "2024-06-08T08:43:32.680Z",
        updatedAt: "2024-06-08T08:43:32.680Z",
      },
      {
        id: "clx5v9m5602ax12vciz0pix5q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.5938.132 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qLeLqlW6AW",
            locked: 1717836109,
            timestamp: 1717836102,
          },
        ],
        createdAt: "2024-06-08T08:41:50.680Z",
        updatedAt: "2024-06-08T08:41:50.680Z",
      },
      {
        id: "clx5v8bcm02aw12vc2qi98gf6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A536U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2168; samsung; SM-A536U; a53x; s5e8825; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2CmquR3GlP",
            locked: 1717836051,
            timestamp: 1717836043,
          },
        ],
        createdAt: "2024-06-08T08:40:50.036Z",
        updatedAt: "2024-06-08T08:40:50.036Z",
      },
      {
        id: "clx5v782102av12vcgz721yng",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 329.0.6.27.88 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 593314425)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "c7p5sMhkkG",
            locked: 1717835998,
            timestamp: 1717835996,
          },
        ],
        createdAt: "2024-06-08T08:39:59.114Z",
        updatedAt: "2024-06-08T08:39:59.114Z",
      },
      {
        id: "clx5v730702au12vciovus7z3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8F9qo7vTgp",
            locked: 1717835992,
            timestamp: 1717835988,
          },
        ],
        createdAt: "2024-06-08T08:39:52.565Z",
        updatedAt: "2024-06-08T08:39:52.565Z",
      },
      {
        id: "clx5v5zh902at12vch4djaw25",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HNTRm9pNiA",
            locked: 1717835940,
            timestamp: 1717835939,
          },
        ],
        createdAt: "2024-06-08T08:39:01.342Z",
        updatedAt: "2024-06-08T08:39:01.342Z",
      },
      {
        id: "clx5v5orp02as12vcwy36kxci",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iZTRM9SUS9",
            locked: 1717835926,
            timestamp: 1717835924,
          },
        ],
        createdAt: "2024-06-08T08:38:47.459Z",
        updatedAt: "2024-06-08T08:38:47.459Z",
      },
      {
        id: "clx5v4qtl02ar12vcds22kwvd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.5938.132 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PwZwBWt2nu",
            locked: 1717835881,
            timestamp: 1717835877,
          },
        ],
        createdAt: "2024-06-08T08:38:03.463Z",
        updatedAt: "2024-06-08T08:38:03.463Z",
      },
      {
        id: "clx5v42yc02aq12vcy66itjqk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G975U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 420dpi; 1080x2047; samsung; SM-G975U; beyond2q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HfWBNmY1o3",
            locked: 1717835851,
            timestamp: 1717835847,
          },
        ],
        createdAt: "2024-06-08T08:37:32.533Z",
        updatedAt: "2024-06-08T08:37:32.533Z",
      },
      {
        id: "clx5v41w602ap12vc0cdlneaz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2Ia5tP1jJ4",
            locked: 1717835850,
            timestamp: 1717835849,
          },
        ],
        createdAt: "2024-06-08T08:37:31.155Z",
        updatedAt: "2024-06-08T08:37:31.155Z",
      },
      {
        id: "clx5v3hlf02ao12vcdkokib89",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "32dPXb9pzj",
            locked: 1717835824,
            timestamp: 1717835821,
          },
        ],
        createdAt: "2024-06-08T08:37:04.848Z",
        updatedAt: "2024-06-08T08:37:04.848Z",
      },
      {
        id: "clx5v323v02an12vctgh8h6d9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; moto g73 5G Build/U1TNS34.82-12-7-2; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.147 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 400dpi; 1080x2163; motorola; moto g73 5G; devonf; mt6855; en_GB; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "MUrYBztpQB",
            locked: 1717835804,
            timestamp: 1717835803,
          },
        ],
        createdAt: "2024-06-08T08:36:44.780Z",
        updatedAt: "2024-06-08T08:36:44.780Z",
      },
      {
        id: "clx5v2y5w02am12vcx0knjrdm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; moto g73 5G Build/U1TNS34.82-12-7-2; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.147 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 400dpi; 1080x2163; motorola; moto g73 5G; devonf; mt6855; en_GB; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bWcpo91OCr",
            locked: 1717835798,
            timestamp: 1717835796,
          },
        ],
        createdAt: "2024-06-08T08:36:39.665Z",
        updatedAt: "2024-06-08T08:36:39.665Z",
      },
      {
        id: "clx5v0xie02al12vcjfzx6qpp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "1RTYMQxA4K",
            locked: 1717835705,
            timestamp: 1717835704,
          },
        ],
        createdAt: "2024-06-08T08:35:05.507Z",
        updatedAt: "2024-06-08T08:35:05.507Z",
      },
      {
        id: "clx5v0ii502ak12vc0fm7bjn6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XMKgGaNoiK",
            locked: 1717835685,
            timestamp: 1717835683,
          },
        ],
        createdAt: "2024-06-08T08:34:46.062Z",
        updatedAt: "2024-06-08T08:34:46.062Z",
      },
      {
        id: "clx5v0ghu02aj12vcfmfqp4hh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yuIiSp3XN4",
            locked: 1717835682,
            timestamp: 1717835676,
          },
        ],
        createdAt: "2024-06-08T08:34:43.459Z",
        updatedAt: "2024-06-08T08:34:43.459Z",
      },
      {
        id: "clx5v0acf02ai12vc5kacqqvg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D67 Instagram 327.1.6.30.88 (iPhone14,2; iOS 16_3_1; en_US; en; scale=3.00; 1170x2532; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "kaC9wZE3IU",
            locked: 1717835675,
            timestamp: 1717835674,
          },
        ],
        createdAt: "2024-06-08T08:34:35.484Z",
        updatedAt: "2024-06-08T08:34:35.484Z",
      },
      {
        id: "clx5uzvip02ah12vc65nwxyou",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RBTOzQgoT5",
            locked: 1717835655,
            timestamp: 1717835653,
          },
        ],
        createdAt: "2024-06-08T08:34:16.274Z",
        updatedAt: "2024-06-08T08:34:16.274Z",
      },
      {
        id: "clx5uzuya02ag12vcp5a2woau",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D67 Instagram 327.1.6.30.88 (iPhone14,2; iOS 16_3_1; en_US; en; scale=3.00; 1170x2532; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tZCdhmngRg",
            locked: 1717835655,
            timestamp: 1717835652,
          },
        ],
        createdAt: "2024-06-08T08:34:15.536Z",
        updatedAt: "2024-06-08T08:34:15.536Z",
      },
      {
        id: "clx5uz1ph02af12vcexwmfuhx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_SG; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ze04AWEBpv",
            locked: 1717835617,
            timestamp: 1717835615,
          },
        ],
        createdAt: "2024-06-08T08:33:37.637Z",
        updatedAt: "2024-06-08T08:33:37.637Z",
      },
      {
        id: "clx5uyx9o02ae12vczg01dzya",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_SG; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hOOUedAbFR",
            locked: 1717835607,
            timestamp: 1717835601,
          },
        ],
        createdAt: "2024-06-08T08:33:31.882Z",
        updatedAt: "2024-06-08T08:33:31.882Z",
      },
      {
        id: "clx5uxt6o02ad12vcnau51bl6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "QEqvtGzhoq",
            locked: 1717835559,
            timestamp: 1717835558,
          },
        ],
        createdAt: "2024-06-08T08:32:39.936Z",
        updatedAt: "2024-06-08T08:32:39.936Z",
      },
      {
        id: "clx5uxll002ac12vcpxdl1dfc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yefEFfNiiG",
            locked: 1717835549,
            timestamp: 1717835546,
          },
        ],
        createdAt: "2024-06-08T08:32:30.081Z",
        updatedAt: "2024-06-08T08:32:30.081Z",
      },
      {
        id: "clx5uwkhy02ab12vc21bq06dq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gCCplBlwxq",
            locked: 1717835501,
            timestamp: 1717835499,
          },
        ],
        createdAt: "2024-06-08T08:31:41.831Z",
        updatedAt: "2024-06-08T08:31:41.831Z",
      },
      {
        id: "clx5up50e02aa12vcdzj6ibi1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone14,2; iOS 16_1_2; en_GB; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "O1cn1eqUW1",
            locked: 1717835155,
            timestamp: 1717835154,
          },
        ],
        createdAt: "2024-06-08T08:25:55.355Z",
        updatedAt: "2024-06-08T08:25:55.355Z",
      },
      {
        id: "clx5unvrh02a912vcjojowa24",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "nOU32XC9qD",
            locked: 1717835096,
            timestamp: 1717835094,
          },
        ],
        createdAt: "2024-06-08T08:24:56.717Z",
        updatedAt: "2024-06-08T08:24:56.717Z",
      },
      {
        id: "clx5unm8a02a812vclzjs3epb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "09l8OnyxRP",
            locked: 1717835083,
            timestamp: 1717835082,
          },
        ],
        createdAt: "2024-06-08T08:24:44.359Z",
        updatedAt: "2024-06-08T08:24:44.359Z",
      },
      {
        id: "clx5umzhx02a712vcp96iic92",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G991U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2176; samsung; SM-G991U1; o1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fiaaW4l7hk",
            locked: 1717835054,
            timestamp: 1717835052,
          },
        ],
        createdAt: "2024-06-08T08:24:14.899Z",
        updatedAt: "2024-06-08T08:24:14.899Z",
      },
      {
        id: "clx5ul33002a612vcyj82gdli",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N976U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/122.0.6261.120 Mobile Safari/537.36 Instagram 328.0.0.42.90 Android (31/12; 560dpi; 1440x2759; samsung; SM-N976U; d2xq2; qcom; en_US; 591192881)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ZlZG2CfqtK",
            locked: 1717834966,
            timestamp: 1717834964,
          },
        ],
        createdAt: "2024-06-08T08:22:46.236Z",
        updatedAt: "2024-06-08T08:22:46.236Z",
      },
      {
        id: "clx5ul28w02a512vctwkn4ib8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N976U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/122.0.6261.120 Mobile Safari/537.36 Instagram 328.0.0.42.90 Android (31/12; 560dpi; 1440x2759; samsung; SM-N976U; d2xq2; qcom; en_US; 591192881)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "768D7jvq2g",
            locked: 1717834964,
            timestamp: 1717834957,
          },
        ],
        createdAt: "2024-06-08T08:22:45.151Z",
        updatedAt: "2024-06-08T08:22:45.151Z",
      },
      {
        id: "clx5ujpqu02a412vc5wn6s2gr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2Dhnt66P1b",
            locked: 1717834900,
            timestamp: 1717834899,
          },
        ],
        createdAt: "2024-06-08T08:21:42.292Z",
        updatedAt: "2024-06-08T08:21:42.292Z",
      },
      {
        id: "clx5uj9q702a312vcsmed2prj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QLi4dW73Uk",
            locked: 1717834881,
            timestamp: 1717834877,
          },
        ],
        createdAt: "2024-06-08T08:21:21.533Z",
        updatedAt: "2024-06-08T08:21:21.533Z",
      },
      {
        id: "clx5uge6i02a212vcfku0hdo5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N975U1 Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 480dpi; 1080x2051; samsung; SM-N975U1; d2q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "T69CJoGOPZ",
            locked: 1717834746,
            timestamp: 1717834742,
          },
        ],
        createdAt: "2024-06-08T08:19:07.335Z",
        updatedAt: "2024-06-08T08:19:07.335Z",
      },
      {
        id: "clx5uf87p02a112vchf9syuw5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F946U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 904x2105; samsung; SM-F946U1; q5q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "E65SDN7KO7",
            locked: 1717834692,
            timestamp: 1717834691,
          },
        ],
        createdAt: "2024-06-08T08:18:12.947Z",
        updatedAt: "2024-06-08T08:18:12.947Z",
      },
      {
        id: "clx5uew5c02a012vcc6lblg53",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F946U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 904x2105; samsung; SM-F946U1; q5q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2B8dYt5kqZ",
            locked: 1717834676,
            timestamp: 1717834673,
          },
        ],
        createdAt: "2024-06-08T08:17:57.310Z",
        updatedAt: "2024-06-08T08:17:57.310Z",
      },
      {
        id: "clx5ub8z6029z12vce81is7t5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "JCI92NWUgU",
            locked: 1717834507,
            timestamp: 1717834506,
          },
        ],
        createdAt: "2024-06-08T08:15:07.315Z",
        updatedAt: "2024-06-08T08:15:07.315Z",
      },
      {
        id: "clx5ub0vb029y12vcp96y5c9v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2SdTGYBDCt",
            locked: 1717834496,
            timestamp: 1717834494,
          },
        ],
        createdAt: "2024-06-08T08:14:56.804Z",
        updatedAt: "2024-06-08T08:14:56.804Z",
      },
      {
        id: "clx5u7pap029x12vculwnc38r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FBJzOJPyRE",
            locked: 1717834341,
            timestamp: 1717834340,
          },
        ],
        createdAt: "2024-06-08T08:12:21.839Z",
        updatedAt: "2024-06-08T08:12:21.839Z",
      },
      {
        id: "clx5u7g3a029w12vc3e7andte",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "TVxVc4iEbY",
            locked: 1717834329,
            timestamp: 1717834328,
          },
        ],
        createdAt: "2024-06-08T08:12:09.911Z",
        updatedAt: "2024-06-08T08:12:09.911Z",
      },
      {
        id: "clx5u7ca8029v12vcp17kt5ui",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19B81 Instagram 334.0.4.32.98 (iPhone13,2; iOS 15_1_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "v81uT7Uncr",
            locked: 1717834316,
            timestamp: 1717834315,
          },
        ],
        createdAt: "2024-06-08T08:12:04.976Z",
        updatedAt: "2024-06-08T08:12:04.976Z",
      },
      {
        id: "clx5u7c34029u12vczfg3ruo8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bFdZIY3mUS",
            locked: 1717834324,
            timestamp: 1717834322,
          },
        ],
        createdAt: "2024-06-08T08:12:04.718Z",
        updatedAt: "2024-06-08T08:12:04.718Z",
      },
      {
        id: "clx5u7113029t12vc0w0kgbtn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19B81 Instagram 334.0.4.32.98 (iPhone13,2; iOS 15_1_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "G7AxgeWVHS",
            locked: 1717834309,
            timestamp: 1717834307,
          },
        ],
        createdAt: "2024-06-08T08:11:50.391Z",
        updatedAt: "2024-06-08T08:11:50.391Z",
      },
      {
        id: "clx5u6q9r029s12vcnrb9pqk3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gWc0Kjmdxh",
            locked: 1717834295,
            timestamp: 1717834294,
          },
        ],
        createdAt: "2024-06-08T08:11:36.445Z",
        updatedAt: "2024-06-08T08:11:36.445Z",
      },
      {
        id: "clx5u666g029r12vcre4owa3g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-E146B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2301; samsung; SM-E146B; m14x; s5e8535; en_IN; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GjkXFqe9QK",
            locked: 1717834269,
            timestamp: 1717834266,
          },
        ],
        createdAt: "2024-06-08T08:11:10.406Z",
        updatedAt: "2024-06-08T08:11:10.406Z",
      },
      {
        id: "clx5u5dm8029q12vcfkbp2502",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; fr_RO; fr; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "MmKOPnT152",
            locked: 1717834233,
            timestamp: 1717834232,
          },
        ],
        createdAt: "2024-06-08T08:10:33.392Z",
        updatedAt: "2024-06-08T08:10:33.392Z",
      },
      {
        id: "clx5u56pv029p12vcu530pc2q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; fr_RO; fr; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EgwMAFqzq4",
            locked: 1717834224,
            timestamp: 1717834223,
          },
        ],
        createdAt: "2024-06-08T08:10:24.449Z",
        updatedAt: "2024-06-08T08:10:24.449Z",
      },
      {
        id: "clx5u1ovh029o12vcf5u8e0k2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 480dpi; 1080x2097; samsung; SM-S918U; dm3q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ImmxUMMDbn",
            locked: 1717834060,
            timestamp: 1717834058,
          },
        ],
        createdAt: "2024-06-08T08:07:41.358Z",
        updatedAt: "2024-06-08T08:07:41.358Z",
      },
      {
        id: "clx5u1kh9029n12vcbrnksd90",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "8PTdBGGSoy",
            locked: 1717834055,
            timestamp: 1717834054,
          },
        ],
        createdAt: "2024-06-08T08:07:35.662Z",
        updatedAt: "2024-06-08T08:07:35.662Z",
      },
      {
        id: "clx5u1j9e029m12vcxtl7a1yx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "oIOAT9vknt",
            locked: 1717834053,
            timestamp: 1717834052,
          },
        ],
        createdAt: "2024-06-08T08:07:34.079Z",
        updatedAt: "2024-06-08T08:07:34.079Z",
      },
      {
        id: "clx5u07vy029l12vclmov4hxg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1g3QmATRje",
            locked: 1717833992,
            timestamp: 1717833988,
          },
        ],
        createdAt: "2024-06-08T08:06:32.684Z",
        updatedAt: "2024-06-08T08:06:32.684Z",
      },
      {
        id: "clx5tzp1y029k12vce22kepgg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "A0F4wjXuSa",
            locked: 1717833967,
            timestamp: 1717833964,
          },
        ],
        createdAt: "2024-06-08T08:06:08.276Z",
        updatedAt: "2024-06-08T08:06:08.276Z",
      },
      {
        id: "clx5tz1jr029j12vc7eqpipdo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "7QssEmz49v",
            locked: 1717833939,
            timestamp: 1717833938,
          },
        ],
        createdAt: "2024-06-08T08:05:37.815Z",
        updatedAt: "2024-06-08T08:05:37.815Z",
      },
      {
        id: "clx5tyzpg029i12vc83t2fvyt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1nkbL1ETDN",
            locked: 1717833936,
            timestamp: 1717833932,
          },
        ],
        createdAt: "2024-06-08T08:05:35.428Z",
        updatedAt: "2024-06-08T08:05:35.428Z",
      },
      {
        id: "clx5tyuhn029h12vc6jkkmdmu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H330 Instagram 334.0.4.32.98 (iPhone10,2; iOS 16_7_7; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "vAbiRtT7ok",
            locked: 1717833928,
            timestamp: 1717833927,
          },
        ],
        createdAt: "2024-06-08T08:05:28.668Z",
        updatedAt: "2024-06-08T08:05:28.668Z",
      },
      {
        id: "clx5tyu0c029g12vcqcnueaaj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NRBQBHJaQI",
            locked: 1717833928,
            timestamp: 1717833926,
          },
        ],
        createdAt: "2024-06-08T08:05:28.042Z",
        updatedAt: "2024-06-08T08:05:28.042Z",
      },
      {
        id: "clx5ty05h029f12vc4gnbwglq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/313.0.625856595 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "C3xtnGyhMY",
            locked: 1717833889,
            timestamp: 1717833887,
          },
        ],
        createdAt: "2024-06-08T08:04:49.349Z",
        updatedAt: "2024-06-08T08:04:49.349Z",
      },
      {
        id: "clx5txyhf029e12vcx4pg8emo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "57gpajwLoA",
            locked: 1717833886,
            timestamp: 1717833885,
          },
        ],
        createdAt: "2024-06-08T08:04:47.187Z",
        updatedAt: "2024-06-08T08:04:47.187Z",
      },
      {
        id: "clx5txx4h029d12vcfesjwco4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; SM-A705YN Build/RP1A.200720.012; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 [FBAN/FB4A;FBAV/466.1.0.57.85;FBBV/605475642;FBDM/{density=2.625,width=1080,height=2198};FBLC/en_US;FBRV/606977748;FB_FW/2;FBCR/Digicel;FBMF/samsung;FBBD/samsung;FBPN/com.facebook.katana;FBDV/SM-A705YN;FBSV/11;FBOP/19;FBCA/arm64-v8a:;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RzmOaCBDU3",
            locked: 1717833885,
            timestamp: 1717833881,
          },
        ],
        createdAt: "2024-06-08T08:04:45.425Z",
        updatedAt: "2024-06-08T08:04:45.425Z",
      },
      {
        id: "clx5txvw4029c12vcknfvae5z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/313.0.625856595 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "V5E0LroMgk",
            locked: 1717833883,
            timestamp: 1717833880,
          },
        ],
        createdAt: "2024-06-08T08:04:43.827Z",
        updatedAt: "2024-06-08T08:04:43.827Z",
      },
      {
        id: "clx5twnvd029b12vcyyu0rfq9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H330 Instagram 334.0.4.32.98 (iPhone10,2; iOS 16_7_7; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "brA5v2QpVu",
            locked: 1717833826,
            timestamp: 1717833823,
          },
        ],
        createdAt: "2024-06-08T08:03:46.778Z",
        updatedAt: "2024-06-08T08:03:46.778Z",
      },
      {
        id: "clx5twi10029a12vcuqlr7gmx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 285.0.0.13.63 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 478871389)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "wJxtJGiqQR",
            locked: 1717833819,
            timestamp: 1717833818,
          },
        ],
        createdAt: "2024-06-08T08:03:39.202Z",
        updatedAt: "2024-06-08T08:03:39.202Z",
      },
      {
        id: "clx5tug12029912vcpnkvevly",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; moto g power (2022) Build/S3RQS32.20-42-10-11-2-5; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 306dpi; 720x1431; motorola; moto g power (2022); tonga; mt6765; en_US; 606558548)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "9R9KjWkfNK",
            locked: 1717833721,
            timestamp: 1717833720,
          },
        ],
        createdAt: "2024-06-08T08:02:03.299Z",
        updatedAt: "2024-06-08T08:02:03.299Z",
      },
      {
        id: "clx5ttu33029812vce9btudn8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; moto g power (2022) Build/S3RQS32.20-42-10-11-2-5; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 306dpi; 720x1431; motorola; moto g power (2022); tonga; mt6765; en_US; 606558548)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fdVg8u8rMc",
            locked: 1717833692,
            timestamp: 1717833680,
          },
        ],
        createdAt: "2024-06-08T08:01:34.861Z",
        updatedAt: "2024-06-08T08:01:34.861Z",
      },
      {
        id: "clx5tt7e1029712vcune5f652",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 285.0.0.13.63 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 478871389)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4G8LMg7asD",
            locked: 1717833664,
            timestamp: 1717833663,
          },
        ],
        createdAt: "2024-06-08T08:01:05.449Z",
        updatedAt: "2024-06-08T08:01:05.449Z",
      },
      {
        id: "clx5tswma029612vcqcjg56or",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "jIJafQdVbo",
            locked: 1717833651,
            timestamp: 1717833644,
          },
        ],
        createdAt: "2024-06-08T08:00:51.487Z",
        updatedAt: "2024-06-08T08:00:51.487Z",
      },
      {
        id: "clx5tsjel029512vctpf3lprj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "92qycjycfd",
            locked: 1717833634,
            timestamp: 1717833630,
          },
        ],
        createdAt: "2024-06-08T08:00:34.363Z",
        updatedAt: "2024-06-08T08:00:34.363Z",
      },
      {
        id: "clx5tnw47029412vcyx084vhj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Tyf6XMOJjm",
            locked: 1717833417,
            timestamp: 1717833416,
          },
        ],
        createdAt: "2024-06-08T07:56:57.559Z",
        updatedAt: "2024-06-08T07:56:57.559Z",
      },
      {
        id: "clx5tntbm029312vc6xe2arew",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VKYKvOBMMU",
            locked: 1717833413,
            timestamp: 1717833412,
          },
        ],
        createdAt: "2024-06-08T07:56:53.936Z",
        updatedAt: "2024-06-08T07:56:53.936Z",
      },
      {
        id: "clx5tmkih029212vcnp2y7cn9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/126 Mobile/15E148 Version/15.0",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "DthEPS2vX6",
            locked: 1717833355,
            timestamp: 1717833354,
          },
        ],
        createdAt: "2024-06-08T07:55:55.865Z",
        updatedAt: "2024-06-08T07:55:55.865Z",
      },
      {
        id: "clx5tme6s029112vcmncsm8qf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "RA50ZUdhKq",
            locked: 1717833347,
            timestamp: 1717833346,
          },
        ],
        createdAt: "2024-06-08T07:55:47.669Z",
        updatedAt: "2024-06-08T07:55:47.669Z",
      },
      {
        id: "clx5tmc4l029012vcllbb2vlr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/126 Mobile/15E148 Version/15.0",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "f6Zzcj1fOl",
            locked: 1717833344,
            timestamp: 1717833343,
          },
        ],
        createdAt: "2024-06-08T07:55:44.998Z",
        updatedAt: "2024-06-08T07:55:44.998Z",
      },
      {
        id: "clx5tm2t2028z12vckrrxh1tw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hRmxMkktIH",
            locked: 1717833332,
            timestamp: 1717833331,
          },
        ],
        createdAt: "2024-06-08T07:55:32.916Z",
        updatedAt: "2024-06-08T07:55:32.916Z",
      },
      {
        id: "clx5tl3h2028y12vc38jd9ux6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pN7XEPFJTG",
            locked: 1717833286,
            timestamp: 1717833285,
          },
        ],
        createdAt: "2024-06-08T07:54:47.124Z",
        updatedAt: "2024-06-08T07:54:47.124Z",
      },
      {
        id: "clx5ti8np028x12vcbo61uq07",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6oas4Z3yxM",
            locked: 1717872772,
            timestamp: 1717872771,
          },
        ],
        createdAt: "2024-06-08T07:52:33.874Z",
        updatedAt: "2024-06-08T07:52:33.874Z",
      },
      {
        id: "clx5thfbq028w12vcx4ye39kr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "owaeY7iSHl",
            locked: 1717833115,
            timestamp: 1717833113,
          },
        ],
        createdAt: "2024-06-08T07:51:55.860Z",
        updatedAt: "2024-06-08T07:51:55.860Z",
      },
      {
        id: "clx5th5py028v12vc6nknbtdu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "vizpRfd5Tj",
            locked: 1717833103,
            timestamp: 1717833102,
          },
        ],
        createdAt: "2024-06-08T07:51:43.414Z",
        updatedAt: "2024-06-08T07:51:43.414Z",
      },
      {
        id: "clx5tgwgp028u12vcsblpdlq9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "u2VMuhheyl",
            locked: 1717833091,
            timestamp: 1717833089,
          },
        ],
        createdAt: "2024-06-08T07:51:31.417Z",
        updatedAt: "2024-06-08T07:51:31.417Z",
      },
      {
        id: "clx5tgw57028t12vceielt53l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ccq6J50btv",
            locked: 1717833090,
            timestamp: 1717833088,
          },
        ],
        createdAt: "2024-06-08T07:51:31.001Z",
        updatedAt: "2024-06-08T07:51:31.001Z",
      },
      {
        id: "clx5tfdvx028s12vce0odet9n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "P3bi2DwAI0",
            locked: 1717833021,
            timestamp: 1717833019,
          },
        ],
        createdAt: "2024-06-08T07:50:20.682Z",
        updatedAt: "2024-06-08T07:50:20.682Z",
      },
      {
        id: "clx5tdx2y028r12vcxekihdtd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AKWH6OAeZ3",
            locked: 1717832951,
            timestamp: 1717832945,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "V0p01A7UCW",
            locked: 1717832951,
            timestamp: 1717832948,
          },
        ],
        createdAt: "2024-06-08T07:49:12.250Z",
        updatedAt: "2024-06-08T07:49:12.250Z",
      },
      {
        id: "clx5tdp6e028q12vckqnwff0e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "mpC1xB4EC1",
            locked: 1717832941,
            timestamp: 1717832940,
          },
        ],
        createdAt: "2024-06-08T07:49:02.003Z",
        updatedAt: "2024-06-08T07:49:02.003Z",
      },
      {
        id: "clx5tbe6b028p12vcu3xrhv7a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "waNu7iJ30s",
            locked: 1717832835,
            timestamp: 1717832834,
          },
        ],
        createdAt: "2024-06-08T07:47:14.431Z",
        updatedAt: "2024-06-08T07:47:14.431Z",
      },
      {
        id: "clx5t9jg5028o12vcq2xteg05",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "BH25KKnr6R",
            locked: 1717872367,
            timestamp: 1717872360,
          },
        ],
        createdAt: "2024-06-08T07:45:47.957Z",
        updatedAt: "2024-06-08T07:45:47.957Z",
      },
      {
        id: "clx5t9d0l028n12vci7p35vb4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "CkX2gu6EEW",
            locked: 1717832739,
            timestamp: 1717832738,
          },
        ],
        createdAt: "2024-06-08T07:45:39.619Z",
        updatedAt: "2024-06-08T07:45:39.619Z",
      },
      {
        id: "clx5t87bn028m12vc7kr412sf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "NGoej3mJWT",
            locked: 1717832685,
            timestamp: 1717832683,
          },
        ],
        createdAt: "2024-06-08T07:44:45.585Z",
        updatedAt: "2024-06-08T07:44:45.585Z",
      },
      {
        id: "clx5t7row028l12vcspy4ocj9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qkqJRbPGP5",
            locked: 1717832664,
            timestamp: 1717832663,
          },
        ],
        createdAt: "2024-06-08T07:44:25.328Z",
        updatedAt: "2024-06-08T07:44:25.328Z",
      },
      {
        id: "clx5t7kia028k12vc9rsumb8y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "t2hrJq58M0",
            locked: 1717832655,
            timestamp: 1717832648,
          },
        ],
        createdAt: "2024-06-08T07:44:16.018Z",
        updatedAt: "2024-06-08T07:44:16.018Z",
      },
      {
        id: "clx5t7iba028j12vclc79wc9f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 219.0.0.11.117 (iPhone12,8; iOS 17_5_1; en_US; en-US; scale=2.00; 750x1334; 346208226)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9aXkJORiNp",
            locked: 1717832652,
            timestamp: 1717832650,
          },
        ],
        createdAt: "2024-06-08T07:44:13.175Z",
        updatedAt: "2024-06-08T07:44:13.175Z",
      },
      {
        id: "clx5t7flm028i12vcuoyvqepa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "V60MsGl7Du",
            locked: 1717832649,
            timestamp: 1717832647,
          },
        ],
        createdAt: "2024-06-08T07:44:09.656Z",
        updatedAt: "2024-06-08T07:44:09.656Z",
      },
      {
        id: "clx5t6by9028h12vcl5w9jegf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "g8lDAKM5fL",
            locked: 1717832598,
            timestamp: 1717832597,
          },
        ],
        createdAt: "2024-06-08T07:43:18.273Z",
        updatedAt: "2024-06-08T07:43:18.273Z",
      },
      {
        id: "clx5t616s028g12vcxrkwxrqi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "WpMOT8n9rX",
            locked: 1717832584,
            timestamp: 1717832583,
          },
        ],
        createdAt: "2024-06-08T07:43:04.323Z",
        updatedAt: "2024-06-08T07:43:04.323Z",
      },
      {
        id: "clx5t5yts028f12vc0yc1h1fn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ceR9N7LEIp",
            locked: 1717832580,
            timestamp: 1717832579,
          },
        ],
        createdAt: "2024-06-08T07:43:01.264Z",
        updatedAt: "2024-06-08T07:43:01.264Z",
      },
      {
        id: "clx5t5p1j028e12vcyzjarbwq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AjzSxQxZKu",
            locked: 1717832568,
            timestamp: 1717832564,
          },
        ],
        createdAt: "2024-06-08T07:42:48.580Z",
        updatedAt: "2024-06-08T07:42:48.580Z",
      },
      {
        id: "clx5t324a028d12vcuepyxmer",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 680dpi; 1440x2955; samsung; SM-S928U; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "jol6y5zzaf",
            locked: 1717832445,
            timestamp: 1717832444,
          },
        ],
        createdAt: "2024-06-08T07:40:45.560Z",
        updatedAt: "2024-06-08T07:40:45.560Z",
      },
      {
        id: "clx5t2pba028c12vcs8qusxfd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 680dpi; 1440x2955; samsung; SM-S928U; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lBa0MYg90G",
            locked: 1717832428,
            timestamp: 1717832425,
          },
        ],
        createdAt: "2024-06-08T07:40:28.963Z",
        updatedAt: "2024-06-08T07:40:28.963Z",
      },
      {
        id: "clx5t0k20028b12vce8hlnfgp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "eG69SB2UwU",
            locked: 1717832328,
            timestamp: 1717832326,
          },
        ],
        createdAt: "2024-06-08T07:38:48.840Z",
        updatedAt: "2024-06-08T07:38:48.840Z",
      },
      {
        id: "clx5t0fp2028a12vcl3o0vh7q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "pcohakCGvB",
            locked: 1717832322,
            timestamp: 1717832321,
          },
        ],
        createdAt: "2024-06-08T07:38:43.188Z",
        updatedAt: "2024-06-08T07:38:43.188Z",
      },
      {
        id: "clx5t002o028912vcwffrndab",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "AWeZudfS4u",
            locked: 1717832301,
            timestamp: 1717832300,
          },
        ],
        createdAt: "2024-06-08T07:38:22.941Z",
        updatedAt: "2024-06-08T07:38:22.941Z",
      },
      {
        id: "clx5szpn1028812vcxcnew1c8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YiMqd2W1Mn",
            locked: 1717832285,
            timestamp: 1717832284,
          },
        ],
        createdAt: "2024-06-08T07:38:09.421Z",
        updatedAt: "2024-06-08T07:38:09.421Z",
      },
      {
        id: "clx5szhv3028712vccn167jfe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2222; samsung; SM-S928B; e3q; qcom; en_IN; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "MXzH0OawO7",
            locked: 1717832278,
            timestamp: 1717832277,
          },
        ],
        createdAt: "2024-06-08T07:37:59.343Z",
        updatedAt: "2024-06-08T07:37:59.343Z",
      },
      {
        id: "clx5szh2b028612vcfwmb0bel",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dfhsRPAmPd",
            locked: 1717832276,
            timestamp: 1717832273,
          },
        ],
        createdAt: "2024-06-08T07:37:58.307Z",
        updatedAt: "2024-06-08T07:37:58.307Z",
      },
      {
        id: "clx5szgmp028512vczxchv6yx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2222; samsung; SM-S928B; e3q; qcom; en_IN; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fHDHh9z1hL",
            locked: 1717832276,
            timestamp: 1717832274,
          },
        ],
        createdAt: "2024-06-08T07:37:57.743Z",
        updatedAt: "2024-06-08T07:37:57.743Z",
      },
      {
        id: "clx5syz1z028412vcl7fec679",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2199; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "yn3MsBZbFt",
            locked: 1717832255,
            timestamp: 1717832254,
          },
        ],
        createdAt: "2024-06-08T07:37:34.968Z",
        updatedAt: "2024-06-08T07:37:34.968Z",
      },
      {
        id: "clx5syupw028312vceapu2mvf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2199; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3XU8QmU756",
            locked: 1717832250,
            timestamp: 1717832248,
          },
        ],
        createdAt: "2024-06-08T07:37:29.346Z",
        updatedAt: "2024-06-08T07:37:29.346Z",
      },
      {
        id: "clx5sydt8028212vct751j93c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "BCiilr9IZ2",
            locked: 1717832227,
            timestamp: 1717832226,
          },
        ],
        createdAt: "2024-06-08T07:37:07.436Z",
        updatedAt: "2024-06-08T07:37:07.436Z",
      },
      {
        id: "clx5syboc028112vcxxpj3lfz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3pYCQQgAIp",
            locked: 1717832224,
            timestamp: 1717832222,
          },
        ],
        createdAt: "2024-06-08T07:37:04.660Z",
        updatedAt: "2024-06-08T07:37:04.660Z",
      },
      {
        id: "clx5sx9m7028012vcxtigfhgv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "WEX5QtfGQB",
            locked: 1717832174,
            timestamp: 1717832173,
          },
        ],
        createdAt: "2024-06-08T07:36:15.344Z",
        updatedAt: "2024-06-08T07:36:15.344Z",
      },
      {
        id: "clx5sx95t027z12vcwfdqr8kf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "faQIlMRQMA",
            locked: 1717832173,
            timestamp: 1717832171,
          },
        ],
        createdAt: "2024-06-08T07:36:14.751Z",
        updatedAt: "2024-06-08T07:36:14.751Z",
      },
      {
        id: "clx5sw5t6027y12vcnlp5iowx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G975F Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 420dpi; 1080x2047; samsung; SM-G975F; beyond2; exynos9820; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "37BYDVEwwm",
            locked: 1717832122,
            timestamp: 1717832118,
          },
        ],
        createdAt: "2024-06-08T07:35:23.754Z",
        updatedAt: "2024-06-08T07:35:23.754Z",
      },
      {
        id: "clx5sw4gn027x12vcm411xxe8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 334.0.4.32.98 (iPhone11,8; iOS 16_1_1; en_GB; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "bc4RrPXqxU",
            locked: 1717832121,
            timestamp: 1717832120,
          },
        ],
        createdAt: "2024-06-08T07:35:22.007Z",
        updatedAt: "2024-06-08T07:35:22.007Z",
      },
      {
        id: "clx5sw2ik027w12vcwqx3he5n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "vaClfAy5Hq",
            locked: 1717832119,
            timestamp: 1717832117,
          },
        ],
        createdAt: "2024-06-08T07:35:19.482Z",
        updatedAt: "2024-06-08T07:35:19.482Z",
      },
      {
        id: "clx5svtg5027v12vcrxd4kx6f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "awMHXWwZY6",
            locked: 1717832107,
            timestamp: 1717832105,
          },
        ],
        createdAt: "2024-06-08T07:35:07.734Z",
        updatedAt: "2024-06-08T07:35:07.734Z",
      },
      {
        id: "clx5svnr6027u12vciardzvdf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 334.0.4.32.98 (iPhone11,8; iOS 16_1_1; en_GB; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AS7uIEe6GN",
            locked: 1717832099,
            timestamp: 1717832097,
          },
        ],
        createdAt: "2024-06-08T07:35:00.350Z",
        updatedAt: "2024-06-08T07:35:00.350Z",
      },
      {
        id: "clx5sto8q027t12vcijnj2pxg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vCRrqY82BN",
            locked: 1717832005,
            timestamp: 1717832003,
          },
        ],
        createdAt: "2024-06-08T07:33:27.671Z",
        updatedAt: "2024-06-08T07:33:27.671Z",
      },
      {
        id: "clx5sr5jg027s12vc644h029d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "u3xkqPGJBr",
            locked: 1717831890,
            timestamp: 1717831889,
          },
        ],
        createdAt: "2024-06-08T07:31:30.125Z",
        updatedAt: "2024-06-08T07:31:30.125Z",
      },
      {
        id: "clx5sr3cl027r12vcxep3ozda",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FRSUKwxtqf",
            locked: 1717831886,
            timestamp: 1717831885,
          },
        ],
        createdAt: "2024-06-08T07:31:27.283Z",
        updatedAt: "2024-06-08T07:31:27.283Z",
      },
      {
        id: "clx5sq7r2027q12vc4cbr1bpp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "RcMdIRnuj8",
            locked: 1717831846,
            timestamp: 1717831845,
          },
        ],
        createdAt: "2024-06-08T07:30:46.334Z",
        updatedAt: "2024-06-08T07:30:46.334Z",
      },
      {
        id: "clx5sq5t2027p12vcnleomyoz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AJo3Q7R3p7",
            locked: 1717831843,
            timestamp: 1717831837,
          },
        ],
        createdAt: "2024-06-08T07:30:43.812Z",
        updatedAt: "2024-06-08T07:30:43.812Z",
      },
      {
        id: "clx5sp4qf027o12vc86jk8gui",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ACl2vnu5jx",
            locked: 1717831795,
            timestamp: 1717831793,
          },
        ],
        createdAt: "2024-06-08T07:29:55.765Z",
        updatedAt: "2024-06-08T07:29:55.765Z",
      },
      {
        id: "clx5soflk027n12vci64ao4d8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "phhRuJR01U",
            locked: 1717831761,
            timestamp: 1717831758,
          },
        ],
        createdAt: "2024-06-08T07:29:23.190Z",
        updatedAt: "2024-06-08T07:29:23.190Z",
      },
      {
        id: "clx5snd87027m12vcngmlhvxz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "angycksekt",
            locked: 1717831713,
            timestamp: 1717831711,
          },
        ],
        createdAt: "2024-06-08T07:28:33.463Z",
        updatedAt: "2024-06-08T07:28:33.463Z",
      },
      {
        id: "clx5snb2x027l12vcmderoy0r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GH0RDlRkkU",
            locked: 1717831710,
            timestamp: 1717831708,
          },
        ],
        createdAt: "2024-06-08T07:28:30.681Z",
        updatedAt: "2024-06-08T07:28:30.681Z",
      },
      {
        id: "clx5sna9t027k12vc8jn04x7f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; moto g stylus 5G Build/RRES31.Q3-60-47-2-2-2; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (30/11; 446dpi; 1080x2163; motorola; moto g stylus 5G; denver; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "VLt7ht2KNO",
            locked: 1717831710,
            timestamp: 1717831709,
          },
        ],
        createdAt: "2024-06-08T07:28:29.630Z",
        updatedAt: "2024-06-08T07:28:29.630Z",
      },
      {
        id: "clx5smhgf027j12vc00pbg6vj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; moto g stylus 5G Build/RRES31.Q3-60-47-2-2-2; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (30/11; 446dpi; 1080x2163; motorola; moto g stylus 5G; denver; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "cI2AmPpZto",
            locked: 1717831672,
            timestamp: 1717831667,
          },
        ],
        createdAt: "2024-06-08T07:27:52.287Z",
        updatedAt: "2024-06-08T07:27:52.287Z",
      },
      {
        id: "clx5smai9027i12vccr3du4tf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; moto g stylus 5G (2022) Build/T2SDS33.75-38-1-3-14; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 331.0.0.37.90 Android (33/13; 400dpi; 1080x2225; motorola; moto g stylus 5G (2022); milanf; qcom; en_US; 598808631)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CmdYBfHgTB",
            locked: 1717831662,
            timestamp: 1717831657,
          },
        ],
        createdAt: "2024-06-08T07:27:43.278Z",
        updatedAt: "2024-06-08T07:27:43.278Z",
      },
      {
        id: "clx5slh08027h12vc3pvq7n9m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "H20QA2dKjE",
            locked: 1717831620,
            timestamp: 1717831619,
          },
        ],
        createdAt: "2024-06-08T07:27:05.047Z",
        updatedAt: "2024-06-08T07:27:05.047Z",
      },
      {
        id: "clx5sld0f027g12vc7nrxe6qr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "sZEvxCJRH7",
            locked: 1717831619,
            timestamp: 1717831618,
          },
        ],
        createdAt: "2024-06-08T07:26:59.871Z",
        updatedAt: "2024-06-08T07:26:59.871Z",
      },
      {
        id: "clx5slcny027f12vc5kv2yrnq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tdRn0tZF0M",
            locked: 1717831614,
            timestamp: 1717831612,
          },
        ],
        createdAt: "2024-06-08T07:26:59.422Z",
        updatedAt: "2024-06-08T07:26:59.422Z",
      },
      {
        id: "clx5sl1yp027e12vc6ztnurjz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ijprPnClcJ",
            locked: 1717831601,
            timestamp: 1717831600,
          },
        ],
        createdAt: "2024-06-08T07:26:45.551Z",
        updatedAt: "2024-06-08T07:26:45.551Z",
      },
      {
        id: "clx5skjzs027d12vcby6grew7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "r1yRy0Oxfl",
            locked: 1717831582,
            timestamp: 1717831577,
          },
        ],
        createdAt: "2024-06-08T07:26:22.261Z",
        updatedAt: "2024-06-08T07:26:22.261Z",
      },
      {
        id: "clx5si5sa027c12vchvs4idnf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EGKlozwtiB",
            locked: 1717831469,
            timestamp: 1717831467,
          },
        ],
        createdAt: "2024-06-08T07:24:30.535Z",
        updatedAt: "2024-06-08T07:24:30.535Z",
      },
      {
        id: "clx5shgjx027b12vcisxc6jgd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "uMEj33MAqj",
            locked: 1717831437,
            timestamp: 1717831436,
          },
        ],
        createdAt: "2024-06-08T07:23:57.835Z",
        updatedAt: "2024-06-08T07:23:57.835Z",
      },
      {
        id: "clx5sgiuf027a12vc0hxpe0xt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VWaCjxWQQW",
            locked: 1717831393,
            timestamp: 1717831392,
          },
        ],
        createdAt: "2024-06-08T07:23:14.148Z",
        updatedAt: "2024-06-08T07:23:14.148Z",
      },
      {
        id: "clx5sfu3p027912vcjujpv5y8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5UM9mC9el4",
            locked: 1717831409,
            timestamp: 1717831406,
          },
        ],
        createdAt: "2024-06-08T07:22:42.084Z",
        updatedAt: "2024-06-08T07:22:42.084Z",
      },
      {
        id: "clx5sdlr0027812vc7wqzs67c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "GA0vwAjLID",
            locked: 1717831257,
            timestamp: 1717831256,
          },
        ],
        createdAt: "2024-06-08T07:20:57.948Z",
        updatedAt: "2024-06-08T07:20:57.948Z",
      },
      {
        id: "clx5sdcp9027712vc5tums4l6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lPHwICQVlc",
            locked: 1717831245,
            timestamp: 1717831243,
          },
        ],
        createdAt: "2024-06-08T07:20:46.221Z",
        updatedAt: "2024-06-08T07:20:46.221Z",
      },
      {
        id: "clx5sdady027612vc0xagedzy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; HD1925 Build/SKQ1.220303.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 331.0.0.37.90 Android (31/12; 560dpi; 1440x2966; OnePlus; HD1925; OnePlus7TProNR; qcom; en_US; 598808625)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1pnvtFeh1B",
            locked: 1717831242,
            timestamp: 1717831237,
          },
        ],
        createdAt: "2024-06-08T07:20:43.220Z",
        updatedAt: "2024-06-08T07:20:43.220Z",
      },
      {
        id: "clx5scmf5027512vczhhrrd94",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "QY84PE2Lms",
            locked: 1717831212,
            timestamp: 1717831210,
          },
        ],
        createdAt: "2024-06-08T07:20:12.161Z",
        updatedAt: "2024-06-08T07:20:12.161Z",
      },
      {
        id: "clx5scjya027412vc4julgri6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ItVcNat7iM",
            locked: 1717831208,
            timestamp: 1717831204,
          },
        ],
        createdAt: "2024-06-08T07:20:08.960Z",
        updatedAt: "2024-06-08T07:20:08.960Z",
      },
      {
        id: "clx5sbbpe027312vc49hsyb2h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "MsOyTmeaxn",
            locked: 1717831151,
            timestamp: 1717831150,
          },
        ],
        createdAt: "2024-06-08T07:19:11.617Z",
        updatedAt: "2024-06-08T07:19:11.617Z",
      },
      {
        id: "clx5sb9vt027212vcsvybrm78",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FAmX09KxFh",
            locked: 1717831148,
            timestamp: 1717831146,
          },
        ],
        createdAt: "2024-06-08T07:19:09.257Z",
        updatedAt: "2024-06-08T07:19:09.257Z",
      },
      {
        id: "clx5sayii027112vc9mp30iu6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Dalvik/2.1.0 (Linux; U; Android 12; TECNO CH6 Build/SP1A.210812.016) [FBAN/Orca-Android;FBAV/460.0.0.48.109;FBPN/com.facebook.orca;FBLC/en_US;FBBV/604345580;FBCR/MTN;FBMF/TECNO MOBILE LIMITED;FBBD/TECNO;FBDV/TECNO CH6;FBSV/12;FBCA/arm64-v8a:null;FBDM/{density=3.0,width=1080,height=2351};FB_FW/1;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QnO3cbwgMg",
            locked: 1717831134,
            timestamp: 1717831130,
          },
        ],
        createdAt: "2024-06-08T07:18:54.520Z",
        updatedAt: "2024-06-08T07:18:54.520Z",
      },
      {
        id: "clx5sabwv027012vcut7w8zzf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "5PXyxQ3WSn",
            locked: 1717831105,
            timestamp: 1717831104,
          },
        ],
        createdAt: "2024-06-08T07:18:25.231Z",
        updatedAt: "2024-06-08T07:18:25.231Z",
      },
      {
        id: "clx5sa5rl026z12vcpgfuooev",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Ls8vkyQT9B",
            locked: 1717831096,
            timestamp: 1717831095,
          },
        ],
        createdAt: "2024-06-08T07:18:17.262Z",
        updatedAt: "2024-06-08T07:18:17.262Z",
      },
      {
        id: "clx5s8gg5026y12vccp7ltq4d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Tenqk1HoRK",
            locked: 1717831016,
            timestamp: 1717831013,
          },
        ],
        createdAt: "2024-06-08T07:16:57.794Z",
        updatedAt: "2024-06-08T07:16:57.794Z",
      },
      {
        id: "clx5s7uuh026x12vc6urqvtyk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "YqmDGFM8Bf",
            locked: 1717830989,
            timestamp: 1717830988,
          },
        ],
        createdAt: "2024-06-08T07:16:29.798Z",
        updatedAt: "2024-06-08T07:16:29.798Z",
      },
      {
        id: "clx5s7dde026w12vczw84c1tv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JEzaII6Vm3",
            locked: 1717830966,
            timestamp: 1717830962,
          },
        ],
        createdAt: "2024-06-08T07:16:07.151Z",
        updatedAt: "2024-06-08T07:16:07.151Z",
      },
      {
        id: "clx5s6jsy026v12vc5034oede",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G75 Instagram 332.0.2.29.94 (iPhone14,4; iOS 16_6; en_US; en; scale=3.00; 960x2079; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7x6lwdRq0K",
            locked: 1717830928,
            timestamp: 1717830926,
          },
        ],
        createdAt: "2024-06-08T07:15:28.831Z",
        updatedAt: "2024-06-08T07:15:28.831Z",
      },
      {
        id: "clx5s4wqc026u12vcemniqju5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Shop App/2.159.0/iOS/17.5.1/WebView (surface=storefront)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Bf1TUaLDmG",
            locked: 1717830852,
            timestamp: 1717830851,
          },
        ],
        createdAt: "2024-06-08T07:14:12.276Z",
        updatedAt: "2024-06-08T07:14:12.276Z",
      },
      {
        id: "clx5s4up4026t12vcqwn1t8yb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Shop App/2.159.0/iOS/17.5.1/WebView (surface=storefront)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gJsWpRvLMh",
            locked: 1717830849,
            timestamp: 1717830848,
          },
        ],
        createdAt: "2024-06-08T07:14:09.638Z",
        updatedAt: "2024-06-08T07:14:09.638Z",
      },
      {
        id: "clx5s3sf4026s12vciestb79x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; es_US; es; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "81lyb4QDn6",
            locked: 1717830799,
            timestamp: 1717830798,
          },
        ],
        createdAt: "2024-06-08T07:13:20.032Z",
        updatedAt: "2024-06-08T07:13:20.032Z",
      },
      {
        id: "clx5s3m88026r12vcpbqgjdhu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HOgsL2c4DU",
            locked: 1717830791,
            timestamp: 1717830788,
          },
        ],
        createdAt: "2024-06-08T07:13:12.005Z",
        updatedAt: "2024-06-08T07:13:12.005Z",
      },
      {
        id: "clx5s2ajj026q12vc6qa7bddn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 334.0.4.32.98 (iPhone14,2; iOS 16_1_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "txbh8kCqv4",
            locked: 1717830729,
            timestamp: 1717830728,
          },
        ],
        createdAt: "2024-06-08T07:12:10.204Z",
        updatedAt: "2024-06-08T07:12:10.204Z",
      },
      {
        id: "clx5s172k026p12vchz59g9yz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20E252 Instagram 333.1.4.32.95 (iPhone12,8; iOS 16_4_1; en_US; en; scale=2.00; 750x1334; 604797909)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DXKgBRlfaA",
            locked: 1717830677,
            timestamp: 1717830676,
          },
        ],
        createdAt: "2024-06-08T07:11:19.050Z",
        updatedAt: "2024-06-08T07:11:19.050Z",
      },
      {
        id: "clx5s0z7v026o12vcl4a3sfwf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "yOu9uCNc9P",
            locked: 1717830668,
            timestamp: 1717830667,
          },
        ],
        createdAt: "2024-06-08T07:11:08.876Z",
        updatedAt: "2024-06-08T07:11:08.876Z",
      },
      {
        id: "clx5s0sm7026n12vctbgkgp1x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PHBoTBtkmy",
            locked: 1717830660,
            timestamp: 1717830657,
          },
        ],
        createdAt: "2024-06-08T07:11:00.316Z",
        updatedAt: "2024-06-08T07:11:00.316Z",
      },
      {
        id: "clx5rzb7s026m12vc5sjf7xm3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; CrOS x86_64 14541.0.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XNTwre6lOb",
            locked: 1717830589,
            timestamp: 1717830587,
          },
        ],
        createdAt: "2024-06-08T07:09:51.110Z",
        updatedAt: "2024-06-08T07:09:51.110Z",
      },
      {
        id: "clx5rwajh026l12vc2ge8hc25",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "66BKDlMS2z",
            locked: 1717830450,
            timestamp: 1717830449,
          },
        ],
        createdAt: "2024-06-08T07:07:30.268Z",
        updatedAt: "2024-06-08T07:07:30.268Z",
      },
      {
        id: "clx5rw6rh026k12vcsuwji5sa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iJEjGyUPEk",
            locked: 1717830445,
            timestamp: 1717830443,
          },
        ],
        createdAt: "2024-06-08T07:07:25.373Z",
        updatedAt: "2024-06-08T07:07:25.373Z",
      },
      {
        id: "clx5rvxxe026j12vc74y45x2w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "j4FbiPIoHF",
            locked: 1717830433,
            timestamp: 1717830429,
          },
        ],
        createdAt: "2024-06-08T07:07:13.920Z",
        updatedAt: "2024-06-08T07:07:13.920Z",
      },
      {
        id: "clx5rtztq026i12vcref51nig",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RbVQEOJeGq",
            locked: 1717830342,
            timestamp: 1717830339,
          },
        ],
        createdAt: "2024-06-08T07:05:43.068Z",
        updatedAt: "2024-06-08T07:05:43.068Z",
      },
      {
        id: "clx5rt4vs026h12vcjxxo4b4i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ENisgKCudx",
            locked: 1717830302,
            timestamp: 1717830301,
          },
        ],
        createdAt: "2024-06-08T07:05:02.968Z",
        updatedAt: "2024-06-08T07:05:02.968Z",
      },
      {
        id: "clx5rt0c8026g12vclg1vazmu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rxANFu315r",
            locked: 1717830296,
            timestamp: 1717830293,
          },
        ],
        createdAt: "2024-06-08T07:04:57.077Z",
        updatedAt: "2024-06-08T07:04:57.077Z",
      },
      {
        id: "clx5rp6ks026f12vc4w62092j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "2Garcqqj2T",
            locked: 1717830118,
            timestamp: 1717830116,
          },
        ],
        createdAt: "2024-06-08T07:01:58.540Z",
        updatedAt: "2024-06-08T07:01:58.540Z",
      },
      {
        id: "clx5roz41026e12vc1uzllf1b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "mriEwokw1i",
            locked: 1717830108,
            timestamp: 1717830106,
          },
        ],
        createdAt: "2024-06-08T07:01:48.865Z",
        updatedAt: "2024-06-08T07:01:48.865Z",
      },
      {
        id: "clx5rovkw026d12vc7e34w3rk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "EhmOV9Iqni",
            locked: 1717830103,
            timestamp: 1717830102,
          },
        ],
        createdAt: "2024-06-08T07:01:44.200Z",
        updatedAt: "2024-06-08T07:01:44.200Z",
      },
      {
        id: "clx5rofre026c12vcnb876ska",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DqQeInm5dk",
            locked: 1717830083,
            timestamp: 1717830082,
          },
        ],
        createdAt: "2024-06-08T07:01:23.784Z",
        updatedAt: "2024-06-08T07:01:23.784Z",
      },
      {
        id: "clx5rlthl026b12vc9gikch6r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S911U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 324.0.0.27.50 Android (34/14; 480dpi; 1080x2115; samsung; SM-S911U; dm1q; qcom; en_US; 581020724)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "aCIR4dHJ71",
            locked: 1717829961,
            timestamp: 1717829960,
          },
        ],
        createdAt: "2024-06-08T06:59:21.607Z",
        updatedAt: "2024-06-08T06:59:21.607Z",
      },
      {
        id: "clx5rldp8026a12vcb5r8sfyh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S911U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 324.0.0.27.50 Android (34/14; 480dpi; 1080x2115; samsung; SM-S911U; dm1q; qcom; en_US; 581020724)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MNacAGTjL5",
            locked: 1717829940,
            timestamp: 1717829938,
          },
        ],
        createdAt: "2024-06-08T06:59:01.146Z",
        updatedAt: "2024-06-08T06:59:01.146Z",
      },
      {
        id: "clx5rjgam026912vcj13k4kyx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Ndvzqz9YdL",
            locked: 1717829850,
            timestamp: 1717829849,
          },
        ],
        createdAt: "2024-06-08T06:57:31.196Z",
        updatedAt: "2024-06-08T06:57:31.196Z",
      },
      {
        id: "clx5rixjv026812vcbf1xbqbo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NobcVMyecD",
            locked: 1717829825,
            timestamp: 1717829824,
          },
        ],
        createdAt: "2024-06-08T06:57:06.905Z",
        updatedAt: "2024-06-08T06:57:06.905Z",
      },
      {
        id: "clx5rhezn026712vclqu96hl0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "t9XiwFMMX5",
            locked: 1717829755,
            timestamp: 1717829754,
          },
        ],
        createdAt: "2024-06-08T06:55:56.193Z",
        updatedAt: "2024-06-08T06:55:56.193Z",
      },
      {
        id: "clx5rewpj026612vcj76pulre",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 510dpi; 1080x2111; samsung; SM-S928U; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "zqSJ2V5Ec0",
            locked: 1717829639,
            timestamp: 1717829638,
          },
        ],
        createdAt: "2024-06-08T06:53:59.189Z",
        updatedAt: "2024-06-08T06:53:59.189Z",
      },
      {
        id: "clx5reooh026512vc8zao6tdr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 510dpi; 1080x2111; samsung; SM-S928U; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0qSXkq5ZR1",
            locked: 1717829628,
            timestamp: 1717829625,
          },
        ],
        createdAt: "2024-06-08T06:53:48.786Z",
        updatedAt: "2024-06-08T06:53:48.786Z",
      },
      {
        id: "clx5reiyd026412vcdtj8fjq7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 331.1.3.29.90 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QmyHqPqFJR",
            locked: 1717829620,
            timestamp: 1717829619,
          },
        ],
        createdAt: "2024-06-08T06:53:41.363Z",
        updatedAt: "2024-06-08T06:53:41.363Z",
      },
      {
        id: "clx5rclwv026312vcc97v4lrk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "3PwbBhy0iK",
            locked: 1717829531,
            timestamp: 1717829530,
          },
        ],
        createdAt: "2024-06-08T06:52:11.888Z",
        updatedAt: "2024-06-08T06:52:11.888Z",
      },
      {
        id: "clx5rckog026212vccodlz9ui",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pi0itUdRrr",
            locked: 1717829529,
            timestamp: 1717829528,
          },
        ],
        createdAt: "2024-06-08T06:52:10.285Z",
        updatedAt: "2024-06-08T06:52:10.285Z",
      },
      {
        id: "clx5rantm026112vcgrv7asi4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPad14,5; iPadOS 17_5_1; en_US; en; scale=2.00; 780x1688; 604797909)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0k86F93AjX",
            locked: 1717829439,
            timestamp: 1717829438,
          },
        ],
        createdAt: "2024-06-08T06:50:41.048Z",
        updatedAt: "2024-06-08T06:50:41.048Z",
      },
      {
        id: "clx5r9e8k026012vckfm08zmq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20C65 Instagram 334.0.4.32.98 (iPhone11,8; iOS 16_2; en_US; en; scale=2.00; 750x1624; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yxzc8yi388",
            locked: 1717829381,
            timestamp: 1717829379,
          },
        ],
        createdAt: "2024-06-08T06:49:41.970Z",
        updatedAt: "2024-06-08T06:49:41.970Z",
      },
      {
        id: "clx5r8lno025z12vccvwwies1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "diGO6MBhIa",
            locked: 1717829340,
            timestamp: 1717829337,
          },
        ],
        createdAt: "2024-06-08T06:49:04.932Z",
        updatedAt: "2024-06-08T06:49:04.932Z",
      },
      {
        id: "clx5r8lmz025y12vcflhew6vb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A515U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2186; samsung; SM-A515U; a51; exynos9611; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tTcO5OLQju",
            locked: 1717829344,
            timestamp: 1717829335,
          },
        ],
        createdAt: "2024-06-08T06:49:04.904Z",
        updatedAt: "2024-06-08T06:49:04.904Z",
      },
      {
        id: "clx5r8c1x025x12vcpsyt76kr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "7pWe9TuEss",
            locked: 1717829332,
            timestamp: 1717829331,
          },
        ],
        createdAt: "2024-06-08T06:48:52.486Z",
        updatedAt: "2024-06-08T06:48:52.486Z",
      },
      {
        id: "clx5r85in025w12vcywx15q6q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; 2107113SG Build/SKQ1.211006.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/466.1.0.57.85;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "loPhvQ0eA5",
            locked: 1717829323,
            timestamp: 1717829319,
          },
        ],
        createdAt: "2024-06-08T06:48:44.012Z",
        updatedAt: "2024-06-08T06:48:44.012Z",
      },
      {
        id: "clx5r7k2l025v12vcnigs79z1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Q7Bgbymewb",
            locked: 1717829295,
            timestamp: 1717829294,
          },
        ],
        createdAt: "2024-06-08T06:48:16.221Z",
        updatedAt: "2024-06-08T06:48:16.221Z",
      },
      {
        id: "clx5r7ey6025u12vcs6ku2qcw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MvAah88wjy",
            locked: 1717829289,
            timestamp: 1717829287,
          },
        ],
        createdAt: "2024-06-08T06:48:09.580Z",
        updatedAt: "2024-06-08T06:48:09.580Z",
      },
      {
        id: "clx5r679j025t12vc4163hfsh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "G11OMU6in0",
            locked: 1717829232,
            timestamp: 1717829231,
          },
        ],
        createdAt: "2024-06-08T06:47:12.964Z",
        updatedAt: "2024-06-08T06:47:12.964Z",
      },
      {
        id: "clx5r3057025s12vcl9tlspui",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/18B121 Instagram 285.0.0.13.63 (iPhone13,2; iOS 14_2_1; en_US; en; scale=3.00; 960x2079; 478871389)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "dYGR6tHg1K",
            locked: 1717829083,
            timestamp: 1717829082,
          },
        ],
        createdAt: "2024-06-08T06:44:43.771Z",
        updatedAt: "2024-06-08T06:44:43.771Z",
      },
      {
        id: "clx5r2x8i025r12vczhpv67g2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/18B121 Instagram 285.0.0.13.63 (iPhone13,2; iOS 14_2_1; en_US; en; scale=3.00; 960x2079; 478871389)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "aT6Y0hpmuv",
            locked: 1717829079,
            timestamp: 1717829077,
          },
        ],
        createdAt: "2024-06-08T06:44:40.000Z",
        updatedAt: "2024-06-08T06:44:40.000Z",
      },
      {
        id: "clx5r2381025q12vctxxza3vw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad13,1; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8S21luQI5N",
            locked: 1717829040,
            timestamp: 1717829039,
          },
        ],
        createdAt: "2024-06-08T06:44:01.102Z",
        updatedAt: "2024-06-08T06:44:01.102Z",
      },
      {
        id: "clx5qwf12025p12vc4rfosrgh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "oqnPYDMS8Q",
            locked: 1717828775,
            timestamp: 1717828774,
          },
        ],
        createdAt: "2024-06-08T06:39:36.468Z",
        updatedAt: "2024-06-08T06:39:36.468Z",
      },
      {
        id: "clx5qu68q025o12vctvvxt9bl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2105; samsung; SM-N986U; c2q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4urJZCUSz9",
            locked: 1717828671,
            timestamp: 1717828668,
          },
        ],
        createdAt: "2024-06-08T06:37:51.770Z",
        updatedAt: "2024-06-08T06:37:51.770Z",
      },
      {
        id: "clx5qu12n025n12vcppvibtxu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 323.0.3.23.54 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 577210397) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LHLgYudJ1L",
            locked: 1717828664,
            timestamp: 1717828663,
          },
        ],
        createdAt: "2024-06-08T06:37:45.068Z",
        updatedAt: "2024-06-08T06:37:45.068Z",
      },
      {
        id: "clx5qscqs025m12vcwb4rd3ew",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 312.0.1.19.124 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 548339486)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "I1I5Ug5ttF",
            locked: 1717828586,
            timestamp: 1717828584,
          },
        ],
        createdAt: "2024-06-08T06:36:26.882Z",
        updatedAt: "2024-06-08T06:36:26.882Z",
      },
      {
        id: "clx5qrhxp025l12vcfdqftwe7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zF61Bi7Igf",
            locked: 1717828546,
            timestamp: 1717828545,
          },
        ],
        createdAt: "2024-06-08T06:35:46.958Z",
        updatedAt: "2024-06-08T06:35:46.958Z",
      },
      {
        id: "clx5qrbq6025k12vc435pre0s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,1; iOS 16_7_8; en_US; en; scale=2.00; 640x1136; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5Z87zbUVYt",
            locked: 1717828538,
            timestamp: 1717828535,
          },
        ],
        createdAt: "2024-06-08T06:35:38.910Z",
        updatedAt: "2024-06-08T06:35:38.910Z",
      },
      {
        id: "clx5qr9qd025j12vc233qa6oj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPad7,3; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 600349675)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UKMTQvtm6U",
            locked: 1717828535,
            timestamp: 1717828532,
          },
        ],
        createdAt: "2024-06-08T06:35:36.323Z",
        updatedAt: "2024-06-08T06:35:36.323Z",
      },
      {
        id: "clx5qqq3i025i12vcnmcn2pzc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "W6sWREgcyH",
            locked: 1717828510,
            timestamp: 1717828509,
          },
        ],
        createdAt: "2024-06-08T06:35:10.878Z",
        updatedAt: "2024-06-08T06:35:10.878Z",
      },
      {
        id: "clx5qqnbm025h12vchi5g6yi7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IotXwBS1Ri",
            locked: 1717828506,
            timestamp: 1717828505,
          },
        ],
        createdAt: "2024-06-08T06:35:07.274Z",
        updatedAt: "2024-06-08T06:35:07.274Z",
      },
      {
        id: "clx5qqb6p025g12vc37bgczcr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_5_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yh2lHtdbvB",
            locked: 1717828490,
            timestamp: 1717828489,
          },
        ],
        createdAt: "2024-06-08T06:34:51.550Z",
        updatedAt: "2024-06-08T06:34:51.550Z",
      },
      {
        id: "clx5qpik3025f12vc1hl1bgbr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_5_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "g7J7SB1wY6",
            locked: 1717828453,
            timestamp: 1717828452,
          },
        ],
        createdAt: "2024-06-08T06:34:14.449Z",
        updatedAt: "2024-06-08T06:34:14.449Z",
      },
      {
        id: "clx5qot85025e12vcqeqyzzdo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 330.0.3.29.91 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hFEnAYknbJ",
            locked: 1717828421,
            timestamp: 1717828419,
          },
        ],
        createdAt: "2024-06-08T06:33:41.619Z",
        updatedAt: "2024-06-08T06:33:41.619Z",
      },
      {
        id: "clx5qnj1u025d12vc5hfseg91",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Dc781tDFTX",
            locked: 1717828361,
            timestamp: 1717828360,
          },
        ],
        createdAt: "2024-06-08T06:32:41.778Z",
        updatedAt: "2024-06-08T06:32:41.778Z",
      },
      {
        id: "clx5qn89p025c12vc3hn6uju4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5gBg4PtDWs",
            locked: 1717828346,
            timestamp: 1717828345,
          },
        ],
        createdAt: "2024-06-08T06:32:27.803Z",
        updatedAt: "2024-06-08T06:32:27.803Z",
      },
      {
        id: "clx5qk16l025b12vcdhfyfzqc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711) NW/6",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "x5cy8chgHU",
            locked: 1717828198,
            timestamp: 1717828196,
          },
        ],
        createdAt: "2024-06-08T06:29:58.654Z",
        updatedAt: "2024-06-08T06:29:58.654Z",
      },
      {
        id: "clx5qjriq025a12vcry24xh0y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Qr63fKddS3",
            locked: 1717826933,
            timestamp: 1717826930,
          },
        ],
        createdAt: "2024-06-08T06:29:46.127Z",
        updatedAt: "2024-06-08T06:29:46.127Z",
      },
      {
        id: "clx5qhsl7025912vc3dx3gyju",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N981U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2164; samsung; SM-N981U; c1q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "z1n6J6yZZZ",
            locked: 1717828093,
            timestamp: 1717828089,
          },
        ],
        createdAt: "2024-06-08T06:28:14.201Z",
        updatedAt: "2024-06-08T06:28:14.201Z",
      },
      {
        id: "clx5qgu2e025812vcmi342ds3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone14,3; iOS 16_6_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pn4szFtOsB",
            locked: 1717828047,
            timestamp: 1717828045,
          },
        ],
        createdAt: "2024-06-08T06:27:29.459Z",
        updatedAt: "2024-06-08T06:27:29.459Z",
      },
      {
        id: "clx5qfy5w025712vcd7a4q556",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GTWwUtNDpO",
            locked: 1717828007,
            timestamp: 1717828006,
          },
        ],
        createdAt: "2024-06-08T06:26:48.114Z",
        updatedAt: "2024-06-08T06:26:48.114Z",
      },
      {
        id: "clx5qfcmy025612vcnwr3a1bi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 [FBAN/FBIOS;FBAV/466.0.0.36.107;FBBV/604687626;FBDV/iPhone15,4;FBMD/iPhone;FBSN/iOS;FBSV/17.5.1;FBSS/3;FBID/phone;FBLC/en_US;FBOP/5;FBRV/608107963]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "oZUzWwpHrM",
            locked: 1717827979,
            timestamp: 1717827978,
          },
        ],
        createdAt: "2024-06-08T06:26:20.215Z",
        updatedAt: "2024-06-08T06:26:20.215Z",
      },
      {
        id: "clx5qe2ud025512vcl5yrrriz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "0Rc3uEBhqh",
            locked: 1717827920,
            timestamp: 1717827919,
          },
        ],
        createdAt: "2024-06-08T06:25:20.870Z",
        updatedAt: "2024-06-08T06:25:20.870Z",
      },
      {
        id: "clx5qducl025412vcezahs2ff",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "g1s1z8tLBA",
            locked: 1717827909,
            timestamp: 1717827908,
          },
        ],
        createdAt: "2024-06-08T06:25:09.859Z",
        updatedAt: "2024-06-08T06:25:09.859Z",
      },
      {
        id: "clx5qcjwr025312vc6hmoiq68",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad13,2; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6fmFbmhau8",
            locked: 1717827848,
            timestamp: 1717827847,
          },
        ],
        createdAt: "2024-06-08T06:24:09.675Z",
        updatedAt: "2024-06-08T06:24:09.675Z",
      },
      {
        id: "clx5qc8kv025212vckcemxym2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "w78HFodaFA",
            locked: 1717827834,
            timestamp: 1717827828,
          },
        ],
        createdAt: "2024-06-08T06:23:54.989Z",
        updatedAt: "2024-06-08T06:23:54.989Z",
      },
      {
        id: "clx5qbwcx025112vcsemrgu0h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "M7Sm4cLlPh",
            locked: 1717827812,
            timestamp: 1717827810,
          },
        ],
        createdAt: "2024-06-08T06:23:39.153Z",
        updatedAt: "2024-06-08T06:23:39.153Z",
      },
      {
        id: "clx5qbw1d025012vcr40t3xom",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yPgHmp49M6",
            locked: 1717827818,
            timestamp: 1717827816,
          },
        ],
        createdAt: "2024-06-08T06:23:38.738Z",
        updatedAt: "2024-06-08T06:23:38.738Z",
      },
      {
        id: "clx5qbobo024z12vc8p1hnyhw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21B101 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_1_2; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "11m2s68jEk",
            locked: 1717827807,
            timestamp: 1717827806,
          },
        ],
        createdAt: "2024-06-08T06:23:28.737Z",
        updatedAt: "2024-06-08T06:23:28.737Z",
      },
      {
        id: "clx5qaxl0024y12vcyh8cpt8h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; moto g stylus 5G Build/S2RES32.29-16-1-23; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 446dpi; 1080x2163; motorola; moto g stylus 5G; denver; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "an2ipzSHsc",
            locked: 1717827774,
            timestamp: 1717827765,
          },
        ],
        createdAt: "2024-06-08T06:22:54.082Z",
        updatedAt: "2024-06-08T06:22:54.082Z",
      },
      {
        id: "clx5q9qzw024x12vcw559ea65",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xdbEsL11Jq",
            locked: 1717827718,
            timestamp: 1717827717,
          },
        ],
        createdAt: "2024-06-08T06:21:58.892Z",
        updatedAt: "2024-06-08T06:21:58.892Z",
      },
      {
        id: "clx5q9ita024w12vcybyren7h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DHLAGatDYq",
            locked: 1717827707,
            timestamp: 1717827706,
          },
        ],
        createdAt: "2024-06-08T06:21:48.284Z",
        updatedAt: "2024-06-08T06:21:48.284Z",
      },
      {
        id: "clx5q96hc024v12vcgbr66760",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LmK0eIzrqm",
            locked: 1717827691,
            timestamp: 1717827689,
          },
        ],
        createdAt: "2024-06-08T06:21:32.301Z",
        updatedAt: "2024-06-08T06:21:32.301Z",
      },
      {
        id: "clx5q8pxo024u12vc4oahgt4w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8nL2VqNF2D",
            locked: 1717827670,
            timestamp: 1717827662,
          },
        ],
        createdAt: "2024-06-08T06:21:10.857Z",
        updatedAt: "2024-06-08T06:21:10.857Z",
      },
      {
        id: "clx5q6km0024t12vc4yagnjr3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2140; samsung; SM-S906U; g0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "buMTsX5gJ4",
            locked: 1717827570,
            timestamp: 1717827569,
          },
        ],
        createdAt: "2024-06-08T06:19:30.648Z",
        updatedAt: "2024-06-08T06:19:30.648Z",
      },
      {
        id: "clx5q6k35024s12vcedhfbrab",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2140; samsung; SM-S906U; g0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "I8ppuNBuI9",
            locked: 1717827569,
            timestamp: 1717827566,
          },
        ],
        createdAt: "2024-06-08T06:19:29.968Z",
        updatedAt: "2024-06-08T06:19:29.968Z",
      },
      {
        id: "clx5q6b1p024r12vcqipfyivm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; ANE-LX1 Build/HUAWEIANE-L01; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (28/9; 408dpi; 1080x2190; HUAWEI; ANE-LX1; HWANE; hi6250; tr_TR; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "dglJIXY2LW",
            locked: 1717827557,
            timestamp: 1717827556,
          },
        ],
        createdAt: "2024-06-08T06:19:18.254Z",
        updatedAt: "2024-06-08T06:19:18.254Z",
      },
      {
        id: "clx5q665z024q12vcw35vrq6q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; ANE-LX1 Build/HUAWEIANE-L01; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (28/9; 408dpi; 1080x2190; HUAWEI; ANE-LX1; HWANE; hi6250; tr_TR; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "6HLF0teYY2",
            locked: 1717827551,
            timestamp: 1717827545,
          },
        ],
        createdAt: "2024-06-08T06:19:11.924Z",
        updatedAt: "2024-06-08T06:19:11.924Z",
      },
      {
        id: "clx5q4spi024p12vcifw2kqvv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NrIvYEdaZZ",
            locked: 1717827487,
            timestamp: 1717827485,
          },
        ],
        createdAt: "2024-06-08T06:18:07.828Z",
        updatedAt: "2024-06-08T06:18:07.828Z",
      },
      {
        id: "clx5q3leh024o12vcfr90nuev",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 420dpi; 1080x2140; samsung; SM-S906U; g0q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RgfM1zkvMt",
            locked: 1717827430,
            timestamp: 1717827428,
          },
        ],
        createdAt: "2024-06-08T06:17:11.702Z",
        updatedAt: "2024-06-08T06:17:11.702Z",
      },
      {
        id: "clx5q2je9024n12vc6s185t1q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ZYtGQqOj3U",
            locked: 1717827378,
            timestamp: 1717827377,
          },
        ],
        createdAt: "2024-06-08T06:16:22.447Z",
        updatedAt: "2024-06-08T06:16:22.447Z",
      },
      {
        id: "clx5q20ar024m12vcoqksirm3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "mqbIgdnlSG",
            locked: 1717827480,
            timestamp: 1717827479,
          },
        ],
        createdAt: "2024-06-08T06:15:57.700Z",
        updatedAt: "2024-06-08T06:15:57.700Z",
      },
      {
        id: "clx5q1xd8024l12vc370x66zb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A236U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 510dpi; 1080x2190; samsung; SM-A236U; a23xq; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UWpcfuIkI7",
            locked: 1717827353,
            timestamp: 1717827345,
          },
        ],
        createdAt: "2024-06-08T06:15:53.901Z",
        updatedAt: "2024-06-08T06:15:53.901Z",
      },
      {
        id: "clx5q1u5q024k12vcq2srrws6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2122; samsung; SM-S906U; g0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "z7bBx9UUYA",
            locked: 1717827348,
            timestamp: 1717827347,
          },
        ],
        createdAt: "2024-06-08T06:15:49.739Z",
        updatedAt: "2024-06-08T06:15:49.739Z",
      },
      {
        id: "clx5q1ir2024j12vc9s61lids",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "B1MfSJhdBx",
            locked: 1717827457,
            timestamp: 1717827452,
          },
        ],
        createdAt: "2024-06-08T06:15:34.958Z",
        updatedAt: "2024-06-08T06:15:34.958Z",
      },
      {
        id: "clx5q1bsr024i12vczrexe45v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QlmVN0e4qP",
            locked: 1717827940,
            timestamp: 1717827930,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "3KI0H3VwFa",
            locked: 1717827940,
            timestamp: 1717827936,
          },
        ],
        createdAt: "2024-06-08T06:15:25.945Z",
        updatedAt: "2024-06-08T06:15:25.945Z",
      },
      {
        id: "clx5q0dc0024h12vc5ffkxx3h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "T51i6RtZQ7",
            locked: 1717827281,
            timestamp: 1717827280,
          },
        ],
        createdAt: "2024-06-08T06:14:41.278Z",
        updatedAt: "2024-06-08T06:14:41.278Z",
      },
      {
        id: "clx5q02n8024g12vcf8kur5yq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "oxcgOK9Mbc",
            locked: 1717827266,
            timestamp: 1717827265,
          },
        ],
        createdAt: "2024-06-08T06:14:27.429Z",
        updatedAt: "2024-06-08T06:14:27.429Z",
      },
      {
        id: "clx5q00rf024f12vcbcy7d1rb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ncb3SHyrHT",
            locked: 1717827264,
            timestamp: 1717827262,
          },
        ],
        createdAt: "2024-06-08T06:14:24.985Z",
        updatedAt: "2024-06-08T06:14:24.985Z",
      },
      {
        id: "clx5pyejz024e12vcdf6c27k9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-S906W Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (33/13; 450dpi; 1080x2131; samsung; SM-S906W; g0q; qcom; en_CA; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9tG1uddcC7",
            locked: 1717827188,
            timestamp: 1717827185,
          },
        ],
        createdAt: "2024-06-08T06:13:09.550Z",
        updatedAt: "2024-06-08T06:13:09.550Z",
      },
      {
        id: "clx5py8af024d12vco5dj4m8v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 420dpi; 1080x2140; samsung; SM-S906U; g0q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "pRDEBXvaoU",
            locked: 1717827181,
            timestamp: 1717827180,
          },
        ],
        createdAt: "2024-06-08T06:13:01.432Z",
        updatedAt: "2024-06-08T06:13:01.432Z",
      },
      {
        id: "clx5py1a7024c12vc541rx6xp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 420dpi; 1080x2140; samsung; SM-S906U; g0q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7RAngSQqtA",
            locked: 1717827172,
            timestamp: 1717827169,
          },
        ],
        createdAt: "2024-06-08T06:12:52.348Z",
        updatedAt: "2024-06-08T06:12:52.348Z",
      },
      {
        id: "clx5pwlxu024b12vchtspt9gd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LMqWUF7jpc",
            locked: 1717827104,
            timestamp: 1717827103,
          },
        ],
        createdAt: "2024-06-08T06:11:45.810Z",
        updatedAt: "2024-06-08T06:11:45.810Z",
      },
      {
        id: "clx5pwi4n024a12vc3ujholq8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2138; samsung; SM-S928U1; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aDaKLiRtqR",
            locked: 1717827100,
            timestamp: 1717827098,
          },
        ],
        createdAt: "2024-06-08T06:11:40.872Z",
        updatedAt: "2024-06-08T06:11:40.872Z",
      },
      {
        id: "clx5pw9ks024912vcxddk9wvh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "wgfkvyaA7q",
            locked: 1717827089,
            timestamp: 1717827088,
          },
        ],
        createdAt: "2024-06-08T06:11:29.786Z",
        updatedAt: "2024-06-08T06:11:29.786Z",
      },
      {
        id: "clx5pvrn8024812vcwluhdqjg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "NcqXE2Ksn7",
            locked: 1717827066,
            timestamp: 1717827065,
          },
        ],
        createdAt: "2024-06-08T06:11:06.548Z",
        updatedAt: "2024-06-08T06:11:06.548Z",
      },
      {
        id: "clx5pvpx1024712vcpago82cz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ZAYOKLNjxs",
            locked: 1717827064,
            timestamp: 1717827063,
          },
        ],
        createdAt: "2024-06-08T06:11:04.309Z",
        updatedAt: "2024-06-08T06:11:04.309Z",
      },
      {
        id: "clx5pvpj1024612vccf43a9yh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Dux44t7dxS",
            locked: 1717827063,
            timestamp: 1717827062,
          },
        ],
        createdAt: "2024-06-08T06:11:03.805Z",
        updatedAt: "2024-06-08T06:11:03.805Z",
      },
      {
        id: "clx5pvene024512vck666geg0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2ETMiSnKEc",
            locked: 1717827049,
            timestamp: 1717827047,
          },
        ],
        createdAt: "2024-06-08T06:10:49.703Z",
        updatedAt: "2024-06-08T06:10:49.703Z",
      },
      {
        id: "clx5puwru024412vcvy88fb0n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; motorola one 5G UW Build/RPNS31.Q1-51-30-14-2; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (30/11; 500dpi; 1080x2255; motorola; motorola one 5G UW; nairo; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uIgqqhnI4X",
            locked: 1717827024,
            timestamp: 1717827022,
          },
        ],
        createdAt: "2024-06-08T06:10:26.536Z",
        updatedAt: "2024-06-08T06:10:26.536Z",
      },
      {
        id: "clx5pu4hk024312vczwubkyz5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U1 Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 540dpi; 1080x2087; samsung; SM-N986U1; c2q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jLPuxmmemr",
            locked: 1717826989,
            timestamp: 1717826985,
          },
        ],
        createdAt: "2024-06-08T06:09:49.880Z",
        updatedAt: "2024-06-08T06:09:49.880Z",
      },
      {
        id: "clx5ptygp024212vcv92lbpa6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-G990U1; r9q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "BxwxepwP9Q",
            locked: 1717826981,
            timestamp: 1717826978,
          },
        ],
        createdAt: "2024-06-08T06:09:42.071Z",
        updatedAt: "2024-06-08T06:09:42.071Z",
      },
      {
        id: "clx5psyb5024112vcd93irtkz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S908U; b0q; qcom; en_US; 606558400)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DXbRTDaMWY",
            locked: 1717826934,
            timestamp: 1717826932,
          },
        ],
        createdAt: "2024-06-08T06:08:55.217Z",
        updatedAt: "2024-06-08T06:08:55.217Z",
      },
      {
        id: "clx5psqyf024012vcniqaok3u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MW6YgbWjLe",
            locked: 1717826923,
            timestamp: 1717826921,
          },
        ],
        createdAt: "2024-06-08T06:08:45.684Z",
        updatedAt: "2024-06-08T06:08:45.684Z",
      },
      {
        id: "clx5psbxg023z12vcop4ulh0a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "aaPymuVKoI",
            locked: 1717826905,
            timestamp: 1717826904,
          },
        ],
        createdAt: "2024-06-08T06:08:26.210Z",
        updatedAt: "2024-06-08T06:08:26.210Z",
      },
      {
        id: "clx5prrrm023y12vcbjjbqgm9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "6f4FU3HVK8",
            locked: 1717826880,
            timestamp: 1717826879,
          },
        ],
        createdAt: "2024-06-08T06:08:00.083Z",
        updatedAt: "2024-06-08T06:08:00.083Z",
      },
      {
        id: "clx5prps3023x12vcqdieffx0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "palvoSOZ4M",
            locked: 1717826877,
            timestamp: 1717826875,
          },
        ],
        createdAt: "2024-06-08T06:07:57.505Z",
        updatedAt: "2024-06-08T06:07:57.505Z",
      },
      {
        id: "clx5pp49n023w12vcxhlc6tza",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "NeHB4IRyFL",
            locked: 1717826756,
            timestamp: 1717826755,
          },
        ],
        createdAt: "2024-06-08T06:05:56.316Z",
        updatedAt: "2024-06-08T06:05:56.316Z",
      },
      {
        id: "clx5pp1ad023v12vc8600nf3j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "O0hnWjA1lJ",
            locked: 1717826752,
            timestamp: 1717826751,
          },
        ],
        createdAt: "2024-06-08T06:05:52.450Z",
        updatedAt: "2024-06-08T06:05:52.450Z",
      },
      {
        id: "clx5plr36023u12vcsvfrw4g0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "0ADEtKYxc2",
            locked: 1717826598,
            timestamp: 1717826597,
          },
        ],
        createdAt: "2024-06-08T06:03:19.263Z",
        updatedAt: "2024-06-08T06:03:19.263Z",
      },
      {
        id: "clx5ple96023t12vcl688thkp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0Y0auJLuGm",
            locked: 1717826581,
            timestamp: 1717826580,
          },
        ],
        createdAt: "2024-06-08T06:03:02.634Z",
        updatedAt: "2024-06-08T06:03:02.634Z",
      },
      {
        id: "clx5pl9x6023s12vc2m5750wv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "0gB5izS3f3",
            locked: 1717826576,
            timestamp: 1717826575,
          },
        ],
        createdAt: "2024-06-08T06:02:57.019Z",
        updatedAt: "2024-06-08T06:02:57.019Z",
      },
      {
        id: "clx5pl7tz023r12vckdcbzbfc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bPP7wxRAKw",
            locked: 1717826572,
            timestamp: 1717826571,
          },
        ],
        createdAt: "2024-06-08T06:02:54.308Z",
        updatedAt: "2024-06-08T06:02:54.308Z",
      },
      {
        id: "clx5pk094023q12vceldd8y2i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/301.0.601872660 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "i52CUItBZa",
            locked: 1717826517,
            timestamp: 1717826516,
          },
        ],
        createdAt: "2024-06-08T06:01:57.833Z",
        updatedAt: "2024-06-08T06:01:57.833Z",
      },
      {
        id: "clx5pjv8b023p12vc2k7ujpv5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/301.0.601872660 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ySvBECy1zK",
            locked: 1717826510,
            timestamp: 1717826509,
          },
        ],
        createdAt: "2024-06-08T06:01:51.321Z",
        updatedAt: "2024-06-08T06:01:51.321Z",
      },
      {
        id: "clx5pj83n023o12vc9rfo2b7q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jxC9zHH9uS",
            locked: 1717826479,
            timestamp: 1717826478,
          },
        ],
        createdAt: "2024-06-08T06:01:21.345Z",
        updatedAt: "2024-06-08T06:01:21.345Z",
      },
      {
        id: "clx5pix1v023n12vcelcwv6l2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9JTuFafojK",
            locked: 1717826466,
            timestamp: 1717826465,
          },
        ],
        createdAt: "2024-06-08T06:01:07.027Z",
        updatedAt: "2024-06-08T06:01:07.027Z",
      },
      {
        id: "clx5pir48023m12vc2m3u83ch",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2y4POTWYV0",
            locked: 1717826458,
            timestamp: 1717826456,
          },
        ],
        createdAt: "2024-06-08T06:00:59.334Z",
        updatedAt: "2024-06-08T06:00:59.334Z",
      },
      {
        id: "clx5pgved023l12vcgysrwl4l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WpSBQEZhUz",
            locked: 1717826368,
            timestamp: 1717826335,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "JwzAC5RMHr",
            locked: 1717826368,
            timestamp: 1717826353,
          },
        ],
        createdAt: "2024-06-08T05:59:31.571Z",
        updatedAt: "2024-06-08T05:59:31.571Z",
      },
      {
        id: "clx5pdpz5023k12vcnmdrx9at",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "7RDudtAtBI",
            locked: 1717826224,
            timestamp: 1717826223,
          },
        ],
        createdAt: "2024-06-08T05:57:04.575Z",
        updatedAt: "2024-06-08T05:57:04.575Z",
      },
      {
        id: "clx5pdcxl023j12vcqsslhdi8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VacJB8S12H",
            locked: 1717826207,
            timestamp: 1717826205,
          },
        ],
        createdAt: "2024-06-08T05:56:47.671Z",
        updatedAt: "2024-06-08T05:56:47.671Z",
      },
      {
        id: "clx5pcl4a023i12vce6pyyik2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "TfWMdQhDOz",
            locked: 1717826170,
            timestamp: 1717826169,
          },
        ],
        createdAt: "2024-06-08T05:56:11.622Z",
        updatedAt: "2024-06-08T05:56:11.622Z",
      },
      {
        id: "clx5pboc5023h12vcpf41h447",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "H6wYdgYsdD",
            locked: 1717826127,
            timestamp: 1717826125,
          },
        ],
        createdAt: "2024-06-08T05:55:29.140Z",
        updatedAt: "2024-06-08T05:55:29.140Z",
      },
      {
        id: "clx5pbgoe023g12vcvumsasyu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A526U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 540dpi; 1080x2150; samsung; SM-A526U; a52xq; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jsHWAhZxXP",
            locked: 1717826118,
            timestamp: 1717826113,
          },
        ],
        createdAt: "2024-06-08T05:55:19.215Z",
        updatedAt: "2024-06-08T05:55:19.215Z",
      },
      {
        id: "clx5pbgl2023f12vc45ubzv0h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_GB; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "kUHBUjypLC",
            locked: 1717826118,
            timestamp: 1717826116,
          },
        ],
        createdAt: "2024-06-08T05:55:19.094Z",
        updatedAt: "2024-06-08T05:55:19.094Z",
      },
      {
        id: "clx5pbcvr023e12vclo8n08u7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nmjP99VI9S",
            locked: 1717826113,
            timestamp: 1717826112,
          },
        ],
        createdAt: "2024-06-08T05:55:14.296Z",
        updatedAt: "2024-06-08T05:55:14.296Z",
      },
      {
        id: "clx5pb6vb023d12vcmfi0uiss",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_GB; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jxFFzZoakP",
            locked: 1717826105,
            timestamp: 1717826104,
          },
        ],
        createdAt: "2024-06-08T05:55:06.501Z",
        updatedAt: "2024-06-08T05:55:06.501Z",
      },
      {
        id: "clx5paq2w023c12vcprwtr2s8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rVJbKTuE1S",
            locked: 1717826084,
            timestamp: 1717826082,
          },
        ],
        createdAt: "2024-06-08T05:54:44.742Z",
        updatedAt: "2024-06-08T05:54:44.742Z",
      },
      {
        id: "clx5p8hy9023b12vc3f6bbzol",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "itNl3uDOyD",
            locked: 1717825980,
            timestamp: 1717825977,
          },
        ],
        createdAt: "2024-06-08T05:53:00.895Z",
        updatedAt: "2024-06-08T05:53:00.895Z",
      },
      {
        id: "clx5p7kff023a12vcukuqr47p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FnovkLGK3x",
            locked: 1717825934,
            timestamp: 1717825931,
          },
        ],
        createdAt: "2024-06-08T05:52:17.448Z",
        updatedAt: "2024-06-08T05:52:17.448Z",
      },
      {
        id: "clx5p6652023912vcjx6jy9zd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uJjnHQXvJc",
            locked: 1717825871,
            timestamp: 1717825870,
          },
        ],
        createdAt: "2024-06-08T05:51:12.278Z",
        updatedAt: "2024-06-08T05:51:12.278Z",
      },
      {
        id: "clx5p64l0023812vcuj404ny0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G75 Instagram 334.0.4.32.98 (iPhone13,3; iOS 16_6; en_AU; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "PZ7pPTY1Nm",
            locked: 1717825869,
            timestamp: 1717825868,
          },
        ],
        createdAt: "2024-06-08T05:51:10.261Z",
        updatedAt: "2024-06-08T05:51:10.261Z",
      },
      {
        id: "clx5p5yvj023712vcagmfyhwk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G75 Instagram 334.0.4.32.98 (iPhone13,3; iOS 16_6; en_AU; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vpu87p6YQt",
            locked: 1717825861,
            timestamp: 1717825860,
          },
        ],
        createdAt: "2024-06-08T05:51:02.860Z",
        updatedAt: "2024-06-08T05:51:02.860Z",
      },
      {
        id: "clx5p24i9023612vc9jhbq89c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; 2312DRA50G Build/UKQ1.231003.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1220x2466; Xiaomi/Redmi; 2312DRA50G; garnet; qcom; en_GB; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "UkuNJyYwaN",
            locked: 1717825683,
            timestamp: 1717825682,
          },
        ],
        createdAt: "2024-06-08T05:48:03.537Z",
        updatedAt: "2024-06-08T05:48:03.537Z",
      },
      {
        id: "clx5p1xqd023512vciknjjpcx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; 2312DRA50G Build/UKQ1.231003.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1220x2466; Xiaomi/Redmi; 2312DRA50G; garnet; qcom; en_GB; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3fQjKr0gyI",
            locked: 1717825674,
            timestamp: 1717825672,
          },
        ],
        createdAt: "2024-06-08T05:47:54.754Z",
        updatedAt: "2024-06-08T05:47:54.754Z",
      },
      {
        id: "clx5p1lc9023412vcrets9zi5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 334.0.4.32.98 (iPhone12,1; iOS 15_6_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WxtWnTDmkR",
            locked: 1717825658,
            timestamp: 1717825654,
          },
        ],
        createdAt: "2024-06-08T05:47:38.695Z",
        updatedAt: "2024-06-08T05:47:38.695Z",
      },
      {
        id: "clx5ozsnq023312vcihwloumk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jFfBWdWlxj",
            locked: 1717825574,
            timestamp: 1717825572,
          },
        ],
        createdAt: "2024-06-08T05:46:14.870Z",
        updatedAt: "2024-06-08T05:46:14.870Z",
      },
      {
        id: "clx5oznv9023212vcgjs1o6q7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/126.0.6478.35 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "eGgYPS6zVq",
            locked: 1717825568,
            timestamp: 1717825567,
          },
        ],
        createdAt: "2024-06-08T05:46:08.661Z",
        updatedAt: "2024-06-08T05:46:08.661Z",
      },
      {
        id: "clx5ozhei023112vcnf4kydvv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/126.0.6478.35 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "A4UJMPzd5e",
            locked: 1717825559,
            timestamp: 1717825558,
          },
        ],
        createdAt: "2024-06-08T05:46:00.277Z",
        updatedAt: "2024-06-08T05:46:00.277Z",
      },
      {
        id: "clx5oyk3b023012vcu4nxino1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 540dpi; 1080x2079; samsung; SM-S918U; dm3q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9Qdzvijx5w",
            locked: 1717825516,
            timestamp: 1717825514,
          },
        ],
        createdAt: "2024-06-08T05:45:17.109Z",
        updatedAt: "2024-06-08T05:45:17.109Z",
      },
      {
        id: "clx5oxz6m022z12vcpz93o5qz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NjjPYTHKMS",
            locked: 1717825489,
            timestamp: 1717825487,
          },
        ],
        createdAt: "2024-06-08T05:44:50.012Z",
        updatedAt: "2024-06-08T05:44:50.012Z",
      },
      {
        id: "clx5oxge5022y12vcdnlhk298",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "xfJjT0ZX0g",
            locked: 1717825465,
            timestamp: 1717825464,
          },
        ],
        createdAt: "2024-06-08T05:44:25.662Z",
        updatedAt: "2024-06-08T05:44:25.662Z",
      },
      {
        id: "clx5oxfun022x12vc8czmczw6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "oPnzKybEfb",
            locked: 1717825462,
            timestamp: 1717825459,
          },
        ],
        createdAt: "2024-06-08T05:44:24.957Z",
        updatedAt: "2024-06-08T05:44:24.957Z",
      },
      {
        id: "clx5ovrro022w12vc7kkgi1yg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone12,5; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7KnkxzCUQz",
            locked: 1717825386,
            timestamp: 1717825381,
          },
        ],
        createdAt: "2024-06-08T05:43:07.090Z",
        updatedAt: "2024-06-08T05:43:07.090Z",
      },
      {
        id: "clx5ou9d7022v12vcvlfc1x4i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "HDWQVtuzZ3",
            locked: 1717825316,
            timestamp: 1717825315,
          },
        ],
        createdAt: "2024-06-08T05:41:56.586Z",
        updatedAt: "2024-06-08T05:41:56.586Z",
      },
      {
        id: "clx5ou4vm022u12vc5vn2b16q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pOsSSYYVaq",
            locked: 1717825310,
            timestamp: 1717825308,
          },
        ],
        createdAt: "2024-06-08T05:41:50.770Z",
        updatedAt: "2024-06-08T05:41:50.770Z",
      },
      {
        id: "clx5ottr7022t12vckqt0tkkb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fupzayMoso",
            locked: 1717825296,
            timestamp: 1717825294,
          },
        ],
        createdAt: "2024-06-08T05:41:36.353Z",
        updatedAt: "2024-06-08T05:41:36.353Z",
      },
      {
        id: "clx5ot8hx022s12vcwhku4mjq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21B101 Instagram 309.0.1.24.108 (iPhone14,7; iOS 17_1_2; en_US; en; scale=3.00; 1170x2532; 536373149)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vT5qAGq3h8",
            locked: 1717825268,
            timestamp: 1717825266,
          },
        ],
        createdAt: "2024-06-08T05:41:08.802Z",
        updatedAt: "2024-06-08T05:41:08.802Z",
      },
      {
        id: "clx5ossuy022r12vcl8wec9u5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "X2EzDrLc0V",
            locked: 1717825248,
            timestamp: 1717825247,
          },
        ],
        createdAt: "2024-06-08T05:40:48.536Z",
        updatedAt: "2024-06-08T05:40:48.536Z",
      },
      {
        id: "clx5osdpw022q12vcx70us472",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AL9GzWYv8k",
            locked: 1717825228,
            timestamp: 1717825227,
          },
        ],
        createdAt: "2024-06-08T05:40:28.914Z",
        updatedAt: "2024-06-08T05:40:28.914Z",
      },
      {
        id: "clx5op6gi022p12vcs0000xy1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "0B3od9Lna8",
            locked: 1717825079,
            timestamp: 1717825078,
          },
        ],
        createdAt: "2024-06-08T05:37:59.536Z",
        updatedAt: "2024-06-08T05:37:59.536Z",
      },
      {
        id: "clx5ooecj022o12vcp2cmds6g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G996U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/126.0.6478.40 Mobile Safari/537.36 Instagram 335.0.0.0.78 Android (34/14; 450dpi; 1080x2190; samsung; SM-G996U; t2q; qcom; en_US; 606985497)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "AYGOkffVAF",
            locked: 1717825043,
            timestamp: 1717825042,
          },
        ],
        createdAt: "2024-06-08T05:37:23.107Z",
        updatedAt: "2024-06-08T05:37:23.107Z",
      },
      {
        id: "clx5oo8ls022n12vcckgk7ocu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G996U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/126.0.6478.40 Mobile Safari/537.36 Instagram 335.0.0.0.78 Android (34/14; 450dpi; 1080x2190; samsung; SM-G996U; t2q; qcom; en_US; 606985497)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GHw6vkcQHa",
            locked: 1717825035,
            timestamp: 1717825032,
          },
        ],
        createdAt: "2024-06-08T05:37:15.656Z",
        updatedAt: "2024-06-08T05:37:15.656Z",
      },
      {
        id: "clx5on017022m12vc0809xlcq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "es8bW8lGXq",
            locked: 1717824977,
            timestamp: 1717824975,
          },
        ],
        createdAt: "2024-06-08T05:36:17.899Z",
        updatedAt: "2024-06-08T05:36:17.899Z",
      },
      {
        id: "clx5omt75022l12vchu5urkv4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; motorola razr plus 2023 Build/T2TZS33.18-110-5; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 460dpi; 1080x2394; motorola; motorola razr plus 2023; zeekr; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "9hYnNdglYt",
            locked: 1717824968,
            timestamp: 1717824967,
          },
        ],
        createdAt: "2024-06-08T05:36:09.040Z",
        updatedAt: "2024-06-08T05:36:09.040Z",
      },
      {
        id: "clx5om8vq022k12vcxw25o34u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; motorola razr plus 2023 Build/T2TZS33.18-110-5; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 460dpi; 1080x2394; motorola; motorola razr plus 2023; zeekr; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nSUEpTAyVn",
            locked: 1717824942,
            timestamp: 1717824938,
          },
        ],
        createdAt: "2024-06-08T05:35:42.710Z",
        updatedAt: "2024-06-08T05:35:42.710Z",
      },
      {
        id: "clx5om7j0022j12vc4tcl02bj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "VKyAedolTF",
            locked: 1717824940,
            timestamp: 1717824939,
          },
        ],
        createdAt: "2024-06-08T05:35:40.956Z",
        updatedAt: "2024-06-08T05:35:40.956Z",
      },
      {
        id: "clx5om4mg022i12vcj20wofoz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YgdK6rznzb",
            locked: 1717824935,
            timestamp: 1717824933,
          },
        ],
        createdAt: "2024-06-08T05:35:37.192Z",
        updatedAt: "2024-06-08T05:35:37.192Z",
      },
      {
        id: "clx5om3ze022h12vc3ig8h1sb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YFhGAbnnOD",
            locked: 1717824935,
            timestamp: 1717824932,
          },
        ],
        createdAt: "2024-06-08T05:35:36.360Z",
        updatedAt: "2024-06-08T05:35:36.360Z",
      },
      {
        id: "clx5ok6xx022g12vc11ljch4r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5hk7PYTXYd",
            locked: 1717824845,
            timestamp: 1717824843,
          },
        ],
        createdAt: "2024-06-08T05:34:06.885Z",
        updatedAt: "2024-06-08T05:34:06.885Z",
      },
      {
        id: "clx5ok6p9022f12vcxq0lorzo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Mq5Dpvkgfp",
            locked: 1717824846,
            timestamp: 1717824844,
          },
        ],
        createdAt: "2024-06-08T05:34:06.573Z",
        updatedAt: "2024-06-08T05:34:06.573Z",
      },
      {
        id: "clx5ojzvt022e12vcd61elweg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "SjJBmPnkh1",
            locked: 1717824836,
            timestamp: 1717824834,
          },
        ],
        createdAt: "2024-06-08T05:33:57.735Z",
        updatedAt: "2024-06-08T05:33:57.735Z",
      },
      {
        id: "clx5oj10b022d12vc4cd36680",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; moto z4 Build/QDF30.130-42-5-17; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/112.0.5615.136 Mobile Safari/537.36 Instagram 281.0.0.19.105 Android (29/10; 480dpi; 1080x2120; motorola; moto z4; foles; qcom; en_US; 470774573)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ajy77zr0m0",
            locked: 1717824791,
            timestamp: 1717824787,
          },
        ],
        createdAt: "2024-06-08T05:33:12.540Z",
        updatedAt: "2024-06-08T05:33:12.540Z",
      },
      {
        id: "clx5oiyjp022c12vc3akcohjq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 334.0.4.32.98 (iPhone14,4; iOS 16_1_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "givJ9Qu2sb",
            locked: 1717824788,
            timestamp: 1717824787,
          },
        ],
        createdAt: "2024-06-08T05:33:09.350Z",
        updatedAt: "2024-06-08T05:33:09.350Z",
      },
      {
        id: "clx5oiru0022b12vc1rwiwykd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.5938.132 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OERTHWOmzd",
            locked: 1717824779,
            timestamp: 1717824776,
          },
        ],
        createdAt: "2024-06-08T05:33:00.646Z",
        updatedAt: "2024-06-08T05:33:00.646Z",
      },
      {
        id: "clx5oibd6022a12vc5o3mrphg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Lwlums6SNF",
            locked: 1717824758,
            timestamp: 1717824757,
          },
        ],
        createdAt: "2024-06-08T05:32:39.306Z",
        updatedAt: "2024-06-08T05:32:39.306Z",
      },
      {
        id: "clx5oiaxe022912vcx07xyv4p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lNr1id4FLN",
            locked: 1717824758,
            timestamp: 1717824756,
          },
        ],
        createdAt: "2024-06-08T05:32:38.736Z",
        updatedAt: "2024-06-08T05:32:38.736Z",
      },
      {
        id: "clx5ognsn022812vczp9k377j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kZTalMFUxd",
            locked: 1717824681,
            timestamp: 1717824679,
          },
        ],
        createdAt: "2024-06-08T05:31:22.101Z",
        updatedAt: "2024-06-08T05:31:22.101Z",
      },
      {
        id: "clx5oddhr022712vcqa5b718f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2mvjQQcorw",
            locked: 1717824528,
            timestamp: 1717824527,
          },
        ],
        createdAt: "2024-06-08T05:28:48.781Z",
        updatedAt: "2024-06-08T05:28:48.781Z",
      },
      {
        id: "clx5ocd8n022612vc5tftoaq0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RD6u54AvqI",
            locked: 1717824481,
            timestamp: 1717824479,
          },
        ],
        createdAt: "2024-06-08T05:28:01.797Z",
        updatedAt: "2024-06-08T05:28:01.797Z",
      },
      {
        id: "clx5oann4022512vc4m0d7l6b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 334.0.4.32.98 (iPhone9,2; iOS 15_8_2; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5CTjCRL5O5",
            locked: 1717824401,
            timestamp: 1717824397,
          },
        ],
        createdAt: "2024-06-08T05:26:41.969Z",
        updatedAt: "2024-06-08T05:26:41.969Z",
      },
      {
        id: "clx5oalu8022412vcnk06jiii",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "C1McQzIgPS",
            locked: 1717824399,
            timestamp: 1717824397,
          },
        ],
        createdAt: "2024-06-08T05:26:39.632Z",
        updatedAt: "2024-06-08T05:26:39.632Z",
      },
      {
        id: "clx5oahqf022312vc8l9sqthz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "buku6LJh7h",
            locked: 1717824393,
            timestamp: 1717824392,
          },
        ],
        createdAt: "2024-06-08T05:26:34.308Z",
        updatedAt: "2024-06-08T05:26:34.308Z",
      },
      {
        id: "clx5oa32b022212vcvurrr8ns",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U1 Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 640dpi; 1440x2807; samsung; SM-N986U1; c2q; qcom; en_US; 606558408)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "aqNVsaOfm8",
            locked: 1717824374,
            timestamp: 1717824372,
          },
        ],
        createdAt: "2024-06-08T05:26:15.297Z",
        updatedAt: "2024-06-08T05:26:15.297Z",
      },
      {
        id: "clx5o9jn9022112vcpbuyd144",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,3; iOS 16_7_8; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Rv5tQONcM1",
            locked: 1717824349,
            timestamp: 1717824348,
          },
        ],
        createdAt: "2024-06-08T05:25:50.133Z",
        updatedAt: "2024-06-08T05:25:50.133Z",
      },
      {
        id: "clx5o9aak022012vcnzxiax5c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,3; iOS 16_7_8; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JIRwWOiFXs",
            locked: 1717824337,
            timestamp: 1717824333,
          },
        ],
        createdAt: "2024-06-08T05:25:38.010Z",
        updatedAt: "2024-06-08T05:25:38.010Z",
      },
      {
        id: "clx5o5124021z12vca8m07tmf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 321.0.2.24.105 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 572181444)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1xMwp76ATj",
            locked: 1717824139,
            timestamp: 1717824137,
          },
        ],
        createdAt: "2024-06-08T05:22:19.417Z",
        updatedAt: "2024-06-08T05:22:19.417Z",
      },
      {
        id: "clx5o4mld021y12vc36l8g90m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "snIxtertY4",
            locked: 1717824120,
            timestamp: 1717824118,
          },
        ],
        createdAt: "2024-06-08T05:22:00.674Z",
        updatedAt: "2024-06-08T05:22:00.674Z",
      },
      {
        id: "clx5o4gbi021x12vcalm00fe3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 321.0.2.24.105 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 572181444)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "6BfpbrLeg7",
            locked: 1717824111,
            timestamp: 1717824110,
          },
        ],
        createdAt: "2024-06-08T05:21:52.542Z",
        updatedAt: "2024-06-08T05:21:52.542Z",
      },
      {
        id: "clx5o4dz3021w12vcku0vxwwm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; Nokia X100 Build/RKQ1.210528.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (30/11; 446dpi; 1080x2165; HMD Global/Nokia; Nokia X100; DM5; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "knNHcGiRgk",
            locked: 1717824108,
            timestamp: 1717824104,
          },
        ],
        createdAt: "2024-06-08T05:21:49.501Z",
        updatedAt: "2024-06-08T05:21:49.501Z",
      },
      {
        id: "clx5o3ypm021v12vchy8r1n7u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "uZPoGhtj7q",
            locked: 1717824089,
            timestamp: 1717824088,
          },
        ],
        createdAt: "2024-06-08T05:21:29.722Z",
        updatedAt: "2024-06-08T05:21:29.722Z",
      },
      {
        id: "clx5o3xfv021u12vcixbfy36z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tEvb40qkSv",
            locked: 1717824087,
            timestamp: 1717824086,
          },
        ],
        createdAt: "2024-06-08T05:21:28.072Z",
        updatedAt: "2024-06-08T05:21:28.072Z",
      },
      {
        id: "clx5o2znn021t12vchzt0k9zn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KrfRt1bB07",
            locked: 1717824043,
            timestamp: 1717824042,
          },
        ],
        createdAt: "2024-06-08T05:20:44.288Z",
        updatedAt: "2024-06-08T05:20:44.288Z",
      },
      {
        id: "clx5o2r6f021s12vc92udncp6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6DimXxrLr4",
            locked: 1717824031,
            timestamp: 1717824029,
          },
        ],
        createdAt: "2024-06-08T05:20:33.303Z",
        updatedAt: "2024-06-08T05:20:33.303Z",
      },
      {
        id: "clx5o2m5f021r12vcmybh74h4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N976V Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 320dpi; 720x1367; samsung; SM-N976V; d2xq; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jPEfbmERvp",
            locked: 1717824024,
            timestamp: 1717824010,
          },
        ],
        createdAt: "2024-06-08T05:20:26.784Z",
        updatedAt: "2024-06-08T05:20:26.784Z",
      },
      {
        id: "clx5o1ou2021q12vc9pnkstr7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jDPxDR2O02",
            locked: 1717823983,
            timestamp: 1717823981,
          },
        ],
        createdAt: "2024-06-08T05:19:43.611Z",
        updatedAt: "2024-06-08T05:19:43.611Z",
      },
      {
        id: "clx5o1nuv021p12vchoraxvcx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/302.0.603406840 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ovHacfA4xs",
            locked: 1717823981,
            timestamp: 1717823980,
          },
        ],
        createdAt: "2024-06-08T05:19:42.340Z",
        updatedAt: "2024-06-08T05:19:42.340Z",
      },
      {
        id: "clx5o0lux021o12vc6pt8ng0r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "cTtxSbHJdW",
            locked: 1717823928,
            timestamp: 1717823927,
          },
        ],
        createdAt: "2024-06-08T05:18:53.095Z",
        updatedAt: "2024-06-08T05:18:53.095Z",
      },
      {
        id: "clx5nzrur021n12vcedqoq5ph",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xivK9Q1q9H",
            locked: 1717823893,
            timestamp: 1717823887,
          },
        ],
        createdAt: "2024-06-08T05:18:14.207Z",
        updatedAt: "2024-06-08T05:18:14.207Z",
      },
      {
        id: "clx5nza3w021m12vcmjour70n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F936U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 904x2103; samsung; SM-F936U; q4q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vHnj88VMjf",
            locked: 1717823870,
            timestamp: 1717823866,
          },
        ],
        createdAt: "2024-06-08T05:17:51.210Z",
        updatedAt: "2024-06-08T05:17:51.210Z",
      },
      {
        id: "clx5nyhou021l12vcmbd5vmyj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MgNMNJYJku",
            locked: 1717823833,
            timestamp: 1717823831,
          },
        ],
        createdAt: "2024-06-08T05:17:14.382Z",
        updatedAt: "2024-06-08T05:17:14.382Z",
      },
      {
        id: "clx5nydp0021k12vcpf19bv8r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "30fDKRpaFl",
            locked: 1717823828,
            timestamp: 1717823827,
          },
        ],
        createdAt: "2024-06-08T05:17:09.204Z",
        updatedAt: "2024-06-08T05:17:09.204Z",
      },
      {
        id: "clx5nyc44021j12vc64g7x2p0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "TPEuETuRu1",
            locked: 1717823827,
            timestamp: 1717823826,
          },
        ],
        createdAt: "2024-06-08T05:17:07.157Z",
        updatedAt: "2024-06-08T05:17:07.157Z",
      },
      {
        id: "clx5ny90o021i12vckt3lp1s8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gp449O5yKy",
            locked: 1717823823,
            timestamp: 1717823821,
          },
        ],
        createdAt: "2024-06-08T05:17:03.144Z",
        updatedAt: "2024-06-08T05:17:03.144Z",
      },
      {
        id: "clx5ny4rx021h12vcxlf8asqu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2mV4158phf",
            locked: 1717823817,
            timestamp: 1717823816,
          },
        ],
        createdAt: "2024-06-08T05:16:57.643Z",
        updatedAt: "2024-06-08T05:16:57.643Z",
      },
      {
        id: "clx5nwqx5021g12vcz5gl72zv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qLHaTkLFvr",
            locked: 1717823752,
            timestamp: 1717823750,
          },
        ],
        createdAt: "2024-06-08T05:15:53.032Z",
        updatedAt: "2024-06-08T05:15:53.032Z",
      },
      {
        id: "clx5nwj8o021f12vckjg9ox2k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S901U; r0q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MwTdPIt4CC",
            locked: 1717823742,
            timestamp: 1717823740,
          },
        ],
        createdAt: "2024-06-08T05:15:43.080Z",
        updatedAt: "2024-06-08T05:15:43.080Z",
      },
      {
        id: "clx5nwect021e12vcluvo5cbo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "edcjiJzMrh",
            locked: 1717823736,
            timestamp: 1717823734,
          },
        ],
        createdAt: "2024-06-08T05:15:36.747Z",
        updatedAt: "2024-06-08T05:15:36.747Z",
      },
      {
        id: "clx5nvch5021d12vcrliqu8ej",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 313.0.2.9.339 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 553462334)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "eAybMqtf8t",
            locked: 1717823687,
            timestamp: 1717823685,
          },
        ],
        createdAt: "2024-06-08T05:14:47.657Z",
        updatedAt: "2024-06-08T05:14:47.657Z",
      },
      {
        id: "clx5nv7bj021c12vcgw36jdmh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bEHmn3E2n1",
            locked: 1717823680,
            timestamp: 1717823679,
          },
        ],
        createdAt: "2024-06-08T05:14:40.975Z",
        updatedAt: "2024-06-08T05:14:40.975Z",
      },
      {
        id: "clx5nv57n021b12vcgx25l1gx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ozEGlKak8z",
            locked: 1717823677,
            timestamp: 1717823676,
          },
        ],
        createdAt: "2024-06-08T05:14:38.241Z",
        updatedAt: "2024-06-08T05:14:38.241Z",
      },
      {
        id: "clx5nu4dx021a12vccwtfvv9k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.2 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "54wzeq1LJV",
            locked: 1717823630,
            timestamp: 1717823628,
          },
        ],
        createdAt: "2024-06-08T05:13:50.517Z",
        updatedAt: "2024-06-08T05:13:50.517Z",
      },
      {
        id: "clx5nu3cm021912vc7qspu3up",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "elLJS8wNsi",
            locked: 1717823629,
            timestamp: 1717823628,
          },
        ],
        createdAt: "2024-06-08T05:13:49.174Z",
        updatedAt: "2024-06-08T05:13:49.174Z",
      },
      {
        id: "clx5ntv03021812vch9ajz73c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "646UrqGwMI",
            locked: 1717823617,
            timestamp: 1717823616,
          },
        ],
        createdAt: "2024-06-08T05:13:38.353Z",
        updatedAt: "2024-06-08T05:13:38.353Z",
      },
      {
        id: "clx5ntj6h021712vcyomscfb4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 540dpi; 1080x2087; samsung; SM-N986U; c2q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7frThjIKMs",
            locked: 1717823602,
            timestamp: 1717823600,
          },
        ],
        createdAt: "2024-06-08T05:13:23.033Z",
        updatedAt: "2024-06-08T05:13:23.033Z",
      },
      {
        id: "clx5ntii0021612vc8r5yitvs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TODF1VjHGo",
            locked: 1717823601,
            timestamp: 1717823600,
          },
        ],
        createdAt: "2024-06-08T05:13:22.149Z",
        updatedAt: "2024-06-08T05:13:22.149Z",
      },
      {
        id: "clx5nswbk021512vca5fe4cj5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KHIgkNb0sj",
            locked: 1717823572,
            timestamp: 1717823571,
          },
        ],
        createdAt: "2024-06-08T05:12:53.405Z",
        updatedAt: "2024-06-08T05:12:53.405Z",
      },
      {
        id: "clx5ns8zb021412vcbt4xxfx0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "oCfsmKOUer",
            locked: 1717823542,
            timestamp: 1717823541,
          },
        ],
        createdAt: "2024-06-08T05:12:23.160Z",
        updatedAt: "2024-06-08T05:12:23.160Z",
      },
      {
        id: "clx5ns8vk021312vc1mh32hsv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2SWlsl8bh3",
            locked: 1717823542,
            timestamp: 1717823540,
          },
        ],
        createdAt: "2024-06-08T05:12:23.021Z",
        updatedAt: "2024-06-08T05:12:23.021Z",
      },
      {
        id: "clx5nrojc021212vcdv9frv0x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone14,5; iOS 16_1_2; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "V0utaF4r1C",
            locked: 1717823516,
            timestamp: 1717823514,
          },
        ],
        createdAt: "2024-06-08T05:11:56.664Z",
        updatedAt: "2024-06-08T05:11:56.664Z",
      },
      {
        id: "clx5nri2r021112vceq1rwvu8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "48zHesc39K",
            locked: 1717823507,
            timestamp: 1717823506,
          },
        ],
        createdAt: "2024-06-08T05:11:48.288Z",
        updatedAt: "2024-06-08T05:11:48.288Z",
      },
      {
        id: "clx5nqy4d021012vctfddpbh2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_NZ; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "UsSyQgLtsU",
            locked: 1717823482,
            timestamp: 1717823481,
          },
        ],
        createdAt: "2024-06-08T05:11:22.428Z",
        updatedAt: "2024-06-08T05:11:22.428Z",
      },
      {
        id: "clx5nqu1o020z12vcse99d56u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_NZ; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9592b3Jutf",
            locked: 1717823476,
            timestamp: 1717823475,
          },
        ],
        createdAt: "2024-06-08T05:11:17.148Z",
        updatedAt: "2024-06-08T05:11:17.148Z",
      },
      {
        id: "clx5nqlv1020y12vc75uh9vxy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "BFjYOTe20R",
            locked: 1717823466,
            timestamp: 1717823465,
          },
        ],
        createdAt: "2024-06-08T05:11:06.541Z",
        updatedAt: "2024-06-08T05:11:06.541Z",
      },
      {
        id: "clx5nql7f020x12vcnkx5krem",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ss3fbXJVUB",
            locked: 1717823465,
            timestamp: 1717823463,
          },
        ],
        createdAt: "2024-06-08T05:11:05.688Z",
        updatedAt: "2024-06-08T05:11:05.688Z",
      },
      {
        id: "clx5nq7be020w12vcn5xklcpq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DwLUA1AnWj",
            locked: 1717823447,
            timestamp: 1717823446,
          },
        ],
        createdAt: "2024-06-08T05:10:47.691Z",
        updatedAt: "2024-06-08T05:10:47.691Z",
      },
      {
        id: "clx5npxxz020v12vc79ic10uu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 330.0.3.29.91 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "A8Cf1EyB9H",
            locked: 1717823435,
            timestamp: 1717823434,
          },
        ],
        createdAt: "2024-06-08T05:10:35.542Z",
        updatedAt: "2024-06-08T05:10:35.542Z",
      },
      {
        id: "clx5npr82020u12vcs8onufdz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 330.0.3.29.91 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "u4FLZA5gdB",
            locked: 1717823426,
            timestamp: 1717823425,
          },
        ],
        createdAt: "2024-06-08T05:10:26.835Z",
        updatedAt: "2024-06-08T05:10:26.835Z",
      },
      {
        id: "clx5npm6s020t12vcws50ozjs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_6_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WR0VGLBmBd",
            locked: 1717823419,
            timestamp: 1717823417,
          },
        ],
        createdAt: "2024-06-08T05:10:20.305Z",
        updatedAt: "2024-06-08T05:10:20.305Z",
      },
      {
        id: "clx5nouzx020s12vc6wucebpj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "gaODIYqJ2Z",
            locked: 1717823384,
            timestamp: 1717823383,
          },
        ],
        createdAt: "2024-06-08T05:09:45.068Z",
        updatedAt: "2024-06-08T05:09:45.068Z",
      },
      {
        id: "clx5noofd020r12vc071sqqll",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; moto g stylus 5G (2022) Build/T2SDS33.75-38-1-3-12; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 446dpi; 1080x2211; motorola; moto g stylus 5G (2022); milanf; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3cE44vJvF4",
            locked: 1717823374,
            timestamp: 1717823370,
          },
        ],
        createdAt: "2024-06-08T05:09:36.553Z",
        updatedAt: "2024-06-08T05:09:36.553Z",
      },
      {
        id: "clx5nogwr020q12vcb2o1kgpl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rnkgc1oNZz",
            locked: 1717823365,
            timestamp: 1717823364,
          },
        ],
        createdAt: "2024-06-08T05:09:26.812Z",
        updatedAt: "2024-06-08T05:09:26.812Z",
      },
      {
        id: "clx5noel6020p12vcx8fut0rt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 600dpi; 1440x2839; samsung; SM-S928U; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PreDRxt3O0",
            locked: 1717823363,
            timestamp: 1717823361,
          },
        ],
        createdAt: "2024-06-08T05:09:23.800Z",
        updatedAt: "2024-06-08T05:09:23.800Z",
      },
      {
        id: "clx5nnj7q020o12vcnse9vq83",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D67 Instagram 334.0.4.32.98 (iPhone13,2; iOS 16_3_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "SLoUvTxDuI",
            locked: 1717823323,
            timestamp: 1717823322,
          },
        ],
        createdAt: "2024-06-08T05:08:43.142Z",
        updatedAt: "2024-06-08T05:08:43.142Z",
      },
      {
        id: "clx5nndzp020n12vc2b37hakn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 280dpi; 720x1410; samsung; SM-S918B; dm3q; qcom; en_IN; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "npTxVa1khJ",
            locked: 1717823316,
            timestamp: 1717823315,
          },
        ],
        createdAt: "2024-06-08T05:08:36.373Z",
        updatedAt: "2024-06-08T05:08:36.373Z",
      },
      {
        id: "clx5nncpo020m12vc70x7h3nv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 280dpi; 720x1410; samsung; SM-S918B; dm3q; qcom; en_IN; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "BpoOaWapU4",
            locked: 1717823314,
            timestamp: 1717823312,
          },
        ],
        createdAt: "2024-06-08T05:08:34.717Z",
        updatedAt: "2024-06-08T05:08:34.717Z",
      },
      {
        id: "clx5nnax0020l12vc67mutmlx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D67 Instagram 334.0.4.32.98 (iPhone13,2; iOS 16_3_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rIjtL3xh4A",
            locked: 1717823311,
            timestamp: 1717823310,
          },
        ],
        createdAt: "2024-06-08T05:08:32.386Z",
        updatedAt: "2024-06-08T05:08:32.386Z",
      },
      {
        id: "clx5nmqwm020k12vcc0rvh5x8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FDdBMTh5EW",
            locked: 1717823285,
            timestamp: 1717823284,
          },
        ],
        createdAt: "2024-06-08T05:08:06.451Z",
        updatedAt: "2024-06-08T05:08:06.451Z",
      },
      {
        id: "clx5nmb6g020j12vcjei0srvl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "iVIWfQs6K2",
            locked: 1717823265,
            timestamp: 1717823264,
          },
        ],
        createdAt: "2024-06-08T05:07:46.070Z",
        updatedAt: "2024-06-08T05:07:46.070Z",
      },
      {
        id: "clx5nl7ff020i12vcnsugott5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "U2BXM2h86y",
            locked: 1717823211,
            timestamp: 1717823210,
          },
        ],
        createdAt: "2024-06-08T05:06:54.555Z",
        updatedAt: "2024-06-08T05:06:54.555Z",
      },
      {
        id: "clx5nl6un020h12vchqz2nkdl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20F75 Instagram 334.0.4.32.98 (iPhone13,4; iOS 16_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jZhoeD5Gvz",
            locked: 1717823210,
            timestamp: 1717823206,
          },
        ],
        createdAt: "2024-06-08T05:06:53.804Z",
        updatedAt: "2024-06-08T05:06:53.804Z",
      },
      {
        id: "clx5nij4a020g12vcrhks0xpy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HueJ5le9Xj",
            locked: 1717823089,
            timestamp: 1717823087,
          },
        ],
        createdAt: "2024-06-08T05:04:49.739Z",
        updatedAt: "2024-06-08T05:04:49.739Z",
      },
      {
        id: "clx5niea3020f12vcrgzhaqzm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone11,2; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HaEEU8mzsY",
            locked: 1717823082,
            timestamp: 1717823081,
          },
        ],
        createdAt: "2024-06-08T05:04:43.467Z",
        updatedAt: "2024-06-08T05:04:43.467Z",
      },
      {
        id: "clx5nie3o020e12vctl1gh7wn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nIDbJQe6O0",
            locked: 1717823082,
            timestamp: 1717823081,
          },
        ],
        createdAt: "2024-06-08T05:04:43.236Z",
        updatedAt: "2024-06-08T05:04:43.236Z",
      },
      {
        id: "clx5ni7r5020d12vcn5h52hs7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_1_2; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KLB3S8YHvm",
            locked: 1717823074,
            timestamp: 1717823072,
          },
        ],
        createdAt: "2024-06-08T05:04:35.006Z",
        updatedAt: "2024-06-08T05:04:35.006Z",
      },
      {
        id: "clx5nh30k020c12vcuy9feq6q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "iFObOLc14l",
            locked: 1717823022,
            timestamp: 1717823021,
          },
        ],
        createdAt: "2024-06-08T05:03:42.210Z",
        updatedAt: "2024-06-08T05:03:42.210Z",
      },
      {
        id: "clx5ngn1a020b12vctl37khz0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kHBTCjGCnR",
            locked: 1717823000,
            timestamp: 1717822999,
          },
        ],
        createdAt: "2024-06-08T05:03:21.502Z",
        updatedAt: "2024-06-08T05:03:21.502Z",
      },
      {
        id: "clx5nggaj020a12vc194cvv5j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DW9BnbtjHr",
            locked: 1717822992,
            timestamp: 1717822990,
          },
        ],
        createdAt: "2024-06-08T05:03:12.761Z",
        updatedAt: "2024-06-08T05:03:12.761Z",
      },
      {
        id: "clx5nexof020912vc68hhsqvj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "PwKUcQWYPZ",
            locked: 1717822917,
            timestamp: 1717822916,
          },
        ],
        createdAt: "2024-06-08T05:02:01.980Z",
        updatedAt: "2024-06-08T05:02:01.980Z",
      },
      {
        id: "clx5nen7t020812vcre8nyr8k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vp00zUHahg",
            locked: 1717822907,
            timestamp: 1717822906,
          },
        ],
        createdAt: "2024-06-08T05:01:48.426Z",
        updatedAt: "2024-06-08T05:01:48.426Z",
      },
      {
        id: "clx5neibh020712vcttj1vhrv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MBcjvg1lK2",
            locked: 1717822896,
            timestamp: 1717822893,
          },
        ],
        createdAt: "2024-06-08T05:01:42.078Z",
        updatedAt: "2024-06-08T05:01:42.078Z",
      },
      {
        id: "clx5neg94020612vc80fpxsku",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "EFbNwPwPQ4",
            locked: 1717822899,
            timestamp: 1717822898,
          },
        ],
        createdAt: "2024-06-08T05:01:39.397Z",
        updatedAt: "2024-06-08T05:01:39.397Z",
      },
      {
        id: "clx5nd5h6020512vcb6mwrm61",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "P1QkUkWbdP",
            locked: 1717822838,
            timestamp: 1717822837,
          },
        ],
        createdAt: "2024-06-08T05:00:38.778Z",
        updatedAt: "2024-06-08T05:00:38.778Z",
      },
      {
        id: "clx5ncybu020412vcf78foiem",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rIZHUTm9oZ",
            locked: 1717822829,
            timestamp: 1717822827,
          },
        ],
        createdAt: "2024-06-08T05:00:29.515Z",
        updatedAt: "2024-06-08T05:00:29.515Z",
      },
      {
        id: "clx5ncwu9020312vcbwu5ioup",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gO59EIyIKt",
            locked: 1717822827,
            timestamp: 1717822825,
          },
        ],
        createdAt: "2024-06-08T05:00:27.582Z",
        updatedAt: "2024-06-08T05:00:27.582Z",
      },
      {
        id: "clx5nc6c6020212vcb0pqlrzl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Dalvik/2.1.0 (Linux; U; Android 12; SM-T225 Build/SP1A.210812.016) [FBAN/Orca-Android;FBAV/460.0.0.48.109;FBPN/com.facebook.orca;FBLC/en_US;FBBV/604345557;FBCR/null;FBMF/samsung;FBBD/samsung;FBDV/SM-T225;FBSV/12;FBCA/arm64-v8a:null;FBDM/{density=1.75,width=800,height=1256};FB_FW/1;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "a10f2jNwMT",
            locked: 1717822793,
            timestamp: 1717822787,
          },
        ],
        createdAt: "2024-06-08T04:59:53.235Z",
        updatedAt: "2024-06-08T04:59:53.235Z",
      },
      {
        id: "clx5nbjzg020112vc072l5r1z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "mOMDgUU0VJ",
            locked: 1717822763,
            timestamp: 1717822762,
          },
        ],
        createdAt: "2024-06-08T04:59:24.269Z",
        updatedAt: "2024-06-08T04:59:24.269Z",
      },
      {
        id: "clx5nbi5r020012vcvg6wqyv1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ijCfqX1cU3",
            locked: 1717822760,
            timestamp: 1717822749,
          },
        ],
        createdAt: "2024-06-08T04:59:21.901Z",
        updatedAt: "2024-06-08T04:59:21.901Z",
      },
      {
        id: "clx5nat5401zz12vcquikcepw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wRddFB82Pd",
            locked: 1717822728,
            timestamp: 1717822727,
          },
        ],
        createdAt: "2024-06-08T04:58:49.478Z",
        updatedAt: "2024-06-08T04:58:49.478Z",
      },
      {
        id: "clx5na3d901zy12vcou06146e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ULSCT8lxNx",
            locked: 1717822695,
            timestamp: 1717822694,
          },
        ],
        createdAt: "2024-06-08T04:58:16.075Z",
        updatedAt: "2024-06-08T04:58:16.075Z",
      },
      {
        id: "clx5n9qge01zx12vcpaoicman",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rVStHhsRwq",
            locked: 1717822678,
            timestamp: 1717822677,
          },
        ],
        createdAt: "2024-06-08T04:57:59.343Z",
        updatedAt: "2024-06-08T04:57:59.343Z",
      },
      {
        id: "clx5n9nfe01zw12vcmdsdi6rm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Q5jJkLJATM",
            locked: 1717822673,
            timestamp: 1717822672,
          },
        ],
        createdAt: "2024-06-08T04:57:55.416Z",
        updatedAt: "2024-06-08T04:57:55.416Z",
      },
      {
        id: "clx5n968a01zv12vcza32vjxq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GU2I48xGy7",
            locked: 1717822653,
            timestamp: 1717822650,
          },
        ],
        createdAt: "2024-06-08T04:57:33.127Z",
        updatedAt: "2024-06-08T04:57:33.127Z",
      },
      {
        id: "clx5n8rvg01zu12vcocf7z0s8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "W4ByKHLIs8",
            locked: 1717822633,
            timestamp: 1717822632,
          },
        ],
        createdAt: "2024-06-08T04:57:14.523Z",
        updatedAt: "2024-06-08T04:57:14.523Z",
      },
      {
        id: "clx5n8lwn01zt12vc5rxr1ozg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jwipUzcZvB",
            locked: 1717822626,
            timestamp: 1717822624,
          },
        ],
        createdAt: "2024-06-08T04:57:06.791Z",
        updatedAt: "2024-06-08T04:57:06.791Z",
      },
      {
        id: "clx5n8j0101zs12vcitvwf5wu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YdT1Gs3FxN",
            locked: 1717822622,
            timestamp: 1717822621,
          },
        ],
        createdAt: "2024-06-08T04:57:03.026Z",
        updatedAt: "2024-06-08T04:57:03.026Z",
      },
      {
        id: "clx5n8fex01zr12vcct8ry1lc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MnPBgdDRBP",
            locked: 1717822617,
            timestamp: 1717822614,
          },
        ],
        createdAt: "2024-06-08T04:56:58.374Z",
        updatedAt: "2024-06-08T04:56:58.374Z",
      },
      {
        id: "clx5n5jad01zq12vcakf46x2s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_CA; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "CLR7aoW7zW",
            locked: 1717822483,
            timestamp: 1717822482,
          },
        ],
        createdAt: "2024-06-08T04:54:43.429Z",
        updatedAt: "2024-06-08T04:54:43.429Z",
      },
      {
        id: "clx5n5h4001zp12vcr71k71zq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_CA; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7ndq37sIHd",
            locked: 1717822480,
            timestamp: 1717822479,
          },
        ],
        createdAt: "2024-06-08T04:54:40.606Z",
        updatedAt: "2024-06-08T04:54:40.606Z",
      },
      {
        id: "clx5n4v9101zo12vc8ssfrs9e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "jOczt0aAPx",
            locked: 1717822452,
            timestamp: 1717822451,
          },
        ],
        createdAt: "2024-06-08T04:54:12.277Z",
        updatedAt: "2024-06-08T04:54:12.277Z",
      },
      {
        id: "clx5n4ty401zn12vca2414jxy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "23uaiv1O16",
            locked: 1717822450,
            timestamp: 1717822449,
          },
        ],
        createdAt: "2024-06-08T04:54:10.585Z",
        updatedAt: "2024-06-08T04:54:10.585Z",
      },
      {
        id: "clx5n41f701zm12vc3cn4rmnu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "gNuDnyJmw6",
            locked: 1717822413,
            timestamp: 1717822412,
          },
        ],
        createdAt: "2024-06-08T04:53:33.616Z",
        updatedAt: "2024-06-08T04:53:33.616Z",
      },
      {
        id: "clx5n2s9601zl12vccumegiru",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1w00uop9VO",
            locked: 1717822353,
            timestamp: 1717822352,
          },
        ],
        createdAt: "2024-06-08T04:52:35.082Z",
        updatedAt: "2024-06-08T04:52:35.082Z",
      },
      {
        id: "clx5n2h0b01zk12vcm6pzf9kq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "p3hWs31ZPH",
            locked: 1717822340,
            timestamp: 1717822338,
          },
        ],
        createdAt: "2024-06-08T04:52:20.505Z",
        updatedAt: "2024-06-08T04:52:20.505Z",
      },
      {
        id: "clx5n1tyn01zj12vcx8mb155g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "1mIce8FxnB",
            locked: 1717822310,
            timestamp: 1717822302,
          },
        ],
        createdAt: "2024-06-08T04:51:50.636Z",
        updatedAt: "2024-06-08T04:51:50.636Z",
      },
      {
        id: "clx5n1gt401zi12vce07epag3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "m0AUMcqyrz",
            locked: 1717822292,
            timestamp: 1717822290,
          },
        ],
        createdAt: "2024-06-08T04:51:33.590Z",
        updatedAt: "2024-06-08T04:51:33.590Z",
      },
      {
        id: "clx5n0wjb01zh12vcegh35nli",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A526U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (34/14; 420dpi; 1080x2186; samsung; SM-A526U; a52xq; qcom; en_US; 601420619)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "y4eFlM6MLi",
            locked: 1717822266,
            timestamp: 1717822265,
          },
        ],
        createdAt: "2024-06-08T04:51:07.316Z",
        updatedAt: "2024-06-08T04:51:07.316Z",
      },
      {
        id: "clx5n0dbq01zg12vc3a5oj5ir",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A526U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (34/14; 420dpi; 1080x2186; samsung; SM-A526U; a52xq; qcom; en_US; 601420619)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4IbWeFLJ0o",
            locked: 1717822241,
            timestamp: 1717822235,
          },
        ],
        createdAt: "2024-06-08T04:50:42.420Z",
        updatedAt: "2024-06-08T04:50:42.420Z",
      },
      {
        id: "clx5my2g401zf12vcuob4q07h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; DE2117 Build/SKQ1.210216.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 302.1.0.36.111 Android (31/12; 480dpi; 1080x2158; OnePlus; DE2117; OnePlusN200; qcom; en_US; 520702295)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "F76O921hGy",
            locked: 1717822133,
            timestamp: 1717822132,
          },
        ],
        createdAt: "2024-06-08T04:48:55.010Z",
        updatedAt: "2024-06-08T04:48:55.010Z",
      },
      {
        id: "clx5mwyix01ze12vcbshah2o5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "UUhJkiWFeE",
            locked: 1717822083,
            timestamp: 1717822082,
          },
        ],
        createdAt: "2024-06-08T04:48:03.273Z",
        updatedAt: "2024-06-08T04:48:03.273Z",
      },
      {
        id: "clx5mwx9i01zd12vcbyd2dsyd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xnwgESk775",
            locked: 1717822081,
            timestamp: 1717822080,
          },
        ],
        createdAt: "2024-06-08T04:48:01.639Z",
        updatedAt: "2024-06-08T04:48:01.639Z",
      },
      {
        id: "clx5mwsq201zc12vc4ibf2255",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; DE2117 Build/SKQ1.210216.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 302.1.0.36.111 Android (31/12; 480dpi; 1080x2158; OnePlus; DE2117; OnePlusN200; qcom; en_US; 520702295)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "p6f6dmB47f",
            locked: 1717822074,
            timestamp: 1717822065,
          },
        ],
        createdAt: "2024-06-08T04:47:55.752Z",
        updatedAt: "2024-06-08T04:47:55.752Z",
      },
      {
        id: "clx5mvjsp01zb12vcwh8r0ed3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S711U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 331.0.0.37.90 Android (34/14; 450dpi; 1080x2125; samsung; SM-S711U; r11q; qcom; en_US; 598808578)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MLOEGM4Tvk",
            locked: 1717822016,
            timestamp: 1717822014,
          },
        ],
        createdAt: "2024-06-08T04:46:57.527Z",
        updatedAt: "2024-06-08T04:46:57.527Z",
      },
      {
        id: "clx5mtm9c01za12vcdj6pvqvx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Messenger/97.11.116 Chrome/83.0.4103.122 Electron/9.1.0 Safari/537.36 MessengerDesktop [FBAN/MessengerDesktop;FBAV/97.11.116;FBBV/283083801;FBDV/high;FBSN/darwin;FBSV/10.14.6;FBSS/1;FBID/desktop;FBLC/en_US;FBOP/0;FBWS/0;MDBeta/0;MDMas/0]",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WFPcqif0t4",
            locked: 1717821926,
            timestamp: 1717821921,
          },
        ],
        createdAt: "2024-06-08T04:45:27.408Z",
        updatedAt: "2024-06-08T04:45:27.408Z",
      },
      {
        id: "clx5mtjrw01z912vczzkyybij",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KAMMhvjYbV",
            locked: 1717821923,
            timestamp: 1717821922,
          },
        ],
        createdAt: "2024-06-08T04:45:24.185Z",
        updatedAt: "2024-06-08T04:45:24.185Z",
      },
      {
        id: "clx5mt5qm01z812vchhpb88i9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DMeu1z6iUe",
            locked: 1717821905,
            timestamp: 1717821902,
          },
        ],
        createdAt: "2024-06-08T04:45:05.995Z",
        updatedAt: "2024-06-08T04:45:05.995Z",
      },
      {
        id: "clx5msnr501z712vcvrkyue2p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KltJE9Jcd2",
            locked: 1717821882,
            timestamp: 1717821880,
          },
        ],
        createdAt: "2024-06-08T04:44:42.687Z",
        updatedAt: "2024-06-08T04:44:42.687Z",
      },
      {
        id: "clx5mscdc01z612vcxu443dz5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 327.1.6.30.88 (iPhone14,3; iOS 17_5_1; en_ZA; en; scale=3.00; 1284x2778; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "LDIz1yyC2j",
            locked: 1717821867,
            timestamp: 1717821866,
          },
        ],
        createdAt: "2024-06-08T04:44:27.936Z",
        updatedAt: "2024-06-08T04:44:27.936Z",
      },
      {
        id: "clx5ms4zx01z512vchccf0nwb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 327.1.6.30.88 (iPhone14,3; iOS 17_5_1; en_ZA; en; scale=3.00; 1284x2778; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PKYznXgIJV",
            locked: 1717821857,
            timestamp: 1717821856,
          },
        ],
        createdAt: "2024-06-08T04:44:18.379Z",
        updatedAt: "2024-06-08T04:44:18.379Z",
      },
      {
        id: "clx5mph5001z412vcgrxro0ne",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "UxmWgyvFrT",
            locked: 1717821733,
            timestamp: 1717821732,
          },
        ],
        createdAt: "2024-06-08T04:42:14.149Z",
        updatedAt: "2024-06-08T04:42:14.149Z",
      },
      {
        id: "clx5mpcqy01z312vcalu5qycd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Lu7ZPq7Tpw",
            locked: 1717821727,
            timestamp: 1717821725,
          },
        ],
        createdAt: "2024-06-08T04:42:08.456Z",
        updatedAt: "2024-06-08T04:42:08.456Z",
      },
      {
        id: "clx5motr001z212vcjcfar22y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "r945z8EmyL",
            locked: 1717821703,
            timestamp: 1717821701,
          },
        ],
        createdAt: "2024-06-08T04:41:43.834Z",
        updatedAt: "2024-06-08T04:41:43.834Z",
      },
      {
        id: "clx5mobk501z112vcdw64tkrg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,2; iOS 16_7_8; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nqO7A2UxTq",
            locked: 1717821679,
            timestamp: 1717821678,
          },
        ],
        createdAt: "2024-06-08T04:41:20.261Z",
        updatedAt: "2024-06-08T04:41:20.261Z",
      },
      {
        id: "clx5moafw01z012vc9rvprt0x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_SG; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "YzJCbwMkML",
            locked: 1717821677,
            timestamp: 1717821676,
          },
        ],
        createdAt: "2024-06-08T04:41:18.809Z",
        updatedAt: "2024-06-08T04:41:18.809Z",
      },
      {
        id: "clx5mn1dw01yz12vc5p6s82u4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 331.1.3.29.90 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZwMfraiqMl",
            locked: 1717821619,
            timestamp: 1717821617,
          },
        ],
        createdAt: "2024-06-08T04:40:20.420Z",
        updatedAt: "2024-06-08T04:40:20.420Z",
      },
      {
        id: "clx5mms8k01yy12vcylkhf330",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-A136U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 329.0.0.41.93 Android (31/12; 300dpi; 720x1465; samsung; SM-A136U; a13x; mt6833; en_US; 593717525)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "S5BCr3ZEFq",
            locked: 1717821607,
            timestamp: 1717821602,
          },
        ],
        createdAt: "2024-06-08T04:40:08.562Z",
        updatedAt: "2024-06-08T04:40:08.562Z",
      },
      {
        id: "clx5mlr2701yx12vctq6c0fuj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 334.0.4.32.98 (iPhone8,1; iOS 15_8_2; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xhdpN6v9pM",
            locked: 1717821559,
            timestamp: 1717821554,
          },
        ],
        createdAt: "2024-06-08T04:39:20.381Z",
        updatedAt: "2024-06-08T04:39:20.381Z",
      },
      {
        id: "clx5mkjn801yw12vcafb07174",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KfFXprhEQj",
            locked: 1717821502,
            timestamp: 1717821500,
          },
        ],
        createdAt: "2024-06-08T04:38:24.113Z",
        updatedAt: "2024-06-08T04:38:24.113Z",
      },
      {
        id: "clx5mi6gz01yv12vcfqj3mefs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "avo2nBJ5Zl",
            locked: 1717821393,
            timestamp: 1717821392,
          },
        ],
        createdAt: "2024-06-08T04:36:33.731Z",
        updatedAt: "2024-06-08T04:36:33.731Z",
      },
      {
        id: "clx5mhycr01yu12vc30hzhxmx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WI506wNW9N",
            locked: 1717821382,
            timestamp: 1717821380,
          },
        ],
        createdAt: "2024-06-08T04:36:23.208Z",
        updatedAt: "2024-06-08T04:36:23.208Z",
      },
      {
        id: "clx5mgpl301yt12vceqr98a74",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0upXm8xZgv",
            locked: 1717821324,
            timestamp: 1717821323,
          },
        ],
        createdAt: "2024-06-08T04:35:25.192Z",
        updatedAt: "2024-06-08T04:35:25.192Z",
      },
      {
        id: "clx5mgp8801ys12vcfz553k5d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.147 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2106; samsung; SM-S918U; dm3q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hE4gBhrU8e",
            locked: 1717821323,
            timestamp: 1717821320,
          },
        ],
        createdAt: "2024-06-08T04:35:24.726Z",
        updatedAt: "2024-06-08T04:35:24.726Z",
      },
      {
        id: "clx5mfdm501yr12vcdzxgx9pu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LBRK5DERBZ",
            locked: 1717821261,
            timestamp: 1717821260,
          },
        ],
        createdAt: "2024-06-08T04:34:23.019Z",
        updatedAt: "2024-06-08T04:34:23.019Z",
      },
      {
        id: "clx5me6yb01yq12vc1jv9y83a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "je2or3kRtx",
            locked: 1717821207,
            timestamp: 1717821206,
          },
        ],
        createdAt: "2024-06-08T04:33:27.728Z",
        updatedAt: "2024-06-08T04:33:27.728Z",
      },
      {
        id: "clx5mdns701yp12vcycd45pmm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "C7dDsgSE0i",
            locked: 1717821184,
            timestamp: 1717821182,
          },
        ],
        createdAt: "2024-06-08T04:33:02.884Z",
        updatedAt: "2024-06-08T04:33:02.884Z",
      },
      {
        id: "clx5mc4dx01yo12vc9en4qnr2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mEY9vrgyI8",
            locked: 1717821110,
            timestamp: 1717821107,
          },
        ],
        createdAt: "2024-06-08T04:31:51.089Z",
        updatedAt: "2024-06-08T04:31:51.089Z",
      },
      {
        id: "clx5ma9rm01yn12vc82yn4l04",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_5_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "uHEVGwAv1y",
            locked: 1717821024,
            timestamp: 1717821022,
          },
        ],
        createdAt: "2024-06-08T04:30:24.751Z",
        updatedAt: "2024-06-08T04:30:24.751Z",
      },
      {
        id: "clx5m9sny01ym12vcul13jva6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ed6bDSCPL2",
            locked: 1717821002,
            timestamp: 1717821001,
          },
        ],
        createdAt: "2024-06-08T04:30:02.589Z",
        updatedAt: "2024-06-08T04:30:02.589Z",
      },
      {
        id: "clx5m9qsc01yl12vc1hsvovp7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "GM9fU34iur",
            locked: 1717820999,
            timestamp: 1717820998,
          },
        ],
        createdAt: "2024-06-08T04:30:00.156Z",
        updatedAt: "2024-06-08T04:30:00.156Z",
      },
      {
        id: "clx5m9p2d01yk12vc6045z7kb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pZrQamRy9q",
            locked: 1717820997,
            timestamp: 1717820996,
          },
        ],
        createdAt: "2024-06-08T04:29:57.926Z",
        updatedAt: "2024-06-08T04:29:57.926Z",
      },
      {
        id: "clx5m9gh101yj12vc7zriuam3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0tBw7uHnZm",
            locked: 1717820985,
            timestamp: 1717820982,
          },
        ],
        createdAt: "2024-06-08T04:29:46.787Z",
        updatedAt: "2024-06-08T04:29:46.787Z",
      },
      {
        id: "clx5m90xr01yi12vcmf681x2d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "k3LeBsNPVd",
            locked: 1717820966,
            timestamp: 1717820964,
          },
        ],
        createdAt: "2024-06-08T04:29:26.653Z",
        updatedAt: "2024-06-08T04:29:26.653Z",
      },
      {
        id: "clx5m6vus01yh12vcx3y2pvu3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fJ2dEYRCNB",
            locked: 1717820866,
            timestamp: 1717820864,
          },
        ],
        createdAt: "2024-06-08T04:27:46.753Z",
        updatedAt: "2024-06-08T04:27:46.753Z",
      },
      {
        id: "clx5m6n1j01yg12vcifg6r9hi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "01uCgzHt2D",
            locked: 1717820854,
            timestamp: 1717820853,
          },
        ],
        createdAt: "2024-06-08T04:27:35.336Z",
        updatedAt: "2024-06-08T04:27:35.336Z",
      },
      {
        id: "clx5m6iez01yf12vcniohewka",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MO7mddLNLg",
            locked: 1717820848,
            timestamp: 1717820847,
          },
        ],
        createdAt: "2024-06-08T04:27:29.336Z",
        updatedAt: "2024-06-08T04:27:29.336Z",
      },
      {
        id: "clx5m621201ye12vc31vy5ld2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "liTb3A2tvq",
            locked: 1717820828,
            timestamp: 1717820827,
          },
        ],
        createdAt: "2024-06-08T04:27:08.103Z",
        updatedAt: "2024-06-08T04:27:08.103Z",
      },
      {
        id: "clx5m60nm01yd12vcrpe93u4j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "o1ywUOwsGD",
            locked: 1717820826,
            timestamp: 1717820824,
          },
        ],
        createdAt: "2024-06-08T04:27:06.322Z",
        updatedAt: "2024-06-08T04:27:06.322Z",
      },
      {
        id: "clx5m5vzz01yc12vchjus9lul",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "e7WeDtfLvS",
            locked: 1717820819,
            timestamp: 1717820817,
          },
        ],
        createdAt: "2024-06-08T04:27:00.284Z",
        updatedAt: "2024-06-08T04:27:00.284Z",
      },
      {
        id: "clx5m5a1g01yb12vcpr8xvagu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "8VJ53SAq38",
            locked: 1717820791,
            timestamp: 1717820790,
          },
        ],
        createdAt: "2024-06-08T04:26:31.826Z",
        updatedAt: "2024-06-08T04:26:31.826Z",
      },
      {
        id: "clx5m4ju501ya12vc6ezu3nmr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ytqjmd2TOG",
            locked: 1717820756,
            timestamp: 1717820755,
          },
        ],
        createdAt: "2024-06-08T04:25:57.866Z",
        updatedAt: "2024-06-08T04:25:57.866Z",
      },
      {
        id: "clx5m467y01y912vc90m2ffrf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A146U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.53 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2208; samsung; SM-A146U; a14xm; mt6833; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "W7nicYCoHy",
            locked: 1717820739,
            timestamp: 1717820735,
          },
        ],
        createdAt: "2024-06-08T04:25:40.222Z",
        updatedAt: "2024-06-08T04:25:40.222Z",
      },
      {
        id: "clx5m3zpg01y812vc3gi7fde8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,1; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SwX4sFblGI",
            locked: 1717820731,
            timestamp: 1717820729,
          },
        ],
        createdAt: "2024-06-08T04:25:31.778Z",
        updatedAt: "2024-06-08T04:25:31.778Z",
      },
      {
        id: "clx5m2p4m01y712vcgrijxm41",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UT4dlkvbtu",
            locked: 1717820670,
            timestamp: 1717820669,
          },
        ],
        createdAt: "2024-06-08T04:24:31.412Z",
        updatedAt: "2024-06-08T04:24:31.412Z",
      },
      {
        id: "clx5m23iu01y612vck6b8o5al",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 331.1.3.29.90 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 599297333) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yIpYAJuHxJ",
            locked: 1717820642,
            timestamp: 1717820639,
          },
        ],
        createdAt: "2024-06-08T04:24:03.412Z",
        updatedAt: "2024-06-08T04:24:03.412Z",
      },
      {
        id: "clx5m1lug01y512vcnlmndw0k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-X200 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 240dpi; 1200x1848; samsung; SM-X200; gta8wifi; ums512_25c10; en_US; 606558546)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "V40yKX127O",
            locked: 1717820619,
            timestamp: 1717820617,
          },
        ],
        createdAt: "2024-06-08T04:23:40.504Z",
        updatedAt: "2024-06-08T04:23:40.504Z",
      },
      {
        id: "clx5m1l5p01y412vc4b1w8x2i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 334.0.4.32.98 (iPhone11,8; iOS 16_1_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rkHhIuB6Jp",
            locked: 1717820616,
            timestamp: 1717820614,
          },
        ],
        createdAt: "2024-06-08T04:23:39.614Z",
        updatedAt: "2024-06-08T04:23:39.614Z",
      },
      {
        id: "clx5m1fo501y312vc2lo86be7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G75 Instagram 318.0.7.22.109 (iPhone15,3; iOS 16_6; en_US; en; scale=3.00; 1290x2796; 565151867)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DiLuaMPh1g",
            locked: 1717820611,
            timestamp: 1717820610,
          },
        ],
        createdAt: "2024-06-08T04:23:32.499Z",
        updatedAt: "2024-06-08T04:23:32.499Z",
      },
      {
        id: "clx5m0t2g01y212vcguuqmyvt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hIiGoKT2MA",
            locked: 1717820582,
            timestamp: 1717820581,
          },
        ],
        createdAt: "2024-06-08T04:23:03.206Z",
        updatedAt: "2024-06-08T04:23:03.206Z",
      },
      {
        id: "clx5lzpb301y112vczqeo5q31",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 331.1.3.29.90 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "wayiEpSmfc",
            locked: 1717820531,
            timestamp: 1717820530,
          },
        ],
        createdAt: "2024-06-08T04:22:11.680Z",
        updatedAt: "2024-06-08T04:22:11.680Z",
      },
      {
        id: "clx5lzkmj01y012vc9r20j9th",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 331.1.3.29.90 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lZNSr0RMfN",
            locked: 1717820524,
            timestamp: 1717820523,
          },
        ],
        createdAt: "2024-06-08T04:22:05.610Z",
        updatedAt: "2024-06-08T04:22:05.610Z",
      },
      {
        id: "clx5lzf7k01xz12vc3l5ds79e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "sKsPnLPykF",
            locked: 1717820517,
            timestamp: 1717820505,
          },
        ],
        createdAt: "2024-06-08T04:21:58.593Z",
        updatedAt: "2024-06-08T04:21:58.593Z",
      },
      {
        id: "clx5lzf0e01xy12vcrmqrx0c5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ruL1CGWHxN",
            locked: 1717820516,
            timestamp: 1717820502,
          },
        ],
        createdAt: "2024-06-08T04:21:58.334Z",
        updatedAt: "2024-06-08T04:21:58.334Z",
      },
      {
        id: "clx5lzd9f01xx12vc871d7n6e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GxIFrE5Ino",
            locked: 1717820515,
            timestamp: 1717820500,
          },
        ],
        createdAt: "2024-06-08T04:21:56.068Z",
        updatedAt: "2024-06-08T04:21:56.068Z",
      },
      {
        id: "clx5lzbpq01xw12vcux5d9ojg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Z6AbwOjKG8",
            locked: 1717820513,
            timestamp: 1717820512,
          },
        ],
        createdAt: "2024-06-08T04:21:54.062Z",
        updatedAt: "2024-06-08T04:21:54.062Z",
      },
      {
        id: "clx5lz8np01xv12vcx51w788z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xsyhDvY38b",
            locked: 1717820509,
            timestamp: 1717820500,
          },
        ],
        createdAt: "2024-06-08T04:21:50.100Z",
        updatedAt: "2024-06-08T04:21:50.100Z",
      },
      {
        id: "clx5lz75z01xu12vcvy59ueju",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) EdgiOS/124.0.2478.89 Version/16.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OvYzLJksIz",
            locked: 1717820507,
            timestamp: 1717820506,
          },
        ],
        createdAt: "2024-06-08T04:21:48.168Z",
        updatedAt: "2024-06-08T04:21:48.168Z",
      },
      {
        id: "clx5lyzwb01xt12vcfmuk4aw3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "k5Le5Evq9q",
            locked: 1717820497,
            timestamp: 1717820486,
          },
        ],
        createdAt: "2024-06-08T04:21:38.748Z",
        updatedAt: "2024-06-08T04:21:38.748Z",
      },
      {
        id: "clx5lywj601xs12vc7cjcz4r1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lyn8Ghmkt0",
            locked: 1717820493,
            timestamp: 1717820492,
          },
        ],
        createdAt: "2024-06-08T04:21:34.384Z",
        updatedAt: "2024-06-08T04:21:34.384Z",
      },
      {
        id: "clx5lykhu01xr12vcynuik3kw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21A340 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_0_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TmcOlbVpCC",
            locked: 1717820478,
            timestamp: 1717820475,
          },
        ],
        createdAt: "2024-06-08T04:21:18.786Z",
        updatedAt: "2024-06-08T04:21:18.786Z",
      },
      {
        id: "clx5lyeiv01xq12vcgfdnlrrq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36 OPR/106.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YkjGO8wMY9",
            locked: 1717820470,
            timestamp: 1717820468,
          },
        ],
        createdAt: "2024-06-08T04:21:11.046Z",
        updatedAt: "2024-06-08T04:21:11.046Z",
      },
      {
        id: "clx5ly9jt01xp12vcgga8fjhj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/124.0.6367.111 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "S4xGZfochd",
            locked: 1717820464,
            timestamp: 1717820461,
          },
        ],
        createdAt: "2024-06-08T04:21:04.601Z",
        updatedAt: "2024-06-08T04:21:04.601Z",
      },
      {
        id: "clx5ly87q01xo12vcel2hhuxv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; CrOS x86_64 14541.0.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "56BrAKU4Ry",
            locked: 1717820462,
            timestamp: 1717820460,
          },
        ],
        createdAt: "2024-06-08T04:21:02.870Z",
        updatedAt: "2024-06-08T04:21:02.870Z",
      },
      {
        id: "clx5ly59d01xn12vcs2swvm0k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36 OPR/109.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ByLTJuxkBC",
            locked: 1717820458,
            timestamp: 1717820456,
          },
        ],
        createdAt: "2024-06-08T04:20:59.041Z",
        updatedAt: "2024-06-08T04:20:59.041Z",
      },
      {
        id: "clx5ly1q001xm12vco18b2bmi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent: "Mozilla/5.0 (compatible; CascadeCMS/8.24.1)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GN01KaEFge",
            locked: 1717820454,
            timestamp: 1717820452,
          },
        ],
        createdAt: "2024-06-08T04:20:54.456Z",
        updatedAt: "2024-06-08T04:20:54.456Z",
      },
      {
        id: "clx5ly07j01xl12vc0ibkm59v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 Safari/537.36 Edg/115.0.1901.203",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RyKchoWGer",
            locked: 1717820452,
            timestamp: 1717820449,
          },
        ],
        createdAt: "2024-06-08T04:20:52.493Z",
        updatedAt: "2024-06-08T04:20:52.493Z",
      },
      {
        id: "clx5lxahr01xk12vcbqkoa9ve",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20A362 Instagram 334.0.4.32.98 (iPhone11,8; iOS 16_0; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GnEO2YlLtO",
            locked: 1717820418,
            timestamp: 1717820417,
          },
        ],
        createdAt: "2024-06-08T04:20:19.165Z",
        updatedAt: "2024-06-08T04:20:19.165Z",
      },
      {
        id: "clx5lv9cv01xj12vctwik1oeh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GAgXSrft9x",
            locked: 1717820320,
            timestamp: 1717820314,
          },
        ],
        createdAt: "2024-06-08T04:18:44.384Z",
        updatedAt: "2024-06-08T04:18:44.384Z",
      },
      {
        id: "clx5lv36401xi12vci3ewlp1l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DdGK1AuQoF",
            locked: 1717820315,
            timestamp: 1717820311,
          },
        ],
        createdAt: "2024-06-08T04:18:36.361Z",
        updatedAt: "2024-06-08T04:18:36.361Z",
      },
      {
        id: "clx5luthi01xh12vc7ple83nb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 261.0.0.18.106 (iPhone14,5; iOS 17_5_1; en_US; en-US; scale=3.00; 1170x2532; 418330231)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "2qJCudEbj1",
            locked: 1717820303,
            timestamp: 1717820302,
          },
        ],
        createdAt: "2024-06-08T04:18:23.815Z",
        updatedAt: "2024-06-08T04:18:23.815Z",
      },
      {
        id: "clx5lupze01xg12vc1zjmz52j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FSJUKoJHsC",
            locked: 1717820298,
            timestamp: 1717820297,
          },
        ],
        createdAt: "2024-06-08T04:18:19.272Z",
        updatedAt: "2024-06-08T04:18:19.272Z",
      },
      {
        id: "clx5lu22n01xf12vcj9xw23m0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 261.0.0.18.106 (iPhone14,5; iOS 17_5_1; en_US; en-US; scale=3.00; 1170x2532; 418330231)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vg0Wlh018I",
            locked: 1717820267,
            timestamp: 1717820266,
          },
        ],
        createdAt: "2024-06-08T04:17:48.284Z",
        updatedAt: "2024-06-08T04:17:48.284Z",
      },
      {
        id: "clx5ltq0501xe12vcdxqm9qao",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Shop App/2.159.0/iOS/17.5.1/WebView (surface=storefront)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ISdQmLr8t9",
            locked: 1717820252,
            timestamp: 1717820251,
          },
        ],
        createdAt: "2024-06-08T04:17:32.643Z",
        updatedAt: "2024-06-08T04:17:32.643Z",
      },
      {
        id: "clx5ltkq501xd12vci1f9s4yl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Shop App/2.159.0/iOS/17.5.1/WebView (surface=storefront)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MbtmLwCeRu",
            locked: 1717820245,
            timestamp: 1717820243,
          },
        ],
        createdAt: "2024-06-08T04:17:25.806Z",
        updatedAt: "2024-06-08T04:17:25.806Z",
      },
      {
        id: "clx5lt9tj01xc12vcedde92e3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 331.1.3.29.90 (iPhone13,2; iOS 17_3_1; en_US; en; scale=3.00; 960x2079; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QNeIjxxRmN",
            locked: 1717820230,
            timestamp: 1717820228,
          },
        ],
        createdAt: "2024-06-08T04:17:11.669Z",
        updatedAt: "2024-06-08T04:17:11.669Z",
      },
      {
        id: "clx5lsffg01xb12vc43wxroma",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F79 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ckmJDr0zzg",
            locked: 1717820191,
            timestamp: 1717820188,
          },
        ],
        createdAt: "2024-06-08T04:16:32.280Z",
        updatedAt: "2024-06-08T04:16:32.280Z",
      },
      {
        id: "clx5ls3p201xa12vck0prqttw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "W3DwZxd5c5",
            locked: 1717820177,
            timestamp: 1717820176,
          },
        ],
        createdAt: "2024-06-08T04:16:17.079Z",
        updatedAt: "2024-06-08T04:16:17.079Z",
      },
      {
        id: "clx5lrww001x912vc8d0re5hq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "q9DkHizciM",
            locked: 1717820168,
            timestamp: 1717820166,
          },
        ],
        createdAt: "2024-06-08T04:16:08.250Z",
        updatedAt: "2024-06-08T04:16:08.250Z",
      },
      {
        id: "clx5lrhcj01x812vc0wy56c5y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "v5GvgcKSaR",
            locked: 1717820147,
            timestamp: 1717820146,
          },
        ],
        createdAt: "2024-06-08T04:15:48.113Z",
        updatedAt: "2024-06-08T04:15:48.113Z",
      },
      {
        id: "clx5lqr8501x712vcxfk5duvz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8veSrXYgCo",
            locked: 1717820113,
            timestamp: 1717820112,
          },
        ],
        createdAt: "2024-06-08T04:15:14.261Z",
        updatedAt: "2024-06-08T04:15:14.261Z",
      },
      {
        id: "clx5lqqtz01x612vcfpja6kb9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "85hT1qSawH",
            locked: 1717820113,
            timestamp: 1717820112,
          },
        ],
        createdAt: "2024-06-08T04:15:13.749Z",
        updatedAt: "2024-06-08T04:15:13.749Z",
      },
      {
        id: "clx5loqmi01x512vc3kcjzhuq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1nmKoKaKzw",
            locked: 1717820019,
            timestamp: 1717820015,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "8jQPSd9HO0",
            locked: 1717820019,
            timestamp: 1717820018,
          },
        ],
        createdAt: "2024-06-08T04:13:40.168Z",
        updatedAt: "2024-06-08T04:13:40.168Z",
      },
      {
        id: "clx5lm7ty01x412vcrr07n0bm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "UosKixyxY6",
            locked: 1717819901,
            timestamp: 1717819897,
          },
        ],
        createdAt: "2024-06-08T04:11:42.502Z",
        updatedAt: "2024-06-08T04:11:42.502Z",
      },
      {
        id: "clx5lm6c301x312vc96rqimcu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (34/14; 450dpi; 1080x2106; samsung; SM-S908U; b0q; qcom; en_US; 601420801)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3VVTNrQTfU",
            locked: 1717819899,
            timestamp: 1717819897,
          },
        ],
        createdAt: "2024-06-08T04:11:40.563Z",
        updatedAt: "2024-06-08T04:11:40.563Z",
      },
      {
        id: "clx5lm4zj01x212vcty2ratej",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19B74 Instagram 333.0.4.29.95 (iPhone12,1; iOS 15_1; en_US; en; scale=2.00; 828x1792; 603983235)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "QwSzsAuuV3",
            locked: 1717819898,
            timestamp: 1717819897,
          },
        ],
        createdAt: "2024-06-08T04:11:38.815Z",
        updatedAt: "2024-06-08T04:11:38.815Z",
      },
      {
        id: "clx5lm0a701x112vceh74kvx0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JOtgwrpjiI",
            locked: 1717819891,
            timestamp: 1717819885,
          },
        ],
        createdAt: "2024-06-08T04:11:32.720Z",
        updatedAt: "2024-06-08T04:11:32.720Z",
      },
      {
        id: "clx5llzld01x012vcsi7zipzv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19B74 Instagram 333.0.4.29.95 (iPhone12,1; iOS 15_1; en_US; en; scale=2.00; 828x1792; 603983235)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "z4KVgAso3t",
            locked: 1717819891,
            timestamp: 1717819890,
          },
        ],
        createdAt: "2024-06-08T04:11:31.823Z",
        updatedAt: "2024-06-08T04:11:31.823Z",
      },
      {
        id: "clx5ll29t01wz12vc2iupz0qe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zychuKRvgk",
            locked: 1717819847,
            timestamp: 1717819844,
          },
        ],
        createdAt: "2024-06-08T04:10:48.641Z",
        updatedAt: "2024-06-08T04:10:48.641Z",
      },
      {
        id: "clx5lkvu101wy12vcff3yv3fq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20F75 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Beh29UoG09",
            locked: 1717819839,
            timestamp: 1717819838,
          },
        ],
        createdAt: "2024-06-08T04:10:40.294Z",
        updatedAt: "2024-06-08T04:10:40.294Z",
      },
      {
        id: "clx5ljhvk01wx12vcqg6k0ka7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "8bjNCA8f2Z",
            locked: 1717819775,
            timestamp: 1717819774,
          },
        ],
        createdAt: "2024-06-08T04:09:35.552Z",
        updatedAt: "2024-06-08T04:09:35.552Z",
      },
      {
        id: "clx5ljg2i01ww12vcd9jac5d9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DdX4rsAZDT",
            locked: 1717819772,
            timestamp: 1717819770,
          },
        ],
        createdAt: "2024-06-08T04:09:33.209Z",
        updatedAt: "2024-06-08T04:09:33.209Z",
      },
      {
        id: "clx5lj8ti01wv12vcjfx1ilaf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WtgzVCjTj0",
            locked: 1717819762,
            timestamp: 1717819760,
          },
        ],
        createdAt: "2024-06-08T04:09:23.815Z",
        updatedAt: "2024-06-08T04:09:23.815Z",
      },
      {
        id: "clx5lj1j501wu12vciotf69ni",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "kSgrb5ljza",
            locked: 1717819744,
            timestamp: 1717819743,
          },
        ],
        createdAt: "2024-06-08T04:09:14.367Z",
        updatedAt: "2024-06-08T04:09:14.367Z",
      },
      {
        id: "clx5lhzzd01wt12vcrp7od9bf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "UllN6HrzWl",
            locked: 1717819705,
            timestamp: 1717819703,
          },
        ],
        createdAt: "2024-06-08T04:08:25.706Z",
        updatedAt: "2024-06-08T04:08:25.706Z",
      },
      {
        id: "clx5lhsfz01ws12vc28a5ya3t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "UQukXsj7tQ",
            locked: 1717819693,
            timestamp: 1717819686,
          },
        ],
        createdAt: "2024-06-08T04:08:15.933Z",
        updatedAt: "2024-06-08T04:08:15.933Z",
      },
      {
        id: "clx5lglk901wr12vci5xai92a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "adVJk2ZXCy",
            locked: 1717819639,
            timestamp: 1717819638,
          },
        ],
        createdAt: "2024-06-08T04:07:20.360Z",
        updatedAt: "2024-06-08T04:07:20.360Z",
      },
      {
        id: "clx5lgijb01wq12vc3w34jdkz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "aBuOLJWJHG",
            locked: 1717819636,
            timestamp: 1717819635,
          },
        ],
        createdAt: "2024-06-08T04:07:16.440Z",
        updatedAt: "2024-06-08T04:07:16.440Z",
      },
      {
        id: "clx5lg98x01wp12vcwb01rt5k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NbQZcaN6MC",
            locked: 1717819623,
            timestamp: 1717819622,
          },
        ],
        createdAt: "2024-06-08T04:07:04.398Z",
        updatedAt: "2024-06-08T04:07:04.398Z",
      },
      {
        id: "clx5lfme301wo12vcamkxg4j5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZP3cBgq3g6",
            locked: 1717819594,
            timestamp: 1717819592,
          },
        ],
        createdAt: "2024-06-08T04:06:34.777Z",
        updatedAt: "2024-06-08T04:06:34.777Z",
      },
      {
        id: "clx5lf8jl01wn12vc04fiolw6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "iRPP3qfyXB",
            locked: 1717819576,
            timestamp: 1717819575,
          },
        ],
        createdAt: "2024-06-08T04:06:16.832Z",
        updatedAt: "2024-06-08T04:06:16.832Z",
      },
      {
        id: "clx5lf7bi01wm12vcak3bgpme",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6IgEnCY6RA",
            locked: 1717819575,
            timestamp: 1717819574,
          },
        ],
        createdAt: "2024-06-08T04:06:15.247Z",
        updatedAt: "2024-06-08T04:06:15.247Z",
      },
      {
        id: "clx5lf5fh01wl12vcm02pmtqh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Bpcqn9J70L",
            locked: 1717819571,
            timestamp: 1717819569,
          },
        ],
        createdAt: "2024-06-08T04:06:12.797Z",
        updatedAt: "2024-06-08T04:06:12.797Z",
      },
      {
        id: "clx5lew9z01wk12vcv2ldtw8m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 750x1624; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9OqpiIuWS2",
            locked: 1717819560,
            timestamp: 1717819558,
          },
        ],
        createdAt: "2024-06-08T04:06:00.933Z",
        updatedAt: "2024-06-08T04:06:00.933Z",
      },
      {
        id: "clx5lejwk01wj12vc2b1oohm0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "H1gqPU9uod",
            locked: 1717819544,
            timestamp: 1717819542,
          },
        ],
        createdAt: "2024-06-08T04:05:44.901Z",
        updatedAt: "2024-06-08T04:05:44.901Z",
      },
      {
        id: "clx5le9ti01wi12vcuek82oq5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,6; iOS 17_5_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fXrpFT7nQD",
            locked: 1717819531,
            timestamp: 1717819529,
          },
        ],
        createdAt: "2024-06-08T04:05:31.830Z",
        updatedAt: "2024-06-08T04:05:31.830Z",
      },
      {
        id: "clx5le9pb01wh12vc8jgazafv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CrxGrJxUpx",
            locked: 1717819531,
            timestamp: 1717819530,
          },
        ],
        createdAt: "2024-06-08T04:05:31.677Z",
        updatedAt: "2024-06-08T04:05:31.677Z",
      },
      {
        id: "clx5lduzn01wg12vcvmj0li70",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YD83yNgoQa",
            locked: 1717819511,
            timestamp: 1717819510,
          },
        ],
        createdAt: "2024-06-08T04:05:12.608Z",
        updatedAt: "2024-06-08T04:05:12.608Z",
      },
      {
        id: "clx5lcu5101wf12vclsyim6ar",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YBV8h0iGgE",
            locked: 1717819464,
            timestamp: 1717819463,
          },
        ],
        createdAt: "2024-06-08T04:04:24.852Z",
        updatedAt: "2024-06-08T04:04:24.852Z",
      },
      {
        id: "clx5lcmx901we12vcq0ndsnjt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,1; iOS 16_7_8; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "as58TXt75n",
            locked: 1717819451,
            timestamp: 1717819448,
          },
        ],
        createdAt: "2024-06-08T04:04:15.502Z",
        updatedAt: "2024-06-08T04:04:15.502Z",
      },
      {
        id: "clx5lch7i01wd12vcp086h8db",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ETRfLpjwP6",
            locked: 1717819450,
            timestamp: 1717819449,
          },
        ],
        createdAt: "2024-06-08T04:04:08.095Z",
        updatedAt: "2024-06-08T04:04:08.095Z",
      },
      {
        id: "clx5lccwz01wc12vcwuyh6921",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pyFRvAkhxT",
            locked: 1717819444,
            timestamp: 1717819443,
          },
        ],
        createdAt: "2024-06-08T04:04:02.528Z",
        updatedAt: "2024-06-08T04:04:02.528Z",
      },
      {
        id: "clx5lc3bm01wb12vcanv23i4e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "79YyL8Hgz6",
            locked: 1717819429,
            timestamp: 1717819428,
          },
        ],
        createdAt: "2024-06-08T04:03:50.099Z",
        updatedAt: "2024-06-08T04:03:50.099Z",
      },
      {
        id: "clx5lbto501wa12vcjdyppzi7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YX6IMGgUdM",
            locked: 1717819416,
            timestamp: 1717819414,
          },
        ],
        createdAt: "2024-06-08T04:03:37.586Z",
        updatedAt: "2024-06-08T04:03:37.586Z",
      },
      {
        id: "clx5lasm701w912vclhnmcneo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Zc25MOWphW",
            locked: 1717819369,
            timestamp: 1717819368,
          },
        ],
        createdAt: "2024-06-08T04:02:49.567Z",
        updatedAt: "2024-06-08T04:02:49.567Z",
      },
      {
        id: "clx5laocd01w812vco91hrade",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "oCQG9L1ir8",
            locked: 1717819363,
            timestamp: 1717819361,
          },
        ],
        createdAt: "2024-06-08T04:02:44.027Z",
        updatedAt: "2024-06-08T04:02:44.027Z",
      },
      {
        id: "clx5l9kx701w712vcbbr6jmqj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kFZkksomDl",
            locked: 1717819312,
            timestamp: 1717819310,
          },
        ],
        createdAt: "2024-06-08T04:01:52.939Z",
        updatedAt: "2024-06-08T04:01:52.939Z",
      },
      {
        id: "clx5l9h9601w612vc7gs3edvk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "sg9zEPsP9T",
            locked: 1717819308,
            timestamp: 1717819307,
          },
        ],
        createdAt: "2024-06-08T04:01:48.186Z",
        updatedAt: "2024-06-08T04:01:48.186Z",
      },
      {
        id: "clx5l9ejo01w512vc2wuviaf9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GKVcSsSzvG",
            locked: 1717819304,
            timestamp: 1717819302,
          },
        ],
        createdAt: "2024-06-08T04:01:44.676Z",
        updatedAt: "2024-06-08T04:01:44.676Z",
      },
      {
        id: "clx5l99di01w412vcgztxat9y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; KYOCERA-E6920 Build/6.206AT.0139.a; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 480dpi; 1080x1776; KYOCERA; KYOCERA-E6920; E6920; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EOsjunhVL5",
            locked: 1717819297,
            timestamp: 1717819283,
          },
        ],
        createdAt: "2024-06-08T04:01:37.972Z",
        updatedAt: "2024-06-08T04:01:37.972Z",
      },
      {
        id: "clx5l8r7c01w312vc1bs3r59y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "1UjgqgpZz7",
            locked: 1717819274,
            timestamp: 1717819273,
          },
        ],
        createdAt: "2024-06-08T04:01:14.423Z",
        updatedAt: "2024-06-08T04:01:14.423Z",
      },
      {
        id: "clx5l8pzh01w212vcavpeyt3h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3yIIBIo2GS",
            locked: 1717819272,
            timestamp: 1717819271,
          },
        ],
        createdAt: "2024-06-08T04:01:12.846Z",
        updatedAt: "2024-06-08T04:01:12.846Z",
      },
      {
        id: "clx5l8fj801w112vcg7j2j5g8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OyuwKqzUms",
            locked: 1717819258,
            timestamp: 1717819257,
          },
        ],
        createdAt: "2024-06-08T04:00:59.297Z",
        updatedAt: "2024-06-08T04:00:59.297Z",
      },
      {
        id: "clx5l7ygs01w012vcltl74i7z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kJXImVi1Np",
            locked: 1717819236,
            timestamp: 1717819235,
          },
        ],
        createdAt: "2024-06-08T04:00:37.177Z",
        updatedAt: "2024-06-08T04:00:37.177Z",
      },
      {
        id: "clx5l5yc201vz12vcxb144s70",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "L2DVs6vqlq",
            locked: 1717819143,
            timestamp: 1717819140,
          },
        ],
        createdAt: "2024-06-08T03:59:03.698Z",
        updatedAt: "2024-06-08T03:59:03.698Z",
      },
      {
        id: "clx5l5tjb01vy12vcv2bcdciv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QXjbCWkBsp",
            locked: 1717819137,
            timestamp: 1717819135,
          },
        ],
        createdAt: "2024-06-08T03:58:57.479Z",
        updatedAt: "2024-06-08T03:58:57.479Z",
      },
      {
        id: "clx5l5qyr01vx12vcv4qaxibq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "WTYamNY4Ce",
            locked: 1717819133,
            timestamp: 1717819132,
          },
        ],
        createdAt: "2024-06-08T03:58:54.147Z",
        updatedAt: "2024-06-08T03:58:54.147Z",
      },
      {
        id: "clx5l5n8b01vw12vckd59at2u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8H8VQwCZBm",
            locked: 1717819128,
            timestamp: 1717819127,
          },
        ],
        createdAt: "2024-06-08T03:58:49.305Z",
        updatedAt: "2024-06-08T03:58:49.305Z",
      },
      {
        id: "clx5l53jd01vv12vcmtx0kq1e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,6; iOS 16_7_8; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "s9eHx9huwc",
            locked: 1717819103,
            timestamp: 1717819100,
          },
        ],
        createdAt: "2024-06-08T03:58:23.783Z",
        updatedAt: "2024-06-08T03:58:23.783Z",
      },
      {
        id: "clx5l4bwo01vu12vck3c7rwxf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7E8ojUIirb",
            locked: 1717819067,
            timestamp: 1717819066,
          },
        ],
        createdAt: "2024-06-08T03:57:47.974Z",
        updatedAt: "2024-06-08T03:57:47.974Z",
      },
      {
        id: "clx5l3cv301vt12vc0vbl5p8d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "S8VQ7n8Ywp",
            locked: 1717819022,
            timestamp: 1717819020,
          },
        ],
        createdAt: "2024-06-08T03:57:02.559Z",
        updatedAt: "2024-06-08T03:57:02.559Z",
      },
      {
        id: "clx5l33dq01vs12vcsq98co8t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 312.0.1.19.124 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 548339486)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "XeuSOit5Sb",
            locked: 1717819009,
            timestamp: 1717819008,
          },
        ],
        createdAt: "2024-06-08T03:56:50.268Z",
        updatedAt: "2024-06-08T03:56:50.268Z",
      },
      {
        id: "clx5l2kvd01vr12vchkrzj8tw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 334.0.4.32.98 (iPad7,5; iPadOS 15_6_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "nyJ7tEomno",
            locked: 1717818985,
            timestamp: 1717818984,
          },
        ],
        createdAt: "2024-06-08T03:56:26.278Z",
        updatedAt: "2024-06-08T03:56:26.278Z",
      },
      {
        id: "clx5l24ms01vq12vc8qd5d3og",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 334.0.4.32.98 (iPad7,5; iPadOS 15_6_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "f75ntzzGFx",
            locked: 1717818964,
            timestamp: 1717818960,
          },
        ],
        createdAt: "2024-06-08T03:56:05.233Z",
        updatedAt: "2024-06-08T03:56:05.233Z",
      },
      {
        id: "clx5l1i7u01vp12vcjxtq8nqs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2129; samsung; SM-S928U; e3q; qcom; es_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "FTrZ5RbXCm",
            locked: 1717818936,
            timestamp: 1717818935,
          },
        ],
        createdAt: "2024-06-08T03:55:36.186Z",
        updatedAt: "2024-06-08T03:55:36.186Z",
      },
      {
        id: "clx5l1g7m01vo12vceobptam9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2129; samsung; SM-S928U; e3q; qcom; es_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "eltHM0MFmM",
            locked: 1717818933,
            timestamp: 1717818931,
          },
        ],
        createdAt: "2024-06-08T03:55:33.584Z",
        updatedAt: "2024-06-08T03:55:33.584Z",
      },
      {
        id: "clx5l0tem01vn12vceiyrapd1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AsMmytKnQa",
            locked: 1717818903,
            timestamp: 1717818902,
          },
        ],
        createdAt: "2024-06-08T03:55:04.027Z",
        updatedAt: "2024-06-08T03:55:04.027Z",
      },
      {
        id: "clx5l05y301vm12vchim9uss3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RZvxs9MAQq",
            locked: 1717818873,
            timestamp: 1717818870,
          },
        ],
        createdAt: "2024-06-08T03:54:33.625Z",
        updatedAt: "2024-06-08T03:54:33.625Z",
      },
      {
        id: "clx5kzglz01vl12vcucmnj5l9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tsFBWLpp2o",
            locked: 1717818840,
            timestamp: 1717818837,
          },
        ],
        createdAt: "2024-06-08T03:54:00.791Z",
        updatedAt: "2024-06-08T03:54:00.791Z",
      },
      {
        id: "clx5kza9101vk12vc9tzcx9ne",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7aqQYjGJ1E",
            locked: 1717818832,
            timestamp: 1717818830,
          },
        ],
        createdAt: "2024-06-08T03:53:52.546Z",
        updatedAt: "2024-06-08T03:53:52.546Z",
      },
      {
        id: "clx5kywm701vj12vcer5tqen3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 326.0.3.30.91 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 585196636)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "euV0hlKqzd",
            locked: 1717818813,
            timestamp: 1717818812,
          },
        ],
        createdAt: "2024-06-08T03:53:34.880Z",
        updatedAt: "2024-06-08T03:53:34.880Z",
      },
      {
        id: "clx5kywfc01vi12vc2l4o6psb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bjI8Mc9sOG",
            locked: 1717818813,
            timestamp: 1717818812,
          },
        ],
        createdAt: "2024-06-08T03:53:34.633Z",
        updatedAt: "2024-06-08T03:53:34.633Z",
      },
      {
        id: "clx5kyt6d01vh12vcvi64fnb9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone14,5; iOS 16_6_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "BeiTfiVQvW",
            locked: 1717818809,
            timestamp: 1717818808,
          },
        ],
        createdAt: "2024-06-08T03:53:30.418Z",
        updatedAt: "2024-06-08T03:53:30.418Z",
      },
      {
        id: "clx5kxhwp01vg12vc10tyi91b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "iFt0wYYBRq",
            locked: 1717818749,
            timestamp: 1717818748,
          },
        ],
        createdAt: "2024-06-08T03:52:29.161Z",
        updatedAt: "2024-06-08T03:52:29.161Z",
      },
      {
        id: "clx5kxg1u01vf12vcey944lht",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1hIU24ukw9",
            locked: 1717818746,
            timestamp: 1717818744,
          },
        ],
        createdAt: "2024-06-08T03:52:26.753Z",
        updatedAt: "2024-06-08T03:52:26.753Z",
      },
      {
        id: "clx5kwr1401ve12vcnfjl9j72",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2123; samsung; SM-N986U; c2q; qcom; en_US; 606558499)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "iJYK1TrRwA",
            locked: 1717818713,
            timestamp: 1717818710,
          },
        ],
        createdAt: "2024-06-08T03:51:54.328Z",
        updatedAt: "2024-06-08T03:51:54.328Z",
      },
      {
        id: "clx5kwqtu01vd12vc09khfroi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IM4ZnNOkGh",
            locked: 1717818713,
            timestamp: 1717818712,
          },
        ],
        createdAt: "2024-06-08T03:51:54.063Z",
        updatedAt: "2024-06-08T03:51:54.063Z",
      },
      {
        id: "clx5kw6ud01vc12vcxdngufo8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ZQInCtJFvG",
            locked: 1717818688,
            timestamp: 1717818687,
          },
        ],
        createdAt: "2024-06-08T03:51:28.166Z",
        updatedAt: "2024-06-08T03:51:28.166Z",
      },
      {
        id: "clx5kvvgz01vb12vc5utvr6cy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6atnPqLCnJ",
            locked: 1717818673,
            timestamp: 1717818671,
          },
        ],
        createdAt: "2024-06-08T03:51:13.426Z",
        updatedAt: "2024-06-08T03:51:13.426Z",
      },
      {
        id: "clx5kvuge01va12vcenyk079n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2123; samsung; SM-N986U; c2q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vHnPLEd1Xs",
            locked: 1717818671,
            timestamp: 1717818667,
          },
        ],
        createdAt: "2024-06-08T03:51:12.111Z",
        updatedAt: "2024-06-08T03:51:12.111Z",
      },
      {
        id: "clx5kvj0h01v912vcgivdjsa0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4bCL8LIhAJ",
            locked: 1717818656,
            timestamp: 1717818655,
          },
        ],
        createdAt: "2024-06-08T03:50:57.279Z",
        updatedAt: "2024-06-08T03:50:57.279Z",
      },
      {
        id: "clx5kuzn001v812vcvyr0o5ka",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 750x1624; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FuApHeal6E",
            locked: 1717818631,
            timestamp: 1717818627,
          },
        ],
        createdAt: "2024-06-08T03:50:32.170Z",
        updatedAt: "2024-06-08T03:50:32.170Z",
      },
      {
        id: "clx5ku3e501v712vcbkhlgc6q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "n5F4xSAgfK",
            locked: 1717818590,
            timestamp: 1717818586,
          },
        ],
        createdAt: "2024-06-08T03:49:50.381Z",
        updatedAt: "2024-06-08T03:49:50.381Z",
      },
      {
        id: "clx5ktw6y01v612vcu39sdjta",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S921U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 330.0.0.40.92 Android (34/14; 480dpi; 1080x2109; samsung; SM-S921U; e1q; qcom; en_US; 596227463)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "5gfwSaYLxu",
            locked: 1717818580,
            timestamp: 1717818579,
          },
        ],
        createdAt: "2024-06-08T03:49:41.048Z",
        updatedAt: "2024-06-08T03:49:41.048Z",
      },
      {
        id: "clx5ktcwp01v512vcrzmam4sc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad8,11; iPadOS 17_5_1; en_US; en; scale=2.00; 780x1688; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pueI0qVQbh",
            locked: 1717818555,
            timestamp: 1717818554,
          },
        ],
        createdAt: "2024-06-08T03:49:16.056Z",
        updatedAt: "2024-06-08T03:49:16.056Z",
      },
      {
        id: "clx5kt7yz01v412vcn8oflu9g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S921U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 330.0.0.40.92 Android (34/14; 480dpi; 1080x2109; samsung; SM-S921U; e1q; qcom; en_US; 596227463)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vVUQDGLOBP",
            locked: 1717818548,
            timestamp: 1717818545,
          },
        ],
        createdAt: "2024-06-08T03:49:09.660Z",
        updatedAt: "2024-06-08T03:49:09.660Z",
      },
      {
        id: "clx5kt3k501v312vcnq2xeopp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HXmcPIbxBM",
            locked: 1717818543,
            timestamp: 1717818541,
          },
        ],
        createdAt: "2024-06-08T03:49:03.941Z",
        updatedAt: "2024-06-08T03:49:03.941Z",
      },
      {
        id: "clx5ksxfh01v212vc7t0y60x8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YP54s3Le3o",
            locked: 1717818535,
            timestamp: 1717818534,
          },
        ],
        createdAt: "2024-06-08T03:48:55.995Z",
        updatedAt: "2024-06-08T03:48:55.995Z",
      },
      {
        id: "clx5kskd901v112vco7smbjb4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U3 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 480dpi; 1080x2196; samsung; SM-G990U3; r9q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6iqaEFnqmD",
            locked: 1717818518,
            timestamp: 1717818515,
          },
        ],
        createdAt: "2024-06-08T03:48:39.070Z",
        updatedAt: "2024-06-08T03:48:39.070Z",
      },
      {
        id: "clx5ksgqf01v012vcrkmevwi1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "E88N2l705A",
            locked: 1717818513,
            timestamp: 1717818511,
          },
        ],
        createdAt: "2024-06-08T03:48:34.360Z",
        updatedAt: "2024-06-08T03:48:34.360Z",
      },
      {
        id: "clx5ksf2001uz12vcxoent7pe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "tU7pcj4mu3",
            locked: 1717818512,
            timestamp: 1717818508,
          },
        ],
        createdAt: "2024-06-08T03:48:32.181Z",
        updatedAt: "2024-06-08T03:48:32.181Z",
      },
      {
        id: "clx5ks60h01uy12vcyc2qkstc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "EwVp1ZtPDI",
            locked: 1717818500,
            timestamp: 1717818498,
          },
        ],
        createdAt: "2024-06-08T03:48:20.465Z",
        updatedAt: "2024-06-08T03:48:20.465Z",
      },
      {
        id: "clx5ks11c01ux12vcqrskr0aj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9BXPy7Jcjf",
            locked: 1717818489,
            timestamp: 1717818488,
          },
        ],
        createdAt: "2024-06-08T03:48:14.014Z",
        updatedAt: "2024-06-08T03:48:14.014Z",
      },
      {
        id: "clx5krp3t01uw12vc9ka53iyh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "gjp17BpWSV",
            locked: 1717818478,
            timestamp: 1717818477,
          },
        ],
        createdAt: "2024-06-08T03:47:58.550Z",
        updatedAt: "2024-06-08T03:47:58.550Z",
      },
      {
        id: "clx5krbhu01uv12vc67us9n0r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Tq4zJG0BDY",
            locked: 1717818459,
            timestamp: 1717818440,
          },
        ],
        createdAt: "2024-06-08T03:47:40.915Z",
        updatedAt: "2024-06-08T03:47:40.915Z",
      },
      {
        id: "clx5kr9w601uu12vc1746cuat",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D67 Instagram 334.0.4.32.98 (iPhone13,1; iOS 16_3_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tIuEpk9fwB",
            locked: 1717818458,
            timestamp: 1717818455,
          },
        ],
        createdAt: "2024-06-08T03:47:38.836Z",
        updatedAt: "2024-06-08T03:47:38.836Z",
      },
      {
        id: "clx5knurn01ut12vcbiwv1628",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AkttZbCn69",
            locked: 1717818298,
            timestamp: 1717818297,
          },
        ],
        createdAt: "2024-06-08T03:44:59.267Z",
        updatedAt: "2024-06-08T03:44:59.267Z",
      },
      {
        id: "clx5knldy01us12vc0ko9wzpo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G973U1 Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 420dpi; 1080x2042; samsung; SM-G973U1; beyond1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0vCgkOhxwK",
            locked: 1717818285,
            timestamp: 1717818282,
          },
        ],
        createdAt: "2024-06-08T03:44:47.107Z",
        updatedAt: "2024-06-08T03:44:47.107Z",
      },
      {
        id: "clx5kn5m901ur12vcn6d8z2g8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iMWXaH4NmA",
            locked: 1717818266,
            timestamp: 1717818264,
          },
        ],
        createdAt: "2024-06-08T03:44:26.673Z",
        updatedAt: "2024-06-08T03:44:26.673Z",
      },
      {
        id: "clx5kmx5u01uq12vcvhaefivt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "s9HOEg19WX",
            locked: 1717818254,
            timestamp: 1717818250,
          },
        ],
        createdAt: "2024-06-08T03:44:15.711Z",
        updatedAt: "2024-06-08T03:44:15.711Z",
      },
      {
        id: "clx5klpej01up12vc6sdhcm7k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ChgTPHUljS",
            locked: 1717818198,
            timestamp: 1717818197,
          },
        ],
        createdAt: "2024-06-08T03:43:19.003Z",
        updatedAt: "2024-06-08T03:43:19.003Z",
      },
      {
        id: "clx5klmrw01uo12vc5m4ru5j2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "aTsMsoJYxg",
            locked: 1717818195,
            timestamp: 1717818193,
          },
        ],
        createdAt: "2024-06-08T03:43:15.594Z",
        updatedAt: "2024-06-08T03:43:15.594Z",
      },
      {
        id: "clx5kjtsz01un12vcq3ja03p0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 680dpi; 1440x2784; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Lf5b5IsFlF",
            locked: 1717818110,
            timestamp: 1717818106,
          },
        ],
        createdAt: "2024-06-08T03:41:51.395Z",
        updatedAt: "2024-06-08T03:41:51.395Z",
      },
      {
        id: "clx5kjldq01um12vcqq17z87v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone14,5; iOS 16_6_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UNcPcARzvZ",
            locked: 1717818100,
            timestamp: 1717818098,
          },
        ],
        createdAt: "2024-06-08T03:41:40.476Z",
        updatedAt: "2024-06-08T03:41:40.476Z",
      },
      {
        id: "clx5kiy5401ul12vc88lrk89h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vpUdWTrJ9F",
            locked: 1717818068,
            timestamp: 1717818067,
          },
        ],
        createdAt: "2024-06-08T03:41:10.357Z",
        updatedAt: "2024-06-08T03:41:10.357Z",
      },
      {
        id: "clx5kh6b001uk12vcqofhb0g0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "t8eqmtdCGw",
            locked: 1717817987,
            timestamp: 1717817985,
          },
        ],
        createdAt: "2024-06-08T03:39:47.626Z",
        updatedAt: "2024-06-08T03:39:47.626Z",
      },
      {
        id: "clx5kg00x01uj12vcxfjbgbq2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jnEgQjPUN7",
            locked: 1717817932,
            timestamp: 1717817930,
          },
        ],
        createdAt: "2024-06-08T03:38:52.831Z",
        updatedAt: "2024-06-08T03:38:52.831Z",
      },
      {
        id: "clx5kfbpi01ui12vcwb5iec83",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "idmPZbzss9",
            locked: 1717817900,
            timestamp: 1717817899,
          },
        ],
        createdAt: "2024-06-08T03:38:21.318Z",
        updatedAt: "2024-06-08T03:38:21.318Z",
      },
      {
        id: "clx5kfbn701uh12vct2b3esd6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gjiFYMegyE",
            locked: 1717817900,
            timestamp: 1717817898,
          },
        ],
        createdAt: "2024-06-08T03:38:21.233Z",
        updatedAt: "2024-06-08T03:38:21.233Z",
      },
      {
        id: "clx5kesq101ug12vciu4hmhp1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "28Kqx0wpp9",
            locked: 1717817876,
            timestamp: 1717817875,
          },
        ],
        createdAt: "2024-06-08T03:37:56.713Z",
        updatedAt: "2024-06-08T03:37:56.713Z",
      },
      {
        id: "clx5keprn01uf12vcsej33l65",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tOKi8p03oO",
            locked: 1717817872,
            timestamp: 1717817871,
          },
        ],
        createdAt: "2024-06-08T03:37:52.884Z",
        updatedAt: "2024-06-08T03:37:52.884Z",
      },
      {
        id: "clx5kep6m01ue12vcg4czws34",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jfMCSH0Of9",
            locked: 1717817871,
            timestamp: 1717817869,
          },
        ],
        createdAt: "2024-06-08T03:37:52.126Z",
        updatedAt: "2024-06-08T03:37:52.126Z",
      },
      {
        id: "clx5keoxx01ud12vcfh3pml9q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "E6gqGANdFu",
            locked: 1717817871,
            timestamp: 1717817868,
          },
        ],
        createdAt: "2024-06-08T03:37:51.811Z",
        updatedAt: "2024-06-08T03:37:51.811Z",
      },
      {
        id: "clx5ke8v101uc12vc1ine0mbf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CptublsLVb",
            locked: 1717817849,
            timestamp: 1717817848,
          },
        ],
        createdAt: "2024-06-08T03:37:30.973Z",
        updatedAt: "2024-06-08T03:37:30.973Z",
      },
      {
        id: "clx5ke7zk01ub12vc0fs9fw0w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ix8dmu2y2m",
            locked: 1717817849,
            timestamp: 1717817848,
          },
        ],
        createdAt: "2024-06-08T03:37:29.838Z",
        updatedAt: "2024-06-08T03:37:29.838Z",
      },
      {
        id: "clx5kdpso01ua12vcjicyxlye",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RVTQGzDUsT",
            locked: 1717817825,
            timestamp: 1717817821,
          },
        ],
        createdAt: "2024-06-08T03:37:06.264Z",
        updatedAt: "2024-06-08T03:37:06.264Z",
      },
      {
        id: "clx5kdmpf01u912vc4auf0asm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lU6EGBtktB",
            locked: 1717817821,
            timestamp: 1717817820,
          },
        ],
        createdAt: "2024-06-08T03:37:02.257Z",
        updatedAt: "2024-06-08T03:37:02.257Z",
      },
      {
        id: "clx5kcjbk01u812vcwthmha9w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JXFme5ltLx",
            locked: 1717817770,
            timestamp: 1717817769,
          },
        ],
        createdAt: "2024-06-08T03:36:11.213Z",
        updatedAt: "2024-06-08T03:36:11.213Z",
      },
      {
        id: "clx5kbc6r01u712vcnxkfu9qy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "8RtuZOWaFn",
            locked: 1717817715,
            timestamp: 1717817714,
          },
        ],
        createdAt: "2024-06-08T03:35:15.315Z",
        updatedAt: "2024-06-08T03:35:15.315Z",
      },
      {
        id: "clx5kbbt801u612vcjluwxqwk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G991U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2176; samsung; SM-G991U; o1q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hwY7QQkeTT",
            locked: 1717817714,
            timestamp: 1717817710,
          },
        ],
        createdAt: "2024-06-08T03:35:14.826Z",
        updatedAt: "2024-06-08T03:35:14.826Z",
      },
      {
        id: "clx5katgh01u512vcpfe41zdm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "R63zM8EGHy",
            locked: 1717817690,
            timestamp: 1717817688,
          },
        ],
        createdAt: "2024-06-08T03:34:51.041Z",
        updatedAt: "2024-06-08T03:34:51.041Z",
      },
      {
        id: "clx5kamd701u412vcmfs20hec",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0sJ2I5x4jO",
            locked: 1717817681,
            timestamp: 1717817679,
          },
        ],
        createdAt: "2024-06-08T03:34:41.851Z",
        updatedAt: "2024-06-08T03:34:41.851Z",
      },
      {
        id: "clx5kal5q01u312vctsts5dhg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "TXqPl5LolI",
            locked: 1717817679,
            timestamp: 1717817678,
          },
        ],
        createdAt: "2024-06-08T03:34:40.284Z",
        updatedAt: "2024-06-08T03:34:40.284Z",
      },
      {
        id: "clx5ka9jn01u212vc0owj7zhv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "VN4xkECAxt",
            locked: 1717817665,
            timestamp: 1717817664,
          },
        ],
        createdAt: "2024-06-08T03:34:25.235Z",
        updatedAt: "2024-06-08T03:34:25.235Z",
      },
      {
        id: "clx5ka7qu01u112vc0czjupwt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AB7u0t9CeF",
            locked: 1717817662,
            timestamp: 1717817660,
          },
        ],
        createdAt: "2024-06-08T03:34:22.901Z",
        updatedAt: "2024-06-08T03:34:22.901Z",
      },
      {
        id: "clx5k9vhi01u012vcap9hy6h5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.53 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2131; samsung; SM-S906U; g0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cahSWqCy0g",
            locked: 1717817646,
            timestamp: 1717817642,
          },
        ],
        createdAt: "2024-06-08T03:34:07.015Z",
        updatedAt: "2024-06-08T03:34:07.015Z",
      },
      {
        id: "clx5k9t7501tz12vc5ebj2qed",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zRAuojwrWp",
            locked: 1717817643,
            timestamp: 1717817641,
          },
        ],
        createdAt: "2024-06-08T03:34:04.046Z",
        updatedAt: "2024-06-08T03:34:04.046Z",
      },
      {
        id: "clx5k8mto01ty12vcrbkyhvb7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4 Mobile/15E148 Safari/604.1 Ddg/17.4",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "gw53E0Mal2",
            locked: 1717817589,
            timestamp: 1717817587,
          },
        ],
        createdAt: "2024-06-08T03:33:09.132Z",
        updatedAt: "2024-06-08T03:33:09.132Z",
      },
      {
        id: "clx5k8mlh01tx12vcqnci932p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; LM-V600 Build/RKQ1.201105.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 300.0.0.29.110 Android (30/11; 420dpi; 1080x2342; LGE/lge; LM-V600; timelm; timelm; en_US; 515103460)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "79c8YadBRy",
            locked: 1717817587,
            timestamp: 1717817581,
          },
        ],
        createdAt: "2024-06-08T03:33:08.838Z",
        updatedAt: "2024-06-08T03:33:08.838Z",
      },
      {
        id: "clx5k8k9m01tw12vcpxvw7voa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4 Mobile/15E148 Safari/604.1 Ddg/17.4",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xVKBXdfFSk",
            locked: 1717817585,
            timestamp: 1717817584,
          },
        ],
        createdAt: "2024-06-08T03:33:05.819Z",
        updatedAt: "2024-06-08T03:33:05.819Z",
      },
      {
        id: "clx5k8g0k01tv12vcy2ibd6ab",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HppcvzpYMf",
            locked: 1717817579,
            timestamp: 1717817578,
          },
        ],
        createdAt: "2024-06-08T03:33:00.303Z",
        updatedAt: "2024-06-08T03:33:00.303Z",
      },
      {
        id: "clx5k823g01tu12vcimeu99oa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S921U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2109; samsung; SM-S921U; e1q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "oVO7EIHTA5",
            locked: 1717817561,
            timestamp: 1717817559,
          },
        ],
        createdAt: "2024-06-08T03:32:42.266Z",
        updatedAt: "2024-06-08T03:32:42.266Z",
      },
      {
        id: "clx5k66we01tt12vcpvly91fk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "QRIUL1cx8G",
            locked: 1717817475,
            timestamp: 1717817474,
          },
        ],
        createdAt: "2024-06-08T03:31:15.182Z",
        updatedAt: "2024-06-08T03:31:15.182Z",
      },
      {
        id: "clx5k64gm01ts12vcf8pmmnu4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 14_8_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/18H107 Instagram 313.0.2.9.339 (iPhone13,3; iOS 14_8_1; en_US; en; scale=3.00; 1170x2532; 553462334)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "QoqswG724I",
            locked: 1717817471,
            timestamp: 1717817470,
          },
        ],
        createdAt: "2024-06-08T03:31:12.022Z",
        updatedAt: "2024-06-08T03:31:12.022Z",
      },
      {
        id: "clx5k62pb01tr12vcxeqk21eg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "WvANSqAsaY",
            locked: 1717817470,
            timestamp: 1717817469,
          },
        ],
        createdAt: "2024-06-08T03:31:09.742Z",
        updatedAt: "2024-06-08T03:31:09.742Z",
      },
      {
        id: "clx5k5w2u01tq12vcwvumqkkp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jGcpIpKrol",
            locked: 1717817460,
            timestamp: 1717817456,
          },
        ],
        createdAt: "2024-06-08T03:31:01.159Z",
        updatedAt: "2024-06-08T03:31:01.159Z",
      },
      {
        id: "clx5k5qe901tp12vcucv5a1eo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZIQsC5lo5t",
            locked: 1717817452,
            timestamp: 1717817450,
          },
        ],
        createdAt: "2024-06-08T03:30:53.791Z",
        updatedAt: "2024-06-08T03:30:53.791Z",
      },
      {
        id: "clx5k4r8901to12vcs9ifih85",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 14_8_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/18H107 Instagram 313.0.2.9.339 (iPhone13,3; iOS 14_8_1; en_US; en; scale=3.00; 1170x2532; 553462334)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ypLb3UMJ2d",
            locked: 1717817407,
            timestamp: 1717817406,
          },
        ],
        createdAt: "2024-06-08T03:30:08.215Z",
        updatedAt: "2024-06-08T03:30:08.215Z",
      },
      {
        id: "clx5k3uzl01tn12vcnx6gd5fl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 601420801)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "AzF0PqZEoG",
            locked: 1717817365,
            timestamp: 1717817364,
          },
        ],
        createdAt: "2024-06-08T03:29:26.430Z",
        updatedAt: "2024-06-08T03:29:26.430Z",
      },
      {
        id: "clx5k2o5901tm12vcerpxaa5g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 601420801)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CjhwufcHys",
            locked: 1717817310,
            timestamp: 1717817308,
          },
        ],
        createdAt: "2024-06-08T03:28:30.906Z",
        updatedAt: "2024-06-08T03:28:30.906Z",
      },
      {
        id: "clx5k10xa01tl12vc0a9lr3nj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 328.1.3.32.89 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 591155251)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "PYfE5xiGQU",
            locked: 1717817234,
            timestamp: 1717817232,
          },
        ],
        createdAt: "2024-06-08T03:27:14.156Z",
        updatedAt: "2024-06-08T03:27:14.156Z",
      },
      {
        id: "clx5k0ftk01tk12vchy290z2p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 328.1.3.32.89 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 591155251)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "roepSuexIj",
            locked: 1717817206,
            timestamp: 1717817202,
          },
        ],
        createdAt: "2024-06-08T03:26:46.806Z",
        updatedAt: "2024-06-08T03:26:46.806Z",
      },
      {
        id: "clx5jzi0k01tj12vct35rh86z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 328.1.3.32.89 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 591155251)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nydlNcCwG8",
            locked: 1717817162,
            timestamp: 1717817158,
          },
        ],
        createdAt: "2024-06-08T03:26:02.997Z",
        updatedAt: "2024-06-08T03:26:02.997Z",
      },
      {
        id: "clx5jzf2e01ti12vcciyfo43g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bqAjXTJxh4",
            locked: 1717817158,
            timestamp: 1717817157,
          },
        ],
        createdAt: "2024-06-08T03:25:59.172Z",
        updatedAt: "2024-06-08T03:25:59.172Z",
      },
      {
        id: "clx5jz2mb01th12vco1p087xw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 12_5_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 264.3.0.19.104 (iPad4,4; iOS 12_5_7; en_US; en-US; scale=2.00; 750x1334; 432065435)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CTb9lkp5fG",
            locked: 1717817142,
            timestamp: 1717817134,
          },
        ],
        createdAt: "2024-06-08T03:25:43.041Z",
        updatedAt: "2024-06-08T03:25:43.041Z",
      },
      {
        id: "clx5jyojj01tg12vcvzzdfth5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20F75 Instagram 334.0.4.32.98 (iPhone14,2; iOS 16_5_1; es_US; es; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "kJjIIKuBc5",
            locked: 1717817124,
            timestamp: 1717817123,
          },
        ],
        createdAt: "2024-06-08T03:25:24.797Z",
        updatedAt: "2024-06-08T03:25:24.797Z",
      },
      {
        id: "clx5jy2gk01tf12vcjf0kdi6e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20F75 Instagram 334.0.4.32.98 (iPhone14,2; iOS 16_5_1; es_US; es; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "J1gn0NxkTf",
            locked: 1717817095,
            timestamp: 1717817094,
          },
        ],
        createdAt: "2024-06-08T03:24:56.178Z",
        updatedAt: "2024-06-08T03:24:56.178Z",
      },
      {
        id: "clx5jxgms01te12vcbnb6s4up",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "dg24NCpfNK",
            locked: 1717817067,
            timestamp: 1717817066,
          },
        ],
        createdAt: "2024-06-08T03:24:27.892Z",
        updatedAt: "2024-06-08T03:24:27.892Z",
      },
      {
        id: "clx5jxcu301td12vc5x6dd9pt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RgRm17Brbe",
            locked: 1717817061,
            timestamp: 1717817060,
          },
        ],
        createdAt: "2024-06-08T03:24:22.969Z",
        updatedAt: "2024-06-08T03:24:22.969Z",
      },
      {
        id: "clx5jvyak01tc12vch8wj1w3x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "1tSZKSTG0X",
            locked: 1717817005,
            timestamp: 1717817004,
          },
        ],
        createdAt: "2024-06-08T03:23:17.468Z",
        updatedAt: "2024-06-08T03:23:17.468Z",
      },
      {
        id: "clx5jvv0f01tb12vc155fyt5r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone14,7; iOS 16_1_2; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VN3xtk7Hub",
            locked: 1717816992,
            timestamp: 1717816991,
          },
        ],
        createdAt: "2024-06-08T03:23:13.216Z",
        updatedAt: "2024-06-08T03:23:13.216Z",
      },
      {
        id: "clx5jvujz01ta12vcvss1p7lr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPad11,4; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 604797909)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vAvGWWviyh",
            locked: 1717816992,
            timestamp: 1717816990,
          },
        ],
        createdAt: "2024-06-08T03:23:12.624Z",
        updatedAt: "2024-06-08T03:23:12.624Z",
      },
      {
        id: "clx5jvr8201t912vce20bqlux",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad12,1; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "t94Ld6XQSh",
            locked: 1717816987,
            timestamp: 1717816985,
          },
        ],
        createdAt: "2024-06-08T03:23:08.305Z",
        updatedAt: "2024-06-08T03:23:08.305Z",
      },
      {
        id: "clx5jvo2c01t812vcriilto3n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 278.0.0.19.115 (iPhone13,3; iOS 16_4_1; en_US; en-US; scale=3.00; 1170x2532; 463736449)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CpeMSmxjra",
            locked: 1717816982,
            timestamp: 1717816981,
          },
        ],
        createdAt: "2024-06-08T03:23:04.212Z",
        updatedAt: "2024-06-08T03:23:04.212Z",
      },
      {
        id: "clx5jvnzn01t712vcqrwe65lo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NpI5Cg4Tkg",
            locked: 1717816991,
            timestamp: 1717816988,
          },
        ],
        createdAt: "2024-06-08T03:23:04.116Z",
        updatedAt: "2024-06-08T03:23:04.116Z",
      },
      {
        id: "clx5jveb801t612vcxe0nal4i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-X200 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 240dpi; 1200x1848; samsung; SM-X200; gta8wifi; ums512_25c10; en_US; 604247851)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "bFyW8IXXup",
            locked: 1717816967,
            timestamp: 1717816966,
          },
        ],
        createdAt: "2024-06-08T03:22:51.572Z",
        updatedAt: "2024-06-08T03:22:51.572Z",
      },
      {
        id: "clx5jvdu701t512vctqfhipd4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-X200 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 240dpi; 1200x1848; samsung; SM-X200; gta8wifi; ums512_25c10; en_US; 604247851)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xSzAuqvViq",
            locked: 1717816966,
            timestamp: 1717816958,
          },
        ],
        createdAt: "2024-06-08T03:22:50.957Z",
        updatedAt: "2024-06-08T03:22:50.957Z",
      },
      {
        id: "clx5juy9d01t412vchbxqv1q1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MToyEr22HQ",
            locked: 1717816949,
            timestamp: 1717816948,
          },
        ],
        createdAt: "2024-06-08T03:22:30.767Z",
        updatedAt: "2024-06-08T03:22:30.767Z",
      },
      {
        id: "clx5jumzl01t312vc9gbyu0qk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 328.1.3.32.89 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 591155251)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xiI84hPrFM",
            locked: 1717816935,
            timestamp: 1717816934,
          },
        ],
        createdAt: "2024-06-08T03:22:16.162Z",
        updatedAt: "2024-06-08T03:22:16.162Z",
      },
      {
        id: "clx5juk9601t212vchanwxozq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A146U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2208; samsung; SM-A146U; a14xm; mt6833; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EZD7XpFmDS",
            locked: 1717816931,
            timestamp: 1717816925,
          },
        ],
        createdAt: "2024-06-08T03:22:12.616Z",
        updatedAt: "2024-06-08T03:22:12.616Z",
      },
      {
        id: "clx5jtezf01t112vcpom5yzid",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "CClRSxN6yN",
            locked: 1717816878,
            timestamp: 1717816874,
          },
        ],
        createdAt: "2024-06-08T03:21:19.132Z",
        updatedAt: "2024-06-08T03:21:19.132Z",
      },
      {
        id: "clx5jt6sl01t012vcx7u0dqgs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4NX8WVUgaF",
            locked: 1717816868,
            timestamp: 1717816866,
          },
        ],
        createdAt: "2024-06-08T03:21:08.516Z",
        updatedAt: "2024-06-08T03:21:08.516Z",
      },
      {
        id: "clx5jszpa01sz12vcwkzzy374",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "o4whksV5qn",
            locked: 1717816858,
            timestamp: 1717816857,
          },
        ],
        createdAt: "2024-06-08T03:20:59.327Z",
        updatedAt: "2024-06-08T03:20:59.327Z",
      },
      {
        id: "clx5jsrwb01sy12vcz2vf61zi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "EyN7M9OUbr",
            locked: 1717816861,
            timestamp: 1717816859,
          },
        ],
        createdAt: "2024-06-08T03:20:49.207Z",
        updatedAt: "2024-06-08T03:20:49.207Z",
      },
      {
        id: "clx5jrym101sx12vcpjb3padx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G781U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2168; samsung; SM-G781U; r8q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "RXDPt6bJJK",
            locked: 1717816810,
            timestamp: 1717816809,
          },
        ],
        createdAt: "2024-06-08T03:20:11.257Z",
        updatedAt: "2024-06-08T03:20:11.257Z",
      },
      {
        id: "clx5jrufa01sw12vcv7u4vxiz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G781U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2168; samsung; SM-G781U; r8q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OHWxtBdOjh",
            locked: 1717816805,
            timestamp: 1717816799,
          },
        ],
        createdAt: "2024-06-08T03:20:05.830Z",
        updatedAt: "2024-06-08T03:20:05.830Z",
      },
      {
        id: "clx5jrr1z01sv12vcwlfnqii9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; 2201116SG Build/TKQ1.221114.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 440dpi; 1080x2180; Xiaomi/Redmi; 2201116SG; veux; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "sMlHpu9U9O",
            locked: 1717816801,
            timestamp: 1717816800,
          },
        ],
        createdAt: "2024-06-08T03:20:01.464Z",
        updatedAt: "2024-06-08T03:20:01.464Z",
      },
      {
        id: "clx5jrq2701su12vc6w05m47l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; 2201116SG Build/TKQ1.221114.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 440dpi; 1080x2180; Xiaomi/Redmi; 2201116SG; veux; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JJomB2GcXE",
            locked: 1717816799,
            timestamp: 1717816792,
          },
        ],
        createdAt: "2024-06-08T03:20:00.173Z",
        updatedAt: "2024-06-08T03:20:00.173Z",
      },
      {
        id: "clx5jqqzj01st12vcd20zleip",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 315.1.2.21.112 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 559614958)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AiAnwkZD5H",
            locked: 1717816754,
            timestamp: 1717816752,
          },
        ],
        createdAt: "2024-06-08T03:19:14.718Z",
        updatedAt: "2024-06-08T03:19:14.718Z",
      },
      {
        id: "clx5jpme101ss12vc0g6ntbee",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; arm_64; Android 14; Pixel 7a) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.6261.39 YaBrowser/24.4.7.39.00 SA/3 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "eViqXF0K4M",
            locked: 1717816703,
            timestamp: 1717816702,
          },
        ],
        createdAt: "2024-06-08T03:18:22.102Z",
        updatedAt: "2024-06-08T03:18:22.102Z",
      },
      {
        id: "clx5jpdfj01sr12vcsj9dxdan",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; arm_64; Android 14; Pixel 7a) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.6261.39 YaBrowser/24.4.7.39.00 SA/3 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jHB7i7R2TI",
            locked: 1717816691,
            timestamp: 1717816689,
          },
        ],
        createdAt: "2024-06-08T03:18:10.495Z",
        updatedAt: "2024-06-08T03:18:10.495Z",
      },
      {
        id: "clx5jpcm901sq12vck2dxile5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9HvHPu0MaR",
            locked: 1717816688,
            timestamp: 1717816687,
          },
        ],
        createdAt: "2024-06-08T03:18:09.442Z",
        updatedAt: "2024-06-08T03:18:09.442Z",
      },
      {
        id: "clx5jpabx01sp12vcy08m7i7a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S711U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2218; samsung; SM-S711U; r11q; qcom; en_US; 606558490)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "j6pE93E2Eh",
            locked: 1717816685,
            timestamp: 1717816684,
          },
        ],
        createdAt: "2024-06-08T03:18:06.474Z",
        updatedAt: "2024-06-08T03:18:06.474Z",
      },
      {
        id: "clx5jonla01so12vcyenmubkb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TMEbcRleds",
            locked: 1717816656,
            timestamp: 1717816654,
          },
        ],
        createdAt: "2024-06-08T03:17:37.004Z",
        updatedAt: "2024-06-08T03:17:37.004Z",
      },
      {
        id: "clx5jnzly01sn12vcdag46w2l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "6DYqhWGnQH",
            locked: 1717816625,
            timestamp: 1717816624,
          },
        ],
        createdAt: "2024-06-08T03:17:05.926Z",
        updatedAt: "2024-06-08T03:17:05.926Z",
      },
      {
        id: "clx5jnyay01sm12vcxszkefxo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mu4cwFCkJ3",
            locked: 1717816623,
            timestamp: 1717816622,
          },
        ],
        createdAt: "2024-06-08T03:17:04.232Z",
        updatedAt: "2024-06-08T03:17:04.232Z",
      },
      {
        id: "clx5jnfhf01sl12vctymszob0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "SjaNSiVWHh",
            locked: 1717816599,
            timestamp: 1717816598,
          },
        ],
        createdAt: "2024-06-08T03:16:39.843Z",
        updatedAt: "2024-06-08T03:16:39.843Z",
      },
      {
        id: "clx5jn8qe01sk12vcoybdd7qg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kGA5gvgGT7",
            locked: 1717816590,
            timestamp: 1717816587,
          },
        ],
        createdAt: "2024-06-08T03:16:31.094Z",
        updatedAt: "2024-06-08T03:16:31.094Z",
      },
      {
        id: "clx5jn7s401sj12vcfqhfj2jf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 236.1.0.22.111 (iPhone14,2; iOS 17_2_1; en_US; en-US; scale=3.00; 1170x2532; 372089098)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7bgiHQjmq7",
            locked: 1717816586,
            timestamp: 1717816585,
          },
        ],
        createdAt: "2024-06-08T03:16:29.859Z",
        updatedAt: "2024-06-08T03:16:29.859Z",
      },
      {
        id: "clx5jn0ew01si12vc3dfemv16",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "i7LViP2jbn",
            locked: 1717816580,
            timestamp: 1717816577,
          },
        ],
        createdAt: "2024-06-08T03:16:20.312Z",
        updatedAt: "2024-06-08T03:16:20.312Z",
      },
      {
        id: "clx5jmx7w01sh12vcc7fctlp5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "sPWPFFblYj",
            locked: 1717816575,
            timestamp: 1717816573,
          },
        ],
        createdAt: "2024-06-08T03:16:16.172Z",
        updatedAt: "2024-06-08T03:16:16.172Z",
      },
      {
        id: "clx5jmtkh01sg12vc2acx7h9r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3og8v8I56y",
            locked: 1717816570,
            timestamp: 1717816569,
          },
        ],
        createdAt: "2024-06-08T03:16:11.442Z",
        updatedAt: "2024-06-08T03:16:11.442Z",
      },
      {
        id: "clx5jmtf201sf12vczbryylt6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lZBkPIrRge",
            locked: 1717816570,
            timestamp: 1717816569,
          },
        ],
        createdAt: "2024-06-08T03:16:11.247Z",
        updatedAt: "2024-06-08T03:16:11.247Z",
      },
      {
        id: "clx5jmr4b01se12vcdqyccyjc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G973U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 540dpi; 1080x2006; samsung; SM-G973U; beyond1q; qcom; en_US; 604247855)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "XtGqkuGtQb",
            locked: 1717816568,
            timestamp: 1717816567,
          },
        ],
        createdAt: "2024-06-08T03:16:08.264Z",
        updatedAt: "2024-06-08T03:16:08.264Z",
      },
      {
        id: "clx5jm5fg01sd12vcj9lw65pq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "X3wATxTQqL",
            locked: 1717816539,
            timestamp: 1717816538,
          },
        ],
        createdAt: "2024-06-08T03:15:40.157Z",
        updatedAt: "2024-06-08T03:15:40.157Z",
      },
      {
        id: "clx5jm2qc01sc12vcia08n8r9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G973U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 540dpi; 1080x2006; samsung; SM-G973U; beyond1q; qcom; en_US; 604247855)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "6eJam8nx4L",
            locked: 1717816535,
            timestamp: 1717816531,
          },
        ],
        createdAt: "2024-06-08T03:15:36.661Z",
        updatedAt: "2024-06-08T03:15:36.661Z",
      },
      {
        id: "clx5jlu7q01sb12vc5hgrl87n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PQJXETVwce",
            locked: 1717816524,
            timestamp: 1717816522,
          },
        ],
        createdAt: "2024-06-08T03:15:25.619Z",
        updatedAt: "2024-06-08T03:15:25.619Z",
      },
      {
        id: "clx5jkwfj01sa12vcpemwn5m3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A716U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 450dpi; 1080x2174; samsung; SM-A716U; a71xq; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5fXc8v8rOn",
            locked: 1717816481,
            timestamp: 1717816477,
          },
        ],
        createdAt: "2024-06-08T03:14:41.836Z",
        updatedAt: "2024-06-08T03:14:41.836Z",
      },
      {
        id: "clx5jjrgw01s912vcwy6wg9ce",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone11,6; iOS 16_6_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "YZ8q9xRznt",
            locked: 1717816428,
            timestamp: 1717816427,
          },
        ],
        createdAt: "2024-06-08T03:13:48.750Z",
        updatedAt: "2024-06-08T03:13:48.750Z",
      },
      {
        id: "clx5jixww01s812vcb6acg9rp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone11,6; iOS 16_6_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "L8eC6WNH50",
            locked: 1717816389,
            timestamp: 1717816387,
          },
        ],
        createdAt: "2024-06-08T03:13:10.448Z",
        updatedAt: "2024-06-08T03:13:10.448Z",
      },
      {
        id: "clx5jiuco01s712vcquik6h1s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5IQ6Pk1TZT",
            locked: 1717816385,
            timestamp: 1717816383,
          },
        ],
        createdAt: "2024-06-08T03:13:05.832Z",
        updatedAt: "2024-06-08T03:13:05.832Z",
      },
      {
        id: "clx5jimur01s612vcled2jjq0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 560dpi; 1440x2820; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "tN5XyknA8r",
            locked: 1717816375,
            timestamp: 1717816374,
          },
        ],
        createdAt: "2024-06-08T03:12:56.112Z",
        updatedAt: "2024-06-08T03:12:56.112Z",
      },
      {
        id: "clx5ji4r901s512vcmimpwk35",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 560dpi; 1440x2820; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QLjrJ2BBtW",
            locked: 1717816352,
            timestamp: 1717816349,
          },
        ],
        createdAt: "2024-06-08T03:12:32.658Z",
        updatedAt: "2024-06-08T03:12:32.658Z",
      },
      {
        id: "clx5jhfyr01s412vcm8y0o0dp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPad6,12; iPadOS 16_7_8; en_US; en; scale=2.00; 750x1334; 605596711) NW/3",
            device_type: "tablet",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "insU6YibZn",
            locked: 1717816318,
            timestamp: 1717816305,
          },
        ],
        createdAt: "2024-06-08T03:12:00.528Z",
        updatedAt: "2024-06-08T03:12:00.528Z",
      },
      {
        id: "clx5jeawl01s312vcukz6hntw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "XYiSgn7pZz",
            locked: 1717816173,
            timestamp: 1717816172,
          },
        ],
        createdAt: "2024-06-08T03:09:34.005Z",
        updatedAt: "2024-06-08T03:09:34.005Z",
      },
      {
        id: "clx5je28c01s212vc2qmckf8n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tcSujUXQ2c",
            locked: 1717816162,
            timestamp: 1717816161,
          },
        ],
        createdAt: "2024-06-08T03:09:22.762Z",
        updatedAt: "2024-06-08T03:09:22.762Z",
      },
      {
        id: "clx5jdn1g01s112vcwj899lvs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "uI43TxMHfU",
            locked: 1717816142,
            timestamp: 1717816141,
          },
        ],
        createdAt: "2024-06-08T03:09:03.073Z",
        updatedAt: "2024-06-08T03:09:03.073Z",
      },
      {
        id: "clx5jdb1l01s012vctnmskp4u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "5RakRieb22",
            locked: 1717816127,
            timestamp: 1717816126,
          },
        ],
        createdAt: "2024-06-08T03:08:47.529Z",
        updatedAt: "2024-06-08T03:08:47.529Z",
      },
      {
        id: "clx5jd74f01rz12vcmzynyxwp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LRvr8QW9Lt",
            locked: 1717816121,
            timestamp: 1717816118,
          },
        ],
        createdAt: "2024-06-08T03:08:42.445Z",
        updatedAt: "2024-06-08T03:08:42.445Z",
      },
      {
        id: "clx5jcrpd01ry12vcpv1z9e8u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pq7LRyIwdr",
            locked: 1717816101,
            timestamp: 1717816100,
          },
        ],
        createdAt: "2024-06-08T03:08:22.462Z",
        updatedAt: "2024-06-08T03:08:22.462Z",
      },
      {
        id: "clx5jba3l01rx12vclm29uqo3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "J54yx9hdl7",
            locked: 1717816031,
            timestamp: 1717816030,
          },
        ],
        createdAt: "2024-06-08T03:07:12.991Z",
        updatedAt: "2024-06-08T03:07:12.991Z",
      },
      {
        id: "clx5j8ell01rw12vc5t83kbvk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "sQXjSJB6dD",
            locked: 1717815898,
            timestamp: 1717815897,
          },
        ],
        createdAt: "2024-06-08T03:04:58.858Z",
        updatedAt: "2024-06-08T03:04:58.858Z",
      },
      {
        id: "clx5j865f01rv12vcvkxwqccw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "EZmmpyvI3Q",
            locked: 1717815887,
            timestamp: 1717815886,
          },
        ],
        createdAt: "2024-06-08T03:04:47.904Z",
        updatedAt: "2024-06-08T03:04:47.904Z",
      },
      {
        id: "clx5j7r0f01ru12vcvi1ns4hi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 14_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 269.0.0.13.76 (iPhone12,8; iOS 14_6; en_US; en-US; scale=2.00; 750x1334; 443842272)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AhbZmEpC91",
            locked: 1717815867,
            timestamp: 1717815866,
          },
        ],
        createdAt: "2024-06-08T03:04:28.287Z",
        updatedAt: "2024-06-08T03:04:28.287Z",
      },
      {
        id: "clx5j7ibo01rt12vc1xvjev30",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NPhNRHScaH",
            locked: 1717815856,
            timestamp: 1717815854,
          },
        ],
        createdAt: "2024-06-08T03:04:17.026Z",
        updatedAt: "2024-06-08T03:04:17.026Z",
      },
      {
        id: "clx5j6ep501rs12vc9nncyitw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S918U; dm3q; qcom; en_US; 606558490)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Eg67EHbaCp",
            locked: 1717815804,
            timestamp: 1717815802,
          },
        ],
        createdAt: "2024-06-08T03:03:25.670Z",
        updatedAt: "2024-06-08T03:03:25.670Z",
      },
      {
        id: "clx5j5ziw01rr12vcpwv4i88m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "jA4HPDka0I",
            locked: 1717815785,
            timestamp: 1717815784,
          },
        ],
        createdAt: "2024-06-08T03:03:06.008Z",
        updatedAt: "2024-06-08T03:03:06.008Z",
      },
      {
        id: "clx5j5y8d01rq12vc2zvcrkrw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "89cUnW2qlw",
            locked: 1717815783,
            timestamp: 1717815782,
          },
        ],
        createdAt: "2024-06-08T03:03:04.331Z",
        updatedAt: "2024-06-08T03:03:04.331Z",
      },
      {
        id: "clx5j497p01rp12vc6zk95soi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F936U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 485dpi; 1812x2030; samsung; SM-F936U; q4q; qcom; en_US; 604247855)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "x9rb4gwGZ9",
            locked: 1717815705,
            timestamp: 1717815703,
          },
        ],
        createdAt: "2024-06-08T03:01:45.246Z",
        updatedAt: "2024-06-08T03:01:45.246Z",
      },
      {
        id: "clx5j3xg201ro12vcp79ioofc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "tGxoHm4Ni0",
            locked: 1717815689,
            timestamp: 1717815687,
          },
        ],
        createdAt: "2024-06-08T03:01:30.002Z",
        updatedAt: "2024-06-08T03:01:30.002Z",
      },
      {
        id: "clx5j3va001rn12vcmstt0kr1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qU6KzAN7FV",
            locked: 1717815686,
            timestamp: 1717815685,
          },
        ],
        createdAt: "2024-06-08T03:01:27.189Z",
        updatedAt: "2024-06-08T03:01:27.189Z",
      },
      {
        id: "clx5j3bte01rm12vckttj4hmj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F936U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 485dpi; 1812x2030; samsung; SM-F936U; q4q; qcom; en_US; 604247855)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UriCPXMIKn",
            locked: 1717815661,
            timestamp: 1717815656,
          },
        ],
        createdAt: "2024-06-08T03:01:01.971Z",
        updatedAt: "2024-06-08T03:01:01.971Z",
      },
      {
        id: "clx5j373v01rl12vc7kpmbgel",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S911U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S911U; dm1q; qcom; en_US; 606558408)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rpqNXy2Z8w",
            locked: 1717815655,
            timestamp: 1717815653,
          },
        ],
        createdAt: "2024-06-08T03:00:55.867Z",
        updatedAt: "2024-06-08T03:00:55.867Z",
      },
      {
        id: "clx5j31py01rk12vc7xtvuhbv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone11,8; iOS 16_6_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "v2WRSv5jSS",
            locked: 1717815648,
            timestamp: 1717815646,
          },
        ],
        createdAt: "2024-06-08T03:00:48.883Z",
        updatedAt: "2024-06-08T03:00:48.883Z",
      },
      {
        id: "clx5j1ql501rj12vcnlpk62gx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,3; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "GOSHKgErd3",
            locked: 1717815587,
            timestamp: 1717815586,
          },
        ],
        createdAt: "2024-06-08T02:59:47.799Z",
        updatedAt: "2024-06-08T02:59:47.799Z",
      },
      {
        id: "clx5j02wz01ri12vc17kbo5c5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F721U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 480dpi; 1080x2402; samsung; SM-F721U; b4q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1LXIuBpROm",
            locked: 1717815510,
            timestamp: 1717815508,
          },
        ],
        createdAt: "2024-06-08T02:58:30.466Z",
        updatedAt: "2024-06-08T02:58:30.466Z",
      },
      {
        id: "clx5izvtz01rh12vctyml8ga4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nAsD8FZ9zQ",
            locked: 1717815500,
            timestamp: 1717815498,
          },
        ],
        createdAt: "2024-06-08T02:58:21.288Z",
        updatedAt: "2024-06-08T02:58:21.288Z",
      },
      {
        id: "clx5izr9s01rg12vcjwz18g7r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F926U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1768x2082; samsung; SM-F926U; q2q; qcom; en_US; 606558499)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "U9YcGqeIxB",
            locked: 1717815494,
            timestamp: 1717815493,
          },
        ],
        createdAt: "2024-06-08T02:58:15.376Z",
        updatedAt: "2024-06-08T02:58:15.376Z",
      },
      {
        id: "clx5iznej01rf12vcpr529b8t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "k3yPdqh5x6",
            locked: 1717815490,
            timestamp: 1717815489,
          },
        ],
        createdAt: "2024-06-08T02:58:10.361Z",
        updatedAt: "2024-06-08T02:58:10.361Z",
      },
      {
        id: "clx5izd4b01re12vcek6t6cvw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "YDYxW5xhlo",
            locked: 1717815476,
            timestamp: 1717815475,
          },
        ],
        createdAt: "2024-06-08T02:57:57.035Z",
        updatedAt: "2024-06-08T02:57:57.035Z",
      },
      {
        id: "clx5iz8qj01rd12vckhq8lm46",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zLZEEA4zec",
            locked: 1717815470,
            timestamp: 1717815469,
          },
        ],
        createdAt: "2024-06-08T02:57:51.352Z",
        updatedAt: "2024-06-08T02:57:51.352Z",
      },
      {
        id: "clx5iyt1x01rc12vc9ebpxe1r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "b5XO5zIICi",
            locked: 1717815450,
            timestamp: 1717815449,
          },
        ],
        createdAt: "2024-06-08T02:57:31.029Z",
        updatedAt: "2024-06-08T02:57:31.029Z",
      },
      {
        id: "clx5iyjuf01rb12vcby2hc5xo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "T78YHXrMo5",
            locked: 1717815438,
            timestamp: 1717815435,
          },
        ],
        createdAt: "2024-06-08T02:57:19.092Z",
        updatedAt: "2024-06-08T02:57:19.092Z",
      },
      {
        id: "clx5ixekn01ra12vc0aqndxdx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "j9fSiX85wj",
            locked: 1717815385,
            timestamp: 1717815383,
          },
        ],
        createdAt: "2024-06-08T02:56:25.607Z",
        updatedAt: "2024-06-08T02:56:25.607Z",
      },
      {
        id: "clx5ix79901r912vcy9kz2in6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ij3gVKiTYF",
            locked: 1717815375,
            timestamp: 1717815373,
          },
        ],
        createdAt: "2024-06-08T02:56:16.122Z",
        updatedAt: "2024-06-08T02:56:16.122Z",
      },
      {
        id: "clx5iw4da01r812vcnsa314da",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8CWBGUwHi8",
            locked: 1717815323,
            timestamp: 1717815317,
          },
        ],
        createdAt: "2024-06-08T02:55:25.724Z",
        updatedAt: "2024-06-08T02:55:25.724Z",
      },
      {
        id: "clx5itv8c01r712vc7tqa9xoh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone14,4; iOS 16_6_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "EPMVxMASmo",
            locked: 1717815220,
            timestamp: 1717815219,
          },
        ],
        createdAt: "2024-06-08T02:53:40.572Z",
        updatedAt: "2024-06-08T02:53:40.572Z",
      },
      {
        id: "clx5ito5g01r612vcut19jhrk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "IWb2Lxg05T",
            locked: 1717815211,
            timestamp: 1717815209,
          },
        ],
        createdAt: "2024-06-08T02:53:31.397Z",
        updatedAt: "2024-06-08T02:53:31.397Z",
      },
      {
        id: "clx5itm2901r512vc7gyi7og2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone14,4; iOS 16_6_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "D94ilC1v6k",
            locked: 1717815208,
            timestamp: 1717815207,
          },
        ],
        createdAt: "2024-06-08T02:53:28.689Z",
        updatedAt: "2024-06-08T02:53:28.689Z",
      },
      {
        id: "clx5itk1t01r412vc0csrmjmv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ARZWAYClfL",
            locked: 1717815205,
            timestamp: 1717815204,
          },
        ],
        createdAt: "2024-06-08T02:53:26.078Z",
        updatedAt: "2024-06-08T02:53:26.078Z",
      },
      {
        id: "clx5it2ms01r312vcgo4xon8g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HpNk3Pl5N1",
            locked: 1717815181,
            timestamp: 1717815180,
          },
        ],
        createdAt: "2024-06-08T02:53:03.506Z",
        updatedAt: "2024-06-08T02:53:03.506Z",
      },
      {
        id: "clx5iso7n01r212vcyvxkgnj5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 510dpi; 1080x2113; samsung; SM-S906U; g0q; qcom; en_US; 606558400)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "44WDtrhcL9",
            locked: 1717815164,
            timestamp: 1717815162,
          },
        ],
        createdAt: "2024-06-08T02:52:44.817Z",
        updatedAt: "2024-06-08T02:52:44.817Z",
      },
      {
        id: "clx5ira3501r112vcntbvba8w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6lRDTzRDZf",
            locked: 1717815099,
            timestamp: 1717815097,
          },
        ],
        createdAt: "2024-06-08T02:51:39.855Z",
        updatedAt: "2024-06-08T02:51:39.855Z",
      },
      {
        id: "clx5iqw2w01r012vc7wdmmfrf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XzCfZ7LJF9",
            locked: 1717815081,
            timestamp: 1717815080,
          },
        ],
        createdAt: "2024-06-08T02:51:21.704Z",
        updatedAt: "2024-06-08T02:51:21.704Z",
      },
      {
        id: "clx5iqnso01qz12vcey5pfjob",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RFR0AWOsiG",
            locked: 1717815070,
            timestamp: 1717815069,
          },
        ],
        createdAt: "2024-06-08T02:51:10.968Z",
        updatedAt: "2024-06-08T02:51:10.968Z",
      },
      {
        id: "clx5iqmt601qy12vcwvjfyd43",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-G965U Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 420dpi; 1080x2094; samsung; SM-G965U; star2qltesq; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kIU0ybo105",
            locked: 1717815069,
            timestamp: 1717815063,
          },
        ],
        createdAt: "2024-06-08T02:51:09.688Z",
        updatedAt: "2024-06-08T02:51:09.688Z",
      },
      {
        id: "clx5iptpc01qx12vcral5v3kg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 334.0.4.32.98 (iPad5,3; iPadOS 15_8_2; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ttmf8Lu7dd",
            locked: 1717815031,
            timestamp: 1717815030,
          },
        ],
        createdAt: "2024-06-08T02:50:31.969Z",
        updatedAt: "2024-06-08T02:50:31.969Z",
      },
      {
        id: "clx5ipkra01qw12vcxyiu5au3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 334.0.4.32.98 (iPad5,3; iPadOS 15_8_2; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aq6EvsRNF4",
            locked: 1717815019,
            timestamp: 1717815016,
          },
        ],
        createdAt: "2024-06-08T02:50:20.371Z",
        updatedAt: "2024-06-08T02:50:20.371Z",
      },
      {
        id: "clx5ioxay01qv12vc741m9l98",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A136U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 312.1.0.34.111 Android (33/13; 340dpi; 720x1453; samsung; SM-A136U; a13x; mt6833; en_US; 548323733)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HFtYs7yDYy",
            locked: 1717814989,
            timestamp: 1717814980,
          },
        ],
        createdAt: "2024-06-08T02:49:49.975Z",
        updatedAt: "2024-06-08T02:49:49.975Z",
      },
      {
        id: "clx5inkhj01qu12vcxqphl5ca",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xw80KEiJma",
            locked: 1717814926,
            timestamp: 1717814925,
          },
        ],
        createdAt: "2024-06-08T02:48:46.709Z",
        updatedAt: "2024-06-08T02:48:46.709Z",
      },
      {
        id: "clx5imlgr01qt12vct2z17j82",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N976U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 322.0.0.37.95 Android (31/12; 450dpi; 1080x2060; samsung; SM-N976U; d2xq2; qcom; en_US; 575346435)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YxGAf9QI4i",
            locked: 1717814880,
            timestamp: 1717814877,
          },
        ],
        createdAt: "2024-06-08T02:48:01.320Z",
        updatedAt: "2024-06-08T02:48:01.320Z",
      },
      {
        id: "clx5il6a501qs12vcavmtcwdn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "V5JQg85a5I",
            locked: 1717814814,
            timestamp: 1717814813,
          },
        ],
        createdAt: "2024-06-08T02:46:54.987Z",
        updatedAt: "2024-06-08T02:46:54.987Z",
      },
      {
        id: "clx5ikn0u01qr12vcbt1lydhd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "f2b1yX939r",
            locked: 1717814789,
            timestamp: 1717814788,
          },
        ],
        createdAt: "2024-06-08T02:46:30.027Z",
        updatedAt: "2024-06-08T02:46:30.027Z",
      },
      {
        id: "clx5ijyl901qq12vc49q42mjy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.147 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "T3h8KGOipW",
            locked: 1717814757,
            timestamp: 1717814756,
          },
        ],
        createdAt: "2024-06-08T02:45:58.363Z",
        updatedAt: "2024-06-08T02:45:58.363Z",
      },
      {
        id: "clx5ijmk101qp12vc4fm3bd93",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 604797909) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4oGZfM3NL3",
            locked: 1717814742,
            timestamp: 1717814741,
          },
        ],
        createdAt: "2024-06-08T02:45:42.766Z",
        updatedAt: "2024-06-08T02:45:42.766Z",
      },
      {
        id: "clx5ii8w901qo12vcdkkgg2my",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "C0SF5GC5g1",
            locked: 1717814678,
            timestamp: 1717814676,
          },
        ],
        createdAt: "2024-06-08T02:44:38.406Z",
        updatedAt: "2024-06-08T02:44:38.406Z",
      },
      {
        id: "clx5igdbt01qn12vcqzynwpe4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AW8LMyeX1i",
            locked: 1717814589,
            timestamp: 1717814588,
          },
        ],
        createdAt: "2024-06-08T02:43:10.841Z",
        updatedAt: "2024-06-08T02:43:10.841Z",
      },
      {
        id: "clx5igdan01qm12vc1vdp7djs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "MRJ3nHxPBC",
            locked: 1717814590,
            timestamp: 1717814589,
          },
        ],
        createdAt: "2024-06-08T02:43:10.799Z",
        updatedAt: "2024-06-08T02:43:10.799Z",
      },
      {
        id: "clx5iga9f01ql12vcq73sp3ql",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ctsygwILh4",
            locked: 1717814586,
            timestamp: 1717814585,
          },
        ],
        createdAt: "2024-06-08T02:43:06.868Z",
        updatedAt: "2024-06-08T02:43:06.868Z",
      },
      {
        id: "clx5ig7zg01qk12vcs6ek3wym",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_AU; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fs4PGlA2j9",
            locked: 1717814583,
            timestamp: 1717814581,
          },
        ],
        createdAt: "2024-06-08T02:43:03.915Z",
        updatedAt: "2024-06-08T02:43:03.915Z",
      },
      {
        id: "clx5ig4tm01qj12vcedzpqngx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Fx0kSyg9UE",
            locked: 1717814579,
            timestamp: 1717814578,
          },
        ],
        createdAt: "2024-06-08T02:42:59.818Z",
        updatedAt: "2024-06-08T02:42:59.818Z",
      },
      {
        id: "clx5ig4s801qi12vcrnu81q0c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G991U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2176; samsung; SM-G991U; o1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "K0pM7UsOU3",
            locked: 1717814579,
            timestamp: 1717814577,
          },
        ],
        createdAt: "2024-06-08T02:42:59.768Z",
        updatedAt: "2024-06-08T02:42:59.768Z",
      },
      {
        id: "clx5ig3fv01qh12vcpftugs01",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DO4Lstma33",
            locked: 1717814577,
            timestamp: 1717814574,
          },
        ],
        createdAt: "2024-06-08T02:42:58.028Z",
        updatedAt: "2024-06-08T02:42:58.028Z",
      },
      {
        id: "clx5iftv701qg12vc7fmrvas7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7vZRD4adEJ",
            locked: 1717814563,
            timestamp: 1717814562,
          },
        ],
        createdAt: "2024-06-08T02:42:45.620Z",
        updatedAt: "2024-06-08T02:42:45.620Z",
      },
      {
        id: "clx5iftc101qf12vc9gpo94zo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tlyHdzxuSW",
            locked: 1717814563,
            timestamp: 1717814560,
          },
        ],
        createdAt: "2024-06-08T02:42:44.926Z",
        updatedAt: "2024-06-08T02:42:44.926Z",
      },
      {
        id: "clx5iejjh01qe12vcrttb6dyn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "1MI6TCpIVw",
            locked: 1717814505,
            timestamp: 1717814503,
          },
        ],
        createdAt: "2024-06-08T02:41:45.581Z",
        updatedAt: "2024-06-08T02:41:45.581Z",
      },
      {
        id: "clx5iegyr01qd12vct0gb0pwk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1xMT1VvJT8",
            locked: 1717814501,
            timestamp: 1717814500,
          },
        ],
        createdAt: "2024-06-08T02:41:42.243Z",
        updatedAt: "2024-06-08T02:41:42.243Z",
      },
      {
        id: "clx5iegah01qc12vce2zwzhus",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QeTPtihOA2",
            locked: 1717814500,
            timestamp: 1717814499,
          },
        ],
        createdAt: "2024-06-08T02:41:41.369Z",
        updatedAt: "2024-06-08T02:41:41.369Z",
      },
      {
        id: "clx5ie91d01qb12vcofjewknv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "XlWZujM6zk",
            locked: 1717814491,
            timestamp: 1717814490,
          },
        ],
        createdAt: "2024-06-08T02:41:31.968Z",
        updatedAt: "2024-06-08T02:41:31.968Z",
      },
      {
        id: "clx5ie3jh01qa12vcspphghh2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9XDo7qjpVE",
            locked: 1717814484,
            timestamp: 1717814482,
          },
        ],
        createdAt: "2024-06-08T02:41:24.846Z",
        updatedAt: "2024-06-08T02:41:24.846Z",
      },
      {
        id: "clx5ids5b01q912vcgvm12hho",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S911U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S911U; dm1q; qcom; en_US; 606558499)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "H3QZjlD67y",
            locked: 1717814467,
            timestamp: 1717814464,
          },
        ],
        createdAt: "2024-06-08T02:41:10.072Z",
        updatedAt: "2024-06-08T02:41:10.072Z",
      },
      {
        id: "clx5idefp01q812vcl98gnyyz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "6n2y91Ny19",
            locked: 1717814451,
            timestamp: 1717814449,
          },
        ],
        createdAt: "2024-06-08T02:40:52.306Z",
        updatedAt: "2024-06-08T02:40:52.306Z",
      },
      {
        id: "clx5ic49u01q712vczjqnarvm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2123; samsung; SM-N986U; c2q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "5JMZDAKks5",
            locked: 1717814392,
            timestamp: 1717814391,
          },
        ],
        createdAt: "2024-06-08T02:39:52.483Z",
        updatedAt: "2024-06-08T02:39:52.483Z",
      },
      {
        id: "clx5ic49h01q612vcev7fcrlg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "t5vnAlM8N6",
            locked: 1717814330,
            timestamp: 1717814329,
          },
        ],
        createdAt: "2024-06-08T02:39:52.469Z",
        updatedAt: "2024-06-08T02:39:52.469Z",
      },
      {
        id: "clx5ic1dy01q512vc3stymtyi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2123; samsung; SM-N986U; c2q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PyydHZYEex",
            locked: 1717814386,
            timestamp: 1717814381,
          },
        ],
        createdAt: "2024-06-08T02:39:48.743Z",
        updatedAt: "2024-06-08T02:39:48.743Z",
      },
      {
        id: "clx5ibxk101q412vcryvd9jgw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 318.0.7.22.109 (iPhone12,1; iOS 16_1_1; en_US; en; scale=2.00; 828x1792; 565151867)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KqYnR5JI81",
            locked: 1717814383,
            timestamp: 1717814380,
          },
        ],
        createdAt: "2024-06-08T02:39:43.778Z",
        updatedAt: "2024-06-08T02:39:43.778Z",
      },
      {
        id: "clx5ibswq01q312vcnedqkcrg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WTbzY79rbm",
            locked: 1717814375,
            timestamp: 1717814372,
          },
        ],
        createdAt: "2024-06-08T02:39:37.752Z",
        updatedAt: "2024-06-08T02:39:37.752Z",
      },
      {
        id: "clx5ib93701q212vcm5mbyb51",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vJlBqCU84i",
            locked: 1717814351,
            timestamp: 1717814348,
          },
        ],
        createdAt: "2024-06-08T02:39:12.066Z",
        updatedAt: "2024-06-08T02:39:12.066Z",
      },
      {
        id: "clx5ib6pg01q112vc68via9c4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "CJ8g7dFbou",
            locked: 1717814348,
            timestamp: 1717814347,
          },
        ],
        createdAt: "2024-06-08T02:39:08.980Z",
        updatedAt: "2024-06-08T02:39:08.980Z",
      },
      {
        id: "clx5ib32o01q012vc0pfmtuwq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "7Y9GdvdoBW",
            locked: 1717814344,
            timestamp: 1717814343,
          },
        ],
        createdAt: "2024-06-08T02:39:04.272Z",
        updatedAt: "2024-06-08T02:39:04.272Z",
      },
      {
        id: "clx5iax3z01pz12vcvdmx163j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QmuClq5NLb",
            locked: 1717814335,
            timestamp: 1717814334,
          },
        ],
        createdAt: "2024-06-08T02:38:56.544Z",
        updatedAt: "2024-06-08T02:38:56.544Z",
      },
      {
        id: "clx5iaw8k01py12vcghz3901f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "weyRnhjCcF",
            locked: 1717814334,
            timestamp: 1717814333,
          },
        ],
        createdAt: "2024-06-08T02:38:55.409Z",
        updatedAt: "2024-06-08T02:38:55.409Z",
      },
      {
        id: "clx5i9y7h01px12vczxituhnq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; REVVL V+ 5G Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 320dpi; 720x1491; Wingtech/T-Mobile; REVVL V+ 5G; Sprout; mt6833; en_US; 606558548)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "3NqvWKX0Pe",
            locked: 1717814291,
            timestamp: 1717814290,
          },
        ],
        createdAt: "2024-06-08T02:38:11.309Z",
        updatedAt: "2024-06-08T02:38:11.309Z",
      },
      {
        id: "clx5i9x6e01pw12vcrr728vkw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "rWdozkkGzW",
            locked: 1717814289,
            timestamp: 1717814287,
          },
        ],
        createdAt: "2024-06-08T02:38:09.974Z",
        updatedAt: "2024-06-08T02:38:09.974Z",
      },
      {
        id: "clx5i9u9j01pv12vcqq0tvo2c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; REVVL V+ 5G Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 320dpi; 720x1491; Wingtech/T-Mobile; REVVL V+ 5G; Sprout; mt6833; en_US; 606558548)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Dls1hwaUip",
            locked: 1717814285,
            timestamp: 1717814280,
          },
        ],
        createdAt: "2024-06-08T02:38:06.199Z",
        updatedAt: "2024-06-08T02:38:06.199Z",
      },
      {
        id: "clx5i9ryr01pu12vcugy47ypb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mPCmnLqINe",
            locked: 1717814282,
            timestamp: 1717814281,
          },
        ],
        createdAt: "2024-06-08T02:38:03.217Z",
        updatedAt: "2024-06-08T02:38:03.217Z",
      },
      {
        id: "clx5i99r601pt12vct99xdyz4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7TpfD0VmJY",
            locked: 1717814259,
            timestamp: 1717814257,
          },
        ],
        createdAt: "2024-06-08T02:37:39.615Z",
        updatedAt: "2024-06-08T02:37:39.615Z",
      },
      {
        id: "clx5i8u0f01ps12vc79mhnnv2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ouN7d7uwtZ",
            locked: 1717814238,
            timestamp: 1717814237,
          },
        ],
        createdAt: "2024-06-08T02:37:19.216Z",
        updatedAt: "2024-06-08T02:37:19.216Z",
      },
      {
        id: "clx5i8pyc01pr12vc2fnvtroj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "awDNrH2ik6",
            locked: 1717814233,
            timestamp: 1717814232,
          },
        ],
        createdAt: "2024-06-08T02:37:13.955Z",
        updatedAt: "2024-06-08T02:37:13.955Z",
      },
      {
        id: "clx5i8avu01pq12vcln0k0xvr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uDShpyvtPL",
            locked: 1717814213,
            timestamp: 1717814212,
          },
        ],
        createdAt: "2024-06-08T02:36:54.427Z",
        updatedAt: "2024-06-08T02:36:54.427Z",
      },
      {
        id: "clx5i86af01pp12vctxwl32h6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "72BULiKeaH",
            locked: 1717814208,
            timestamp: 1717814207,
          },
        ],
        createdAt: "2024-06-08T02:36:48.469Z",
        updatedAt: "2024-06-08T02:36:48.469Z",
      },
      {
        id: "clx5i7qyp01po12vc2ytptkoj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lW3iRVQ3cM",
            locked: 1717814187,
            timestamp: 1717814184,
          },
        ],
        createdAt: "2024-06-08T02:36:28.607Z",
        updatedAt: "2024-06-08T02:36:28.607Z",
      },
      {
        id: "clx5i72bt01pn12vcq9hfrpnu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "P9libGf3Gu",
            locked: 1717814156,
            timestamp: 1717814154,
          },
        ],
        createdAt: "2024-06-08T02:35:56.678Z",
        updatedAt: "2024-06-08T02:35:56.678Z",
      },
      {
        id: "clx5i6hc501pm12vccehzhw30",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "UTth8WQfyT",
            locked: 1717814129,
            timestamp: 1717814128,
          },
        ],
        createdAt: "2024-06-08T02:35:29.478Z",
        updatedAt: "2024-06-08T02:35:29.478Z",
      },
      {
        id: "clx5i6gui01pl12vcm3xdvh1d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cPgDnuZ8aC",
            locked: 1717814128,
            timestamp: 1717814125,
          },
        ],
        createdAt: "2024-06-08T02:35:28.843Z",
        updatedAt: "2024-06-08T02:35:28.843Z",
      },
      {
        id: "clx5i6a9k01pk12vc8o53jsp7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "iJGA98awnj",
            locked: 1717814119,
            timestamp: 1717814118,
          },
        ],
        createdAt: "2024-06-08T02:35:20.310Z",
        updatedAt: "2024-06-08T02:35:20.310Z",
      },
      {
        id: "clx5i5xj101pj12vc9cgd8sas",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "IaLZbARTXm",
            locked: 1717814103,
            timestamp: 1717814102,
          },
        ],
        createdAt: "2024-06-08T02:35:03.805Z",
        updatedAt: "2024-06-08T02:35:03.805Z",
      },
      {
        id: "clx5i5vcx01pi12vc64x0riqz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Lh8MTqxckx",
            locked: 1717814100,
            timestamp: 1717814098,
          },
        ],
        createdAt: "2024-06-08T02:35:00.991Z",
        updatedAt: "2024-06-08T02:35:00.991Z",
      },
      {
        id: "clx5i4ypt01ph12vcuvf1gna1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A146U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 450dpi; 1080x2208; samsung; SM-A146U; a14xm; mt6833; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "5Bd5uPooee",
            locked: 1717814058,
            timestamp: 1717814057,
          },
        ],
        createdAt: "2024-06-08T02:34:18.689Z",
        updatedAt: "2024-06-08T02:34:18.689Z",
      },
      {
        id: "clx5i4ufl01pg12vcj2x24drp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A146U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 450dpi; 1080x2208; samsung; SM-A146U; a14xm; mt6833; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WvEHex119N",
            locked: 1717814052,
            timestamp: 1717814045,
          },
        ],
        createdAt: "2024-06-08T02:34:13.137Z",
        updatedAt: "2024-06-08T02:34:13.137Z",
      },
      {
        id: "clx5i4ot101pf12vcul7vkkga",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "b76rFrU3kW",
            locked: 1717814045,
            timestamp: 1717814043,
          },
        ],
        createdAt: "2024-06-08T02:34:05.843Z",
        updatedAt: "2024-06-08T02:34:05.843Z",
      },
      {
        id: "clx5i3wmc01pe12vcifrje4e4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Bw8e2rzIhZ",
            locked: 1717814009,
            timestamp: 1717814008,
          },
        ],
        createdAt: "2024-06-08T02:33:29.316Z",
        updatedAt: "2024-06-08T02:33:29.316Z",
      },
      {
        id: "clx5i3rk301pd12vce2cwmuqs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JsmjjBZtID",
            locked: 1717814002,
            timestamp: 1717814000,
          },
        ],
        createdAt: "2024-06-08T02:33:22.753Z",
        updatedAt: "2024-06-08T02:33:22.753Z",
      },
      {
        id: "clx5i2okx01pc12vcpxr3mt9k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "l1OFIIvCif",
            locked: 1717813951,
            timestamp: 1717813950,
          },
        ],
        createdAt: "2024-06-08T02:32:32.239Z",
        updatedAt: "2024-06-08T02:32:32.239Z",
      },
      {
        id: "clx5i1uks01pb12vc0r2695vn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 510dpi; 1080x2088; samsung; SM-G990U; r9q; qcom; en_US; 606558408)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TcxklDlErd",
            locked: 1717813912,
            timestamp: 1717813910,
          },
        ],
        createdAt: "2024-06-08T02:31:53.354Z",
        updatedAt: "2024-06-08T02:31:53.354Z",
      },
      {
        id: "clx5hyn1i01pa12vcyxlrxwf2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; motorola edge 5G UW (2021) Build/T1RM33.1-110-17; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 400dpi; 1080x2225; motorola; motorola edge 5G UW (2021); berlna; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ufowq3MU4G",
            locked: 1717813763,
            timestamp: 1717813761,
          },
        ],
        createdAt: "2024-06-08T02:29:23.620Z",
        updatedAt: "2024-06-08T02:29:23.620Z",
      },
      {
        id: "clx5hxqc701p912vcycsr1wfd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad14,5; iPadOS 17_5_1; en_US; en; scale=2.00; 780x1688; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "F4kEqQfWBJ",
            locked: 1717813721,
            timestamp: 1717813720,
          },
        ],
        createdAt: "2024-06-08T02:28:41.240Z",
        updatedAt: "2024-06-08T02:28:41.240Z",
      },
      {
        id: "clx5hxnzi01p812vcilo2duo6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "QjZdY3W83O",
            locked: 1717813718,
            timestamp: 1717813717,
          },
        ],
        createdAt: "2024-06-08T02:28:38.191Z",
        updatedAt: "2024-06-08T02:28:38.191Z",
      },
      {
        id: "clx5hxivx01p712vcr6ng6s96",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Y75msz1SNL",
            locked: 1717813711,
            timestamp: 1717813709,
          },
        ],
        createdAt: "2024-06-08T02:28:31.580Z",
        updatedAt: "2024-06-08T02:28:31.580Z",
      },
      {
        id: "clx5hxe4z01p612vc0lkxlkvn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad14,5; iPadOS 17_5_1; en_US; en; scale=2.00; 780x1688; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "iDxC6jKCvE",
            locked: 1717813705,
            timestamp: 1717813703,
          },
        ],
        createdAt: "2024-06-08T02:28:25.427Z",
        updatedAt: "2024-06-08T02:28:25.427Z",
      },
      {
        id: "clx5hx6wp01p512vcs8kgqud6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; moto g stylus 5G Build/S2RE32.29-16-1-5-19; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/126.0.6478.40 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 400dpi; 1080x2177; motorola; moto g stylus 5G; denver; qcom; en_US; 607752121)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HVf1npUXKY",
            locked: 1717813693,
            timestamp: 1717813686,
          },
        ],
        createdAt: "2024-06-08T02:28:16.055Z",
        updatedAt: "2024-06-08T02:28:16.055Z",
      },
      {
        id: "clx5hwn0p01p412vcarx1ge4h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 322.1.1.25.88 (iPhone13,2; iOS 17_3_1; en_US; en; scale=3.00; 960x2079; 575967800)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "shYlZj9Cgi",
            locked: 1717813669,
            timestamp: 1717813668,
          },
        ],
        createdAt: "2024-06-08T02:27:50.279Z",
        updatedAt: "2024-06-08T02:27:50.279Z",
      },
      {
        id: "clx5hv5za01p312vcvgid5c68",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; SM-A505U Build/RP1A.200720.012; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 322.0.0.37.95 Android (30/11; 420dpi; 1080x2131; samsung; SM-A505U; a50; exynos9610; en_US; 575346435)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "13QEf3lbFY",
            locked: 1717813602,
            timestamp: 1717813597,
          },
        ],
        createdAt: "2024-06-08T02:26:41.543Z",
        updatedAt: "2024-06-08T02:26:41.543Z",
      },
      {
        id: "clx5hv1vo01p212vcbariniby",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Zg3S7PWKBp",
            locked: 1717813623,
            timestamp: 1717813622,
          },
        ],
        createdAt: "2024-06-08T02:26:36.226Z",
        updatedAt: "2024-06-08T02:26:36.226Z",
      },
      {
        id: "clx5huecb01p112vcrqfa5ams",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U2 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-G990U2; r9q; qcom; en_US; 606558499)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MYSfR0D2hX",
            locked: 1717813564,
            timestamp: 1717813560,
          },
        ],
        createdAt: "2024-06-08T02:26:05.720Z",
        updatedAt: "2024-06-08T02:26:05.720Z",
      },
      {
        id: "clx5ht14301p012vck98letxl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,2; iOS 16_7_8; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4oDfCUAg2p",
            locked: 1717813500,
            timestamp: 1717813498,
          },
        ],
        createdAt: "2024-06-08T02:25:01.920Z",
        updatedAt: "2024-06-08T02:25:01.920Z",
      },
      {
        id: "clx5hsdum01oz12vcc5vc9gbr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,2; iOS 16_7_8; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "25uKaxHEyZ",
            locked: 1717813471,
            timestamp: 1717813469,
          },
        ],
        createdAt: "2024-06-08T02:24:31.774Z",
        updatedAt: "2024-06-08T02:24:31.774Z",
      },
      {
        id: "clx5hs53701oy12vc9yf9qngk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Ycnxu2avmf",
            locked: 1717813460,
            timestamp: 1717813457,
          },
        ],
        createdAt: "2024-06-08T02:24:20.416Z",
        updatedAt: "2024-06-08T02:24:20.416Z",
      },
      {
        id: "clx5hrp0x01ox12vc082bvyoe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad8,5; iPadOS 17_5_1; en_US; en; scale=2.00; 780x1688; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hnxtvWHcCX",
            locked: 1717813439,
            timestamp: 1717813437,
          },
        ],
        createdAt: "2024-06-08T02:23:59.602Z",
        updatedAt: "2024-06-08T02:23:59.602Z",
      },
      {
        id: "clx5hrlzx01ow12vchovt6yj9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IYenNF27tj",
            locked: 1717813435,
            timestamp: 1717813432,
          },
        ],
        createdAt: "2024-06-08T02:23:55.674Z",
        updatedAt: "2024-06-08T02:23:55.674Z",
      },
      {
        id: "clx5hqtak01ov12vcwmb1p2y5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "mLjNQJz0uj",
            locked: 1717813397,
            timestamp: 1717813396,
          },
        ],
        createdAt: "2024-06-08T02:23:18.474Z",
        updatedAt: "2024-06-08T02:23:18.474Z",
      },
      {
        id: "clx5hp9w901ou12vcuji4bwsc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JlEjqv8qGi",
            locked: 1717813326,
            timestamp: 1717813324,
          },
        ],
        createdAt: "2024-06-08T02:22:06.682Z",
        updatedAt: "2024-06-08T02:22:06.682Z",
      },
      {
        id: "clx5hp84i01ot12vc3y53nxdx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2124; samsung; SM-S908U1; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hN5cR6GgIR",
            locked: 1717813323,
            timestamp: 1717813320,
          },
        ],
        createdAt: "2024-06-08T02:22:04.383Z",
        updatedAt: "2024-06-08T02:22:04.383Z",
      },
      {
        id: "clx5hopp801os12vcfhxokoae",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-N950U Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (28/9; 420dpi; 1080x2094; samsung; SM-N950U; greatqlte; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MR5CNX7Lm6",
            locked: 1717813299,
            timestamp: 1717813289,
          },
        ],
        createdAt: "2024-06-08T02:21:40.508Z",
        updatedAt: "2024-06-08T02:21:40.508Z",
      },
      {
        id: "clx5hof9501or12vcxdg7jkr2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gbSJ38wkWG",
            locked: 1717813286,
            timestamp: 1717813284,
          },
        ],
        createdAt: "2024-06-08T02:21:26.967Z",
        updatedAt: "2024-06-08T02:21:26.967Z",
      },
      {
        id: "clx5hoctp01oq12vcv65dz9oh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 320.0.2.29.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 569998124)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "7LSlkhZICl",
            locked: 1717813283,
            timestamp: 1717813282,
          },
        ],
        createdAt: "2024-06-08T02:21:23.821Z",
        updatedAt: "2024-06-08T02:21:23.821Z",
      },
      {
        id: "clx5hoa4a01op12vcw5yni7mz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad13,1; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yeTdXHyQOa",
            locked: 1717813279,
            timestamp: 1717813278,
          },
        ],
        createdAt: "2024-06-08T02:21:20.315Z",
        updatedAt: "2024-06-08T02:21:20.315Z",
      },
      {
        id: "clx5ho4yn01oo12vc613z93r3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 320.0.2.29.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 569998124)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "R5B9p84J9R",
            locked: 1717813273,
            timestamp: 1717813270,
          },
        ],
        createdAt: "2024-06-08T02:21:13.632Z",
        updatedAt: "2024-06-08T02:21:13.632Z",
      },
      {
        id: "clx5ho38a01on12vcl71npot6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "g6PJRnTuHW",
            locked: 1717813270,
            timestamp: 1717813268,
          },
        ],
        createdAt: "2024-06-08T02:21:11.384Z",
        updatedAt: "2024-06-08T02:21:11.384Z",
      },
      {
        id: "clx5hmidf01om12vczb9xm8r5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "vRhz8IVpIW",
            locked: 1717813197,
            timestamp: 1717813195,
          },
        ],
        createdAt: "2024-06-08T02:19:57.700Z",
        updatedAt: "2024-06-08T02:19:57.700Z",
      },
      {
        id: "clx5hmdrl01ol12vce7jia8kc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RGktQcZY8X",
            locked: 1717813191,
            timestamp: 1717813189,
          },
        ],
        createdAt: "2024-06-08T02:19:51.727Z",
        updatedAt: "2024-06-08T02:19:51.727Z",
      },
      {
        id: "clx5hlmnu01ok12vcy4i0xizv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Qy5UfPd0Gp",
            locked: 1717813156,
            timestamp: 1717813154,
          },
        ],
        createdAt: "2024-06-08T02:19:16.600Z",
        updatedAt: "2024-06-08T02:19:16.600Z",
      },
      {
        id: "clx5hl58m01oj12vc16502xxd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-T878U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Safari/537.36 Instagram 332.0.0.38.90 Android (33/13; 300dpi; 1600x2470; samsung; SM-T878U; gts7l; qcom; en_US; 601420823)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qWX0xSxuG1",
            locked: 1717813132,
            timestamp: 1717813130,
          },
        ],
        createdAt: "2024-06-08T02:18:54.022Z",
        updatedAt: "2024-06-08T02:18:54.022Z",
      },
      {
        id: "clx5hl2x101oi12vcomubg1de",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,3; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ur4xU6EeOz",
            locked: 1717813130,
            timestamp: 1717813129,
          },
        ],
        createdAt: "2024-06-08T02:18:51.011Z",
        updatedAt: "2024-06-08T02:18:51.011Z",
      },
      {
        id: "clx5hk41v01oh12vcs5r1e03m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AEgfs1sLko",
            locked: 1717813084,
            timestamp: 1717813081,
          },
        ],
        createdAt: "2024-06-08T02:18:05.827Z",
        updatedAt: "2024-06-08T02:18:05.827Z",
      },
      {
        id: "clx5hjzkx01og12vco7gyr4ej",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 450dpi; 1080x2114; samsung; SM-N986U; c2q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1h6BxbGcf1",
            locked: 1717813079,
            timestamp: 1717813076,
          },
        ],
        createdAt: "2024-06-08T02:18:00.028Z",
        updatedAt: "2024-06-08T02:18:00.028Z",
      },
      {
        id: "clx5hjv9q01of12vc4ymqw1ed",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A236U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2208; samsung; SM-A236U; a23xq; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WyA5ZkC89H",
            locked: 1717813073,
            timestamp: 1717813067,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Zq4JtTijxE",
            locked: 1717813073,
            timestamp: 1717813072,
          },
        ],
        createdAt: "2024-06-08T02:17:54.447Z",
        updatedAt: "2024-06-08T02:17:54.447Z",
      },
      {
        id: "clx5hjl1x01oe12vcwv7seqkv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "sU926SaWvt",
            locked: 1717813060,
            timestamp: 1717813059,
          },
        ],
        createdAt: "2024-06-08T02:17:41.202Z",
        updatedAt: "2024-06-08T02:17:41.202Z",
      },
      {
        id: "clx5hj4yr01od12vc2p2ie501",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5wYRb2Bapp",
            locked: 1717813039,
            timestamp: 1717813037,
          },
        ],
        createdAt: "2024-06-08T02:17:20.353Z",
        updatedAt: "2024-06-08T02:17:20.353Z",
      },
      {
        id: "clx5hhpww01oc12vcodsvdqqu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gFkcWdU3gq",
            locked: 1717812973,
            timestamp: 1717812972,
          },
        ],
        createdAt: "2024-06-08T02:16:14.190Z",
        updatedAt: "2024-06-08T02:16:14.190Z",
      },
      {
        id: "clx5hh97g01ob12vcu0j14yyy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "d0pPrsx6op",
            locked: 1717812952,
            timestamp: 1717812951,
          },
        ],
        createdAt: "2024-06-08T02:15:52.540Z",
        updatedAt: "2024-06-08T02:15:52.540Z",
      },
      {
        id: "clx5hh3da01oa12vclopkjv1n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Qn2MIUSk4c",
            locked: 1717812944,
            timestamp: 1717812942,
          },
        ],
        createdAt: "2024-06-08T02:15:44.974Z",
        updatedAt: "2024-06-08T02:15:44.974Z",
      },
      {
        id: "clx5hgzps01o912vcsgtihbk6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-N960U Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 420dpi; 1080x2094; samsung; SM-N960U; crownqltesq; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VUAWjeatu6",
            locked: 1717812940,
            timestamp: 1717812936,
          },
        ],
        createdAt: "2024-06-08T02:15:40.237Z",
        updatedAt: "2024-06-08T02:15:40.237Z",
      },
      {
        id: "clx5hg2q201o812vcsqanfgd0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vTni4d4UTW",
            locked: 1717812896,
            timestamp: 1717812894,
          },
        ],
        createdAt: "2024-06-08T02:14:57.479Z",
        updatedAt: "2024-06-08T02:14:57.479Z",
      },
      {
        id: "clx5hewjc01o712vckhvl5xs6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 [FBAN/FBIOS;FBAV/466.0.0.36.107;FBBV/604687626;FBDV/iPhone13,2;FBMD/iPhone;FBSN/iOS;FBSV/17.5.1;FBSS/3;FBID/phone;FBLC/da_DK;FBOP/5;FBRV/607593564]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "mk6ozavcKB",
            locked: 1717812842,
            timestamp: 1717812838,
          },
        ],
        createdAt: "2024-06-08T02:14:02.806Z",
        updatedAt: "2024-06-08T02:14:02.806Z",
      },
      {
        id: "clx5hem9601o612vcgyntmkd2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "fhZKQKSlgt",
            locked: 1717812829,
            timestamp: 1717812828,
          },
        ],
        createdAt: "2024-06-08T02:13:49.483Z",
        updatedAt: "2024-06-08T02:13:49.483Z",
      },
      {
        id: "clx5hekda01o512vcr44nepqm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Dc17bmyUsv",
            locked: 1717812826,
            timestamp: 1717812824,
          },
        ],
        createdAt: "2024-06-08T02:13:47.039Z",
        updatedAt: "2024-06-08T02:13:47.039Z",
      },
      {
        id: "clx5heedl01o412vcz8nmbh98",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "de6O1kyGK4",
            locked: 1717812818,
            timestamp: 1717812817,
          },
        ],
        createdAt: "2024-06-08T02:13:39.269Z",
        updatedAt: "2024-06-08T02:13:39.269Z",
      },
      {
        id: "clx5hd81e01o312vcbfb2lx9t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tWQgqwBwLR",
            locked: 1717812763,
            timestamp: 1717812762,
          },
        ],
        createdAt: "2024-06-08T02:12:44.403Z",
        updatedAt: "2024-06-08T02:12:44.403Z",
      },
      {
        id: "clx5hd4vn01o212vc8k2tv72a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "TMYnlROUzu",
            locked: 1717812759,
            timestamp: 1717812758,
          },
        ],
        createdAt: "2024-06-08T02:12:40.307Z",
        updatedAt: "2024-06-08T02:12:40.307Z",
      },
      {
        id: "clx5hd1xf01o112vc9u3nbhn1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A516V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 253.0.0.23.114 Android (33/13; 450dpi; 1080x2177; samsung; SM-A516V; a51xq; qcom; en_US; 399993162)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "xGzBvc8vGT",
            locked: 1717812756,
            timestamp: 1717812755,
          },
        ],
        createdAt: "2024-06-08T02:12:36.483Z",
        updatedAt: "2024-06-08T02:12:36.483Z",
      },
      {
        id: "clx5hcyax01o012vcua4g58kf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tVT0J4tvOi",
            locked: 1717812751,
            timestamp: 1717812750,
          },
        ],
        createdAt: "2024-06-08T02:12:31.786Z",
        updatedAt: "2024-06-08T02:12:31.786Z",
      },
      {
        id: "clx5hcx3b01nz12vcs8pkq3oc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A516V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 253.0.0.23.114 Android (33/13; 450dpi; 1080x2177; samsung; SM-A516V; a51xq; qcom; en_US; 399993162)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "l0bICXu1qa",
            locked: 1717812749,
            timestamp: 1717812746,
          },
        ],
        createdAt: "2024-06-08T02:12:30.213Z",
        updatedAt: "2024-06-08T02:12:30.213Z",
      },
      {
        id: "clx5hc9r701ny12vcubea3fjj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "mL87t615DI",
            locked: 1717812719,
            timestamp: 1717812718,
          },
        ],
        createdAt: "2024-06-08T02:11:59.971Z",
        updatedAt: "2024-06-08T02:11:59.971Z",
      },
      {
        id: "clx5hc59701nx12vcb4r5i6vc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LMQm4iThcl",
            locked: 1717812713,
            timestamp: 1717812712,
          },
        ],
        createdAt: "2024-06-08T02:11:54.137Z",
        updatedAt: "2024-06-08T02:11:54.137Z",
      },
      {
        id: "clx5haun401nw12vcmsxlmb6w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone12,5; iOS 16_1_2; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "f4eNIDBlk6",
            locked: 1717812652,
            timestamp: 1717812651,
          },
        ],
        createdAt: "2024-06-08T02:10:53.725Z",
        updatedAt: "2024-06-08T02:10:53.725Z",
      },
      {
        id: "clx5h8zb901nv12vc0l523d2z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0DT02XDgI8",
            locked: 1717812566,
            timestamp: 1717812565,
          },
        ],
        createdAt: "2024-06-08T02:09:26.466Z",
        updatedAt: "2024-06-08T02:09:26.466Z",
      },
      {
        id: "clx5h8oy001nu12vcm9kdmp57",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "DtgN2SFFut",
            locked: 1717812552,
            timestamp: 1717812551,
          },
        ],
        createdAt: "2024-06-08T02:09:13.032Z",
        updatedAt: "2024-06-08T02:09:13.032Z",
      },
      {
        id: "clx5h8h9w01nt12vc5jyczhfr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "eStpYXYn6q",
            locked: 1717812542,
            timestamp: 1717812541,
          },
        ],
        createdAt: "2024-06-08T02:09:03.092Z",
        updatedAt: "2024-06-08T02:09:03.092Z",
      },
      {
        id: "clx5h8eft01ns12vcicq2n1yx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GOHzBVeEHU",
            locked: 1717812538,
            timestamp: 1717812537,
          },
        ],
        createdAt: "2024-06-08T02:08:59.415Z",
        updatedAt: "2024-06-08T02:08:59.415Z",
      },
      {
        id: "clx5h7o9b01nr12vc70kbj20i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6KryFOi6nk",
            locked: 1717812504,
            timestamp: 1717812503,
          },
        ],
        createdAt: "2024-06-08T02:08:25.487Z",
        updatedAt: "2024-06-08T02:08:25.487Z",
      },
      {
        id: "clx5h7m8j01nq12vchxf43e8z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; moto g51 5G Build/S2RYA32.58-13-8; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/460.0.0.48.109;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OCFJrFYhrO",
            locked: 1717812502,
            timestamp: 1717812499,
          },
        ],
        createdAt: "2024-06-08T02:08:22.865Z",
        updatedAt: "2024-06-08T02:08:22.865Z",
      },
      {
        id: "clx5h79ha01np12vcxovvqcpp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "m9LYy3Msqd",
            locked: 1717812484,
            timestamp: 1717812476,
          },
        ],
        createdAt: "2024-06-08T02:08:06.331Z",
        updatedAt: "2024-06-08T02:08:06.331Z",
      },
      {
        id: "clx5h5f8901no12vcahelty83",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "t0SO6iZ8fV",
            locked: 1717812400,
            timestamp: 1717812399,
          },
        ],
        createdAt: "2024-06-08T02:06:40.474Z",
        updatedAt: "2024-06-08T02:06:40.474Z",
      },
      {
        id: "clx5h5ald01nn12vcpz7t8pu3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "W3o8qFZz9I",
            locked: 1717812394,
            timestamp: 1717812393,
          },
        ],
        createdAt: "2024-06-08T02:06:34.463Z",
        updatedAt: "2024-06-08T02:06:34.463Z",
      },
      {
        id: "clx5h41vn01nm12vcxymcoau2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "bmNnVcIXvr",
            locked: 1717812336,
            timestamp: 1717812335,
          },
        ],
        createdAt: "2024-06-08T02:05:36.513Z",
        updatedAt: "2024-06-08T02:05:36.513Z",
      },
      {
        id: "clx5h2yvy01nl12vc4hlz1i4q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad13,8; iPadOS 17_5_1; en_US; en; scale=2.00; 780x1688; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nX44wNKuOn",
            locked: 1717812284,
            timestamp: 1717812283,
          },
        ],
        createdAt: "2024-06-08T02:04:45.983Z",
        updatedAt: "2024-06-08T02:04:45.983Z",
      },
      {
        id: "clx5h2wpk01nk12vcx7fiumpg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EOXRuxnyfR",
            locked: 1717812282,
            timestamp: 1717812280,
          },
        ],
        createdAt: "2024-06-08T02:04:43.158Z",
        updatedAt: "2024-06-08T02:04:43.158Z",
      },
      {
        id: "clx5h2d6z01nj12vc0sfbf3ue",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bKymSonzDm",
            locked: 1717812257,
            timestamp: 1717812256,
          },
        ],
        createdAt: "2024-06-08T02:04:17.866Z",
        updatedAt: "2024-06-08T02:04:17.866Z",
      },
      {
        id: "clx5h10ya01ni12vc6c0mdzxn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VdL8AXSPX0",
            locked: 1717812194,
            timestamp: 1717812192,
          },
        ],
        createdAt: "2024-06-08T02:03:15.343Z",
        updatedAt: "2024-06-08T02:03:15.343Z",
      },
      {
        id: "clx5h0alk01nh12vc8kflq3v0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 8.0.0; LG-H870DS Build/OPR1.170623.032) AppleWebKit/537.37 (KHTML, like Gecko) Chrome/68.0.3440.91 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "v838KB0f3H",
            locked: 1717812160,
            timestamp: 1717812158,
          },
        ],
        createdAt: "2024-06-08T02:02:41.192Z",
        updatedAt: "2024-06-08T02:02:41.192Z",
      },
      {
        id: "clx5h05q801ng12vcglj3126d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GYGlqnvxqo",
            locked: 1717812153,
            timestamp: 1717812152,
          },
        ],
        createdAt: "2024-06-08T02:02:34.880Z",
        updatedAt: "2024-06-08T02:02:34.880Z",
      },
      {
        id: "clx5h02wk01nf12vcyrmwsczs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-A037U1 Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.152 Mobile Safari/537.36 [FBAN/FB4A;FBAV/467.0.0.46.83;FBBV/606680392;FBDM/{density=1.875,width=720,height=1465};FBLC/en_US;FBRV/607790481;FB_FW/2;FBCR/FLOW;FBMF/samsung;FBBD/samsung;FBPN/com.facebook.katana;FBDV/SM-A037U1;FBSV/12;FBOP/1;FBCA/arm64-v8a:;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NDAa8cOGMP",
            locked: 1717812151,
            timestamp: 1717812147,
          },
        ],
        createdAt: "2024-06-08T02:02:31.218Z",
        updatedAt: "2024-06-08T02:02:31.218Z",
      },
      {
        id: "clx5gzngj01ne12vc3h1ptqvh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "TRQPE5DtKI",
            locked: 1717812131,
            timestamp: 1717812130,
          },
        ],
        createdAt: "2024-06-08T02:02:11.203Z",
        updatedAt: "2024-06-08T02:02:11.203Z",
      },
      {
        id: "clx5gzkip01nd12vce4h8vfc9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vAf4WZjtZR",
            locked: 1717812126,
            timestamp: 1717812125,
          },
        ],
        createdAt: "2024-06-08T02:02:07.390Z",
        updatedAt: "2024-06-08T02:02:07.390Z",
      },
      {
        id: "clx5gz0d601nc12vcz6q5gy69",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5qEBF6Zc58",
            locked: 1717812100,
            timestamp: 1717812098,
          },
        ],
        createdAt: "2024-06-08T02:01:41.274Z",
        updatedAt: "2024-06-08T02:01:41.274Z",
      },
      {
        id: "clx5gyt1a01nb12vcs2qlo8fl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,2; iOS 17_5_1; en_CA; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vwYWoQRrwK",
            locked: 1717812091,
            timestamp: 1717812089,
          },
        ],
        createdAt: "2024-06-08T02:01:31.772Z",
        updatedAt: "2024-06-08T02:01:31.772Z",
      },
      {
        id: "clx5gy6vn01na12vcru1xnsma",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "whMl3sCsLM",
            locked: 1717812061,
            timestamp: 1717812059,
          },
        ],
        createdAt: "2024-06-08T02:01:03.057Z",
        updatedAt: "2024-06-08T02:01:03.057Z",
      },
      {
        id: "clx5gxks401n912vc3y19cvhe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-N950U Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/100.0.4896.88 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (28/9; 420dpi; 1080x2094; samsung; SM-N950U; greatqlte; qcom; en_US; 601420827)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JDEiT0MNCH",
            locked: 1717812032,
            timestamp: 1717812026,
          },
        ],
        createdAt: "2024-06-08T02:00:34.420Z",
        updatedAt: "2024-06-08T02:00:34.420Z",
      },
      {
        id: "clx5gxkoq01n812vc8eiwu8bx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "R7D64LUBsv",
            locked: 1717811994,
            timestamp: 1717811993,
          },
        ],
        createdAt: "2024-06-08T02:00:34.298Z",
        updatedAt: "2024-06-08T02:00:34.298Z",
      },
      {
        id: "clx5gxiv801n712vclg0f7kwe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "l5MiwsJP03",
            locked: 1717812031,
            timestamp: 1717812030,
          },
        ],
        createdAt: "2024-06-08T02:00:31.937Z",
        updatedAt: "2024-06-08T02:00:31.937Z",
      },
      {
        id: "clx5gx56y01n612vcxo81gfwx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "cpwzva0AbS",
            locked: 1717812014,
            timestamp: 1717812013,
          },
        ],
        createdAt: "2024-06-08T02:00:14.219Z",
        updatedAt: "2024-06-08T02:00:14.219Z",
      },
      {
        id: "clx5gx2wk01n512vcczl5ejvi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mSlOdiTK45",
            locked: 1717812009,
            timestamp: 1717812002,
          },
        ],
        createdAt: "2024-06-08T02:00:11.252Z",
        updatedAt: "2024-06-08T02:00:11.252Z",
      },
      {
        id: "clx5gwxql01n412vcf3y94okg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PVsNA58lkP",
            locked: 1717812004,
            timestamp: 1717812002,
          },
        ],
        createdAt: "2024-06-08T02:00:04.557Z",
        updatedAt: "2024-06-08T02:00:04.557Z",
      },
      {
        id: "clx5gwuq401n312vco7udt0vz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U2 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-G990U2; r9q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "LgelMd16JB",
            locked: 1717812000,
            timestamp: 1717811999,
          },
        ],
        createdAt: "2024-06-08T02:00:00.648Z",
        updatedAt: "2024-06-08T02:00:00.648Z",
      },
      {
        id: "clx5gwmmr01n212vc1rzimemg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U2 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-G990U2; r9q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rFeFfMXUZN",
            locked: 1717811989,
            timestamp: 1717811987,
          },
        ],
        createdAt: "2024-06-08T01:59:50.164Z",
        updatedAt: "2024-06-08T01:59:50.164Z",
      },
      {
        id: "clx5gwhxh01n112vci5mrj599",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "g9zpqDbBVT",
            locked: 1717811983,
            timestamp: 1717811976,
          },
        ],
        createdAt: "2024-06-08T01:59:44.066Z",
        updatedAt: "2024-06-08T01:59:44.066Z",
      },
      {
        id: "clx5gw17301n012vcycxu6396",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 326.0.3.30.91 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 585196636)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AGq2GzBwGB",
            locked: 1717811959,
            timestamp: 1717811958,
          },
        ],
        createdAt: "2024-06-08T01:59:22.380Z",
        updatedAt: "2024-06-08T01:59:22.380Z",
      },
      {
        id: "clx5gvpkh01mz12vcc37qjvom",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "f0rksC3hK0",
            locked: 1717811946,
            timestamp: 1717811945,
          },
        ],
        createdAt: "2024-06-08T01:59:07.313Z",
        updatedAt: "2024-06-08T01:59:07.313Z",
      },
      {
        id: "clx5gvpal01my12vcf394a3tb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "GSkDLV67k5",
            locked: 1717811946,
            timestamp: 1717811945,
          },
        ],
        createdAt: "2024-06-08T01:59:06.957Z",
        updatedAt: "2024-06-08T01:59:06.957Z",
      },
      {
        id: "clx5gvo2901mx12vcs3b441oa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NEhha366n7",
            locked: 1717811944,
            timestamp: 1717811943,
          },
        ],
        createdAt: "2024-06-08T01:59:05.362Z",
        updatedAt: "2024-06-08T01:59:05.362Z",
      },
      {
        id: "clx5gvo1401mw12vczihmn87m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DaE4dGUdPf",
            locked: 1717811944,
            timestamp: 1717811943,
          },
        ],
        createdAt: "2024-06-08T01:59:05.316Z",
        updatedAt: "2024-06-08T01:59:05.316Z",
      },
      {
        id: "clx5gv0tt01mv12vcn8uvcc4v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; motorola edge 2023 Build/T2TTS33.60-75-1-6-1; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 400dpi; 1080x2174; motorola; motorola edge 2023; aion; mt6879; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fYPu01UIJ0",
            locked: 1717811914,
            timestamp: 1717811911,
          },
        ],
        createdAt: "2024-06-08T01:58:35.247Z",
        updatedAt: "2024-06-08T01:58:35.247Z",
      },
      {
        id: "clx5gumvt01mu12vckjhzdqtk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "t6N4wnRz5f",
            locked: 1717811897,
            timestamp: 1717811896,
          },
        ],
        createdAt: "2024-06-08T01:58:17.178Z",
        updatedAt: "2024-06-08T01:58:17.178Z",
      },
      {
        id: "clx5guip301mt12vcevroaz7s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "aBmzIggOCk",
            locked: 1717811891,
            timestamp: 1717811890,
          },
        ],
        createdAt: "2024-06-08T01:58:11.750Z",
        updatedAt: "2024-06-08T01:58:11.750Z",
      },
      {
        id: "clx5gsk3w01ms12vchkqsb56u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gUTm3FqDHu",
            locked: 1717811799,
            timestamp: 1717811798,
          },
        ],
        createdAt: "2024-06-08T01:56:40.266Z",
        updatedAt: "2024-06-08T01:56:40.266Z",
      },
      {
        id: "clx5gs3k001mr12vcm00y0hud",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Te3zSapyo1",
            locked: 1717811778,
            timestamp: 1717811776,
          },
        ],
        createdAt: "2024-06-08T01:56:18.816Z",
        updatedAt: "2024-06-08T01:56:18.816Z",
      },
      {
        id: "clx5grxb701mq12vc6zq29qia",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "B8R7MgUc7U",
            locked: 1717811770,
            timestamp: 1717811768,
          },
        ],
        createdAt: "2024-06-08T01:56:10.724Z",
        updatedAt: "2024-06-08T01:56:10.724Z",
      },
      {
        id: "clx5grv6f01mp12vc9nrw3o6n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TLjaAnhtxs",
            locked: 1717811767,
            timestamp: 1717811766,
          },
        ],
        createdAt: "2024-06-08T01:56:07.956Z",
        updatedAt: "2024-06-08T01:56:07.956Z",
      },
      {
        id: "clx5grf9401mo12vc217qd0k2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "s7pVSpGK4R",
            locked: 1717811746,
            timestamp: 1717811744,
          },
        ],
        createdAt: "2024-06-08T01:55:47.320Z",
        updatedAt: "2024-06-08T01:55:47.320Z",
      },
      {
        id: "clx5grckd01mn12vcmqiwarkd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RPfs4pjwhj",
            locked: 1717811736,
            timestamp: 1717811730,
          },
        ],
        createdAt: "2024-06-08T01:55:43.838Z",
        updatedAt: "2024-06-08T01:55:43.838Z",
      },
      {
        id: "clx5grc4x01mm12vc7l0skcfy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WOM3HgxXvS",
            locked: 1717811742,
            timestamp: 1717811741,
          },
        ],
        createdAt: "2024-06-08T01:55:43.282Z",
        updatedAt: "2024-06-08T01:55:43.282Z",
      },
      {
        id: "clx5gr8ut01ml12vc6luozgzm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "FP5KdWUcTQ",
            locked: 1717811738,
            timestamp: 1717811737,
          },
        ],
        createdAt: "2024-06-08T01:55:39.026Z",
        updatedAt: "2024-06-08T01:55:39.026Z",
      },
      {
        id: "clx5gr09701mk12vcfisefqge",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "BiGcXgnd5Y",
            locked: 1717811727,
            timestamp: 1717811725,
          },
        ],
        createdAt: "2024-06-08T01:55:27.884Z",
        updatedAt: "2024-06-08T01:55:27.884Z",
      },
      {
        id: "clx5gqzat01mj12vcwmv8c4df",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "F0xKj5iM0y",
            locked: 1717811726,
            timestamp: 1717811724,
          },
        ],
        createdAt: "2024-06-08T01:55:26.646Z",
        updatedAt: "2024-06-08T01:55:26.646Z",
      },
      {
        id: "clx5gqw1l01mi12vcpl34yebn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WtGGeI4UnE",
            locked: 1717811721,
            timestamp: 1717811720,
          },
        ],
        createdAt: "2024-06-08T01:55:22.421Z",
        updatedAt: "2024-06-08T01:55:22.421Z",
      },
      {
        id: "clx5gq2sb01mh12vcfi0it5b6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "vV9vP4HNik",
            locked: 1717811684,
            timestamp: 1717811682,
          },
        ],
        createdAt: "2024-06-08T01:54:44.507Z",
        updatedAt: "2024-06-08T01:54:44.507Z",
      },
      {
        id: "clx5gq2pc01mg12vcg534rnlp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A156U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (34/14; 450dpi; 1080x2125; samsung; SM-A156U; a15x; mt6835; en_US; 601420801)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "anHTqZkLIa",
            locked: 1717811683,
            timestamp: 1717811680,
          },
        ],
        createdAt: "2024-06-08T01:54:44.400Z",
        updatedAt: "2024-06-08T01:54:44.400Z",
      },
      {
        id: "clx5gptig01mf12vcoijvzbav",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vpH3PnL34M",
            locked: 1717811671,
            timestamp: 1717811670,
          },
        ],
        createdAt: "2024-06-08T01:54:32.487Z",
        updatedAt: "2024-06-08T01:54:32.487Z",
      },
      {
        id: "clx5gpnju01me12vci3zjf19p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21B74 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vqj6H3esGR",
            locked: 1717811663,
            timestamp: 1717811662,
          },
        ],
        createdAt: "2024-06-08T01:54:24.763Z",
        updatedAt: "2024-06-08T01:54:24.763Z",
      },
      {
        id: "clx5gplbq01md12vc2hi54zyw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iwE7UAkyxJ",
            locked: 1717811661,
            timestamp: 1717811658,
          },
        ],
        createdAt: "2024-06-08T01:54:21.878Z",
        updatedAt: "2024-06-08T01:54:21.878Z",
      },
      {
        id: "clx5gpjax01mc12vcgouauhnd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "wAyBjeEznN",
            locked: 1717811659,
            timestamp: 1717811658,
          },
        ],
        createdAt: "2024-06-08T01:54:19.257Z",
        updatedAt: "2024-06-08T01:54:19.257Z",
      },
      {
        id: "clx5gpfy601mb12vc4gtzbad2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OQBqUEJcg1",
            locked: 1717811654,
            timestamp: 1717811652,
          },
        ],
        createdAt: "2024-06-08T01:54:14.910Z",
        updatedAt: "2024-06-08T01:54:14.910Z",
      },
      {
        id: "clx5gpei201ma12vchlx1o7af",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20F66 Instagram 308.0.2.18.106 (iPhone14,8; iOS 16_5; en_US; en; scale=3.00; 1284x2778; 533874066)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "XuIYOquf21",
            locked: 1717811651,
            timestamp: 1717811650,
          },
        ],
        createdAt: "2024-06-08T01:54:13.033Z",
        updatedAt: "2024-06-08T01:54:13.033Z",
      },
      {
        id: "clx5gpc5l01m912vc21ladmec",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 327.1.6.30.88 (iPhone15,2; iOS 17_5_1; en_AU; en; scale=3.00; 1179x2556; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Mu30sfo5q1",
            locked: 1717811649,
            timestamp: 1717811647,
          },
        ],
        createdAt: "2024-06-08T01:54:09.993Z",
        updatedAt: "2024-06-08T01:54:09.993Z",
      },
      {
        id: "clx5gp26a01m812vcdaa0exa8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Android 10; Mobile; rv:126.0) Gecko/126.0 Firefox/126.0",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "SOD6F1gX6w",
            locked: 1717811636,
            timestamp: 1717811635,
          },
        ],
        createdAt: "2024-06-08T01:53:57.058Z",
        updatedAt: "2024-06-08T01:53:57.058Z",
      },
      {
        id: "clx5gp14y01m712vcfs3nk314",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JQYeKxsbvL",
            locked: 1717811635,
            timestamp: 1717811605,
          },
        ],
        createdAt: "2024-06-08T01:53:55.712Z",
        updatedAt: "2024-06-08T01:53:55.712Z",
      },
      {
        id: "clx5gnzsg01m612vc3i1shp57",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Sl6hnrC2mk",
            locked: 1717811587,
            timestamp: 1717811586,
          },
        ],
        createdAt: "2024-06-08T01:53:07.313Z",
        updatedAt: "2024-06-08T01:53:07.313Z",
      },
      {
        id: "clx5gnt8301m512vc5shonwzx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PNdBC6EZlu",
            locked: 1717811578,
            timestamp: 1717811577,
          },
        ],
        createdAt: "2024-06-08T01:52:58.800Z",
        updatedAt: "2024-06-08T01:52:58.800Z",
      },
      {
        id: "clx5gmpz901m412vcu66qq7jo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lWqNSWY8X8",
            locked: 1717811527,
            timestamp: 1717811525,
          },
        ],
        createdAt: "2024-06-08T01:52:07.938Z",
        updatedAt: "2024-06-08T01:52:07.938Z",
      },
      {
        id: "clx5gmbtt01m312vcih86c0ty",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ca4q1heTr8",
            locked: 1717811509,
            timestamp: 1717811507,
          },
        ],
        createdAt: "2024-06-08T01:51:49.601Z",
        updatedAt: "2024-06-08T01:51:49.601Z",
      },
      {
        id: "clx5gm2f401m212vctzf04tcg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PdNvXTm1JE",
            locked: 1717811496,
            timestamp: 1717811495,
          },
        ],
        createdAt: "2024-06-08T01:51:37.406Z",
        updatedAt: "2024-06-08T01:51:37.406Z",
      },
      {
        id: "clx5gkxwb01m112vchj43pwdn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "DAxseduMtP",
            locked: 1717811444,
            timestamp: 1717811443,
          },
        ],
        createdAt: "2024-06-08T01:50:44.891Z",
        updatedAt: "2024-06-08T01:50:44.891Z",
      },
      {
        id: "clx5gkptf01m012vc8z00yusj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "p6GpmMsnvE",
            locked: 1717811433,
            timestamp: 1717811432,
          },
        ],
        createdAt: "2024-06-08T01:50:34.417Z",
        updatedAt: "2024-06-08T01:50:34.417Z",
      },
      {
        id: "clx5gkdpb01lz12vcdbwlvaju",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6DVDCo8Ml9",
            locked: 1717811413,
            timestamp: 1717811410,
          },
        ],
        createdAt: "2024-06-08T01:50:18.719Z",
        updatedAt: "2024-06-08T01:50:18.719Z",
      },
      {
        id: "clx5gk5uc01ly12vc72jwmt23",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "TB8sjTDdFU",
            locked: 1717811408,
            timestamp: 1717811407,
          },
        ],
        createdAt: "2024-06-08T01:50:08.532Z",
        updatedAt: "2024-06-08T01:50:08.532Z",
      },
      {
        id: "clx5gk4od01lx12vclnzv0g6z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bO7Uylawkk",
            locked: 1717811406,
            timestamp: 1717811405,
          },
        ],
        createdAt: "2024-06-08T01:50:07.018Z",
        updatedAt: "2024-06-08T01:50:07.018Z",
      },
      {
        id: "clx5gjskb01lw12vczwres012",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RQklV3pprG",
            locked: 1717811390,
            timestamp: 1717811388,
          },
        ],
        createdAt: "2024-06-08T01:49:51.320Z",
        updatedAt: "2024-06-08T01:49:51.320Z",
      },
      {
        id: "clx5giufh01lv12vcahmomvjs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "uMrYiRZx0d",
            locked: 1717811343,
            timestamp: 1717811342,
          },
        ],
        createdAt: "2024-06-08T01:49:07.085Z",
        updatedAt: "2024-06-08T01:49:07.085Z",
      },
      {
        id: "clx5giper01lu12vcc4du6tbc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rVZTbQrWHe",
            locked: 1717811339,
            timestamp: 1717811338,
          },
        ],
        createdAt: "2024-06-08T01:49:00.576Z",
        updatedAt: "2024-06-08T01:49:00.576Z",
      },
      {
        id: "clx5ghio201lt12vcoxt0ug4a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) AppleWebKit/420+",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "l4Cmu1Q0ZL",
            locked: 1717811284,
            timestamp: 1717811281,
          },
        ],
        createdAt: "2024-06-08T01:48:05.183Z",
        updatedAt: "2024-06-08T01:48:05.183Z",
      },
      {
        id: "clx5ggm0o01ls12vcixwau2uo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21A350 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_0_2; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "XtDSsFZMEa",
            locked: 1717811242,
            timestamp: 1717811241,
          },
        ],
        createdAt: "2024-06-08T01:47:22.872Z",
        updatedAt: "2024-06-08T01:47:22.872Z",
      },
      {
        id: "clx5ggjvo01lr12vcel2js8zd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VannmkFwDV",
            locked: 1717811239,
            timestamp: 1717811237,
          },
        ],
        createdAt: "2024-06-08T01:47:20.101Z",
        updatedAt: "2024-06-08T01:47:20.101Z",
      },
      {
        id: "clx5ggi9301lq12vc8g8ky7p2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21A350 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_0_2; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "iAGRHrIdsV",
            locked: 1717811237,
            timestamp: 1717811236,
          },
        ],
        createdAt: "2024-06-08T01:47:17.988Z",
        updatedAt: "2024-06-08T01:47:17.988Z",
      },
      {
        id: "clx5gf8p401lp12vcco32v186",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "l0DLpDLahK",
            locked: 1717811178,
            timestamp: 1717811177,
          },
        ],
        createdAt: "2024-06-08T01:46:18.953Z",
        updatedAt: "2024-06-08T01:46:18.953Z",
      },
      {
        id: "clx5gezcq01lo12vc7nydth56",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G9980 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2190; samsung; SM-G9980; p3q; qcom; es_ES; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ofwwfLZNKK",
            locked: 1717811166,
            timestamp: 1717811162,
          },
        ],
        createdAt: "2024-06-08T01:46:06.840Z",
        updatedAt: "2024-06-08T01:46:06.840Z",
      },
      {
        id: "clx5geju801ln12vch74vajot",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 331.1.3.29.90 (iPhone12,5; iOS 17_4_1; en_US; en; scale=3.00; 1242x2688; 599297333) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zpQtnK9cSb",
            locked: 1717811146,
            timestamp: 1717811142,
          },
        ],
        createdAt: "2024-06-08T01:45:46.734Z",
        updatedAt: "2024-06-08T01:45:46.734Z",
      },
      {
        id: "clx5gdhjq01lm12vctttdtf8e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-S908U1 Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/111.0.5563.116 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (33/13; 450dpi; 1080x2106; samsung; SM-S908U1; b0q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "cOYDTLQ2LR",
            locked: 1717811096,
            timestamp: 1717811089,
          },
        ],
        createdAt: "2024-06-08T01:44:57.110Z",
        updatedAt: "2024-06-08T01:44:57.110Z",
      },
      {
        id: "clx5gdah701ll12vcqr1oup83",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone14,7; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "96M6Vwz8dY",
            locked: 1717811087,
            timestamp: 1717811086,
          },
        ],
        createdAt: "2024-06-08T01:44:47.946Z",
        updatedAt: "2024-06-08T01:44:47.946Z",
      },
      {
        id: "clx5gda4701lk12vckvybe9pt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9G6gW8dyX6",
            locked: 1717811086,
            timestamp: 1717811085,
          },
        ],
        createdAt: "2024-06-08T01:44:47.479Z",
        updatedAt: "2024-06-08T01:44:47.479Z",
      },
      {
        id: "clx5gcyq901lj12vccfmiyssq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G75 Instagram 320.0.2.29.98 (iPhone12,8; iOS 16_6; en_US; en; scale=2.00; 750x1334; 569998124) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ku5aOQbgfu",
            locked: 1717811069,
            timestamp: 1717811067,
          },
        ],
        createdAt: "2024-06-08T01:44:32.719Z",
        updatedAt: "2024-06-08T01:44:32.719Z",
      },
      {
        id: "clx5g9irk01li12vctrb9jyuj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MLYUtajJ23",
            locked: 1717810911,
            timestamp: 1717810909,
          },
        ],
        createdAt: "2024-06-08T01:41:52.061Z",
        updatedAt: "2024-06-08T01:41:52.061Z",
      },
      {
        id: "clx5g8fv201lh12vc8vfq1h34",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "XSNtc6Z7Jv",
            locked: 1717810861,
            timestamp: 1717810860,
          },
        ],
        createdAt: "2024-06-08T01:41:01.646Z",
        updatedAt: "2024-06-08T01:41:01.646Z",
      },
      {
        id: "clx5g89zz01lg12vctrm5utbe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "niHO60Apn6",
            locked: 1717810853,
            timestamp: 1717810852,
          },
        ],
        createdAt: "2024-06-08T01:40:54.047Z",
        updatedAt: "2024-06-08T01:40:54.047Z",
      },
      {
        id: "clx5g89de01lf12vcpox06uqi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "BKLTZ7EEvV",
            locked: 1717810853,
            timestamp: 1717810852,
          },
        ],
        createdAt: "2024-06-08T01:40:53.234Z",
        updatedAt: "2024-06-08T01:40:53.234Z",
      },
      {
        id: "clx5g88sk01le12vc96phvhw1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KKE5IxFB8f",
            locked: 1717810852,
            timestamp: 1717810850,
          },
        ],
        createdAt: "2024-06-08T01:40:52.482Z",
        updatedAt: "2024-06-08T01:40:52.482Z",
      },
      {
        id: "clx5g82ss01ld12vcl94f6obt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "M2sonNuP9i",
            locked: 1717810844,
            timestamp: 1717810842,
          },
        ],
        createdAt: "2024-06-08T01:40:44.716Z",
        updatedAt: "2024-06-08T01:40:44.716Z",
      },
      {
        id: "clx5g80g301lc12vc4olg87zx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KFvyVSoIbd",
            locked: 1717810841,
            timestamp: 1717810840,
          },
        ],
        createdAt: "2024-06-08T01:40:41.667Z",
        updatedAt: "2024-06-08T01:40:41.667Z",
      },
      {
        id: "clx5g7vcp01lb12vcn7q0b43r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gHSjsaXVea",
            locked: 1717810834,
            timestamp: 1717810833,
          },
        ],
        createdAt: "2024-06-08T01:40:35.063Z",
        updatedAt: "2024-06-08T01:40:35.063Z",
      },
      {
        id: "clx5g782h01la12vcc9skalqv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "bvsnGJVrFZ",
            locked: 1717810804,
            timestamp: 1717810803,
          },
        ],
        createdAt: "2024-06-08T01:40:04.887Z",
        updatedAt: "2024-06-08T01:40:04.887Z",
      },
      {
        id: "clx5g6vys01l912vcjf5qgc4z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "5HqBHyvwWE",
            locked: 1717810789,
            timestamp: 1717810788,
          },
        ],
        createdAt: "2024-06-08T01:39:49.204Z",
        updatedAt: "2024-06-08T01:39:49.204Z",
      },
      {
        id: "clx5g6vfn01l812vca26urd67",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1624; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RYuPmsrd32",
            locked: 1717810788,
            timestamp: 1717810785,
          },
        ],
        createdAt: "2024-06-08T01:39:48.511Z",
        updatedAt: "2024-06-08T01:39:48.511Z",
      },
      {
        id: "clx5g5cx101l712vckab8f7gb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F936U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 360dpi; 904x2121; samsung; SM-F936U; q4q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iIcS3F7GgI",
            locked: 1717810706,
            timestamp: 1717810705,
          },
        ],
        createdAt: "2024-06-08T01:38:37.858Z",
        updatedAt: "2024-06-08T01:38:37.858Z",
      },
      {
        id: "clx5g4qx301l612vckdctl84g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,6; iOS 17_5_1; en_CA; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "teglD70HIq",
            locked: 1717810689,
            timestamp: 1717810688,
          },
        ],
        createdAt: "2024-06-08T01:38:09.348Z",
        updatedAt: "2024-06-08T01:38:09.348Z",
      },
      {
        id: "clx5g4d5701l512vcni71fjzr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,6; iOS 17_5_1; en_CA; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7qcwM69kgS",
            locked: 1717810671,
            timestamp: 1717810669,
          },
        ],
        createdAt: "2024-06-08T01:37:51.496Z",
        updatedAt: "2024-06-08T01:37:51.496Z",
      },
      {
        id: "clx5g3x5t01l412vcg5uj707k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HCxgTPX5Gz",
            locked: 1717810650,
            timestamp: 1717810649,
          },
        ],
        createdAt: "2024-06-08T01:37:30.786Z",
        updatedAt: "2024-06-08T01:37:30.786Z",
      },
      {
        id: "clx5g3vff01l312vchdxc47s7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "rqxVw4F4la",
            locked: 1717810647,
            timestamp: 1717810641,
          },
        ],
        createdAt: "2024-06-08T01:37:28.537Z",
        updatedAt: "2024-06-08T01:37:28.537Z",
      },
      {
        id: "clx5g3mqd01l212vc8jd8vbbo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mDSSEVPT4N",
            locked: 1717810636,
            timestamp: 1717810634,
          },
        ],
        createdAt: "2024-06-08T01:37:17.269Z",
        updatedAt: "2024-06-08T01:37:17.269Z",
      },
      {
        id: "clx5g3irw01l112vc4vyo5ci4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Pr2LchauuV",
            locked: 1717810631,
            timestamp: 1717810630,
          },
        ],
        createdAt: "2024-06-08T01:37:12.140Z",
        updatedAt: "2024-06-08T01:37:12.140Z",
      },
      {
        id: "clx5g3gr201l012vctpe8d5vn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hREyNLj7jP",
            locked: 1717810627,
            timestamp: 1717810623,
          },
        ],
        createdAt: "2024-06-08T01:37:09.518Z",
        updatedAt: "2024-06-08T01:37:09.518Z",
      },
      {
        id: "clx5g3dfa01kz12vcnx42ojey",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5SHORYOHZR",
            locked: 1717810616,
            timestamp: 1717810615,
          },
        ],
        createdAt: "2024-06-08T01:37:05.204Z",
        updatedAt: "2024-06-08T01:37:05.204Z",
      },
      {
        id: "clx5g30t901ky12vcvo42vas5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; CrOS x86_64 14541.0.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "1IWRWCdE1A",
            locked: 1717810607,
            timestamp: 1717810606,
          },
        ],
        createdAt: "2024-06-08T01:36:48.859Z",
        updatedAt: "2024-06-08T01:36:48.859Z",
      },
      {
        id: "clx5g2krg01kx12vcbun16lu1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; CrOS x86_64 14541.0.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "C7QUZj2yXx",
            locked: 1717810586,
            timestamp: 1717810581,
          },
        ],
        createdAt: "2024-06-08T01:36:28.060Z",
        updatedAt: "2024-06-08T01:36:28.060Z",
      },
      {
        id: "clx5g2bn601kw12vcb98o5sgc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vrWkNJJP1K",
            locked: 1717810575,
            timestamp: 1717810573,
          },
        ],
        createdAt: "2024-06-08T01:36:16.240Z",
        updatedAt: "2024-06-08T01:36:16.240Z",
      },
      {
        id: "clx5g1cf401kv12vch3gn82nb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3F1hGZKFx0",
            locked: 1717810530,
            timestamp: 1717810529,
          },
        ],
        createdAt: "2024-06-08T01:35:30.589Z",
        updatedAt: "2024-06-08T01:35:30.589Z",
      },
      {
        id: "clx5g0atd01ku12vcvb9y2q1t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "gxMyVlM02C",
            locked: 1717810482,
            timestamp: 1717810481,
          },
        ],
        createdAt: "2024-06-08T01:34:41.855Z",
        updatedAt: "2024-06-08T01:34:41.855Z",
      },
      {
        id: "clx5fzzde01kt12vccho88x8q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QrTgNskbts",
            locked: 1717810467,
            timestamp: 1717810465,
          },
        ],
        createdAt: "2024-06-08T01:34:27.026Z",
        updatedAt: "2024-06-08T01:34:27.026Z",
      },
      {
        id: "clx5fzqdv01ks12vcekza7pdn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad13,18; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JosWZJJ3qz",
            locked: 1717810454,
            timestamp: 1717810453,
          },
        ],
        createdAt: "2024-06-08T01:34:15.378Z",
        updatedAt: "2024-06-08T01:34:15.378Z",
      },
      {
        id: "clx5fytok01kr12vcaa8o6mgj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21C66 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_2_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NQVFdLHNo7",
            locked: 1717810412,
            timestamp: 1717810410,
          },
        ],
        createdAt: "2024-06-08T01:33:32.997Z",
        updatedAt: "2024-06-08T01:33:32.997Z",
      },
      {
        id: "clx5fysap01kq12vcn64l655k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "TYbvAmaCEa",
            locked: 1717810410,
            timestamp: 1717810409,
          },
        ],
        createdAt: "2024-06-08T01:33:31.202Z",
        updatedAt: "2024-06-08T01:33:31.202Z",
      },
      {
        id: "clx5fynhf01kp12vchdt1nzte",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 640dpi; 1440x2796; samsung; SM-S918U1; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "RvtIDRUnq9",
            locked: 1717810404,
            timestamp: 1717810403,
          },
        ],
        createdAt: "2024-06-08T01:33:24.961Z",
        updatedAt: "2024-06-08T01:33:24.961Z",
      },
      {
        id: "clx5fybc401ko12vcrp6lwxed",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 640dpi; 1440x2796; samsung; SM-S918U1; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "51NjDEihsr",
            locked: 1717810388,
            timestamp: 1717810387,
          },
        ],
        createdAt: "2024-06-08T01:33:09.221Z",
        updatedAt: "2024-06-08T01:33:09.221Z",
      },
      {
        id: "clx5fy9sj01kn12vckjksuuu3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MJvY1NjSuL",
            locked: 1717810386,
            timestamp: 1717810385,
          },
        ],
        createdAt: "2024-06-08T01:33:07.216Z",
        updatedAt: "2024-06-08T01:33:07.216Z",
      },
      {
        id: "clx5fuls701km12vcw6tmopze",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "zEfSoHB4Fj",
            locked: 1717810216,
            timestamp: 1717810215,
          },
        ],
        createdAt: "2024-06-08T01:30:16.135Z",
        updatedAt: "2024-06-08T01:30:16.135Z",
      },
      {
        id: "clx5fuehr01kl12vcsng60vdv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "viCMWh5C5O",
            locked: 1717810206,
            timestamp: 1717810204,
          },
        ],
        createdAt: "2024-06-08T01:30:06.684Z",
        updatedAt: "2024-06-08T01:30:06.684Z",
      },
      {
        id: "clx5fty6401kk12vc6n0hxcex",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 [FBAN/FBIOS;FBAV/466.0.0.36.107;FBBV/604687626;FBDV/iPhone16,2;FBMD/iPhone;FBSN/iOS;FBSV/17.4.1;FBSS/3;FBID/phone;FBLC/en_US;FBOP/5;FBRV/607733558]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZwpNNa75XE",
            locked: 1717810184,
            timestamp: 1717810183,
          },
        ],
        createdAt: "2024-06-08T01:29:45.529Z",
        updatedAt: "2024-06-08T01:29:45.529Z",
      },
      {
        id: "clx5ftls701kj12vcmi57v0zd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "B1gOlu8lLS",
            locked: 1717810169,
            timestamp: 1717810167,
          },
        ],
        createdAt: "2024-06-08T01:29:29.477Z",
        updatedAt: "2024-06-08T01:29:29.477Z",
      },
      {
        id: "clx5ftdcu01ki12vcb6lz8fig",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3DEW6Q4pXR",
            locked: 1717810158,
            timestamp: 1717810156,
          },
        ],
        createdAt: "2024-06-08T01:29:18.559Z",
        updatedAt: "2024-06-08T01:29:18.559Z",
      },
      {
        id: "clx5ft7ij01kh12vcn0440u77",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6IE52kbMu7",
            locked: 1717810150,
            timestamp: 1717810149,
          },
        ],
        createdAt: "2024-06-08T01:29:10.985Z",
        updatedAt: "2024-06-08T01:29:10.985Z",
      },
      {
        id: "clx5fstwi01kg12vcyrhpy69q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CJuJKLO0pT",
            locked: 1717810132,
            timestamp: 1717810129,
          },
        ],
        createdAt: "2024-06-08T01:28:53.347Z",
        updatedAt: "2024-06-08T01:28:53.347Z",
      },
      {
        id: "clx5fsoz601kf12vclb40m4m4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "2hSiRTBtuO",
            locked: 1717810126,
            timestamp: 1717810125,
          },
        ],
        createdAt: "2024-06-08T01:28:46.959Z",
        updatedAt: "2024-06-08T01:28:46.959Z",
      },
      {
        id: "clx5fs34p01ke12vcbe9i98lq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xcj0e3urpq",
            locked: 1717810097,
            timestamp: 1717810094,
          },
        ],
        createdAt: "2024-06-08T01:28:18.646Z",
        updatedAt: "2024-06-08T01:28:18.646Z",
      },
      {
        id: "clx5fra5m01kd12vckdjjuu39",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "d0bN6q5Z5j",
            locked: 1717810060,
            timestamp: 1717810057,
          },
        ],
        createdAt: "2024-06-08T01:27:41.099Z",
        updatedAt: "2024-06-08T01:27:41.099Z",
      },
      {
        id: "clx5fr3f601kc12vc37xlcods",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Ufx8QXkMBF",
            locked: 1717810051,
            timestamp: 1717810050,
          },
        ],
        createdAt: "2024-06-08T01:27:32.370Z",
        updatedAt: "2024-06-08T01:27:32.370Z",
      },
      {
        id: "clx5fr0ru01kb12vck9i2hn4q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Zi03e3pIiP",
            locked: 1717810048,
            timestamp: 1717810045,
          },
        ],
        createdAt: "2024-06-08T01:27:28.936Z",
        updatedAt: "2024-06-08T01:27:28.936Z",
      },
      {
        id: "clx5fqgf801ka12vczv7p8b92",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G998U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 315.0.0.29.109 Android (34/14; 540dpi; 1080x2163; samsung; SM-G998U; p3q; qcom; es_US; 558601257)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yVQVQz1LxZ",
            locked: 1717810021,
            timestamp: 1717810019,
          },
        ],
        createdAt: "2024-06-08T01:27:02.561Z",
        updatedAt: "2024-06-08T01:27:02.561Z",
      },
      {
        id: "clx5foeah01k912vc80z873wk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3usaI3SJXq",
            locked: 1717809925,
            timestamp: 1717809924,
          },
        ],
        createdAt: "2024-06-08T01:25:26.489Z",
        updatedAt: "2024-06-08T01:25:26.489Z",
      },
      {
        id: "clx5foauh01k812vcbpafmcqf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "XbWZuMhAtC",
            locked: 1717809921,
            timestamp: 1717809919,
          },
        ],
        createdAt: "2024-06-08T01:25:22.023Z",
        updatedAt: "2024-06-08T01:25:22.023Z",
      },
      {
        id: "clx5fms6z01k712vci2jwieto",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "D5b8Egsn4z",
            locked: 1717809850,
            timestamp: 1717809849,
          },
        ],
        createdAt: "2024-06-08T01:24:11.195Z",
        updatedAt: "2024-06-08T01:24:11.195Z",
      },
      {
        id: "clx5fmqd401k612vcag0ecl1w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "eOFnHQcyBr",
            locked: 1717809848,
            timestamp: 1717809847,
          },
        ],
        createdAt: "2024-06-08T01:24:08.822Z",
        updatedAt: "2024-06-08T01:24:08.822Z",
      },
      {
        id: "clx5fm50101k512vcxl6mw0zl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cmR9z16Vfy",
            locked: 1717809820,
            timestamp: 1717809819,
          },
        ],
        createdAt: "2024-06-08T01:23:41.138Z",
        updatedAt: "2024-06-08T01:23:41.138Z",
      },
      {
        id: "clx5flwvm01k412vcmzspw08u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "utBLwlJJfl",
            locked: 1717809809,
            timestamp: 1717809808,
          },
        ],
        createdAt: "2024-06-08T01:23:30.608Z",
        updatedAt: "2024-06-08T01:23:30.608Z",
      },
      {
        id: "clx5fkgz601k312vckykzjobo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S711U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2125; samsung; SM-S711U; r11q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JqNglgNQt6",
            locked: 1717809742,
            timestamp: 1717809740,
          },
        ],
        createdAt: "2024-06-08T01:22:23.344Z",
        updatedAt: "2024-06-08T01:22:23.344Z",
      },
      {
        id: "clx5fjwd901k212vc8zpuemwg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; moto g41 Build/S3RWS32.138-29-5-5; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/116.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UM0pZFhRyQ",
            locked: 1717809742,
            timestamp: 1717809729,
          },
        ],
        createdAt: "2024-06-08T01:21:56.637Z",
        updatedAt: "2024-06-08T01:21:56.637Z",
      },
      {
        id: "clx5fjlv501k112vcah9txasi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-G965U Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 480dpi; 1080x2076; samsung; SM-G965U; star2qltesq; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LmJ81PMAIg",
            locked: 1717809699,
            timestamp: 1717809694,
          },
        ],
        createdAt: "2024-06-08T01:21:43.023Z",
        updatedAt: "2024-06-08T01:21:43.023Z",
      },
      {
        id: "clx5fj7s901k012vc1bashc1r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad12,2; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "K9n7MHt0Z4",
            locked: 1717809684,
            timestamp: 1717809682,
          },
        ],
        createdAt: "2024-06-08T01:21:24.775Z",
        updatedAt: "2024-06-08T01:21:24.775Z",
      },
      {
        id: "clx5fhsvh01jz12vce57dr082",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lQxcGopoio",
            locked: 1717809618,
            timestamp: 1717809616,
          },
        ],
        createdAt: "2024-06-08T01:20:18.797Z",
        updatedAt: "2024-06-08T01:20:18.797Z",
      },
      {
        id: "clx5fhnh301jy12vcsbbxw0k5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 560dpi; 1440x2851; samsung; SM-S928U1; e3q; qcom; en_US; 606558490)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8X5jkYZCz1",
            locked: 1717809610,
            timestamp: 1717809607,
          },
        ],
        createdAt: "2024-06-08T01:20:11.797Z",
        updatedAt: "2024-06-08T01:20:11.797Z",
      },
      {
        id: "clx5fh0if01jx12vcn2qgj5en",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "15KGCURSvn",
            locked: 1717809581,
            timestamp: 1717809580,
          },
        ],
        createdAt: "2024-06-08T01:19:42.037Z",
        updatedAt: "2024-06-08T01:19:42.037Z",
      },
      {
        id: "clx5ffli701jw12vcqm3oofek",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "J7IcgWnwHR",
            locked: 1717809515,
            timestamp: 1717809514,
          },
        ],
        createdAt: "2024-06-08T01:18:35.933Z",
        updatedAt: "2024-06-08T01:18:35.933Z",
      },
      {
        id: "clx5feq5d01jv12vcfj8wn3tx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D47 Instagram 317.0.4.27.109 (iPhone13,3; iOS 16_3; en_US; en; scale=3.00; 1170x2532; 562830928) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "y3nJHt12IA",
            locked: 1717809474,
            timestamp: 1717809470,
          },
        ],
        createdAt: "2024-06-08T01:17:55.295Z",
        updatedAt: "2024-06-08T01:17:55.295Z",
      },
      {
        id: "clx5fd8ec01ju12vcbra50i9k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; es_US; es; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ZDdkvFzeJ0",
            locked: 1717809404,
            timestamp: 1717809403,
          },
        ],
        createdAt: "2024-06-08T01:16:45.634Z",
        updatedAt: "2024-06-08T01:16:45.634Z",
      },
      {
        id: "clx5fbyhq01jt12vcd689qafy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone12,8; iOS 17_4_1; en_US; en; scale=2.00; 750x1334; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GpevHkxhDw",
            locked: 1717809344,
            timestamp: 1717809343,
          },
        ],
        createdAt: "2024-06-08T01:15:46.143Z",
        updatedAt: "2024-06-08T01:15:46.143Z",
      },
      {
        id: "clx5fbqvz01js12vcik4pqv7f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tpry1GRqR3",
            locked: 1717809335,
            timestamp: 1717809330,
          },
        ],
        createdAt: "2024-06-08T01:15:36.287Z",
        updatedAt: "2024-06-08T01:15:36.287Z",
      },
      {
        id: "clx5fbovy01jr12vck7uj3mfo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zRSUd1g51S",
            locked: 1717809332,
            timestamp: 1717809331,
          },
        ],
        createdAt: "2024-06-08T01:15:33.692Z",
        updatedAt: "2024-06-08T01:15:33.692Z",
      },
      {
        id: "clx5fau4o01jq12vcrj6wim5g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZBAfl989rz",
            locked: 1717809293,
            timestamp: 1717809291,
          },
        ],
        createdAt: "2024-06-08T01:14:53.830Z",
        updatedAt: "2024-06-08T01:14:53.830Z",
      },
      {
        id: "clx5fahjv01jp12vc6rheifpl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; motorola edge 5G UW (2021) Build/T1RM33.1-110-17; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 400dpi; 1080x2285; motorola; motorola edge 5G UW (2021); berlna; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "l4RqS2wUbc",
            locked: 1717809277,
            timestamp: 1717809275,
          },
        ],
        createdAt: "2024-06-08T01:14:37.528Z",
        updatedAt: "2024-06-08T01:14:37.528Z",
      },
      {
        id: "clx5f9eri01jo12vc53ss6iwc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KuhXQJgCQY",
            locked: 1717809227,
            timestamp: 1717809225,
          },
        ],
        createdAt: "2024-06-08T01:13:47.263Z",
        updatedAt: "2024-06-08T01:13:47.263Z",
      },
      {
        id: "clx5f962z01jn12vc3d0apvr7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5V9pwTR544",
            locked: 1717809215,
            timestamp: 1717809213,
          },
        ],
        createdAt: "2024-06-08T01:13:36.009Z",
        updatedAt: "2024-06-08T01:13:36.009Z",
      },
      {
        id: "clx5f7qw301jm12vc63j51arr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "y24fsUuQ0F",
            locked: 1717809148,
            timestamp: 1717809147,
          },
        ],
        createdAt: "2024-06-08T01:12:29.667Z",
        updatedAt: "2024-06-08T01:12:29.667Z",
      },
      {
        id: "clx5f7qaa01jl12vcruy60so1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "qZ8oADa7fm",
            locked: 1717809148,
            timestamp: 1717809147,
          },
        ],
        createdAt: "2024-06-08T01:12:28.881Z",
        updatedAt: "2024-06-08T01:12:28.881Z",
      },
      {
        id: "clx5f7o1v01jk12vckn0s48gz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gJD6bAPS2z",
            locked: 1717809145,
            timestamp: 1717809144,
          },
        ],
        createdAt: "2024-06-08T01:12:25.987Z",
        updatedAt: "2024-06-08T01:12:25.987Z",
      },
      {
        id: "clx5f7ejx01jj12vcmpwf51pu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; CrOS x86_64 14541.0.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "6JyfC66wM6",
            locked: 1717809132,
            timestamp: 1717809131,
          },
        ],
        createdAt: "2024-06-08T01:12:13.678Z",
        updatedAt: "2024-06-08T01:12:13.678Z",
      },
      {
        id: "clx5f7df401ji12vctlhtegfy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "B2u7Ss4oN6",
            locked: 1717809131,
            timestamp: 1717809130,
          },
        ],
        createdAt: "2024-06-08T01:12:12.207Z",
        updatedAt: "2024-06-08T01:12:12.207Z",
      },
      {
        id: "clx5f7a5t01jh12vcnt2v2u07",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "b45gVZruxa",
            locked: 1717809127,
            timestamp: 1717809125,
          },
        ],
        createdAt: "2024-06-08T01:12:07.985Z",
        updatedAt: "2024-06-08T01:12:07.985Z",
      },
      {
        id: "clx5f71oe01jg12vchycd6pfg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; CrOS x86_64 14541.0.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GVHs7BgiOy",
            locked: 1717809115,
            timestamp: 1717809112,
          },
        ],
        createdAt: "2024-06-08T01:11:56.987Z",
        updatedAt: "2024-06-08T01:11:56.987Z",
      },
      {
        id: "clx5f6p9r01jf12vcmv3ffjyy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20A380 Instagram 334.0.4.32.98 (iPhone15,2; iOS 16_0_2; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KkI1b7Ch8E",
            locked: 1717809097,
            timestamp: 1717809092,
          },
        ],
        createdAt: "2024-06-08T01:11:40.909Z",
        updatedAt: "2024-06-08T01:11:40.909Z",
      },
      {
        id: "clx5f5quk01je12vcrhcat5uq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fy01tCMsHr",
            locked: 1717809055,
            timestamp: 1717809054,
          },
        ],
        createdAt: "2024-06-08T01:10:56.300Z",
        updatedAt: "2024-06-08T01:10:56.300Z",
      },
      {
        id: "clx5f5pqe01jd12vc4kf0em08",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F711U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2402; samsung; SM-F711U; b2q; qcom; es_LA; 606558408)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "xgmrzjH521",
            locked: 1717809054,
            timestamp: 1717809053,
          },
        ],
        createdAt: "2024-06-08T01:10:54.852Z",
        updatedAt: "2024-06-08T01:10:54.852Z",
      },
      {
        id: "clx5f4wp201jc12vcdg54oqdc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "C42aZjdfFD",
            locked: 1717809016,
            timestamp: 1717809014,
          },
        ],
        createdAt: "2024-06-08T01:10:17.223Z",
        updatedAt: "2024-06-08T01:10:17.223Z",
      },
      {
        id: "clx5f4ugi01jb12vcpgj90y6b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F711U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2402; samsung; SM-F711U; b2q; qcom; es_LA; 606558408)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LeDniiOIfG",
            locked: 1717809013,
            timestamp: 1717809011,
          },
        ],
        createdAt: "2024-06-08T01:10:14.320Z",
        updatedAt: "2024-06-08T01:10:14.320Z",
      },
      {
        id: "clx5f4kzy01ja12vcbo6sn9ew",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; motorola edge 5G UW (2021) Build/T1RM33.1-110-17; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 540dpi; 1080x2264; motorola; motorola edge 5G UW (2021); berlna; qcom; en_US; 606558499)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zdb6UZKn0z",
            locked: 1717809000,
            timestamp: 1717808995,
          },
        ],
        createdAt: "2024-06-08T01:10:02.062Z",
        updatedAt: "2024-06-08T01:10:02.062Z",
      },
      {
        id: "clx5f4h9701j912vcfpiif3iw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; Mi 9T Build/SQ3A.220705.003; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (32/12; 440dpi; 1080x2296; Xiaomi; Mi 9T; davinci; qcom; ru_RU; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "JKcJzZkUxH",
            locked: 1717808996,
            timestamp: 1717808995,
          },
        ],
        createdAt: "2024-06-08T01:09:57.209Z",
        updatedAt: "2024-06-08T01:09:57.209Z",
      },
      {
        id: "clx5f3m2g01j812vclfttwc9j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; Mi 9T Build/SQ3A.220705.003; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (32/12; 440dpi; 1080x2296; Xiaomi; Mi 9T; davinci; qcom; ru_RU; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NeE4WmBPxa",
            locked: 1717808955,
            timestamp: 1717808953,
          },
        ],
        createdAt: "2024-06-08T01:09:16.790Z",
        updatedAt: "2024-06-08T01:09:16.790Z",
      },
      {
        id: "clx5f3fny01j712vcx58ae7wp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "hnKuETNl2H",
            locked: 1717808945,
            timestamp: 1717808944,
          },
        ],
        createdAt: "2024-06-08T01:09:08.495Z",
        updatedAt: "2024-06-08T01:09:08.495Z",
      },
      {
        id: "clx5f38z501j612vcvwigt2k4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hAOnGYsrDG",
            locked: 1717808936,
            timestamp: 1717808934,
          },
        ],
        createdAt: "2024-06-08T01:08:59.822Z",
        updatedAt: "2024-06-08T01:08:59.822Z",
      },
      {
        id: "clx5f2vuv01j512vcg0ltm81h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "xvtqCIOcXi",
            locked: 1717808922,
            timestamp: 1717808921,
          },
        ],
        createdAt: "2024-06-08T01:08:42.823Z",
        updatedAt: "2024-06-08T01:08:42.823Z",
      },
      {
        id: "clx5f2t1e01j412vc07h0gryi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NQp9Ya4hhX",
            locked: 1717808919,
            timestamp: 1717808917,
          },
        ],
        createdAt: "2024-06-08T01:08:39.167Z",
        updatedAt: "2024-06-08T01:08:39.167Z",
      },
      {
        id: "clx5f1xut01j312vcybd8efri",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RGr6NO3lbL",
            locked: 1717808878,
            timestamp: 1717808876,
          },
        ],
        createdAt: "2024-06-08T01:07:58.756Z",
        updatedAt: "2024-06-08T01:07:58.756Z",
      },
      {
        id: "clx5f1u1001j212vcstz8yacp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ifnXl1vqKk",
            locked: 1717808873,
            timestamp: 1717808871,
          },
        ],
        createdAt: "2024-06-08T01:07:53.796Z",
        updatedAt: "2024-06-08T01:07:53.796Z",
      },
      {
        id: "clx5f1kwt01j112vct2nyuj7s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "q8aFAWfKfU",
            locked: 1717808861,
            timestamp: 1717808858,
          },
        ],
        createdAt: "2024-06-08T01:07:41.978Z",
        updatedAt: "2024-06-08T01:07:41.978Z",
      },
      {
        id: "clx5f0ox401j012vc8lpfii52",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "gX9u1yQre7",
            locked: 1717808819,
            timestamp: 1717808818,
          },
        ],
        createdAt: "2024-06-08T01:07:00.520Z",
        updatedAt: "2024-06-08T01:07:00.520Z",
      },
      {
        id: "clx5f0mzu01iz12vcgcux9vuy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "itU8sYQnTg",
            locked: 1717808817,
            timestamp: 1717808816,
          },
        ],
        createdAt: "2024-06-08T01:06:58.022Z",
        updatedAt: "2024-06-08T01:06:58.022Z",
      },
      {
        id: "clx5ezx5001iy12vc6lny340a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rj83M7hCXz",
            locked: 1717808783,
            timestamp: 1717808782,
          },
        ],
        createdAt: "2024-06-08T01:06:24.514Z",
        updatedAt: "2024-06-08T01:06:24.514Z",
      },
      {
        id: "clx5eygvh01ix12vcjqzoyfwc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_GB; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SJOrqzNBkf",
            locked: 1717808716,
            timestamp: 1717808715,
          },
        ],
        createdAt: "2024-06-08T01:05:16.779Z",
        updatedAt: "2024-06-08T01:05:16.779Z",
      },
      {
        id: "clx5exnh501iw12vce7cjsp0r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WOVX9dg9CH",
            locked: 1717808678,
            timestamp: 1717808676,
          },
        ],
        createdAt: "2024-06-08T01:04:38.679Z",
        updatedAt: "2024-06-08T01:04:38.679Z",
      },
      {
        id: "clx5evvaq01iv12vcenq1u1ly",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-S908U; b0q; qcom; en_US; 606558490)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "x0bEVSyosu",
            locked: 1717808594,
            timestamp: 1717808593,
          },
        ],
        createdAt: "2024-06-08T01:03:15.506Z",
        updatedAt: "2024-06-08T01:03:15.506Z",
      },
      {
        id: "clx5evkvv01iu12vcnimsamm1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H30 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_7_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "JRdSkQGrdm",
            locked: 1717808581,
            timestamp: 1717808580,
          },
        ],
        createdAt: "2024-06-08T01:03:02.008Z",
        updatedAt: "2024-06-08T01:03:02.008Z",
      },
      {
        id: "clx5eva6z01it12vc2axw7nkd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H30 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_7_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "R8iTc29kp4",
            locked: 1717808567,
            timestamp: 1717808566,
          },
        ],
        createdAt: "2024-06-08T01:02:48.156Z",
        updatedAt: "2024-06-08T01:02:48.156Z",
      },
      {
        id: "clx5ev1ba01is12vcapepackc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "fRYJnn0JU0",
            locked: 1717808556,
            timestamp: 1717808555,
          },
        ],
        createdAt: "2024-06-08T01:02:36.644Z",
        updatedAt: "2024-06-08T01:02:36.644Z",
      },
      {
        id: "clx5euioc01ir12vc0blqnpch",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZPnnOtvosv",
            locked: 1717808532,
            timestamp: 1717808530,
          },
        ],
        createdAt: "2024-06-08T01:02:12.491Z",
        updatedAt: "2024-06-08T01:02:12.491Z",
      },
      {
        id: "clx5euczf01iq12vcmdawgnh7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A326U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 300dpi; 720x1457; samsung; SM-A326U; a32x; mt6853; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "APxdUlpJVj",
            locked: 1717808524,
            timestamp: 1717808520,
          },
        ],
        createdAt: "2024-06-08T01:02:05.116Z",
        updatedAt: "2024-06-08T01:02:05.116Z",
      },
      {
        id: "clx5eu4lc01ip12vc0g5n5uzh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VG3VM3ctVI",
            locked: 1717808512,
            timestamp: 1717808511,
          },
        ],
        createdAt: "2024-06-08T01:01:54.241Z",
        updatedAt: "2024-06-08T01:01:54.241Z",
      },
      {
        id: "clx5eu3gi01io12vc8p31v224",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "W3yLAf1FZL",
            locked: 1717808512,
            timestamp: 1717808509,
          },
        ],
        createdAt: "2024-06-08T01:01:52.768Z",
        updatedAt: "2024-06-08T01:01:52.768Z",
      },
      {
        id: "clx5et56d01in12vcuuzm5w3u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mtiLijUGdx",
            locked: 1717808467,
            timestamp: 1717808466,
          },
        ],
        createdAt: "2024-06-08T01:01:08.340Z",
        updatedAt: "2024-06-08T01:01:08.340Z",
      },
      {
        id: "clx5espaq01im12vc4epbix20",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "MfuFYkR9Xf",
            locked: 1717808447,
            timestamp: 1717808446,
          },
        ],
        createdAt: "2024-06-08T01:00:47.759Z",
        updatedAt: "2024-06-08T01:00:47.759Z",
      },
      {
        id: "clx5esems01il12vcloqk1mxv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "8x0tVJo2du",
            locked: 1717808433,
            timestamp: 1717808432,
          },
        ],
        createdAt: "2024-06-08T01:00:33.940Z",
        updatedAt: "2024-06-08T01:00:33.940Z",
      },
      {
        id: "clx5esbd201ik12vcgs97m9ru",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "LlRXG6Rz8h",
            locked: 1717808429,
            timestamp: 1717808428,
          },
        ],
        createdAt: "2024-06-08T01:00:29.703Z",
        updatedAt: "2024-06-08T01:00:29.703Z",
      },
      {
        id: "clx5es7cy01ij12vc122oje9c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6PheEDmpPd",
            locked: 1717808424,
            timestamp: 1717808423,
          },
        ],
        createdAt: "2024-06-08T01:00:24.513Z",
        updatedAt: "2024-06-08T01:00:24.513Z",
      },
      {
        id: "clx5es5pa01ii12vcwxp80bn6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fgTa4SN9Iv",
            locked: 1717808421,
            timestamp: 1717808418,
          },
        ],
        createdAt: "2024-06-08T01:00:22.366Z",
        updatedAt: "2024-06-08T01:00:22.366Z",
      },
      {
        id: "clx5eruh101ih12vcxajm5ppo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "l35qknZ7tC",
            locked: 1717808407,
            timestamp: 1717808405,
          },
        ],
        createdAt: "2024-06-08T01:00:07.811Z",
        updatedAt: "2024-06-08T01:00:07.811Z",
      },
      {
        id: "clx5er9xp01ig12vcsnsq6f9h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "XRp8lcxtuZ",
            locked: 1717808380,
            timestamp: 1717808379,
          },
        ],
        createdAt: "2024-06-08T00:59:41.194Z",
        updatedAt: "2024-06-08T00:59:41.194Z",
      },
      {
        id: "clx5er80d01if12vcuizqla5e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pZ5Yh7Oo0j",
            locked: 1717808377,
            timestamp: 1717808376,
          },
        ],
        createdAt: "2024-06-08T00:59:38.702Z",
        updatedAt: "2024-06-08T00:59:38.702Z",
      },
      {
        id: "clx5eqxe201ie12vch75slhin",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2QVpt9RvVA",
            locked: 1717808364,
            timestamp: 1717808362,
          },
        ],
        createdAt: "2024-06-08T00:59:24.935Z",
        updatedAt: "2024-06-08T00:59:24.935Z",
      },
      {
        id: "clx5epuue01id12vca58mcd7c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "YiCzuMx5bk",
            locked: 1717808314,
            timestamp: 1717808312,
          },
        ],
        createdAt: "2024-06-08T00:58:34.982Z",
        updatedAt: "2024-06-08T00:58:34.982Z",
      },
      {
        id: "clx5epto801ic12vc0j9i651n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S916U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2131; samsung; SM-S916U; dm2q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "f1tA23JAPr",
            locked: 1717808313,
            timestamp: 1717808312,
          },
        ],
        createdAt: "2024-06-08T00:58:33.464Z",
        updatedAt: "2024-06-08T00:58:33.464Z",
      },
      {
        id: "clx5ept9i01ib12vckgg957q0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "36iqFE6vPX",
            locked: 1717808312,
            timestamp: 1717808310,
          },
        ],
        createdAt: "2024-06-08T00:58:32.934Z",
        updatedAt: "2024-06-08T00:58:32.934Z",
      },
      {
        id: "clx5eps1e01ia12vc5xa3xfg9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UdGJqMx1iH",
            locked: 1717808310,
            timestamp: 1717808309,
          },
        ],
        createdAt: "2024-06-08T00:58:31.346Z",
        updatedAt: "2024-06-08T00:58:31.346Z",
      },
      {
        id: "clx5eprm701i912vcsi1q8czk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S916U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2131; samsung; SM-S916U; dm2q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "v8PStMbauK",
            locked: 1717808310,
            timestamp: 1717808306,
          },
        ],
        createdAt: "2024-06-08T00:58:30.797Z",
        updatedAt: "2024-06-08T00:58:30.797Z",
      },
      {
        id: "clx5emq6r01i812vciy04xgno",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S711U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2125; samsung; SM-S711U; r11q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7aWkKREtVJ",
            locked: 1717808168,
            timestamp: 1717808166,
          },
        ],
        createdAt: "2024-06-08T00:56:08.977Z",
        updatedAt: "2024-06-08T00:56:08.977Z",
      },
      {
        id: "clx5emfmh01i712vcuueewe8g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "X6Sdv1jf64",
            locked: 1717808154,
            timestamp: 1717808153,
          },
        ],
        createdAt: "2024-06-08T00:55:55.289Z",
        updatedAt: "2024-06-08T00:55:55.289Z",
      },
      {
        id: "clx5em5wc01i612vcyajjugyk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "psq0sSDYzN",
            locked: 1717808141,
            timestamp: 1717808138,
          },
        ],
        createdAt: "2024-06-08T00:55:42.681Z",
        updatedAt: "2024-06-08T00:55:42.681Z",
      },
      {
        id: "clx5ek4qm01i512vcghl6ffog",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "a4zbFgEfGS",
            locked: 1717808047,
            timestamp: 1717808046,
          },
        ],
        createdAt: "2024-06-08T00:54:07.870Z",
        updatedAt: "2024-06-08T00:54:07.870Z",
      },
      {
        id: "clx5ejyta01i412vcclbi9kd7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ifDaFkftS2",
            locked: 1717808039,
            timestamp: 1717808038,
          },
        ],
        createdAt: "2024-06-08T00:54:00.187Z",
        updatedAt: "2024-06-08T00:54:00.187Z",
      },
      {
        id: "clx5ejpn101i312vcq4dtyccr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mx7VmZd7Rf",
            locked: 1717808027,
            timestamp: 1717808026,
          },
        ],
        createdAt: "2024-06-08T00:53:48.301Z",
        updatedAt: "2024-06-08T00:53:48.301Z",
      },
      {
        id: "clx5ejk0q01i212vc8xt8pw1x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AZQw2kCgWY",
            locked: 1717808019,
            timestamp: 1717808011,
          },
        ],
        createdAt: "2024-06-08T00:53:41.016Z",
        updatedAt: "2024-06-08T00:53:41.016Z",
      },
      {
        id: "clx5ejbdw01i112vcrocs83hu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 334.0.4.32.98 (iPhone14,5; iOS 16_1_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "NKkQ0Lbbjo",
            locked: 1717808009,
            timestamp: 1717808008,
          },
        ],
        createdAt: "2024-06-08T00:53:29.828Z",
        updatedAt: "2024-06-08T00:53:29.828Z",
      },
      {
        id: "clx5ej62801i012vcx1s3ikuc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 334.0.4.32.98 (iPhone14,5; iOS 16_1_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FUPKdCtyw7",
            locked: 1717808002,
            timestamp: 1717808000,
          },
        ],
        createdAt: "2024-06-08T00:53:22.929Z",
        updatedAt: "2024-06-08T00:53:22.929Z",
      },
      {
        id: "clx5ej5s401hz12vc8xyy88ny",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QMvLypUJlN",
            locked: 1717808002,
            timestamp: 1717808000,
          },
        ],
        createdAt: "2024-06-08T00:53:22.562Z",
        updatedAt: "2024-06-08T00:53:22.562Z",
      },
      {
        id: "clx5ei2oo01hy12vc4qzha180",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_GB; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lf0qIXqEsp",
            locked: 1717807951,
            timestamp: 1717807950,
          },
        ],
        createdAt: "2024-06-08T00:52:31.894Z",
        updatedAt: "2024-06-08T00:52:31.894Z",
      },
      {
        id: "clx5ehp9b01hx12vc55l12d9r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mSFfKJOT7H",
            locked: 1717807934,
            timestamp: 1717807932,
          },
        ],
        createdAt: "2024-06-08T00:52:14.493Z",
        updatedAt: "2024-06-08T00:52:14.493Z",
      },
      {
        id: "clx5egg1g01hw12vc323g7933",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Y0wOxzJelB",
            locked: 1717807875,
            timestamp: 1717807873,
          },
        ],
        createdAt: "2024-06-08T00:51:15.890Z",
        updatedAt: "2024-06-08T00:51:15.890Z",
      },
      {
        id: "clx5eez3801hv12vcu9pjxzcy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "6Fzezp0Q7F",
            locked: 1717807806,
            timestamp: 1717807804,
          },
        ],
        createdAt: "2024-06-08T00:50:07.267Z",
        updatedAt: "2024-06-08T00:50:07.267Z",
      },
      {
        id: "clx5eeuxe01hu12vc87xoqold",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; EC211001 Build/RP1A.200720.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (30/11; 280dpi; 720x1502; FIH/Cricket; EC211001; EC211001; mt6833; en_US; 606558405)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UXSlQi6HZX",
            locked: 1717807800,
            timestamp: 1717807792,
          },
        ],
        createdAt: "2024-06-08T00:50:01.875Z",
        updatedAt: "2024-06-08T00:50:01.875Z",
      },
      {
        id: "clx5eenhk01ht12vc88d3j2ha",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "wMYt85Rmxo",
            locked: 1717807792,
            timestamp: 1717807791,
          },
        ],
        createdAt: "2024-06-08T00:49:52.233Z",
        updatedAt: "2024-06-08T00:49:52.233Z",
      },
      {
        id: "clx5eemy801hs12vc08ggzzdx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NsGS3zi14C",
            locked: 1717807791,
            timestamp: 1717807789,
          },
        ],
        createdAt: "2024-06-08T00:49:51.536Z",
        updatedAt: "2024-06-08T00:49:51.536Z",
      },
      {
        id: "clx5eelh601hr12vcblnhzfp8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G781V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2186; samsung; SM-G781V; r8q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "9YnP9bqDF7",
            locked: 1717807789,
            timestamp: 1717807788,
          },
        ],
        createdAt: "2024-06-08T00:49:49.624Z",
        updatedAt: "2024-06-08T00:49:49.624Z",
      },
      {
        id: "clx5ee79q01hq12vckpnawbba",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "odTlh2BtOi",
            locked: 1717807770,
            timestamp: 1717807769,
          },
        ],
        createdAt: "2024-06-08T00:49:31.215Z",
        updatedAt: "2024-06-08T00:49:31.215Z",
      },
      {
        id: "clx5ee79e01hp12vc9h09iu0x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZuvhXgQrma",
            locked: 1717807769,
            timestamp: 1717807767,
          },
        ],
        createdAt: "2024-06-08T00:49:31.199Z",
        updatedAt: "2024-06-08T00:49:31.199Z",
      },
      {
        id: "clx5edr0y01ho12vcd9llmqul",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aa5xlaU4er",
            locked: 1717807749,
            timestamp: 1717807747,
          },
        ],
        createdAt: "2024-06-08T00:49:10.163Z",
        updatedAt: "2024-06-08T00:49:10.163Z",
      },
      {
        id: "clx5edqu201hn12vc9v7unwn6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G986U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2183; samsung; SM-G986U; y2q; qcom; en_US; 606558400)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4I4OyM3Mg2",
            locked: 1717807748,
            timestamp: 1717807746,
          },
        ],
        createdAt: "2024-06-08T00:49:09.915Z",
        updatedAt: "2024-06-08T00:49:09.915Z",
      },
      {
        id: "clx5edopm01hm12vc59zw7ic6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G781V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2186; samsung; SM-G781V; r8q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7uTcX7ohz7",
            locked: 1717807746,
            timestamp: 1717807744,
          },
        ],
        createdAt: "2024-06-08T00:49:07.160Z",
        updatedAt: "2024-06-08T00:49:07.160Z",
      },
      {
        id: "clx5eda8k01hl12vcemv49yzr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FYeT8bvESR",
            locked: 1717807727,
            timestamp: 1717807710,
          },
        ],
        createdAt: "2024-06-08T00:48:48.405Z",
        updatedAt: "2024-06-08T00:48:48.405Z",
      },
      {
        id: "clx5ed3i201hk12vc2ze7oh2n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "cqAeFby1jK",
            locked: 1717807719,
            timestamp: 1717807718,
          },
        ],
        createdAt: "2024-06-08T00:48:39.674Z",
        updatedAt: "2024-06-08T00:48:39.674Z",
      },
      {
        id: "clx5ed0lk01hj12vcifj0oy5t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MbWzKbdTT0",
            locked: 1717807715,
            timestamp: 1717807714,
          },
        ],
        createdAt: "2024-06-08T00:48:35.909Z",
        updatedAt: "2024-06-08T00:48:35.909Z",
      },
      {
        id: "clx5ecouk01hi12vctvxb3l27",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4KMuHmKT6h",
            locked: 1717807700,
            timestamp: 1717807698,
          },
        ],
        createdAt: "2024-06-08T00:48:20.682Z",
        updatedAt: "2024-06-08T00:48:20.682Z",
      },
      {
        id: "clx5ec2vn01hh12vc0mpeonpc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F731U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2402; samsung; SM-F731U; b5q; qcom; en_US; 606558408)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "I0ogVVGHtm",
            locked: 1717807671,
            timestamp: 1717807669,
          },
        ],
        createdAt: "2024-06-08T00:47:52.209Z",
        updatedAt: "2024-06-08T00:47:52.209Z",
      },
      {
        id: "clx5eaygf01hg12vcbkauu1mu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "8KPVqtsd9U",
            locked: 1717807619,
            timestamp: 1717807618,
          },
        ],
        createdAt: "2024-06-08T00:46:59.821Z",
        updatedAt: "2024-06-08T00:46:59.821Z",
      },
      {
        id: "clx5eapj501hf12vccpviw52a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1 Ddg/17.5",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "9iHYkqTCYq",
            locked: 1717807608,
            timestamp: 1717807607,
          },
        ],
        createdAt: "2024-06-08T00:46:48.257Z",
        updatedAt: "2024-06-08T00:46:48.257Z",
      },
      {
        id: "clx5eahjv01he12vcwmwjvrsi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "R34JWoCSZs",
            locked: 1717807597,
            timestamp: 1717807596,
          },
        ],
        createdAt: "2024-06-08T00:46:37.912Z",
        updatedAt: "2024-06-08T00:46:37.912Z",
      },
      {
        id: "clx5ea3gl01hd12vc4odudo2b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1 Ddg/17.5",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wJTieKdKa4",
            locked: 1717807578,
            timestamp: 1717807574,
          },
        ],
        createdAt: "2024-06-08T00:46:19.650Z",
        updatedAt: "2024-06-08T00:46:19.650Z",
      },
      {
        id: "clx5e91jm01hc12vci9yrm8kp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7Q28Ux10Sk",
            locked: 1717807529,
            timestamp: 1717807520,
          },
        ],
        createdAt: "2024-06-08T00:45:30.515Z",
        updatedAt: "2024-06-08T00:45:30.515Z",
      },
      {
        id: "clx5e911f01hb12vcygnzvqja",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "y2h2HEMUAx",
            locked: 1717807529,
            timestamp: 1717807527,
          },
        ],
        createdAt: "2024-06-08T00:45:29.857Z",
        updatedAt: "2024-06-08T00:45:29.857Z",
      },
      {
        id: "clx5e88gv01ha12vc72dqczq0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qzrvARAKp5",
            locked: 1717807492,
            timestamp: 1717807490,
          },
        ],
        createdAt: "2024-06-08T00:44:52.832Z",
        updatedAt: "2024-06-08T00:44:52.832Z",
      },
      {
        id: "clx5e88bk01h912vc17urou9t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S911U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S911U; dm1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jPSRp4ayOE",
            locked: 1717807491,
            timestamp: 1717807490,
          },
        ],
        createdAt: "2024-06-08T00:44:52.637Z",
        updatedAt: "2024-06-08T00:44:52.637Z",
      },
      {
        id: "clx5e7nan01h812vcukv8wq3k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "rt7KCBJQRg",
            locked: 1717807465,
            timestamp: 1717807464,
          },
        ],
        createdAt: "2024-06-08T00:44:25.392Z",
        updatedAt: "2024-06-08T00:44:25.392Z",
      },
      {
        id: "clx5e7lgu01h712vcplvh3uiv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AoSXbmEuh6",
            locked: 1717807462,
            timestamp: 1717807461,
          },
        ],
        createdAt: "2024-06-08T00:44:23.022Z",
        updatedAt: "2024-06-08T00:44:23.022Z",
      },
      {
        id: "clx5e7i5101h612vc9y2oo79w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1 Ddg/17.5",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1P6Eo8UFAW",
            locked: 1717807453,
            timestamp: 1717807448,
          },
        ],
        createdAt: "2024-06-08T00:44:18.706Z",
        updatedAt: "2024-06-08T00:44:18.706Z",
      },
      {
        id: "clx5e6udu01h512vc6gsix5od",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "qkdu9lKAc8",
            locked: 1717807427,
            timestamp: 1717807426,
          },
        ],
        createdAt: "2024-06-08T00:43:47.921Z",
        updatedAt: "2024-06-08T00:43:47.921Z",
      },
      {
        id: "clx5e6s1s01h412vc22crdy25",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1624; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "E5FTvSLsbk",
            locked: 1717807424,
            timestamp: 1717807422,
          },
        ],
        createdAt: "2024-06-08T00:43:44.896Z",
        updatedAt: "2024-06-08T00:43:44.896Z",
      },
      {
        id: "clx5e6p1l01h312vcwz2a1vmm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GYkKtzfH3p",
            locked: 1717807420,
            timestamp: 1717807418,
          },
        ],
        createdAt: "2024-06-08T00:43:41.001Z",
        updatedAt: "2024-06-08T00:43:41.001Z",
      },
      {
        id: "clx5e6ihk01h212vc4uyk98so",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Jy7PvexcUO",
            locked: 1717807412,
            timestamp: 1717807411,
          },
        ],
        createdAt: "2024-06-08T00:43:32.505Z",
        updatedAt: "2024-06-08T00:43:32.505Z",
      },
      {
        id: "clx5e6h1501h112vc98sp10dj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rSqyLt0KDp",
            locked: 1717807410,
            timestamp: 1717807408,
          },
        ],
        createdAt: "2024-06-08T00:43:30.615Z",
        updatedAt: "2024-06-08T00:43:30.615Z",
      },
      {
        id: "clx5e5tjq01h012vckr3ax955",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "VFkE0y8tS8",
            locked: 1717807379,
            timestamp: 1717807378,
          },
        ],
        createdAt: "2024-06-08T00:43:00.182Z",
        updatedAt: "2024-06-08T00:43:00.182Z",
      },
      {
        id: "clx5e5s4d01gz12vcfuo3rfwx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cfy8Um6kzw",
            locked: 1717807377,
            timestamp: 1717807376,
          },
        ],
        createdAt: "2024-06-08T00:42:58.330Z",
        updatedAt: "2024-06-08T00:42:58.330Z",
      },
      {
        id: "clx5e5i0h01gy12vcogyz4h6x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "z1khp4hIgX",
            locked: 1717807363,
            timestamp: 1717807361,
          },
        ],
        createdAt: "2024-06-08T00:42:45.233Z",
        updatedAt: "2024-06-08T00:42:45.233Z",
      },
      {
        id: "clx5e5gar01gx12vcy84arfn6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "CQWjda9Ypn",
            locked: 1717807362,
            timestamp: 1717807361,
          },
        ],
        createdAt: "2024-06-08T00:42:43.010Z",
        updatedAt: "2024-06-08T00:42:43.010Z",
      },
      {
        id: "clx5e5cem01gw12vcq61y3mj6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JcFcyCdEwQ",
            locked: 1717807357,
            timestamp: 1717807355,
          },
        ],
        createdAt: "2024-06-08T00:42:37.966Z",
        updatedAt: "2024-06-08T00:42:37.966Z",
      },
      {
        id: "clx5e5abz01gv12vcyh9euf06",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 325.0.3.34.90 (iPhone14,7; iOS 17_4_1; en_AU; en; scale=3.00; 1170x2532; 584303227)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ntBBdWQoy4",
            locked: 1717807355,
            timestamp: 1717807354,
          },
        ],
        createdAt: "2024-06-08T00:42:35.279Z",
        updatedAt: "2024-06-08T00:42:35.279Z",
      },
      {
        id: "clx5e55px01gu12vc0zdybz4m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 325.0.3.34.90 (iPhone14,7; iOS 17_4_1; en_AU; en; scale=3.00; 1170x2532; 584303227)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5U269hXjho",
            locked: 1717807349,
            timestamp: 1717807347,
          },
        ],
        createdAt: "2024-06-08T00:42:29.301Z",
        updatedAt: "2024-06-08T00:42:29.301Z",
      },
      {
        id: "clx5e516701gt12vcm55pkfwz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "P4MvqfQ0e7",
            locked: 1717807342,
            timestamp: 1717807340,
          },
        ],
        createdAt: "2024-06-08T00:42:23.405Z",
        updatedAt: "2024-06-08T00:42:23.405Z",
      },
      {
        id: "clx5e4qwa01gs12vcyazk147m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 325.0.3.34.90 (iPhone14,7; iOS 17_4_1; en_AU; en; scale=3.00; 1170x2532; 584303227)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NqiNpHuKKe",
            locked: 1717807328,
            timestamp: 1717807327,
          },
        ],
        createdAt: "2024-06-08T00:42:10.090Z",
        updatedAt: "2024-06-08T00:42:10.090Z",
      },
      {
        id: "clx5e4fqy01gr12vcns3cw45h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "j3ecbIMstJ",
            locked: 1717807315,
            timestamp: 1717807313,
          },
        ],
        createdAt: "2024-06-08T00:41:55.640Z",
        updatedAt: "2024-06-08T00:41:55.640Z",
      },
      {
        id: "clx5e476701gq12vcvp5ag6p1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G996U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.83 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2190; samsung; SM-G996U; t2q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NM42wuNdhz",
            locked: 1717807304,
            timestamp: 1717807301,
          },
        ],
        createdAt: "2024-06-08T00:41:44.528Z",
        updatedAt: "2024-06-08T00:41:44.528Z",
      },
      {
        id: "clx5e45qk01gp12vcpp1n5rxg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 330.0.3.29.91 (iPhone13,2; iOS 15_6_1; en_US; en; scale=3.00; 1170x2532; 595323416)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OY9o0JwpcY",
            locked: 1717807302,
            timestamp: 1717807283,
          },
        ],
        createdAt: "2024-06-08T00:41:42.668Z",
        updatedAt: "2024-06-08T00:41:42.668Z",
      },
      {
        id: "clx5e3xeh01go12vcg0uw2ar4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "aJnBPfvbbx",
            locked: 1717807291,
            timestamp: 1717807290,
          },
        ],
        createdAt: "2024-06-08T00:41:31.864Z",
        updatedAt: "2024-06-08T00:41:31.864Z",
      },
      {
        id: "clx5e3pss01gn12vcfh7f36rq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Q18nUOo73n",
            locked: 1717807281,
            timestamp: 1717807279,
          },
        ],
        createdAt: "2024-06-08T00:41:22.012Z",
        updatedAt: "2024-06-08T00:41:22.012Z",
      },
      {
        id: "clx5e3hhm01gm12vc7igwpofp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.1.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "bEhqi5QDr8",
            locked: 1717803678,
            timestamp: 1717803677,
          },
        ],
        createdAt: "2024-06-08T00:41:11.242Z",
        updatedAt: "2024-06-08T00:41:11.242Z",
      },
      {
        id: "clx5e3ed501gl12vcpeoxyvkg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.1.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MX3tnHG35q",
            locked: 1717803672,
            timestamp: 1717803671,
          },
        ],
        createdAt: "2024-06-08T00:41:07.191Z",
        updatedAt: "2024-06-08T00:41:07.191Z",
      },
      {
        id: "clx5e2t2o01gk12vcknn261te",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_3_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dSEgxfqMKc",
            locked: 1717807238,
            timestamp: 1717807237,
          },
        ],
        createdAt: "2024-06-08T00:40:39.598Z",
        updatedAt: "2024-06-08T00:40:39.598Z",
      },
      {
        id: "clx5e1j9q01gj12vc8dl43m0q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "DlGgLPg53R",
            locked: 1717807182,
            timestamp: 1717807179,
          },
        ],
        createdAt: "2024-06-08T00:39:40.238Z",
        updatedAt: "2024-06-08T00:39:40.238Z",
      },
      {
        id: "clx5e1gxa01gi12vcggqat1d3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Xd5tqQRp2n",
            locked: 1717807176,
            timestamp: 1717807173,
          },
        ],
        createdAt: "2024-06-08T00:39:37.197Z",
        updatedAt: "2024-06-08T00:39:37.197Z",
      },
      {
        id: "clx5e1bcs01gh12vcrcdyddvk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MdhUyHgDPg",
            locked: 1717807171,
            timestamp: 1717807169,
          },
        ],
        createdAt: "2024-06-08T00:39:29.980Z",
        updatedAt: "2024-06-08T00:39:29.980Z",
      },
      {
        id: "clx5e152m01gg12vc9cphmyrm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Uardwt1xDs",
            locked: 1717807161,
            timestamp: 1717807159,
          },
        ],
        createdAt: "2024-06-08T00:39:21.838Z",
        updatedAt: "2024-06-08T00:39:21.838Z",
      },
      {
        id: "clx5e13iv01gf12vccnniqdrg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3ZqosWAVqi",
            locked: 1717807158,
            timestamp: 1717807157,
          },
        ],
        createdAt: "2024-06-08T00:39:19.828Z",
        updatedAt: "2024-06-08T00:39:19.828Z",
      },
      {
        id: "clx5e0oda01ge12vcfg3gyi72",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "XwX0qK3R2C",
            locked: 1717807138,
            timestamp: 1717807134,
          },
        ],
        createdAt: "2024-06-08T00:39:00.186Z",
        updatedAt: "2024-06-08T00:39:00.186Z",
      },
      {
        id: "clx5e0gav01gd12vcg9jzc5jk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tvHGNGJblm",
            locked: 1717807129,
            timestamp: 1717807128,
          },
        ],
        createdAt: "2024-06-08T00:38:49.736Z",
        updatedAt: "2024-06-08T00:38:49.736Z",
      },
      {
        id: "clx5e087l01gc12vcll6qkii4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VMZQN2ty54",
            locked: 1717807118,
            timestamp: 1717807116,
          },
        ],
        createdAt: "2024-06-08T00:38:39.246Z",
        updatedAt: "2024-06-08T00:38:39.246Z",
      },
      {
        id: "clx5dznxe01gb12vc06e86bz8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yMJiEAJnf3",
            locked: 1717807091,
            timestamp: 1717807089,
          },
        ],
        createdAt: "2024-06-08T00:38:12.963Z",
        updatedAt: "2024-06-08T00:38:12.963Z",
      },
      {
        id: "clx5dzkd501ga12vcjbunskcl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CGhUSdCzVa",
            locked: 1717807087,
            timestamp: 1717807086,
          },
        ],
        createdAt: "2024-06-08T00:38:08.344Z",
        updatedAt: "2024-06-08T00:38:08.344Z",
      },
      {
        id: "clx5dzejd01g912vcu5esx2ka",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ymqfP45Nwm",
            locked: 1717807080,
            timestamp: 1717807079,
          },
        ],
        createdAt: "2024-06-08T00:38:00.793Z",
        updatedAt: "2024-06-08T00:38:00.793Z",
      },
      {
        id: "clx5dz6za01g812vc2qkz4u48",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QtmxOUoImt",
            locked: 1717807070,
            timestamp: 1717807069,
          },
        ],
        createdAt: "2024-06-08T00:37:50.991Z",
        updatedAt: "2024-06-08T00:37:50.991Z",
      },
      {
        id: "clx5dytrt01g712vcr6zy5zaq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; moto g power (2021) Build/RZBS31.Q2-143-27-6-16; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (30/11; 280dpi; 720x1488; motorola; moto g power (2021); borneo; qcom; en_US; 606558548)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rH3MsXXB5d",
            locked: 1717807052,
            timestamp: 1717807044,
          },
        ],
        createdAt: "2024-06-08T00:37:33.882Z",
        updatedAt: "2024-06-08T00:37:33.882Z",
      },
      {
        id: "clx5dyt9v01g612vcqwb7tciq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G996U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2190; samsung; SM-G996U; t2q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "24aDeq5Zrf",
            locked: 1717807053,
            timestamp: 1717807052,
          },
        ],
        createdAt: "2024-06-08T00:37:33.236Z",
        updatedAt: "2024-06-08T00:37:33.236Z",
      },
      {
        id: "clx5dyqwd01g512vchzrk5da2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G996U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2190; samsung; SM-G996U; t2q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5UCqATurRW",
            locked: 1717807049,
            timestamp: 1717807045,
          },
        ],
        createdAt: "2024-06-08T00:37:30.157Z",
        updatedAt: "2024-06-08T00:37:30.157Z",
      },
      {
        id: "clx5dyi7w01g412vchoaywmo2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "XrkWfMiIMk",
            locked: 1717807038,
            timestamp: 1717807037,
          },
        ],
        createdAt: "2024-06-08T00:37:18.905Z",
        updatedAt: "2024-06-08T00:37:18.905Z",
      },
      {
        id: "clx5dy76s01g312vctiohldhp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "PYWk34mMAK",
            locked: 1717807023,
            timestamp: 1717807022,
          },
        ],
        createdAt: "2024-06-08T00:37:04.612Z",
        updatedAt: "2024-06-08T00:37:04.612Z",
      },
      {
        id: "clx5dy2v101g212vcre0qi5p1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "on1tHWCF3w",
            locked: 1717807018,
            timestamp: 1717807016,
          },
        ],
        createdAt: "2024-06-08T00:36:59.006Z",
        updatedAt: "2024-06-08T00:36:59.006Z",
      },
      {
        id: "clx5dy2i801g112vczixziddp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5uk2qJNH04",
            locked: 1717807017,
            timestamp: 1717807015,
          },
        ],
        createdAt: "2024-06-08T00:36:58.545Z",
        updatedAt: "2024-06-08T00:36:58.545Z",
      },
      {
        id: "clx5dy20g01g012vc76lwyhbj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "7tnr9uK6ET",
            locked: 1717807017,
            timestamp: 1717807015,
          },
        ],
        createdAt: "2024-06-08T00:36:57.903Z",
        updatedAt: "2024-06-08T00:36:57.903Z",
      },
      {
        id: "clx5dxyhg01fz12vcwe0h3dnp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/92.0.4515.107 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FVts3QNzO6",
            locked: 1717807012,
            timestamp: 1717807010,
          },
        ],
        createdAt: "2024-06-08T00:36:53.333Z",
        updatedAt: "2024-06-08T00:36:53.333Z",
      },
      {
        id: "clx5dxy0s01fy12vcsvxwq1yh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "frqqA7UAn5",
            locked: 1717807012,
            timestamp: 1717807010,
          },
        ],
        createdAt: "2024-06-08T00:36:52.733Z",
        updatedAt: "2024-06-08T00:36:52.733Z",
      },
      {
        id: "clx5dxwnz01fx12vc0thsppbb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LZSYD5n9VB",
            locked: 1717807010,
            timestamp: 1717807009,
          },
        ],
        createdAt: "2024-06-08T00:36:50.976Z",
        updatedAt: "2024-06-08T00:36:50.976Z",
      },
      {
        id: "clx5dxn2l01fw12vc7qqyeffn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G781U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2168; samsung; SM-G781U; r8q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ULdmuGG573",
            locked: 1717806997,
            timestamp: 1717806996,
          },
        ],
        createdAt: "2024-06-08T00:36:38.539Z",
        updatedAt: "2024-06-08T00:36:38.539Z",
      },
      {
        id: "clx5dxeml01fv12vcqg99jgfq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Android 10; Mobile; rv:126.0) Gecko/126.0 Firefox/126.0",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HPMspQXfxw",
            locked: 1717806987,
            timestamp: 1717806984,
          },
        ],
        createdAt: "2024-06-08T00:36:27.598Z",
        updatedAt: "2024-06-08T00:36:27.598Z",
      },
      {
        id: "clx5dxamg01fu12vcdprn08nt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G781U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2168; samsung; SM-G781U; r8q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3CUoStBp0c",
            locked: 1717806981,
            timestamp: 1717806978,
          },
        ],
        createdAt: "2024-06-08T00:36:22.406Z",
        updatedAt: "2024-06-08T00:36:22.406Z",
      },
      {
        id: "clx5dvqk801ft12vck6vsk2tx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9e0iFj6vxZ",
            locked: 1717806908,
            timestamp: 1717806906,
          },
        ],
        createdAt: "2024-06-08T00:35:09.750Z",
        updatedAt: "2024-06-08T00:35:09.750Z",
      },
      {
        id: "clx5dvags01fs12vctkpuqd4g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "BL03LNrYFk",
            locked: 1717806888,
            timestamp: 1717806886,
          },
        ],
        createdAt: "2024-06-08T00:34:48.889Z",
        updatedAt: "2024-06-08T00:34:48.889Z",
      },
      {
        id: "clx5dty8a01fr12vccm77mog8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "pWS4RjXL2N",
            locked: 1717806825,
            timestamp: 1717806820,
          },
        ],
        createdAt: "2024-06-08T00:33:46.376Z",
        updatedAt: "2024-06-08T00:33:46.376Z",
      },
      {
        id: "clx5dt3sf01fq12vc9ea91hhy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "suYoRO4dNE",
            locked: 1717806786,
            timestamp: 1717806783,
          },
        ],
        createdAt: "2024-06-08T00:33:06.925Z",
        updatedAt: "2024-06-08T00:33:06.925Z",
      },
      {
        id: "clx5dsb5201fp12vcju4my44t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "m3U4DIgPBH",
            locked: 1717806749,
            timestamp: 1717806748,
          },
        ],
        createdAt: "2024-06-08T00:32:29.796Z",
        updatedAt: "2024-06-08T00:32:29.796Z",
      },
      {
        id: "clx5drz9o01fo12vcv5xbthij",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qUkPykakvs",
            locked: 1717806733,
            timestamp: 1717806732,
          },
        ],
        createdAt: "2024-06-08T00:32:14.409Z",
        updatedAt: "2024-06-08T00:32:14.409Z",
      },
      {
        id: "clx5dqhni01fn12vcr0lo4v3d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "C8aInJsHxy",
            locked: 1717806664,
            timestamp: 1717806663,
          },
        ],
        createdAt: "2024-06-08T00:31:04.926Z",
        updatedAt: "2024-06-08T00:31:04.926Z",
      },
      {
        id: "clx5dqgbc01fm12vckfswp74n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Y3Rxvu2DW0",
            locked: 1717806662,
            timestamp: 1717806661,
          },
        ],
        createdAt: "2024-06-08T00:31:03.190Z",
        updatedAt: "2024-06-08T00:31:03.190Z",
      },
      {
        id: "clx5dps8t01fl12vc1vs5ml0g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S916U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2131; samsung; SM-S916U; dm2q; qcom; en_US; 604247775)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "otnpO3TQCr",
            locked: 1717806631,
            timestamp: 1717806628,
          },
        ],
        createdAt: "2024-06-08T00:30:31.995Z",
        updatedAt: "2024-06-08T00:30:31.995Z",
      },
      {
        id: "clx5dpa8l01fk12vcftx3rq8p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "JMOUiRThyX",
            locked: 1717806608,
            timestamp: 1717806607,
          },
        ],
        createdAt: "2024-06-08T00:30:08.661Z",
        updatedAt: "2024-06-08T00:30:08.661Z",
      },
      {
        id: "clx5dp6ak01fj12vc3edshmmg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "25OGnA7xv2",
            locked: 1717806602,
            timestamp: 1717806600,
          },
        ],
        createdAt: "2024-06-08T00:30:03.548Z",
        updatedAt: "2024-06-08T00:30:03.548Z",
      },
      {
        id: "clx5dp2v501fi12vcujwsk426",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "cG4GwLkv8K",
            locked: 1717806598,
            timestamp: 1717806596,
          },
        ],
        createdAt: "2024-06-08T00:29:59.103Z",
        updatedAt: "2024-06-08T00:29:59.103Z",
      },
      {
        id: "clx5doho901fh12vc3qjtc59p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "i3RjMVgw9y",
            locked: 1717806571,
            timestamp: 1717806569,
          },
        ],
        createdAt: "2024-06-08T00:29:31.641Z",
        updatedAt: "2024-06-08T00:29:31.641Z",
      },
      {
        id: "clx5doa7p01fg12vcvy99tfm1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CcnkHBP0CC",
            locked: 1717806561,
            timestamp: 1717806560,
          },
        ],
        createdAt: "2024-06-08T00:29:21.971Z",
        updatedAt: "2024-06-08T00:29:21.971Z",
      },
      {
        id: "clx5dnx9p01ff12vcjwb57lk0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Mr7LgbmzPM",
            locked: 1717806545,
            timestamp: 1717806544,
          },
        ],
        createdAt: "2024-06-08T00:29:05.198Z",
        updatedAt: "2024-06-08T00:29:05.198Z",
      },
      {
        id: "clx5dnqk101fe12vcwfv4ctww",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hJai9YO5Db",
            locked: 1717806536,
            timestamp: 1717806534,
          },
        ],
        createdAt: "2024-06-08T00:28:56.494Z",
        updatedAt: "2024-06-08T00:28:56.494Z",
      },
      {
        id: "clx5dn13z01fd12vc9fdiicd2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "rf1ufkoXDq",
            locked: 1717806503,
            timestamp: 1717806502,
          },
        ],
        createdAt: "2024-06-08T00:28:23.520Z",
        updatedAt: "2024-06-08T00:28:23.520Z",
      },
      {
        id: "clx5dmydn01fc12vcljsoh1j8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cyCzKFY3VY",
            locked: 1717806499,
            timestamp: 1717806497,
          },
        ],
        createdAt: "2024-06-08T00:28:19.976Z",
        updatedAt: "2024-06-08T00:28:19.976Z",
      },
      {
        id: "clx5dmoek01fb12vcni6qhucb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F731U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2411; samsung; SM-F731U; b5q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CGH7HtV7es",
            locked: 1717806486,
            timestamp: 1717806484,
          },
        ],
        createdAt: "2024-06-08T00:28:07.053Z",
        updatedAt: "2024-06-08T00:28:07.053Z",
      },
      {
        id: "clx5dmo3q01fa12vca434489r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NCYQRB2VC0",
            locked: 1717806486,
            timestamp: 1717806484,
          },
        ],
        createdAt: "2024-06-08T00:28:06.662Z",
        updatedAt: "2024-06-08T00:28:06.662Z",
      },
      {
        id: "clx5dml7201f912vcv6vnepv2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Sff7HCXxab",
            locked: 1717806482,
            timestamp: 1717806479,
          },
        ],
        createdAt: "2024-06-08T00:28:02.891Z",
        updatedAt: "2024-06-08T00:28:02.891Z",
      },
      {
        id: "clx5dm69401f812vcri0w7wkw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pNheWRAwR5",
            locked: 1717806463,
            timestamp: 1717806460,
          },
        ],
        createdAt: "2024-06-08T00:27:43.528Z",
        updatedAt: "2024-06-08T00:27:43.528Z",
      },
      {
        id: "clx5dlw5w01f712vcqs5rdlg3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.147 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5TsIM0lVZE",
            locked: 1717806449,
            timestamp: 1717806447,
          },
        ],
        createdAt: "2024-06-08T00:27:30.449Z",
        updatedAt: "2024-06-08T00:27:30.449Z",
      },
      {
        id: "clx5dkl6t01f612vc2hscjl59",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 600349675) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "sQUAvyZOLo",
            locked: 1717806389,
            timestamp: 1717806387,
          },
        ],
        createdAt: "2024-06-08T00:26:29.573Z",
        updatedAt: "2024-06-08T00:26:29.573Z",
      },
      {
        id: "clx5dkfsl01f512vc1qjhetzm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9xG0rpFJLR",
            locked: 1717806381,
            timestamp: 1717806375,
          },
        ],
        createdAt: "2024-06-08T00:26:22.578Z",
        updatedAt: "2024-06-08T00:26:22.578Z",
      },
      {
        id: "clx5dhqwe01f412vcy1a289wk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_7_9 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H365 Instagram 334.0.4.32.98 (iPhone9,4; iOS 15_7_9; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tr95LQaZRf",
            locked: 1717806256,
            timestamp: 1717806252,
          },
        ],
        createdAt: "2024-06-08T00:24:17.006Z",
        updatedAt: "2024-06-08T00:24:17.006Z",
      },
      {
        id: "clx5dhhxx01f312vckjgca7va",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G996U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2181; samsung; SM-G996U; t2q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "0pAYH4H2l7",
            locked: 1717806245,
            timestamp: 1717806244,
          },
        ],
        createdAt: "2024-06-08T00:24:05.397Z",
        updatedAt: "2024-06-08T00:24:05.397Z",
      },
      {
        id: "clx5dhfze01f212vceb2k6q5o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G996U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2181; samsung; SM-G996U; t2q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3p2UcQpY9F",
            locked: 1717806242,
            timestamp: 1717806240,
          },
        ],
        createdAt: "2024-06-08T00:24:02.856Z",
        updatedAt: "2024-06-08T00:24:02.856Z",
      },
      {
        id: "clx5dh1uo01f112vc6ifklfk1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "itYU9cbTzZ",
            locked: 1717806224,
            timestamp: 1717806222,
          },
        ],
        createdAt: "2024-06-08T00:23:44.544Z",
        updatedAt: "2024-06-08T00:23:44.544Z",
      },
      {
        id: "clx5dh16g01f012vczljrutwu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,3; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tGw4ttdp8d",
            locked: 1717806223,
            timestamp: 1717806221,
          },
        ],
        createdAt: "2024-06-08T00:23:43.670Z",
        updatedAt: "2024-06-08T00:23:43.670Z",
      },
      {
        id: "clx5dg92001ez12vcj0yrnf1u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 334.0.4.32.98 (iPad5,4; iPadOS 15_8_2; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Whr24Au7Sb",
            locked: 1717806186,
            timestamp: 1717806183,
          },
        ],
        createdAt: "2024-06-08T00:23:07.221Z",
        updatedAt: "2024-06-08T00:23:07.221Z",
      },
      {
        id: "clx5dfs3h01ey12vcjrmaw2kv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kYk8t3eFZ1",
            locked: 1717806164,
            timestamp: 1717806148,
          },
        ],
        createdAt: "2024-06-08T00:22:45.242Z",
        updatedAt: "2024-06-08T00:22:45.242Z",
      },
      {
        id: "clx5dfeox01ex12vcl6nsnt6p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; GN2200 Build/RKQ1.211119.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.166 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 480dpi; 1080x2150; OnePlus; GN2200; OP515AL1; qcom; en_US; 606558499)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MoHsc19UU1",
            locked: 1717806147,
            timestamp: 1717806143,
          },
        ],
        createdAt: "2024-06-08T00:22:27.874Z",
        updatedAt: "2024-06-08T00:22:27.874Z",
      },
      {
        id: "clx5dfdom01ew12vc5blnb3vj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 560dpi; 1440x2820; samsung; SM-S918U; dm3q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "o07zhZznLD",
            locked: 1717806145,
            timestamp: 1717806143,
          },
        ],
        createdAt: "2024-06-08T00:22:26.563Z",
        updatedAt: "2024-06-08T00:22:26.563Z",
      },
      {
        id: "clx5depb601ev12vcj43vn4ob",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "LCiGFAxQcF",
            locked: 1717806114,
            timestamp: 1717806113,
          },
        ],
        createdAt: "2024-06-08T00:21:54.978Z",
        updatedAt: "2024-06-08T00:21:54.978Z",
      },
      {
        id: "clx5dek4s01eu12vch6luy8kb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DuJ9oPxmne",
            locked: 1717806107,
            timestamp: 1717806106,
          },
        ],
        createdAt: "2024-06-08T00:21:48.269Z",
        updatedAt: "2024-06-08T00:21:48.269Z",
      },
      {
        id: "clx5degvl01et12vc9mzqgtml",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JnjGuDpbnZ",
            locked: 1717806103,
            timestamp: 1717806102,
          },
        ],
        createdAt: "2024-06-08T00:21:44.046Z",
        updatedAt: "2024-06-08T00:21:44.046Z",
      },
      {
        id: "clx5ddda301es12vcj7pil3bf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 330.0.3.29.91 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 595323416) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "p3Ps7zKB9G",
            locked: 1717806052,
            timestamp: 1717806049,
          },
        ],
        createdAt: "2024-06-08T00:20:52.729Z",
        updatedAt: "2024-06-08T00:20:52.729Z",
      },
      {
        id: "clx5dcnvn01er12vcsehtq93z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-G960U Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 480dpi; 1080x2076; samsung; SM-G960U; starqltesq; qcom; en_US; 606558499)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "M9MfCQMvUW",
            locked: 1717806019,
            timestamp: 1717806016,
          },
        ],
        createdAt: "2024-06-08T00:20:19.809Z",
        updatedAt: "2024-06-08T00:20:19.809Z",
      },
      {
        id: "clx5dbi2r01eq12vc6fp9ldmd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 334.0.4.32.98 (iPhone8,2; iOS 15_8_2; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XBqN3W9ne2",
            locked: 1717805964,
            timestamp: 1717805959,
          },
        ],
        createdAt: "2024-06-08T00:19:25.633Z",
        updatedAt: "2024-06-08T00:19:25.633Z",
      },
      {
        id: "clx5db0cr01ep12vc5g1ygbih",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A536U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2177; samsung; SM-A536U; a53x; s5e8825; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "U3suUKcuAt",
            locked: 1717805941,
            timestamp: 1717805939,
          },
        ],
        createdAt: "2024-06-08T00:19:02.665Z",
        updatedAt: "2024-06-08T00:19:02.665Z",
      },
      {
        id: "clx5dae0401eo12vco2z6dk1g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NZTr9xSuSa",
            locked: 1717805913,
            timestamp: 1717805911,
          },
        ],
        createdAt: "2024-06-08T00:18:33.698Z",
        updatedAt: "2024-06-08T00:18:33.698Z",
      },
      {
        id: "clx5da1l201en12vcwblmvql5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "w8MRWlMMgq",
            locked: 1717805897,
            timestamp: 1717805895,
          },
        ],
        createdAt: "2024-06-08T00:18:17.607Z",
        updatedAt: "2024-06-08T00:18:17.607Z",
      },
      {
        id: "clx5d9x3w01em12vcfnqrlz25",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wAfxhGSbmW",
            locked: 1717805891,
            timestamp: 1717805884,
          },
        ],
        createdAt: "2024-06-08T00:18:11.801Z",
        updatedAt: "2024-06-08T00:18:11.801Z",
      },
      {
        id: "clx5d9hrf01el12vc5tcgawst",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-G990U; r9q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ulYC6OLnTb",
            locked: 1717805871,
            timestamp: 1717805869,
          },
        ],
        createdAt: "2024-06-08T00:17:51.913Z",
        updatedAt: "2024-06-08T00:17:51.913Z",
      },
      {
        id: "clx5d937901ek12vc48smvr1v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "FzR07Iht7W",
            locked: 1717805852,
            timestamp: 1717805851,
          },
        ],
        createdAt: "2024-06-08T00:17:33.046Z",
        updatedAt: "2024-06-08T00:17:33.046Z",
      },
      {
        id: "clx5d8yke01ej12vcnekegrjx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zO38kaZiii",
            locked: 1717805846,
            timestamp: 1717805845,
          },
        ],
        createdAt: "2024-06-08T00:17:27.036Z",
        updatedAt: "2024-06-08T00:17:27.036Z",
      },
      {
        id: "clx5d8j4w01ei12vcuty4eop6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "NC9NQjgoAP",
            locked: 1717805826,
            timestamp: 1717805825,
          },
        ],
        createdAt: "2024-06-08T00:17:07.040Z",
        updatedAt: "2024-06-08T00:17:07.040Z",
      },
      {
        id: "clx5d8gvp01eh12vcwm1fhln2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "prApWfhxgd",
            locked: 1717805823,
            timestamp: 1717805821,
          },
        ],
        createdAt: "2024-06-08T00:17:04.116Z",
        updatedAt: "2024-06-08T00:17:04.116Z",
      },
      {
        id: "clx5d8a4101eg12vcx7up1ich",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ja44fDWjrL",
            locked: 1717805815,
            timestamp: 1717805814,
          },
        ],
        createdAt: "2024-06-08T00:16:55.346Z",
        updatedAt: "2024-06-08T00:16:55.346Z",
      },
      {
        id: "clx5d88t401ef12vc609m2566",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9hm1Hbu1Nz",
            locked: 1717805813,
            timestamp: 1717805812,
          },
        ],
        createdAt: "2024-06-08T00:16:53.656Z",
        updatedAt: "2024-06-08T00:16:53.656Z",
      },
      {
        id: "clx5d833q01ee12vce6pt292e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "cHeuAbh84V",
            locked: 1717805806,
            timestamp: 1717805805,
          },
        ],
        createdAt: "2024-06-08T00:16:46.259Z",
        updatedAt: "2024-06-08T00:16:46.259Z",
      },
      {
        id: "clx5d7mph01ed12vceogzqdo9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YmkwuoBI6o",
            locked: 1717805784,
            timestamp: 1717805783,
          },
        ],
        createdAt: "2024-06-08T00:16:25.014Z",
        updatedAt: "2024-06-08T00:16:25.014Z",
      },
      {
        id: "clx5d7kfu01ec12vcc6caujz8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PngcsglaOk",
            locked: 1717805781,
            timestamp: 1717805778,
          },
        ],
        createdAt: "2024-06-08T00:16:22.071Z",
        updatedAt: "2024-06-08T00:16:22.071Z",
      },
      {
        id: "clx5d6i0w01eb12vc9nfftnbu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xab5iI8Z7I",
            locked: 1717805728,
            timestamp: 1717805727,
          },
        ],
        createdAt: "2024-06-08T00:15:32.287Z",
        updatedAt: "2024-06-08T00:15:32.287Z",
      },
      {
        id: "clx5d6bgp01ea12vctdvhf994",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OIbUZzctNf",
            locked: 1717805722,
            timestamp: 1717805715,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "LJiV9iOYGv",
            locked: 1717805722,
            timestamp: 1717805718,
          },
        ],
        createdAt: "2024-06-08T00:15:23.786Z",
        updatedAt: "2024-06-08T00:15:23.786Z",
      },
      {
        id: "clx5d60fo01e912vckqkzvqzl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S911U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S911U; dm1q; qcom; es_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pzppx6rgN0",
            locked: 1717805708,
            timestamp: 1717805707,
          },
        ],
        createdAt: "2024-06-08T00:15:09.490Z",
        updatedAt: "2024-06-08T00:15:09.490Z",
      },
      {
        id: "clx5d39tx01e812vcs2i350u8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "HL8JVTvl34",
            locked: 1717805581,
            timestamp: 1717805580,
          },
        ],
        createdAt: "2024-06-08T00:13:01.701Z",
        updatedAt: "2024-06-08T00:13:01.701Z",
      },
      {
        id: "clx5d35ed01e712vcemil4rms",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lcY8MbwKmc",
            locked: 1717805575,
            timestamp: 1717805573,
          },
        ],
        createdAt: "2024-06-08T00:12:55.955Z",
        updatedAt: "2024-06-08T00:12:55.955Z",
      },
      {
        id: "clx5d0nfq01e612vcpkifoy5w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "37ReIGb4SM",
            locked: 1717805459,
            timestamp: 1717805456,
          },
        ],
        createdAt: "2024-06-08T00:10:59.366Z",
        updatedAt: "2024-06-08T00:10:59.366Z",
      },
      {
        id: "clx5d0jyv01e512vc7drtxwz1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; F92 E 5G Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.152 Mobile Safari/537.36 [FBAN/FB4A;FBAV/467.0.0.46.83;FBBV/606680399;FBDM/{density=2.0,width=720,height=1520};FBLC/en_US;FBRV/607790481;FBCR/ASTC;FBMF/BLU;FBBD/BLU;FBPN/com.facebook.katana;FBDV/F92 E 5G;FBSV/12;FBOP/1;FBCA/arm64-v8a:;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RdUVxt5Iv3",
            locked: 1717805454,
            timestamp: 1717805450,
          },
        ],
        createdAt: "2024-06-08T00:10:54.869Z",
        updatedAt: "2024-06-08T00:10:54.869Z",
      },
      {
        id: "clx5d050z01e412vcxcxdrt6l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "dHLcdfqMvn",
            locked: 1717805435,
            timestamp: 1717805434,
          },
        ],
        createdAt: "2024-06-08T00:10:35.507Z",
        updatedAt: "2024-06-08T00:10:35.507Z",
      },
      {
        id: "clx5d034001e312vcuc9x667z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bIIYvl1bF9",
            locked: 1717805432,
            timestamp: 1717805421,
          },
        ],
        createdAt: "2024-06-08T00:10:33.022Z",
        updatedAt: "2024-06-08T00:10:33.022Z",
      },
      {
        id: "clx5cyqik01e212vcppcyse7o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S926B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2220; samsung; SM-S926B; e2s; s5e9945; en_AU; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "j5AhgTtR1o",
            locked: 1717805370,
            timestamp: 1717805369,
          },
        ],
        createdAt: "2024-06-08T00:09:30.041Z",
        updatedAt: "2024-06-08T00:09:30.041Z",
      },
      {
        id: "clx5cyjh201e112vcfjb14f4p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S926B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2220; samsung; SM-S926B; e2s; s5e9945; en_AU; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mStyo3vyKm",
            locked: 1717805360,
            timestamp: 1717805358,
          },
        ],
        createdAt: "2024-06-08T00:09:20.919Z",
        updatedAt: "2024-06-08T00:09:20.919Z",
      },
      {
        id: "clx5cyann01e012vc41f69qky",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xjFutdXWHw",
            locked: 1717805348,
            timestamp: 1717805347,
          },
        ],
        createdAt: "2024-06-08T00:09:09.489Z",
        updatedAt: "2024-06-08T00:09:09.489Z",
      },
      {
        id: "clx5cx18l01dz12vc4kjjhowu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; motorola edge 40 Build/T2TLS33.3-62-3-8; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 400dpi; 1080x2174; motorola; motorola edge 40; lyriq; mt6893; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "99c66RlOog",
            locked: 1717805290,
            timestamp: 1717805287,
          },
        ],
        createdAt: "2024-06-08T00:08:10.630Z",
        updatedAt: "2024-06-08T00:08:10.630Z",
      },
      {
        id: "clx5cwyru01dy12vcw92evt6s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; motorola edge 40 Build/T2TLS33.3-62-3-8; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 400dpi; 1080x2174; motorola; motorola edge 40; lyriq; mt6893; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GGG0DzOv5P",
            locked: 1717805286,
            timestamp: 1717805284,
          },
        ],
        createdAt: "2024-06-08T00:08:07.432Z",
        updatedAt: "2024-06-08T00:08:07.432Z",
      },
      {
        id: "clx5cwbmx01dx12vcbjenurnc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qviK5AGQUs",
            locked: 1717805256,
            timestamp: 1717805255,
          },
        ],
        createdAt: "2024-06-08T00:07:37.447Z",
        updatedAt: "2024-06-08T00:07:37.447Z",
      },
      {
        id: "clx5cw1hu01dw12vck8wagb1y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "9DNp6BXJ2w",
            locked: 1717805244,
            timestamp: 1717805243,
          },
        ],
        createdAt: "2024-06-08T00:07:24.306Z",
        updatedAt: "2024-06-08T00:07:24.306Z",
      },
      {
        id: "clx5cvt2q01dv12vc689ypbt0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yS8fWkSMZS",
            locked: 1717805233,
            timestamp: 1717805231,
          },
        ],
        createdAt: "2024-06-08T00:07:13.391Z",
        updatedAt: "2024-06-08T00:07:13.391Z",
      },
      {
        id: "clx5cv5oh01du12vc2mbovbsx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "tNpXNzmowr",
            locked: 1717805202,
            timestamp: 1717805201,
          },
        ],
        createdAt: "2024-06-08T00:06:43.071Z",
        updatedAt: "2024-06-08T00:06:43.071Z",
      },
      {
        id: "clx5cukhc01dt12vcxpgrknk4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vCh6yShLUz",
            locked: 1717805174,
            timestamp: 1717805173,
          },
        ],
        createdAt: "2024-06-08T00:06:15.599Z",
        updatedAt: "2024-06-08T00:06:15.599Z",
      },
      {
        id: "clx5cud8m01ds12vca4vi8k3d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "7rEX6zrKtQ",
            locked: 1717805177,
            timestamp: 1717805176,
          },
        ],
        createdAt: "2024-06-08T00:06:06.215Z",
        updatedAt: "2024-06-08T00:06:06.215Z",
      },
      {
        id: "clx5cub8401dr12vcjt274tgi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "x9Xw0Snp2Y",
            locked: 1717805174,
            timestamp: 1717805172,
          },
        ],
        createdAt: "2024-06-08T00:06:03.604Z",
        updatedAt: "2024-06-08T00:06:03.604Z",
      },
      {
        id: "clx5cuac101dq12vckiser0sp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DQqe80KuO3",
            locked: 1717805162,
            timestamp: 1717805160,
          },
        ],
        createdAt: "2024-06-08T00:06:02.450Z",
        updatedAt: "2024-06-08T00:06:02.450Z",
      },
      {
        id: "clx5cu5we01dp12vcdp162zub",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3oKdPCMuPr",
            locked: 1717805156,
            timestamp: 1717805154,
          },
        ],
        createdAt: "2024-06-08T00:05:56.700Z",
        updatedAt: "2024-06-08T00:05:56.700Z",
      },
      {
        id: "clx5cs72i01do12vc6m2g830e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone11,2; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Ik94u5A9iv",
            locked: 1717805064,
            timestamp: 1717805063,
          },
        ],
        createdAt: "2024-06-08T00:04:24.906Z",
        updatedAt: "2024-06-08T00:04:24.906Z",
      },
      {
        id: "clx5cs03s01dn12vcrietud0g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone11,2; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3CXpWSUh30",
            locked: 1717805055,
            timestamp: 1717805053,
          },
        ],
        createdAt: "2024-06-08T00:04:15.876Z",
        updatedAt: "2024-06-08T00:04:15.876Z",
      },
      {
        id: "clx5cr2g001dm12vctsmm945i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9nbIH7xDeH",
            locked: 1717805011,
            timestamp: 1717805010,
          },
        ],
        createdAt: "2024-06-08T00:03:32.254Z",
        updatedAt: "2024-06-08T00:03:32.254Z",
      },
      {
        id: "clx5cpxk501dl12vclfu1jfq3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "NqPR8PtTFK",
            locked: 1717804959,
            timestamp: 1717804958,
          },
        ],
        createdAt: "2024-06-08T00:02:39.270Z",
        updatedAt: "2024-06-08T00:02:39.270Z",
      },
      {
        id: "clx5cps7f01dk12vcvqj3n6ye",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3RxnYZWr1h",
            locked: 1717804951,
            timestamp: 1717804950,
          },
        ],
        createdAt: "2024-06-08T00:02:32.328Z",
        updatedAt: "2024-06-08T00:02:32.328Z",
      },
      {
        id: "clx5cpbyg01dj12vcac84ruwi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/126.0.6478.35 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "aZtFIUXts1",
            locked: 1717804931,
            timestamp: 1717804930,
          },
        ],
        createdAt: "2024-06-08T00:02:11.272Z",
        updatedAt: "2024-06-08T00:02:11.272Z",
      },
      {
        id: "clx5cpaov01di12vcv89yr9ch",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/126.0.6478.35 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "TOK2yNrwhM",
            locked: 1717804929,
            timestamp: 1717804926,
          },
        ],
        createdAt: "2024-06-08T00:02:09.631Z",
        updatedAt: "2024-06-08T00:02:09.631Z",
      },
      {
        id: "clx5cp7wl01dh12vc58rq4g5u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "2fUaWXGNhp",
            locked: 1717804926,
            timestamp: 1717804923,
          },
        ],
        createdAt: "2024-06-08T00:02:06.019Z",
        updatedAt: "2024-06-08T00:02:06.019Z",
      },
      {
        id: "clx5cou9701dg12vctdn5wesa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "4WqllPoGWb",
            locked: 1717804908,
            timestamp: 1717804907,
          },
        ],
        createdAt: "2024-06-08T00:01:48.331Z",
        updatedAt: "2024-06-08T00:01:48.331Z",
      },
      {
        id: "clx5cop7s01df12vcmejb619c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G991U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 480dpi; 1080x2176; samsung; SM-G991U; o1q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EESPpi9gOc",
            locked: 1717804898,
            timestamp: 1717804896,
          },
        ],
        createdAt: "2024-06-08T00:01:41.800Z",
        updatedAt: "2024-06-08T00:01:41.800Z",
      },
      {
        id: "clx5colcj01de12vcdi4qpigq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PlvRI9lirc",
            locked: 1717804896,
            timestamp: 1717804895,
          },
        ],
        createdAt: "2024-06-08T00:01:36.786Z",
        updatedAt: "2024-06-08T00:01:36.786Z",
      },
      {
        id: "clx5coixy01dd12vcf3xb5ymd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zZcL8Y3VKC",
            locked: 1717804892,
            timestamp: 1717804891,
          },
        ],
        createdAt: "2024-06-08T00:01:33.670Z",
        updatedAt: "2024-06-08T00:01:33.670Z",
      },
      {
        id: "clx5cogh801dc12vchp0c6t7w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MdBaOs91LH",
            locked: 1717804890,
            timestamp: 1717804888,
          },
        ],
        createdAt: "2024-06-08T00:01:30.476Z",
        updatedAt: "2024-06-08T00:01:30.476Z",
      },
      {
        id: "clx5co8nf01db12vcr31uhqht",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VBjwHgnK9Q",
            locked: 1717804878,
            timestamp: 1717804877,
          },
        ],
        createdAt: "2024-06-08T00:01:20.328Z",
        updatedAt: "2024-06-08T00:01:20.328Z",
      },
      {
        id: "clx5cnuaf01da12vczxauj03g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "IrRBwfmDef",
            locked: 1717804861,
            timestamp: 1717804858,
          },
        ],
        createdAt: "2024-06-08T00:01:01.717Z",
        updatedAt: "2024-06-08T00:01:01.717Z",
      },
      {
        id: "clx5cnhnp01d912vcazd9u3fg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Vx0qdpx1yT",
            locked: 1717804844,
            timestamp: 1717804843,
          },
        ],
        createdAt: "2024-06-08T00:00:45.347Z",
        updatedAt: "2024-06-08T00:00:45.347Z",
      },
      {
        id: "clx5clv0t01d812vce43j19sy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6Lzcp3tqME",
            locked: 1717804768,
            timestamp: 1717804767,
          },
        ],
        createdAt: "2024-06-07T23:59:29.357Z",
        updatedAt: "2024-06-07T23:59:29.357Z",
      },
      {
        id: "clx5clrso01d712vcxv9q3zcf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WXQJkNP4G5",
            locked: 1717804764,
            timestamp: 1717804763,
          },
        ],
        createdAt: "2024-06-07T23:59:25.176Z",
        updatedAt: "2024-06-07T23:59:25.176Z",
      },
      {
        id: "clx5clj2001d612vcs6o7f9un",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 333.1.4.32.95 (iPhone13,1; iOS 17_3_1; en_US; en; scale=3.00; 1125x2436; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rfz0xjZHND",
            locked: 1717804753,
            timestamp: 1717804750,
          },
        ],
        createdAt: "2024-06-07T23:59:13.845Z",
        updatedAt: "2024-06-07T23:59:13.845Z",
      },
      {
        id: "clx5ckygi01d512vcqmrzdm5f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "CfaR3688O8",
            locked: 1717804726,
            timestamp: 1717804725,
          },
        ],
        createdAt: "2024-06-07T23:58:47.154Z",
        updatedAt: "2024-06-07T23:58:47.154Z",
      },
      {
        id: "clx5ckvgz01d412vckte7tn2y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dt0P5IlSa6",
            locked: 1717804722,
            timestamp: 1717804721,
          },
        ],
        createdAt: "2024-06-07T23:58:43.277Z",
        updatedAt: "2024-06-07T23:58:43.277Z",
      },
      {
        id: "clx5cju4d01d312vc1h6gckcr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "2apsufxFC6",
            locked: 1717804674,
            timestamp: 1717804673,
          },
        ],
        createdAt: "2024-06-07T23:57:54.878Z",
        updatedAt: "2024-06-07T23:57:54.878Z",
      },
      {
        id: "clx5cjt2301d212vc4vq9fbna",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jssde1qeaf",
            locked: 1717804672,
            timestamp: 1717804671,
          },
        ],
        createdAt: "2024-06-07T23:57:53.499Z",
        updatedAt: "2024-06-07T23:57:53.499Z",
      },
      {
        id: "clx5cjrpz01d112vc7uuhqngn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3Ji3ByX71q",
            locked: 1717804671,
            timestamp: 1717804669,
          },
        ],
        createdAt: "2024-06-07T23:57:51.764Z",
        updatedAt: "2024-06-07T23:57:51.764Z",
      },
      {
        id: "clx5cj0w101d012vchevtjv1e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "d9r7nIrzEY",
            locked: 1717804636,
            timestamp: 1717804635,
          },
        ],
        createdAt: "2024-06-07T23:57:16.993Z",
        updatedAt: "2024-06-07T23:57:16.993Z",
      },
      {
        id: "clx5cixtm01cz12vc0wvzy4ev",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "voaGHn1f4A",
            locked: 1717804633,
            timestamp: 1717804629,
          },
        ],
        createdAt: "2024-06-07T23:57:13.018Z",
        updatedAt: "2024-06-07T23:57:13.018Z",
      },
      {
        id: "clx5cixd101cy12vcstpp34t7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "IgSIP4iETG",
            locked: 1717804631,
            timestamp: 1717804630,
          },
        ],
        createdAt: "2024-06-07T23:57:12.420Z",
        updatedAt: "2024-06-07T23:57:12.420Z",
      },
      {
        id: "clx5cezab01cx12vcwd5yp7wr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "uvHqX5sqlD",
            locked: 1717804448,
            timestamp: 1717804447,
          },
        ],
        createdAt: "2024-06-07T23:54:08.291Z",
        updatedAt: "2024-06-07T23:54:08.291Z",
      },
      {
        id: "clx5cez0101cw12vcjof2wdgt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "uSLqOpomZV",
            locked: 1717804447,
            timestamp: 1717804446,
          },
        ],
        createdAt: "2024-06-07T23:54:07.921Z",
        updatedAt: "2024-06-07T23:54:07.921Z",
      },
      {
        id: "clx5cequ701cv12vcq8n6dc3j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IBjUotxrzV",
            locked: 1717804436,
            timestamp: 1717804435,
          },
        ],
        createdAt: "2024-06-07T23:53:57.342Z",
        updatedAt: "2024-06-07T23:53:57.342Z",
      },
      {
        id: "clx5cem8801cu12vc038rft6y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9KtX0nQEjh",
            locked: 1717804430,
            timestamp: 1717804429,
          },
        ],
        createdAt: "2024-06-07T23:53:51.369Z",
        updatedAt: "2024-06-07T23:53:51.369Z",
      },
      {
        id: "clx5cedfd01ct12vcr9ljm11i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wwvpT7bxba",
            locked: 1717804418,
            timestamp: 1717804417,
          },
        ],
        createdAt: "2024-06-07T23:53:39.958Z",
        updatedAt: "2024-06-07T23:53:39.958Z",
      },
      {
        id: "clx5cdmdc01cs12vcej70eso2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N970U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 420dpi; 1080x2064; samsung; SM-N970U; d1q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jR2V7FXEuC",
            locked: 1717804384,
            timestamp: 1717804381,
          },
        ],
        createdAt: "2024-06-07T23:53:04.894Z",
        updatedAt: "2024-06-07T23:53:04.894Z",
      },
      {
        id: "clx5cd37p01cr12vcoqotn34i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "QX7sQKn3Gd",
            locked: 1717804359,
            timestamp: 1717804358,
          },
        ],
        createdAt: "2024-06-07T23:52:40.066Z",
        updatedAt: "2024-06-07T23:52:40.066Z",
      },
      {
        id: "clx5ccuzd01cq12vcalrgz27k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "udqKq1bSyV",
            locked: 1717804348,
            timestamp: 1717804347,
          },
        ],
        createdAt: "2024-06-07T23:52:29.401Z",
        updatedAt: "2024-06-07T23:52:29.401Z",
      },
      {
        id: "clx5ccuc701cp12vc4hqo1lbf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Tkuh5s7Z35",
            locked: 1717804345,
            timestamp: 1717804341,
          },
        ],
        createdAt: "2024-06-07T23:52:28.568Z",
        updatedAt: "2024-06-07T23:52:28.568Z",
      },
      {
        id: "clx5ccofq01co12vc5g65lezz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "R9V6Lyn5Po",
            locked: 1717804340,
            timestamp: 1717804338,
          },
        ],
        createdAt: "2024-06-07T23:52:20.916Z",
        updatedAt: "2024-06-07T23:52:20.916Z",
      },
      {
        id: "clx5cbros01cn12vc5is5b2jr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "fOLaFgx0bL",
            locked: 1717804298,
            timestamp: 1717804297,
          },
        ],
        createdAt: "2024-06-07T23:51:38.476Z",
        updatedAt: "2024-06-07T23:51:38.476Z",
      },
      {
        id: "clx5cbqgp01cm12vcjsdpicu6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "G1F0hMjwBT",
            locked: 1717804296,
            timestamp: 1717804295,
          },
        ],
        createdAt: "2024-06-07T23:51:36.889Z",
        updatedAt: "2024-06-07T23:51:36.889Z",
      },
      {
        id: "clx5cblya01cl12vc1n22iosi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "wYKWI1HO8u",
            locked: 1717804290,
            timestamp: 1717804289,
          },
        ],
        createdAt: "2024-06-07T23:51:31.041Z",
        updatedAt: "2024-06-07T23:51:31.041Z",
      },
      {
        id: "clx5cbj6i01ck12vcahquoajm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WGjUb66bTo",
            locked: 1717804286,
            timestamp: 1717804285,
          },
        ],
        createdAt: "2024-06-07T23:51:27.450Z",
        updatedAt: "2024-06-07T23:51:27.450Z",
      },
      {
        id: "clx5cb7vg01cj12vcbf14l311",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Tkd4a5J08x",
            locked: 1717804272,
            timestamp: 1717804270,
          },
        ],
        createdAt: "2024-06-07T23:51:12.793Z",
        updatedAt: "2024-06-07T23:51:12.793Z",
      },
      {
        id: "clx5c9tg401ci12vcs23azt39",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21B101 Instagram 312.0.1.19.124 (iPhone15,3; iOS 17_1_2; en_US; en; scale=3.00; 1290x2796; 548339486)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HBQSNOxJHY",
            locked: 1717804206,
            timestamp: 1717804205,
          },
        ],
        createdAt: "2024-06-07T23:50:07.444Z",
        updatedAt: "2024-06-07T23:50:07.444Z",
      },
      {
        id: "clx5c9ru601ch12vcbqghdysz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LjkSEuE82y",
            locked: 1717804205,
            timestamp: 1717804201,
          },
        ],
        createdAt: "2024-06-07T23:50:05.358Z",
        updatedAt: "2024-06-07T23:50:05.358Z",
      },
      {
        id: "clx5c9rpr01cg12vczfggscvx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "aSImYcndcE",
            locked: 1717804204,
            timestamp: 1717804203,
          },
        ],
        createdAt: "2024-06-07T23:50:05.196Z",
        updatedAt: "2024-06-07T23:50:05.196Z",
      },
      {
        id: "clx5c9dzu01cf12vcil50i69a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pCk5ptz82B",
            locked: 1717804186,
            timestamp: 1717804185,
          },
        ],
        createdAt: "2024-06-07T23:49:47.419Z",
        updatedAt: "2024-06-07T23:49:47.419Z",
      },
      {
        id: "clx5c9d9h01ce12vchjh29hvn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PNDcGzij44",
            locked: 1717804183,
            timestamp: 1717804182,
          },
        ],
        createdAt: "2024-06-07T23:49:46.466Z",
        updatedAt: "2024-06-07T23:49:46.466Z",
      },
      {
        id: "clx5c85mi01cd12vcc4xy74op",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21B101 Instagram 312.0.1.19.124 (iPhone14,7; iOS 17_1_2; en_US; en; scale=3.00; 1170x2532; 548339486)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3Ear6TkvAC",
            locked: 1717804129,
            timestamp: 1717804127,
          },
        ],
        createdAt: "2024-06-07T23:48:49.915Z",
        updatedAt: "2024-06-07T23:48:49.915Z",
      },
      {
        id: "clx5c7y7601cc12vc5hnvkac4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "nvIXJi2kkj",
            locked: 1717804120,
            timestamp: 1717804119,
          },
        ],
        createdAt: "2024-06-07T23:48:40.290Z",
        updatedAt: "2024-06-07T23:48:40.290Z",
      },
      {
        id: "clx5c7wfi01cb12vczg8ghym9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DAiWvmbMWH",
            locked: 1717804117,
            timestamp: 1717804115,
          },
        ],
        createdAt: "2024-06-07T23:48:37.995Z",
        updatedAt: "2024-06-07T23:48:37.995Z",
      },
      {
        id: "clx5c7dii01ca12vc67k4zu5l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "NFqFXKDkB9",
            locked: 1717804093,
            timestamp: 1717804092,
          },
        ],
        createdAt: "2024-06-07T23:48:13.481Z",
        updatedAt: "2024-06-07T23:48:13.481Z",
      },
      {
        id: "clx5c77e401c912vcytgukg2c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "I8p1CBBW3o",
            locked: 1717804085,
            timestamp: 1717804083,
          },
        ],
        createdAt: "2024-06-07T23:48:05.549Z",
        updatedAt: "2024-06-07T23:48:05.549Z",
      },
      {
        id: "clx5c6z8j01c812vcim35q81e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Q92gfAfa0k",
            locked: 1717804074,
            timestamp: 1717804072,
          },
        ],
        createdAt: "2024-06-07T23:47:54.977Z",
        updatedAt: "2024-06-07T23:47:54.977Z",
      },
      {
        id: "clx5c5ufu01c712vcuaf7jhmf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "5j0GadNy91",
            locked: 1717804022,
            timestamp: 1717804021,
          },
        ],
        createdAt: "2024-06-07T23:47:02.106Z",
        updatedAt: "2024-06-07T23:47:02.106Z",
      },
      {
        id: "clx5c5rag01c612vcnj7rwqh7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gEHeOYFBZE",
            locked: 1717804017,
            timestamp: 1717804015,
          },
        ],
        createdAt: "2024-06-07T23:46:58.023Z",
        updatedAt: "2024-06-07T23:46:58.023Z",
      },
      {
        id: "clx5c5ni401c512vcvgywjvs9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bd9j9dzyZ6",
            locked: 1717804009,
            timestamp: 1717803995,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ZcqlossZd9",
            locked: 1717804009,
            timestamp: 1717804003,
          },
        ],
        createdAt: "2024-06-07T23:46:53.116Z",
        updatedAt: "2024-06-07T23:46:53.116Z",
      },
      {
        id: "clx5c5gpi01c412vc7d70y354",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "imQwIBpUwE",
            locked: 1717804004,
            timestamp: 1717804002,
          },
        ],
        createdAt: "2024-06-07T23:46:44.311Z",
        updatedAt: "2024-06-07T23:46:44.311Z",
      },
      {
        id: "clx5c5cpn01c312vcpyg1mqbe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "T8xySRMGZ7",
            locked: 1717803998,
            timestamp: 1717803992,
          },
        ],
        createdAt: "2024-06-07T23:46:39.130Z",
        updatedAt: "2024-06-07T23:46:39.130Z",
      },
      {
        id: "clx5c586901c212vczn4w35tu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7nxOMxDsV0",
            locked: 1717803992,
            timestamp: 1717803991,
          },
        ],
        createdAt: "2024-06-07T23:46:33.250Z",
        updatedAt: "2024-06-07T23:46:33.250Z",
      },
      {
        id: "clx5c4x3m01c112vcenogmddb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-G965U1 Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (29/10; 480dpi; 1080x2076; samsung; SM-G965U1; star2qlteue; qcom; en_US; 601420827)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2hEJ37i59A",
            locked: 1717803978,
            timestamp: 1717803972,
          },
        ],
        createdAt: "2024-06-07T23:46:18.895Z",
        updatedAt: "2024-06-07T23:46:18.895Z",
      },
      {
        id: "clx5c4kti01c012vcj8leyr66",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A156U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2125; samsung; SM-A156U; a15x; mt6835; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "sk4c4m0dkq",
            locked: 1717803961,
            timestamp: 1717803958,
          },
        ],
        createdAt: "2024-06-07T23:46:02.982Z",
        updatedAt: "2024-06-07T23:46:02.982Z",
      },
      {
        id: "clx5c4kif01bz12vc7nhr9srk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zeIvnrg3F7",
            locked: 1717803961,
            timestamp: 1717803960,
          },
        ],
        createdAt: "2024-06-07T23:46:02.583Z",
        updatedAt: "2024-06-07T23:46:02.583Z",
      },
      {
        id: "clx5c4ear01by12vctdz59n3b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G970U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 480dpi; 1080x2020; samsung; SM-G970U; beyond0q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "wH8j537CdR",
            locked: 1717803954,
            timestamp: 1717803951,
          },
        ],
        createdAt: "2024-06-07T23:45:54.528Z",
        updatedAt: "2024-06-07T23:45:54.528Z",
      },
      {
        id: "clx5c1ez901bx12vcwi3d7he7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Yknr1CcUnm",
            locked: 1717803815,
            timestamp: 1717803812,
          },
        ],
        createdAt: "2024-06-07T23:43:35.446Z",
        updatedAt: "2024-06-07T23:43:35.446Z",
      },
      {
        id: "clx5c19a901bw12vchm6gynf2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "A4raCyApMu",
            locked: 1717803807,
            timestamp: 1717803806,
          },
        ],
        createdAt: "2024-06-07T23:43:28.062Z",
        updatedAt: "2024-06-07T23:43:28.062Z",
      },
      {
        id: "clx5c0qhh01bv12vcifgt1iei",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 750x1624; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "8ARVyNvNsZ",
            locked: 1717803782,
            timestamp: 1717803781,
          },
        ],
        createdAt: "2024-06-07T23:43:03.700Z",
        updatedAt: "2024-06-07T23:43:03.700Z",
      },
      {
        id: "clx5c082y01bu12vcae7gjd2h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "PBIhfAMK4h",
            locked: 1717803758,
            timestamp: 1717803757,
          },
        ],
        createdAt: "2024-06-07T23:42:39.851Z",
        updatedAt: "2024-06-07T23:42:39.851Z",
      },
      {
        id: "clx5c01rd01bt12vc8oxdjjx3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ivWXqAEcU5",
            locked: 1717803749,
            timestamp: 1717803748,
          },
        ],
        createdAt: "2024-06-07T23:42:31.658Z",
        updatedAt: "2024-06-07T23:42:31.658Z",
      },
      {
        id: "clx5bzzj701bs12vc6lacrgt8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "73ekaArTFb",
            locked: 1717803748,
            timestamp: 1717803747,
          },
        ],
        createdAt: "2024-06-07T23:42:28.769Z",
        updatedAt: "2024-06-07T23:42:28.769Z",
      },
      {
        id: "clx5bzq5701br12vcsn250imz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7kX8PBR98K",
            locked: 1717803733,
            timestamp: 1717803732,
          },
        ],
        createdAt: "2024-06-07T23:42:16.603Z",
        updatedAt: "2024-06-07T23:42:16.603Z",
      },
      {
        id: "clx5bzmlu01bq12vcqmg45j6y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AyLowDPjlk",
            locked: 1717803731,
            timestamp: 1717803730,
          },
        ],
        createdAt: "2024-06-07T23:42:12.019Z",
        updatedAt: "2024-06-07T23:42:12.019Z",
      },
      {
        id: "clx5bzg2401bp12vcknx3zqe9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DEHdsSuJ9G",
            locked: 1717803722,
            timestamp: 1717803721,
          },
        ],
        createdAt: "2024-06-07T23:42:03.529Z",
        updatedAt: "2024-06-07T23:42:03.529Z",
      },
      {
        id: "clx5byy6t01bo12vcs2pl0j4v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "631f9tWrSy",
            locked: 1717803699,
            timestamp: 1717803698,
          },
        ],
        createdAt: "2024-06-07T23:41:40.370Z",
        updatedAt: "2024-06-07T23:41:40.370Z",
      },
      {
        id: "clx5bxpux01bn12vca7x0rz9o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,1; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UCNIabwpdl",
            locked: 1717803642,
            timestamp: 1717803638,
          },
        ],
        createdAt: "2024-06-07T23:40:42.918Z",
        updatedAt: "2024-06-07T23:40:42.918Z",
      },
      {
        id: "clx5buu2p01bm12vcd6nqwxsd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "20iykpE6Oh",
            locked: 1717803508,
            timestamp: 1717803506,
          },
        ],
        createdAt: "2024-06-07T23:38:28.415Z",
        updatedAt: "2024-06-07T23:38:28.415Z",
      },
      {
        id: "clx5bu4bs01bl12vcp23fylia",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HBjFx9I7Rz",
            locked: 1717803474,
            timestamp: 1717803473,
          },
        ],
        createdAt: "2024-06-07T23:37:55.046Z",
        updatedAt: "2024-06-07T23:37:55.046Z",
      },
      {
        id: "clx5btd4b01bk12vcbjihaqtu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "20mTtapItQ",
            locked: 1717803439,
            timestamp: 1717803438,
          },
        ],
        createdAt: "2024-06-07T23:37:19.785Z",
        updatedAt: "2024-06-07T23:37:19.785Z",
      },
      {
        id: "clx5bs96701bj12vcifrqljvi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dS3bigyYDd",
            locked: 1717803387,
            timestamp: 1717803383,
          },
        ],
        createdAt: "2024-06-07T23:36:28.013Z",
        updatedAt: "2024-06-07T23:36:28.013Z",
      },
      {
        id: "clx5brrba01bi12vci5g8jzh2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "wq5ltY5MPm",
            locked: 1717803364,
            timestamp: 1717803363,
          },
        ],
        createdAt: "2024-06-07T23:36:04.871Z",
        updatedAt: "2024-06-07T23:36:04.871Z",
      },
      {
        id: "clx5brr0k01bh12vch9vk75f9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 334.0.4.32.98 (iPhone12,3; iOS 15_6_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pbzwiLX0PY",
            locked: 1717803364,
            timestamp: 1717803362,
          },
        ],
        createdAt: "2024-06-07T23:36:04.485Z",
        updatedAt: "2024-06-07T23:36:04.485Z",
      },
      {
        id: "clx5bri4b01bg12vc4s27y53b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MTSilN09im",
            locked: 1717803352,
            timestamp: 1717803349,
          },
        ],
        createdAt: "2024-06-07T23:35:52.953Z",
        updatedAt: "2024-06-07T23:35:52.953Z",
      },
      {
        id: "clx5bpewg01bf12vcg5byvbil",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-S918U; dm3q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fBW6osv6AN",
            locked: 1717803254,
            timestamp: 1717803252,
          },
        ],
        createdAt: "2024-06-07T23:34:15.469Z",
        updatedAt: "2024-06-07T23:34:15.469Z",
      },
      {
        id: "clx5bok7h01be12vcnpahyf86",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2GaKbYaWs0",
            locked: 1717803214,
            timestamp: 1717803210,
          },
        ],
        createdAt: "2024-06-07T23:33:35.690Z",
        updatedAt: "2024-06-07T23:33:35.690Z",
      },
      {
        id: "clx5bnoua01bd12vcq6c0pd0e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G973U1 Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 420dpi; 1080x2042; samsung; SM-G973U1; beyond1q; qcom; en_US; 604247782)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "eUslKJ5qln",
            locked: 1717803173,
            timestamp: 1717803168,
          },
        ],
        createdAt: "2024-06-07T23:32:55.039Z",
        updatedAt: "2024-06-07T23:32:55.039Z",
      },
      {
        id: "clx5bltx801bc12vcskba8l3d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0.0; Win64; x64; ) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.6261.156 Not(A:Brand/24 YaBrowser/24.4.4.1168 Yowser/2.5  Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yUInigGdNH",
            locked: 1717803084,
            timestamp: 1717803079,
          },
        ],
        createdAt: "2024-06-07T23:31:28.314Z",
        updatedAt: "2024-06-07T23:31:28.314Z",
      },
      {
        id: "clx5bkwtf01bb12vcenii60xn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hMnR8CzcpT",
            locked: 1717803044,
            timestamp: 1717803043,
          },
        ],
        createdAt: "2024-06-07T23:30:45.405Z",
        updatedAt: "2024-06-07T23:30:45.405Z",
      },
      {
        id: "clx5bkl7g01ba12vcpt4ahmge",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S916U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2131; samsung; SM-S916U; dm2q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "eoFpnThfsn",
            locked: 1717803029,
            timestamp: 1717803027,
          },
        ],
        createdAt: "2024-06-07T23:30:30.364Z",
        updatedAt: "2024-06-07T23:30:30.364Z",
      },
      {
        id: "clx5bkj2h01b912vcqbsyoqbb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "t9UnDMihZf",
            locked: 1717803027,
            timestamp: 1717803024,
          },
        ],
        createdAt: "2024-06-07T23:30:27.590Z",
        updatedAt: "2024-06-07T23:30:27.590Z",
      },
      {
        id: "clx5bjrsf01b812vc8l8xl62l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ZECExP3jHi",
            locked: 1717802992,
            timestamp: 1717802990,
          },
        ],
        createdAt: "2024-06-07T23:29:52.240Z",
        updatedAt: "2024-06-07T23:29:52.240Z",
      },
      {
        id: "clx5bjneu01b712vcw380tdv1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "IQKP0Yqxz3",
            locked: 1717802985,
            timestamp: 1717802984,
          },
        ],
        createdAt: "2024-06-07T23:29:46.563Z",
        updatedAt: "2024-06-07T23:29:46.563Z",
      },
      {
        id: "clx5bilzd01b612vc1lf8dkqh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "aiadWTcIFU",
            locked: 1717802937,
            timestamp: 1717802936,
          },
        ],
        createdAt: "2024-06-07T23:28:58.058Z",
        updatedAt: "2024-06-07T23:28:58.058Z",
      },
      {
        id: "clx5biexi01b512vcqrnudck4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,2; iOS 16_7_8; en_US; en; scale=3.00; 1125x2001; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0BSrCR6fJ2",
            locked: 1717802928,
            timestamp: 1717802925,
          },
        ],
        createdAt: "2024-06-07T23:28:48.914Z",
        updatedAt: "2024-06-07T23:28:48.914Z",
      },
      {
        id: "clx5bgz3201b412vcx08e3qww",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 330.0.3.29.91 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 595323416) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2YHsMUFKbL",
            locked: 1717802861,
            timestamp: 1717802859,
          },
        ],
        createdAt: "2024-06-07T23:27:41.727Z",
        updatedAt: "2024-06-07T23:27:41.727Z",
      },
      {
        id: "clx5bgq4k01b312vci69oblrf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "r9uWLuCkUK",
            locked: 1717802849,
            timestamp: 1717802848,
          },
        ],
        createdAt: "2024-06-07T23:27:30.108Z",
        updatedAt: "2024-06-07T23:27:30.108Z",
      },
      {
        id: "clx5bgf6f01b212vc0tx3w5r7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "8TQIhpt8et",
            locked: 1717802835,
            timestamp: 1717802834,
          },
        ],
        createdAt: "2024-06-07T23:27:15.928Z",
        updatedAt: "2024-06-07T23:27:15.928Z",
      },
      {
        id: "clx5bgd4g01b112vchftohe43",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dFEW5cwyq6",
            locked: 1717802832,
            timestamp: 1717802831,
          },
        ],
        createdAt: "2024-06-07T23:27:13.264Z",
        updatedAt: "2024-06-07T23:27:13.264Z",
      },
      {
        id: "clx5bg99a01b012vcyc7ungey",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "l3JTRIlS8t",
            locked: 1717802827,
            timestamp: 1717802823,
          },
        ],
        createdAt: "2024-06-07T23:27:08.254Z",
        updatedAt: "2024-06-07T23:27:08.254Z",
      },
      {
        id: "clx5bg4zo01az12vcnq8ltfbn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MbvVbV1XQf",
            locked: 1717802821,
            timestamp: 1717802820,
          },
        ],
        createdAt: "2024-06-07T23:27:02.722Z",
        updatedAt: "2024-06-07T23:27:02.722Z",
      },
      {
        id: "clx5bfhhc01ay12vcih2wbrlz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 334.0.4.32.98 (iPad5,3; iPadOS 15_8_2; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "UgsZPIKd4z",
            locked: 1717802791,
            timestamp: 1717802783,
          },
        ],
        createdAt: "2024-06-07T23:26:32.254Z",
        updatedAt: "2024-06-07T23:26:32.254Z",
      },
      {
        id: "clx5bezsv01ax12vckoguj607",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Mkibqt014W",
            locked: 1717802768,
            timestamp: 1717802766,
          },
        ],
        createdAt: "2024-06-07T23:26:09.344Z",
        updatedAt: "2024-06-07T23:26:09.344Z",
      },
      {
        id: "clx5beo8c01aw12vc3w9920rk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "KW7EJjTg78",
            locked: 1717802753,
            timestamp: 1717802752,
          },
        ],
        createdAt: "2024-06-07T23:25:54.346Z",
        updatedAt: "2024-06-07T23:25:54.346Z",
      },
      {
        id: "clx5ba1d301av12vcpp8d52wc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_CA; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "uEkbKSTWLI",
            locked: 1717802537,
            timestamp: 1717802536,
          },
        ],
        createdAt: "2024-06-07T23:22:18.088Z",
        updatedAt: "2024-06-07T23:22:18.088Z",
      },
      {
        id: "clx5b9xyc01au12vcu3lkldae",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_CA; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qZgi3rMLAi",
            locked: 1717802532,
            timestamp: 1717802531,
          },
        ],
        createdAt: "2024-06-07T23:22:13.665Z",
        updatedAt: "2024-06-07T23:22:13.665Z",
      },
      {
        id: "clx5b8kuc01at12vc3lgb8lzc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "mgLkvLIBI2",
            locked: 1717802469,
            timestamp: 1717802468,
          },
        ],
        createdAt: "2024-06-07T23:21:10.021Z",
        updatedAt: "2024-06-07T23:21:10.021Z",
      },
      {
        id: "clx5b8fuq01as12vcfwkoeeet",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 [FBAN/FBIOS;FBAV/466.0.0.36.107;FBBV/604687626;FBDV/iPhone14,3;FBMD/iPhone;FBSN/iOS;FBSV/17.5.1;FBSS/3;FBID/phone;FBLC/en_US;FBOP/5;FBRV/608107963]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VxDrrXpxEE",
            locked: 1717802463,
            timestamp: 1717802461,
          },
        ],
        createdAt: "2024-06-07T23:21:03.551Z",
        updatedAt: "2024-06-07T23:21:03.551Z",
      },
      {
        id: "clx5b7v3p01ar12vczzv9w7bv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "74VzJ21J6w",
            locked: 1717802436,
            timestamp: 1717802435,
          },
        ],
        createdAt: "2024-06-07T23:20:36.662Z",
        updatedAt: "2024-06-07T23:20:36.662Z",
      },
      {
        id: "clx5b7tvj01aq12vcsg4b8vd9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AlkBqr5HI0",
            locked: 1717802434,
            timestamp: 1717802433,
          },
        ],
        createdAt: "2024-06-07T23:20:35.069Z",
        updatedAt: "2024-06-07T23:20:35.069Z",
      },
      {
        id: "clx5b6vht01ap12vcurr0yshy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2129; samsung; SM-S928U; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UHQKKJjJ6n",
            locked: 1717802389,
            timestamp: 1717802388,
          },
        ],
        createdAt: "2024-06-07T23:19:50.514Z",
        updatedAt: "2024-06-07T23:19:50.514Z",
      },
      {
        id: "clx5b6rxk01ao12vcxu5r3iaz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OZfM6XzCda",
            locked: 1717802385,
            timestamp: 1717802384,
          },
        ],
        createdAt: "2024-06-07T23:19:45.894Z",
        updatedAt: "2024-06-07T23:19:45.894Z",
      },
      {
        id: "clx5b6bde01an12vc8lesp1zb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lRUujD6p2C",
            locked: 1717802363,
            timestamp: 1717802362,
          },
        ],
        createdAt: "2024-06-07T23:19:24.432Z",
        updatedAt: "2024-06-07T23:19:24.432Z",
      },
      {
        id: "clx5b5yi501am12vcj3540kq5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aPhVBP7HN9",
            locked: 1717802346,
            timestamp: 1717802344,
          },
        ],
        createdAt: "2024-06-07T23:19:07.758Z",
        updatedAt: "2024-06-07T23:19:07.758Z",
      },
      {
        id: "clx5b5puj01al12vcyvf2pv6b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21C66 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_2_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nERVr9EAB3",
            locked: 1717802335,
            timestamp: 1717802334,
          },
        ],
        createdAt: "2024-06-07T23:18:56.537Z",
        updatedAt: "2024-06-07T23:18:56.537Z",
      },
      {
        id: "clx5b4mz501ak12vcgu7ivars",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 332.0.2.29.94 (iPhone10,5; iOS 16_7_8; en_US; en; scale=3.00; 1242x2208; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "k4uqjnH90V",
            locked: 1717802285,
            timestamp: 1717802282,
          },
        ],
        createdAt: "2024-06-07T23:18:06.161Z",
        updatedAt: "2024-06-07T23:18:06.161Z",
      },
      {
        id: "clx5b4h5v01aj12vcczsway4b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_5_1; es_US; es; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RdunC4DGom",
            locked: 1717802278,
            timestamp: 1717802275,
          },
        ],
        createdAt: "2024-06-07T23:17:58.625Z",
        updatedAt: "2024-06-07T23:17:58.625Z",
      },
      {
        id: "clx5b3yym01ai12vcid6ap2wj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "3KsIui9nyc",
            locked: 1717802254,
            timestamp: 1717802253,
          },
        ],
        createdAt: "2024-06-07T23:17:35.039Z",
        updatedAt: "2024-06-07T23:17:35.039Z",
      },
      {
        id: "clx5b3u0x01ah12vcrksix3lb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "7LLYTd5MVJ",
            locked: 1717802242,
            timestamp: 1717802241,
          },
        ],
        createdAt: "2024-06-07T23:17:28.641Z",
        updatedAt: "2024-06-07T23:17:28.641Z",
      },
      {
        id: "clx5b3tob01ag12vcpqcfh36u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7qQI8QffTN",
            locked: 1717802247,
            timestamp: 1717802246,
          },
        ],
        createdAt: "2024-06-07T23:17:28.187Z",
        updatedAt: "2024-06-07T23:17:28.187Z",
      },
      {
        id: "clx5b3s5i01af12vcf10f6fgm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone11,2; iOS 16_6_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YfoQZO5H7b",
            locked: 1717802245,
            timestamp: 1717802243,
          },
        ],
        createdAt: "2024-06-07T23:17:26.214Z",
        updatedAt: "2024-06-07T23:17:26.214Z",
      },
      {
        id: "clx5b3r5a01ae12vcl1z8ggiw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bdIpItvvOS",
            locked: 1717802238,
            timestamp: 1717802237,
          },
        ],
        createdAt: "2024-06-07T23:17:24.908Z",
        updatedAt: "2024-06-07T23:17:24.908Z",
      },
      {
        id: "clx5b3d8601ad12vcm62gimjm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "XjXZAwXO3B",
            locked: 1717802226,
            timestamp: 1717802222,
          },
        ],
        createdAt: "2024-06-07T23:17:06.870Z",
        updatedAt: "2024-06-07T23:17:06.870Z",
      },
      {
        id: "clx5b361i01ac12vc4rkdu5v0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JWQQHhlc1K",
            locked: 1717802217,
            timestamp: 1717802215,
          },
        ],
        createdAt: "2024-06-07T23:16:57.559Z",
        updatedAt: "2024-06-07T23:16:57.559Z",
      },
      {
        id: "clx5b345e01ab12vccf4fmgdk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6ZFQsihhEC",
            locked: 1717802214,
            timestamp: 1717802213,
          },
        ],
        createdAt: "2024-06-07T23:16:55.104Z",
        updatedAt: "2024-06-07T23:16:55.104Z",
      },
      {
        id: "clx5b2p0b01aa12vcf6nlf9i0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vuAPlnfZhq",
            locked: 1717802194,
            timestamp: 1717802193,
          },
        ],
        createdAt: "2024-06-07T23:16:35.484Z",
        updatedAt: "2024-06-07T23:16:35.484Z",
      },
      {
        id: "clx5b2iaj01a912vcpg0tlc4c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zaLXX90NwG",
            locked: 1717802186,
            timestamp: 1717802185,
          },
        ],
        createdAt: "2024-06-07T23:16:26.779Z",
        updatedAt: "2024-06-07T23:16:26.779Z",
      },
      {
        id: "clx5b2gpi01a812vcun4kdzst",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N975F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fxm72Qn1GS",
            locked: 1717802184,
            timestamp: 1717802167,
          },
        ],
        createdAt: "2024-06-07T23:16:24.724Z",
        updatedAt: "2024-06-07T23:16:24.724Z",
      },
      {
        id: "clx5b0das01a712vcm40top7l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 510dpi; 1080x2106; samsung; SM-S901U; r0q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NF7xvl3Tzg",
            locked: 1717802086,
            timestamp: 1717802084,
          },
        ],
        createdAt: "2024-06-07T23:14:46.996Z",
        updatedAt: "2024-06-07T23:14:46.996Z",
      },
      {
        id: "clx5b03e401a612vc29h62pex",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 332.0.2.29.94 (iPhone14,8; iOS 16_1_1; en_US; en; scale=3.00; 1125x2436; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9rEsdxAQKx",
            locked: 1717802073,
            timestamp: 1717802071,
          },
        ],
        createdAt: "2024-06-07T23:14:34.153Z",
        updatedAt: "2024-06-07T23:14:34.153Z",
      },
      {
        id: "clx5az8xq01a512vchcb30hrn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4XVQPpWcIp",
            locked: 1717802034,
            timestamp: 1717802032,
          },
        ],
        createdAt: "2024-06-07T23:13:54.683Z",
        updatedAt: "2024-06-07T23:13:54.683Z",
      },
      {
        id: "clx5axtm101a412vccp1c3187",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NtU7j6dxVx",
            locked: 1717801967,
            timestamp: 1717801966,
          },
        ],
        createdAt: "2024-06-07T23:12:48.170Z",
        updatedAt: "2024-06-07T23:12:48.170Z",
      },
      {
        id: "clx5axl1501a312vcmv71at7w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad12,1; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "BqfkHDRDwx",
            locked: 1717801956,
            timestamp: 1717801955,
          },
        ],
        createdAt: "2024-06-07T23:12:37.047Z",
        updatedAt: "2024-06-07T23:12:37.047Z",
      },
      {
        id: "clx5axd9001a212vcasoggoq6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad12,1; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wD66frXXEx",
            locked: 1717801946,
            timestamp: 1717801945,
          },
        ],
        createdAt: "2024-06-07T23:12:26.964Z",
        updatedAt: "2024-06-07T23:12:26.964Z",
      },
      {
        id: "clx5ax4c801a112vcjp6fcv57",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 320.0.2.29.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 569998124) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VqCO7A0yvE",
            locked: 1717801934,
            timestamp: 1717801933,
          },
        ],
        createdAt: "2024-06-07T23:12:15.414Z",
        updatedAt: "2024-06-07T23:12:15.414Z",
      },
      {
        id: "clx5awcme01a012vca1y3kmuj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_5_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "X0YmE4ooVg",
            locked: 1717801898,
            timestamp: 1717801897,
          },
        ],
        createdAt: "2024-06-07T23:11:39.494Z",
        updatedAt: "2024-06-07T23:11:39.494Z",
      },
      {
        id: "clx5aw9t4019z12vcf94ar8tv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "4dv4VQ1L2L",
            locked: 1717801895,
            timestamp: 1717801894,
          },
        ],
        createdAt: "2024-06-07T23:11:35.846Z",
        updatedAt: "2024-06-07T23:11:35.846Z",
      },
      {
        id: "clx5avs7e019y12vci6kag3py",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OLNGhrcuIC",
            locked: 1717801872,
            timestamp: 1717801870,
          },
        ],
        createdAt: "2024-06-07T23:11:13.032Z",
        updatedAt: "2024-06-07T23:11:13.032Z",
      },
      {
        id: "clx5aupqv019x12vc7usdjg1z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QaKFsS5QOz",
            locked: 1717801822,
            timestamp: 1717801820,
          },
        ],
        createdAt: "2024-06-07T23:10:23.189Z",
        updatedAt: "2024-06-07T23:10:23.189Z",
      },
      {
        id: "clx5atrst019w12vc7qbi783k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kwqWmxpt8M",
            locked: 1717801789,
            timestamp: 1717801788,
          },
        ],
        createdAt: "2024-06-07T23:09:39.195Z",
        updatedAt: "2024-06-07T23:09:39.195Z",
      },
      {
        id: "clx5at950019v12vcyse3fosh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "k2yhGgHAJ3",
            locked: 1717801754,
            timestamp: 1717801753,
          },
        ],
        createdAt: "2024-06-07T23:09:15.009Z",
        updatedAt: "2024-06-07T23:09:15.009Z",
      },
      {
        id: "clx5aroks019u12vcmz8grmwm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bGukk4OVIc",
            locked: 1717801680,
            timestamp: 1717801678,
          },
        ],
        createdAt: "2024-06-07T23:08:01.698Z",
        updatedAt: "2024-06-07T23:08:01.698Z",
      },
      {
        id: "clx5ap3hr019t12vcjt8kmfnj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "whwgnoZT1W",
            locked: 1717801560,
            timestamp: 1717801559,
          },
        ],
        createdAt: "2024-06-07T23:06:01.071Z",
        updatedAt: "2024-06-07T23:06:01.071Z",
      },
      {
        id: "clx5aozwx019s12vcxu8h2kej",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "eNL9agv6Dr",
            locked: 1717801555,
            timestamp: 1717801554,
          },
        ],
        createdAt: "2024-06-07T23:05:56.430Z",
        updatedAt: "2024-06-07T23:05:56.430Z",
      },
      {
        id: "clx5aoh81019r12vcsqs7pvud",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S921U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 420dpi; 1080x2127; samsung; SM-S921U; e1q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ImmoU97Lrv",
            locked: 1717801531,
            timestamp: 1717801529,
          },
        ],
        createdAt: "2024-06-07T23:05:32.206Z",
        updatedAt: "2024-06-07T23:05:32.206Z",
      },
      {
        id: "clx5anw1a019q12vcaidnvl1x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "v8dQtXhd0F",
            locked: 1717801503,
            timestamp: 1717801502,
          },
        ],
        createdAt: "2024-06-07T23:05:04.747Z",
        updatedAt: "2024-06-07T23:05:04.747Z",
      },
      {
        id: "clx5an7ru019p12vc6rkvp117",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GqukIOB3dX",
            locked: 1717801472,
            timestamp: 1717801470,
          },
        ],
        createdAt: "2024-06-07T23:04:33.304Z",
        updatedAt: "2024-06-07T23:04:33.304Z",
      },
      {
        id: "clx5amqzd019o12vc58si8orz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "DQqYApZuUz",
            locked: 1717801451,
            timestamp: 1717801449,
          },
        ],
        createdAt: "2024-06-07T23:04:11.542Z",
        updatedAt: "2024-06-07T23:04:11.542Z",
      },
      {
        id: "clx5amd1x019n12vchpf89eni",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cZYSp5bU5A",
            locked: 1717801432,
            timestamp: 1717801427,
          },
        ],
        createdAt: "2024-06-07T23:03:53.490Z",
        updatedAt: "2024-06-07T23:03:53.490Z",
      },
      {
        id: "clx5am1ju019m12vc1usqakxl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QR7qE51XKZ",
            locked: 1717801417,
            timestamp: 1717801405,
          },
        ],
        createdAt: "2024-06-07T23:03:38.587Z",
        updatedAt: "2024-06-07T23:03:38.587Z",
      },
      {
        id: "clx5aly3b019l12vczmd2j37r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0quJxWX4tC",
            locked: 1717801413,
            timestamp: 1717801412,
          },
        ],
        createdAt: "2024-06-07T23:03:34.101Z",
        updatedAt: "2024-06-07T23:03:34.101Z",
      },
      {
        id: "clx5algcw019k12vctai33anh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G781V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 297.0.0.33.109 Android (33/13; 480dpi; 1080x2168; samsung; SM-G781V; r8q; qcom; en_US; 507536348)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IoB2vCGQtF",
            locked: 1717801390,
            timestamp: 1717801385,
          },
        ],
        createdAt: "2024-06-07T23:03:11.117Z",
        updatedAt: "2024-06-07T23:03:11.117Z",
      },
      {
        id: "clx5al59n019j12vcff4guesn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "9J1rXv9jcY",
            locked: 1717801376,
            timestamp: 1717801375,
          },
        ],
        createdAt: "2024-06-07T23:02:56.747Z",
        updatedAt: "2024-06-07T23:02:56.747Z",
      },
      {
        id: "clx5al0tb019i12vchtsvaj4m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aQp5kJoMCc",
            locked: 1717801370,
            timestamp: 1717801369,
          },
        ],
        createdAt: "2024-06-07T23:02:50.973Z",
        updatedAt: "2024-06-07T23:02:50.973Z",
      },
      {
        id: "clx5ak9id019h12vcv6s0zgxw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VFopKE3EQl",
            locked: 1717801346,
            timestamp: 1717801342,
          },
        ],
        createdAt: "2024-06-07T23:02:15.587Z",
        updatedAt: "2024-06-07T23:02:15.587Z",
      },
      {
        id: "clx5ajf38019g12vc1mhxeq9n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LhGHQBsSsi",
            locked: 1717801296,
            timestamp: 1717801292,
          },
        ],
        createdAt: "2024-06-07T23:01:36.162Z",
        updatedAt: "2024-06-07T23:01:36.162Z",
      },
      {
        id: "clx5aiwim019f12vc7b7eixix",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 317.0.4.27.109 (iPhone10,1; iOS 16_7_8; en_US; en; scale=2.00; 750x1334; 562830928)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "aMT99491rH",
            locked: 1717801271,
            timestamp: 1717801270,
          },
        ],
        createdAt: "2024-06-07T23:01:12.092Z",
        updatedAt: "2024-06-07T23:01:12.092Z",
      },
      {
        id: "clx5ais8m019e12vccrv6s83u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "zLsMoDHcne",
            locked: 1717801266,
            timestamp: 1717801265,
          },
        ],
        createdAt: "2024-06-07T23:01:06.551Z",
        updatedAt: "2024-06-07T23:01:06.551Z",
      },
      {
        id: "clx5aimc8019d12vcgroraoa9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LxkCLrN0P6",
            locked: 1717801258,
            timestamp: 1717801256,
          },
        ],
        createdAt: "2024-06-07T23:00:58.905Z",
        updatedAt: "2024-06-07T23:00:58.905Z",
      },
      {
        id: "clx5aigoi019c12vcro2sfdwz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 317.0.4.27.109 (iPhone10,1; iOS 16_7_8; en_US; en; scale=2.00; 750x1334; 562830928)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ai5r3jeLFs",
            locked: 1717801251,
            timestamp: 1717801248,
          },
        ],
        createdAt: "2024-06-07T23:00:51.568Z",
        updatedAt: "2024-06-07T23:00:51.568Z",
      },
      {
        id: "clx5ai08c019b12vcoycfsfsr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; moto g play - 2024 Build/T3TFS33.66-29-3-3; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 280dpi; 720x1436; motorola; moto g play - 2024; fogona; qcom; en_US; 606558548)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dxYtpnAcWt",
            locked: 1717801227,
            timestamp: 1717801224,
          },
        ],
        createdAt: "2024-06-07T23:00:30.247Z",
        updatedAt: "2024-06-07T23:00:30.247Z",
      },
      {
        id: "clx5agsbk019a12vcqk0hudgw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "BMVDHDN8rE",
            locked: 1717801172,
            timestamp: 1717801171,
          },
        ],
        createdAt: "2024-06-07T22:59:33.341Z",
        updatedAt: "2024-06-07T22:59:33.341Z",
      },
      {
        id: "clx5agfkt019912vc5vfs820q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "N2Di0lc967",
            locked: 1717801156,
            timestamp: 1717801154,
          },
        ],
        createdAt: "2024-06-07T22:59:16.827Z",
        updatedAt: "2024-06-07T22:59:16.827Z",
      },
      {
        id: "clx5aff7h019812vcsymcr0l2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "W8LZqB6kXR",
            locked: 1717801109,
            timestamp: 1717801107,
          },
        ],
        createdAt: "2024-06-07T22:58:29.693Z",
        updatedAt: "2024-06-07T22:58:29.693Z",
      },
      {
        id: "clx5af4r9019712vcwyrmmgmc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "im9Rk9T8hX",
            locked: 1717801095,
            timestamp: 1717801091,
          },
        ],
        createdAt: "2024-06-07T22:58:16.147Z",
        updatedAt: "2024-06-07T22:58:16.147Z",
      },
      {
        id: "clx5ae53a019612vc56qvd2at",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NsCXyXxiMC",
            locked: 1717801049,
            timestamp: 1717801048,
          },
        ],
        createdAt: "2024-06-07T22:57:29.925Z",
        updatedAt: "2024-06-07T22:57:29.925Z",
      },
      {
        id: "clx5ae30d019512vcg6eav8jm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "FL1L7gAs2L",
            locked: 1717801047,
            timestamp: 1717801046,
          },
        ],
        createdAt: "2024-06-07T22:57:27.229Z",
        updatedAt: "2024-06-07T22:57:27.229Z",
      },
      {
        id: "clx5adz8b019412vcw51g3660",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gQ4t5uAj2o",
            locked: 1717801041,
            timestamp: 1717801040,
          },
        ],
        createdAt: "2024-06-07T22:57:22.331Z",
        updatedAt: "2024-06-07T22:57:22.331Z",
      },
      {
        id: "clx5adsqa019312vc5ko8u4j5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 334.0.4.32.98 (iPhone14,5; iOS 16_1_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tXWoHueVzR",
            locked: 1717801033,
            timestamp: 1717801031,
          },
        ],
        createdAt: "2024-06-07T22:57:13.904Z",
        updatedAt: "2024-06-07T22:57:13.904Z",
      },
      {
        id: "clx5adk15019212vc9j1498ji",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kfUjEU7SoU",
            locked: 1717801021,
            timestamp: 1717801019,
          },
        ],
        createdAt: "2024-06-07T22:57:02.633Z",
        updatedAt: "2024-06-07T22:57:02.633Z",
      },
      {
        id: "clx5adhbz019112vcabf8wtxo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "rTRHEU5Zkz",
            locked: 1717801018,
            timestamp: 1717801017,
          },
        ],
        createdAt: "2024-06-07T22:56:59.135Z",
        updatedAt: "2024-06-07T22:56:59.135Z",
      },
      {
        id: "clx5addnw019012vc5jnllvjx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8UoxUzMzKH",
            locked: 1717801013,
            timestamp: 1717801012,
          },
        ],
        createdAt: "2024-06-07T22:56:54.377Z",
        updatedAt: "2024-06-07T22:56:54.377Z",
      },
      {
        id: "clx5acax4018z12vc1zhmx04n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S918U; dm3q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "osKwIM8PdR",
            locked: 1717800963,
            timestamp: 1717800961,
          },
        ],
        createdAt: "2024-06-07T22:56:04.166Z",
        updatedAt: "2024-06-07T22:56:04.166Z",
      },
      {
        id: "clx5abtqc018y12vcwdjypbwy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xdGOkn7AyY",
            locked: 1717800941,
            timestamp: 1717800939,
          },
        ],
        createdAt: "2024-06-07T22:55:41.893Z",
        updatedAt: "2024-06-07T22:55:41.893Z",
      },
      {
        id: "clx5abtco018x12vcqkij8ihx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Y96a9qIVt6",
            locked: 1717800940,
            timestamp: 1717800939,
          },
        ],
        createdAt: "2024-06-07T22:55:41.398Z",
        updatedAt: "2024-06-07T22:55:41.398Z",
      },
      {
        id: "clx5aa45t018w12vcaxjvg9f8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5ACx2pbikt",
            locked: 1717800861,
            timestamp: 1717800860,
          },
        ],
        createdAt: "2024-06-07T22:54:22.097Z",
        updatedAt: "2024-06-07T22:54:22.097Z",
      },
      {
        id: "clx5aa2pm018v12vcmalbjkhr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "YFDyttkwkC",
            locked: 1717800859,
            timestamp: 1717800858,
          },
        ],
        createdAt: "2024-06-07T22:54:20.219Z",
        updatedAt: "2024-06-07T22:54:20.219Z",
      },
      {
        id: "clx5aa1ew018u12vcvsivlfv6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "T4mOWB4obh",
            locked: 1717800856,
            timestamp: 1717800855,
          },
        ],
        createdAt: "2024-06-07T22:54:18.536Z",
        updatedAt: "2024-06-07T22:54:18.536Z",
      },
      {
        id: "clx5aa0jh018t12vcgpl5x4fs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,2; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "fJb4j03gMM",
            locked: 1717800854,
            timestamp: 1717800853,
          },
        ],
        createdAt: "2024-06-07T22:54:17.405Z",
        updatedAt: "2024-06-07T22:54:17.405Z",
      },
      {
        id: "clx5aa0ij018s12vcxwdgkgu1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,2; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CBvjaCDJ1V",
            locked: 1717800849,
            timestamp: 1717800847,
          },
        ],
        createdAt: "2024-06-07T22:54:17.371Z",
        updatedAt: "2024-06-07T22:54:17.371Z",
      },
      {
        id: "clx5a9yw8018r12vcf9ufpyz7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ehvBdRc9Ru",
            locked: 1717800854,
            timestamp: 1717800853,
          },
        ],
        createdAt: "2024-06-07T22:54:15.270Z",
        updatedAt: "2024-06-07T22:54:15.270Z",
      },
      {
        id: "clx5a9k7i018q12vcs6akljn4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; moto g 5G (2022) Build/T1SAS33.73-40-0-12-1; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (33/13; 280dpi; 720x1439; motorola; moto g 5G (2022); austin; mt6833; en_US; 604247853)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Akwxn5h88Y",
            locked: 1717800834,
            timestamp: 1717800827,
          },
        ],
        createdAt: "2024-06-07T22:53:56.238Z",
        updatedAt: "2024-06-07T22:53:56.238Z",
      },
      {
        id: "clx5a9dir018p12vclhb4axhk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_AU; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rMxqcObibl",
            locked: 1717800826,
            timestamp: 1717800825,
          },
        ],
        createdAt: "2024-06-07T22:53:47.571Z",
        updatedAt: "2024-06-07T22:53:47.571Z",
      },
      {
        id: "clx5a9d9e018o12vcks06vebq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4dEgQI9qij",
            locked: 1717800826,
            timestamp: 1717800825,
          },
        ],
        createdAt: "2024-06-07T22:53:47.231Z",
        updatedAt: "2024-06-07T22:53:47.231Z",
      },
      {
        id: "clx5a91rp018n12vc9e90tp9b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dBxE3j9ybw",
            locked: 1717800811,
            timestamp: 1717800810,
          },
        ],
        createdAt: "2024-06-07T22:53:32.341Z",
        updatedAt: "2024-06-07T22:53:32.341Z",
      },
      {
        id: "clx5a8qps018m12vch0kizvte",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S911U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 540dpi; 1080x2097; samsung; SM-S911U; dm1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xdSzOkdGSg",
            locked: 1717800796,
            timestamp: 1717800795,
          },
        ],
        createdAt: "2024-06-07T22:53:18.013Z",
        updatedAt: "2024-06-07T22:53:18.013Z",
      },
      {
        id: "clx5a7vmn018l12vc1z35ywqz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2131; samsung; SM-S906U1; g0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PKTuCar9hP",
            locked: 1717800753,
            timestamp: 1717800747,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "4nwrZEeLPf",
            locked: 1717800753,
            timestamp: 1717800751,
          },
        ],
        createdAt: "2024-06-07T22:52:37.724Z",
        updatedAt: "2024-06-07T22:52:37.724Z",
      },
      {
        id: "clx5a6p5g018k12vcgv6wjfsu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "e533bKyEp9",
            locked: 1717800702,
            timestamp: 1717800700,
          },
        ],
        createdAt: "2024-06-07T22:51:42.673Z",
        updatedAt: "2024-06-07T22:51:42.673Z",
      },
      {
        id: "clx5a55sm018j12vccdnarj74",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "AmnuYbO3Tw",
            locked: 1717800631,
            timestamp: 1717800630,
          },
        ],
        createdAt: "2024-06-07T22:50:30.935Z",
        updatedAt: "2024-06-07T22:50:30.935Z",
      },
      {
        id: "clx5a525p018i12vcsrmvq9oi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "e0aor97oAf",
            locked: 1717800626,
            timestamp: 1717800620,
          },
        ],
        createdAt: "2024-06-07T22:50:26.218Z",
        updatedAt: "2024-06-07T22:50:26.218Z",
      },
      {
        id: "clx5a351p018h12vco6cevfm3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "h2330INE8v",
            locked: 1717800534,
            timestamp: 1717800533,
          },
        ],
        createdAt: "2024-06-07T22:48:56.652Z",
        updatedAt: "2024-06-07T22:48:56.652Z",
      },
      {
        id: "clx5a2xq8018g12vcmskzsok4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N976V Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 420dpi; 1080x2069; samsung; SM-N976V; d2xq; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "IzlxjcZ81R",
            locked: 1717800525,
            timestamp: 1717800521,
          },
        ],
        createdAt: "2024-06-07T22:48:47.168Z",
        updatedAt: "2024-06-07T22:48:47.168Z",
      },
      {
        id: "clx5a2vkx018f12vcv9ge5s7d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "rpO3j4qy9D",
            locked: 1717800524,
            timestamp: 1717800523,
          },
        ],
        createdAt: "2024-06-07T22:48:44.385Z",
        updatedAt: "2024-06-07T22:48:44.385Z",
      },
      {
        id: "clx5a2pyv018e12vckx9u3dmv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "gtmLnyQ5aD",
            locked: 1717800517,
            timestamp: 1717800516,
          },
        ],
        createdAt: "2024-06-07T22:48:37.111Z",
        updatedAt: "2024-06-07T22:48:37.111Z",
      },
      {
        id: "clx5a2op0018d12vcn4mhht6e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "XjDV4VydC3",
            locked: 1717800515,
            timestamp: 1717800514,
          },
        ],
        createdAt: "2024-06-07T22:48:35.458Z",
        updatedAt: "2024-06-07T22:48:35.458Z",
      },
      {
        id: "clx5a26mc018c12vczgat41em",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "V87U8IVOQO",
            locked: 1717800491,
            timestamp: 1717800490,
          },
        ],
        createdAt: "2024-06-07T22:48:12.037Z",
        updatedAt: "2024-06-07T22:48:12.037Z",
      },
      {
        id: "clx5a1ycf018b12vc4wmff3wc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad7,5; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "sHg7ywZfiP",
            locked: 1717800481,
            timestamp: 1717800480,
          },
        ],
        createdAt: "2024-06-07T22:48:01.309Z",
        updatedAt: "2024-06-07T22:48:01.309Z",
      },
      {
        id: "clx5a1m3h018a12vcytfi6441",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad7,5; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "T25quPE7UH",
            locked: 1717800464,
            timestamp: 1717800458,
          },
        ],
        createdAt: "2024-06-07T22:47:45.438Z",
        updatedAt: "2024-06-07T22:47:45.438Z",
      },
      {
        id: "clx5a1l0r018912vczjy9a9g7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UYrwK75EiF",
            locked: 1717800463,
            timestamp: 1717800462,
          },
        ],
        createdAt: "2024-06-07T22:47:44.041Z",
        updatedAt: "2024-06-07T22:47:44.041Z",
      },
      {
        id: "clx5a19d9018812vc1j6fytmz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "jvjVWe4Kvb",
            locked: 1717800448,
            timestamp: 1717800447,
          },
        ],
        createdAt: "2024-06-07T22:47:28.941Z",
        updatedAt: "2024-06-07T22:47:28.941Z",
      },
      {
        id: "clx5a17zb018712vcna7b5qoe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "k87UOyMY0w",
            locked: 1717800446,
            timestamp: 1717800445,
          },
        ],
        createdAt: "2024-06-07T22:47:27.144Z",
        updatedAt: "2024-06-07T22:47:27.144Z",
      },
      {
        id: "clx5a101q018612vc3br26khc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MauEiKLGWG",
            locked: 1717800436,
            timestamp: 1717800435,
          },
        ],
        createdAt: "2024-06-07T22:47:16.860Z",
        updatedAt: "2024-06-07T22:47:16.860Z",
      },
      {
        id: "clx5a04c9018512vc64wgix87",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,3; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0o8TzUECsG",
            locked: 1717800395,
            timestamp: 1717800392,
          },
        ],
        createdAt: "2024-06-07T22:46:35.767Z",
        updatedAt: "2024-06-07T22:46:35.767Z",
      },
      {
        id: "clx59z8w6018412vcn96885nx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "SRcLJ4apin",
            locked: 1717800354,
            timestamp: 1717800353,
          },
        ],
        createdAt: "2024-06-07T22:45:55.014Z",
        updatedAt: "2024-06-07T22:45:55.014Z",
      },
      {
        id: "clx59z4hf018312vceqtjfw7h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fdk5GQyjyc",
            locked: 1717800348,
            timestamp: 1717800347,
          },
        ],
        createdAt: "2024-06-07T22:45:49.297Z",
        updatedAt: "2024-06-07T22:45:49.297Z",
      },
      {
        id: "clx59yehp018212vc4ycqtub6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-G990U; r9q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0ZffZipPyz",
            locked: 1717800315,
            timestamp: 1717800313,
          },
        ],
        createdAt: "2024-06-07T22:45:15.613Z",
        updatedAt: "2024-06-07T22:45:15.613Z",
      },
      {
        id: "clx59y6i3018112vc8gna3fka",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "8ES6l9VUWh",
            locked: 1717800304,
            timestamp: 1717800303,
          },
        ],
        createdAt: "2024-06-07T22:45:05.258Z",
        updatedAt: "2024-06-07T22:45:05.258Z",
      },
      {
        id: "clx59y5cn018012vckncq2tqy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-A215U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 300dpi; 720x1437; samsung; SM-A215U; a21; mt6765; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0zzub5LR4z",
            locked: 1717800306,
            timestamp: 1717800289,
          },
        ],
        createdAt: "2024-06-07T22:45:03.765Z",
        updatedAt: "2024-06-07T22:45:03.765Z",
      },
      {
        id: "clx59xyzo017z12vc3r8gsajz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "qvzdNg3mq1",
            locked: 1717800295,
            timestamp: 1717800294,
          },
        ],
        createdAt: "2024-06-07T22:44:55.525Z",
        updatedAt: "2024-06-07T22:44:55.525Z",
      },
      {
        id: "clx59xslt017y12vc4bg6tmyb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "SgBOOMONc6",
            locked: 1717800287,
            timestamp: 1717800286,
          },
        ],
        createdAt: "2024-06-07T22:44:47.249Z",
        updatedAt: "2024-06-07T22:44:47.249Z",
      },
      {
        id: "clx59xscg017x12vc5jubovnw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gjtGekIkXB",
            locked: 1717800286,
            timestamp: 1717800285,
          },
        ],
        createdAt: "2024-06-07T22:44:46.912Z",
        updatedAt: "2024-06-07T22:44:46.912Z",
      },
      {
        id: "clx59xsa1017w12vca12y22tz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MUxVdid3Cp",
            locked: 1717800286,
            timestamp: 1717800284,
          },
        ],
        createdAt: "2024-06-07T22:44:46.825Z",
        updatedAt: "2024-06-07T22:44:46.825Z",
      },
      {
        id: "clx59xo9b017v12vciun5ro38",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jtmTTggIZI",
            locked: 1717800281,
            timestamp: 1717800279,
          },
        ],
        createdAt: "2024-06-07T22:44:41.614Z",
        updatedAt: "2024-06-07T22:44:41.614Z",
      },
      {
        id: "clx59xkqw017u12vcl4khvpfg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3Lx4N4DLib",
            locked: 1717800276,
            timestamp: 1717800270,
          },
        ],
        createdAt: "2024-06-07T22:44:37.064Z",
        updatedAt: "2024-06-07T22:44:37.064Z",
      },
      {
        id: "clx59xd2g017t12vc26ojtdzf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4l8rS7YyWH",
            locked: 1717800266,
            timestamp: 1717800265,
          },
        ],
        createdAt: "2024-06-07T22:44:27.112Z",
        updatedAt: "2024-06-07T22:44:27.112Z",
      },
      {
        id: "clx59x9ax017s12vcoley70nt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 334.0.4.32.98 (iPhone14,3; iOS 16_1_1; en_GB; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4RfnJxDDOJ",
            locked: 1717800262,
            timestamp: 1717800260,
          },
        ],
        createdAt: "2024-06-07T22:44:22.230Z",
        updatedAt: "2024-06-07T22:44:22.230Z",
      },
      {
        id: "clx59wt5m017r12vcuof9yn79",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "hbFpnwFafk",
            locked: 1717800241,
            timestamp: 1717800240,
          },
        ],
        createdAt: "2024-06-07T22:44:01.306Z",
        updatedAt: "2024-06-07T22:44:01.306Z",
      },
      {
        id: "clx59wnex017q12vcohyvd0ai",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "70Wh08z1e5",
            locked: 1717800233,
            timestamp: 1717800231,
          },
        ],
        createdAt: "2024-06-07T22:43:53.863Z",
        updatedAt: "2024-06-07T22:43:53.863Z",
      },
      {
        id: "clx59w1he017p12vcru91rnrs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Apmy9AyHBM",
            locked: 1717800205,
            timestamp: 1717800201,
          },
        ],
        createdAt: "2024-06-07T22:43:25.443Z",
        updatedAt: "2024-06-07T22:43:25.443Z",
      },
      {
        id: "clx59vr2y017o12vcmvstnzk7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7fo5tvMvIK",
            locked: 1717800191,
            timestamp: 1717800189,
          },
        ],
        createdAt: "2024-06-07T22:43:11.960Z",
        updatedAt: "2024-06-07T22:43:11.960Z",
      },
      {
        id: "clx59v1rb017n12vce6g15szm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qF7YJhSn1j",
            locked: 1717800158,
            timestamp: 1717800156,
          },
        ],
        createdAt: "2024-06-07T22:42:39.140Z",
        updatedAt: "2024-06-07T22:42:39.140Z",
      },
      {
        id: "clx59u43u017m12vcderlcb59",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "T1VZkcxY5I",
            locked: 1717800115,
            timestamp: 1717800114,
          },
        ],
        createdAt: "2024-06-07T22:41:55.529Z",
        updatedAt: "2024-06-07T22:41:55.529Z",
      },
      {
        id: "clx59tw7s017l12vcscpgxvbu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DYde58YDFj",
            locked: 1717800104,
            timestamp: 1717800103,
          },
        ],
        createdAt: "2024-06-07T22:41:45.305Z",
        updatedAt: "2024-06-07T22:41:45.305Z",
      },
      {
        id: "clx59ttpn017k12vceb05nsta",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ApyAdoP9Hg",
            locked: 1717800101,
            timestamp: 1717800100,
          },
        ],
        createdAt: "2024-06-07T22:41:42.060Z",
        updatedAt: "2024-06-07T22:41:42.060Z",
      },
      {
        id: "clx59tt6p017j12vc5nyqw3w9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "rsJfprTNyp",
            locked: 1717800101,
            timestamp: 1717800099,
          },
        ],
        createdAt: "2024-06-07T22:41:41.377Z",
        updatedAt: "2024-06-07T22:41:41.377Z",
      },
      {
        id: "clx59tol3017i12vck1valyzb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tvAXoFF4I2",
            locked: 1717800095,
            timestamp: 1717800093,
          },
        ],
        createdAt: "2024-06-07T22:41:35.413Z",
        updatedAt: "2024-06-07T22:41:35.413Z",
      },
      {
        id: "clx59th3z017h12vcawflqkbc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Sgi6FlV2xI",
            locked: 1717800085,
            timestamp: 1717800083,
          },
        ],
        createdAt: "2024-06-07T22:41:25.727Z",
        updatedAt: "2024-06-07T22:41:25.727Z",
      },
      {
        id: "clx59tba7017g12vccis2h3cv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "G2zRSy7DaR",
            locked: 1717800077,
            timestamp: 1717800076,
          },
        ],
        createdAt: "2024-06-07T22:41:18.173Z",
        updatedAt: "2024-06-07T22:41:18.173Z",
      },
      {
        id: "clx59su6d017f12vcy5sv1px6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "UCCdR5uCgl",
            locked: 1717800055,
            timestamp: 1717800054,
          },
        ],
        createdAt: "2024-06-07T22:40:56.005Z",
        updatedAt: "2024-06-07T22:40:56.005Z",
      },
      {
        id: "clx59sr6v017e12vcqdlz1c4k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0Xi5HnEkUJ",
            locked: 1717800051,
            timestamp: 1717800050,
          },
        ],
        createdAt: "2024-06-07T22:40:52.133Z",
        updatedAt: "2024-06-07T22:40:52.133Z",
      },
      {
        id: "clx59s9tt017d12vcnn3mnzwl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G973U1 Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.53 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 420dpi; 1080x2042; samsung; SM-G973U1; beyond1q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NPIaGNV4pv",
            locked: 1717800029,
            timestamp: 1717800021,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "QHOc0AblO3",
            locked: 1717800029,
            timestamp: 1717800028,
          },
        ],
        createdAt: "2024-06-07T22:40:29.631Z",
        updatedAt: "2024-06-07T22:40:29.631Z",
      },
      {
        id: "clx59quzg017c12vcpowklpv2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1vHXafdXOj",
            locked: 1717799963,
            timestamp: 1717799961,
          },
        ],
        createdAt: "2024-06-07T22:39:23.738Z",
        updatedAt: "2024-06-07T22:39:23.738Z",
      },
      {
        id: "clx59qgxu017b12vcr2kt54xe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "3zy9cby22F",
            locked: 1717799945,
            timestamp: 1717799944,
          },
        ],
        createdAt: "2024-06-07T22:39:05.539Z",
        updatedAt: "2024-06-07T22:39:05.539Z",
      },
      {
        id: "clx59qffo017a12vc7w9hih5z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "sfCc2YDjJr",
            locked: 1717799943,
            timestamp: 1717799941,
          },
        ],
        createdAt: "2024-06-07T22:39:03.586Z",
        updatedAt: "2024-06-07T22:39:03.586Z",
      },
      {
        id: "clx59omic017912vcyvgads2a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "9sEX1PRaCw",
            locked: 1717799859,
            timestamp: 1717799858,
          },
        ],
        createdAt: "2024-06-07T22:37:39.444Z",
        updatedAt: "2024-06-07T22:37:39.444Z",
      },
      {
        id: "clx59olzw017812vcvfc8fcvm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A146U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 540dpi; 1080x2181; samsung; SM-A146U; a14xm; mt6833; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "hckpqRcSIq",
            locked: 1717799858,
            timestamp: 1717799857,
          },
        ],
        createdAt: "2024-06-07T22:37:38.780Z",
        updatedAt: "2024-06-07T22:37:38.780Z",
      },
      {
        id: "clx59oixg017712vcv5eunyti",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A146U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 540dpi; 1080x2181; samsung; SM-A146U; a14xm; mt6833; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "36940ls3eJ",
            locked: 1717799854,
            timestamp: 1717799848,
          },
        ],
        createdAt: "2024-06-07T22:37:34.801Z",
        updatedAt: "2024-06-07T22:37:34.801Z",
      },
      {
        id: "clx59n98j017612vc2g99f3jq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bxpuduWy0C",
            locked: 1717799795,
            timestamp: 1717799793,
          },
        ],
        createdAt: "2024-06-07T22:36:35.587Z",
        updatedAt: "2024-06-07T22:36:35.587Z",
      },
      {
        id: "clx59n7l3017512vcvrdgaj8m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 327.1.6.30.88 (iPhone15,4; iOS 17_4_1; en_US; en; scale=3.00; 960x2079; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uekQnMGo5p",
            locked: 1717799792,
            timestamp: 1717799790,
          },
        ],
        createdAt: "2024-06-07T22:36:33.444Z",
        updatedAt: "2024-06-07T22:36:33.444Z",
      },
      {
        id: "clx59moje017412vclbhcr7wx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "83XIpB3m6o",
            locked: 1717799768,
            timestamp: 1717799766,
          },
        ],
        createdAt: "2024-06-07T22:36:08.763Z",
        updatedAt: "2024-06-07T22:36:08.763Z",
      },
      {
        id: "clx59mmse017312vco6kq23l9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.13; rv:109.0) Gecko/20100101 Firefox/115.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nvPeDhXU82",
            locked: 1717799765,
            timestamp: 1717799763,
          },
        ],
        createdAt: "2024-06-07T22:36:06.494Z",
        updatedAt: "2024-06-07T22:36:06.494Z",
      },
      {
        id: "clx59mkz4017212vcco8mnbuh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HtN0W7COGd",
            locked: 1717799763,
            timestamp: 1717799761,
          },
        ],
        createdAt: "2024-06-07T22:36:04.141Z",
        updatedAt: "2024-06-07T22:36:04.141Z",
      },
      {
        id: "clx59loy3017112vcbnysbdw6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Br1SQFK0Gn",
            locked: 1717799722,
            timestamp: 1717799721,
          },
        ],
        createdAt: "2024-06-07T22:35:22.635Z",
        updatedAt: "2024-06-07T22:35:22.635Z",
      },
      {
        id: "clx59ln8l017012vcgbnyhzwa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xSVN3M34Pk",
            locked: 1717799719,
            timestamp: 1717799718,
          },
        ],
        createdAt: "2024-06-07T22:35:20.421Z",
        updatedAt: "2024-06-07T22:35:20.421Z",
      },
      {
        id: "clx59lma0016z12vcyd3tzi5n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "P8btzUQXNL",
            locked: 1717799719,
            timestamp: 1717799718,
          },
        ],
        createdAt: "2024-06-07T22:35:19.176Z",
        updatedAt: "2024-06-07T22:35:19.176Z",
      },
      {
        id: "clx59ljn3016y12vc3va16ago",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E219 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_4; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XZUhbV7Hqk",
            locked: 1717799714,
            timestamp: 1717799713,
          },
        ],
        createdAt: "2024-06-07T22:35:15.759Z",
        updatedAt: "2024-06-07T22:35:15.759Z",
      },
      {
        id: "clx59ldkm016x12vc397ggl2o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A536U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2177; samsung; SM-A536U; a53x; s5e8825; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VJHrWbvYAq",
            locked: 1717799706,
            timestamp: 1717799697,
          },
        ],
        createdAt: "2024-06-07T22:35:07.893Z",
        updatedAt: "2024-06-07T22:35:07.893Z",
      },
      {
        id: "clx59l6if016w12vcb5xpe59g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ziPUNdpG2O",
            locked: 1717799698,
            timestamp: 1717799697,
          },
        ],
        createdAt: "2024-06-07T22:34:58.744Z",
        updatedAt: "2024-06-07T22:34:58.744Z",
      },
      {
        id: "clx59l49n016v12vcyt5fuo60",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pK3bP8i7B1",
            locked: 1717799695,
            timestamp: 1717799693,
          },
        ],
        createdAt: "2024-06-07T22:34:55.835Z",
        updatedAt: "2024-06-07T22:34:55.835Z",
      },
      {
        id: "clx59kv12016u12vcichj5nlv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dDnT8qcnwY",
            locked: 1717799683,
            timestamp: 1717799681,
          },
        ],
        createdAt: "2024-06-07T22:34:43.860Z",
        updatedAt: "2024-06-07T22:34:43.860Z",
      },
      {
        id: "clx59kg62016t12vcggr4tjkx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D67 Instagram 334.0.4.32.98 (iPhone15,3; iOS 16_3_1; en_CA; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "towO7JKOV9",
            locked: 1717799664,
            timestamp: 1717799663,
          },
        ],
        createdAt: "2024-06-07T22:34:24.603Z",
        updatedAt: "2024-06-07T22:34:24.603Z",
      },
      {
        id: "clx59k95v016s12vc4im79kme",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-F936B Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 904x2103; samsung; SM-F936B; q4q; qcom; en_AU; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Uv0aDHRZQI",
            locked: 1717799654,
            timestamp: 1717799652,
          },
        ],
        createdAt: "2024-06-07T22:34:15.520Z",
        updatedAt: "2024-06-07T22:34:15.520Z",
      },
      {
        id: "clx59jtr7016r12vccf5ac3yp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "wCcFeYK38r",
            locked: 1717799635,
            timestamp: 1717799634,
          },
        ],
        createdAt: "2024-06-07T22:33:55.554Z",
        updatedAt: "2024-06-07T22:33:55.554Z",
      },
      {
        id: "clx59jitv016q12vcjcd9wixf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "73UQzkG7wO",
            locked: 1717799620,
            timestamp: 1717799619,
          },
        ],
        createdAt: "2024-06-07T22:33:41.396Z",
        updatedAt: "2024-06-07T22:33:41.396Z",
      },
      {
        id: "clx59jhnt016p12vcuugztnvi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D67 Instagram 334.0.4.32.98 (iPhone15,3; iOS 16_3_1; en_CA; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PtIUC07RdD",
            locked: 1717799619,
            timestamp: 1717799618,
          },
        ],
        createdAt: "2024-06-07T22:33:39.879Z",
        updatedAt: "2024-06-07T22:33:39.879Z",
      },
      {
        id: "clx59ivzw016o12vcrp45ky1p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "uvphHALtLk",
            locked: 1717799589,
            timestamp: 1717799588,
          },
        ],
        createdAt: "2024-06-07T22:33:11.805Z",
        updatedAt: "2024-06-07T22:33:11.805Z",
      },
      {
        id: "clx59iura016n12vcuemtk7xv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "irOhoKh981",
            locked: 1717799589,
            timestamp: 1717799587,
          },
        ],
        createdAt: "2024-06-07T22:33:10.198Z",
        updatedAt: "2024-06-07T22:33:10.198Z",
      },
      {
        id: "clx59inwj016m12vc5ia0wfvy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 BytedanceWebview/d8a21c6 musical_ly_34.7.1 JsSdk/2.0 NetType/WIFI Channel/App Store ByteLocale/en Region/US FalconTag/611EF4C3-AFD5-491C-A2EA-0C610E6DD76F",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "muqSB9uHOR",
            locked: 1717799581,
            timestamp: 1717799580,
          },
        ],
        createdAt: "2024-06-07T22:33:01.315Z",
        updatedAt: "2024-06-07T22:33:01.315Z",
      },
      {
        id: "clx59ikzx016l12vcqyoh9h60",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 BytedanceWebview/d8a21c6 musical_ly_34.7.1 JsSdk/2.0 NetType/WIFI Channel/App Store ByteLocale/en Region/US FalconTag/611EF4C3-AFD5-491C-A2EA-0C610E6DD76F",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ejb0Iuwgyn",
            locked: 1717799577,
            timestamp: 1717799576,
          },
        ],
        createdAt: "2024-06-07T22:32:57.546Z",
        updatedAt: "2024-06-07T22:32:57.546Z",
      },
      {
        id: "clx59gbjs016k12vc4mcza74o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 640x1136; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Ly0FNlgWnf",
            locked: 1717799471,
            timestamp: 1717799469,
          },
        ],
        createdAt: "2024-06-07T22:31:11.992Z",
        updatedAt: "2024-06-07T22:31:11.992Z",
      },
      {
        id: "clx59gaa9016j12vcqkqu8y61",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4Nc8gw6s8X",
            locked: 1717799469,
            timestamp: 1717799468,
          },
        ],
        createdAt: "2024-06-07T22:31:10.354Z",
        updatedAt: "2024-06-07T22:31:10.354Z",
      },
      {
        id: "clx59g3cu016i12vcrs99k0ly",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ieTFFFKlWZ",
            locked: 1717799461,
            timestamp: 1717799460,
          },
        ],
        createdAt: "2024-06-07T22:31:01.373Z",
        updatedAt: "2024-06-07T22:31:01.373Z",
      },
      {
        id: "clx59etde016h12vc29dx74hn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9sD87oEZ74",
            locked: 1717799400,
            timestamp: 1717799399,
          },
        ],
        createdAt: "2024-06-07T22:30:01.775Z",
        updatedAt: "2024-06-07T22:30:01.775Z",
      },
      {
        id: "clx59ecus016g12vcsivj19gv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tnTQRYtcK4",
            locked: 1717799379,
            timestamp: 1717799377,
          },
        ],
        createdAt: "2024-06-07T22:29:40.370Z",
        updatedAt: "2024-06-07T22:29:40.370Z",
      },
      {
        id: "clx59djr0016f12vcfybrxcdy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "SupportsFresco=1 modular=3 Dalvik/2.1.0 (Linux; U; Android 12; TECNO BF6 Build/SP1A.210812.001) [FBAN/EMA;FBBV/603919436;FBAV/409.0.0.8.114;FBDV/TECNO BF6;FBSV/12;FBCX/OkHttp3;FBDM/{density=2.0}]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ebyjqdtGUP",
            locked: 1717799342,
            timestamp: 1717799338,
          },
        ],
        createdAt: "2024-06-07T22:29:02.649Z",
        updatedAt: "2024-06-07T22:29:02.649Z",
      },
      {
        id: "clx59cgwb016e12vci07kry1d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xITDHOwkea",
            locked: 1717799291,
            timestamp: 1717799289,
          },
        ],
        createdAt: "2024-06-07T22:28:12.300Z",
        updatedAt: "2024-06-07T22:28:12.300Z",
      },
      {
        id: "clx59c99t016d12vcyhjfz3u3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "vjSp4NXspx",
            locked: 1717799282,
            timestamp: 1717799281,
          },
        ],
        createdAt: "2024-06-07T22:28:02.418Z",
        updatedAt: "2024-06-07T22:28:02.418Z",
      },
      {
        id: "clx59c66b016c12vc3d6b70om",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "uI0VFwVvdn",
            locked: 1717799275,
            timestamp: 1717799273,
          },
        ],
        createdAt: "2024-06-07T22:27:58.398Z",
        updatedAt: "2024-06-07T22:27:58.398Z",
      },
      {
        id: "clx59bjwl016b12vccid6d3k7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 330.0.3.29.91 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "mypMrOZi0y",
            locked: 1717799249,
            timestamp: 1717799248,
          },
        ],
        createdAt: "2024-06-07T22:27:29.541Z",
        updatedAt: "2024-06-07T22:27:29.541Z",
      },
      {
        id: "clx59bfph016a12vc8lvmwof0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 330.0.3.29.91 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "sP1lpTGrX6",
            locked: 1717799243,
            timestamp: 1717799240,
          },
        ],
        createdAt: "2024-06-07T22:27:24.099Z",
        updatedAt: "2024-06-07T22:27:24.099Z",
      },
      {
        id: "clx59aqb7016912vc099nm9fb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 330.0.3.29.91 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MFChRJ8Civ",
            locked: 1717799210,
            timestamp: 1717799209,
          },
        ],
        createdAt: "2024-06-07T22:26:51.187Z",
        updatedAt: "2024-06-07T22:26:51.187Z",
      },
      {
        id: "clx59afij016812vcl0pqzp2l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XwyyFFxZTE",
            locked: 1717799196,
            timestamp: 1717799195,
          },
        ],
        createdAt: "2024-06-07T22:26:37.193Z",
        updatedAt: "2024-06-07T22:26:37.193Z",
      },
      {
        id: "clx59a4zl016712vco3vwmao5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "rGjtY91yXC",
            locked: 1717799183,
            timestamp: 1717799182,
          },
        ],
        createdAt: "2024-06-07T22:26:23.553Z",
        updatedAt: "2024-06-07T22:26:23.553Z",
      },
      {
        id: "clx59a1fe016612vc5pn9a0du",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "eTYI47GolM",
            locked: 1717799178,
            timestamp: 1717799177,
          },
        ],
        createdAt: "2024-06-07T22:26:18.936Z",
        updatedAt: "2024-06-07T22:26:18.936Z",
      },
      {
        id: "clx599vez016512vcb2w8s5i3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; motorola one 5G UW Build/RPNS31.Q1-51-30-14-2; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (30/11; 540dpi; 1080x2324; motorola; motorola one 5G UW; nairo; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GZx8UuyfQc",
            locked: 1717799171,
            timestamp: 1717799168,
          },
        ],
        createdAt: "2024-06-07T22:26:11.147Z",
        updatedAt: "2024-06-07T22:26:11.147Z",
      },
      {
        id: "clx599sil016412vckzfek7tv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FxQHOk0KVI",
            locked: 1717799167,
            timestamp: 1717799164,
          },
        ],
        createdAt: "2024-06-07T22:26:07.389Z",
        updatedAt: "2024-06-07T22:26:07.389Z",
      },
      {
        id: "clx599pax016312vckpos5zoq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HsYemO0Ct4",
            locked: 1717799162,
            timestamp: 1717799161,
          },
        ],
        createdAt: "2024-06-07T22:26:03.225Z",
        updatedAt: "2024-06-07T22:26:03.225Z",
      },
      {
        id: "clx599mow016212vctx51ivlo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F946U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1812x2050; samsung; SM-F946U1; q5q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DBLRIuzJXO",
            locked: 1717799158,
            timestamp: 1717799156,
          },
        ],
        createdAt: "2024-06-07T22:25:59.837Z",
        updatedAt: "2024-06-07T22:25:59.837Z",
      },
      {
        id: "clx596sum016112vchz6gx1yy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Wy7mXePNYQ",
            locked: 1717799027,
            timestamp: 1717799025,
          },
        ],
        createdAt: "2024-06-07T22:23:47.852Z",
        updatedAt: "2024-06-07T22:23:47.852Z",
      },
      {
        id: "clx595i8g016012vcp3akc7br",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E219 Instagram 330.0.3.29.91 (iPhone14,7; iOS 17_4; es_US; es; scale=3.00; 1170x2532; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "18zqJva50h",
            locked: 1717798966,
            timestamp: 1717798964,
          },
        ],
        createdAt: "2024-06-07T22:22:47.438Z",
        updatedAt: "2024-06-07T22:22:47.438Z",
      },
      {
        id: "clx59522q015z12vcqtqwnrty",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3CZ0rhh0f3",
            locked: 1717798944,
            timestamp: 1717798941,
          },
        ],
        createdAt: "2024-06-07T22:22:26.496Z",
        updatedAt: "2024-06-07T22:22:26.496Z",
      },
      {
        id: "clx594tny015y12vclv4ektz8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "81EEJ9FOBB",
            locked: 1717798934,
            timestamp: 1717798933,
          },
        ],
        createdAt: "2024-06-07T22:22:15.599Z",
        updatedAt: "2024-06-07T22:22:15.599Z",
      },
      {
        id: "clx594m33015x12vctwvavpfz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rflP7qVBEB",
            locked: 1717798925,
            timestamp: 1717798924,
          },
        ],
        createdAt: "2024-06-07T22:22:05.774Z",
        updatedAt: "2024-06-07T22:22:05.774Z",
      },
      {
        id: "clx594gab015w12vc40u6nbur",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 324.0.4.26.52 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 960x2079; 580117525) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kYnwsAf5z6",
            locked: 1717798918,
            timestamp: 1717798916,
          },
        ],
        createdAt: "2024-06-07T22:21:58.259Z",
        updatedAt: "2024-06-07T22:21:58.259Z",
      },
      {
        id: "clx5944q9015v12vcoy5o9khd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3xvtPXpaIp",
            locked: 1717798902,
            timestamp: 1717798900,
          },
        ],
        createdAt: "2024-06-07T22:21:43.279Z",
        updatedAt: "2024-06-07T22:21:43.279Z",
      },
      {
        id: "clx593pto015u12vc65rd9uyo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YptwSCl7d7",
            locked: 1717798883,
            timestamp: 1717798881,
          },
        ],
        createdAt: "2024-06-07T22:21:23.961Z",
        updatedAt: "2024-06-07T22:21:23.961Z",
      },
      {
        id: "clx5935g9015t12vc9dvlkpar",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 324.0.4.26.52 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 960x2079; 580117525) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "X7cpCd0Ygp",
            locked: 1717798857,
            timestamp: 1717798855,
          },
        ],
        createdAt: "2024-06-07T22:20:57.561Z",
        updatedAt: "2024-06-07T22:20:57.561Z",
      },
      {
        id: "clx593469015s12vc46xelgfe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21B91 Instagram 333.1.4.32.95 (iPhone12,1; iOS 17_1_1; en_US; en; scale=2.00; 828x1792; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7XSwDO1C1n",
            locked: 1717798854,
            timestamp: 1717798853,
          },
        ],
        createdAt: "2024-06-07T22:20:55.903Z",
        updatedAt: "2024-06-07T22:20:55.903Z",
      },
      {
        id: "clx591puo015r12vcogbr58nq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "oCe6sjZkil",
            locked: 1717798790,
            timestamp: 1717798784,
          },
        ],
        createdAt: "2024-06-07T22:19:50.686Z",
        updatedAt: "2024-06-07T22:19:50.686Z",
      },
      {
        id: "clx58zqg3015q12vcqtkg4lsn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "aPAUo0xAES",
            locked: 1717798697,
            timestamp: 1717798696,
          },
        ],
        createdAt: "2024-06-07T22:18:18.145Z",
        updatedAt: "2024-06-07T22:18:18.145Z",
      },
      {
        id: "clx58yf6y015p12vch0dkuzfb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "B1g5jS17I1",
            locked: 1717798636,
            timestamp: 1717798634,
          },
        ],
        createdAt: "2024-06-07T22:17:16.906Z",
        updatedAt: "2024-06-07T22:17:16.906Z",
      },
      {
        id: "clx58y7lr015o12vcqprbsajj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "I47Aog1RR0",
            locked: 1717798626,
            timestamp: 1717798625,
          },
        ],
        createdAt: "2024-06-07T22:17:07.069Z",
        updatedAt: "2024-06-07T22:17:07.069Z",
      },
      {
        id: "clx58xrtt015n12vcfjh6v5j5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 295.0.0.16.63 (iPhone14,3; iOS 16_1_1; en_US; en; scale=3.00; 1284x2778; 501817603)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MVf0vSTsub",
            locked: 1717798605,
            timestamp: 1717798603,
          },
        ],
        createdAt: "2024-06-07T22:16:46.623Z",
        updatedAt: "2024-06-07T22:16:46.623Z",
      },
      {
        id: "clx58xh9p015m12vcdhf3njch",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 326.0.3.30.91 (iPhone14,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 585196636)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GjVrOTOHyA",
            locked: 1717798592,
            timestamp: 1717798590,
          },
        ],
        createdAt: "2024-06-07T22:16:32.940Z",
        updatedAt: "2024-06-07T22:16:32.940Z",
      },
      {
        id: "clx58xezm015l12vc2fx8pni5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "yZnpRt4noc",
            locked: 1717798588,
            timestamp: 1717798587,
          },
        ],
        createdAt: "2024-06-07T22:16:29.986Z",
        updatedAt: "2024-06-07T22:16:29.986Z",
      },
      {
        id: "clx58xexc015k12vc10kt99t9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bB668hEIAR",
            locked: 1717798580,
            timestamp: 1717798573,
          },
        ],
        createdAt: "2024-06-07T22:16:29.901Z",
        updatedAt: "2024-06-07T22:16:29.901Z",
      },
      {
        id: "clx58x2lg015j12vclhchckzr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-A015M Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.83 Mobile Safari/537.36 [FBAN/EMA;FBLC/en_US;FBAV/409.0.0.8.114;FB_FW/1;FBDM/DisplayMetrics{density=2.0, width=720, height=1365, scaledDensity=2.0, xdpi=320.842, ydpi=319.074};]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vripKE2dL4",
            locked: 1717798573,
            timestamp: 1717798568,
          },
        ],
        createdAt: "2024-06-07T22:16:13.921Z",
        updatedAt: "2024-06-07T22:16:13.921Z",
      },
      {
        id: "clx58vxvj015i12vcbsyk6zwg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A716U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2183; samsung; SM-A716U; a71xq; qcom; en_US; 606558408)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LCUJgBSElt",
            locked: 1717798520,
            timestamp: 1717798516,
          },
        ],
        createdAt: "2024-06-07T22:15:21.149Z",
        updatedAt: "2024-06-07T22:15:21.149Z",
      },
      {
        id: "clx58vf46015h12vc9f3399na",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Lorxgi70Hy",
            locked: 1717798497,
            timestamp: 1717798495,
          },
        ],
        createdAt: "2024-06-07T22:14:56.838Z",
        updatedAt: "2024-06-07T22:14:56.838Z",
      },
      {
        id: "clx58vf36015g12vctbso4gol",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "vy6nl1pRyy",
            locked: 1717798498,
            timestamp: 1717798497,
          },
        ],
        createdAt: "2024-06-07T22:14:56.802Z",
        updatedAt: "2024-06-07T22:14:56.802Z",
      },
      {
        id: "clx58vdux015f12vcfjrtsajd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FtvKjdx4at",
            locked: 1717798494,
            timestamp: 1717798493,
          },
        ],
        createdAt: "2024-06-07T22:14:55.207Z",
        updatedAt: "2024-06-07T22:14:55.207Z",
      },
      {
        id: "clx58uyzo015e12vcsj1rfrih",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 600349675) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "8XC5BIv2ri",
            locked: 1717798475,
            timestamp: 1717798474,
          },
        ],
        createdAt: "2024-06-07T22:14:35.940Z",
        updatedAt: "2024-06-07T22:14:35.940Z",
      },
      {
        id: "clx58utvr015d12vcnl7pzjry",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dgbik7mBSm",
            locked: 1717798469,
            timestamp: 1717798468,
          },
        ],
        createdAt: "2024-06-07T22:14:29.320Z",
        updatedAt: "2024-06-07T22:14:29.320Z",
      },
      {
        id: "clx58uru4015c12vctc96f51i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kcxMzEtZIv",
            locked: 1717798442,
            timestamp: 1717798441,
          },
        ],
        createdAt: "2024-06-07T22:14:26.666Z",
        updatedAt: "2024-06-07T22:14:26.666Z",
      },
      {
        id: "clx58thmb015b12vcqm7fnh9j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PQvB5Ldzkz",
            locked: 1717798406,
            timestamp: 1717798405,
          },
        ],
        createdAt: "2024-06-07T22:13:26.769Z",
        updatedAt: "2024-06-07T22:13:26.769Z",
      },
      {
        id: "clx58t1ke015a12vcqm7jcvbm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "LT8DoMrEC1",
            locked: 1717798385,
            timestamp: 1717798383,
          },
        ],
        createdAt: "2024-06-07T22:13:05.966Z",
        updatedAt: "2024-06-07T22:13:05.966Z",
      },
      {
        id: "clx58szbo015912vcnpb2y96d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mvA4nGIk9G",
            locked: 1717798382,
            timestamp: 1717798381,
          },
        ],
        createdAt: "2024-06-07T22:13:03.057Z",
        updatedAt: "2024-06-07T22:13:03.057Z",
      },
      {
        id: "clx58rsm6015812vccqejrvhy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "irjXsg0CGt",
            locked: 1717798327,
            timestamp: 1717798326,
          },
        ],
        createdAt: "2024-06-07T22:12:07.710Z",
        updatedAt: "2024-06-07T22:12:07.710Z",
      },
      {
        id: "clx58rrow015712vcghbdidj6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EZUSxSt6cf",
            locked: 1717798325,
            timestamp: 1717798323,
          },
        ],
        createdAt: "2024-06-07T22:12:06.509Z",
        updatedAt: "2024-06-07T22:12:06.509Z",
      },
      {
        id: "clx58r2mk015612vcjvplubaq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36 OPR/110.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "sRiCR9CYvv",
            locked: 1717798292,
            timestamp: 1717798288,
          },
        ],
        createdAt: "2024-06-07T22:11:34.026Z",
        updatedAt: "2024-06-07T22:11:34.026Z",
      },
      {
        id: "clx58q48h015512vcdfesx5jg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/311.0.621658582 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "84oPjL81GS",
            locked: 1717798249,
            timestamp: 1717798248,
          },
        ],
        createdAt: "2024-06-07T22:10:49.454Z",
        updatedAt: "2024-06-07T22:10:49.454Z",
      },
      {
        id: "clx58px6q015412vcso00piv7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/311.0.621658582 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "u9sQlCM46F",
            locked: 1717798239,
            timestamp: 1717798236,
          },
        ],
        createdAt: "2024-06-07T22:10:40.322Z",
        updatedAt: "2024-06-07T22:10:40.322Z",
      },
      {
        id: "clx58pp4r015312vc1loqt2vm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "kdVdXcbF4W",
            locked: 1717798230,
            timestamp: 1717798229,
          },
        ],
        createdAt: "2024-06-07T22:10:29.884Z",
        updatedAt: "2024-06-07T22:10:29.884Z",
      },
      {
        id: "clx58pmyd015212vcit6zksj3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "LHbS6jbhxu",
            locked: 1717798226,
            timestamp: 1717798223,
          },
        ],
        createdAt: "2024-06-07T22:10:27.058Z",
        updatedAt: "2024-06-07T22:10:27.058Z",
      },
      {
        id: "clx58pau6015112vcwo2g2jc8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "c1EpYGKZOY",
            locked: 1717798210,
            timestamp: 1717798204,
          },
        ],
        createdAt: "2024-06-07T22:10:11.356Z",
        updatedAt: "2024-06-07T22:10:11.356Z",
      },
      {
        id: "clx58opjb015012vc16udem3k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "4CC65xCmuf",
            locked: 1717798182,
            timestamp: 1717798181,
          },
        ],
        createdAt: "2024-06-07T22:09:43.750Z",
        updatedAt: "2024-06-07T22:09:43.750Z",
      },
      {
        id: "clx58oiey014z12vc9pfxam9c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/126.2  Mobile/15E148 Safari/605.1.15",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ie39bDEU9t",
            locked: 1717798174,
            timestamp: 1717798172,
          },
        ],
        createdAt: "2024-06-07T22:09:34.522Z",
        updatedAt: "2024-06-07T22:09:34.522Z",
      },
      {
        id: "clx58odxb014y12vccdakeanz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fodcZMb9CC",
            locked: 1717798168,
            timestamp: 1717798166,
          },
        ],
        createdAt: "2024-06-07T22:09:28.704Z",
        updatedAt: "2024-06-07T22:09:28.704Z",
      },
      {
        id: "clx58oap6014x12vclcaxiuwm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/126.2  Mobile/15E148 Safari/605.1.15",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Xl149CKVZJ",
            locked: 1717798163,
            timestamp: 1717798162,
          },
        ],
        createdAt: "2024-06-07T22:09:24.519Z",
        updatedAt: "2024-06-07T22:09:24.519Z",
      },
      {
        id: "clx58ntjp014w12vcold9mejt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Cm7KRKNMBS",
            locked: 1717798141,
            timestamp: 1717798140,
          },
        ],
        createdAt: "2024-06-07T22:09:02.294Z",
        updatedAt: "2024-06-07T22:09:02.294Z",
      },
      {
        id: "clx58njtd014v12vcg0acipp6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DmVT6M3oaV",
            locked: 1717798128,
            timestamp: 1717798127,
          },
        ],
        createdAt: "2024-06-07T22:08:49.679Z",
        updatedAt: "2024-06-07T22:08:49.679Z",
      },
      {
        id: "clx58n7zi014u12vc9ky928cs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "u6WOMDhgFI",
            locked: 1717798113,
            timestamp: 1717798110,
          },
        ],
        createdAt: "2024-06-07T22:08:34.351Z",
        updatedAt: "2024-06-07T22:08:34.351Z",
      },
      {
        id: "clx58myjy014t12vculyjf20v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2129; samsung; SM-S928U; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "TjM6EToDPM",
            locked: 1717798102,
            timestamp: 1717798101,
          },
        ],
        createdAt: "2024-06-07T22:08:22.123Z",
        updatedAt: "2024-06-07T22:08:22.123Z",
      },
      {
        id: "clx58lwyp014s12vcbbw0ypko",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "wMGIbDjooc",
            locked: 1717798053,
            timestamp: 1717798052,
          },
        ],
        createdAt: "2024-06-07T22:07:33.409Z",
        updatedAt: "2024-06-07T22:07:33.409Z",
      },
      {
        id: "clx58luxp014r12vc3y2dl595",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OQh3W8w2Rk",
            locked: 1717798049,
            timestamp: 1717798048,
          },
        ],
        createdAt: "2024-06-07T22:07:30.778Z",
        updatedAt: "2024-06-07T22:07:30.778Z",
      },
      {
        id: "clx58lmq3014q12vclw8metog",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1tvYXD9KPq",
            locked: 1717798039,
            timestamp: 1717798036,
          },
        ],
        createdAt: "2024-06-07T22:07:20.140Z",
        updatedAt: "2024-06-07T22:07:20.140Z",
      },
      {
        id: "clx58lm8c014p12vc7oz6f1nh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2129; samsung; SM-S928U; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kbisUSx7MP",
            locked: 1717798039,
            timestamp: 1717798037,
          },
        ],
        createdAt: "2024-06-07T22:07:19.501Z",
        updatedAt: "2024-06-07T22:07:19.501Z",
      },
      {
        id: "clx58llmb014o12vc3rpktf2k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kFGRuOeDTt",
            locked: 1717798038,
            timestamp: 1717798036,
          },
        ],
        createdAt: "2024-06-07T22:07:18.707Z",
        updatedAt: "2024-06-07T22:07:18.707Z",
      },
      {
        id: "clx58lkol014n12vcjrxpw6em",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "V0tWtBLLFa",
            locked: 1717798036,
            timestamp: 1717798035,
          },
        ],
        createdAt: "2024-06-07T22:07:17.493Z",
        updatedAt: "2024-06-07T22:07:17.493Z",
      },
      {
        id: "clx58lil2014m12vcvutv9li0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hLvolN2III",
            locked: 1717798030,
            timestamp: 1717798027,
          },
        ],
        createdAt: "2024-06-07T22:07:14.774Z",
        updatedAt: "2024-06-07T22:07:14.774Z",
      },
      {
        id: "clx58lf8z014l12vc5pctvrlw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JtsPKOUXg5",
            locked: 1717798029,
            timestamp: 1717798028,
          },
        ],
        createdAt: "2024-06-07T22:07:10.449Z",
        updatedAt: "2024-06-07T22:07:10.449Z",
      },
      {
        id: "clx58jgb9014k12vcyz4eb4k6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nHtO3Uuonr",
            locked: 1717797938,
            timestamp: 1717797924,
          },
        ],
        createdAt: "2024-06-07T22:05:38.514Z",
        updatedAt: "2024-06-07T22:05:38.514Z",
      },
      {
        id: "clx58isy1014j12vch13e8g95",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rQPg8krdNw",
            locked: 1717797907,
            timestamp: 1717797906,
          },
        ],
        createdAt: "2024-06-07T22:05:08.230Z",
        updatedAt: "2024-06-07T22:05:08.230Z",
      },
      {
        id: "clx58ib7n014i12vcyr76ynjy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19E258 Instagram 330.0.3.29.91 (iPhone12,1; iOS 15_4_1; en_US; en; scale=2.00; 828x1792; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "xOHrVLY2bn",
            locked: 1717797885,
            timestamp: 1717797884,
          },
        ],
        createdAt: "2024-06-07T22:04:45.251Z",
        updatedAt: "2024-06-07T22:04:45.251Z",
      },
      {
        id: "clx58i178014h12vcdgjdyfmh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19E258 Instagram 330.0.3.29.91 (iPhone12,1; iOS 15_4_1; en_US; en; scale=2.00; 828x1792; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1p0d986RIm",
            locked: 1717797871,
            timestamp: 1717797869,
          },
        ],
        createdAt: "2024-06-07T22:04:32.273Z",
        updatedAt: "2024-06-07T22:04:32.273Z",
      },
      {
        id: "clx58h98i014g12vc6h93pjtw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "CfXIYjFPV7",
            locked: 1717797835,
            timestamp: 1717797834,
          },
        ],
        createdAt: "2024-06-07T22:03:56.033Z",
        updatedAt: "2024-06-07T22:03:56.033Z",
      },
      {
        id: "clx58h33i014f12vcfji2zudb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NukfZiaV7D",
            locked: 1717797826,
            timestamp: 1717797824,
          },
        ],
        createdAt: "2024-06-07T22:03:48.079Z",
        updatedAt: "2024-06-07T22:03:48.079Z",
      },
      {
        id: "clx58gx6c014e12vciiboe1f6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "mj5vdxeJ0p",
            locked: 1717797819,
            timestamp: 1717797814,
          },
        ],
        createdAt: "2024-06-07T22:03:40.405Z",
        updatedAt: "2024-06-07T22:03:40.405Z",
      },
      {
        id: "clx58gruw014d12vc9owj5mpq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 317.0.4.27.109 (iPhone16,2; iOS 17_3_1; en_US; en; scale=3.00; 1290x2796; 562830928) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Sl7ZPID2mB",
            locked: 1717797812,
            timestamp: 1717797810,
          },
        ],
        createdAt: "2024-06-07T22:03:33.509Z",
        updatedAt: "2024-06-07T22:03:33.509Z",
      },
      {
        id: "clx58gjbx014c12vchxvzb4lq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "PC5n3lye94",
            locked: 1717797802,
            timestamp: 1717797801,
          },
        ],
        createdAt: "2024-06-07T22:03:22.462Z",
        updatedAt: "2024-06-07T22:03:22.462Z",
      },
      {
        id: "clx58gdey014b12vcmqhqae8n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IppUt6JzTK",
            locked: 1717797793,
            timestamp: 1717797792,
          },
        ],
        createdAt: "2024-06-07T22:03:14.792Z",
        updatedAt: "2024-06-07T22:03:14.792Z",
      },
      {
        id: "clx58en70014a12vcozitgda6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KM6Awwu493",
            locked: 1717797713,
            timestamp: 1717797712,
          },
        ],
        createdAt: "2024-06-07T22:01:54.154Z",
        updatedAt: "2024-06-07T22:01:54.154Z",
      },
      {
        id: "clx58df5x014912vci95smqob",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gqhdHpzM6k",
            locked: 1717797656,
            timestamp: 1717797655,
          },
        ],
        createdAt: "2024-06-07T22:00:57.092Z",
        updatedAt: "2024-06-07T22:00:57.092Z",
      },
      {
        id: "clx58cxzs014812vcjji40oqw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 266.0.0.10.105 (iPhone14,5; iOS 17_4_1; en_US; en-US; scale=3.00; 1170x2532; 437674770)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3WknLy0TAU",
            locked: 1717797632,
            timestamp: 1717797630,
          },
        ],
        createdAt: "2024-06-07T22:00:34.839Z",
        updatedAt: "2024-06-07T22:00:34.839Z",
      },
      {
        id: "clx58cnnt014712vc0qy19nqd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/99.0.4844.47 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bZihOQl4Ld",
            locked: 1717797618,
            timestamp: 1717797610,
          },
        ],
        createdAt: "2024-06-07T22:00:21.449Z",
        updatedAt: "2024-06-07T22:00:21.449Z",
      },
      {
        id: "clx58cdu2014612vcrbxdflbh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 327.1.6.30.88 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KJ6vKXj52E",
            locked: 1717797608,
            timestamp: 1717797606,
          },
        ],
        createdAt: "2024-06-07T22:00:08.711Z",
        updatedAt: "2024-06-07T22:00:08.711Z",
      },
      {
        id: "clx58c1sq014512vcz6u5pt40",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4ZYz7OOSco",
            locked: 1717797592,
            timestamp: 1717797591,
          },
        ],
        createdAt: "2024-06-07T21:59:53.115Z",
        updatedAt: "2024-06-07T21:59:53.115Z",
      },
      {
        id: "clx58buf8014412vccqzuqgpo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "doIIRWknbk",
            locked: 1717797583,
            timestamp: 1717797582,
          },
        ],
        createdAt: "2024-06-07T21:59:43.556Z",
        updatedAt: "2024-06-07T21:59:43.556Z",
      },
      {
        id: "clx58btph014312vckvs6ctge",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MNBHPunJZ2",
            locked: 1717797581,
            timestamp: 1717797580,
          },
        ],
        createdAt: "2024-06-07T21:59:42.626Z",
        updatedAt: "2024-06-07T21:59:42.626Z",
      },
      {
        id: "clx58bbdz014212vczc1h2my8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fH0TvVXqFh",
            locked: 1717797558,
            timestamp: 1717797557,
          },
        ],
        createdAt: "2024-06-07T21:59:18.888Z",
        updatedAt: "2024-06-07T21:59:18.888Z",
      },
      {
        id: "clx58b9cz014112vc22jjcpcb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1ewh6q9JMo",
            locked: 1717797555,
            timestamp: 1717797553,
          },
        ],
        createdAt: "2024-06-07T21:59:16.258Z",
        updatedAt: "2024-06-07T21:59:16.258Z",
      },
      {
        id: "clx58b1of014012vcx2ry9xol",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "DI5LrXbJTr",
            locked: 1717797545,
            timestamp: 1717797544,
          },
        ],
        createdAt: "2024-06-07T21:59:06.303Z",
        updatedAt: "2024-06-07T21:59:06.303Z",
      },
      {
        id: "clx58awtn013z12vck9nk9by7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XpV1Wnxwgp",
            locked: 1717797539,
            timestamp: 1717797537,
          },
        ],
        createdAt: "2024-06-07T21:59:00.012Z",
        updatedAt: "2024-06-07T21:59:00.012Z",
      },
      {
        id: "clx58awjb013y12vct72uf0xf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Wzv8Mi9xxv",
            locked: 1717797539,
            timestamp: 1717797535,
          },
        ],
        createdAt: "2024-06-07T21:58:59.636Z",
        updatedAt: "2024-06-07T21:58:59.636Z",
      },
      {
        id: "clx58akix013x12vcvej11l5v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "HLDh9y3Gg4",
            locked: 1717797523,
            timestamp: 1717797522,
          },
        ],
        createdAt: "2024-06-07T21:58:44.073Z",
        updatedAt: "2024-06-07T21:58:44.073Z",
      },
      {
        id: "clx58ace9013w12vcc9vzqjs3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FKf2EWDQ7H",
            locked: 1717797512,
            timestamp: 1717797508,
          },
        ],
        createdAt: "2024-06-07T21:58:33.534Z",
        updatedAt: "2024-06-07T21:58:33.534Z",
      },
      {
        id: "clx588qm9013v12vckf213esg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rFv7JcGf2U",
            locked: 1717797438,
            timestamp: 1717797436,
          },
        ],
        createdAt: "2024-06-07T21:57:18.654Z",
        updatedAt: "2024-06-07T21:57:18.654Z",
      },
      {
        id: "clx587os5013u12vchm1k4j5f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "IrJGlKqDb5",
            locked: 1717797389,
            timestamp: 1717797388,
          },
        ],
        createdAt: "2024-06-07T21:56:29.619Z",
        updatedAt: "2024-06-07T21:56:29.619Z",
      },
      {
        id: "clx587ftu013t12vcrlhaki73",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2133; samsung; SM-S901U; r0q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mewOOluC1U",
            locked: 1717797377,
            timestamp: 1717797375,
          },
        ],
        createdAt: "2024-06-07T21:56:18.018Z",
        updatedAt: "2024-06-07T21:56:18.018Z",
      },
      {
        id: "clx587bny013s12vc9f1zycat",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8tK9ZCq2h3",
            locked: 1717797372,
            timestamp: 1717797367,
          },
        ],
        createdAt: "2024-06-07T21:56:12.620Z",
        updatedAt: "2024-06-07T21:56:12.620Z",
      },
      {
        id: "clx586ey6013r12vc8h6tvn8o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/116.0.5845.177 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tLVb7hwwPY",
            locked: 1717797329,
            timestamp: 1717797327,
          },
        ],
        createdAt: "2024-06-07T21:55:30.218Z",
        updatedAt: "2024-06-07T21:55:30.218Z",
      },
      {
        id: "clx585fl8013q12vcn315f9o7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,1; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "gAEMqLBRTB",
            locked: 1717797284,
            timestamp: 1717797283,
          },
        ],
        createdAt: "2024-06-07T21:54:44.397Z",
        updatedAt: "2024-06-07T21:54:44.397Z",
      },
      {
        id: "clx585ebb013p12vc1pakh4xn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,1; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "O0zPdqsNTo",
            locked: 1717797282,
            timestamp: 1717797280,
          },
        ],
        createdAt: "2024-06-07T21:54:42.740Z",
        updatedAt: "2024-06-07T21:54:42.740Z",
      },
      {
        id: "clx584d97013o12vce8n1szm9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A146U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2208; samsung; SM-A146U; a14xm; mt6833; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nLB91f87tg",
            locked: 1717797232,
            timestamp: 1717797228,
          },
        ],
        createdAt: "2024-06-07T21:53:54.716Z",
        updatedAt: "2024-06-07T21:53:54.716Z",
      },
      {
        id: "clx5846n9013n12vct76r33fx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G973U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 480dpi; 1080x2024; samsung; SM-G973U; beyond1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "d5jIgaCErc",
            locked: 1717797225,
            timestamp: 1717797224,
          },
        ],
        createdAt: "2024-06-07T21:53:46.150Z",
        updatedAt: "2024-06-07T21:53:46.150Z",
      },
      {
        id: "clx5845cp013m12vc84xfypq2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "wXon31wvut",
            locked: 1717797222,
            timestamp: 1717797221,
          },
        ],
        createdAt: "2024-06-07T21:53:44.471Z",
        updatedAt: "2024-06-07T21:53:44.471Z",
      },
      {
        id: "clx583o8j013l12vcy56wisi7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G973U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 480dpi; 1080x2024; samsung; SM-G973U; beyond1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tzYq5Obawc",
            locked: 1717797201,
            timestamp: 1717797198,
          },
        ],
        createdAt: "2024-06-07T21:53:22.288Z",
        updatedAt: "2024-06-07T21:53:22.288Z",
      },
      {
        id: "clx582eze013k12vcoxm9qmk2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RxeF25qVHi",
            locked: 1717797143,
            timestamp: 1717797141,
          },
        ],
        createdAt: "2024-06-07T21:52:23.640Z",
        updatedAt: "2024-06-07T21:52:23.640Z",
      },
      {
        id: "clx581rro013j12vcavlf116a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; es_US; es; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ny1SLxSpW0",
            locked: 1717797113,
            timestamp: 1717797110,
          },
        ],
        createdAt: "2024-06-07T21:51:53.554Z",
        updatedAt: "2024-06-07T21:51:53.554Z",
      },
      {
        id: "clx580z9a013i12vc8kwp5y7s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "9wPVKyx3Ni",
            locked: 1717797076,
            timestamp: 1717797075,
          },
        ],
        createdAt: "2024-06-07T21:51:16.605Z",
        updatedAt: "2024-06-07T21:51:16.605Z",
      },
      {
        id: "clx580vfl013h12vcln36gglq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pCUbMHfHr0",
            locked: 1717797071,
            timestamp: 1717797069,
          },
        ],
        createdAt: "2024-06-07T21:51:11.649Z",
        updatedAt: "2024-06-07T21:51:11.649Z",
      },
      {
        id: "clx580sqc013g12vc57ib81n4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ozhs9xQfKJ",
            locked: 1717797067,
            timestamp: 1717797066,
          },
        ],
        createdAt: "2024-06-07T21:51:08.148Z",
        updatedAt: "2024-06-07T21:51:08.148Z",
      },
      {
        id: "clx580nle013f12vcqduxpzpb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0wdfNy5Qcb",
            locked: 1717797060,
            timestamp: 1717797058,
          },
        ],
        createdAt: "2024-06-07T21:51:01.490Z",
        updatedAt: "2024-06-07T21:51:01.490Z",
      },
      {
        id: "clx580gd9013e12vc8hzvye1c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IAs8d9c9BB",
            locked: 1717796996,
            timestamp: 1717796995,
          },
        ],
        createdAt: "2024-06-07T21:50:52.125Z",
        updatedAt: "2024-06-07T21:50:52.125Z",
      },
      {
        id: "clx5807yb013d12vc2tnglv67",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "hcuVy4PE44",
            locked: 1717797041,
            timestamp: 1717797040,
          },
        ],
        createdAt: "2024-06-07T21:50:41.219Z",
        updatedAt: "2024-06-07T21:50:41.219Z",
      },
      {
        id: "clx5807dt013c12vchs2j7axw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "c2m1NZX0Dm",
            locked: 1717797039,
            timestamp: 1717797037,
          },
        ],
        createdAt: "2024-06-07T21:50:40.482Z",
        updatedAt: "2024-06-07T21:50:40.482Z",
      },
      {
        id: "clx5805e9013b12vcr87byvb0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1uNK2e2xgP",
            locked: 1717797036,
            timestamp: 1717797032,
          },
        ],
        createdAt: "2024-06-07T21:50:37.903Z",
        updatedAt: "2024-06-07T21:50:37.903Z",
      },
      {
        id: "clx57zh4w013a12vcmbe4go1e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-G960U Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 480dpi; 1080x2076; samsung; SM-G960U; starqltesq; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nlFgsaIxEG",
            locked: 1717797005,
            timestamp: 1717797002,
          },
        ],
        createdAt: "2024-06-07T21:50:06.461Z",
        updatedAt: "2024-06-07T21:50:06.461Z",
      },
      {
        id: "clx57z8o7013912vct07n8iy3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "oVovOlW8WD",
            locked: 1717796995,
            timestamp: 1717796993,
          },
        ],
        createdAt: "2024-06-07T21:49:55.495Z",
        updatedAt: "2024-06-07T21:49:55.495Z",
      },
      {
        id: "clx57z6ga013812vc4qaa2w1u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "GXDySaqFgs",
            locked: 1717796992,
            timestamp: 1717796991,
          },
        ],
        createdAt: "2024-06-07T21:49:52.618Z",
        updatedAt: "2024-06-07T21:49:52.618Z",
      },
      {
        id: "clx57z1do013712vcd5bpjrod",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rVSkEJTtUR",
            locked: 1717796985,
            timestamp: 1717796984,
          },
        ],
        createdAt: "2024-06-07T21:49:46.042Z",
        updatedAt: "2024-06-07T21:49:46.042Z",
      },
      {
        id: "clx57yk4m013612vcu4s6c36w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "8RGANeyElf",
            locked: 1717796963,
            timestamp: 1717796962,
          },
        ],
        createdAt: "2024-06-07T21:49:23.686Z",
        updatedAt: "2024-06-07T21:49:23.686Z",
      },
      {
        id: "clx57yg5o013512vcy4wgeix2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lVvRbF5BIs",
            locked: 1717796957,
            timestamp: 1717796953,
          },
        ],
        createdAt: "2024-06-07T21:49:18.540Z",
        updatedAt: "2024-06-07T21:49:18.540Z",
      },
      {
        id: "clx57yaci013412vc1bsgtnzr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3MZ9ta1tpr",
            locked: 1717796950,
            timestamp: 1717796949,
          },
        ],
        createdAt: "2024-06-07T21:49:11.008Z",
        updatedAt: "2024-06-07T21:49:11.008Z",
      },
      {
        id: "clx57xsvl013312vca4g62dug",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "kHtwnZTSl2",
            locked: 1717796928,
            timestamp: 1717796927,
          },
        ],
        createdAt: "2024-06-07T21:48:48.369Z",
        updatedAt: "2024-06-07T21:48:48.369Z",
      },
      {
        id: "clx57xp8y013212vc7p1h4agc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "t6Sslh4yoN",
            locked: 1717796923,
            timestamp: 1717796921,
          },
        ],
        createdAt: "2024-06-07T21:48:43.664Z",
        updatedAt: "2024-06-07T21:48:43.664Z",
      },
      {
        id: "clx57xckb013112vcx9y3d3s5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; TECNO LH8n Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2352; TECNO; TECNO LH8n; TECNO-LH8n; mt6833; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "TrueCnoMjp",
            locked: 1717796908,
            timestamp: 1717796902,
          },
        ],
        createdAt: "2024-06-07T21:48:27.225Z",
        updatedAt: "2024-06-07T21:48:27.225Z",
      },
      {
        id: "clx57wyd7013012vcab2zgd0d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; TECNO LH8n Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 480dpi; 1080x2352; TECNO; TECNO LH8n; TECNO-LH8n; mt6833; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4KMEyUjJyS",
            locked: 1717796889,
            timestamp: 1717796886,
          },
        ],
        createdAt: "2024-06-07T21:48:08.825Z",
        updatedAt: "2024-06-07T21:48:08.825Z",
      },
      {
        id: "clx57v8eh012z12vczhutw2v1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "frkPQFfu0p",
            locked: 1717796808,
            timestamp: 1717796806,
          },
        ],
        createdAt: "2024-06-07T21:46:48.517Z",
        updatedAt: "2024-06-07T21:46:48.517Z",
      },
      {
        id: "clx57skd7012y12vcbb4rfpy5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "l3nr1EGqIb",
            locked: 1717796683,
            timestamp: 1717796682,
          },
        ],
        createdAt: "2024-06-07T21:44:44.060Z",
        updatedAt: "2024-06-07T21:44:44.060Z",
      },
      {
        id: "clx57sjx0012x12vcc0brt7gp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "B2VjoXnNav",
            locked: 1717796683,
            timestamp: 1717796681,
          },
        ],
        createdAt: "2024-06-07T21:44:43.474Z",
        updatedAt: "2024-06-07T21:44:43.474Z",
      },
      {
        id: "clx57s6vj012w12vco0orxy3g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 328.1.3.32.89 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 591155251)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "w10MHbRQ4d",
            locked: 1717796666,
            timestamp: 1717796665,
          },
        ],
        createdAt: "2024-06-07T21:44:26.575Z",
        updatedAt: "2024-06-07T21:44:26.575Z",
      },
      {
        id: "clx57s5vy012v12vc5iop0d9i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "XYZ78Jd2O7",
            locked: 1717796664,
            timestamp: 1717796663,
          },
        ],
        createdAt: "2024-06-07T21:44:25.295Z",
        updatedAt: "2024-06-07T21:44:25.295Z",
      },
      {
        id: "clx57s5ou012u12vczp8rt2s0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A156U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2125; samsung; SM-A156U; a15x; mt6835; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OuVntXhObE",
            locked: 1717796664,
            timestamp: 1717796661,
          },
        ],
        createdAt: "2024-06-07T21:44:25.038Z",
        updatedAt: "2024-06-07T21:44:25.038Z",
      },
      {
        id: "clx57s3se012t12vcwdj884pa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 328.1.3.32.89 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 591155251)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SQQYNSDezS",
            locked: 1717796662,
            timestamp: 1717796660,
          },
        ],
        createdAt: "2024-06-07T21:44:22.572Z",
        updatedAt: "2024-06-07T21:44:22.572Z",
      },
      {
        id: "clx57rtwk012s12vc0etxokf5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "XjFkwVkfUO",
            locked: 1717796648,
            timestamp: 1717796647,
          },
        ],
        createdAt: "2024-06-07T21:44:09.764Z",
        updatedAt: "2024-06-07T21:44:09.764Z",
      },
      {
        id: "clx57rjib012r12vcwsmwywdp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VRQnZ1Bx8Z",
            locked: 1717796636,
            timestamp: 1717796633,
          },
        ],
        createdAt: "2024-06-07T21:43:56.291Z",
        updatedAt: "2024-06-07T21:43:56.291Z",
      },
      {
        id: "clx57rfpv012q12vc5nuo3npk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 328.1.3.32.89 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 591155251)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3W1w3BBJYp",
            locked: 1717796630,
            timestamp: 1717796628,
          },
        ],
        createdAt: "2024-06-07T21:43:51.379Z",
        updatedAt: "2024-06-07T21:43:51.379Z",
      },
      {
        id: "clx57r649012p12vcp7766pvw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-G892A Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.113 Mobile Safari/537.36 Instagram 328.0.0.42.90 Android (28/9; 540dpi; 1080x2058; samsung; SM-G892A; cruiserlteatt; qcom; en_US; 591192883)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "trqqrkQ4mP",
            locked: 1717796615,
            timestamp: 1717796598,
          },
        ],
        createdAt: "2024-06-07T21:43:38.934Z",
        updatedAt: "2024-06-07T21:43:38.934Z",
      },
      {
        id: "clx57qmep012o12vcffvucywe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8f5Lq3ewyH",
            locked: 1717796592,
            timestamp: 1717796590,
          },
        ],
        createdAt: "2024-06-07T21:43:13.391Z",
        updatedAt: "2024-06-07T21:43:13.391Z",
      },
      {
        id: "clx57pwnb012n12vcq8i7egdg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; CrOS armv7l 15359.58.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.3",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TdPp4NbDfe",
            locked: 1717796559,
            timestamp: 1717796557,
          },
        ],
        createdAt: "2024-06-07T21:42:40.004Z",
        updatedAt: "2024-06-07T21:42:40.004Z",
      },
      {
        id: "clx57pa6r012m12vcc35x67vd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "W5JtXydQ7P",
            locked: 1717796529,
            timestamp: 1717796527,
          },
        ],
        createdAt: "2024-06-07T21:42:10.896Z",
        updatedAt: "2024-06-07T21:42:10.896Z",
      },
      {
        id: "clx57ozwd012l12vcy1uvhwcm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "n9nLq2NA66",
            locked: 1717796517,
            timestamp: 1717796516,
          },
        ],
        createdAt: "2024-06-07T21:41:57.565Z",
        updatedAt: "2024-06-07T21:41:57.565Z",
      },
      {
        id: "clx57oy5k012k12vccg21vcgs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "VLfNqGHtsv",
            locked: 1717796514,
            timestamp: 1717796513,
          },
        ],
        createdAt: "2024-06-07T21:41:55.305Z",
        updatedAt: "2024-06-07T21:41:55.305Z",
      },
      {
        id: "clx57otef012j12vc9jb2f9qx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Y5CnwOGukf",
            locked: 1717796508,
            timestamp: 1717796505,
          },
        ],
        createdAt: "2024-06-07T21:41:49.142Z",
        updatedAt: "2024-06-07T21:41:49.142Z",
      },
      {
        id: "clx57ondd012i12vcz5gibfvd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "t8qWlzCYfk",
            locked: 1717796500,
            timestamp: 1717796499,
          },
        ],
        createdAt: "2024-06-07T21:41:41.330Z",
        updatedAt: "2024-06-07T21:41:41.330Z",
      },
      {
        id: "clx57ocoq012h12vc779jfjvk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_CA; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "r4qahJs0p6",
            locked: 1717796487,
            timestamp: 1717796486,
          },
        ],
        createdAt: "2024-06-07T21:41:27.483Z",
        updatedAt: "2024-06-07T21:41:27.483Z",
      },
      {
        id: "clx57oc6r012g12vcz6ar83eu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_CA; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ebBCr0aHRZ",
            locked: 1717796486,
            timestamp: 1717796484,
          },
        ],
        createdAt: "2024-06-07T21:41:26.833Z",
        updatedAt: "2024-06-07T21:41:26.833Z",
      },
      {
        id: "clx57mvm6012f12vc2ebp4qj0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/87.0.4280.77 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Jr3aTlVDoU",
            locked: 1717796418,
            timestamp: 1717796417,
          },
        ],
        createdAt: "2024-06-07T21:40:18.702Z",
        updatedAt: "2024-06-07T21:40:18.702Z",
      },
      {
        id: "clx57mv3f012e12vcgbd0qd22",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/87.0.4280.77 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Nj8uTekOlU",
            locked: 1717796417,
            timestamp: 1717796416,
          },
        ],
        createdAt: "2024-06-07T21:40:18.024Z",
        updatedAt: "2024-06-07T21:40:18.024Z",
      },
      {
        id: "clx57maf4012d12vcw7c71787",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "k85ctCNWsv",
            locked: 1717796394,
            timestamp: 1717796393,
          },
        ],
        createdAt: "2024-06-07T21:39:51.231Z",
        updatedAt: "2024-06-07T21:39:51.231Z",
      },
      {
        id: "clx57m669012c12vcjoid7xuq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-G965F Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 420dpi; 1080x2094; samsung; SM-G965F; star2lte; samsungexynos9810; el_GR; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "BWE49QYMnT",
            locked: 1717796384,
            timestamp: 1717796383,
          },
        ],
        createdAt: "2024-06-07T21:39:45.729Z",
        updatedAt: "2024-06-07T21:39:45.729Z",
      },
      {
        id: "clx57m58f012b12vcoodcgpbx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ljm8nfUzVN",
            locked: 1717796384,
            timestamp: 1717796382,
          },
        ],
        createdAt: "2024-06-07T21:39:44.511Z",
        updatedAt: "2024-06-07T21:39:44.511Z",
      },
      {
        id: "clx57lyoh012a12vcum9hpc2z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-G965F Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 420dpi; 1080x2094; samsung; SM-G965F; star2lte; samsungexynos9810; el_GR; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pGYhm6Gnid",
            locked: 1717796374,
            timestamp: 1717796371,
          },
        ],
        createdAt: "2024-06-07T21:39:36.015Z",
        updatedAt: "2024-06-07T21:39:36.015Z",
      },
      {
        id: "clx57l9ww012912vcw175bxdz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "84B3gOYlJP",
            locked: 1717796343,
            timestamp: 1717796342,
          },
        ],
        createdAt: "2024-06-07T21:39:03.921Z",
        updatedAt: "2024-06-07T21:39:03.921Z",
      },
      {
        id: "clx57l8yt012812vcqtwpthqy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Xcb3GXhLce",
            locked: 1717796341,
            timestamp: 1717796339,
          },
        ],
        createdAt: "2024-06-07T21:39:02.690Z",
        updatedAt: "2024-06-07T21:39:02.690Z",
      },
      {
        id: "clx57ksky012712vcr8ma2qjy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "H85Z833hKj",
            locked: 1717796324,
            timestamp: 1717796312,
          },
        ],
        createdAt: "2024-06-07T21:38:41.456Z",
        updatedAt: "2024-06-07T21:38:41.456Z",
      },
      {
        id: "clx57kakc012612vcfafz9mql",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xsg9ML11wo",
            locked: 1717796297,
            timestamp: 1717796296,
          },
        ],
        createdAt: "2024-06-07T21:38:18.106Z",
        updatedAt: "2024-06-07T21:38:18.106Z",
      },
      {
        id: "clx57im9w012512vcaru2ecop",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fRTjy8gBKq",
            locked: 1717796219,
            timestamp: 1717796218,
          },
        ],
        createdAt: "2024-06-07T21:36:59.971Z",
        updatedAt: "2024-06-07T21:36:59.971Z",
      },
      {
        id: "clx57ih76012412vc8m2rdeat",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "BjE42QxAUW",
            locked: 1717796213,
            timestamp: 1717796212,
          },
        ],
        createdAt: "2024-06-07T21:36:53.394Z",
        updatedAt: "2024-06-07T21:36:53.394Z",
      },
      {
        id: "clx57i7z4012312vcpdfyd0u4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FpsmUAlptx",
            locked: 1717796200,
            timestamp: 1717796198,
          },
        ],
        createdAt: "2024-06-07T21:36:41.436Z",
        updatedAt: "2024-06-07T21:36:41.436Z",
      },
      {
        id: "clx57hsvz012212vcrv198gz6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "oou7ilD6ej",
            locked: 1717796181,
            timestamp: 1717796180,
          },
        ],
        createdAt: "2024-06-07T21:36:21.888Z",
        updatedAt: "2024-06-07T21:36:21.888Z",
      },
      {
        id: "clx57hqis012112vc2b94pypu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5GL5HeEPi0",
            locked: 1717796176,
            timestamp: 1717796175,
          },
        ],
        createdAt: "2024-06-07T21:36:18.820Z",
        updatedAt: "2024-06-07T21:36:18.820Z",
      },
      {
        id: "clx57hlg9012012vc3n4wd0hm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "PvS2LH7wrB",
            locked: 1717796170,
            timestamp: 1717796169,
          },
        ],
        createdAt: "2024-06-07T21:36:12.250Z",
        updatedAt: "2024-06-07T21:36:12.250Z",
      },
      {
        id: "clx57hl86011z12vcroecksfl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ilYAqVPedI",
            locked: 1717796169,
            timestamp: 1717796160,
          },
        ],
        createdAt: "2024-06-07T21:36:11.956Z",
        updatedAt: "2024-06-07T21:36:11.956Z",
      },
      {
        id: "clx57h988011y12vcic8l6rl7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "a4evjRhW9R",
            locked: 1717796156,
            timestamp: 1717796155,
          },
        ],
        createdAt: "2024-06-07T21:35:56.408Z",
        updatedAt: "2024-06-07T21:35:56.408Z",
      },
      {
        id: "clx57h6h2011x12vc2wa9bccr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2ZHlQjcd5t",
            locked: 1717796152,
            timestamp: 1717796150,
          },
        ],
        createdAt: "2024-06-07T21:35:52.839Z",
        updatedAt: "2024-06-07T21:35:52.839Z",
      },
      {
        id: "clx57h4rn011w12vcdx4x79ar",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,1; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "aQZmC1PuMI",
            locked: 1717796150,
            timestamp: 1717796148,
          },
        ],
        createdAt: "2024-06-07T21:35:50.625Z",
        updatedAt: "2024-06-07T21:35:50.625Z",
      },
      {
        id: "clx57gpqt011v12vcnx0xal04",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1Ik9I7kkhX",
            locked: 1717796130,
            timestamp: 1717796129,
          },
        ],
        createdAt: "2024-06-07T21:35:31.155Z",
        updatedAt: "2024-06-07T21:35:31.155Z",
      },
      {
        id: "clx57ej2b011u12vc5u7vzaoo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "NAIvNs0Ksp",
            locked: 1717796028,
            timestamp: 1717796027,
          },
        ],
        createdAt: "2024-06-07T21:33:49.188Z",
        updatedAt: "2024-06-07T21:33:49.188Z",
      },
      {
        id: "clx57efj3011t12vcok2blnbk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wpt28qBQFh",
            locked: 1717796023,
            timestamp: 1717796021,
          },
        ],
        createdAt: "2024-06-07T21:33:44.605Z",
        updatedAt: "2024-06-07T21:33:44.605Z",
      },
      {
        id: "clx57dyra011s12vcb9950ul5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "k4WJRJFNn7",
            locked: 1717796002,
            timestamp: 1717796001,
          },
        ],
        createdAt: "2024-06-07T21:33:22.867Z",
        updatedAt: "2024-06-07T21:33:22.867Z",
      },
      {
        id: "clx57dk2c011r12vcpuwjsg3o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "LkUNd435je",
            locked: 1717795983,
            timestamp: 1717795982,
          },
        ],
        createdAt: "2024-06-07T21:33:03.828Z",
        updatedAt: "2024-06-07T21:33:03.828Z",
      },
      {
        id: "clx57dism011q12vcjs3cy2xl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NDvjs1VCiB",
            locked: 1717795981,
            timestamp: 1717795980,
          },
        ],
        createdAt: "2024-06-07T21:33:02.182Z",
        updatedAt: "2024-06-07T21:33:02.182Z",
      },
      {
        id: "clx57ddxt011p12vc4kw0b0lv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9hc2tEDCJe",
            locked: 1717795975,
            timestamp: 1717795974,
          },
        ],
        createdAt: "2024-06-07T21:32:55.887Z",
        updatedAt: "2024-06-07T21:32:55.887Z",
      },
      {
        id: "clx57ctqs011o12vcmrdgzlqn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "esWEzlfQ3t",
            locked: 1717795896,
            timestamp: 1717795895,
          },
        ],
        createdAt: "2024-06-07T21:32:29.717Z",
        updatedAt: "2024-06-07T21:32:29.717Z",
      },
      {
        id: "clx57csut011n12vcqc0jik9l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YhV9sCQxq0",
            locked: 1717795947,
            timestamp: 1717795946,
          },
        ],
        createdAt: "2024-06-07T21:32:28.563Z",
        updatedAt: "2024-06-07T21:32:28.563Z",
      },
      {
        id: "clx57ciia011m12vcyvwk3r55",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "kq2lSvxMnP",
            locked: 1717795934,
            timestamp: 1717795929,
          },
        ],
        createdAt: "2024-06-07T21:32:15.154Z",
        updatedAt: "2024-06-07T21:32:15.154Z",
      },
      {
        id: "clx57cfta011l12vcbkvm5q1d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5AU0IXtfLK",
            locked: 1717795929,
            timestamp: 1717795924,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "wJgVeJG8BI",
            locked: 1717795929,
            timestamp: 1717795927,
          },
        ],
        createdAt: "2024-06-07T21:32:11.662Z",
        updatedAt: "2024-06-07T21:32:11.662Z",
      },
      {
        id: "clx57cery011k12vc31wq69v7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 328.1.3.32.89 (iPhone14,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 591155251) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rpZ1DdfFsH",
            locked: 1717795929,
            timestamp: 1717795928,
          },
        ],
        createdAt: "2024-06-07T21:32:10.318Z",
        updatedAt: "2024-06-07T21:32:10.318Z",
      },
      {
        id: "clx57cbdd011j12vc6l936ewy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; es_US; es; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Zqi9InlbeQ",
            locked: 1717795925,
            timestamp: 1717795923,
          },
        ],
        createdAt: "2024-06-07T21:32:05.902Z",
        updatedAt: "2024-06-07T21:32:05.902Z",
      },
      {
        id: "clx57c2az011i12vc3x8rv47r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "7q9e3K5L1N",
            locked: 1717795914,
            timestamp: 1717795913,
          },
        ],
        createdAt: "2024-06-07T21:31:54.155Z",
        updatedAt: "2024-06-07T21:31:54.155Z",
      },
      {
        id: "clx57bypb011h12vcox0g0u74",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YAVWZzIgGI",
            locked: 1717795909,
            timestamp: 1717795906,
          },
        ],
        createdAt: "2024-06-07T21:31:49.484Z",
        updatedAt: "2024-06-07T21:31:49.484Z",
      },
      {
        id: "clx57b95e011g12vcsvs1ughq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0yr3H4oBYU",
            locked: 1717795875,
            timestamp: 1717795874,
          },
        ],
        createdAt: "2024-06-07T21:31:16.368Z",
        updatedAt: "2024-06-07T21:31:16.368Z",
      },
      {
        id: "clx57avjy011f12vcnjb8eljc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21A350 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_0_2; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "iUk0C3MH0f",
            locked: 1717795857,
            timestamp: 1717795856,
          },
        ],
        createdAt: "2024-06-07T21:30:58.748Z",
        updatedAt: "2024-06-07T21:30:58.748Z",
      },
      {
        id: "clx57alnq011e12vcac39edqy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OSCYjUAvwj",
            locked: 1717795845,
            timestamp: 1717795840,
          },
        ],
        createdAt: "2024-06-07T21:30:45.926Z",
        updatedAt: "2024-06-07T21:30:45.926Z",
      },
      {
        id: "clx57aipz011d12vcflc0h6qs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone16,2; iOS 17_4_1; en_CA; en; scale=3.00; 1290x2796; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5EKuKs3CeP",
            locked: 1717795841,
            timestamp: 1717795836,
          },
        ],
        createdAt: "2024-06-07T21:30:42.117Z",
        updatedAt: "2024-06-07T21:30:42.117Z",
      },
      {
        id: "clx579xr9011c12vcwkqqbj3j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone16,2; iOS 17_4_1; en_CA; en; scale=3.00; 1290x2796; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uVr6kADUn2",
            locked: 1717795813,
            timestamp: 1717795812,
          },
        ],
        createdAt: "2024-06-07T21:30:14.946Z",
        updatedAt: "2024-06-07T21:30:14.946Z",
      },
      {
        id: "clx579dw3011b12vcveixulsr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.1.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "IAzurBpCuC",
            locked: 1717795789,
            timestamp: 1717795788,
          },
        ],
        createdAt: "2024-06-07T21:29:49.203Z",
        updatedAt: "2024-06-07T21:29:49.203Z",
      },
      {
        id: "clx5799vw011a12vcnsk9timi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.1.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hVgvbPjrJs",
            locked: 1717795782,
            timestamp: 1717795780,
          },
        ],
        createdAt: "2024-06-07T21:29:44.009Z",
        updatedAt: "2024-06-07T21:29:44.009Z",
      },
      {
        id: "clx578uby011912vczktl5ktv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "prcH7jHbVF",
            locked: 1717795763,
            timestamp: 1717795762,
          },
        ],
        createdAt: "2024-06-07T21:29:23.853Z",
        updatedAt: "2024-06-07T21:29:23.853Z",
      },
      {
        id: "clx578p1g011812vcsfpxzavp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.2 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "6us8l8QJIP",
            locked: 1717795756,
            timestamp: 1717795752,
          },
        ],
        createdAt: "2024-06-07T21:29:16.996Z",
        updatedAt: "2024-06-07T21:29:16.996Z",
      },
      {
        id: "clx578ogv011712vcf2p5bk0v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lwWOP7pWFw",
            locked: 1717795756,
            timestamp: 1717795754,
          },
        ],
        createdAt: "2024-06-07T21:29:16.256Z",
        updatedAt: "2024-06-07T21:29:16.256Z",
      },
      {
        id: "clx578f2l011612vc7jbp4j3r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "x0Wi3A7NI7",
            locked: 1717795743,
            timestamp: 1717795741,
          },
        ],
        createdAt: "2024-06-07T21:29:04.075Z",
        updatedAt: "2024-06-07T21:29:04.075Z",
      },
      {
        id: "clx577ovm011512vc27xa3lpi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Stt8g3iuAP",
            locked: 1717795709,
            timestamp: 1717795708,
          },
        ],
        createdAt: "2024-06-07T21:28:30.127Z",
        updatedAt: "2024-06-07T21:28:30.127Z",
      },
      {
        id: "clx577grs011412vcqm82gpav",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "A4tZ6tXyHo",
            locked: 1717795699,
            timestamp: 1717795697,
          },
        ],
        createdAt: "2024-06-07T21:28:19.624Z",
        updatedAt: "2024-06-07T21:28:19.624Z",
      },
      {
        id: "clx577bie011312vcpg5nn0qk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PS8182uoNs",
            locked: 1717795692,
            timestamp: 1717795690,
          },
        ],
        createdAt: "2024-06-07T21:28:12.804Z",
        updatedAt: "2024-06-07T21:28:12.804Z",
      },
      {
        id: "clx575buo011212vc6ua3e2ow",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "5MQlkJYJUf",
            locked: 1717795599,
            timestamp: 1717795598,
          },
        ],
        createdAt: "2024-06-07T21:26:39.934Z",
        updatedAt: "2024-06-07T21:26:39.934Z",
      },
      {
        id: "clx574rum011112vcxoc3jnsn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3bClbkaC6n",
            locked: 1717795573,
            timestamp: 1717795572,
          },
        ],
        createdAt: "2024-06-07T21:26:14.012Z",
        updatedAt: "2024-06-07T21:26:14.012Z",
      },
      {
        id: "clx574bue011012vc96zx6wl6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "H6EK8FZkzU",
            locked: 1717795550,
            timestamp: 1717795548,
          },
        ],
        createdAt: "2024-06-07T21:25:53.270Z",
        updatedAt: "2024-06-07T21:25:53.270Z",
      },
      {
        id: "clx5749km010z12vcccfzf4mx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hLbHvmDOa0",
            locked: 1717795550,
            timestamp: 1717795548,
          },
        ],
        createdAt: "2024-06-07T21:25:50.325Z",
        updatedAt: "2024-06-07T21:25:50.325Z",
      },
      {
        id: "clx5745g5010y12vch4brg5y7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "TMlFq9cm4u",
            locked: 1717795542,
            timestamp: 1717795540,
          },
        ],
        createdAt: "2024-06-07T21:25:44.982Z",
        updatedAt: "2024-06-07T21:25:44.982Z",
      },
      {
        id: "clx573zug010x12vc3gxlvhx3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HguEFlzng6",
            locked: 1717795537,
            timestamp: 1717795535,
          },
        ],
        createdAt: "2024-06-07T21:25:37.721Z",
        updatedAt: "2024-06-07T21:25:37.721Z",
      },
      {
        id: "clx573wiv010w12vcoyvtml08",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9NcOIF7pW8",
            locked: 1717795532,
            timestamp: 1717795531,
          },
        ],
        createdAt: "2024-06-07T21:25:33.413Z",
        updatedAt: "2024-06-07T21:25:33.413Z",
      },
      {
        id: "clx573fur010v12vc4x54fuqk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Idkm69kvdM",
            locked: 1717795511,
            timestamp: 1717795510,
          },
        ],
        createdAt: "2024-06-07T21:25:11.811Z",
        updatedAt: "2024-06-07T21:25:11.811Z",
      },
      {
        id: "clx573fcu010u12vcdqaj7fld",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "X9XiE5ZGBb",
            locked: 1717795510,
            timestamp: 1717795509,
          },
        ],
        createdAt: "2024-06-07T21:25:11.167Z",
        updatedAt: "2024-06-07T21:25:11.167Z",
      },
      {
        id: "clx5734p3010t12vcbdvtpw8z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "eaVZR0uyjg",
            locked: 1717795497,
            timestamp: 1717795496,
          },
        ],
        createdAt: "2024-06-07T21:24:57.352Z",
        updatedAt: "2024-06-07T21:24:57.352Z",
      },
      {
        id: "clx5734cd010s12vcto3dl1pf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "as4aQj88q0",
            locked: 1717795496,
            timestamp: 1717795495,
          },
        ],
        createdAt: "2024-06-07T21:24:56.892Z",
        updatedAt: "2024-06-07T21:24:56.892Z",
      },
      {
        id: "clx5731xs010r12vceqo3na4w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7kBUPwAlru",
            locked: 1717795493,
            timestamp: 1717795491,
          },
        ],
        createdAt: "2024-06-07T21:24:53.777Z",
        updatedAt: "2024-06-07T21:24:53.777Z",
      },
      {
        id: "clx572vzg010q12vciopk26pi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gFTOmh17l9",
            locked: 1717795485,
            timestamp: 1717795481,
          },
        ],
        createdAt: "2024-06-07T21:24:46.060Z",
        updatedAt: "2024-06-07T21:24:46.060Z",
      },
      {
        id: "clx572ris010p12vcqp3gd5eu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vceTymn3y4",
            locked: 1717795479,
            timestamp: 1717795478,
          },
        ],
        createdAt: "2024-06-07T21:24:40.274Z",
        updatedAt: "2024-06-07T21:24:40.274Z",
      },
      {
        id: "clx572due010o12vcqc1ps29k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Pe3Wkm13TD",
            locked: 1717795462,
            timestamp: 1717795461,
          },
        ],
        createdAt: "2024-06-07T21:24:22.550Z",
        updatedAt: "2024-06-07T21:24:22.550Z",
      },
      {
        id: "clx572bki010n12vczlv8ybgq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dLos9knUem",
            locked: 1717795459,
            timestamp: 1717795456,
          },
        ],
        createdAt: "2024-06-07T21:24:19.603Z",
        updatedAt: "2024-06-07T21:24:19.603Z",
      },
      {
        id: "clx572bhi010m12vcbj0hf46a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CzV7uvYlJB",
            locked: 1717795458,
            timestamp: 1717795457,
          },
        ],
        createdAt: "2024-06-07T21:24:19.493Z",
        updatedAt: "2024-06-07T21:24:19.493Z",
      },
      {
        id: "clx5726ap010l12vcbcg949ph",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TEfiPqDOQ9",
            locked: 1717795452,
            timestamp: 1717795450,
          },
        ],
        createdAt: "2024-06-07T21:24:12.770Z",
        updatedAt: "2024-06-07T21:24:12.770Z",
      },
      {
        id: "clx5720th010k12vcs3biieiz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4dbie3CGzf",
            locked: 1717795445,
            timestamp: 1717795443,
          },
        ],
        createdAt: "2024-06-07T21:24:05.669Z",
        updatedAt: "2024-06-07T21:24:05.669Z",
      },
      {
        id: "clx571zjp010j12vcy311hxer",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "EPThnbIGX6",
            locked: 1717795443,
            timestamp: 1717795442,
          },
        ],
        createdAt: "2024-06-07T21:24:04.020Z",
        updatedAt: "2024-06-07T21:24:04.020Z",
      },
      {
        id: "clx571vfc010i12vcztco2w2f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "gxlRiOPsTI",
            locked: 1717795438,
            timestamp: 1717795437,
          },
        ],
        createdAt: "2024-06-07T21:23:58.681Z",
        updatedAt: "2024-06-07T21:23:58.681Z",
      },
      {
        id: "clx571t46010h12vceim6bczv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Kn0n8dz8jr",
            locked: 1717795435,
            timestamp: 1717795434,
          },
        ],
        createdAt: "2024-06-07T21:23:55.686Z",
        updatedAt: "2024-06-07T21:23:55.686Z",
      },
      {
        id: "clx571ssp010g12vc9d6pw4vx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OcVipvwR8o",
            locked: 1717795434,
            timestamp: 1717795433,
          },
        ],
        createdAt: "2024-06-07T21:23:55.274Z",
        updatedAt: "2024-06-07T21:23:55.274Z",
      },
      {
        id: "clx571ntq010f12vc81rp9u8a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zIdMfbrMZv",
            locked: 1717795428,
            timestamp: 1717795425,
          },
        ],
        createdAt: "2024-06-07T21:23:48.828Z",
        updatedAt: "2024-06-07T21:23:48.828Z",
      },
      {
        id: "clx570mh6010e12vce8fryu19",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 334.0.4.32.98 (iPhone14,2; iOS 15_6_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "bSxPJaUfUf",
            locked: 1717795380,
            timestamp: 1717795379,
          },
        ],
        createdAt: "2024-06-07T21:23:00.425Z",
        updatedAt: "2024-06-07T21:23:00.425Z",
      },
      {
        id: "clx570h88010d12vcqo59od89",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "N9k9UE7Ggc",
            locked: 1717795372,
            timestamp: 1717795371,
          },
        ],
        createdAt: "2024-06-07T21:22:53.625Z",
        updatedAt: "2024-06-07T21:22:53.625Z",
      },
      {
        id: "clx570h4n010c12vc8njoe7q9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FeSkISbq6p",
            locked: 1717795372,
            timestamp: 1717795371,
          },
        ],
        createdAt: "2024-06-07T21:22:53.495Z",
        updatedAt: "2024-06-07T21:22:53.495Z",
      },
      {
        id: "clx570bt8010b12vcxfeo914m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 334.0.4.32.98 (iPhone14,2; iOS 15_6_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YbXE8mTsSG",
            locked: 1717795365,
            timestamp: 1717795364,
          },
        ],
        createdAt: "2024-06-07T21:22:46.604Z",
        updatedAt: "2024-06-07T21:22:46.604Z",
      },
      {
        id: "clx570a97010a12vcwa9o7vka",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "2Y9gE1JLkh",
            locked: 1717795364,
            timestamp: 1717795363,
          },
        ],
        createdAt: "2024-06-07T21:22:44.586Z",
        updatedAt: "2024-06-07T21:22:44.586Z",
      },
      {
        id: "clx5706x4010912vc2bbmt2pd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JI3s8pN2Fm",
            locked: 1717795359,
            timestamp: 1717795357,
          },
        ],
        createdAt: "2024-06-07T21:22:40.264Z",
        updatedAt: "2024-06-07T21:22:40.264Z",
      },
      {
        id: "clx56zxnq010812vcpnce9l10",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "bgBRzk9UEH",
            locked: 1717795347,
            timestamp: 1717795346,
          },
        ],
        createdAt: "2024-06-07T21:22:28.261Z",
        updatedAt: "2024-06-07T21:22:28.261Z",
      },
      {
        id: "clx56zqoa010712vc9enem01m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9Ebj1CDE46",
            locked: 1717795338,
            timestamp: 1717795332,
          },
        ],
        createdAt: "2024-06-07T21:22:19.211Z",
        updatedAt: "2024-06-07T21:22:19.211Z",
      },
      {
        id: "clx56zlhs010612vc88rntry5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "r5GTODAESd",
            locked: 1717795331,
            timestamp: 1717795330,
          },
        ],
        createdAt: "2024-06-07T21:22:12.496Z",
        updatedAt: "2024-06-07T21:22:12.496Z",
      },
      {
        id: "clx56zjvw010512vcm3qqfy4z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 750x1624; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "fM1tSrG4MD",
            locked: 1717795330,
            timestamp: 1717795329,
          },
        ],
        createdAt: "2024-06-07T21:22:10.411Z",
        updatedAt: "2024-06-07T21:22:10.411Z",
      },
      {
        id: "clx56zeyu010412vc4xfqfcd0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "at11oZdMFl",
            locked: 1717795323,
            timestamp: 1717795322,
          },
        ],
        createdAt: "2024-06-07T21:22:04.038Z",
        updatedAt: "2024-06-07T21:22:04.038Z",
      },
      {
        id: "clx56zcc9010312vcezfyn5ic",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "apVZ0Oh9zM",
            locked: 1717795316,
            timestamp: 1717795315,
          },
        ],
        createdAt: "2024-06-07T21:22:00.633Z",
        updatedAt: "2024-06-07T21:22:00.633Z",
      },
      {
        id: "clx56z8ma010212vcy690cvht",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "5AJXKxGey2",
            locked: 1717795315,
            timestamp: 1717795314,
          },
        ],
        createdAt: "2024-06-07T21:21:55.811Z",
        updatedAt: "2024-06-07T21:21:55.811Z",
      },
      {
        id: "clx56z8m5010112vc0wootfp7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 750x1624; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3TytqC5tNT",
            locked: 1717795313,
            timestamp: 1717795312,
          },
        ],
        createdAt: "2024-06-07T21:21:55.805Z",
        updatedAt: "2024-06-07T21:21:55.805Z",
      },
      {
        id: "clx56z6af010012vcnqpm4qlb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xgWttk428y",
            locked: 1717795310,
            timestamp: 1717795309,
          },
        ],
        createdAt: "2024-06-07T21:21:52.789Z",
        updatedAt: "2024-06-07T21:21:52.789Z",
      },
      {
        id: "clx56yw8p00zz12vcfcndh8lj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "A8RWTCvbCy",
            locked: 1717795299,
            timestamp: 1717795297,
          },
        ],
        createdAt: "2024-06-07T21:21:39.770Z",
        updatedAt: "2024-06-07T21:21:39.770Z",
      },
      {
        id: "clx56ykoz00zy12vc6448u8hl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "P5W0TIkWKu",
            locked: 1717795284,
            timestamp: 1717795283,
          },
        ],
        createdAt: "2024-06-07T21:21:24.801Z",
        updatedAt: "2024-06-07T21:21:24.801Z",
      },
      {
        id: "clx56yc5q00zx12vcp9h21vxb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "KeMJEBth2R",
            locked: 1717795273,
            timestamp: 1717795272,
          },
        ],
        createdAt: "2024-06-07T21:21:13.742Z",
        updatedAt: "2024-06-07T21:21:13.742Z",
      },
      {
        id: "clx56y5ms00zw12vcy2xb5zc9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GbK3DRVvfJ",
            locked: 1717795264,
            timestamp: 1717795262,
          },
        ],
        createdAt: "2024-06-07T21:21:05.284Z",
        updatedAt: "2024-06-07T21:21:05.284Z",
      },
      {
        id: "clx56y58d00zv12vc0a810ipt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; M2010J19SG Build/SKQ1.211202.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 440dpi; 1080x2218; Xiaomi/Redmi; M2010J19SG; lime; qcom; en_GB; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "zUAZpSItFt",
            locked: 1717795264,
            timestamp: 1717795263,
          },
        ],
        createdAt: "2024-06-07T21:21:04.764Z",
        updatedAt: "2024-06-07T21:21:04.764Z",
      },
      {
        id: "clx56y4a100zu12vcti5esoec",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "omVOWBCsWZ",
            locked: 1717795263,
            timestamp: 1717795262,
          },
        ],
        createdAt: "2024-06-07T21:21:03.530Z",
        updatedAt: "2024-06-07T21:21:03.530Z",
      },
      {
        id: "clx56y3k600zt12vcirj7c89y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FhzfNRcmCb",
            locked: 1717795260,
            timestamp: 1717795258,
          },
        ],
        createdAt: "2024-06-07T21:21:02.598Z",
        updatedAt: "2024-06-07T21:21:02.598Z",
      },
      {
        id: "clx56xta800zs12vc0l3iv80h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; M2010J19SG Build/SKQ1.211202.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 440dpi; 1080x2218; Xiaomi/Redmi; M2010J19SG; lime; qcom; en_GB; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "n3qF5VSqsF",
            locked: 1717795248,
            timestamp: 1717795243,
          },
        ],
        createdAt: "2024-06-07T21:20:49.278Z",
        updatedAt: "2024-06-07T21:20:49.278Z",
      },
      {
        id: "clx56xjrm00zr12vcfze4fj6c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EolWDvmcyc",
            locked: 1717795236,
            timestamp: 1717795235,
          },
        ],
        createdAt: "2024-06-07T21:20:36.946Z",
        updatedAt: "2024-06-07T21:20:36.946Z",
      },
      {
        id: "clx56xhmq00zq12vcnzq2q5vu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CjmCD24Ycb",
            locked: 1717795233,
            timestamp: 1717795232,
          },
        ],
        createdAt: "2024-06-07T21:20:34.176Z",
        updatedAt: "2024-06-07T21:20:34.176Z",
      },
      {
        id: "clx56wjb500zp12vcy1sbl2zc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "uoW4eaB1Hn",
            locked: 1717795190,
            timestamp: 1717795189,
          },
        ],
        createdAt: "2024-06-07T21:19:49.697Z",
        updatedAt: "2024-06-07T21:19:49.697Z",
      },
      {
        id: "clx56wi4e00zo12vcz9gbuixe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wwRPfCQOQM",
            locked: 1717795188,
            timestamp: 1717795185,
          },
        ],
        createdAt: "2024-06-07T21:19:48.155Z",
        updatedAt: "2024-06-07T21:19:48.155Z",
      },
      {
        id: "clx56w9j600zn12vcixjpqi7m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "cxZtgKoqx4",
            locked: 1717795176,
            timestamp: 1717795175,
          },
        ],
        createdAt: "2024-06-07T21:19:37.026Z",
        updatedAt: "2024-06-07T21:19:37.026Z",
      },
      {
        id: "clx56w6x700zm12vcm90ejoo1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YPJvTjPbSz",
            locked: 1717795172,
            timestamp: 1717795171,
          },
        ],
        createdAt: "2024-06-07T21:19:33.643Z",
        updatedAt: "2024-06-07T21:19:33.643Z",
      },
      {
        id: "clx56w3c100zl12vcwc8buj0n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "630TFoppwt",
            locked: 1717795168,
            timestamp: 1717795167,
          },
        ],
        createdAt: "2024-06-07T21:19:28.991Z",
        updatedAt: "2024-06-07T21:19:28.991Z",
      },
      {
        id: "clx56vo7r00zk12vctcw2e1rf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "SSWEDSs8Cf",
            locked: 1717795149,
            timestamp: 1717795148,
          },
        ],
        createdAt: "2024-06-07T21:19:09.399Z",
        updatedAt: "2024-06-07T21:19:09.399Z",
      },
      {
        id: "clx56vo0n00zj12vcwvmz6nnq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vCdPmu3zlX",
            locked: 1717795148,
            timestamp: 1717795146,
          },
        ],
        createdAt: "2024-06-07T21:19:09.144Z",
        updatedAt: "2024-06-07T21:19:09.144Z",
      },
      {
        id: "clx56vnxl00zi12vcaol8payq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Wb1LwIvaKT",
            locked: 1717795148,
            timestamp: 1717795147,
          },
        ],
        createdAt: "2024-06-07T21:19:09.033Z",
        updatedAt: "2024-06-07T21:19:09.033Z",
      },
      {
        id: "clx56vkk900zh12vc8s9vhcle",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LlY7he8e8S",
            locked: 1717795144,
            timestamp: 1717795142,
          },
        ],
        createdAt: "2024-06-07T21:19:04.666Z",
        updatedAt: "2024-06-07T21:19:04.666Z",
      },
      {
        id: "clx56vjlw00zg12vc79w0kkep",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "NI8g7pxygR",
            locked: 1717795143,
            timestamp: 1717795142,
          },
        ],
        createdAt: "2024-06-07T21:19:03.428Z",
        updatedAt: "2024-06-07T21:19:03.428Z",
      },
      {
        id: "clx56vgss00zf12vc7w4vmoi1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WaO9uDPy6T",
            locked: 1717795139,
            timestamp: 1717795138,
          },
        ],
        createdAt: "2024-06-07T21:18:59.786Z",
        updatedAt: "2024-06-07T21:18:59.786Z",
      },
      {
        id: "clx56v1qn00ze12vcdjo06xpt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2241; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "a5khA3dxh8",
            locked: 1717795120,
            timestamp: 1717795119,
          },
        ],
        createdAt: "2024-06-07T21:18:40.272Z",
        updatedAt: "2024-06-07T21:18:40.272Z",
      },
      {
        id: "clx56v0c000zd12vcvr4kofo9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "29FzfZFqGJ",
            locked: 1717795117,
            timestamp: 1717795116,
          },
        ],
        createdAt: "2024-06-07T21:18:38.448Z",
        updatedAt: "2024-06-07T21:18:38.448Z",
      },
      {
        id: "clx56uyjh00zc12vctzaw9473",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "GbtX0mwlV0",
            locked: 1717795116,
            timestamp: 1717795115,
          },
        ],
        createdAt: "2024-06-07T21:18:36.126Z",
        updatedAt: "2024-06-07T21:18:36.126Z",
      },
      {
        id: "clx56uxvk00zb12vcmba11s7j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aR3ZK1EUI6",
            locked: 1717795114,
            timestamp: 1717795113,
          },
        ],
        createdAt: "2024-06-07T21:18:35.264Z",
        updatedAt: "2024-06-07T21:18:35.264Z",
      },
      {
        id: "clx56uwip00za12vc5smwt8d1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2241; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qf5adnVCEK",
            locked: 1717795113,
            timestamp: 1717795109,
          },
        ],
        createdAt: "2024-06-07T21:18:33.502Z",
        updatedAt: "2024-06-07T21:18:33.502Z",
      },
      {
        id: "clx56tv3500z912vc4cwm02y4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "eHnpjRISos",
            locked: 1717795063,
            timestamp: 1717795061,
          },
        ],
        createdAt: "2024-06-07T21:17:44.991Z",
        updatedAt: "2024-06-07T21:17:44.991Z",
      },
      {
        id: "clx56stlu00z812vciyhnggs2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bI37tFxiKs",
            locked: 1717795015,
            timestamp: 1717795013,
          },
        ],
        createdAt: "2024-06-07T21:16:56.416Z",
        updatedAt: "2024-06-07T21:16:56.416Z",
      },
      {
        id: "clx56rz1j00z712vcjwkk1mn3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "HAlfKXQQ9f",
            locked: 1717794976,
            timestamp: 1717794975,
          },
        ],
        createdAt: "2024-06-07T21:16:16.808Z",
        updatedAt: "2024-06-07T21:16:16.808Z",
      },
      {
        id: "clx56rwak00z612vcinctm9op",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hB1TJGaKFC",
            locked: 1717794972,
            timestamp: 1717794971,
          },
        ],
        createdAt: "2024-06-07T21:16:13.245Z",
        updatedAt: "2024-06-07T21:16:13.245Z",
      },
      {
        id: "clx56rt3p00z512vc123gcpa0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "UjEMOgsP9c",
            locked: 1717794969,
            timestamp: 1717794968,
          },
        ],
        createdAt: "2024-06-07T21:16:09.107Z",
        updatedAt: "2024-06-07T21:16:09.107Z",
      },
      {
        id: "clx56r0j800z412vcstbh8v7m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9cwgDurPnb",
            locked: 1717794931,
            timestamp: 1717794930,
          },
        ],
        createdAt: "2024-06-07T21:15:32.084Z",
        updatedAt: "2024-06-07T21:15:32.084Z",
      },
      {
        id: "clx56qtcz00z312vcswsawhos",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "aGcxnAMsqM",
            locked: 1717794922,
            timestamp: 1717794921,
          },
        ],
        createdAt: "2024-06-07T21:15:22.788Z",
        updatedAt: "2024-06-07T21:15:22.788Z",
      },
      {
        id: "clx56qrtl00z212vcvq6mmedq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ScA4Lqj64N",
            locked: 1717794920,
            timestamp: 1717794919,
          },
        ],
        createdAt: "2024-06-07T21:15:20.792Z",
        updatedAt: "2024-06-07T21:15:20.792Z",
      },
      {
        id: "clx56qpy400z112vcq6kdfoi7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "5BYnaH6Unk",
            locked: 1717794918,
            timestamp: 1717794917,
          },
        ],
        createdAt: "2024-06-07T21:15:18.365Z",
        updatedAt: "2024-06-07T21:15:18.365Z",
      },
      {
        id: "clx56qpga00z012vccfa26k08",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yhzYE9bG98",
            locked: 1717794917,
            timestamp: 1717794916,
          },
        ],
        createdAt: "2024-06-07T21:15:17.723Z",
        updatedAt: "2024-06-07T21:15:17.723Z",
      },
      {
        id: "clx56qlzu00yz12vcyjxl607r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wzsG3x67ks",
            locked: 1717794912,
            timestamp: 1717794911,
          },
        ],
        createdAt: "2024-06-07T21:15:13.243Z",
        updatedAt: "2024-06-07T21:15:13.243Z",
      },
      {
        id: "clx56qf8800yy12vccahbldnz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QM4Z1mzuO2",
            locked: 1717794903,
            timestamp: 1717794902,
          },
        ],
        createdAt: "2024-06-07T21:15:04.468Z",
        updatedAt: "2024-06-07T21:15:04.468Z",
      },
      {
        id: "clx56pqui00yx12vca7jv2a1i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "kJ6XR4JEMI",
            locked: 1717794872,
            timestamp: 1717794871,
          },
        ],
        createdAt: "2024-06-07T21:14:32.875Z",
        updatedAt: "2024-06-07T21:14:32.875Z",
      },
      {
        id: "clx56pn6i00yw12vcop5flrsy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tUxxM8kstB",
            locked: 1717794867,
            timestamp: 1717794866,
          },
        ],
        createdAt: "2024-06-07T21:14:28.120Z",
        updatedAt: "2024-06-07T21:14:28.120Z",
      },
      {
        id: "clx56ob0y00yv12vcxfe2p37e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A136U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 300dpi; 720x1465; samsung; SM-A136U; a13x; mt6833; en_US; 606558517)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Fos4f1gfKF",
            locked: 1717794805,
            timestamp: 1717794800,
          },
        ],
        createdAt: "2024-06-07T21:13:25.714Z",
        updatedAt: "2024-06-07T21:13:25.714Z",
      },
      {
        id: "clx56nzsw00yu12vch37zevfe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Xa5bvFX6U5",
            locked: 1717794789,
            timestamp: 1717794788,
          },
        ],
        createdAt: "2024-06-07T21:13:11.165Z",
        updatedAt: "2024-06-07T21:13:11.165Z",
      },
      {
        id: "clx56niu300yt12vckcbmpsg4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "phU6xj8PJy",
            locked: 1717794769,
            timestamp: 1717794768,
          },
        ],
        createdAt: "2024-06-07T21:12:49.178Z",
        updatedAt: "2024-06-07T21:12:49.178Z",
      },
      {
        id: "clx56ndmq00ys12vcr8ijk3w1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "atSeybqccd",
            locked: 1717794762,
            timestamp: 1717794757,
          },
        ],
        createdAt: "2024-06-07T21:12:42.434Z",
        updatedAt: "2024-06-07T21:12:42.434Z",
      },
      {
        id: "clx56nc1c00yr12vcd0mkeq41",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tBPt3G5xT9",
            locked: 1717794643,
            timestamp: 1717794642,
          },
        ],
        createdAt: "2024-06-07T21:12:40.368Z",
        updatedAt: "2024-06-07T21:12:40.368Z",
      },
      {
        id: "clx56n5gm00yq12vchsupn5cl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0P45UPXRew",
            locked: 1717794751,
            timestamp: 1717794749,
          },
        ],
        createdAt: "2024-06-07T21:12:31.844Z",
        updatedAt: "2024-06-07T21:12:31.844Z",
      },
      {
        id: "clx56lrnl00yp12vcju1j9t51",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "fjuAoPVWk7",
            locked: 1717794687,
            timestamp: 1717794686,
          },
        ],
        createdAt: "2024-06-07T21:11:27.297Z",
        updatedAt: "2024-06-07T21:11:27.297Z",
      },
      {
        id: "clx56lplr00yo12vcs8e5mkx7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KcfXd52dYO",
            locked: 1717794684,
            timestamp: 1717794683,
          },
        ],
        createdAt: "2024-06-07T21:11:24.636Z",
        updatedAt: "2024-06-07T21:11:24.636Z",
      },
      {
        id: "clx56icvb00yn12vcjpva3sj7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "BufPfyGupD",
            locked: 1717794527,
            timestamp: 1717794525,
          },
        ],
        createdAt: "2024-06-07T21:08:48.164Z",
        updatedAt: "2024-06-07T21:08:48.164Z",
      },
      {
        id: "clx56hthk00ym12vca4gr8vax",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qfbO3k7Xmd",
            locked: 1717794502,
            timestamp: 1717794501,
          },
        ],
        createdAt: "2024-06-07T21:08:23.046Z",
        updatedAt: "2024-06-07T21:08:23.046Z",
      },
      {
        id: "clx56ha8b00yl12vcjt4d3j9d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "k78ta5nIPI",
            locked: 1717794477,
            timestamp: 1717794476,
          },
        ],
        createdAt: "2024-06-07T21:07:58.089Z",
        updatedAt: "2024-06-07T21:07:58.089Z",
      },
      {
        id: "clx56gyis00yk12vc5k1nob2a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ExsUEtJfPk",
            locked: 1717794462,
            timestamp: 1717794461,
          },
        ],
        createdAt: "2024-06-07T21:07:42.916Z",
        updatedAt: "2024-06-07T21:07:42.916Z",
      },
      {
        id: "clx56gozw00yj12vcieh99jz4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kPaMpEfOXZ",
            locked: 1717794450,
            timestamp: 1717794448,
          },
        ],
        createdAt: "2024-06-07T21:07:30.569Z",
        updatedAt: "2024-06-07T21:07:30.569Z",
      },
      {
        id: "clx56fgff00yi12vcp69cvthu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fQsDxK8es2",
            locked: 1717794392,
            timestamp: 1717794391,
          },
        ],
        createdAt: "2024-06-07T21:06:32.808Z",
        updatedAt: "2024-06-07T21:06:32.808Z",
      },
      {
        id: "clx56e02400yh12vc18e8kpeb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "4lOxBPItir",
            locked: 1717794324,
            timestamp: 1717794323,
          },
        ],
        createdAt: "2024-06-07T21:05:24.940Z",
        updatedAt: "2024-06-07T21:05:24.940Z",
      },
      {
        id: "clx56dxr800yg12vcpjtrpqv3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "oUUBadfuuB",
            locked: 1717794321,
            timestamp: 1717794319,
          },
        ],
        createdAt: "2024-06-07T21:05:21.952Z",
        updatedAt: "2024-06-07T21:05:21.952Z",
      },
      {
        id: "clx56dkt500yf12vc4sfl05tu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "BRTiSHhgDb",
            locked: 1717794304,
            timestamp: 1717794303,
          },
        ],
        createdAt: "2024-06-07T21:05:05.128Z",
        updatedAt: "2024-06-07T21:05:05.128Z",
      },
      {
        id: "clx56dbku00ye12vcw2hg39eo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 13_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 244.0.0.12.112 (iPhone10,4; iOS 13_4_1; en_US; en-US; scale=2.00; 750x1334; 383361019)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vZ3v3bdaCJ",
            locked: 1717794292,
            timestamp: 1717794289,
          },
        ],
        createdAt: "2024-06-07T21:04:53.214Z",
        updatedAt: "2024-06-07T21:04:53.214Z",
      },
      {
        id: "clx56db3r00yd12vcm6um81u2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; LG-G710 Build/PKQ1.181105.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (28/9; 560dpi; 1440x2858; LGE/lge; LG-G710; judyln; judyln; en_US; 606558449)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qcKDHF0CbE",
            locked: 1717794292,
            timestamp: 1717794284,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "CBqolzDulS",
            locked: 1717794292,
            timestamp: 1717794291,
          },
        ],
        createdAt: "2024-06-07T21:04:52.599Z",
        updatedAt: "2024-06-07T21:04:52.599Z",
      },
      {
        id: "clx56d8wp00yc12vccnvvk6a1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-A716V Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 420dpi; 1080x2183; samsung; SM-A716V; a71xq; qcom; en_US; 606558499)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HmXiEnseuz",
            locked: 1717794289,
            timestamp: 1717794285,
          },
        ],
        createdAt: "2024-06-07T21:04:49.750Z",
        updatedAt: "2024-06-07T21:04:49.750Z",
      },
      {
        id: "clx56cdud00yb12vc1fdbkzmh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad7,3; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nB48eCjTIk",
            locked: 1717794248,
            timestamp: 1717794246,
          },
        ],
        createdAt: "2024-06-07T21:04:09.493Z",
        updatedAt: "2024-06-07T21:04:09.493Z",
      },
      {
        id: "clx56caz100ya12vc6535fyck",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 315.1.2.21.112 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 559614958)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "wtrQN2rJCr",
            locked: 1717794245,
            timestamp: 1717794244,
          },
        ],
        createdAt: "2024-06-07T21:04:05.773Z",
        updatedAt: "2024-06-07T21:04:05.773Z",
      },
      {
        id: "clx56c9hb00y912vcmdj9qr4o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 315.1.2.21.112 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 559614958)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Hj98CDOHyY",
            locked: 1717794243,
            timestamp: 1717794240,
          },
        ],
        createdAt: "2024-06-07T21:04:03.836Z",
        updatedAt: "2024-06-07T21:04:03.836Z",
      },
      {
        id: "clx56brua00y812vcucexbysp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ur7uLAsv6D",
            locked: 1717794220,
            timestamp: 1717794219,
          },
        ],
        createdAt: "2024-06-07T21:03:40.976Z",
        updatedAt: "2024-06-07T21:03:40.976Z",
      },
      {
        id: "clx56bc8t00y712vcdfhu02q8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "tv69NA7hnh",
            locked: 1717794198,
            timestamp: 1717794197,
          },
        ],
        createdAt: "2024-06-07T21:03:20.765Z",
        updatedAt: "2024-06-07T21:03:20.765Z",
      },
      {
        id: "clx56b6hb00y612vcsch1vjhu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "x9ul1uMHmD",
            locked: 1717794191,
            timestamp: 1717794189,
          },
        ],
        createdAt: "2024-06-07T21:03:13.293Z",
        updatedAt: "2024-06-07T21:03:13.293Z",
      },
      {
        id: "clx56askr00y512vck3qk9l2g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 264.3.0.19.104 (iPad6,3; iOS 13_3_1; en_US; en-US; scale=2.00; 750x1334; 432065435)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OtIGP2sQYe",
            locked: 1717794174,
            timestamp: 1717794172,
          },
        ],
        createdAt: "2024-06-07T21:02:55.276Z",
        updatedAt: "2024-06-07T21:02:55.276Z",
      },
      {
        id: "clx56arpv00y412vckwdmveiu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; ONEPLUS A5010 Build/QKQ1.191014.012; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.83 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 420dpi; 1080x2034; OnePlus; ONEPLUS A5010; OnePlus5T; qcom; en_MY; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "fn4hrGqaTl",
            locked: 1717794173,
            timestamp: 1717794172,
          },
        ],
        createdAt: "2024-06-07T21:02:54.163Z",
        updatedAt: "2024-06-07T21:02:54.163Z",
      },
      {
        id: "clx56aln200y312vc6burfcf2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; ONEPLUS A5010 Build/QKQ1.191014.012; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.83 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 420dpi; 1080x2034; OnePlus; ONEPLUS A5010; OnePlus5T; qcom; en_MY; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Z1bkDWkC6Z",
            locked: 1717794164,
            timestamp: 1717794161,
          },
        ],
        createdAt: "2024-06-07T21:02:46.284Z",
        updatedAt: "2024-06-07T21:02:46.284Z",
      },
      {
        id: "clx56a1o900y212vcfuz6rwzr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GuPDNqdhz6",
            locked: 1717794139,
            timestamp: 1717794138,
          },
        ],
        createdAt: "2024-06-07T21:02:20.407Z",
        updatedAt: "2024-06-07T21:02:20.407Z",
      },
      {
        id: "clx568pp700y112vc07jz90iz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "87d9OIsiuF",
            locked: 1717794078,
            timestamp: 1717794077,
          },
        ],
        createdAt: "2024-06-07T21:01:18.233Z",
        updatedAt: "2024-06-07T21:01:18.233Z",
      },
      {
        id: "clx568amq00y012vcm3n20ben",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SWYN6Kw8uD",
            locked: 1717794058,
            timestamp: 1717794057,
          },
        ],
        createdAt: "2024-06-07T21:00:58.706Z",
        updatedAt: "2024-06-07T21:00:58.706Z",
      },
      {
        id: "clx56865w00xz12vcw9nlxt4r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; 2303CRA44A Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 299.0.0.34.111 Android (33/13; 440dpi; 1080x2177; Xiaomi/Redmi; 2303CRA44A; ocean; mt6781; fa_IR; 512770468)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "2NxjyCMTqT",
            locked: 1717794051,
            timestamp: 1717794050,
          },
        ],
        createdAt: "2024-06-07T21:00:52.917Z",
        updatedAt: "2024-06-07T21:00:52.917Z",
      },
      {
        id: "clx56854500xy12vcha8mzkov",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; 2303CRA44A Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 299.0.0.34.111 Android (33/13; 440dpi; 1080x2177; Xiaomi/Redmi; 2303CRA44A; ocean; mt6781; fa_IR; 512770468)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3kaUjpZMPW",
            locked: 1717794049,
            timestamp: 1717794047,
          },
        ],
        createdAt: "2024-06-07T21:00:51.555Z",
        updatedAt: "2024-06-07T21:00:51.555Z",
      },
      {
        id: "clx567u9f00xx12vcp6q3294s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GTiFMh2Ytt",
            locked: 1717793993,
            timestamp: 1717793989,
          },
        ],
        createdAt: "2024-06-07T21:00:37.492Z",
        updatedAt: "2024-06-07T21:00:37.492Z",
      },
      {
        id: "clx567k0j00xw12vcsz178tmh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NiSDxVZ1iT",
            locked: 1717794023,
            timestamp: 1717794022,
          },
        ],
        createdAt: "2024-06-07T21:00:24.209Z",
        updatedAt: "2024-06-07T21:00:24.209Z",
      },
      {
        id: "clx567h2900xv12vcnxqyngo4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "yrHtnLevhr",
            locked: 1717794019,
            timestamp: 1717794018,
          },
        ],
        createdAt: "2024-06-07T21:00:20.385Z",
        updatedAt: "2024-06-07T21:00:20.385Z",
      },
      {
        id: "clx56778y00xu12vcybhzu44c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rbdxkM2mE2",
            locked: 1717794007,
            timestamp: 1717794005,
          },
        ],
        createdAt: "2024-06-07T21:00:07.663Z",
        updatedAt: "2024-06-07T21:00:07.663Z",
      },
      {
        id: "clx565sjv00xt12vcyudip8is",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gKAFkxKWxn",
            locked: 1717793941,
            timestamp: 1717793940,
          },
        ],
        createdAt: "2024-06-07T20:59:01.960Z",
        updatedAt: "2024-06-07T20:59:01.960Z",
      },
      {
        id: "clx565e2m00xs12vccfa05yu0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "zgCE2B4G8w",
            locked: 1717793923,
            timestamp: 1717793922,
          },
        ],
        createdAt: "2024-06-07T20:58:43.198Z",
        updatedAt: "2024-06-07T20:58:43.198Z",
      },
      {
        id: "clx565cyh00xr12vcxkwe8crp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PGUCZqaj0C",
            locked: 1717793921,
            timestamp: 1717793918,
          },
        ],
        createdAt: "2024-06-07T20:58:41.751Z",
        updatedAt: "2024-06-07T20:58:41.751Z",
      },
      {
        id: "clx564vsd00xq12vc4l1zlezh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Vf6h5EPJRu",
            locked: 1717793897,
            timestamp: 1717793893,
          },
        ],
        createdAt: "2024-06-07T20:58:19.499Z",
        updatedAt: "2024-06-07T20:58:19.499Z",
      },
      {
        id: "clx562u2x00xp12vcpfnnzhk4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zT4lz7G4WP",
            locked: 1717793803,
            timestamp: 1717793802,
          },
        ],
        createdAt: "2024-06-07T20:56:43.978Z",
        updatedAt: "2024-06-07T20:56:43.978Z",
      },
      {
        id: "clx562lch00xo12vchyisl9fd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,5; iOS 16_7_8; en_US; en; scale=3.00; 1125x2001; 605596711)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "orQEoaGnxd",
            locked: 1717793791,
            timestamp: 1717793790,
          },
        ],
        createdAt: "2024-06-07T20:56:32.655Z",
        updatedAt: "2024-06-07T20:56:32.655Z",
      },
      {
        id: "clx561ts000xn12vc6lcqdkx3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone11,6; iOS 17_4_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "e1q77TdLny",
            locked: 1717793756,
            timestamp: 1717793754,
          },
        ],
        createdAt: "2024-06-07T20:55:56.926Z",
        updatedAt: "2024-06-07T20:55:56.926Z",
      },
      {
        id: "clx560oc400xm12vc888kll4g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "oRYzUjtarM",
            locked: 1717793703,
            timestamp: 1717793700,
          },
        ],
        createdAt: "2024-06-07T20:55:03.221Z",
        updatedAt: "2024-06-07T20:55:03.221Z",
      },
      {
        id: "clx560h7z00xl12vclkghthku",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QovQvfHnjM",
            locked: 1717793693,
            timestamp: 1717793692,
          },
        ],
        createdAt: "2024-06-07T20:54:53.997Z",
        updatedAt: "2024-06-07T20:54:53.997Z",
      },
      {
        id: "clx55zwag00xk12vcvqqpf6s8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,5; iOS 16_7_8; en_US; en; scale=3.00; 1125x2001; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qqRenpVzAI",
            locked: 1717793665,
            timestamp: 1717793663,
          },
        ],
        createdAt: "2024-06-07T20:54:26.870Z",
        updatedAt: "2024-06-07T20:54:26.870Z",
      },
      {
        id: "clx55zf1r00xj12vcd1mb2q54",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad8,9; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uo1ihfwrPx",
            locked: 1717793643,
            timestamp: 1717793642,
          },
        ],
        createdAt: "2024-06-07T20:54:04.525Z",
        updatedAt: "2024-06-07T20:54:04.525Z",
      },
      {
        id: "clx55z1qs00xi12vcl3odw5al",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "RfTTD08eUb",
            locked: 1717793618,
            timestamp: 1717793617,
          },
        ],
        createdAt: "2024-06-07T20:53:47.284Z",
        updatedAt: "2024-06-07T20:53:47.284Z",
      },
      {
        id: "clx55ysxs00xh12vcifg687jq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "hfVG0xQn4d",
            locked: 1717793615,
            timestamp: 1717793614,
          },
        ],
        createdAt: "2024-06-07T20:53:35.872Z",
        updatedAt: "2024-06-07T20:53:35.872Z",
      },
      {
        id: "clx55yq9a00xg12vc2f8gjvej",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wJfMxCwcmM",
            locked: 1717793603,
            timestamp: 1717793601,
          },
        ],
        createdAt: "2024-06-07T20:53:32.396Z",
        updatedAt: "2024-06-07T20:53:32.396Z",
      },
      {
        id: "clx55y3bq00xf12vcd7atntfr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 540dpi; 1080x2104; samsung; SM-S906U; g0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Z9Swsuwoei",
            locked: 1717793582,
            timestamp: 1717793578,
          },
        ],
        createdAt: "2024-06-07T20:53:02.676Z",
        updatedAt: "2024-06-07T20:53:02.676Z",
      },
      {
        id: "clx55x34o00xe12vcapbnnuu8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hy8kDtDy9j",
            locked: 1717793535,
            timestamp: 1717793533,
          },
        ],
        createdAt: "2024-06-07T20:52:15.768Z",
        updatedAt: "2024-06-07T20:52:15.768Z",
      },
      {
        id: "clx55wtk400xd12vcbx9h1szl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "PjLcrWrXzx",
            locked: 1717793522,
            timestamp: 1717793521,
          },
        ],
        createdAt: "2024-06-07T20:52:03.364Z",
        updatedAt: "2024-06-07T20:52:03.364Z",
      },
      {
        id: "clx55wt6900xc12vcwqljd088",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OqT7O32KfR",
            locked: 1717793521,
            timestamp: 1717793520,
          },
        ],
        createdAt: "2024-06-07T20:52:02.862Z",
        updatedAt: "2024-06-07T20:52:02.862Z",
      },
      {
        id: "clx55vzp400xb12vcgmshl17d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uX2Oy40eoG",
            locked: 1717793484,
            timestamp: 1717793482,
          },
        ],
        createdAt: "2024-06-07T20:51:24.662Z",
        updatedAt: "2024-06-07T20:51:24.662Z",
      },
      {
        id: "clx55utpl00xa12vc97fg76sx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2129; samsung; SM-S928U; e3q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "hpEOP20AVG",
            locked: 1717793429,
            timestamp: 1717793428,
          },
        ],
        createdAt: "2024-06-07T20:50:30.250Z",
        updatedAt: "2024-06-07T20:50:30.250Z",
      },
      {
        id: "clx55utoo00x912vctggze2j5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PxXmtk6eVZ",
            locked: 1717793429,
            timestamp: 1717793427,
          },
        ],
        createdAt: "2024-06-07T20:50:30.216Z",
        updatedAt: "2024-06-07T20:50:30.216Z",
      },
      {
        id: "clx55ur5r00x812vc79yoca48",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "mdSn93bc9U",
            locked: 1717793426,
            timestamp: 1717793425,
          },
        ],
        createdAt: "2024-06-07T20:50:26.944Z",
        updatedAt: "2024-06-07T20:50:26.944Z",
      },
      {
        id: "clx55unw700x712vcr4yhgukz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2129; samsung; SM-S928U; e3q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CDGgBmp7Zn",
            locked: 1717793421,
            timestamp: 1717793419,
          },
        ],
        createdAt: "2024-06-07T20:50:22.712Z",
        updatedAt: "2024-06-07T20:50:22.712Z",
      },
      {
        id: "clx55ukzk00x612vcealalyov",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "8johrWJvMF",
            locked: 1717793418,
            timestamp: 1717793417,
          },
        ],
        createdAt: "2024-06-07T20:50:18.938Z",
        updatedAt: "2024-06-07T20:50:18.938Z",
      },
      {
        id: "clx55ttag00x512vcpgnhw04n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone15,2; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KuU0ju5DOh",
            locked: 1717793382,
            timestamp: 1717793380,
          },
        ],
        createdAt: "2024-06-07T20:49:43.040Z",
        updatedAt: "2024-06-07T20:49:43.040Z",
      },
      {
        id: "clx55qvov00x412vcs3z9v7xh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "05l24kSJMK",
            locked: 1717793245,
            timestamp: 1717793244,
          },
        ],
        createdAt: "2024-06-07T20:47:26.190Z",
        updatedAt: "2024-06-07T20:47:26.190Z",
      },
      {
        id: "clx55qovr00x312vcebpy5a9m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "52I6DOV2mk",
            locked: 1717793236,
            timestamp: 1717793234,
          },
        ],
        createdAt: "2024-06-07T20:47:17.367Z",
        updatedAt: "2024-06-07T20:47:17.367Z",
      },
      {
        id: "clx55qhg200x212vcf0gppeoa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0kYOWBTHI9",
            locked: 1717793225,
            timestamp: 1717793223,
          },
        ],
        createdAt: "2024-06-07T20:47:07.729Z",
        updatedAt: "2024-06-07T20:47:07.729Z",
      },
      {
        id: "clx55qcda00x112vc2jb7f350",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; TECNO KI5k Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.152 Mobile Safari/537.36 [FBAN/FB4A;FBAV/467.0.0.46.83;FBBV/606680399;FBDM/{density=2.0,width=720,height=1444};FBLC/fr_FR;FBRV/0;FB_FW/2;FBCR/Digicel;FBMF/TECNO;FBBD/TECNO;FBPN/com.facebook.katana;FBDV/TECNO KI5k;FBSV/12;FBOP/1;FBCA/arm64-v8a:;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1yUmPoNh5x",
            locked: 1717793220,
            timestamp: 1717793218,
          },
        ],
        createdAt: "2024-06-07T20:47:01.151Z",
        updatedAt: "2024-06-07T20:47:01.151Z",
      },
      {
        id: "clx55q7lc00x012vcx5ae89lx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone16,2; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7NvRrcRuNJ",
            locked: 1717793214,
            timestamp: 1717793212,
          },
        ],
        createdAt: "2024-06-07T20:46:54.961Z",
        updatedAt: "2024-06-07T20:46:54.961Z",
      },
      {
        id: "clx55q1na00wz12vcy0ep446z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "esBChenmOe",
            locked: 1717793243,
            timestamp: 1717793242,
          },
        ],
        createdAt: "2024-06-07T20:46:47.252Z",
        updatedAt: "2024-06-07T20:46:47.252Z",
      },
      {
        id: "clx55phr200wy12vcr2jfmetw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0soVE6UQZS",
            locked: 1717793180,
            timestamp: 1717793178,
          },
        ],
        createdAt: "2024-06-07T20:46:21.468Z",
        updatedAt: "2024-06-07T20:46:21.468Z",
      },
      {
        id: "clx55p61k00wx12vcl3pqqs0u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "J0ckNxKUy2",
            locked: 1717793166,
            timestamp: 1717793165,
          },
        ],
        createdAt: "2024-06-07T20:46:06.296Z",
        updatedAt: "2024-06-07T20:46:06.296Z",
      },
      {
        id: "clx55p4sn00ww12vcbzihhdry",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jOSJpa2asH",
            locked: 1717793164,
            timestamp: 1717793162,
          },
        ],
        createdAt: "2024-06-07T20:46:04.677Z",
        updatedAt: "2024-06-07T20:46:04.677Z",
      },
      {
        id: "clx55os9y00wv12vcftim7zw7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ZRhTPyrsoL",
            locked: 1717793148,
            timestamp: 1717793147,
          },
        ],
        createdAt: "2024-06-07T20:45:48.453Z",
        updatedAt: "2024-06-07T20:45:48.453Z",
      },
      {
        id: "clx55odc500wu12vcyw3g2bdf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ptg7Uqgid9",
            locked: 1717793128,
            timestamp: 1717793126,
          },
        ],
        createdAt: "2024-06-07T20:45:29.093Z",
        updatedAt: "2024-06-07T20:45:29.093Z",
      },
      {
        id: "clx55o8mr00wt12vcnp6t7e21",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rl6VRDMrYG",
            locked: 1717793158,
            timestamp: 1717793156,
          },
        ],
        createdAt: "2024-06-07T20:45:22.996Z",
        updatedAt: "2024-06-07T20:45:22.996Z",
      },
      {
        id: "clx55o8g200ws12vcdq5zg0sw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6IGPqVF009",
            locked: 1717793122,
            timestamp: 1717793121,
          },
        ],
        createdAt: "2024-06-07T20:45:22.751Z",
        updatedAt: "2024-06-07T20:45:22.751Z",
      },
      {
        id: "clx55npl800wr12vcy9be9keb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OQgVrzCGjQ",
            locked: 1717793096,
            timestamp: 1717793094,
          },
        ],
        createdAt: "2024-06-07T20:44:58.309Z",
        updatedAt: "2024-06-07T20:44:58.309Z",
      },
      {
        id: "clx55nfl700wq12vclu52vye2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "l8f1Iuspbz",
            locked: 1717793084,
            timestamp: 1717793083,
          },
        ],
        createdAt: "2024-06-07T20:44:45.355Z",
        updatedAt: "2024-06-07T20:44:45.355Z",
      },
      {
        id: "clx55nc1s00wp12vcsd6bv8ro",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.5938.132 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4J4PcEQ8lN",
            locked: 1717793078,
            timestamp: 1717793074,
          },
        ],
        createdAt: "2024-06-07T20:44:40.768Z",
        updatedAt: "2024-06-07T20:44:40.768Z",
      },
      {
        id: "clx55n6n100wo12vces57xf2l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Plslb9gdyE",
            locked: 1717793073,
            timestamp: 1717793071,
          },
        ],
        createdAt: "2024-06-07T20:44:33.754Z",
        updatedAt: "2024-06-07T20:44:33.754Z",
      },
      {
        id: "clx55msb700wn12vcmo3g0hi2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone14,8; iOS 16_6_1; en_CA; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "r6UKrPlG09",
            locked: 1717793055,
            timestamp: 1717793054,
          },
        ],
        createdAt: "2024-06-07T20:44:15.187Z",
        updatedAt: "2024-06-07T20:44:15.187Z",
      },
      {
        id: "clx55mr3f00wm12vchyhu95tz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone14,8; iOS 16_6_1; en_CA; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mMuIK3LLNd",
            locked: 1717793052,
            timestamp: 1717793051,
          },
        ],
        createdAt: "2024-06-07T20:44:13.612Z",
        updatedAt: "2024-06-07T20:44:13.612Z",
      },
      {
        id: "clx55mokm00wl12vcwds30cz9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Aps1QNvfkQ",
            locked: 1717793049,
            timestamp: 1717793041,
          },
        ],
        createdAt: "2024-06-07T20:44:10.340Z",
        updatedAt: "2024-06-07T20:44:10.340Z",
      },
      {
        id: "clx55m2e800wk12vcpec0r80q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "nEllRtGFtV",
            locked: 1717793020,
            timestamp: 1717793019,
          },
        ],
        createdAt: "2024-06-07T20:43:41.600Z",
        updatedAt: "2024-06-07T20:43:41.600Z",
      },
      {
        id: "clx55m2a400wj12vcow1xnmd6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3KIga5VELb",
            locked: 1717793021,
            timestamp: 1717793019,
          },
        ],
        createdAt: "2024-06-07T20:43:41.452Z",
        updatedAt: "2024-06-07T20:43:41.452Z",
      },
      {
        id: "clx55m20g00wi12vcx6yn082f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3u6L5ovH1S",
            locked: 1717793018,
            timestamp: 1717793017,
          },
        ],
        createdAt: "2024-06-07T20:43:41.102Z",
        updatedAt: "2024-06-07T20:43:41.102Z",
      },
      {
        id: "clx55lphf00wh12vc4q3zt85k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mjOu8PlASB",
            locked: 1717793004,
            timestamp: 1717792999,
          },
        ],
        createdAt: "2024-06-07T20:43:24.865Z",
        updatedAt: "2024-06-07T20:43:24.865Z",
      },
      {
        id: "clx55l98n00wg12vcc1lky14v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 334.0.4.32.98 (iPhone9,1; iOS 15_8_2; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9WWKyP59kF",
            locked: 1717792981,
            timestamp: 1717792977,
          },
        ],
        createdAt: "2024-06-07T20:43:03.816Z",
        updatedAt: "2024-06-07T20:43:03.816Z",
      },
      {
        id: "clx55l4gv00wf12vca29ynef2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 540dpi; 1080x2079; samsung; SM-G990U; r9q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "HHcFAX7czX",
            locked: 1717792975,
            timestamp: 1717792974,
          },
        ],
        createdAt: "2024-06-07T20:42:57.630Z",
        updatedAt: "2024-06-07T20:42:57.630Z",
      },
      {
        id: "clx55kwrm00we12vccn6v0y5b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "72Q6P8Um6g",
            locked: 1717792966,
            timestamp: 1717792964,
          },
        ],
        createdAt: "2024-06-07T20:42:47.651Z",
        updatedAt: "2024-06-07T20:42:47.651Z",
      },
      {
        id: "clx55ksva00wd12vctilzhevj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 540dpi; 1080x2079; samsung; SM-G990U; r9q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xouZrNveWu",
            locked: 1717792961,
            timestamp: 1717792956,
          },
        ],
        createdAt: "2024-06-07T20:42:42.439Z",
        updatedAt: "2024-06-07T20:42:42.439Z",
      },
      {
        id: "clx55ksqr00wc12vcvj22q2jx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 283.0.0.16.103 (iPhone15,2; iOS 17_5_1; en_US; en-US; scale=3.00; 1179x2556; 474411477)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Ti52H4Symi",
            locked: 1717792961,
            timestamp: 1717792958,
          },
        ],
        createdAt: "2024-06-07T20:42:42.432Z",
        updatedAt: "2024-06-07T20:42:42.432Z",
      },
      {
        id: "clx55jkkg00wb12vcyinf93fm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "90U9ejkssk",
            locked: 1717792904,
            timestamp: 1717792903,
          },
        ],
        createdAt: "2024-06-07T20:41:45.183Z",
        updatedAt: "2024-06-07T20:41:45.183Z",
      },
      {
        id: "clx55jekx00wa12vcyaz39u34",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RFCzQHeQxM",
            locked: 1717792896,
            timestamp: 1717792895,
          },
        ],
        createdAt: "2024-06-07T20:41:37.425Z",
        updatedAt: "2024-06-07T20:41:37.425Z",
      },
      {
        id: "clx55jbry00w912vcao0seq55",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "SD3SKwO78Y",
            locked: 1717792893,
            timestamp: 1717792892,
          },
        ],
        createdAt: "2024-06-07T20:41:33.791Z",
        updatedAt: "2024-06-07T20:41:33.791Z",
      },
      {
        id: "clx55j6x900w812vck51l84r7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZQBlzUrUXS",
            locked: 1717792886,
            timestamp: 1717792882,
          },
        ],
        createdAt: "2024-06-07T20:41:27.498Z",
        updatedAt: "2024-06-07T20:41:27.498Z",
      },
      {
        id: "clx55i1jt00w712vcffozcdle",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "e9kRqfVjH9",
            locked: 1717792834,
            timestamp: 1717792833,
          },
        ],
        createdAt: "2024-06-07T20:40:33.878Z",
        updatedAt: "2024-06-07T20:40:33.878Z",
      },
      {
        id: "clx55hmwy00w612vcx3jlvdzk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "y3lywdsuh0",
            locked: 1717792815,
            timestamp: 1717792814,
          },
        ],
        createdAt: "2024-06-07T20:40:14.915Z",
        updatedAt: "2024-06-07T20:40:14.915Z",
      },
      {
        id: "clx55hj6h00w512vcpicyxohq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hVbk6cmsMP",
            locked: 1717792809,
            timestamp: 1717792803,
          },
        ],
        createdAt: "2024-06-07T20:40:10.072Z",
        updatedAt: "2024-06-07T20:40:10.072Z",
      },
      {
        id: "clx55ez0300w412vc8y6mb3bd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cjaQirBeoe",
            locked: 1717792689,
            timestamp: 1717792688,
          },
        ],
        createdAt: "2024-06-07T20:38:10.609Z",
        updatedAt: "2024-06-07T20:38:10.609Z",
      },
      {
        id: "clx55e06w00w312vciv1izxlj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.53 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "c2345scC39",
            locked: 1717792644,
            timestamp: 1717792641,
          },
        ],
        createdAt: "2024-06-07T20:37:25.494Z",
        updatedAt: "2024-06-07T20:37:25.494Z",
      },
      {
        id: "clx55ckdq00w212vcaeo27c1p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_5_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "yDIaUKZG3F",
            locked: 1717792578,
            timestamp: 1717792577,
          },
        ],
        createdAt: "2024-06-07T20:36:18.350Z",
        updatedAt: "2024-06-07T20:36:18.350Z",
      },
      {
        id: "clx55ccmr00w112vc18lx9yof",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_5_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QKAsNS5XOK",
            locked: 1717792567,
            timestamp: 1717792565,
          },
        ],
        createdAt: "2024-06-07T20:36:08.303Z",
        updatedAt: "2024-06-07T20:36:08.303Z",
      },
      {
        id: "clx55b4tk00w012vcg968fsjv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F721B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2420; samsung; SM-F721B; b4q; qcom; en_GB; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Kjwf3ArWx1",
            locked: 1717792511,
            timestamp: 1717792509,
          },
        ],
        createdAt: "2024-06-07T20:35:11.526Z",
        updatedAt: "2024-06-07T20:35:11.526Z",
      },
      {
        id: "clx55aw2q00vz12vcj8ozn5cv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 334.0.4.32.98 (iPad5,3; iPadOS 15_8_2; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "aulXgAZAeJ",
            locked: 1717792499,
            timestamp: 1717792489,
          },
        ],
        createdAt: "2024-06-07T20:35:00.195Z",
        updatedAt: "2024-06-07T20:35:00.195Z",
      },
      {
        id: "clx55aqdd00vy12vc000g9hc0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "wf5BiVeQiO",
            locked: 1717792491,
            timestamp: 1717792486,
          },
        ],
        createdAt: "2024-06-07T20:34:52.798Z",
        updatedAt: "2024-06-07T20:34:52.798Z",
      },
      {
        id: "clx559eb500vx12vcl0gcx81f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "0yoArSxwad",
            locked: 1717792429,
            timestamp: 1717792428,
          },
        ],
        createdAt: "2024-06-07T20:33:50.509Z",
        updatedAt: "2024-06-07T20:33:50.509Z",
      },
      {
        id: "clx558x3m00vw12vc1y8ci3bx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "P0EPtk8ozj",
            locked: 1717792408,
            timestamp: 1717792406,
          },
        ],
        createdAt: "2024-06-07T20:33:28.208Z",
        updatedAt: "2024-06-07T20:33:28.208Z",
      },
      {
        id: "clx5583n200vv12vc930o1glc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CN9Jf56KDf",
            locked: 1717792369,
            timestamp: 1717792366,
          },
        ],
        createdAt: "2024-06-07T20:32:50.031Z",
        updatedAt: "2024-06-07T20:32:50.031Z",
      },
      {
        id: "clx557zcw00vu12vcxkp72p5j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "X6Pqmrpk2r",
            locked: 1717792362,
            timestamp: 1717792360,
          },
        ],
        createdAt: "2024-06-07T20:32:44.479Z",
        updatedAt: "2024-06-07T20:32:44.479Z",
      },
      {
        id: "clx557sgl00vt12vcrpervjam",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FXfOymWhq8",
            locked: 1717792351,
            timestamp: 1717792347,
          },
        ],
        createdAt: "2024-06-07T20:32:35.541Z",
        updatedAt: "2024-06-07T20:32:35.541Z",
      },
      {
        id: "clx557rl900vs12vc1n903y3d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kz9LfTNEer",
            locked: 1717792350,
            timestamp: 1717792349,
          },
        ],
        createdAt: "2024-06-07T20:32:34.413Z",
        updatedAt: "2024-06-07T20:32:34.413Z",
      },
      {
        id: "clx557oyp00vr12vc5as7ennp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "9XGQdwAvPi",
            locked: 1717792350,
            timestamp: 1717792349,
          },
        ],
        createdAt: "2024-06-07T20:32:31.010Z",
        updatedAt: "2024-06-07T20:32:31.010Z",
      },
      {
        id: "clx557lm500vq12vcp92iz5go",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "BDM8jBhfvm",
            locked: 1717792346,
            timestamp: 1717792343,
          },
        ],
        createdAt: "2024-06-07T20:32:26.668Z",
        updatedAt: "2024-06-07T20:32:26.668Z",
      },
      {
        id: "clx557ih000vp12vcx8s6wzt4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WcUiEDgKEk",
            locked: 1717792300,
            timestamp: 1717792299,
          },
        ],
        createdAt: "2024-06-07T20:32:22.596Z",
        updatedAt: "2024-06-07T20:32:22.596Z",
      },
      {
        id: "clx5578q800vo12vcdegwlxu1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-G781U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 510dpi; 1080x2159; samsung; SM-G781U; r8q; qcom; en_US; 606558408)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "00YPk8hsM5",
            locked: 1717792329,
            timestamp: 1717792323,
          },
        ],
        createdAt: "2024-06-07T20:32:09.965Z",
        updatedAt: "2024-06-07T20:32:09.965Z",
      },
      {
        id: "clx556baj00vn12vctak09r5f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cmBuh9Y6dP",
            locked: 1717792285,
            timestamp: 1717792284,
          },
        ],
        createdAt: "2024-06-07T20:31:26.633Z",
        updatedAt: "2024-06-07T20:31:26.633Z",
      },
      {
        id: "clx555b4p00vm12vc893clh98",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fLJqXt5sV3",
            locked: 1717792238,
            timestamp: 1717792226,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ChRZhYkNYF",
            locked: 1717792238,
            timestamp: 1717792231,
          },
        ],
        createdAt: "2024-06-07T20:30:39.770Z",
        updatedAt: "2024-06-07T20:30:39.770Z",
      },
      {
        id: "clx555as900vl12vcchsiqz8p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "canFqct2y2",
            locked: 1717792239,
            timestamp: 1717792238,
          },
        ],
        createdAt: "2024-06-07T20:30:39.319Z",
        updatedAt: "2024-06-07T20:30:39.319Z",
      },
      {
        id: "clx554tgm00vk12vcrg7jykaz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S908U; b0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hu5UMvsOaR",
            locked: 1717792216,
            timestamp: 1717792214,
          },
        ],
        createdAt: "2024-06-07T20:30:16.870Z",
        updatedAt: "2024-06-07T20:30:16.870Z",
      },
      {
        id: "clx554r8g00vj12vc4nsrp1ue",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AjhXffCJJ1",
            locked: 1717792213,
            timestamp: 1717792212,
          },
        ],
        createdAt: "2024-06-07T20:30:13.984Z",
        updatedAt: "2024-06-07T20:30:13.984Z",
      },
      {
        id: "clx554q1800vi12vccwv755md",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone15,5; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CtCpBbyAGE",
            locked: 1717792211,
            timestamp: 1717792210,
          },
        ],
        createdAt: "2024-06-07T20:30:12.426Z",
        updatedAt: "2024-06-07T20:30:12.426Z",
      },
      {
        id: "clx5545lf00vh12vclc9vi5pr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "5qKG7UgHPz",
            locked: 1717792185,
            timestamp: 1717792184,
          },
        ],
        createdAt: "2024-06-07T20:29:45.940Z",
        updatedAt: "2024-06-07T20:29:45.940Z",
      },
      {
        id: "clx5540wy00vg12vc86gohcst",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3FPal6Lrjz",
            locked: 1717792178,
            timestamp: 1717792177,
          },
        ],
        createdAt: "2024-06-07T20:29:39.873Z",
        updatedAt: "2024-06-07T20:29:39.873Z",
      },
      {
        id: "clx5540al00vf12vcakewih7r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5DZDc8zNko",
            locked: 1717792178,
            timestamp: 1717792177,
          },
        ],
        createdAt: "2024-06-07T20:29:39.069Z",
        updatedAt: "2024-06-07T20:29:39.069Z",
      },
      {
        id: "clx553xq800ve12vcngps0mhy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "syGfoSvmyC",
            locked: 1717792175,
            timestamp: 1717792172,
          },
        ],
        createdAt: "2024-06-07T20:29:35.744Z",
        updatedAt: "2024-06-07T20:29:35.744Z",
      },
      {
        id: "clx553ozf00vd12vch2m848lq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GO7BzvWRCO",
            locked: 1717792163,
            timestamp: 1717792162,
          },
        ],
        createdAt: "2024-06-07T20:29:24.407Z",
        updatedAt: "2024-06-07T20:29:24.407Z",
      },
      {
        id: "clx5538mx00vc12vcvrrqtagk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ZHYRqzRXU6",
            locked: 1717792142,
            timestamp: 1717792141,
          },
        ],
        createdAt: "2024-06-07T20:29:03.222Z",
        updatedAt: "2024-06-07T20:29:03.222Z",
      },
      {
        id: "clx552r3i00vb12vcugxcs3cr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Km7weB1HS1",
            locked: 1717792119,
            timestamp: 1717792116,
          },
        ],
        createdAt: "2024-06-07T20:28:40.495Z",
        updatedAt: "2024-06-07T20:28:40.495Z",
      },
      {
        id: "clx552l3700va12vcbgsvxj1w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gkVEGSerLO",
            locked: 1717792111,
            timestamp: 1717792110,
          },
        ],
        createdAt: "2024-06-07T20:28:32.706Z",
        updatedAt: "2024-06-07T20:28:32.706Z",
      },
      {
        id: "clx552i8b00v912vc7wjtnwf5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RnxK5IUmQk",
            locked: 1717792108,
            timestamp: 1717792106,
          },
        ],
        createdAt: "2024-06-07T20:28:29.003Z",
        updatedAt: "2024-06-07T20:28:29.003Z",
      },
      {
        id: "clx5526xs00v812vcofnsoorp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LuECHHXjUu",
            locked: 1717792093,
            timestamp: 1717792091,
          },
        ],
        createdAt: "2024-06-07T20:28:14.362Z",
        updatedAt: "2024-06-07T20:28:14.362Z",
      },
      {
        id: "clx551tt300v712vck6g02jv4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LSoK7qQCwt",
            locked: 1717792076,
            timestamp: 1717792075,
          },
        ],
        createdAt: "2024-06-07T20:27:57.351Z",
        updatedAt: "2024-06-07T20:27:57.351Z",
      },
      {
        id: "clx551t5t00v612vc8dz5aa6g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fzvHhOfNX3",
            locked: 1717792075,
            timestamp: 1717792074,
          },
        ],
        createdAt: "2024-06-07T20:27:56.513Z",
        updatedAt: "2024-06-07T20:27:56.513Z",
      },
      {
        id: "clx551s8e00v512vcu7c9l8ic",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xgIecJwf1S",
            locked: 1717792074,
            timestamp: 1717792073,
          },
        ],
        createdAt: "2024-06-07T20:27:55.308Z",
        updatedAt: "2024-06-07T20:27:55.308Z",
      },
      {
        id: "clx5510gj00v412vchsummp0u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "YaENfMq0C2",
            locked: 1717792039,
            timestamp: 1717792038,
          },
        ],
        createdAt: "2024-06-07T20:27:19.316Z",
        updatedAt: "2024-06-07T20:27:19.316Z",
      },
      {
        id: "clx550sh400v312vc1y6e2lgd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "W7qHwFLB08",
            locked: 1717792028,
            timestamp: 1717792027,
          },
        ],
        createdAt: "2024-06-07T20:27:08.966Z",
        updatedAt: "2024-06-07T20:27:08.966Z",
      },
      {
        id: "clx5506wa00v212vc5q17d3z6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "u4BdgstYht",
            locked: 1717792000,
            timestamp: 1717791995,
          },
        ],
        createdAt: "2024-06-07T20:26:41.000Z",
        updatedAt: "2024-06-07T20:26:41.000Z",
      },
      {
        id: "clx54zssd00v112vcdd63wtg4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IEcHKbURhC",
            locked: 1717791982,
            timestamp: 1717791979,
          },
        ],
        createdAt: "2024-06-07T20:26:22.717Z",
        updatedAt: "2024-06-07T20:26:22.717Z",
      },
      {
        id: "clx54zow400v012vchi1sywal",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "MOXqCKxLr1",
            locked: 1717791977,
            timestamp: 1717791976,
          },
        ],
        createdAt: "2024-06-07T20:26:17.669Z",
        updatedAt: "2024-06-07T20:26:17.669Z",
      },
      {
        id: "clx54zmvr00uz12vc9dvfmp6y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "f8QcRBxzlO",
            locked: 1717791974,
            timestamp: 1717791973,
          },
        ],
        createdAt: "2024-06-07T20:26:15.061Z",
        updatedAt: "2024-06-07T20:26:15.061Z",
      },
      {
        id: "clx54z3mc00uy12vc8z8fjoux",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 332.0.0.38.90 Android (33/13; 420dpi; 1080x2123; samsung; SM-N986U; c2q; qcom; en_US; 601420827)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kk4cMnj1Kc",
            locked: 1717791949,
            timestamp: 1717791946,
          },
        ],
        createdAt: "2024-06-07T20:25:50.096Z",
        updatedAt: "2024-06-07T20:25:50.096Z",
      },
      {
        id: "clx54xr7500ux12vcwzbnkm22",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "LkPFnTjUM0",
            locked: 1717791887,
            timestamp: 1717791886,
          },
        ],
        createdAt: "2024-06-07T20:24:47.345Z",
        updatedAt: "2024-06-07T20:24:47.345Z",
      },
      {
        id: "clx54xm7t00uw12vcj89ji4op",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7pD9d1UGIJ",
            locked: 1717791880,
            timestamp: 1717791879,
          },
        ],
        createdAt: "2024-06-07T20:24:40.889Z",
        updatedAt: "2024-06-07T20:24:40.889Z",
      },
      {
        id: "clx54xm1s00uv12vcd7vzzc12",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "t7rSnneaeS",
            locked: 1717791880,
            timestamp: 1717791878,
          },
        ],
        createdAt: "2024-06-07T20:24:40.670Z",
        updatedAt: "2024-06-07T20:24:40.670Z",
      },
      {
        id: "clx54x9qx00uu12vcj8avhfg1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; motorola one 5G UW Build/RPNS31.Q1-51-30-14-2; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (30/11; 420dpi; 1080x2342; motorola; motorola one 5G UW; nairo; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9PTf2tvSBM",
            locked: 1717791864,
            timestamp: 1717791860,
          },
        ],
        createdAt: "2024-06-07T20:24:24.726Z",
        updatedAt: "2024-06-07T20:24:24.726Z",
      },
      {
        id: "clx54wq1m00ut12vcg9u6pvwb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/466.1.0.57.85;]",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ouD96dYDW2",
            locked: 1717791838,
            timestamp: 1717791837,
          },
        ],
        createdAt: "2024-06-07T20:23:59.191Z",
        updatedAt: "2024-06-07T20:23:59.191Z",
      },
      {
        id: "clx54w29000us12vcxvfh8igk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,1; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KyVdf4sZhC",
            locked: 1717791807,
            timestamp: 1717791806,
          },
        ],
        createdAt: "2024-06-07T20:23:28.354Z",
        updatedAt: "2024-06-07T20:23:28.354Z",
      },
      {
        id: "clx54vosu00ur12vc1iw5npmz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; TMRV065G Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 280dpi; 720x1515; Wingtech/T-Mobile; TMRV065G; TorreyPines; mt6833; en_US; 606558548)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "XHkGWItOTt",
            locked: 1717791789,
            timestamp: 1717791788,
          },
        ],
        createdAt: "2024-06-07T20:23:10.926Z",
        updatedAt: "2024-06-07T20:23:10.926Z",
      },
      {
        id: "clx54vm4j00uq12vc07ak6725",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; TMRV065G Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 280dpi; 720x1515; Wingtech/T-Mobile; TMRV065G; TorreyPines; mt6833; en_US; 606558548)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "EDoV7eq3e5",
            locked: 1717791785,
            timestamp: 1717791782,
          },
        ],
        createdAt: "2024-06-07T20:23:07.456Z",
        updatedAt: "2024-06-07T20:23:07.456Z",
      },
      {
        id: "clx54ur7700up12vccvzkdmey",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "F7SJy9g6cH",
            locked: 1717791746,
            timestamp: 1717791745,
          },
        ],
        createdAt: "2024-06-07T20:22:27.379Z",
        updatedAt: "2024-06-07T20:22:27.379Z",
      },
      {
        id: "clx54um5r00uo12vcxbglgn1t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZAuDWqVPDV",
            locked: 1717791739,
            timestamp: 1717791738,
          },
        ],
        createdAt: "2024-06-07T20:22:20.845Z",
        updatedAt: "2024-06-07T20:22:20.845Z",
      },
      {
        id: "clx54u37300un12vcditxmk5w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "7LASObceKO",
            locked: 1717791716,
            timestamp: 1717791715,
          },
        ],
        createdAt: "2024-06-07T20:21:56.269Z",
        updatedAt: "2024-06-07T20:21:56.269Z",
      },
      {
        id: "clx54tn2500um12vccxmm8h6s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DLAkxLCeMw",
            locked: 1717791694,
            timestamp: 1717791693,
          },
        ],
        createdAt: "2024-06-07T20:21:35.354Z",
        updatedAt: "2024-06-07T20:21:35.354Z",
      },
      {
        id: "clx54szyr00ul12vcuzvwbsir",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21B91 Instagram 330.0.3.29.91 (iPhone13,2; iOS 17_1_1; en_US; en; scale=3.00; 1170x2532; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Aq2DoXGhHS",
            locked: 1717791663,
            timestamp: 1717791662,
          },
        ],
        createdAt: "2024-06-07T20:21:05.427Z",
        updatedAt: "2024-06-07T20:21:05.427Z",
      },
      {
        id: "clx54sqrg00uk12vch68diymf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 BytedanceWebview/d8a21c6 musical_ly_34.7.1 JsSdk/2.0 NetType/WIFI Channel/App Store ByteLocale/en Region/US FalconTag/80D30B45-7138-42BC-B10E-2D104917FF42",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QGAMRjA5F4",
            locked: 1717791652,
            timestamp: 1717791651,
          },
        ],
        createdAt: "2024-06-07T20:20:53.497Z",
        updatedAt: "2024-06-07T20:20:53.497Z",
      },
      {
        id: "clx54sabq00uj12vcptgstkfg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "gi0MssBcSS",
            locked: 1717791632,
            timestamp: 1717791631,
          },
        ],
        createdAt: "2024-06-07T20:20:32.198Z",
        updatedAt: "2024-06-07T20:20:32.198Z",
      },
      {
        id: "clx54s82n00ui12vcucjz3dre",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "HXRZeTQXIu",
            locked: 1717791629,
            timestamp: 1717791619,
          },
        ],
        createdAt: "2024-06-07T20:20:29.279Z",
        updatedAt: "2024-06-07T20:20:29.279Z",
      },
      {
        id: "clx54s0uq00uh12vcufgqjp0k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "unykEQrN14",
            locked: 1717791618,
            timestamp: 1717791615,
          },
        ],
        createdAt: "2024-06-07T20:20:19.920Z",
        updatedAt: "2024-06-07T20:20:19.920Z",
      },
      {
        id: "clx54rmas00ug12vcsfdjzsio",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "10OBIl37ka",
            locked: 1717791600,
            timestamp: 1717791599,
          },
        ],
        createdAt: "2024-06-07T20:20:01.061Z",
        updatedAt: "2024-06-07T20:20:01.061Z",
      },
      {
        id: "clx54rk3p00uf12vclwkfk9pn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "p3KXuooiL6",
            locked: 1717791598,
            timestamp: 1717791597,
          },
        ],
        createdAt: "2024-06-07T20:19:58.211Z",
        updatedAt: "2024-06-07T20:19:58.211Z",
      },
      {
        id: "clx54qzgk00ue12vch4ms2a5l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LokmPP10oR",
            locked: 1717791571,
            timestamp: 1717791569,
          },
        ],
        createdAt: "2024-06-07T20:19:31.458Z",
        updatedAt: "2024-06-07T20:19:31.458Z",
      },
      {
        id: "clx54q3qx00ud12vcjvza0pse",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 [FBAN/FBIOS;FBAV/466.0.0.36.107;FBBV/604687626;FBDV/iPhone16,2;FBMD/iPhone;FBSN/iOS;FBSV/17.5.1;FBSS/3;FBID/phone;FBLC/en_US;FBOP/5;FBRV/607786073]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6NKJJEcplQ",
            locked: 1717791529,
            timestamp: 1717791528,
          },
        ],
        createdAt: "2024-06-07T20:18:50.361Z",
        updatedAt: "2024-06-07T20:18:50.361Z",
      },
      {
        id: "clx54pv7b00uc12vcwytdf802",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RiQhVWMk5v",
            locked: 1717791518,
            timestamp: 1717791515,
          },
        ],
        createdAt: "2024-06-07T20:18:39.284Z",
        updatedAt: "2024-06-07T20:18:39.284Z",
      },
      {
        id: "clx54n76400ub12vcg0b67x3v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9yHNSVc6Xo",
            locked: 1717791394,
            timestamp: 1717791392,
          },
        ],
        createdAt: "2024-06-07T20:16:34.825Z",
        updatedAt: "2024-06-07T20:16:34.825Z",
      },
      {
        id: "clx54mpz500ua12vczshz5qg7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 334.0.4.32.98 (iPhone11,8; iOS 16_1_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Z6putbM4WK",
            locked: 1717791372,
            timestamp: 1717791370,
          },
        ],
        createdAt: "2024-06-07T20:16:12.542Z",
        updatedAt: "2024-06-07T20:16:12.542Z",
      },
      {
        id: "clx54kvvc00u912vc4myq881i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "rqG374aVHQ",
            locked: 1717791287,
            timestamp: 1717791284,
          },
        ],
        createdAt: "2024-06-07T20:14:46.870Z",
        updatedAt: "2024-06-07T20:14:46.870Z",
      },
      {
        id: "clx54kfg300u812vcj4t9c637",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "89QLwShF0k",
            locked: 1717791265,
            timestamp: 1717791264,
          },
        ],
        createdAt: "2024-06-07T20:14:25.587Z",
        updatedAt: "2024-06-07T20:14:25.587Z",
      },
      {
        id: "clx54kd5h00u712vcydunfy0m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "piK8RzmRzc",
            locked: 1717791266,
            timestamp: 1717791264,
          },
        ],
        createdAt: "2024-06-07T20:14:22.614Z",
        updatedAt: "2024-06-07T20:14:22.614Z",
      },
      {
        id: "clx54k83t00u612vchs72q41z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "bnUNavkShY",
            locked: 1717791255,
            timestamp: 1717791254,
          },
        ],
        createdAt: "2024-06-07T20:14:16.074Z",
        updatedAt: "2024-06-07T20:14:16.074Z",
      },
      {
        id: "clx54k5qo00u512vcbgdl99m0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "vxc14rL4DY",
            locked: 1717791252,
            timestamp: 1717791251,
          },
        ],
        createdAt: "2024-06-07T20:14:13.007Z",
        updatedAt: "2024-06-07T20:14:13.007Z",
      },
      {
        id: "clx54jyd600u412vcqfjz0lou",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9jaz9ewR4u",
            locked: 1717791242,
            timestamp: 1717791241,
          },
        ],
        createdAt: "2024-06-07T20:14:03.450Z",
        updatedAt: "2024-06-07T20:14:03.450Z",
      },
      {
        id: "clx54jrnk00u312vc93272x3s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_6_1; en_US; en; scale=2.00; 750x1624; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WwACM0hTAn",
            locked: 1717791233,
            timestamp: 1717791231,
          },
        ],
        createdAt: "2024-06-07T20:13:54.752Z",
        updatedAt: "2024-06-07T20:13:54.752Z",
      },
      {
        id: "clx54jnjv00u212vcgtrozomk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone11,8; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wigOQGAfKH",
            locked: 1717791226,
            timestamp: 1717791225,
          },
        ],
        createdAt: "2024-06-07T20:13:49.433Z",
        updatedAt: "2024-06-07T20:13:49.433Z",
      },
      {
        id: "clx54i8kl00u112vc5n0ikgp8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6kyyNdWIb3",
            locked: 1717791152,
            timestamp: 1717791151,
          },
        ],
        createdAt: "2024-06-07T20:12:43.366Z",
        updatedAt: "2024-06-07T20:12:43.366Z",
      },
      {
        id: "clx54i1rt00u012vc2h5i3kx5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "IdlFohskVq",
            locked: 1717791154,
            timestamp: 1717791153,
          },
        ],
        createdAt: "2024-06-07T20:12:34.551Z",
        updatedAt: "2024-06-07T20:12:34.551Z",
      },
      {
        id: "clx54hory00tz12vcrwsk6bai",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yLpEQvg6GZ",
            locked: 1717791137,
            timestamp: 1717791136,
          },
        ],
        createdAt: "2024-06-07T20:12:17.710Z",
        updatedAt: "2024-06-07T20:12:17.710Z",
      },
      {
        id: "clx54hlmj00ty12vc7bj5wqou",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2kaBEFETyp",
            locked: 1717791133,
            timestamp: 1717791131,
          },
        ],
        createdAt: "2024-06-07T20:12:13.624Z",
        updatedAt: "2024-06-07T20:12:13.624Z",
      },
      {
        id: "clx54glkq00tx12vcd57ld778",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lmrYDUH2Na",
            locked: 1717791084,
            timestamp: 1717791083,
          },
        ],
        createdAt: "2024-06-07T20:11:26.905Z",
        updatedAt: "2024-06-07T20:11:26.905Z",
      },
      {
        id: "clx54ghkq00tw12vc73ds1za2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "3u6l7koCxv",
            locked: 1717791081,
            timestamp: 1717791080,
          },
        ],
        createdAt: "2024-06-07T20:11:21.722Z",
        updatedAt: "2024-06-07T20:11:21.722Z",
      },
      {
        id: "clx54geq000tv12vcu9t90jma",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "jbaJ3c97AM",
            locked: 1717791077,
            timestamp: 1717791076,
          },
        ],
        createdAt: "2024-06-07T20:11:18.024Z",
        updatedAt: "2024-06-07T20:11:18.024Z",
      },
      {
        id: "clx54g8r600tu12vcph7jk6dp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "McgWpsVKHg",
            locked: 1717791069,
            timestamp: 1717791068,
          },
        ],
        createdAt: "2024-06-07T20:11:10.289Z",
        updatedAt: "2024-06-07T20:11:10.289Z",
      },
      {
        id: "clx54g1e000tt12vcf1a9x571",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "8MolrivAN8",
            locked: 1717791060,
            timestamp: 1717791058,
          },
        ],
        createdAt: "2024-06-07T20:11:00.744Z",
        updatedAt: "2024-06-07T20:11:00.744Z",
      },
      {
        id: "clx54fvzl00ts12vc1q8eq2ul",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "f0TEfA2V6U",
            locked: 1717791054,
            timestamp: 1717791053,
          },
        ],
        createdAt: "2024-06-07T20:10:53.744Z",
        updatedAt: "2024-06-07T20:10:53.744Z",
      },
      {
        id: "clx54fqh100tr12vc0elrffly",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "m66EwaXz2r",
            locked: 1717791045,
            timestamp: 1717791042,
          },
        ],
        createdAt: "2024-06-07T20:10:46.597Z",
        updatedAt: "2024-06-07T20:10:46.597Z",
      },
      {
        id: "clx54fhaj00tq12vcoyaqdht1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 309.1.1.28.108 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 537288535)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rRKzJztdOF",
            locked: 1717791034,
            timestamp: 1717791033,
          },
        ],
        createdAt: "2024-06-07T20:10:34.696Z",
        updatedAt: "2024-06-07T20:10:34.696Z",
      },
      {
        id: "clx54f53c00tp12vcphfem6ze",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DVW8m1OnqH",
            locked: 1717791018,
            timestamp: 1717791015,
          },
        ],
        createdAt: "2024-06-07T20:10:18.886Z",
        updatedAt: "2024-06-07T20:10:18.886Z",
      },
      {
        id: "clx54eupq00to12vc156qzgm3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:127.0) Gecko/20100101 Firefox/127.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "UOHRch62Nk",
            locked: 1717791005,
            timestamp: 1717791004,
          },
        ],
        createdAt: "2024-06-07T20:10:05.438Z",
        updatedAt: "2024-06-07T20:10:05.438Z",
      },
      {
        id: "clx54etcg00tn12vcr611ksrv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0d2ovQFnew",
            locked: 1717791003,
            timestamp: 1717791001,
          },
        ],
        createdAt: "2024-06-07T20:10:03.664Z",
        updatedAt: "2024-06-07T20:10:03.664Z",
      },
      {
        id: "clx54escq00tm12vcs0vh0u4d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1aEGUvcRhk",
            locked: 1717791001,
            timestamp: 1717791000,
          },
        ],
        createdAt: "2024-06-07T20:10:02.376Z",
        updatedAt: "2024-06-07T20:10:02.376Z",
      },
      {
        id: "clx54emzj00tl12vcq1hpdvnx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "EmzZMQOk0E",
            locked: 1717790995,
            timestamp: 1717790994,
          },
        ],
        createdAt: "2024-06-07T20:09:55.423Z",
        updatedAt: "2024-06-07T20:09:55.423Z",
      },
      {
        id: "clx54ejjt00tk12vce6a1jv4f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pYWnfNrj2v",
            locked: 1717790989,
            timestamp: 1717790988,
          },
        ],
        createdAt: "2024-06-07T20:09:50.970Z",
        updatedAt: "2024-06-07T20:09:50.970Z",
      },
      {
        id: "clx54egct00tj12vcpdqrbclk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aDoOTjWQV2",
            locked: 1717790986,
            timestamp: 1717790984,
          },
        ],
        createdAt: "2024-06-07T20:09:46.827Z",
        updatedAt: "2024-06-07T20:09:46.827Z",
      },
      {
        id: "clx54e28d00ti12vcexzg0xqt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:127.0) Gecko/20100101 Firefox/127.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aIEXDOvnU2",
            locked: 1717790968,
            timestamp: 1717790966,
          },
        ],
        createdAt: "2024-06-07T20:09:28.523Z",
        updatedAt: "2024-06-07T20:09:28.523Z",
      },
      {
        id: "clx54d4d300th12vcj06ya431",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "6vqo8vaTZk",
            locked: 1717790924,
            timestamp: 1717790922,
          },
        ],
        createdAt: "2024-06-07T20:08:44.632Z",
        updatedAt: "2024-06-07T20:08:44.632Z",
      },
      {
        id: "clx54cyka00tg12vc01bce5v6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20C65 Instagram 334.0.4.32.98 (iPhone14,6; iOS 16_2; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7tz7vK4wwz",
            locked: 1717790916,
            timestamp: 1717790914,
          },
        ],
        createdAt: "2024-06-07T20:08:37.112Z",
        updatedAt: "2024-06-07T20:08:37.112Z",
      },
      {
        id: "clx54ci4n00tf12vcyncb5vcx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Pzd32eH2HL",
            locked: 1717790895,
            timestamp: 1717790892,
          },
        ],
        createdAt: "2024-06-07T20:08:15.814Z",
        updatedAt: "2024-06-07T20:08:15.814Z",
      },
      {
        id: "clx54cbzl00te12vca2iu0tgw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "S1DzENTSsK",
            locked: 1717790886,
            timestamp: 1717790885,
          },
        ],
        createdAt: "2024-06-07T20:08:07.857Z",
        updatedAt: "2024-06-07T20:08:07.857Z",
      },
      {
        id: "clx54c8c200td12vc2cxn07sg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "melCYhzKUV",
            locked: 1717790883,
            timestamp: 1717790882,
          },
        ],
        createdAt: "2024-06-07T20:08:03.123Z",
        updatedAt: "2024-06-07T20:08:03.123Z",
      },
      {
        id: "clx54c0nq00tc12vc8wiont3i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QoHeYz3qZH",
            locked: 1717790873,
            timestamp: 1717790871,
          },
        ],
        createdAt: "2024-06-07T20:07:53.172Z",
        updatedAt: "2024-06-07T20:07:53.172Z",
      },
      {
        id: "clx54bg2u00tb12vc0s5oq7iv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "JUBegGwKMP",
            locked: 1717790874,
            timestamp: 1717790873,
          },
        ],
        createdAt: "2024-06-07T20:07:26.499Z",
        updatedAt: "2024-06-07T20:07:26.499Z",
      },
      {
        id: "clx54b40e00ta12vcxp96e9n9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9HNlzwSw3x",
            locked: 1717790858,
            timestamp: 1717790857,
          },
        ],
        createdAt: "2024-06-07T20:07:10.860Z",
        updatedAt: "2024-06-07T20:07:10.860Z",
      },
      {
        id: "clx549bq700t912vc7dqcdodr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rAcGDR4NEI",
            locked: 1717790736,
            timestamp: 1717790733,
          },
        ],
        createdAt: "2024-06-07T20:05:47.550Z",
        updatedAt: "2024-06-07T20:05:47.550Z",
      },
      {
        id: "clx5494ag00t812vcxjl3nmwv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "3kt7tOmP1H",
            locked: 1717790737,
            timestamp: 1717790736,
          },
        ],
        createdAt: "2024-06-07T20:05:37.912Z",
        updatedAt: "2024-06-07T20:05:37.912Z",
      },
      {
        id: "clx5493w800t712vcw9qmy8ho",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "lcoWWssfLy",
            locked: 1717790737,
            timestamp: 1717790736,
          },
        ],
        createdAt: "2024-06-07T20:05:37.400Z",
        updatedAt: "2024-06-07T20:05:37.400Z",
      },
      {
        id: "clx5492hr00t612vcbk434yi8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "G8bYT5lT9P",
            locked: 1717790735,
            timestamp: 1717790733,
          },
        ],
        createdAt: "2024-06-07T20:05:35.584Z",
        updatedAt: "2024-06-07T20:05:35.584Z",
      },
      {
        id: "clx548u5700t512vci3pc8hx0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "S5F2SexiL9",
            locked: 1717790724,
            timestamp: 1717790723,
          },
        ],
        createdAt: "2024-06-07T20:05:24.764Z",
        updatedAt: "2024-06-07T20:05:24.764Z",
      },
      {
        id: "clx548ts400t412vc7g89gha6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_6_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "SZs7ifQx4P",
            locked: 1717790723,
            timestamp: 1717790722,
          },
        ],
        createdAt: "2024-06-07T20:05:24.289Z",
        updatedAt: "2024-06-07T20:05:24.289Z",
      },
      {
        id: "clx548l0s00t312vc44k0gv19",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21C66 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_2_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "uxW7TTAuo6",
            locked: 1717790712,
            timestamp: 1717790710,
          },
        ],
        createdAt: "2024-06-07T20:05:12.940Z",
        updatedAt: "2024-06-07T20:05:12.940Z",
      },
      {
        id: "clx548klv00t212vcto2pc5qq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_6_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lyKQpesdrn",
            locked: 1717790710,
            timestamp: 1717790709,
          },
        ],
        createdAt: "2024-06-07T20:05:12.403Z",
        updatedAt: "2024-06-07T20:05:12.403Z",
      },
      {
        id: "clx548htn00t112vcan98e59q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; Power Armor 18T Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 408dpi; 1080x2194; Ulefone; Power Armor 18T; Power_Armor_18T; mt6877; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "8gwPI3Jr7S",
            locked: 1717790708,
            timestamp: 1717790707,
          },
        ],
        createdAt: "2024-06-07T20:05:08.796Z",
        updatedAt: "2024-06-07T20:05:08.796Z",
      },
      {
        id: "clx548hf900t012vcszuiyh29",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nzAnc3big0",
            locked: 1717790705,
            timestamp: 1717790702,
          },
        ],
        createdAt: "2024-06-07T20:05:08.278Z",
        updatedAt: "2024-06-07T20:05:08.278Z",
      },
      {
        id: "clx548adg00sz12vcq6xx5ek1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; Power Armor 18T Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (31/12; 408dpi; 1080x2194; Ulefone; Power Armor 18T; Power_Armor_18T; mt6877; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jQyUOdjlN5",
            locked: 1717790698,
            timestamp: 1717790694,
          },
        ],
        createdAt: "2024-06-07T20:04:59.134Z",
        updatedAt: "2024-06-07T20:04:59.134Z",
      },
      {
        id: "clx545mrw00sy12vcayfvg7nt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "eHskFSM64U",
            locked: 1717790577,
            timestamp: 1717790576,
          },
        ],
        createdAt: "2024-06-07T20:02:55.234Z",
        updatedAt: "2024-06-07T20:02:55.234Z",
      },
      {
        id: "clx5457um00sx12vc4ha2ei8z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "b1q3dqqUMf",
            locked: 1717790552,
            timestamp: 1717790551,
          },
        ],
        createdAt: "2024-06-07T20:02:35.900Z",
        updatedAt: "2024-06-07T20:02:35.900Z",
      },
      {
        id: "clx544p4200sw12vc9o1u51ra",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nJFwqfkg8W",
            locked: 1717790533,
            timestamp: 1717790531,
          },
        ],
        createdAt: "2024-06-07T20:02:11.618Z",
        updatedAt: "2024-06-07T20:02:11.618Z",
      },
      {
        id: "clx544gky00sv12vcgnbpfw23",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0pHyrZqBSj",
            locked: 1717790518,
            timestamp: 1717790516,
          },
        ],
        createdAt: "2024-06-07T20:02:00.559Z",
        updatedAt: "2024-06-07T20:02:00.559Z",
      },
      {
        id: "clx5440pu00su12vcqc0ptvjf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H115 Instagram 334.0.4.32.98 (iPhone14,3; iOS 16_7_2; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "cf5VBESXLv",
            locked: 1717790499,
            timestamp: 1717790498,
          },
        ],
        createdAt: "2024-06-07T20:01:40.003Z",
        updatedAt: "2024-06-07T20:01:40.003Z",
      },
      {
        id: "clx543yll00st12vcg9oqzh21",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H115 Instagram 334.0.4.32.98 (iPhone14,3; iOS 16_7_2; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "soYnlCb5Et",
            locked: 1717790495,
            timestamp: 1717790489,
          },
        ],
        createdAt: "2024-06-07T20:01:37.254Z",
        updatedAt: "2024-06-07T20:01:37.254Z",
      },
      {
        id: "clx5435dk00ss12vczqlxc8rm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "9XqWJAo3mT",
            locked: 1717790726,
            timestamp: 1717790725,
          },
        ],
        createdAt: "2024-06-07T20:00:59.383Z",
        updatedAt: "2024-06-07T20:00:59.383Z",
      },
      {
        id: "clx541p6f00sr12vcpm6syuj8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 310.0.4.21.348 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 542705233)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "OdfiZDTlkh",
            locked: 1717790391,
            timestamp: 1717790390,
          },
        ],
        createdAt: "2024-06-07T19:59:51.734Z",
        updatedAt: "2024-06-07T19:59:51.734Z",
      },
      {
        id: "clx541kwm00sq12vckrobpg7k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ixuP8dCN4F",
            locked: 1717790651,
            timestamp: 1717790649,
          },
        ],
        createdAt: "2024-06-07T19:59:46.198Z",
        updatedAt: "2024-06-07T19:59:46.198Z",
      },
      {
        id: "clx541cp700sp12vc8ml6woc1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 310.0.4.21.348 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 542705233)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Mlpz5jdsgV",
            locked: 1717790374,
            timestamp: 1717790372,
          },
        ],
        createdAt: "2024-06-07T19:59:35.562Z",
        updatedAt: "2024-06-07T19:59:35.562Z",
      },
      {
        id: "clx541abs00so12vcwd9f0ct2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "0oEgI6OyUi",
            locked: 1717790372,
            timestamp: 1717790371,
          },
        ],
        createdAt: "2024-06-07T19:59:32.488Z",
        updatedAt: "2024-06-07T19:59:32.488Z",
      },
      {
        id: "clx5419tx00sn12vcn6m2mnah",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "abiWZ6Hqgb",
            locked: 1717790371,
            timestamp: 1717790370,
          },
        ],
        createdAt: "2024-06-07T19:59:31.845Z",
        updatedAt: "2024-06-07T19:59:31.845Z",
      },
      {
        id: "clx5418b400sm12vc4zpf9v3m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "iVKYZqUm9G",
            locked: 1717790369,
            timestamp: 1717790368,
          },
        ],
        createdAt: "2024-06-07T19:59:29.872Z",
        updatedAt: "2024-06-07T19:59:29.872Z",
      },
      {
        id: "clx540z9h00sl12vcz5edw37z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YYVOUAIMg7",
            locked: 1717790356,
            timestamp: 1717790352,
          },
        ],
        createdAt: "2024-06-07T19:59:18.146Z",
        updatedAt: "2024-06-07T19:59:18.146Z",
      },
      {
        id: "clx540if400sk12vcz5n1uda7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "UZ5SYS8TXE",
            locked: 1717790335,
            timestamp: 1717790334,
          },
        ],
        createdAt: "2024-06-07T19:58:56.318Z",
        updatedAt: "2024-06-07T19:58:56.318Z",
      },
      {
        id: "clx53zy5u00sj12vcq8qx78bs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36 OPR/110.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5ePyAe9bzy",
            locked: 1717790309,
            timestamp: 1717790305,
          },
        ],
        createdAt: "2024-06-07T19:58:30.063Z",
        updatedAt: "2024-06-07T19:58:30.063Z",
      },
      {
        id: "clx53zov500si12vczdiw95hp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "mtkEyERQUm",
            locked: 1717790297,
            timestamp: 1717790296,
          },
        ],
        createdAt: "2024-06-07T19:58:18.017Z",
        updatedAt: "2024-06-07T19:58:18.017Z",
      },
      {
        id: "clx53znxa00sh12vcxsomp6xy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Zux4u8T2oO",
            locked: 1717790296,
            timestamp: 1717790295,
          },
        ],
        createdAt: "2024-06-07T19:58:16.799Z",
        updatedAt: "2024-06-07T19:58:16.799Z",
      },
      {
        id: "clx53zmrv00sg12vcjmsutrzi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CTgph3cikE",
            locked: 1717790294,
            timestamp: 1717790293,
          },
        ],
        createdAt: "2024-06-07T19:58:15.307Z",
        updatedAt: "2024-06-07T19:58:15.307Z",
      },
      {
        id: "clx53zmle00sf12vcmofqe2zp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Y64qiPKlGP",
            locked: 1717790294,
            timestamp: 1717790293,
          },
        ],
        createdAt: "2024-06-07T19:58:15.074Z",
        updatedAt: "2024-06-07T19:58:15.074Z",
      },
      {
        id: "clx53zm8g00se12vc6vay2j9p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S711U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2218; samsung; SM-S711U; r11q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "e80FlGoSRL",
            locked: 1717790294,
            timestamp: 1717790293,
          },
        ],
        createdAt: "2024-06-07T19:58:14.606Z",
        updatedAt: "2024-06-07T19:58:14.606Z",
      },
      {
        id: "clx53z83800sd12vc8hu6a84p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5MLKrCNeJN",
            locked: 1717790273,
            timestamp: 1717790272,
          },
        ],
        createdAt: "2024-06-07T19:57:56.276Z",
        updatedAt: "2024-06-07T19:57:56.276Z",
      },
      {
        id: "clx53z14q00sc12vcss5fyjvf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S711U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2218; samsung; SM-S711U; r11q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ih3qbio9vc",
            locked: 1717790266,
            timestamp: 1717790264,
          },
        ],
        createdAt: "2024-06-07T19:57:47.258Z",
        updatedAt: "2024-06-07T19:57:47.258Z",
      },
      {
        id: "clx53yz1l00sb12vc0lvp87le",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "A4esK1uZ3P",
            locked: 1717790264,
            timestamp: 1717790262,
          },
        ],
        createdAt: "2024-06-07T19:57:44.552Z",
        updatedAt: "2024-06-07T19:57:44.552Z",
      },
      {
        id: "clx53uvxb00sa12vcgni5mp33",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wyM2Lk0Qig",
            locked: 1717790073,
            timestamp: 1717790071,
          },
        ],
        createdAt: "2024-06-07T19:54:33.885Z",
        updatedAt: "2024-06-07T19:54:33.885Z",
      },
      {
        id: "clx53ulws00s912vcw5yyaw2z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "LEdeaQWmJW",
            locked: 1717790061,
            timestamp: 1717790059,
          },
        ],
        createdAt: "2024-06-07T19:54:20.908Z",
        updatedAt: "2024-06-07T19:54:20.908Z",
      },
      {
        id: "clx53ujmg00s812vc8g66zejg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YrIIKTvDKu",
            locked: 1717790057,
            timestamp: 1717790056,
          },
        ],
        createdAt: "2024-06-07T19:54:17.941Z",
        updatedAt: "2024-06-07T19:54:17.941Z",
      },
      {
        id: "clx53ua2700s712vcoyy1zx3y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WpPE65eCmv",
            locked: 1717790045,
            timestamp: 1717790043,
          },
        ],
        createdAt: "2024-06-07T19:54:05.551Z",
        updatedAt: "2024-06-07T19:54:05.551Z",
      },
      {
        id: "clx53u13m00s612vcgemgso43",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 313.0.2.9.339 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 553462334)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0LRFkyUdBE",
            locked: 1717790032,
            timestamp: 1717790031,
          },
        ],
        createdAt: "2024-06-07T19:53:53.935Z",
        updatedAt: "2024-06-07T19:53:53.935Z",
      },
      {
        id: "clx53t5xw00s512vckzal1m0h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "A49PxPCJOc",
            locked: 1717789992,
            timestamp: 1717789991,
          },
        ],
        createdAt: "2024-06-07T19:53:13.556Z",
        updatedAt: "2024-06-07T19:53:13.556Z",
      },
      {
        id: "clx53t05100s412vc8n6rrl46",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E237 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_4_1; en_001@rg=bezzzz; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "K4kRyFKCpd",
            locked: 1717789985,
            timestamp: 1717789984,
          },
        ],
        createdAt: "2024-06-07T19:53:06.037Z",
        updatedAt: "2024-06-07T19:53:06.037Z",
      },
      {
        id: "clx53sxkz00s312vcttf2kwsj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S916B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2122; samsung; SM-S916B; dm2q; qcom; fr_CH; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pqYeyp3BQF",
            locked: 1717789982,
            timestamp: 1717789981,
          },
        ],
        createdAt: "2024-06-07T19:53:02.723Z",
        updatedAt: "2024-06-07T19:53:02.723Z",
      },
      {
        id: "clx53sx5b00s212vc1iym4hx6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E237 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_4_1; en_001@rg=bezzzz; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2f92L0vH5g",
            locked: 1717789981,
            timestamp: 1717789980,
          },
        ],
        createdAt: "2024-06-07T19:53:02.160Z",
        updatedAt: "2024-06-07T19:53:02.160Z",
      },
      {
        id: "clx53suv400s112vcgmy5c9wb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yUtQe7cS8o",
            locked: 1717789978,
            timestamp: 1717789977,
          },
        ],
        createdAt: "2024-06-07T19:52:59.198Z",
        updatedAt: "2024-06-07T19:52:59.198Z",
      },
      {
        id: "clx53rmup00s012vc7gnu3y2s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AJrGt89r7w",
            locked: 1717789921,
            timestamp: 1717789919,
          },
        ],
        createdAt: "2024-06-07T19:52:02.159Z",
        updatedAt: "2024-06-07T19:52:02.159Z",
      },
      {
        id: "clx53qk0n00rz12vcyn4hjgxb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone14,7; iOS 16_6_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "iA6ag8vWwf",
            locked: 1717789871,
            timestamp: 1717789870,
          },
        ],
        createdAt: "2024-06-07T19:51:11.832Z",
        updatedAt: "2024-06-07T19:51:11.832Z",
      },
      {
        id: "clx53qazx00ry12vc7td59vkg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SQxrQcekVF",
            locked: 1717789859,
            timestamp: 1717789858,
          },
        ],
        createdAt: "2024-06-07T19:51:00.137Z",
        updatedAt: "2024-06-07T19:51:00.137Z",
      },
      {
        id: "clx53q37o00rx12vc6qy7aj8b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "vZgswIpNRh",
            locked: 1717789849,
            timestamp: 1717789848,
          },
        ],
        createdAt: "2024-06-07T19:50:50.052Z",
        updatedAt: "2024-06-07T19:50:50.052Z",
      },
      {
        id: "clx53q2gq00rw12vc0jsovu9e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone14,7; iOS 16_6_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yvz1NRpSYu",
            locked: 1717789848,
            timestamp: 1717789847,
          },
        ],
        createdAt: "2024-06-07T19:50:49.083Z",
        updatedAt: "2024-06-07T19:50:49.083Z",
      },
      {
        id: "clx53ptwb00rv12vctonj2dag",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NaJRO3KIE8",
            locked: 1717789837,
            timestamp: 1717789836,
          },
        ],
        createdAt: "2024-06-07T19:50:37.976Z",
        updatedAt: "2024-06-07T19:50:37.976Z",
      },
      {
        id: "clx53pebm00ru12vcea23asr9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; moto g stylus 5G - 2023 Build/U1TGNS34.42-86-2-1; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 340dpi; 1080x2188; motorola; moto g stylus 5G - 2023; genevn; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vKQGhbGAlA",
            locked: 1717789817,
            timestamp: 1717789814,
          },
        ],
        createdAt: "2024-06-07T19:50:17.792Z",
        updatedAt: "2024-06-07T19:50:17.792Z",
      },
      {
        id: "clx53ol7100rt12vcp4nppdqn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G991U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2176; samsung; SM-G991U1; o1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JYGNxQTN7r",
            locked: 1717789779,
            timestamp: 1717789777,
          },
        ],
        createdAt: "2024-06-07T19:49:40.044Z",
        updatedAt: "2024-06-07T19:49:40.044Z",
      },
      {
        id: "clx53oj6b00rs12vc31xdl1hl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "OJbqODrHwD",
            locked: 1717789777,
            timestamp: 1717789776,
          },
        ],
        createdAt: "2024-06-07T19:49:37.428Z",
        updatedAt: "2024-06-07T19:49:37.428Z",
      },
      {
        id: "clx53oero00rr12vci462s4tz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "c30IEWw02R",
            locked: 1717789771,
            timestamp: 1717789769,
          },
        ],
        createdAt: "2024-06-07T19:49:31.716Z",
        updatedAt: "2024-06-07T19:49:31.716Z",
      },
      {
        id: "clx53o87y00rq12vcinl85jnt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 331.1.3.29.90 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ev5bSz2OhO",
            locked: 1717789762,
            timestamp: 1717789761,
          },
        ],
        createdAt: "2024-06-07T19:49:23.228Z",
        updatedAt: "2024-06-07T19:49:23.228Z",
      },
      {
        id: "clx53nugu00rp12vcpwiu4po9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F79 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "yKl9qGJxdW",
            locked: 1717789745,
            timestamp: 1717789744,
          },
        ],
        createdAt: "2024-06-07T19:49:05.406Z",
        updatedAt: "2024-06-07T19:49:05.406Z",
      },
      {
        id: "clx53nr5100ro12vc1ea4f3hc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Mk8OaUS6yN",
            locked: 1717789740,
            timestamp: 1717789739,
          },
        ],
        createdAt: "2024-06-07T19:49:01.093Z",
        updatedAt: "2024-06-07T19:49:01.093Z",
      },
      {
        id: "clx53nnqt00rn12vcd4wf25ex",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F79 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AZAK8JwNJ7",
            locked: 1717789736,
            timestamp: 1717789734,
          },
        ],
        createdAt: "2024-06-07T19:48:56.691Z",
        updatedAt: "2024-06-07T19:48:56.691Z",
      },
      {
        id: "clx53mzi400rm12vcz5qmcat2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Tv3cUAHcCs",
            locked: 1717789704,
            timestamp: 1717789703,
          },
        ],
        createdAt: "2024-06-07T19:48:25.274Z",
        updatedAt: "2024-06-07T19:48:25.274Z",
      },
      {
        id: "clx53knrn00rl12vc8ru8a24c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "JX8PFdBBme",
            locked: 1717789596,
            timestamp: 1717789595,
          },
        ],
        createdAt: "2024-06-07T19:46:36.755Z",
        updatedAt: "2024-06-07T19:46:36.755Z",
      },
      {
        id: "clx53klpz00rk12vc343qyhgz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yCaLO3BFdi",
            locked: 1717789593,
            timestamp: 1717789592,
          },
        ],
        createdAt: "2024-06-07T19:46:34.101Z",
        updatedAt: "2024-06-07T19:46:34.101Z",
      },
      {
        id: "clx53jfpd00rj12vcd77cment",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "keb5OIHmqo",
            locked: 1717789539,
            timestamp: 1717789537,
          },
        ],
        createdAt: "2024-06-07T19:45:39.647Z",
        updatedAt: "2024-06-07T19:45:39.647Z",
      },
      {
        id: "clx53iweh00ri12vcktx0828x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "v9TQzoikaN",
            locked: 1717789514,
            timestamp: 1717789512,
          },
        ],
        createdAt: "2024-06-07T19:45:14.634Z",
        updatedAt: "2024-06-07T19:45:14.634Z",
      },
      {
        id: "clx53ito800rh12vc1jtx09x9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yzY3T2YjWF",
            locked: 1717789510,
            timestamp: 1717789509,
          },
        ],
        createdAt: "2024-06-07T19:45:11.094Z",
        updatedAt: "2024-06-07T19:45:11.094Z",
      },
      {
        id: "clx53ie8m00rg12vcp9smvkig",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F926U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 840x2081; samsung; SM-F926U1; q2q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bOr88LISGY",
            locked: 1717789490,
            timestamp: 1717789488,
          },
        ],
        createdAt: "2024-06-07T19:44:51.091Z",
        updatedAt: "2024-06-07T19:44:51.091Z",
      },
      {
        id: "clx53hmew00rf12vcqa7kxgzg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/86.0.4240.93 Mobile/15E148 Safari/604.1",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "QQ4Bs4TbVm",
            locked: 1717789454,
            timestamp: 1717789453,
          },
        ],
        createdAt: "2024-06-07T19:44:15.030Z",
        updatedAt: "2024-06-07T19:44:15.030Z",
      },
      {
        id: "clx53hdgc00re12vc6sojkd0h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/86.0.4240.93 Mobile/15E148 Safari/604.1",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "oGeeTLz7FT",
            locked: 1717789442,
            timestamp: 1717789441,
          },
        ],
        createdAt: "2024-06-07T19:44:03.420Z",
        updatedAt: "2024-06-07T19:44:03.420Z",
      },
      {
        id: "clx53h65o00rd12vcg90a97qf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1624; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YM23zaDLmA",
            locked: 1717789433,
            timestamp: 1717789430,
          },
        ],
        createdAt: "2024-06-07T19:43:53.962Z",
        updatedAt: "2024-06-07T19:43:53.962Z",
      },
      {
        id: "clx53gh3f00rc12vc1l4xla4v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19F77 Instagram 334.0.4.32.98 (iPhone12,1; iOS 15_5; en_US; en; scale=2.00; 828x1792; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "d5hCh7nUPA",
            locked: 1717789400,
            timestamp: 1717789392,
          },
        ],
        createdAt: "2024-06-07T19:43:21.481Z",
        updatedAt: "2024-06-07T19:43:21.481Z",
      },
      {
        id: "clx53g3zu00rb12vcpegjgz1h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "H741BBH4mf",
            locked: 1717789384,
            timestamp: 1717789382,
          },
        ],
        createdAt: "2024-06-07T19:43:04.505Z",
        updatedAt: "2024-06-07T19:43:04.505Z",
      },
      {
        id: "clx53ess200ra12vcwna131nz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Er2b6Ps7Sg",
            locked: 1717789323,
            timestamp: 1717789322,
          },
        ],
        createdAt: "2024-06-07T19:42:03.314Z",
        updatedAt: "2024-06-07T19:42:03.314Z",
      },
      {
        id: "clx53esfg00r912vc36i9fxlb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qj8cw1Jk7m",
            locked: 1717789321,
            timestamp: 1717789317,
          },
        ],
        createdAt: "2024-06-07T19:42:02.857Z",
        updatedAt: "2024-06-07T19:42:02.857Z",
      },
      {
        id: "clx53e5xh00r812vcj8ha9p5y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "QkKmqMi2xK",
            locked: 1717789293,
            timestamp: 1717789292,
          },
        ],
        createdAt: "2024-06-07T19:41:33.698Z",
        updatedAt: "2024-06-07T19:41:33.698Z",
      },
      {
        id: "clx53dg9700r712vci4ao5ec2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "H2noD9AMrM",
            locked: 1717789259,
            timestamp: 1717789257,
          },
        ],
        createdAt: "2024-06-07T19:41:00.425Z",
        updatedAt: "2024-06-07T19:41:00.425Z",
      },
      {
        id: "clx53c30300r612vcoamtkmah",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "xiK7CNb93O",
            locked: 1717789194,
            timestamp: 1717789193,
          },
        ],
        createdAt: "2024-06-07T19:39:56.595Z",
        updatedAt: "2024-06-07T19:39:56.595Z",
      },
      {
        id: "clx53c0jh00r512vcuz9nljtv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DS9stBChif",
            locked: 1717789190,
            timestamp: 1717789189,
          },
        ],
        createdAt: "2024-06-07T19:39:53.405Z",
        updatedAt: "2024-06-07T19:39:53.405Z",
      },
      {
        id: "clx53brni00r412vc873yzo8m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N981U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (33/13; 510dpi; 1080x2155; samsung; SM-N981U; c1q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pPcasjf0UQ",
            locked: 1717789179,
            timestamp: 1717789173,
          },
        ],
        createdAt: "2024-06-07T19:39:41.884Z",
        updatedAt: "2024-06-07T19:39:41.884Z",
      },
      {
        id: "clx53bes200r312vcxgkuaxan",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_AR; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YThD3CWFuu",
            locked: 1717789164,
            timestamp: 1717789162,
          },
        ],
        createdAt: "2024-06-07T19:39:25.199Z",
        updatedAt: "2024-06-07T19:39:25.199Z",
      },
      {
        id: "clx53aakr00r212vci8mrmnkt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U2 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 510dpi; 1080x2088; samsung; SM-G990U2; r9q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "UkJMmf4hv4",
            locked: 1717789112,
            timestamp: 1717789111,
          },
        ],
        createdAt: "2024-06-07T19:38:33.100Z",
        updatedAt: "2024-06-07T19:38:33.100Z",
      },
      {
        id: "clx53a7hp00r112vc2h6l22cb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JXebw2pAoo",
            locked: 1717789107,
            timestamp: 1717789104,
          },
        ],
        createdAt: "2024-06-07T19:38:29.100Z",
        updatedAt: "2024-06-07T19:38:29.100Z",
      },
      {
        id: "clx53a2yi00r012vcjctnqfst",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.5938.132 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "sd8JUMiwhU",
            locked: 1717789101,
            timestamp: 1717789099,
          },
        ],
        createdAt: "2024-06-07T19:38:23.227Z",
        updatedAt: "2024-06-07T19:38:23.227Z",
      },
      {
        id: "clx539ya000qz12vctzprcsfr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_6_1; en_IN; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "712nY9PaXY",
            locked: 1717789096,
            timestamp: 1717789095,
          },
        ],
        createdAt: "2024-06-07T19:38:17.161Z",
        updatedAt: "2024-06-07T19:38:17.161Z",
      },
      {
        id: "clx539tx800qy12vcohgs52nk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990U2 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 510dpi; 1080x2088; samsung; SM-G990U2; r9q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "oYfW1YFTCx",
            locked: 1717789090,
            timestamp: 1717789078,
          },
        ],
        createdAt: "2024-06-07T19:38:11.516Z",
        updatedAt: "2024-06-07T19:38:11.516Z",
      },
      {
        id: "clx539tdx00qx12vc6ke2dbuo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_6_1; en_IN; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yM7dTQ9gbX",
            locked: 1717789090,
            timestamp: 1717789089,
          },
        ],
        createdAt: "2024-06-07T19:38:10.820Z",
        updatedAt: "2024-06-07T19:38:10.820Z",
      },
      {
        id: "clx539ow800qw12vcyzku5xvx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JjL75mSCEn",
            locked: 1717789084,
            timestamp: 1717789082,
          },
        ],
        createdAt: "2024-06-07T19:38:05.000Z",
        updatedAt: "2024-06-07T19:38:05.000Z",
      },
      {
        id: "clx539hei00qv12vcpp2c4qog",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PpW8EB9Auy",
            locked: 1717789074,
            timestamp: 1717789073,
          },
        ],
        createdAt: "2024-06-07T19:37:55.287Z",
        updatedAt: "2024-06-07T19:37:55.287Z",
      },
      {
        id: "clx538w1r00qu12vchv63u7ne",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "uquiF6Ju6L",
            locked: 1717789046,
            timestamp: 1717789044,
          },
        ],
        createdAt: "2024-06-07T19:37:27.614Z",
        updatedAt: "2024-06-07T19:37:27.614Z",
      },
      {
        id: "clx538pgq00qt12vcftu6f9sk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "joMOLYhuux",
            locked: 1717789038,
            timestamp: 1717789036,
          },
        ],
        createdAt: "2024-06-07T19:37:19.083Z",
        updatedAt: "2024-06-07T19:37:19.083Z",
      },
      {
        id: "clx538dxm00qs12vcxsv6f56v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ujYEqSjpdA",
            locked: 1717789023,
            timestamp: 1717789021,
          },
        ],
        createdAt: "2024-06-07T19:37:04.137Z",
        updatedAt: "2024-06-07T19:37:04.137Z",
      },
      {
        id: "clx537r5d00qr12vcit70scm9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "T8ec9zjNC6",
            locked: 1717788994,
            timestamp: 1717788992,
          },
        ],
        createdAt: "2024-06-07T19:36:34.609Z",
        updatedAt: "2024-06-07T19:36:34.609Z",
      },
      {
        id: "clx537ozt00qq12vc9kpcd2yo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7Ic18NrdKr",
            locked: 1717788991,
            timestamp: 1717788989,
          },
        ],
        createdAt: "2024-06-07T19:36:31.817Z",
        updatedAt: "2024-06-07T19:36:31.817Z",
      },
      {
        id: "clx537ltq00qp12vc05p5q9rj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KPOsFwECIg",
            locked: 1717788987,
            timestamp: 1717788986,
          },
        ],
        createdAt: "2024-06-07T19:36:27.708Z",
        updatedAt: "2024-06-07T19:36:27.708Z",
      },
      {
        id: "clx536cv200qo12vc94m3tt84",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D67 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_3_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "SmRN6soHCU",
            locked: 1717788928,
            timestamp: 1717788927,
          },
        ],
        createdAt: "2024-06-07T19:35:29.435Z",
        updatedAt: "2024-06-07T19:35:29.435Z",
      },
      {
        id: "clx532wr800qn12vc5tvilbbz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F721U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/126.0.6478.40 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2402; samsung; SM-F721U; b4q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jZAsUMfwhd",
            locked: 1717788767,
            timestamp: 1717788765,
          },
        ],
        createdAt: "2024-06-07T19:32:48.593Z",
        updatedAt: "2024-06-07T19:32:48.593Z",
      },
      {
        id: "clx532l5s00qm12vcn7rs9plv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AzBnpcCDTk",
            locked: 1717788753,
            timestamp: 1717788751,
          },
        ],
        createdAt: "2024-06-07T19:32:33.565Z",
        updatedAt: "2024-06-07T19:32:33.565Z",
      },
      {
        id: "clx5321ag00ql12vcxq065ruv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "isJElBD9P6",
            locked: 1717788727,
            timestamp: 1717788725,
          },
        ],
        createdAt: "2024-06-07T19:32:07.817Z",
        updatedAt: "2024-06-07T19:32:07.817Z",
      },
      {
        id: "clx531ucd00qk12vc88a3n57p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PivN5o2JX4",
            locked: 1717788717,
            timestamp: 1717788716,
          },
        ],
        createdAt: "2024-06-07T19:31:58.814Z",
        updatedAt: "2024-06-07T19:31:58.814Z",
      },
      {
        id: "clx531tjz00qj12vcasdxxk2e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "G81t3cvIpf",
            locked: 1717788717,
            timestamp: 1717788716,
          },
        ],
        createdAt: "2024-06-07T19:31:57.790Z",
        updatedAt: "2024-06-07T19:31:57.790Z",
      },
      {
        id: "clx531nqa00qi12vc2gyzwbx8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kZDm6ZF9mC",
            locked: 1717788709,
            timestamp: 1717788708,
          },
        ],
        createdAt: "2024-06-07T19:31:50.243Z",
        updatedAt: "2024-06-07T19:31:50.243Z",
      },
      {
        id: "clx531jcw00qh12vcfbq3g3gm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Qf10sC4BOP",
            locked: 1717788703,
            timestamp: 1717788702,
          },
        ],
        createdAt: "2024-06-07T19:31:44.576Z",
        updatedAt: "2024-06-07T19:31:44.576Z",
      },
      {
        id: "clx531gig00qg12vcekh5qhuw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4XLstpXPd5",
            locked: 1717788700,
            timestamp: 1717788697,
          },
        ],
        createdAt: "2024-06-07T19:31:40.886Z",
        updatedAt: "2024-06-07T19:31:40.886Z",
      },
      {
        id: "clx530yw000qf12vc8563szos",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 295.0.0.16.63 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 501817603)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "wSmZnKnrxd",
            locked: 1717788677,
            timestamp: 1717788676,
          },
        ],
        createdAt: "2024-06-07T19:31:18.048Z",
        updatedAt: "2024-06-07T19:31:18.048Z",
      },
      {
        id: "clx530t2o00qe12vc95jrutlh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 295.0.0.16.63 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 501817603)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ch1crSGwY3",
            locked: 1717788669,
            timestamp: 1717788668,
          },
        ],
        createdAt: "2024-06-07T19:31:10.508Z",
        updatedAt: "2024-06-07T19:31:10.508Z",
      },
      {
        id: "clx52zaxc00qd12vcq6uur5u9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone15,2; iOS 16_6_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Nb1tosg1X3",
            locked: 1717788599,
            timestamp: 1717788598,
          },
        ],
        createdAt: "2024-06-07T19:30:00.336Z",
        updatedAt: "2024-06-07T19:30:00.336Z",
      },
      {
        id: "clx52zaoi00qc12vcwo692wkh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 331.1.3.29.90 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KJg7MTyS0q",
            locked: 1717788599,
            timestamp: 1717788598,
          },
        ],
        createdAt: "2024-06-07T19:30:00.015Z",
        updatedAt: "2024-06-07T19:30:00.015Z",
      },
      {
        id: "clx52yoka00qb12vcmr5qpiqc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "8DDPfWgavm",
            locked: 1717788570,
            timestamp: 1717788569,
          },
        ],
        createdAt: "2024-06-07T19:29:31.352Z",
        updatedAt: "2024-06-07T19:29:31.352Z",
      },
      {
        id: "clx52xwm300qa12vcpbwtt7mo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ofFVlauRzY",
            locked: 1717788535,
            timestamp: 1717788534,
          },
        ],
        createdAt: "2024-06-07T19:28:55.129Z",
        updatedAt: "2024-06-07T19:28:55.129Z",
      },
      {
        id: "clx52xiv500q912vcvua5xelo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "sYCKrX4RH9",
            locked: 1717788516,
            timestamp: 1717788515,
          },
        ],
        createdAt: "2024-06-07T19:28:37.308Z",
        updatedAt: "2024-06-07T19:28:37.308Z",
      },
      {
        id: "clx52vne900q812vculq9aw9r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 260.1.0.16.106 (iPhone14,8; iOS 16_3_1; en_US; en-US; scale=3.00; 1284x2778; 416871149)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ThJrGp8pRs",
            locked: 1717788429,
            timestamp: 1717788427,
          },
        ],
        createdAt: "2024-06-07T19:27:09.871Z",
        updatedAt: "2024-06-07T19:27:09.871Z",
      },
      {
        id: "clx52v3pe00q712vcvrwzidse",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,2; iOS 16_7_8; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "y63Mrg5ypT",
            locked: 1717788403,
            timestamp: 1717788401,
          },
        ],
        createdAt: "2024-06-07T19:26:44.353Z",
        updatedAt: "2024-06-07T19:26:44.353Z",
      },
      {
        id: "clx52v1v100q612vcjph1qt27",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "hwJPG9s3Ia",
            locked: 1717788401,
            timestamp: 1717788400,
          },
        ],
        createdAt: "2024-06-07T19:26:41.965Z",
        updatedAt: "2024-06-07T19:26:41.965Z",
      },
      {
        id: "clx52uvi800q512vcyfdlkvl7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 [FBAN/FBIOS;FBAV/466.0.0.36.107;FBBV/604687626;FBDV/iPhone14,2;FBMD/iPhone;FBSN/iOS;FBSV/17.5.1;FBSS/3;FBID/phone;FBLC/en_GB;FBOP/5;FBRV/608069078]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "C23p0Zyhwc",
            locked: 1717788393,
            timestamp: 1717788392,
          },
        ],
        createdAt: "2024-06-07T19:26:33.729Z",
        updatedAt: "2024-06-07T19:26:33.729Z",
      },
      {
        id: "clx52urxd00q412vcf3fn4h30",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1mhZZNWSuQ",
            locked: 1717788388,
            timestamp: 1717788387,
          },
        ],
        createdAt: "2024-06-07T19:26:29.086Z",
        updatedAt: "2024-06-07T19:26:29.086Z",
      },
      {
        id: "clx52u2xj00q312vc8ijzbz8x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "iWjRq2Kr1C",
            locked: 1717788356,
            timestamp: 1717788355,
          },
        ],
        createdAt: "2024-06-07T19:25:56.693Z",
        updatedAt: "2024-06-07T19:25:56.693Z",
      },
      {
        id: "clx52tfux00q212vcltfb9xkm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lVlNBjNneG",
            locked: 1717788326,
            timestamp: 1717788325,
          },
        ],
        createdAt: "2024-06-07T19:25:26.791Z",
        updatedAt: "2024-06-07T19:25:26.791Z",
      },
      {
        id: "clx52t5fe00q112vcm9qvjlfv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "j7c6jy7qQe",
            locked: 1717788312,
            timestamp: 1717788311,
          },
        ],
        createdAt: "2024-06-07T19:25:13.274Z",
        updatedAt: "2024-06-07T19:25:13.274Z",
      },
      {
        id: "clx52suti00q012vcl4a16hsf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uPwM4d2eOI",
            locked: 1717788299,
            timestamp: 1717788297,
          },
        ],
        createdAt: "2024-06-07T19:24:59.525Z",
        updatedAt: "2024-06-07T19:24:59.525Z",
      },
      {
        id: "clx52sfh900pz12vcg12n2svn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "I3jhAEDNDd",
            locked: 1717788279,
            timestamp: 1717788277,
          },
        ],
        createdAt: "2024-06-07T19:24:39.644Z",
        updatedAt: "2024-06-07T19:24:39.644Z",
      },
      {
        id: "clx52sczx00py12vcieh3cvsr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kIUzZfNYHi",
            locked: 1717788275,
            timestamp: 1717788274,
          },
        ],
        createdAt: "2024-06-07T19:24:36.429Z",
        updatedAt: "2024-06-07T19:24:36.429Z",
      },
      {
        id: "clx52s5uh00px12vcgu024eac",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_IN; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "LoXvFEg6uP",
            locked: 1717788267,
            timestamp: 1717788266,
          },
        ],
        createdAt: "2024-06-07T19:24:27.161Z",
        updatedAt: "2024-06-07T19:24:27.161Z",
      },
      {
        id: "clx52s3pz00pw12vcypnx9b3q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_IN; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6kXTn0v29H",
            locked: 1717788263,
            timestamp: 1717788261,
          },
        ],
        createdAt: "2024-06-07T19:24:24.405Z",
        updatedAt: "2024-06-07T19:24:24.405Z",
      },
      {
        id: "clx52r1lf00pv12vc47r0nvyp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "dcYQ4Bx3nS",
            locked: 1717788214,
            timestamp: 1717788213,
          },
        ],
        createdAt: "2024-06-07T19:23:34.996Z",
        updatedAt: "2024-06-07T19:23:34.996Z",
      },
      {
        id: "clx52r0d000pu12vcc6bmhijn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "IrSo6eBs4p",
            locked: 1717788212,
            timestamp: 1717788211,
          },
        ],
        createdAt: "2024-06-07T19:23:33.395Z",
        updatedAt: "2024-06-07T19:23:33.395Z",
      },
      {
        id: "clx52qn9h00pt12vc1fu26sgj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone14,5; iOS 16_1_2; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "l9MSBL11sO",
            locked: 1717788195,
            timestamp: 1717788194,
          },
        ],
        createdAt: "2024-06-07T19:23:16.418Z",
        updatedAt: "2024-06-07T19:23:16.418Z",
      },
      {
        id: "clx52qbtc00ps12vczh6ko3yn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "EnVRDveJKj",
            locked: 1717788181,
            timestamp: 1717788179,
          },
        ],
        createdAt: "2024-06-07T19:23:01.585Z",
        updatedAt: "2024-06-07T19:23:01.585Z",
      },
      {
        id: "clx52q5sh00pr12vciia88ohe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GXmM23Ab2X",
            locked: 1717788173,
            timestamp: 1717788171,
          },
        ],
        createdAt: "2024-06-07T19:22:53.775Z",
        updatedAt: "2024-06-07T19:22:53.775Z",
      },
      {
        id: "clx52pjr000pq12vcvxg0qpab",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "CPqSDbaWKY",
            locked: 1717788145,
            timestamp: 1717788144,
          },
        ],
        createdAt: "2024-06-07T19:22:25.209Z",
        updatedAt: "2024-06-07T19:22:25.209Z",
      },
      {
        id: "clx52oz1u00pp12vckn90c8c1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TRujeDtyw1",
            locked: 1717788117,
            timestamp: 1717788116,
          },
        ],
        createdAt: "2024-06-07T19:21:58.385Z",
        updatedAt: "2024-06-07T19:21:58.385Z",
      },
      {
        id: "clx52nz9z00po12vctzm59ngi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1ykjubm3xj",
            locked: 1717788071,
            timestamp: 1717788069,
          },
        ],
        createdAt: "2024-06-07T19:21:12.020Z",
        updatedAt: "2024-06-07T19:21:12.020Z",
      },
      {
        id: "clx52nok500pn12vcxi7znt2o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "KDKFjM6VI1",
            locked: 1717788058,
            timestamp: 1717788057,
          },
        ],
        createdAt: "2024-06-07T19:20:58.133Z",
        updatedAt: "2024-06-07T19:20:58.133Z",
      },
      {
        id: "clx52nn4e00pm12vceo811hdz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0wnUZ4DIw5",
            locked: 1717788055,
            timestamp: 1717788053,
          },
        ],
        createdAt: "2024-06-07T19:20:56.268Z",
        updatedAt: "2024-06-07T19:20:56.268Z",
      },
      {
        id: "clx52nd3d00pl12vcnapc3blg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "0oNTU3DMej",
            locked: 1717788043,
            timestamp: 1717788040,
          },
        ],
        createdAt: "2024-06-07T19:20:43.274Z",
        updatedAt: "2024-06-07T19:20:43.274Z",
      },
      {
        id: "clx52n9np00pk12vcsw54se6g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "csFSF0uOE4",
            locked: 1717788038,
            timestamp: 1717788036,
          },
        ],
        createdAt: "2024-06-07T19:20:38.822Z",
        updatedAt: "2024-06-07T19:20:38.822Z",
      },
      {
        id: "clx52n26i00pj12vcer91rz8v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 271.1.0.11.84 (iPhone12,5; iOS 15_6_1; en_US; en-US; scale=3.31; 1242x2689; 449456748)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Fec2t91LHo",
            locked: 1717788028,
            timestamp: 1717788027,
          },
        ],
        createdAt: "2024-06-07T19:20:29.128Z",
        updatedAt: "2024-06-07T19:20:29.128Z",
      },
      {
        id: "clx52mebr00pi12vchzdnjylz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NTiWZN2Yeu",
            locked: 1717787997,
            timestamp: 1717787996,
          },
        ],
        createdAt: "2024-06-07T19:19:58.216Z",
        updatedAt: "2024-06-07T19:19:58.216Z",
      },
      {
        id: "clx52mdr500ph12vcok13pn7v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VTe2MIGhHr",
            locked: 1717787996,
            timestamp: 1717787995,
          },
        ],
        createdAt: "2024-06-07T19:19:57.473Z",
        updatedAt: "2024-06-07T19:19:57.473Z",
      },
      {
        id: "clx52mbfr00pg12vcftf9x3is",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "RRD73oY55K",
            locked: 1717787994,
            timestamp: 1717787993,
          },
        ],
        createdAt: "2024-06-07T19:19:54.472Z",
        updatedAt: "2024-06-07T19:19:54.472Z",
      },
      {
        id: "clx52m65f00pf12vcceh3yxpr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "myps6oWN5T",
            locked: 1717787987,
            timestamp: 1717787986,
          },
        ],
        createdAt: "2024-06-07T19:19:47.616Z",
        updatedAt: "2024-06-07T19:19:47.616Z",
      },
      {
        id: "clx52lr7300pe12vc4v3ikq7b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9trgO4TlE7",
            locked: 1717787967,
            timestamp: 1717787965,
          },
        ],
        createdAt: "2024-06-07T19:19:28.240Z",
        updatedAt: "2024-06-07T19:19:28.240Z",
      },
      {
        id: "clx52limb00pd12vc9k0nmx6f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rt1NPXO7we",
            locked: 1717787954,
            timestamp: 1717787953,
          },
        ],
        createdAt: "2024-06-07T19:19:17.120Z",
        updatedAt: "2024-06-07T19:19:17.120Z",
      },
      {
        id: "clx52knzx00pc12vch2s1mcfb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "hO083aMt3h",
            locked: 1717787917,
            timestamp: 1717787916,
          },
        ],
        createdAt: "2024-06-07T19:18:37.437Z",
        updatedAt: "2024-06-07T19:18:37.437Z",
      },
      {
        id: "clx52kng000pb12vcc21b2itd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "aI4rXHtrCZ",
            locked: 1717787916,
            timestamp: 1717787914,
          },
        ],
        createdAt: "2024-06-07T19:18:36.720Z",
        updatedAt: "2024-06-07T19:18:36.720Z",
      },
      {
        id: "clx52knao00pa12vcl5hlk97o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QvzX6Meij5",
            locked: 1717787916,
            timestamp: 1717787914,
          },
        ],
        createdAt: "2024-06-07T19:18:36.525Z",
        updatedAt: "2024-06-07T19:18:36.525Z",
      },
      {
        id: "clx52k9y200p912vcjle8599a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "R7v8ZTAQe0",
            locked: 1717787898,
            timestamp: 1717787897,
          },
        ],
        createdAt: "2024-06-07T19:18:19.223Z",
        updatedAt: "2024-06-07T19:18:19.223Z",
      },
      {
        id: "clx52joah00p812vcm03pxv2y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wMovtBP1xN",
            locked: 1717787869,
            timestamp: 1717787868,
          },
        ],
        createdAt: "2024-06-07T19:17:51.159Z",
        updatedAt: "2024-06-07T19:17:51.159Z",
      },
      {
        id: "clx52jcja00p712vc74snidf1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "skufKIf9De",
            locked: 1717787855,
            timestamp: 1717787851,
          },
        ],
        createdAt: "2024-06-07T19:17:35.923Z",
        updatedAt: "2024-06-07T19:17:35.923Z",
      },
      {
        id: "clx52iyg800p612vczusxu2qv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_4_1; en_CZ; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uw5oB6JVWs",
            locked: 1717787837,
            timestamp: 1717787836,
          },
        ],
        createdAt: "2024-06-07T19:17:17.670Z",
        updatedAt: "2024-06-07T19:17:17.670Z",
      },
      {
        id: "clx52i21p00p512vcw4z4ltco",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; LE2127 Build/UKQ1.230924.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2200; OnePlus; LE2127; OnePlus9ProTMO; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "O7LXAO14nY",
            locked: 1717787794,
            timestamp: 1717787791,
          },
        ],
        createdAt: "2024-06-07T19:16:35.678Z",
        updatedAt: "2024-06-07T19:16:35.678Z",
      },
      {
        id: "clx52i0ia00p412vchavpfsst",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.5005.63 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "TdCH7umUZm",
            locked: 1717787791,
            timestamp: 1717787788,
          },
        ],
        createdAt: "2024-06-07T19:16:33.680Z",
        updatedAt: "2024-06-07T19:16:33.680Z",
      },
      {
        id: "clx52gssl00p312vclgaeg4i7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VsWj1fu385",
            locked: 1717787736,
            timestamp: 1717787735,
          },
        ],
        createdAt: "2024-06-07T19:15:37.027Z",
        updatedAt: "2024-06-07T19:15:37.027Z",
      },
      {
        id: "clx52c9hr00p212vcqcu5lnac",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 330.0.0.40.92 Android (34/14; 480dpi; 1080x2115; samsung; SM-S901U; r0q; qcom; en_US; 596227463)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zvQqf96utq",
            locked: 1717787523,
            timestamp: 1717787513,
          },
        ],
        createdAt: "2024-06-07T19:12:05.388Z",
        updatedAt: "2024-06-07T19:12:05.388Z",
      },
      {
        id: "clx52bq5a00p112vcy2br492z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 BytedanceWebview/d8a21c6 musical_ly_34.7.1 JsSdk/2.0 NetType/2G Channel/App Store ByteLocale/en Region/US FalconTag/AC552BBA-76EE-4002-9BF7-318EA91FABFF",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "MfyYKCtdFy",
            locked: 1717787500,
            timestamp: 1717787499,
          },
        ],
        createdAt: "2024-06-07T19:11:40.318Z",
        updatedAt: "2024-06-07T19:11:40.318Z",
      },
      {
        id: "clx52blmv00p012vcnd6a5nty",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 BytedanceWebview/d8a21c6 musical_ly_34.7.1 JsSdk/2.0 NetType/2G Channel/App Store ByteLocale/en Region/US FalconTag/AC552BBA-76EE-4002-9BF7-318EA91FABFF",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ta7Q3amuG1",
            locked: 1717787493,
            timestamp: 1717787492,
          },
        ],
        createdAt: "2024-06-07T19:11:34.469Z",
        updatedAt: "2024-06-07T19:11:34.469Z",
      },
      {
        id: "clx52at1700oz12vc7hase8ny",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-F731W Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 480dpi; 1080x2402; samsung; SM-F731W; b5q; qcom; en_CA; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2fXps5ZRbv",
            locked: 1717787456,
            timestamp: 1717787454,
          },
        ],
        createdAt: "2024-06-07T19:10:57.400Z",
        updatedAt: "2024-06-07T19:10:57.400Z",
      },
      {
        id: "clx52a38v00oy12vc49e52x46",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "VZhp2xRWNX",
            locked: 1717787423,
            timestamp: 1717787422,
          },
        ],
        createdAt: "2024-06-07T19:10:23.981Z",
        updatedAt: "2024-06-07T19:10:23.981Z",
      },
      {
        id: "clx529rhv00ox12vcgg1v3vru",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yv5pmDCB0R",
            locked: 1717787408,
            timestamp: 1717787407,
          },
        ],
        createdAt: "2024-06-07T19:10:08.753Z",
        updatedAt: "2024-06-07T19:10:08.753Z",
      },
      {
        id: "clx529gej00ow12vc1nl4ghmj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 330.0.3.29.91 (iPhone12,3; iOS 16_1_2; en_US; en; scale=3.00; 1125x2436; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "XsaUxM3t02",
            locked: 1717787394,
            timestamp: 1717787393,
          },
        ],
        createdAt: "2024-06-07T19:09:54.380Z",
        updatedAt: "2024-06-07T19:09:54.380Z",
      },
      {
        id: "clx529cq700ov12vc4fmicqkb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 330.0.3.29.91 (iPhone12,3; iOS 16_1_2; en_US; en; scale=3.00; 1125x2436; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Mo3jKudaYP",
            locked: 1717787388,
            timestamp: 1717787386,
          },
        ],
        createdAt: "2024-06-07T19:09:49.613Z",
        updatedAt: "2024-06-07T19:09:49.613Z",
      },
      {
        id: "clx528vey00ou12vc2t1d47mv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21C66 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_2_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4GRmVjkhXD",
            locked: 1717787366,
            timestamp: 1717787364,
          },
        ],
        createdAt: "2024-06-07T19:09:27.176Z",
        updatedAt: "2024-06-07T19:09:27.176Z",
      },
      {
        id: "clx527u4w00ot12vclmlva247",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "uy0evN683z",
            locked: 1717787318,
            timestamp: 1717787316,
          },
        ],
        createdAt: "2024-06-07T19:08:38.862Z",
        updatedAt: "2024-06-07T19:08:38.862Z",
      },
      {
        id: "clx526znv00os12vczmjs9sah",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone14,5; iOS 16_1_2; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5f0xLUpRBD",
            locked: 1717787278,
            timestamp: 1717787277,
          },
        ],
        createdAt: "2024-06-07T19:07:59.369Z",
        updatedAt: "2024-06-07T19:07:59.369Z",
      },
      {
        id: "clx525pz200or12vcaax8p64z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "MCe2imSgFT",
            locked: 1717787219,
            timestamp: 1717787218,
          },
        ],
        createdAt: "2024-06-07T19:07:00.158Z",
        updatedAt: "2024-06-07T19:07:00.158Z",
      },
      {
        id: "clx525li000oq12vc6n1jva4p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "EHu7mWzElL",
            locked: 1717787213,
            timestamp: 1717787212,
          },
        ],
        createdAt: "2024-06-07T19:06:54.359Z",
        updatedAt: "2024-06-07T19:06:54.359Z",
      },
      {
        id: "clx5235u200op12vcs88bf231",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Slsk4Mvlzf",
            locked: 1717787100,
            timestamp: 1717787098,
          },
        ],
        createdAt: "2024-06-07T19:05:00.743Z",
        updatedAt: "2024-06-07T19:05:00.743Z",
      },
      {
        id: "clx521nf400oo12vcc2vs6lko",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "09xRcIG9qm",
            locked: 1717787030,
            timestamp: 1717787029,
          },
        ],
        createdAt: "2024-06-07T19:03:50.224Z",
        updatedAt: "2024-06-07T19:03:50.224Z",
      },
      {
        id: "clx521mx300on12vcbpjqxgzt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gmWrTHk5b6",
            locked: 1717787029,
            timestamp: 1717787028,
          },
        ],
        createdAt: "2024-06-07T19:03:49.575Z",
        updatedAt: "2024-06-07T19:03:49.575Z",
      },
      {
        id: "clx521j6o00om12vcz5yq9ebk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Wik8sHt2a3",
            locked: 1717787024,
            timestamp: 1717787023,
          },
        ],
        createdAt: "2024-06-07T19:03:44.733Z",
        updatedAt: "2024-06-07T19:03:44.733Z",
      },
      {
        id: "clx5210h100ol12vcnb67oa5b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SeMVukHqZJ",
            locked: 1717786999,
            timestamp: 1717786997,
          },
        ],
        createdAt: "2024-06-07T19:03:20.483Z",
        updatedAt: "2024-06-07T19:03:20.483Z",
      },
      {
        id: "clx5209lj00ok12vcelz367rg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NNk6n0zv0c",
            locked: 1717786965,
            timestamp: 1717786963,
          },
        ],
        createdAt: "2024-06-07T19:02:45.652Z",
        updatedAt: "2024-06-07T19:02:45.652Z",
      },
      {
        id: "clx51ybsu00oj12vcxh2f0fm1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "k0hGKnwZIC",
            locked: 1717786756,
            timestamp: 1717786755,
          },
        ],
        createdAt: "2024-06-07T19:01:15.198Z",
        updatedAt: "2024-06-07T19:01:15.198Z",
      },
      {
        id: "clx51ybjx00oi12vc048yykzp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "esnxC7JA9I",
            locked: 1717786863,
            timestamp: 1717786854,
          },
        ],
        createdAt: "2024-06-07T19:01:14.877Z",
        updatedAt: "2024-06-07T19:01:14.877Z",
      },
      {
        id: "clx51y91r00oh12vcot2gwx4l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone15,2; iOS 17_4_1; en_US; en; scale=3.00; 960x2079; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KerRXrbVZN",
            locked: 1717786871,
            timestamp: 1717786869,
          },
        ],
        createdAt: "2024-06-07T19:01:11.628Z",
        updatedAt: "2024-06-07T19:01:11.628Z",
      },
      {
        id: "clx51xc0t00og12vcdu1hsmch",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vhSsNxKOHi",
            locked: 1717786827,
            timestamp: 1717786826,
          },
        ],
        createdAt: "2024-06-07T19:00:28.826Z",
        updatedAt: "2024-06-07T19:00:28.826Z",
      },
      {
        id: "clx51w8fc00of12vcu2fzvnmr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7TGtqxLWM1",
            locked: 1717786776,
            timestamp: 1717786775,
          },
        ],
        createdAt: "2024-06-07T18:59:37.510Z",
        updatedAt: "2024-06-07T18:59:37.510Z",
      },
      {
        id: "clx51v06y00oe12vcee6t4r34",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7Q5YiWwwyp",
            locked: 1717786722,
            timestamp: 1717786720,
          },
        ],
        createdAt: "2024-06-07T18:58:40.186Z",
        updatedAt: "2024-06-07T18:58:40.186Z",
      },
      {
        id: "clx51uz5700od12vcknz2bfgs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,1; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iOFLjO1zfm",
            locked: 1717786718,
            timestamp: 1717786716,
          },
        ],
        createdAt: "2024-06-07T18:58:38.828Z",
        updatedAt: "2024-06-07T18:58:38.828Z",
      },
      {
        id: "clx51uv5500oc12vc5yuabv9o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "b3Dr7oYCtP",
            locked: 1717786713,
            timestamp: 1717786712,
          },
        ],
        createdAt: "2024-06-07T18:58:33.639Z",
        updatedAt: "2024-06-07T18:58:33.639Z",
      },
      {
        id: "clx51uhh700ob12vcggr1oxd5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_3_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qKRa7tcHeF",
            locked: 1717786695,
            timestamp: 1717786694,
          },
        ],
        createdAt: "2024-06-07T18:58:15.928Z",
        updatedAt: "2024-06-07T18:58:15.928Z",
      },
      {
        id: "clx51u79z00oa12vc52t2vjoi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "xk61L375rH",
            locked: 1717786682,
            timestamp: 1717786681,
          },
        ],
        createdAt: "2024-06-07T18:58:02.711Z",
        updatedAt: "2024-06-07T18:58:02.711Z",
      },
      {
        id: "clx51u3yj00o912vcocxxxiwy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DZDvlmZtMF",
            locked: 1717786677,
            timestamp: 1717786675,
          },
        ],
        createdAt: "2024-06-07T18:57:58.410Z",
        updatedAt: "2024-06-07T18:57:58.410Z",
      },
      {
        id: "clx51sib000o812vcj40gn2gx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xRD7L0NtDw",
            locked: 1717786603,
            timestamp: 1717786601,
          },
        ],
        createdAt: "2024-06-07T18:56:43.690Z",
        updatedAt: "2024-06-07T18:56:43.690Z",
      },
      {
        id: "clx51qsjh00o712vc87obgcbl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 334.0.4.32.98 (iPad11,6; iPadOS 17_3_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ghyeAkivwV",
            locked: 1717786523,
            timestamp: 1717786521,
          },
        ],
        createdAt: "2024-06-07T18:55:23.646Z",
        updatedAt: "2024-06-07T18:55:23.646Z",
      },
      {
        id: "clx51qinw00o612vcuo99om2k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "OK6334moXs",
            locked: 1717786510,
            timestamp: 1717786508,
          },
        ],
        createdAt: "2024-06-07T18:55:10.842Z",
        updatedAt: "2024-06-07T18:55:10.842Z",
      },
      {
        id: "clx51qcr900o512vchou0bvcq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wQjOUhPS2b",
            locked: 1717786502,
            timestamp: 1717786501,
          },
        ],
        createdAt: "2024-06-07T18:55:03.190Z",
        updatedAt: "2024-06-07T18:55:03.190Z",
      },
      {
        id: "clx51q5c600o412vcn1gjrlfw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "D4ySDaf4vk",
            locked: 1717786493,
            timestamp: 1717786491,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "MnAcrfZlMn",
            locked: 1717786493,
            timestamp: 1717786492,
          },
        ],
        createdAt: "2024-06-07T18:54:53.573Z",
        updatedAt: "2024-06-07T18:54:53.573Z",
      },
      {
        id: "clx51ps4p00o312vczwu2nijn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 331.1.3.29.90 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "opOIIeo6oK",
            locked: 1717786475,
            timestamp: 1717786474,
          },
        ],
        createdAt: "2024-06-07T18:54:36.454Z",
        updatedAt: "2024-06-07T18:54:36.454Z",
      },
      {
        id: "clx51pf6700o212vctg21694x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "MgEua5liz4",
            locked: 1717786458,
            timestamp: 1717786456,
          },
        ],
        createdAt: "2024-06-07T18:54:19.664Z",
        updatedAt: "2024-06-07T18:54:19.664Z",
      },
      {
        id: "clx51pdne00o112vc99smvynu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dO4Fzg59xc",
            locked: 1717786457,
            timestamp: 1717786455,
          },
        ],
        createdAt: "2024-06-07T18:54:17.687Z",
        updatedAt: "2024-06-07T18:54:17.687Z",
      },
      {
        id: "clx51obxr00o012vcjx2br6xo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "vMw9ctD35W",
            locked: 1717786408,
            timestamp: 1717786407,
          },
        ],
        createdAt: "2024-06-07T18:53:28.816Z",
        updatedAt: "2024-06-07T18:53:28.816Z",
      },
      {
        id: "clx51o8br00nz12vcjniokfsp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WHFae1kKNW",
            locked: 1717786403,
            timestamp: 1717786401,
          },
        ],
        createdAt: "2024-06-07T18:53:24.133Z",
        updatedAt: "2024-06-07T18:53:24.133Z",
      },
      {
        id: "clx51nz4b00ny12vceu2czkae",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S711U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.83 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2125; samsung; SM-S711U; r11q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZjFmmNOWE0",
            locked: 1717786391,
            timestamp: 1717786389,
          },
        ],
        createdAt: "2024-06-07T18:53:12.203Z",
        updatedAt: "2024-06-07T18:53:12.203Z",
      },
      {
        id: "clx51no4100nx12vcy76hbbl4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0khtUMk0f0",
            locked: 1717786377,
            timestamp: 1717786376,
          },
        ],
        createdAt: "2024-06-07T18:52:57.934Z",
        updatedAt: "2024-06-07T18:52:57.934Z",
      },
      {
        id: "clx51m4i800nw12vcyoimb7fm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "d2JjaXBRgQ",
            locked: 1717786305,
            timestamp: 1717786304,
          },
        ],
        createdAt: "2024-06-07T18:51:45.873Z",
        updatedAt: "2024-06-07T18:51:45.873Z",
      },
      {
        id: "clx51m3yk00nv12vcc2c8d7ze",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PeC8StcGWk",
            locked: 1717786304,
            timestamp: 1717786303,
          },
        ],
        createdAt: "2024-06-07T18:51:45.161Z",
        updatedAt: "2024-06-07T18:51:45.161Z",
      },
      {
        id: "clx51lokv00nu12vcebltzugz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "c6U0y02JAm",
            locked: 1717786284,
            timestamp: 1717786283,
          },
        ],
        createdAt: "2024-06-07T18:51:25.231Z",
        updatedAt: "2024-06-07T18:51:25.231Z",
      },
      {
        id: "clx51llhm00nt12vcpjafamu2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "PhJXdyRg5J",
            locked: 1717786281,
            timestamp: 1717786280,
          },
        ],
        createdAt: "2024-06-07T18:51:21.226Z",
        updatedAt: "2024-06-07T18:51:21.226Z",
      },
      {
        id: "clx51li4d00ns12vccl6ya98a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "CRauwtIIuK",
            locked: 1717786276,
            timestamp: 1717786275,
          },
        ],
        createdAt: "2024-06-07T18:51:16.861Z",
        updatedAt: "2024-06-07T18:51:16.861Z",
      },
      {
        id: "clx51lhy500nr12vc6hbgfe4y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "xzzoVXmgnX",
            locked: 1717786276,
            timestamp: 1717786275,
          },
        ],
        createdAt: "2024-06-07T18:51:16.637Z",
        updatedAt: "2024-06-07T18:51:16.637Z",
      },
      {
        id: "clx51lgr000nq12vc29xlsdv0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "J7Sj65q9uG",
            locked: 1717786274,
            timestamp: 1717786273,
          },
        ],
        createdAt: "2024-06-07T18:51:15.083Z",
        updatedAt: "2024-06-07T18:51:15.083Z",
      },
      {
        id: "clx51ldef00np12vc02rbsr05",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jDJEnE0X16",
            locked: 1717786270,
            timestamp: 1717786265,
          },
        ],
        createdAt: "2024-06-07T18:51:10.743Z",
        updatedAt: "2024-06-07T18:51:10.743Z",
      },
      {
        id: "clx51l5wk00no12vckfmdpfwd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Pgl6YqqgZV",
            locked: 1717786260,
            timestamp: 1717786259,
          },
        ],
        createdAt: "2024-06-07T18:51:01.029Z",
        updatedAt: "2024-06-07T18:51:01.029Z",
      },
      {
        id: "clx51l2zf00nn12vcj3oclth7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "pUQTVZccXs",
            locked: 1717786256,
            timestamp: 1717786255,
          },
        ],
        createdAt: "2024-06-07T18:50:57.240Z",
        updatedAt: "2024-06-07T18:50:57.240Z",
      },
      {
        id: "clx51j1t900nm12vccyq1t6hn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rqW8I2dncM",
            locked: 1717786162,
            timestamp: 1717786159,
          },
        ],
        createdAt: "2024-06-07T18:49:22.410Z",
        updatedAt: "2024-06-07T18:49:22.410Z",
      },
      {
        id: "clx51it3h00nl12vc019j0yq9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "APM6MP1tGW",
            locked: 1717786151,
            timestamp: 1717786149,
          },
        ],
        createdAt: "2024-06-07T18:49:11.117Z",
        updatedAt: "2024-06-07T18:49:11.117Z",
      },
      {
        id: "clx51isd500nk12vc3iokxn22",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ms9CicXYMv",
            locked: 1717786149,
            timestamp: 1717786148,
          },
        ],
        createdAt: "2024-06-07T18:49:10.169Z",
        updatedAt: "2024-06-07T18:49:10.169Z",
      },
      {
        id: "clx51iilw00nj12vcnv51hzt9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fp3tq2hoaX",
            locked: 1717786137,
            timestamp: 1717786135,
          },
        ],
        createdAt: "2024-06-07T18:48:57.523Z",
        updatedAt: "2024-06-07T18:48:57.523Z",
      },
      {
        id: "clx51i47q00ni12vcjcqtv0ce",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HrXPiMAKXW",
            locked: 1717786118,
            timestamp: 1717786113,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "zP0B4XwV42",
            locked: 1717786118,
            timestamp: 1717786115,
          },
        ],
        createdAt: "2024-06-07T18:48:38.867Z",
        updatedAt: "2024-06-07T18:48:38.867Z",
      },
      {
        id: "clx51hih900nh12vc5nigwtyy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ip7VGaitAG",
            locked: 1717786090,
            timestamp: 1717786087,
          },
        ],
        createdAt: "2024-06-07T18:48:10.699Z",
        updatedAt: "2024-06-07T18:48:10.699Z",
      },
      {
        id: "clx51h0jv00ng12vc04bqeelu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N986U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (33/13; 480dpi; 1080x2105; samsung; SM-N986U; c2q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nUTtRRiDxA",
            locked: 1717786066,
            timestamp: 1717786063,
          },
        ],
        createdAt: "2024-06-07T18:47:47.464Z",
        updatedAt: "2024-06-07T18:47:47.464Z",
      },
      {
        id: "clx51g2fp00nf12vcym7qur4y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "U8F3cHunW5",
            locked: 1717786023,
            timestamp: 1717786020,
          },
        ],
        createdAt: "2024-06-07T18:47:03.254Z",
        updatedAt: "2024-06-07T18:47:03.254Z",
      },
      {
        id: "clx51fzsp00ne12vchlq9lpeo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hxyfqHSDWy",
            locked: 1717786019,
            timestamp: 1717786017,
          },
        ],
        createdAt: "2024-06-07T18:46:59.831Z",
        updatedAt: "2024-06-07T18:46:59.831Z",
      },
      {
        id: "clx51fbmh00nd12vcqfo01dcl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "kIykUUqZzV",
            locked: 1717785988,
            timestamp: 1717785987,
          },
        ],
        createdAt: "2024-06-07T18:46:28.505Z",
        updatedAt: "2024-06-07T18:46:28.505Z",
      },
      {
        id: "clx51f9q800nc12vcy2dt2d9g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "8XFuRpfnNj",
            locked: 1717785985,
            timestamp: 1717785984,
          },
        ],
        createdAt: "2024-06-07T18:46:26.046Z",
        updatedAt: "2024-06-07T18:46:26.046Z",
      },
      {
        id: "clx51evz600nb12vcjrtdf1go",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "j9KifBC9AO",
            locked: 1717785967,
            timestamp: 1717785965,
          },
        ],
        createdAt: "2024-06-07T18:46:08.226Z",
        updatedAt: "2024-06-07T18:46:08.226Z",
      },
      {
        id: "clx51evra00na12vcrst8n13d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MyYbXb64hK",
            locked: 1717785967,
            timestamp: 1717785966,
          },
        ],
        createdAt: "2024-06-07T18:46:07.943Z",
        updatedAt: "2024-06-07T18:46:07.943Z",
      },
      {
        id: "clx51em7300n912vcbsfztdop",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5CmgPMy9A1",
            locked: 1717785955,
            timestamp: 1717785953,
          },
        ],
        createdAt: "2024-06-07T18:45:55.550Z",
        updatedAt: "2024-06-07T18:45:55.550Z",
      },
      {
        id: "clx51dv2k00n812vc14l3lse1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "YiNdfqgZRq",
            locked: 1717785918,
            timestamp: 1717785917,
          },
        ],
        createdAt: "2024-06-07T18:45:20.393Z",
        updatedAt: "2024-06-07T18:45:20.393Z",
      },
      {
        id: "clx51d0vy00n712vc3xj661q5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_FR; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "0A6oAzqSyw",
            locked: 1717785881,
            timestamp: 1717785880,
          },
        ],
        createdAt: "2024-06-07T18:44:41.278Z",
        updatedAt: "2024-06-07T18:44:41.278Z",
      },
      {
        id: "clx51cxkw00n612vcvj6sfytz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "isX2GBRr1w",
            locked: 1717785875,
            timestamp: 1717785871,
          },
        ],
        createdAt: "2024-06-07T18:44:36.989Z",
        updatedAt: "2024-06-07T18:44:36.989Z",
      },
      {
        id: "clx51cn6i00n512vcmd5iq172",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_FR; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "C5RZL86Jne",
            locked: 1717785862,
            timestamp: 1717785856,
          },
        ],
        createdAt: "2024-06-07T18:44:23.514Z",
        updatedAt: "2024-06-07T18:44:23.514Z",
      },
      {
        id: "clx51cep800n412vc89o3mec3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "R2wZTOFZMJ",
            locked: 1717785852,
            timestamp: 1717785851,
          },
        ],
        createdAt: "2024-06-07T18:44:12.521Z",
        updatedAt: "2024-06-07T18:44:12.521Z",
      },
      {
        id: "clx51c2jy00n312vc3ym137tg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yE4sB7IJd3",
            locked: 1717785836,
            timestamp: 1717785835,
          },
        ],
        createdAt: "2024-06-07T18:43:56.779Z",
        updatedAt: "2024-06-07T18:43:56.779Z",
      },
      {
        id: "clx51bk0000n212vcm1yfbdlo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "8X2ncSVSf7",
            locked: 1717785812,
            timestamp: 1717785811,
          },
        ],
        createdAt: "2024-06-07T18:43:32.735Z",
        updatedAt: "2024-06-07T18:43:32.735Z",
      },
      {
        id: "clx51bect00n112vcrnbvefv6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S916U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 540dpi; 1080x2104; samsung; SM-S916U; dm2q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0pMtq6qnx9",
            locked: 1717785804,
            timestamp: 1717785802,
          },
        ],
        createdAt: "2024-06-07T18:43:25.422Z",
        updatedAt: "2024-06-07T18:43:25.422Z",
      },
      {
        id: "clx51b7mu00n012vcx2uirj57",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RG7FI7qFnT",
            locked: 1717785796,
            timestamp: 1717785795,
          },
        ],
        createdAt: "2024-06-07T18:43:16.709Z",
        updatedAt: "2024-06-07T18:43:16.709Z",
      },
      {
        id: "clx51b04800mz12vc5eike0xs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "drUaM0C7ut",
            locked: 1717785786,
            timestamp: 1717785783,
          },
        ],
        createdAt: "2024-06-07T18:43:06.968Z",
        updatedAt: "2024-06-07T18:43:06.968Z",
      },
      {
        id: "clx51auop00my12vcyqqlav87",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "fReoAYpMeB",
            locked: 1717785779,
            timestamp: 1717785777,
          },
        ],
        createdAt: "2024-06-07T18:42:59.930Z",
        updatedAt: "2024-06-07T18:42:59.930Z",
      },
      {
        id: "clx51aqh300mx12vcfqqmllj7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "L5mt2nRpyw",
            locked: 1717785773,
            timestamp: 1717785771,
          },
        ],
        createdAt: "2024-06-07T18:42:54.470Z",
        updatedAt: "2024-06-07T18:42:54.470Z",
      },
      {
        id: "clx51a5jq00mw12vclhw2q1y8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ne1QRDcHI9",
            locked: 1717785746,
            timestamp: 1717785745,
          },
        ],
        createdAt: "2024-06-07T18:42:27.350Z",
        updatedAt: "2024-06-07T18:42:27.350Z",
      },
      {
        id: "clx519zu200mv12vctaub3ty8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "p4ePsg46KQ",
            locked: 1717785739,
            timestamp: 1717785737,
          },
        ],
        createdAt: "2024-06-07T18:42:19.944Z",
        updatedAt: "2024-06-07T18:42:19.944Z",
      },
      {
        id: "clx518xgc00mu12vcncbvefnw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 960x2079; 605596711)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tHGPesgegv",
            locked: 1717785686,
            timestamp: 1717785685,
          },
        ],
        createdAt: "2024-06-07T18:41:30.205Z",
        updatedAt: "2024-06-07T18:41:30.205Z",
      },
      {
        id: "clx518o3i00mt12vcf9izz417",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "PZr37Q6N5J",
            locked: 1717785678,
            timestamp: 1717785677,
          },
        ],
        createdAt: "2024-06-07T18:41:18.077Z",
        updatedAt: "2024-06-07T18:41:18.077Z",
      },
      {
        id: "clx518mqh00ms12vckhgqtqu6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jkwNm4ptNx",
            locked: 1717785676,
            timestamp: 1717785674,
          },
        ],
        createdAt: "2024-06-07T18:41:16.313Z",
        updatedAt: "2024-06-07T18:41:16.313Z",
      },
      {
        id: "clx518bow00mr12vcdyo68cso",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_4_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9YHaw5BjUF",
            locked: 1717785661,
            timestamp: 1717785659,
          },
        ],
        createdAt: "2024-06-07T18:41:01.997Z",
        updatedAt: "2024-06-07T18:41:01.997Z",
      },
      {
        id: "clx516zwz00mq12vc0tm749ok",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "viadns8v6W",
            locked: 1717785600,
            timestamp: 1717785599,
          },
        ],
        createdAt: "2024-06-07T18:40:00.083Z",
        updatedAt: "2024-06-07T18:40:00.083Z",
      },
      {
        id: "clx516yvh00mp12vcfalvgi8k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "wEgGF6tajw",
            locked: 1717785598,
            timestamp: 1717785596,
          },
        ],
        createdAt: "2024-06-07T18:39:58.734Z",
        updatedAt: "2024-06-07T18:39:58.734Z",
      },
      {
        id: "clx516xdm00mo12vc8ep95y2s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "k2BI0zRxkq",
            locked: 1717785596,
            timestamp: 1717785595,
          },
        ],
        createdAt: "2024-06-07T18:39:56.794Z",
        updatedAt: "2024-06-07T18:39:56.794Z",
      },
      {
        id: "clx516uh300mn12vc64ybkg2m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9ogzAAvJ1D",
            locked: 1717785592,
            timestamp: 1717785591,
          },
        ],
        createdAt: "2024-06-07T18:39:53.031Z",
        updatedAt: "2024-06-07T18:39:53.031Z",
      },
      {
        id: "clx516q8100mm12vc5j48pold",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-N950U Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (28/9; 420dpi; 1080x2094; samsung; SM-N950U; greatqlte; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "R42LQ2lepT",
            locked: 1717785586,
            timestamp: 1717785585,
          },
        ],
        createdAt: "2024-06-07T18:39:47.518Z",
        updatedAt: "2024-06-07T18:39:47.518Z",
      },
      {
        id: "clx516idf00ml12vc3hg144xe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 321.0.2.24.105 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 572181444)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lGTJcQCnpa",
            locked: 1717785576,
            timestamp: 1717785575,
          },
        ],
        createdAt: "2024-06-07T18:39:37.347Z",
        updatedAt: "2024-06-07T18:39:37.347Z",
      },
      {
        id: "clx51693900mk12vcg42mtuzz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-N950U Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (28/9; 420dpi; 1080x2094; samsung; SM-N950U; greatqlte; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LEl477Ijr0",
            locked: 1717785564,
            timestamp: 1717785558,
          },
        ],
        createdAt: "2024-06-07T18:39:25.315Z",
        updatedAt: "2024-06-07T18:39:25.315Z",
      },
      {
        id: "clx515utz00mj12vcpambbres",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "4probQSe6s",
            locked: 1717785546,
            timestamp: 1717785545,
          },
        ],
        createdAt: "2024-06-07T18:39:06.839Z",
        updatedAt: "2024-06-07T18:39:06.839Z",
      },
      {
        id: "clx515tln00mi12vcp9i40ghy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KevwDpqHcx",
            locked: 1717785544,
            timestamp: 1717785543,
          },
        ],
        createdAt: "2024-06-07T18:39:05.240Z",
        updatedAt: "2024-06-07T18:39:05.240Z",
      },
      {
        id: "clx5151ip00mh12vcoi665uxy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19F77 Instagram 320.0.2.29.98 (iPhone11,8; iOS 15_5; en_LY; en; scale=2.00; 828x1792; 569998124) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "iNjSsjCumG",
            locked: 1717785508,
            timestamp: 1717785507,
          },
        ],
        createdAt: "2024-06-07T18:38:28.847Z",
        updatedAt: "2024-06-07T18:38:28.847Z",
      },
      {
        id: "clx514g3f00mg12vco83iiejg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19F77 Instagram 320.0.2.29.98 (iPhone11,8; iOS 15_5; en_LY; en; scale=2.00; 828x1792; 569998124) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JAesSstATV",
            locked: 1717785480,
            timestamp: 1717785476,
          },
        ],
        createdAt: "2024-06-07T18:38:01.080Z",
        updatedAt: "2024-06-07T18:38:01.080Z",
      },
      {
        id: "clx5140xp00mf12vc4z9lv9ow",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "TDz7nW8BIs",
            locked: 1717785461,
            timestamp: 1717785460,
          },
        ],
        createdAt: "2024-06-07T18:37:41.437Z",
        updatedAt: "2024-06-07T18:37:41.437Z",
      },
      {
        id: "clx513x2s00me12vcydp78ug9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Rm5O4Vp73W",
            locked: 1717785456,
            timestamp: 1717785454,
          },
        ],
        createdAt: "2024-06-07T18:37:36.434Z",
        updatedAt: "2024-06-07T18:37:36.434Z",
      },
      {
        id: "clx513fiq00md12vcq4tsh88b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "DXSC0HKQ39",
            locked: 1717785433,
            timestamp: 1717785432,
          },
        ],
        createdAt: "2024-06-07T18:37:13.682Z",
        updatedAt: "2024-06-07T18:37:13.682Z",
      },
      {
        id: "clx5139j300mc12vchqeilg0g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DQqjccrpTY",
            locked: 1717785425,
            timestamp: 1717785422,
          },
        ],
        createdAt: "2024-06-07T18:37:05.916Z",
        updatedAt: "2024-06-07T18:37:05.916Z",
      },
      {
        id: "clx512i2i00mb12vc75g7gjmi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Dk1l5ltsFq",
            locked: 1717785389,
            timestamp: 1717785388,
          },
        ],
        createdAt: "2024-06-07T18:36:30.328Z",
        updatedAt: "2024-06-07T18:36:30.328Z",
      },
      {
        id: "clx5114a000ma12vcg0fimnov",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MP9KjJlWVS",
            locked: 1717785325,
            timestamp: 1717785317,
          },
        ],
        createdAt: "2024-06-07T18:35:25.798Z",
        updatedAt: "2024-06-07T18:35:25.798Z",
      },
      {
        id: "clx510fyh00m912vcucb4ahcy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "gOwyWUJ2RP",
            locked: 1717785294,
            timestamp: 1717785293,
          },
        ],
        createdAt: "2024-06-07T18:34:54.280Z",
        updatedAt: "2024-06-07T18:34:54.280Z",
      },
      {
        id: "clx5108nh00m812vcfxan7erf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "WsHFsHayid",
            locked: 1717785284,
            timestamp: 1717785283,
          },
        ],
        createdAt: "2024-06-07T18:34:44.814Z",
        updatedAt: "2024-06-07T18:34:44.814Z",
      },
      {
        id: "clx5106li00m712vclvofc4hf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_4_1; en_LT; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "quWqSs2RPM",
            locked: 1717785282,
            timestamp: 1717785281,
          },
        ],
        createdAt: "2024-06-07T18:34:42.151Z",
        updatedAt: "2024-06-07T18:34:42.151Z",
      },
      {
        id: "clx5105zc00m612vcycbdiep3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_4_1; en_LT; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "o05vfrJ6Sn",
            locked: 1717785281,
            timestamp: 1717785279,
          },
        ],
        createdAt: "2024-06-07T18:34:41.353Z",
        updatedAt: "2024-06-07T18:34:41.353Z",
      },
      {
        id: "clx5104u700m512vcfhn14kji",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NARSMzlgod",
            locked: 1717785279,
            timestamp: 1717785278,
          },
        ],
        createdAt: "2024-06-07T18:34:39.871Z",
        updatedAt: "2024-06-07T18:34:39.871Z",
      },
      {
        id: "clx50zz1x00m412vcyzsqwjeu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "suOp5YR5J4",
            locked: 1717785271,
            timestamp: 1717785270,
          },
        ],
        createdAt: "2024-06-07T18:34:32.370Z",
        updatedAt: "2024-06-07T18:34:32.370Z",
      },
      {
        id: "clx50znfo00m312vc6ry5642c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "o2UrPHYyQe",
            locked: 1717785257,
            timestamp: 1717785255,
          },
        ],
        createdAt: "2024-06-07T18:34:17.315Z",
        updatedAt: "2024-06-07T18:34:17.315Z",
      },
      {
        id: "clx50zgir00m212vcmqdu18n6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; A142 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1084x2223; Nothing; A142; Pacman; mt6886; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "gNdqYv6EVm",
            locked: 1717785247,
            timestamp: 1717785241,
          },
        ],
        createdAt: "2024-06-07T18:34:08.355Z",
        updatedAt: "2024-06-07T18:34:08.355Z",
      },
      {
        id: "clx50z7fn00m112vcao82wx6i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "pFQR3qT4LD",
            locked: 1717785236,
            timestamp: 1717785235,
          },
        ],
        createdAt: "2024-06-07T18:33:56.579Z",
        updatedAt: "2024-06-07T18:33:56.579Z",
      },
      {
        id: "clx50z76b00m012vc3qr6axxk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "LECymSpg55",
            locked: 1717785236,
            timestamp: 1717785235,
          },
        ],
        createdAt: "2024-06-07T18:33:56.243Z",
        updatedAt: "2024-06-07T18:33:56.243Z",
      },
      {
        id: "clx50z6nf00lz12vc53zi3dov",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; A142 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.82 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1084x2223; Nothing; A142; Pacman; mt6886; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zm4zSus2pj",
            locked: 1717785233,
            timestamp: 1717785231,
          },
        ],
        createdAt: "2024-06-07T18:33:55.563Z",
        updatedAt: "2024-06-07T18:33:55.563Z",
      },
      {
        id: "clx50z63p00ly12vcrkzdysee",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3Pntw5WP24",
            locked: 1717785234,
            timestamp: 1717785233,
          },
        ],
        createdAt: "2024-06-07T18:33:54.852Z",
        updatedAt: "2024-06-07T18:33:54.852Z",
      },
      {
        id: "clx50yzyu00lx12vce4f7vdcy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 BytedanceWebview/d8a21c6 musical_ly_34.7.1 JsSdk/2.0 NetType/WIFI Channel/App Store ByteLocale/en Region/US FalconTag/07571084-C299-45B4-AB4E-9CE534939EA9",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "5b1lyn47iD",
            locked: 1717785226,
            timestamp: 1717785225,
          },
        ],
        createdAt: "2024-06-07T18:33:46.903Z",
        updatedAt: "2024-06-07T18:33:46.903Z",
      },
      {
        id: "clx50yxwm00lw12vcytz8b4rz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VdGduD6NDH",
            locked: 1717785223,
            timestamp: 1717785222,
          },
        ],
        createdAt: "2024-06-07T18:33:44.230Z",
        updatedAt: "2024-06-07T18:33:44.230Z",
      },
      {
        id: "clx50yusz00lv12vcyvh1v85c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 BytedanceWebview/d8a21c6 musical_ly_34.7.1 JsSdk/2.0 NetType/WIFI Channel/App Store ByteLocale/en Region/US FalconTag/07571084-C299-45B4-AB4E-9CE534939EA9",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "wGoQ6hUl6u",
            locked: 1717785219,
            timestamp: 1717785218,
          },
        ],
        createdAt: "2024-06-07T18:33:40.211Z",
        updatedAt: "2024-06-07T18:33:40.211Z",
      },
      {
        id: "clx50yrn900lu12vcth92u0xd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QtC78bAokK",
            locked: 1717785215,
            timestamp: 1717785214,
          },
        ],
        createdAt: "2024-06-07T18:33:36.114Z",
        updatedAt: "2024-06-07T18:33:36.114Z",
      },
      {
        id: "clx50wc4u00lt12vcmob1zpq4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JFmA2PBdv2",
            locked: 1717785102,
            timestamp: 1717785100,
          },
        ],
        createdAt: "2024-06-07T18:31:42.700Z",
        updatedAt: "2024-06-07T18:31:42.700Z",
      },
      {
        id: "clx50vuij00ls12vcbl2u30el",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "NkAtjAg8bJ",
            locked: 1717785079,
            timestamp: 1717785078,
          },
        ],
        createdAt: "2024-06-07T18:31:19.868Z",
        updatedAt: "2024-06-07T18:31:19.868Z",
      },
      {
        id: "clx50vroa00lr12vcqod2s4ie",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "eFDEv0oW1Q",
            locked: 1717785075,
            timestamp: 1717785074,
          },
        ],
        createdAt: "2024-06-07T18:31:16.184Z",
        updatedAt: "2024-06-07T18:31:16.184Z",
      },
      {
        id: "clx50v5ms00lq12vczvbbc4t4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "7m9G9vr4BT",
            locked: 1717785047,
            timestamp: 1717785046,
          },
        ],
        createdAt: "2024-06-07T18:30:47.619Z",
        updatedAt: "2024-06-07T18:30:47.619Z",
      },
      {
        id: "clx50v14f00lp12vciesktmnj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "wVNHhLoLRP",
            locked: 1717785041,
            timestamp: 1717785039,
          },
        ],
        createdAt: "2024-06-07T18:30:41.775Z",
        updatedAt: "2024-06-07T18:30:41.775Z",
      },
      {
        id: "clx50uqw800lo12vcy0ektyqq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YlU8IvisLZ",
            locked: 1717785027,
            timestamp: 1717785026,
          },
        ],
        createdAt: "2024-06-07T18:30:28.517Z",
        updatedAt: "2024-06-07T18:30:28.517Z",
      },
      {
        id: "clx50tfzj00ln12vcgkwmhys4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Rm5bhjglt1",
            locked: 1717784967,
            timestamp: 1717784966,
          },
        ],
        createdAt: "2024-06-07T18:29:27.727Z",
        updatedAt: "2024-06-07T18:29:27.727Z",
      },
      {
        id: "clx50tdax00lm12vcz7yewupz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Q1tCE1eVFx",
            locked: 1717784963,
            timestamp: 1717784962,
          },
        ],
        createdAt: "2024-06-07T18:29:24.247Z",
        updatedAt: "2024-06-07T18:29:24.247Z",
      },
      {
        id: "clx50s0fh00ll12vc2lfa7b7p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "lEmZulGNwh",
            locked: 1717784900,
            timestamp: 1717784899,
          },
        ],
        createdAt: "2024-06-07T18:28:20.909Z",
        updatedAt: "2024-06-07T18:28:20.909Z",
      },
      {
        id: "clx50rx8500lk12vckqc600cc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YCqDmzY61d",
            locked: 1717784896,
            timestamp: 1717784892,
          },
        ],
        createdAt: "2024-06-07T18:28:16.755Z",
        updatedAt: "2024-06-07T18:28:16.755Z",
      },
      {
        id: "clx50rbad00lj12vcfxe05mzv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ZXkqm7mVO8",
            locked: 1717784868,
            timestamp: 1717784866,
          },
        ],
        createdAt: "2024-06-07T18:27:48.323Z",
        updatedAt: "2024-06-07T18:27:48.323Z",
      },
      {
        id: "clx50r9p900li12vccrxtnc5w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "By3iYNZli7",
            locked: 1717784865,
            timestamp: 1717784864,
          },
        ],
        createdAt: "2024-06-07T18:27:46.270Z",
        updatedAt: "2024-06-07T18:27:46.270Z",
      },
      {
        id: "clx50qz9l00lh12vcx3cty114",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "icbw4I7VbB",
            locked: 1717784852,
            timestamp: 1717784851,
          },
        ],
        createdAt: "2024-06-07T18:27:32.745Z",
        updatedAt: "2024-06-07T18:27:32.745Z",
      },
      {
        id: "clx50qy9y00lg12vci0qximfv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "q0lwTRT9OO",
            locked: 1717784849,
            timestamp: 1717784846,
          },
        ],
        createdAt: "2024-06-07T18:27:31.457Z",
        updatedAt: "2024-06-07T18:27:31.457Z",
      },
      {
        id: "clx50pxjr00lf12vcle9m3cuq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "3E2pcZGdt2",
            locked: 1717784803,
            timestamp: 1717784802,
          },
        ],
        createdAt: "2024-06-07T18:26:43.864Z",
        updatedAt: "2024-06-07T18:26:43.864Z",
      },
      {
        id: "clx50pvje00le12vcs2ozpirh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ZbDmwcsQxx",
            locked: 1717784800,
            timestamp: 1717784798,
          },
        ],
        createdAt: "2024-06-07T18:26:41.255Z",
        updatedAt: "2024-06-07T18:26:41.255Z",
      },
      {
        id: "clx50ozuq00ld12vc9aj5o29d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S921U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2109; samsung; SM-S921U; e1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PtGERXa2bD",
            locked: 1717784759,
            timestamp: 1717784757,
          },
        ],
        createdAt: "2024-06-07T18:26:00.190Z",
        updatedAt: "2024-06-07T18:26:00.190Z",
      },
      {
        id: "clx50nn2h00lc12vc5uh3jfcd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "u84nyKsOKH",
            locked: 1717784696,
            timestamp: 1717784695,
          },
        ],
        createdAt: "2024-06-07T18:24:56.967Z",
        updatedAt: "2024-06-07T18:24:56.967Z",
      },
      {
        id: "clx50mn6r00lb12vcgw105j47",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "P9AXu0vqGj",
            locked: 1717784649,
            timestamp: 1717784647,
          },
        ],
        createdAt: "2024-06-07T18:24:10.465Z",
        updatedAt: "2024-06-07T18:24:10.465Z",
      },
      {
        id: "clx50md7500la12vcvv1k9k7i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "IQLsMY0DbW",
            locked: 1717784637,
            timestamp: 1717784636,
          },
        ],
        createdAt: "2024-06-07T18:23:57.521Z",
        updatedAt: "2024-06-07T18:23:57.521Z",
      },
      {
        id: "clx50mbww00l912vca18hxph5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kFkBVpXG2z",
            locked: 1717784635,
            timestamp: 1717784634,
          },
        ],
        createdAt: "2024-06-07T18:23:55.856Z",
        updatedAt: "2024-06-07T18:23:55.856Z",
      },
      {
        id: "clx50ma4p00l812vc94t7a2cm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "khQ4DDZ9Xt",
            locked: 1717784633,
            timestamp: 1717784631,
          },
        ],
        createdAt: "2024-06-07T18:23:53.545Z",
        updatedAt: "2024-06-07T18:23:53.545Z",
      },
      {
        id: "clx50m52l00l712vcotltn6eq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "X8w2oybrh1",
            locked: 1717784626,
            timestamp: 1717784625,
          },
        ],
        createdAt: "2024-06-07T18:23:46.986Z",
        updatedAt: "2024-06-07T18:23:46.986Z",
      },
      {
        id: "clx50lohj00l612vcclhmm4k2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; nb_NO; nb; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2EEQJ7Jeko",
            locked: 1717784605,
            timestamp: 1717784604,
          },
        ],
        createdAt: "2024-06-07T18:23:25.493Z",
        updatedAt: "2024-06-07T18:23:25.493Z",
      },
      {
        id: "clx50ledi00l512vc3s2h3x1y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dn4Fyolo59",
            locked: 1717784589,
            timestamp: 1717784588,
          },
        ],
        createdAt: "2024-06-07T18:23:12.391Z",
        updatedAt: "2024-06-07T18:23:12.391Z",
      },
      {
        id: "clx50lbm800l412vce26qkquh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Z2JYfKskaW",
            locked: 1717784588,
            timestamp: 1717784586,
          },
        ],
        createdAt: "2024-06-07T18:23:08.814Z",
        updatedAt: "2024-06-07T18:23:08.814Z",
      },
      {
        id: "clx50klso00l312vc1ipsbf7s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "hkVBKiyF6S",
            locked: 1717784555,
            timestamp: 1717784554,
          },
        ],
        createdAt: "2024-06-07T18:22:35.353Z",
        updatedAt: "2024-06-07T18:22:35.353Z",
      },
      {
        id: "clx50kgcs00l212vcnpa6wx0y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "qmvMMVXhyE",
            locked: 1717784548,
            timestamp: 1717784547,
          },
        ],
        createdAt: "2024-06-07T18:22:28.300Z",
        updatedAt: "2024-06-07T18:22:28.300Z",
      },
      {
        id: "clx50kfcw00l112vc7dpqrd2m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "u3YEnFYEci",
            locked: 1717784546,
            timestamp: 1717784543,
          },
        ],
        createdAt: "2024-06-07T18:22:27.008Z",
        updatedAt: "2024-06-07T18:22:27.008Z",
      },
      {
        id: "clx50ke4500l012vc8xgoc4rq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nP3mMlAubv",
            locked: 1717784545,
            timestamp: 1717784543,
          },
        ],
        createdAt: "2024-06-07T18:22:25.394Z",
        updatedAt: "2024-06-07T18:22:25.394Z",
      },
      {
        id: "clx50iowc00kz12vcbo7bdops",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qT9f9CwLjY",
            locked: 1717784465,
            timestamp: 1717784463,
          },
        ],
        createdAt: "2024-06-07T18:21:06.060Z",
        updatedAt: "2024-06-07T18:21:06.060Z",
      },
      {
        id: "clx50iku100ky12vcikyylovz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fnkyE7Zxtx",
            locked: 1717784460,
            timestamp: 1717784458,
          },
        ],
        createdAt: "2024-06-07T18:21:00.791Z",
        updatedAt: "2024-06-07T18:21:00.791Z",
      },
      {
        id: "clx50i3we00kx12vcs0px8mo1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "k1CzvwBVX1",
            locked: 1717784438,
            timestamp: 1717784428,
          },
        ],
        createdAt: "2024-06-07T18:20:38.844Z",
        updatedAt: "2024-06-07T18:20:38.844Z",
      },
      {
        id: "clx50gbj000kw12vc132y6dlr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yIAVH7m3TD",
            locked: 1717784354,
            timestamp: 1717784353,
          },
        ],
        createdAt: "2024-06-07T18:19:15.417Z",
        updatedAt: "2024-06-07T18:19:15.417Z",
      },
      {
        id: "clx50fr7g00kv12vc0s9jwhp5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VdDR9b1ZCK",
            locked: 1717784328,
            timestamp: 1717784325,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "YvGSDzV5ki",
            locked: 1717784328,
            timestamp: 1717784327,
          },
        ],
        createdAt: "2024-06-07T18:18:49.085Z",
        updatedAt: "2024-06-07T18:18:49.085Z",
      },
      {
        id: "clx50fg4n00ku12vcrshpv2ru",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S916U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2131; samsung; SM-S916U; dm2q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hFcZjSnXR6",
            locked: 1717784313,
            timestamp: 1717784312,
          },
        ],
        createdAt: "2024-06-07T18:18:34.725Z",
        updatedAt: "2024-06-07T18:18:34.725Z",
      },
      {
        id: "clx50eyq100kt12vcdzz9vqcy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "CL5d5CSFEJ",
            locked: 1717784292,
            timestamp: 1717784291,
          },
        ],
        createdAt: "2024-06-07T18:18:12.168Z",
        updatedAt: "2024-06-07T18:18:12.168Z",
      },
      {
        id: "clx50et1300ks12vc0r7z8gt7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "GDI3g7Sz37",
            locked: 1717784284,
            timestamp: 1717784256,
          },
        ],
        createdAt: "2024-06-07T18:18:04.791Z",
        updatedAt: "2024-06-07T18:18:04.791Z",
      },
      {
        id: "clx50ep2o00kr12vc9tkqetr0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "POFqaUMuNC",
            locked: 1717343273,
            timestamp: 1717343267,
          },
        ],
        createdAt: "2024-06-07T18:17:59.665Z",
        updatedAt: "2024-06-07T18:17:59.665Z",
      },
      {
        id: "clx50emvd00kq12vcgonya4d3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "96fs45QE6Q",
            locked: 1717784272,
            timestamp: 1717784270,
          },
        ],
        createdAt: "2024-06-07T18:17:56.809Z",
        updatedAt: "2024-06-07T18:17:56.809Z",
      },
      {
        id: "clx50eit100kp12vckco3g4fm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "tUyrPaMNDU",
            locked: 1717784271,
            timestamp: 1717784270,
          },
        ],
        createdAt: "2024-06-07T18:17:51.542Z",
        updatedAt: "2024-06-07T18:17:51.542Z",
      },
      {
        id: "clx50ehle00ko12vcnq27uk2t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zIV9NXRV7N",
            locked: 1717784269,
            timestamp: 1717784268,
          },
        ],
        createdAt: "2024-06-07T18:17:49.969Z",
        updatedAt: "2024-06-07T18:17:49.969Z",
      },
      {
        id: "clx50ee2i00kn12vcwd9agyk0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HP8TcLkP8f",
            locked: 1717784263,
            timestamp: 1717784244,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "uUz122oqoq",
            locked: 1717784263,
            timestamp: 1717784250,
          },
        ],
        createdAt: "2024-06-07T18:17:45.403Z",
        updatedAt: "2024-06-07T18:17:45.403Z",
      },
      {
        id: "clx50edvv00km12vc6tw04o3w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "w8ZNS7mDb0",
            locked: 1717343254,
            timestamp: 1717343246,
          },
        ],
        createdAt: "2024-06-07T18:17:45.163Z",
        updatedAt: "2024-06-07T18:17:45.163Z",
      },
      {
        id: "clx50e4r400kl12vcs2oaovkj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "grMiJb7I6l",
            locked: 1717784252,
            timestamp: 1717784250,
          },
        ],
        createdAt: "2024-06-07T18:17:33.326Z",
        updatedAt: "2024-06-07T18:17:33.326Z",
      },
      {
        id: "clx50dn4e00kk12vcyowecaw5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "1LIr1Q4tQ1",
            locked: 1717784230,
            timestamp: 1717784229,
          },
        ],
        createdAt: "2024-06-07T18:17:10.478Z",
        updatedAt: "2024-06-07T18:17:10.478Z",
      },
      {
        id: "clx50dkek00kj12vcgrtfd4vq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "r5yAuOLUr0",
            locked: 1717784226,
            timestamp: 1717784223,
          },
        ],
        createdAt: "2024-06-07T18:17:06.953Z",
        updatedAt: "2024-06-07T18:17:06.953Z",
      },
      {
        id: "clx50cv0k00ki12vcq4r3qygy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "s3uYjIXxze",
            locked: 1717784193,
            timestamp: 1717784191,
          },
        ],
        createdAt: "2024-06-07T18:16:34.049Z",
        updatedAt: "2024-06-07T18:16:34.049Z",
      },
      {
        id: "clx50c6g900kh12vctd78mip0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EUztTfBnDn",
            locked: 1717784161,
            timestamp: 1717784160,
          },
        ],
        createdAt: "2024-06-07T18:16:02.214Z",
        updatedAt: "2024-06-07T18:16:02.214Z",
      },
      {
        id: "clx50brse00kg12vcjl8eve8k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "p5RQ0S4Rae",
            locked: 1717784143,
            timestamp: 1717784142,
          },
        ],
        createdAt: "2024-06-07T18:15:43.211Z",
        updatedAt: "2024-06-07T18:15:43.211Z",
      },
      {
        id: "clx50bja300kf12vc5vjy523p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nZSfbOepfn",
            locked: 1717784131,
            timestamp: 1717784130,
          },
        ],
        createdAt: "2024-06-07T18:15:32.187Z",
        updatedAt: "2024-06-07T18:15:32.187Z",
      },
      {
        id: "clx50ba1100ke12vcl1m7g258",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.4951.67 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7x7WiNtII3",
            locked: 1717784116,
            timestamp: 1717784104,
          },
        ],
        createdAt: "2024-06-07T18:15:20.195Z",
        updatedAt: "2024-06-07T18:15:20.195Z",
      },
      {
        id: "clx50ay8z00kd12vc74z4cbc3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "fp4o6HiV6M",
            locked: 1717784104,
            timestamp: 1717784103,
          },
        ],
        createdAt: "2024-06-07T18:15:04.931Z",
        updatedAt: "2024-06-07T18:15:04.931Z",
      },
      {
        id: "clx50ax2z00kc12vctb3ipfyg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OXC85xc6Ov",
            locked: 1717784102,
            timestamp: 1717784100,
          },
        ],
        createdAt: "2024-06-07T18:15:03.418Z",
        updatedAt: "2024-06-07T18:15:03.418Z",
      },
      {
        id: "clx50aurm00kb12vcc1tbayp6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NfOc9b8NG5",
            locked: 1717784099,
            timestamp: 1717784098,
          },
        ],
        createdAt: "2024-06-07T18:15:00.418Z",
        updatedAt: "2024-06-07T18:15:00.418Z",
      },
      {
        id: "clx50aojs00ka12vcfmwtvut6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "QvniBxEGPN",
            locked: 1717784092,
            timestamp: 1717784091,
          },
        ],
        createdAt: "2024-06-07T18:14:52.360Z",
        updatedAt: "2024-06-07T18:14:52.360Z",
      },
      {
        id: "clx50al5300k912vcvnb3re62",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ImDPQkxvrf",
            locked: 1717784087,
            timestamp: 1717784086,
          },
        ],
        createdAt: "2024-06-07T18:14:47.940Z",
        updatedAt: "2024-06-07T18:14:47.940Z",
      },
      {
        id: "clx509su000k812vcc8i9b1la",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Q3iTYHZ9oR",
            locked: 1717784050,
            timestamp: 1717784048,
          },
        ],
        createdAt: "2024-06-07T18:14:11.253Z",
        updatedAt: "2024-06-07T18:14:11.253Z",
      },
      {
        id: "clx5096gr00k712vc5wxr1jla",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LpCC1EiBwi",
            locked: 1717784021,
            timestamp: 1717784020,
          },
        ],
        createdAt: "2024-06-07T18:13:42.267Z",
        updatedAt: "2024-06-07T18:13:42.267Z",
      },
      {
        id: "clx5091ec00k612vccy6wra2c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1eDfGdinZh",
            locked: 1717784015,
            timestamp: 1717784013,
          },
        ],
        createdAt: "2024-06-07T18:13:35.697Z",
        updatedAt: "2024-06-07T18:13:35.697Z",
      },
      {
        id: "clx508gt600k512vcm9mycbnj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G75 Instagram 334.0.4.32.98 (iPhone13,2; iOS 16_6; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Mfj1zK8LnQ",
            locked: 1717783988,
            timestamp: 1717783987,
          },
        ],
        createdAt: "2024-06-07T18:13:09.018Z",
        updatedAt: "2024-06-07T18:13:09.018Z",
      },
      {
        id: "clx508fic00k412vcro5h7tqb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G75 Instagram 334.0.4.32.98 (iPhone13,2; iOS 16_6; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HnRCthZhQj",
            locked: 1717783986,
            timestamp: 1717783985,
          },
        ],
        createdAt: "2024-06-07T18:13:07.332Z",
        updatedAt: "2024-06-07T18:13:07.332Z",
      },
      {
        id: "clx508emy00k312vc8hh6vb1d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "SDgSQgPZ4b",
            locked: 1717783985,
            timestamp: 1717783984,
          },
        ],
        createdAt: "2024-06-07T18:13:06.199Z",
        updatedAt: "2024-06-07T18:13:06.199Z",
      },
      {
        id: "clx5078hl00k212vcikue6fi5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qLISBgba2a",
            locked: 1717783931,
            timestamp: 1717783929,
          },
        ],
        createdAt: "2024-06-07T18:12:11.574Z",
        updatedAt: "2024-06-07T18:12:11.574Z",
      },
      {
        id: "clx506vnn00k112vcqpasdlez",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "y1unT6yDJG",
            locked: 1717783914,
            timestamp: 1717783913,
          },
        ],
        createdAt: "2024-06-07T18:11:54.947Z",
        updatedAt: "2024-06-07T18:11:54.947Z",
      },
      {
        id: "clx506tlm00k012vcumh0q669",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DhMZ04VPaM",
            locked: 1717783907,
            timestamp: 1717783894,
          },
        ],
        createdAt: "2024-06-07T18:11:52.283Z",
        updatedAt: "2024-06-07T18:11:52.283Z",
      },
      {
        id: "clx506tk400jz12vckmlkwq2l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tOny9qFGke",
            locked: 1717783911,
            timestamp: 1717783910,
          },
        ],
        createdAt: "2024-06-07T18:11:52.229Z",
        updatedAt: "2024-06-07T18:11:52.229Z",
      },
      {
        id: "clx506kum00jy12vcj3ld3lv6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "cbkgGtr5tH",
            locked: 1717783900,
            timestamp: 1717783899,
          },
        ],
        createdAt: "2024-06-07T18:11:40.940Z",
        updatedAt: "2024-06-07T18:11:40.940Z",
      },
      {
        id: "clx505q6400jx12vc0tbegpsv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "oCSCbN8tJr",
            locked: 1717783860,
            timestamp: 1717783859,
          },
        ],
        createdAt: "2024-06-07T18:11:01.180Z",
        updatedAt: "2024-06-07T18:11:01.180Z",
      },
      {
        id: "clx505gj400jw12vcpixtivba",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S711U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2125; samsung; SM-S711U; r11q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Z4XxSw2BKd",
            locked: 1717783847,
            timestamp: 1717783844,
          },
        ],
        createdAt: "2024-06-07T18:10:48.685Z",
        updatedAt: "2024-06-07T18:10:48.685Z",
      },
      {
        id: "clx504l9d00jv12vcnukpk8vs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "3z0lOJ5Xgr",
            locked: 1717783808,
            timestamp: 1717783807,
          },
        ],
        createdAt: "2024-06-07T18:10:08.162Z",
        updatedAt: "2024-06-07T18:10:08.162Z",
      },
      {
        id: "clx504kpn00ju12vcc1w0lk24",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "igs5ZJkVOU",
            locked: 1717783806,
            timestamp: 1717783805,
          },
        ],
        createdAt: "2024-06-07T18:10:07.452Z",
        updatedAt: "2024-06-07T18:10:07.452Z",
      },
      {
        id: "clx504jfl00jt12vc1uytbyh8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "OJCZ9yWSaj",
            locked: 1717783805,
            timestamp: 1717783804,
          },
        ],
        createdAt: "2024-06-07T18:10:05.793Z",
        updatedAt: "2024-06-07T18:10:05.793Z",
      },
      {
        id: "clx504i4p00js12vcjup69e2u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DjCRaRUqeL",
            locked: 1717783803,
            timestamp: 1717783802,
          },
        ],
        createdAt: "2024-06-07T18:10:04.101Z",
        updatedAt: "2024-06-07T18:10:04.101Z",
      },
      {
        id: "clx5044ol00jr12vck2t55g86",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 308.0.2.18.106 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 533874066)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "uRFKWHPwL2",
            locked: 1717783786,
            timestamp: 1717783784,
          },
        ],
        createdAt: "2024-06-07T18:09:46.674Z",
        updatedAt: "2024-06-07T18:09:46.674Z",
      },
      {
        id: "clx503fst00jq12vcb5dr05f9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "4ubcaftI8U",
            locked: 1717783754,
            timestamp: 1717783752,
          },
        ],
        createdAt: "2024-06-07T18:09:14.429Z",
        updatedAt: "2024-06-07T18:09:14.429Z",
      },
      {
        id: "clx5038tk00jp12vc7noyyman",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vnZJTqa6NI",
            locked: 1717783744,
            timestamp: 1717783741,
          },
        ],
        createdAt: "2024-06-07T18:09:05.381Z",
        updatedAt: "2024-06-07T18:09:05.381Z",
      },
      {
        id: "clx502vds00jo12vcg50s2g2y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU OS 16_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/309.1.619045077 Mobile/15E148 Safari/604.1",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wjQE2zQPGL",
            locked: 1717783727,
            timestamp: 1717783726,
          },
        ],
        createdAt: "2024-06-07T18:08:47.965Z",
        updatedAt: "2024-06-07T18:08:47.965Z",
      },
      {
        id: "clx501s1k00jn12vckv5tgm8i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kgy3xP63ij",
            locked: 1717783676,
            timestamp: 1717783674,
          },
        ],
        createdAt: "2024-06-07T18:07:56.984Z",
        updatedAt: "2024-06-07T18:07:56.984Z",
      },
      {
        id: "clx501nyo00jm12vczvyms6nf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "08DjeL6DNf",
            locked: 1717783690,
            timestamp: 1717783688,
          },
        ],
        createdAt: "2024-06-07T18:07:51.696Z",
        updatedAt: "2024-06-07T18:07:51.696Z",
      },
      {
        id: "clx501iob00jl12vcgrzd9usm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EABFfipZIS",
            locked: 1717783663,
            timestamp: 1717783658,
          },
        ],
        createdAt: "2024-06-07T18:07:44.840Z",
        updatedAt: "2024-06-07T18:07:44.840Z",
      },
      {
        id: "clx500pps00jk12vcvxtti8n1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YZBb9QNM6E",
            locked: 1717783626,
            timestamp: 1717783613,
          },
        ],
        createdAt: "2024-06-07T18:07:07.310Z",
        updatedAt: "2024-06-07T18:07:07.310Z",
      },
      {
        id: "clx4zzpb300jj12vcesnf7veo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "fPIyrnGuBJ",
            locked: 1717783579,
            timestamp: 1717783578,
          },
        ],
        createdAt: "2024-06-07T18:06:20.124Z",
        updatedAt: "2024-06-07T18:06:20.124Z",
      },
      {
        id: "clx4zz7gj00ji12vcyrhpg4ay",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "k58jdsqYe2",
            locked: 1717783556,
            timestamp: 1717783555,
          },
        ],
        createdAt: "2024-06-07T18:05:56.992Z",
        updatedAt: "2024-06-07T18:05:56.992Z",
      },
      {
        id: "clx4zytpg00jh12vcs9dwgm43",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "JNPDknShEj",
            locked: 1717783538,
            timestamp: 1717783537,
          },
        ],
        createdAt: "2024-06-07T18:05:39.173Z",
        updatedAt: "2024-06-07T18:05:39.173Z",
      },
      {
        id: "clx4zynjq00jg12vce0l91w1h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "PhhIQ21AwF",
            locked: 1717783530,
            timestamp: 1717783529,
          },
        ],
        createdAt: "2024-06-07T18:05:31.188Z",
        updatedAt: "2024-06-07T18:05:31.188Z",
      },
      {
        id: "clx4zxwy100jf12vcxsttovdm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jw6bEgYbr2",
            locked: 1717783496,
            timestamp: 1717783493,
          },
        ],
        createdAt: "2024-06-07T18:04:56.711Z",
        updatedAt: "2024-06-07T18:04:56.711Z",
      },
      {
        id: "clx4zx0zn00je12vcc4yii6ux",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "u90yRh85u5",
            locked: 1717783455,
            timestamp: 1717783454,
          },
        ],
        createdAt: "2024-06-07T18:04:15.299Z",
        updatedAt: "2024-06-07T18:04:15.299Z",
      },
      {
        id: "clx4zx0dv00jd12vcnjlzde2t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 [FBAN/FBIOS;FBAV/466.0.0.36.107;FBBV/604687626;FBDV/iPhone14,2;FBMD/iPhone;FBSN/iOS;FBSV/17.4.1;FBSS/3;FBID/phone;FBLC/zh_CN;FBOP/5;FBRV/607278421]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PKsuPY9ERc",
            locked: 1717783454,
            timestamp: 1717783450,
          },
        ],
        createdAt: "2024-06-07T18:04:14.515Z",
        updatedAt: "2024-06-07T18:04:14.515Z",
      },
      {
        id: "clx4zwyk500jc12vctn0n66zx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "THQlWAdfVt",
            locked: 1717783451,
            timestamp: 1717783448,
          },
        ],
        createdAt: "2024-06-07T18:04:12.146Z",
        updatedAt: "2024-06-07T18:04:12.146Z",
      },
      {
        id: "clx4zwlwx00jb12vcsldoudou",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GYf2EAtt8O",
            locked: 1717783435,
            timestamp: 1717783433,
          },
        ],
        createdAt: "2024-06-07T18:03:55.760Z",
        updatedAt: "2024-06-07T18:03:55.760Z",
      },
      {
        id: "clx4zvhex00ja12vcj6yjw98v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E219 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_4; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "V0wReSH3lz",
            locked: 1717783382,
            timestamp: 1717783381,
          },
        ],
        createdAt: "2024-06-07T18:03:03.271Z",
        updatedAt: "2024-06-07T18:03:03.271Z",
      },
      {
        id: "clx4zv3p900j912vc5uyev1cm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "wIQRelW5W2",
            locked: 1717783364,
            timestamp: 1717783363,
          },
        ],
        createdAt: "2024-06-07T18:02:45.498Z",
        updatedAt: "2024-06-07T18:02:45.498Z",
      },
      {
        id: "clx4zusw400j812vcbt91kb1b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "HYA3wkw8gE",
            locked: 1717783351,
            timestamp: 1717783350,
          },
        ],
        createdAt: "2024-06-07T18:02:31.492Z",
        updatedAt: "2024-06-07T18:02:31.492Z",
      },
      {
        id: "clx4zurzk00j712vc0bsatfxl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "niKX6Qjdun",
            locked: 1717783349,
            timestamp: 1717783347,
          },
        ],
        createdAt: "2024-06-07T18:02:30.317Z",
        updatedAt: "2024-06-07T18:02:30.317Z",
      },
      {
        id: "clx4zu3y100j612vcleqdtbbp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dxNc5cpxXM",
            locked: 1717783318,
            timestamp: 1717783316,
          },
        ],
        createdAt: "2024-06-07T18:01:59.161Z",
        updatedAt: "2024-06-07T18:01:59.161Z",
      },
      {
        id: "clx4zu2vb00j512vcaj2yoivs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "D8yhnh2j02",
            locked: 1717783317,
            timestamp: 1717783316,
          },
        ],
        createdAt: "2024-06-07T18:01:57.768Z",
        updatedAt: "2024-06-07T18:01:57.768Z",
      },
      {
        id: "clx4zu2nz00j412vc8z8phtc0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_ZA; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "a1cOjJFHcD",
            locked: 1717783316,
            timestamp: 1717783315,
          },
        ],
        createdAt: "2024-06-07T18:01:57.500Z",
        updatedAt: "2024-06-07T18:01:57.500Z",
      },
      {
        id: "clx4zsic000j312vc9dh9fqv8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,1; iOS 16_7_8; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vqxHWTO6Cf",
            locked: 1717783243,
            timestamp: 1717783241,
          },
        ],
        createdAt: "2024-06-07T18:00:44.496Z",
        updatedAt: "2024-06-07T18:00:44.496Z",
      },
      {
        id: "clx4zsg2u00j212vcawbbvjbh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dtBLI1gBeD",
            locked: 1717783241,
            timestamp: 1717783239,
          },
        ],
        createdAt: "2024-06-07T18:00:41.571Z",
        updatedAt: "2024-06-07T18:00:41.571Z",
      },
      {
        id: "clx4zrvcc00j112vc7mszzs7i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AN85xS7Nvr",
            locked: 1717783146,
            timestamp: 1717783145,
          },
        ],
        createdAt: "2024-06-07T18:00:14.699Z",
        updatedAt: "2024-06-07T18:00:14.699Z",
      },
      {
        id: "clx4zrny300j012vccd9mf7xb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-G960U1 Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 480dpi; 1080x2076; samsung; SM-G960U1; starqlteue; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wmDNW3bdmc",
            locked: 1717783204,
            timestamp: 1717783201,
          },
        ],
        createdAt: "2024-06-07T18:00:05.116Z",
        updatedAt: "2024-06-07T18:00:05.116Z",
      },
      {
        id: "clx4zrgz900iz12vcfafqceni",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "mZkcNVVgYe",
            locked: 1717783195,
            timestamp: 1717783194,
          },
        ],
        createdAt: "2024-06-07T17:59:56.085Z",
        updatedAt: "2024-06-07T17:59:56.085Z",
      },
      {
        id: "clx4zrglw00iy12vc7tqj2npb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "UC1iD9zFIE",
            locked: 1717783194,
            timestamp: 1717783192,
          },
        ],
        createdAt: "2024-06-07T17:59:55.602Z",
        updatedAt: "2024-06-07T17:59:55.602Z",
      },
      {
        id: "clx4zqg3400ix12vc08lgpnuk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "D5lBbgUGXz",
            locked: 1717783148,
            timestamp: 1717783146,
          },
        ],
        createdAt: "2024-06-07T17:59:08.272Z",
        updatedAt: "2024-06-07T17:59:08.272Z",
      },
      {
        id: "clx4zqcbx00iw12vc4j74ypzv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "FO0iRnNYmE",
            locked: 1717783143,
            timestamp: 1717783142,
          },
        ],
        createdAt: "2024-06-07T17:59:03.405Z",
        updatedAt: "2024-06-07T17:59:03.405Z",
      },
      {
        id: "clx4zqbuh00iv12vcv9x2huum",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Nh2cfEUUxp",
            locked: 1717783142,
            timestamp: 1717783140,
          },
        ],
        createdAt: "2024-06-07T17:59:02.778Z",
        updatedAt: "2024-06-07T17:59:02.778Z",
      },
      {
        id: "clx4zqa1100iu12vcaku8jp5j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N981U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (33/13; 450dpi; 1080x2173; samsung; SM-N981U; c1q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "MDDukqTGB4",
            locked: 1717783140,
            timestamp: 1717783139,
          },
        ],
        createdAt: "2024-06-07T17:59:00.421Z",
        updatedAt: "2024-06-07T17:59:00.421Z",
      },
      {
        id: "clx4zq9y800it12vc84lo0eo8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jkj7Wrxo2G",
            locked: 1717783140,
            timestamp: 1717783137,
          },
        ],
        createdAt: "2024-06-07T17:59:00.318Z",
        updatedAt: "2024-06-07T17:59:00.318Z",
      },
      {
        id: "clx4zpvuf00is12vcll8ro9d0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-N981U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (33/13; 450dpi; 1080x2173; samsung; SM-N981U; c1q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Z0Jw2wpR1I",
            locked: 1717783121,
            timestamp: 1717783118,
          },
        ],
        createdAt: "2024-06-07T17:58:42.036Z",
        updatedAt: "2024-06-07T17:58:42.036Z",
      },
      {
        id: "clx4zo67k00ir12vcvv9pzyc0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "I3MHxzAjE8",
            locked: 1717783041,
            timestamp: 1717783040,
          },
        ],
        createdAt: "2024-06-07T17:57:22.160Z",
        updatedAt: "2024-06-07T17:57:22.160Z",
      },
      {
        id: "clx4zo0x700iq12vcxiv76vac",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "cGaAyimQcg",
            locked: 1717783034,
            timestamp: 1717783033,
          },
        ],
        createdAt: "2024-06-07T17:57:15.307Z",
        updatedAt: "2024-06-07T17:57:15.307Z",
      },
      {
        id: "clx4zo0d300ip12vcffex64ks",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DMqF0A3LyS",
            locked: 1717783034,
            timestamp: 1717783032,
          },
        ],
        createdAt: "2024-06-07T17:57:14.580Z",
        updatedAt: "2024-06-07T17:57:14.580Z",
      },
      {
        id: "clx4znmqu00io12vczcdrqgsq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "oAk7kX1V8S",
            locked: 1717783016,
            timestamp: 1717783015,
          },
        ],
        createdAt: "2024-06-07T17:56:56.935Z",
        updatedAt: "2024-06-07T17:56:56.935Z",
      },
      {
        id: "clx4znhsf00in12vcwhhm6eau",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zf3eiLQInq",
            locked: 1717783004,
            timestamp: 1717783001,
          },
        ],
        createdAt: "2024-06-07T17:56:50.511Z",
        updatedAt: "2024-06-07T17:56:50.511Z",
      },
      {
        id: "clx4zng2900im12vccu0e9hov",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "sJRm1m7Afl",
            locked: 1717783007,
            timestamp: 1717783005,
          },
        ],
        createdAt: "2024-06-07T17:56:48.270Z",
        updatedAt: "2024-06-07T17:56:48.270Z",
      },
      {
        id: "clx4zmvc500il12vcr3fffm6s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fLrQJw0hnt",
            locked: 1717782980,
            timestamp: 1717782979,
          },
        ],
        createdAt: "2024-06-07T17:56:21.410Z",
        updatedAt: "2024-06-07T17:56:21.410Z",
      },
      {
        id: "clx4zmjv300ik12vcymyqumpz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "wsX7PQnp6U",
            locked: 1717782966,
            timestamp: 1717782965,
          },
        ],
        createdAt: "2024-06-07T17:56:06.544Z",
        updatedAt: "2024-06-07T17:56:06.544Z",
      },
      {
        id: "clx4zmje000ij12vc28ycnxxx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "19K1w2MmLK",
            locked: 1717782965,
            timestamp: 1717782962,
          },
        ],
        createdAt: "2024-06-07T17:56:05.926Z",
        updatedAt: "2024-06-07T17:56:05.926Z",
      },
      {
        id: "clx4zlzhp00ii12vcf1779vs3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "u5SZcg1d9U",
            locked: 1717782940,
            timestamp: 1717782938,
          },
        ],
        createdAt: "2024-06-07T17:55:40.141Z",
        updatedAt: "2024-06-07T17:55:40.141Z",
      },
      {
        id: "clx4zlye500ih12vcqdcy6ba9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_AE; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Xz9zEhMamk",
            locked: 1717782937,
            timestamp: 1717782936,
          },
        ],
        createdAt: "2024-06-07T17:55:38.718Z",
        updatedAt: "2024-06-07T17:55:38.718Z",
      },
      {
        id: "clx4zlxkm00ig12vctc8zw82y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "U0tnhsVhr0",
            locked: 1717782936,
            timestamp: 1717782933,
          },
        ],
        createdAt: "2024-06-07T17:55:37.654Z",
        updatedAt: "2024-06-07T17:55:37.654Z",
      },
      {
        id: "clx4zlw4q00if12vcjlehoqid",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "MA2Cz42u1R",
            locked: 1717782934,
            timestamp: 1717782933,
          },
        ],
        createdAt: "2024-06-07T17:55:35.785Z",
        updatedAt: "2024-06-07T17:55:35.785Z",
      },
      {
        id: "clx4zlqce00ie12vc97b9wz6y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OtTAycI1e8",
            locked: 1717782926,
            timestamp: 1717782925,
          },
        ],
        createdAt: "2024-06-07T17:55:28.286Z",
        updatedAt: "2024-06-07T17:55:28.286Z",
      },
      {
        id: "clx4zlgd600id12vc58fg9w77",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kZsAX5RrnZ",
            locked: 1717782913,
            timestamp: 1717782911,
          },
        ],
        createdAt: "2024-06-07T17:55:15.351Z",
        updatedAt: "2024-06-07T17:55:15.351Z",
      },
      {
        id: "clx4zkfcq00ic12vc8y1jcox1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,5; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gRTNXjuh97",
            locked: 1717782866,
            timestamp: 1717782864,
          },
        ],
        createdAt: "2024-06-07T17:54:27.383Z",
        updatedAt: "2024-06-07T17:54:27.383Z",
      },
      {
        id: "clx4zjm9i00ib12vc03qg9yh7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "RJ1hQzzFQv",
            locked: 1717782829,
            timestamp: 1717782828,
          },
        ],
        createdAt: "2024-06-07T17:53:49.686Z",
        updatedAt: "2024-06-07T17:53:49.686Z",
      },
      {
        id: "clx4zjh1u00ia12vcsrzbliy4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KCrntJ6UUm",
            locked: 1717782822,
            timestamp: 1717782821,
          },
        ],
        createdAt: "2024-06-07T17:53:42.931Z",
        updatedAt: "2024-06-07T17:53:42.931Z",
      },
      {
        id: "clx4zjfj800i912vc4teys7jx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ImrQCcxKyj",
            locked: 1717782820,
            timestamp: 1717782819,
          },
        ],
        createdAt: "2024-06-07T17:53:40.963Z",
        updatedAt: "2024-06-07T17:53:40.963Z",
      },
      {
        id: "clx4zj8w600i812vc0hpr3znh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; nl_NL; nl; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "X9n0q2ZAck",
            locked: 1717782812,
            timestamp: 1717782809,
          },
        ],
        createdAt: "2024-06-07T17:53:32.358Z",
        updatedAt: "2024-06-07T17:53:32.358Z",
      },
      {
        id: "clx4zj3ox00i712vc6gs01pdn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0LVVPQcxuN",
            locked: 1717782805,
            timestamp: 1717782803,
          },
        ],
        createdAt: "2024-06-07T17:53:25.616Z",
        updatedAt: "2024-06-07T17:53:25.616Z",
      },
      {
        id: "clx4zj0pa00i612vc1s44tnrh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; nl_NL; nl; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cqOSuD5X8l",
            locked: 1717782801,
            timestamp: 1717782800,
          },
        ],
        createdAt: "2024-06-07T17:53:21.743Z",
        updatedAt: "2024-06-07T17:53:21.743Z",
      },
      {
        id: "clx4ziqnt00i512vcnm97qi50",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7D69DYb3ro",
            locked: 1717782787,
            timestamp: 1717782786,
          },
        ],
        createdAt: "2024-06-07T17:53:08.726Z",
        updatedAt: "2024-06-07T17:53:08.726Z",
      },
      {
        id: "clx4zi23i00i412vcrduu6pue",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HrcD0o5SrF",
            locked: 1717782756,
            timestamp: 1717782754,
          },
        ],
        createdAt: "2024-06-07T17:52:36.892Z",
        updatedAt: "2024-06-07T17:52:36.892Z",
      },
      {
        id: "clx4zhhf400i312vcbc9lx5kv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "eHAly6qsEr",
            locked: 1717782729,
            timestamp: 1717782728,
          },
        ],
        createdAt: "2024-06-07T17:52:10.095Z",
        updatedAt: "2024-06-07T17:52:10.095Z",
      },
      {
        id: "clx4zheph00i212vcq7shb3l8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NEwcN53uX6",
            locked: 1717782725,
            timestamp: 1717782724,
          },
        ],
        createdAt: "2024-06-07T17:52:06.582Z",
        updatedAt: "2024-06-07T17:52:06.582Z",
      },
      {
        id: "clx4zh5j300i112vcht3eah5t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "eG6hiyCfIy",
            locked: 1717782714,
            timestamp: 1717782711,
          },
        ],
        createdAt: "2024-06-07T17:51:54.687Z",
        updatedAt: "2024-06-07T17:51:54.687Z",
      },
      {
        id: "clx4zgs2z00i012vcveuov0m6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_IT; en; scale=3.00; 1179x2556; 605596711) NW/6",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JZsb6Bxvz0",
            locked: 1717782697,
            timestamp: 1717782694,
          },
        ],
        createdAt: "2024-06-07T17:51:37.260Z",
        updatedAt: "2024-06-07T17:51:37.260Z",
      },
      {
        id: "clx4zgnom00hz12vcje001z4f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "a1e1dOLmOJ",
            locked: 1717782690,
            timestamp: 1717782688,
          },
        ],
        createdAt: "2024-06-07T17:51:31.556Z",
        updatedAt: "2024-06-07T17:51:31.556Z",
      },
      {
        id: "clx4zg6n300hy12vcbxa6r9cx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; CPH2417 Build/UKQ1.230924.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2173; OnePlus; CPH2417; OP5552L1; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "qTuTmtYoDC",
            locked: 1717782669,
            timestamp: 1717782668,
          },
        ],
        createdAt: "2024-06-07T17:51:09.469Z",
        updatedAt: "2024-06-07T17:51:09.469Z",
      },
      {
        id: "clx4zfvv400hx12vcqi5isp76",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; CPH2417 Build/UKQ1.230924.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2173; OnePlus; CPH2417; OP5552L1; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gjXJ9OZbei",
            locked: 1717782655,
            timestamp: 1717782652,
          },
        ],
        createdAt: "2024-06-07T17:50:55.504Z",
        updatedAt: "2024-06-07T17:50:55.504Z",
      },
      {
        id: "clx4zfs5f00hw12vcgcawh3p5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8oEI3dcPnz",
            locked: 1717782650,
            timestamp: 1717782648,
          },
        ],
        createdAt: "2024-06-07T17:50:50.689Z",
        updatedAt: "2024-06-07T17:50:50.689Z",
      },
      {
        id: "clx4ze9yj00hv12vc6t4friaz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "kg92POXglk",
            locked: 1717782578,
            timestamp: 1717782575,
          },
        ],
        createdAt: "2024-06-07T17:49:40.456Z",
        updatedAt: "2024-06-07T17:49:40.456Z",
      },
      {
        id: "clx4zdv1l00hu12vcsma8jvcx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LkwIZrKDTJ",
            locked: 1717782558,
            timestamp: 1717782556,
          },
        ],
        createdAt: "2024-06-07T17:49:21.126Z",
        updatedAt: "2024-06-07T17:49:21.126Z",
      },
      {
        id: "clx4zcorz00ht12vcduyfx0at",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "h9kSXKqkwf",
            locked: 1717782506,
            timestamp: 1717782505,
          },
        ],
        createdAt: "2024-06-07T17:48:26.351Z",
        updatedAt: "2024-06-07T17:48:26.351Z",
      },
      {
        id: "clx4zchvv00hs12vc1v3uvq1t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "j73nvucOi7",
            locked: 1717782496,
            timestamp: 1717782494,
          },
        ],
        createdAt: "2024-06-07T17:48:17.417Z",
        updatedAt: "2024-06-07T17:48:17.417Z",
      },
      {
        id: "clx4zc0k200hr12vcc9l7p1d6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G998U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 280dpi; 720x1466; samsung; SM-G998U; p3q; qcom; en_US; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ZBThQJL5qL",
            locked: 1717782472,
            timestamp: 1717782469,
          },
        ],
        createdAt: "2024-06-07T17:47:54.962Z",
        updatedAt: "2024-06-07T17:47:54.962Z",
      },
      {
        id: "clx4zbvb500hq12vc4y9ammqx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; es_US; es; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZTs9JRwAg7",
            locked: 1717782467,
            timestamp: 1717782465,
          },
        ],
        createdAt: "2024-06-07T17:47:48.155Z",
        updatedAt: "2024-06-07T17:47:48.155Z",
      },
      {
        id: "clx4zbmm700hp12vca3nh4wzw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 297.0.0.13.114 (iPhone9,2; iOS 15_8_2; en_US; en; scale=3.00; 1242x2208; 506769230)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "U2xj1dGcJK",
            locked: 1717782452,
            timestamp: 1717782448,
          },
        ],
        createdAt: "2024-06-07T17:47:36.895Z",
        updatedAt: "2024-06-07T17:47:36.895Z",
      },
      {
        id: "clx4zbibc00ho12vck3dibbpw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G991U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 330.0.0.40.92 Android (34/14; 480dpi; 1080x2176; samsung; SM-G991U; o1q; qcom; es_US; 596227192)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "KKmNC0L95L",
            locked: 1717782451,
            timestamp: 1717782450,
          },
        ],
        createdAt: "2024-06-07T17:47:31.317Z",
        updatedAt: "2024-06-07T17:47:31.317Z",
      },
      {
        id: "clx4zb0do00hn12vclh4a6mo6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1MvYcVUCE5",
            locked: 1717782426,
            timestamp: 1717782420,
          },
        ],
        createdAt: "2024-06-07T17:47:08.076Z",
        updatedAt: "2024-06-07T17:47:08.076Z",
      },
      {
        id: "clx4zapfq00hm12vcomirp3aq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G991U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 330.0.0.40.92 Android (34/14; 480dpi; 1080x2176; samsung; SM-G991U; o1q; qcom; es_US; 596227192)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "UvRnQZe1h5",
            locked: 1717782413,
            timestamp: 1717782409,
          },
        ],
        createdAt: "2024-06-07T17:46:53.892Z",
        updatedAt: "2024-06-07T17:46:53.892Z",
      },
      {
        id: "clx4zacti00hl12vcza0oth5x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 332.0.2.29.94 (iPhone14,5; iOS 15_6_1; en_US; en; scale=3.00; 1170x2532; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tg5eL9r0P6",
            locked: 1717782396,
            timestamp: 1717782395,
          },
        ],
        createdAt: "2024-06-07T17:46:37.543Z",
        updatedAt: "2024-06-07T17:46:37.543Z",
      },
      {
        id: "clx4zaboj00hk12vc0bsunlxh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zCrRg0AcST",
            locked: 1717782395,
            timestamp: 1717782389,
          },
        ],
        createdAt: "2024-06-07T17:46:36.065Z",
        updatedAt: "2024-06-07T17:46:36.065Z",
      },
      {
        id: "clx4z9ls600hj12vc3yg56awz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S901U; r0q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rmDLF1kCEX",
            locked: 1717782362,
            timestamp: 1717782357,
          },
        ],
        createdAt: "2024-06-07T17:46:02.503Z",
        updatedAt: "2024-06-07T17:46:02.503Z",
      },
      {
        id: "clx4z9l5400hi12vc29cjb527",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.6422.142 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "1QpthLMeHn",
            locked: 1717782361,
            timestamp: 1717782360,
          },
        ],
        createdAt: "2024-06-07T17:46:01.672Z",
        updatedAt: "2024-06-07T17:46:01.672Z",
      },
      {
        id: "clx4z9kok00hh12vcnoo6mnk8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.6422.142 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Pk0XqoSDZb",
            locked: 1717782360,
            timestamp: 1717782359,
          },
        ],
        createdAt: "2024-06-07T17:46:01.073Z",
        updatedAt: "2024-06-07T17:46:01.073Z",
      },
      {
        id: "clx4z7uyr00hg12vchpnh1e2e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "EgUvh4HHMu",
            locked: 1717782281,
            timestamp: 1717782280,
          },
        ],
        createdAt: "2024-06-07T17:44:41.089Z",
        updatedAt: "2024-06-07T17:44:41.089Z",
      },
      {
        id: "clx4z6ttn00hf12vc3liig7y4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fTRGC0rMOh",
            locked: 1717782232,
            timestamp: 1717782231,
          },
        ],
        createdAt: "2024-06-07T17:43:52.953Z",
        updatedAt: "2024-06-07T17:43:52.953Z",
      },
      {
        id: "clx4z5wn000he12vcnyassssd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G970U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.53 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 480dpi; 1080x2020; samsung; SM-G970U; beyond0q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KH1cZtFXei",
            locked: 1717782188,
            timestamp: 1717782185,
          },
        ],
        createdAt: "2024-06-07T17:43:09.949Z",
        updatedAt: "2024-06-07T17:43:09.949Z",
      },
      {
        id: "clx4z5tob00hd12vczhyeqgzh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad13,4; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "L5anVABUNF",
            locked: 1717782185,
            timestamp: 1717782184,
          },
        ],
        createdAt: "2024-06-07T17:43:06.105Z",
        updatedAt: "2024-06-07T17:43:06.105Z",
      },
      {
        id: "clx4z5ecq00hc12vc0245wwoq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G996B Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 450dpi; 1080x2190; samsung; SM-G996B; t2s; exynos2100; en_GB; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "TJAtKQYT4g",
            locked: 1717782167,
            timestamp: 1717782166,
          },
        ],
        createdAt: "2024-06-07T17:42:46.250Z",
        updatedAt: "2024-06-07T17:42:46.250Z",
      },
      {
        id: "clx4z5due00hb12vcuqm7gomw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad13,4; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "B4Oh5v90Wl",
            locked: 1717782163,
            timestamp: 1717782162,
          },
        ],
        createdAt: "2024-06-07T17:42:45.591Z",
        updatedAt: "2024-06-07T17:42:45.591Z",
      },
      {
        id: "clx4z5dq500ha12vcb0qk35br",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "quF226zWim",
            locked: 1717782163,
            timestamp: 1717782162,
          },
        ],
        createdAt: "2024-06-07T17:42:45.438Z",
        updatedAt: "2024-06-07T17:42:45.438Z",
      },
      {
        id: "clx4z5db100h912vc3n5brvsn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "oo8EnyAxUV",
            locked: 1717782164,
            timestamp: 1717782163,
          },
        ],
        createdAt: "2024-06-07T17:42:44.891Z",
        updatedAt: "2024-06-07T17:42:44.891Z",
      },
      {
        id: "clx4z4t4m00h812vczhsgo2ji",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; moto e13) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.6367.179 Mobile Safari/537.36 OPR/82.5.4342.79868",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "njQ0MovhV0",
            locked: 1717782138,
            timestamp: 1717782136,
          },
        ],
        createdAt: "2024-06-07T17:42:18.741Z",
        updatedAt: "2024-06-07T17:42:18.741Z",
      },
      {
        id: "clx4z4ril00h712vcji4fxq6g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ksVd8AWSO4",
            locked: 1717782136,
            timestamp: 1717782135,
          },
        ],
        createdAt: "2024-06-07T17:42:16.653Z",
        updatedAt: "2024-06-07T17:42:16.653Z",
      },
      {
        id: "clx4z4qir00h612vc5s1femkj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,4; iOS 16_7_8; en_US; en; scale=2.00; 640x1136; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5GouGuaMhC",
            locked: 1717782134,
            timestamp: 1717782132,
          },
        ],
        createdAt: "2024-06-07T17:42:15.363Z",
        updatedAt: "2024-06-07T17:42:15.363Z",
      },
      {
        id: "clx4z4pds00h512vcrrfhssm3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "DolUxxNhf5",
            locked: 1717782133,
            timestamp: 1717782130,
          },
        ],
        createdAt: "2024-06-07T17:42:13.889Z",
        updatedAt: "2024-06-07T17:42:13.889Z",
      },
      {
        id: "clx4z4gir00h412vced2xhh21",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G996B Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 450dpi; 1080x2190; samsung; SM-G996B; t2s; exynos2100; en_GB; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pLW1TtCjx4",
            locked: 1717782123,
            timestamp: 1717782116,
          },
        ],
        createdAt: "2024-06-07T17:42:02.400Z",
        updatedAt: "2024-06-07T17:42:02.400Z",
      },
      {
        id: "clx4z3yol00h312vcfja5llqp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XIjEypLNwm",
            locked: 1717782099,
            timestamp: 1717782098,
          },
        ],
        createdAt: "2024-06-07T17:41:39.285Z",
        updatedAt: "2024-06-07T17:41:39.285Z",
      },
      {
        id: "clx4z3srw00h212vcouf583tq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; es_AR; es; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "xHOg9HQlPU",
            locked: 1717782091,
            timestamp: 1717782090,
          },
        ],
        createdAt: "2024-06-07T17:41:31.626Z",
        updatedAt: "2024-06-07T17:41:31.626Z",
      },
      {
        id: "clx4z3ebx00h112vcx8cg30ts",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; es_AR; es; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KXNfnkx4YS",
            locked: 1717782072,
            timestamp: 1717782071,
          },
        ],
        createdAt: "2024-06-07T17:41:12.907Z",
        updatedAt: "2024-06-07T17:41:12.907Z",
      },
      {
        id: "clx4z35zi00h012vcp7ws15c8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "pdPhWEqm2v",
            locked: 1717782061,
            timestamp: 1717782060,
          },
        ],
        createdAt: "2024-06-07T17:41:02.095Z",
        updatedAt: "2024-06-07T17:41:02.095Z",
      },
      {
        id: "clx4z2ug800gz12vc8um4pywo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yirIT2Xt13",
            locked: 1717782046,
            timestamp: 1717782045,
          },
        ],
        createdAt: "2024-06-07T17:40:47.141Z",
        updatedAt: "2024-06-07T17:40:47.141Z",
      },
      {
        id: "clx4z2j8p00gy12vcx0bjphnu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FxbvaPlB6l",
            locked: 1717782032,
            timestamp: 1717782031,
          },
        ],
        createdAt: "2024-06-07T17:40:32.618Z",
        updatedAt: "2024-06-07T17:40:32.618Z",
      },
      {
        id: "clx4z2j3i00gx12vcxf7e3xu1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "mesP2fKfRM",
            locked: 1717782031,
            timestamp: 1717782030,
          },
        ],
        createdAt: "2024-06-07T17:40:32.430Z",
        updatedAt: "2024-06-07T17:40:32.430Z",
      },
      {
        id: "clx4z2hz300gw12vckb2bl3og",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "x8W30jx1KT",
            locked: 1717782030,
            timestamp: 1717782029,
          },
        ],
        createdAt: "2024-06-07T17:40:30.975Z",
        updatedAt: "2024-06-07T17:40:30.975Z",
      },
      {
        id: "clx4z2eb600gv12vc0d2mdgta",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Tybqbho2Cl",
            locked: 1717782026,
            timestamp: 1717782025,
          },
        ],
        createdAt: "2024-06-07T17:40:26.226Z",
        updatedAt: "2024-06-07T17:40:26.226Z",
      },
      {
        id: "clx4z2e4u00gu12vc175e97da",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "SGNlVviyjb",
            locked: 1717782024,
            timestamp: 1717782022,
          },
        ],
        createdAt: "2024-06-07T17:40:25.998Z",
        updatedAt: "2024-06-07T17:40:25.998Z",
      },
      {
        id: "clx4z2cl300gt12vcb7kgjfay",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rs3SWW2Epa",
            locked: 1717782023,
            timestamp: 1717782022,
          },
        ],
        createdAt: "2024-06-07T17:40:23.991Z",
        updatedAt: "2024-06-07T17:40:23.991Z",
      },
      {
        id: "clx4z28h200gs12vcs748etz4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hpBYUeNV8w",
            locked: 1717782018,
            timestamp: 1717782016,
          },
        ],
        createdAt: "2024-06-07T17:40:18.659Z",
        updatedAt: "2024-06-07T17:40:18.659Z",
      },
      {
        id: "clx4z1zxw00gr12vc9biplwfw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PS2dGUZ6Ry",
            locked: 1717782006,
            timestamp: 1717782001,
          },
        ],
        createdAt: "2024-06-07T17:40:07.605Z",
        updatedAt: "2024-06-07T17:40:07.605Z",
      },
      {
        id: "clx4z1twe00gq12vcq6cnclir",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Y8zf7u9xNf",
            locked: 1717781999,
            timestamp: 1717781994,
          },
        ],
        createdAt: "2024-06-07T17:39:59.774Z",
        updatedAt: "2024-06-07T17:39:59.774Z",
      },
      {
        id: "clx4z1ory00gp12vc8gd0ztpl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EM1gJniinD",
            locked: 1717781992,
            timestamp: 1717781991,
          },
        ],
        createdAt: "2024-06-07T17:39:53.133Z",
        updatedAt: "2024-06-07T17:39:53.133Z",
      },
      {
        id: "clx4z0yy000go12vcqhd6d9mf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "GXy7F9T05s",
            locked: 1717781957,
            timestamp: 1717781932,
          },
        ],
        createdAt: "2024-06-07T17:39:19.653Z",
        updatedAt: "2024-06-07T17:39:19.653Z",
      },
      {
        id: "clx4z0l9l00gn12vc6w0z69jr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XPF0FT85kO",
            locked: 1717781941,
            timestamp: 1717781940,
          },
        ],
        createdAt: "2024-06-07T17:39:01.930Z",
        updatedAt: "2024-06-07T17:39:01.930Z",
      },
      {
        id: "clx4z0kwz00gm12vc6m9ojkym",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "0Ya5IFXfLc",
            locked: 1717781940,
            timestamp: 1717781934,
          },
        ],
        createdAt: "2024-06-07T17:39:01.473Z",
        updatedAt: "2024-06-07T17:39:01.473Z",
      },
      {
        id: "clx4z09l900gl12vctm357djr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Sk5ciVH2Gn",
            locked: 1717781925,
            timestamp: 1717781924,
          },
        ],
        createdAt: "2024-06-07T17:38:46.798Z",
        updatedAt: "2024-06-07T17:38:46.798Z",
      },
      {
        id: "clx4yzz8800gk12vci0qlw4t4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "8BhSJyO12I",
            locked: 1717781913,
            timestamp: 1717781901,
          },
        ],
        createdAt: "2024-06-07T17:38:33.366Z",
        updatedAt: "2024-06-07T17:38:33.366Z",
      },
      {
        id: "clx4yzoon00gj12vcycbutclw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9Q3HWL92p7",
            locked: 1717781899,
            timestamp: 1717781896,
          },
        ],
        createdAt: "2024-06-07T17:38:19.703Z",
        updatedAt: "2024-06-07T17:38:19.703Z",
      },
      {
        id: "clx4yzncn00gi12vcp2842tx1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PomQLEpIMs",
            locked: 1717781896,
            timestamp: 1717781895,
          },
        ],
        createdAt: "2024-06-07T17:38:17.972Z",
        updatedAt: "2024-06-07T17:38:17.972Z",
      },
      {
        id: "clx4yytpp00gh12vc20rgilhp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,1; iOS 16_7_8; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "i4PPrqZFAE",
            locked: 1717781859,
            timestamp: 1717781856,
          },
        ],
        createdAt: "2024-06-07T17:37:39.562Z",
        updatedAt: "2024-06-07T17:37:39.562Z",
      },
      {
        id: "clx4yxjgi00gg12vc7xgz4zz9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.1.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qBfdDn28up",
            locked: 1717781799,
            timestamp: 1717781797,
          },
        ],
        createdAt: "2024-06-07T17:36:39.619Z",
        updatedAt: "2024-06-07T17:36:39.619Z",
      },
      {
        id: "clx4yxgr800gf12vc4hfwl1oz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 324.0.4.26.52 (iPhone16,2; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 580117525)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "izEbpQ7cme",
            locked: 1717781793,
            timestamp: 1717781792,
          },
        ],
        createdAt: "2024-06-07T17:36:36.116Z",
        updatedAt: "2024-06-07T17:36:36.116Z",
      },
      {
        id: "clx4yx8z000ge12vci76j2jes",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone13,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "y9hvq3Y53w",
            locked: 1717781785,
            timestamp: 1717781784,
          },
        ],
        createdAt: "2024-06-07T17:36:26.026Z",
        updatedAt: "2024-06-07T17:36:26.026Z",
      },
      {
        id: "clx4yw78j00gd12vc9as9qfwk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-G892U Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.113 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (28/9; 540dpi; 1080x2169; samsung; SM-G892U; cruiserltesq; qcom; en_US; 604247855)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "pZhHNRj6Z7",
            locked: 1717781736,
            timestamp: 1717781735,
          },
        ],
        createdAt: "2024-06-07T17:35:37.123Z",
        updatedAt: "2024-06-07T17:35:37.123Z",
      },
      {
        id: "clx4yw74q00gc12vca5nytjnf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; SM-G892U Build/PPR1.180610.011; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.113 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (28/9; 540dpi; 1080x2169; samsung; SM-G892U; cruiserltesq; qcom; en_US; 604247855)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZD0AmLZlsD",
            locked: 1717781735,
            timestamp: 1717781725,
          },
        ],
        createdAt: "2024-06-07T17:35:36.985Z",
        updatedAt: "2024-06-07T17:35:36.985Z",
      },
      {
        id: "clx4yw5fc00gb12vcmegaamhw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "BTYdmndWmY",
            locked: 1717781734,
            timestamp: 1717781733,
          },
        ],
        createdAt: "2024-06-07T17:35:34.777Z",
        updatedAt: "2024-06-07T17:35:34.777Z",
      },
      {
        id: "clx4yw4la00ga12vcpetictzg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "J1mnBOIffq",
            locked: 1717781732,
            timestamp: 1717781731,
          },
        ],
        createdAt: "2024-06-07T17:35:33.694Z",
        updatedAt: "2024-06-07T17:35:33.694Z",
      },
      {
        id: "clx4yvvjj00g912vc1rdmw54a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PDjxfhcbrJ",
            locked: 1717781721,
            timestamp: 1717781719,
          },
        ],
        createdAt: "2024-06-07T17:35:21.964Z",
        updatedAt: "2024-06-07T17:35:21.964Z",
      },
      {
        id: "clx4yvl8700g812vcv5hnce6f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "iqlYSAptD9",
            locked: 1717781708,
            timestamp: 1717781706,
          },
        ],
        createdAt: "2024-06-07T17:35:08.599Z",
        updatedAt: "2024-06-07T17:35:08.599Z",
      },
      {
        id: "clx4yvis600g712vcof26wfxs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rDRnvkQoOO",
            locked: 1717781704,
            timestamp: 1717781702,
          },
        ],
        createdAt: "2024-06-07T17:35:05.427Z",
        updatedAt: "2024-06-07T17:35:05.427Z",
      },
      {
        id: "clx4yv9vq00g612vcerhwww7j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.0 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "hM50Mf1spY",
            locked: 1717781693,
            timestamp: 1717781692,
          },
        ],
        createdAt: "2024-06-07T17:34:53.894Z",
        updatedAt: "2024-06-07T17:34:53.894Z",
      },
      {
        id: "clx4yv5e300g512vcvf6lxngs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.0 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cf5nxDq315",
            locked: 1717781687,
            timestamp: 1717781685,
          },
        ],
        createdAt: "2024-06-07T17:34:48.073Z",
        updatedAt: "2024-06-07T17:34:48.073Z",
      },
      {
        id: "clx4yusip00g412vcadpzlb9o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "TaChjHmYRJ",
            locked: 1717781671,
            timestamp: 1717781669,
          },
        ],
        createdAt: "2024-06-07T17:34:31.394Z",
        updatedAt: "2024-06-07T17:34:31.394Z",
      },
      {
        id: "clx4yuoas00g312vc1xenryi9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 420dpi; 1080x2133; samsung; SM-S901U; r0q; qcom; en_US; 604247760)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yfTS3BJIPJ",
            locked: 1717781665,
            timestamp: 1717781661,
          },
        ],
        createdAt: "2024-06-07T17:34:25.921Z",
        updatedAt: "2024-06-07T17:34:25.921Z",
      },
      {
        id: "clx4yufrh00g212vcx8s1w828",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "KyVugfXGQk",
            locked: 1717781654,
            timestamp: 1717781653,
          },
        ],
        createdAt: "2024-06-07T17:34:14.861Z",
        updatedAt: "2024-06-07T17:34:14.861Z",
      },
      {
        id: "clx4yu97t00g112vcj2i1afjh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PFUT9F3QCe",
            locked: 1717781645,
            timestamp: 1717781644,
          },
        ],
        createdAt: "2024-06-07T17:34:06.374Z",
        updatedAt: "2024-06-07T17:34:06.374Z",
      },
      {
        id: "clx4ytqb900g012vcvfpzj2cc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "NhKMtnjPUi",
            locked: 1717781621,
            timestamp: 1717781620,
          },
        ],
        createdAt: "2024-06-07T17:33:41.877Z",
        updatedAt: "2024-06-07T17:33:41.877Z",
      },
      {
        id: "clx4ytoyi00fz12vcbo1vkypu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "5VR9bugg1L",
            locked: 1717781619,
            timestamp: 1717781617,
          },
        ],
        createdAt: "2024-06-07T17:33:40.120Z",
        updatedAt: "2024-06-07T17:33:40.120Z",
      },
      {
        id: "clx4ytb7800fy12vcn1z5bchh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "sQWgKhsNLM",
            locked: 1717781602,
            timestamp: 1717781601,
          },
        ],
        createdAt: "2024-06-07T17:33:22.292Z",
        updatedAt: "2024-06-07T17:33:22.292Z",
      },
      {
        id: "clx4yt41e00fx12vcfstwctbh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "wAk1Xxt16W",
            locked: 1717781592,
            timestamp: 1717781590,
          },
        ],
        createdAt: "2024-06-07T17:33:13.007Z",
        updatedAt: "2024-06-07T17:33:13.007Z",
      },
      {
        id: "clx4ysuay00fw12vchyet74o7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 [FBAN/FBIOS;FBAV/460.0.0.31.103;FBBV/588708950;FBDV/iPhone14,2;FBMD/iPhone;FBSN/iOS;FBSV/17.4.1;FBSS/3;FBID/phone;FBLC/en_US;FBOP/5;FBRV/592229855]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kHxFrOJ0nI",
            locked: 1717781579,
            timestamp: 1717781578,
          },
        ],
        createdAt: "2024-06-07T17:33:00.395Z",
        updatedAt: "2024-06-07T17:33:00.395Z",
      },
      {
        id: "clx4ysrn800fv12vcnindwlr5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zU2D1glxlS",
            locked: 1717781575,
            timestamp: 1717781574,
          },
        ],
        createdAt: "2024-06-07T17:32:56.948Z",
        updatedAt: "2024-06-07T17:32:56.948Z",
      },
      {
        id: "clx4ysmzn00fu12vcrf7w1wmr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "atvjrT3EAJ",
            locked: 1717781570,
            timestamp: 1717781569,
          },
        ],
        createdAt: "2024-06-07T17:32:50.913Z",
        updatedAt: "2024-06-07T17:32:50.913Z",
      },
      {
        id: "clx4ys9fv00ft12vc87dlbvg9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NNj6aeJ6tK",
            locked: 1717781551,
            timestamp: 1717781549,
          },
        ],
        createdAt: "2024-06-07T17:32:33.355Z",
        updatedAt: "2024-06-07T17:32:33.355Z",
      },
      {
        id: "clx4ys69d00fs12vct69dsqwj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_GB; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Kttp4ocq8u",
            locked: 1717781548,
            timestamp: 1717781547,
          },
        ],
        createdAt: "2024-06-07T17:32:29.230Z",
        updatedAt: "2024-06-07T17:32:29.230Z",
      },
      {
        id: "clx4yrl4w00fr12vckkj984i9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "enKGx5CWcE",
            locked: 1717781521,
            timestamp: 1717781520,
          },
        ],
        createdAt: "2024-06-07T17:32:01.854Z",
        updatedAt: "2024-06-07T17:32:01.854Z",
      },
      {
        id: "clx4yqs4l00fq12vc14z8arsw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.1.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4SjSTHMogy",
            locked: 1717781483,
            timestamp: 1717781482,
          },
        ],
        createdAt: "2024-06-07T17:31:24.262Z",
        updatedAt: "2024-06-07T17:31:24.262Z",
      },
      {
        id: "clx4yqmk100fp12vck3t6m4s8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Fwg8qwA4Uz",
            locked: 1717781476,
            timestamp: 1717781471,
          },
        ],
        createdAt: "2024-06-07T17:31:17.039Z",
        updatedAt: "2024-06-07T17:31:17.039Z",
      },
      {
        id: "clx4ypu5500fo12vc8a80xon4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1CDlpiEXlh",
            locked: 1717781439,
            timestamp: 1717781432,
          },
        ],
        createdAt: "2024-06-07T17:30:40.215Z",
        updatedAt: "2024-06-07T17:30:40.215Z",
      },
      {
        id: "clx4ypcyu00fn12vconfbd1pu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "cQMyV3Pd09",
            locked: 1717781417,
            timestamp: 1717781416,
          },
        ],
        createdAt: "2024-06-07T17:30:17.956Z",
        updatedAt: "2024-06-07T17:30:17.956Z",
      },
      {
        id: "clx4yotae00fm12vc175eezr2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_3_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yQHf2kNQsC",
            locked: 1717781391,
            timestamp: 1717781390,
          },
        ],
        createdAt: "2024-06-07T17:29:52.454Z",
        updatedAt: "2024-06-07T17:29:52.454Z",
      },
      {
        id: "clx4yosxb00fl12vclr5l8mqg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Dj4m8BcL5V",
            locked: 1717781391,
            timestamp: 1717781390,
          },
        ],
        createdAt: "2024-06-07T17:29:51.980Z",
        updatedAt: "2024-06-07T17:29:51.980Z",
      },
      {
        id: "clx4yocw500fk12vcb79szlc0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-N975U1 Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.64 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (31/12; 420dpi; 1080x2069; samsung; SM-N975U1; d2q; qcom; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fVBCVHdVH5",
            locked: 1717781369,
            timestamp: 1717781366,
          },
        ],
        createdAt: "2024-06-07T17:29:31.203Z",
        updatedAt: "2024-06-07T17:29:31.203Z",
      },
      {
        id: "clx4ynhvw00fj12vchhkkwo8s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-A217F Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.152 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/467.0.0.46.83;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "opAzUazjzM",
            locked: 1717781330,
            timestamp: 1717781326,
          },
        ],
        createdAt: "2024-06-07T17:28:51.017Z",
        updatedAt: "2024-06-07T17:28:51.017Z",
      },
      {
        id: "clx4yn25800fi12vcmq1yqivp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "BPkHJwX92o",
            locked: 1717781310,
            timestamp: 1717781308,
          },
        ],
        createdAt: "2024-06-07T17:28:30.615Z",
        updatedAt: "2024-06-07T17:28:30.615Z",
      },
      {
        id: "clx4ylkst00fh12vcb39xy6wr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "BR83uTXkOD",
            locked: 1717781241,
            timestamp: 1717781239,
          },
        ],
        createdAt: "2024-06-07T17:27:21.485Z",
        updatedAt: "2024-06-07T17:27:21.485Z",
      },
      {
        id: "clx4yleyx00fg12vcf09xfytu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A146U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 327.2.0.50.93 Android (34/14; 450dpi; 1080x2208; samsung; SM-A146U; a14xm; mt6833; en_US; 588553287)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Puyn3j0uv1",
            locked: 1717781233,
            timestamp: 1717781228,
          },
        ],
        createdAt: "2024-06-07T17:27:13.929Z",
        updatedAt: "2024-06-07T17:27:13.929Z",
      },
      {
        id: "clx4ylej900ff12vcz5k2lwlp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fhDocmfn9D",
            locked: 1717781232,
            timestamp: 1717781231,
          },
        ],
        createdAt: "2024-06-07T17:27:13.363Z",
        updatedAt: "2024-06-07T17:27:13.363Z",
      },
      {
        id: "clx4yl2ga00fe12vcr1q3b2v6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/126.2  Mobile/15E148 Safari/605.1.15",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xr4ZM1CYe5",
            locked: 1717781216,
            timestamp: 1717781214,
          },
        ],
        createdAt: "2024-06-07T17:26:57.704Z",
        updatedAt: "2024-06-07T17:26:57.704Z",
      },
      {
        id: "clx4yknfl00fd12vcflos3d47",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "n14sk39dcD",
            locked: 1717781198,
            timestamp: 1717781197,
          },
        ],
        createdAt: "2024-06-07T17:26:38.242Z",
        updatedAt: "2024-06-07T17:26:38.242Z",
      },
      {
        id: "clx4ykm9a00fc12vcktchhz7l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Vburia48LS",
            locked: 1717781196,
            timestamp: 1717781194,
          },
        ],
        createdAt: "2024-06-07T17:26:36.719Z",
        updatedAt: "2024-06-07T17:26:36.719Z",
      },
      {
        id: "clx4ykcez00fb12vciuboxgol",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 320.0.2.29.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 569998124)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "uh300P2uLs",
            locked: 1717781183,
            timestamp: 1717781181,
          },
        ],
        createdAt: "2024-06-07T17:26:23.960Z",
        updatedAt: "2024-06-07T17:26:23.960Z",
      },
      {
        id: "clx4yjrag00fa12vcafsaiiqk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "zO9mHnAV56",
            locked: 1717781156,
            timestamp: 1717781155,
          },
        ],
        createdAt: "2024-06-07T17:25:56.582Z",
        updatedAt: "2024-06-07T17:25:56.582Z",
      },
      {
        id: "clx4yjg5j00f912vci005irri",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 318.0.7.22.109 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 565151867)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "U3pwaAfjK0",
            locked: 1717781141,
            timestamp: 1717781140,
          },
        ],
        createdAt: "2024-06-07T17:25:42.151Z",
        updatedAt: "2024-06-07T17:25:42.151Z",
      },
      {
        id: "clx4yjder00f812vcrxpvws3o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 328.1.3.32.89 (iPhone14,2; iOS 15_6_1; en_US; en; scale=3.00; 1170x2532; 591155251)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "rBTV3JXhiz",
            locked: 1717781138,
            timestamp: 1717781137,
          },
        ],
        createdAt: "2024-06-07T17:25:38.595Z",
        updatedAt: "2024-06-07T17:25:38.595Z",
      },
      {
        id: "clx4yjbpm00f712vcjk3bp0cb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone15,2; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZBOVmG0SNN",
            locked: 1717781135,
            timestamp: 1717781132,
          },
        ],
        createdAt: "2024-06-07T17:25:36.394Z",
        updatedAt: "2024-06-07T17:25:36.394Z",
      },
      {
        id: "clx4yj94k00f612vc80vf0bw2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Oz33Ndwkfo",
            locked: 1717781132,
            timestamp: 1717781130,
          },
        ],
        createdAt: "2024-06-07T17:25:33.044Z",
        updatedAt: "2024-06-07T17:25:33.044Z",
      },
      {
        id: "clx4yj89e00f512vc1c5tu85g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "p1ZrocI9en",
            locked: 1717781131,
            timestamp: 1717781127,
          },
        ],
        createdAt: "2024-06-07T17:25:31.921Z",
        updatedAt: "2024-06-07T17:25:31.921Z",
      },
      {
        id: "clx4yj5bq00f412vcn6s25v2j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 328.1.3.32.89 (iPhone14,2; iOS 15_6_1; en_US; en; scale=3.00; 1170x2532; 591155251)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1T7uWAdeiO",
            locked: 1717781127,
            timestamp: 1717781126,
          },
        ],
        createdAt: "2024-06-07T17:25:28.115Z",
        updatedAt: "2024-06-07T17:25:28.115Z",
      },
      {
        id: "clx4yhyb900f312vcg1q7chx6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 331.1.3.29.90 (iPhone15,3; iOS 17_5_1; es_EC; es; scale=3.00; 1290x2796; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yODyYqD53n",
            locked: 1717781071,
            timestamp: 1717781069,
          },
        ],
        createdAt: "2024-06-07T17:24:32.370Z",
        updatedAt: "2024-06-07T17:24:32.370Z",
      },
      {
        id: "clx4yhj8f00f212vcr3ohefoo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad8,11; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pryjzTEuIm",
            locked: 1717781052,
            timestamp: 1717781050,
          },
        ],
        createdAt: "2024-06-07T17:24:12.827Z",
        updatedAt: "2024-06-07T17:24:12.827Z",
      },
      {
        id: "clx4yfvyq00f112vcuck1q78h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "oBe0WAWS17",
            locked: 1717780975,
            timestamp: 1717780974,
          },
        ],
        createdAt: "2024-06-07T17:22:56.015Z",
        updatedAt: "2024-06-07T17:22:56.015Z",
      },
      {
        id: "clx4yesxa00f012vcuz2taf7b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "2vqDX8Nfvy",
            locked: 1717780925,
            timestamp: 1717780924,
          },
        ],
        createdAt: "2024-06-07T17:22:05.421Z",
        updatedAt: "2024-06-07T17:22:05.421Z",
      },
      {
        id: "clx4yeok800ez12vcv8g81k9d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nWrP9185EW",
            locked: 1717780919,
            timestamp: 1717780918,
          },
        ],
        createdAt: "2024-06-07T17:21:59.769Z",
        updatedAt: "2024-06-07T17:21:59.769Z",
      },
      {
        id: "clx4yelhq00ey12vc95h3icw4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "ItxIBeBjqI",
            locked: 1717780915,
            timestamp: 1717780914,
          },
        ],
        createdAt: "2024-06-07T17:21:55.791Z",
        updatedAt: "2024-06-07T17:21:55.791Z",
      },
      {
        id: "clx4yeibu00ex12vc9tt96gqa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Pob1dkVqeY",
            locked: 1717780910,
            timestamp: 1717780908,
          },
        ],
        createdAt: "2024-06-07T17:21:51.691Z",
        updatedAt: "2024-06-07T17:21:51.691Z",
      },
      {
        id: "clx4yedf900ew12vcdq76qicu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LcG8Pp0nxz",
            locked: 1717780904,
            timestamp: 1717780899,
          },
        ],
        createdAt: "2024-06-07T17:21:45.330Z",
        updatedAt: "2024-06-07T17:21:45.330Z",
      },
      {
        id: "clx4ydyo900ev12vcc3v1sg7q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "yqP0MyyKxW",
            locked: 1717780885,
            timestamp: 1717780884,
          },
        ],
        createdAt: "2024-06-07T17:21:26.217Z",
        updatedAt: "2024-06-07T17:21:26.217Z",
      },
      {
        id: "clx4ydxc100eu12vcp2k92y7v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "F0pAgFPVBs",
            locked: 1717780884,
            timestamp: 1717780883,
          },
        ],
        createdAt: "2024-06-07T17:21:24.481Z",
        updatedAt: "2024-06-07T17:21:24.481Z",
      },
      {
        id: "clx4yduku00et12vcre304eht",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jZwAZVlAfj",
            locked: 1717780880,
            timestamp: 1717780879,
          },
        ],
        createdAt: "2024-06-07T17:21:20.909Z",
        updatedAt: "2024-06-07T17:21:20.909Z",
      },
      {
        id: "clx4ydpge00es12vcrfvyz0e5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20C65 Instagram 334.0.4.32.98 (iPhone15,2; iOS 16_2; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PnEtOMEf0v",
            locked: 1717780873,
            timestamp: 1717780872,
          },
        ],
        createdAt: "2024-06-07T17:21:14.270Z",
        updatedAt: "2024-06-07T17:21:14.270Z",
      },
      {
        id: "clx4ydmw600er12vc8iavwfca",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "so61jujLz2",
            locked: 1717780869,
            timestamp: 1717780867,
          },
        ],
        createdAt: "2024-06-07T17:21:10.951Z",
        updatedAt: "2024-06-07T17:21:10.951Z",
      },
      {
        id: "clx4ydjcv00eq12vc3wpilmmh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H12 Instagram 327.1.6.30.88 (iPhone14,3; iOS 15_7; en_US; en; scale=3.00; 1284x2778; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "gwuuWXnDW2",
            locked: 1717780866,
            timestamp: 1717780865,
          },
        ],
        createdAt: "2024-06-07T17:21:06.367Z",
        updatedAt: "2024-06-07T17:21:06.367Z",
      },
      {
        id: "clx4ydfsz00ep12vcdpktgvji",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_7 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H12 Instagram 327.1.6.30.88 (iPhone14,3; iOS 15_7; en_US; en; scale=3.00; 1284x2778; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WIA1R0L8ub",
            locked: 1717780861,
            timestamp: 1717780859,
          },
        ],
        createdAt: "2024-06-07T17:21:01.760Z",
        updatedAt: "2024-06-07T17:21:01.760Z",
      },
      {
        id: "clx4yd40q00eo12vc5miae7ka",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "eSOUhOx4bC",
            locked: 1717780843,
            timestamp: 1717780841,
          },
        ],
        createdAt: "2024-06-07T17:20:46.491Z",
        updatedAt: "2024-06-07T17:20:46.491Z",
      },
      {
        id: "clx4ycxo500en12vcqrsmrq2f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LhHoSXJOFO",
            locked: 1717780837,
            timestamp: 1717780836,
          },
        ],
        createdAt: "2024-06-07T17:20:38.259Z",
        updatedAt: "2024-06-07T17:20:38.259Z",
      },
      {
        id: "clx4ycna400em12vcjikm6585",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 11; SM-A405FN Build/RP1A.200720.012; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (30/11; 480dpi; 1080x2110; samsung; SM-A405FN; a40; exynos7904; sr_RS; 604247822)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "l0uhsmOOmx",
            locked: 1717780821,
            timestamp: 1717780817,
          },
        ],
        createdAt: "2024-06-07T17:20:24.796Z",
        updatedAt: "2024-06-07T17:20:24.796Z",
      },
      {
        id: "clx4ycmls00el12vcu7xs94x6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tPcfxwUjj6",
            locked: 1717780823,
            timestamp: 1717780819,
          },
        ],
        createdAt: "2024-06-07T17:20:23.921Z",
        updatedAt: "2024-06-07T17:20:23.921Z",
      },
      {
        id: "clx4ycij500ek12vck476v4w4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "530xKtSBwe",
            locked: 1717780818,
            timestamp: 1717780816,
          },
        ],
        createdAt: "2024-06-07T17:20:18.641Z",
        updatedAt: "2024-06-07T17:20:18.641Z",
      },
      {
        id: "clx4ycd5c00ej12vcyvkealkp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "KVgYEDsu5y",
            locked: 1717780811,
            timestamp: 1717780809,
          },
        ],
        createdAt: "2024-06-07T17:20:11.664Z",
        updatedAt: "2024-06-07T17:20:11.664Z",
      },
      {
        id: "clx4ycccj00ei12vcsgwn0cr7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Z8RP1dnhK6",
            locked: 1717780810,
            timestamp: 1717780809,
          },
        ],
        createdAt: "2024-06-07T17:20:10.626Z",
        updatedAt: "2024-06-07T17:20:10.626Z",
      },
      {
        id: "clx4yc6ak00eh12vca9jpx6kh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "sDpX492yLI",
            locked: 1717780802,
            timestamp: 1717780800,
          },
        ],
        createdAt: "2024-06-07T17:20:02.781Z",
        updatedAt: "2024-06-07T17:20:02.781Z",
      },
      {
        id: "clx4ybwvf00eg12vcoyarqn1k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bffZBlyRet",
            locked: 1717780736,
            timestamp: 1717780735,
          },
        ],
        createdAt: "2024-06-07T17:19:50.570Z",
        updatedAt: "2024-06-07T17:19:50.570Z",
      },
      {
        id: "clx4ybpu900ef12vcu7ves8oo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "elfpxjxdNq",
            locked: 1717780781,
            timestamp: 1717780780,
          },
        ],
        createdAt: "2024-06-07T17:19:41.457Z",
        updatedAt: "2024-06-07T17:19:41.457Z",
      },
      {
        id: "clx4ybpjv00ee12vca8p2bj3h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "KBzmu6lwEx",
            locked: 1717780777,
            timestamp: 1717780776,
          },
        ],
        createdAt: "2024-06-07T17:19:41.084Z",
        updatedAt: "2024-06-07T17:19:41.084Z",
      },
      {
        id: "clx4ybjct00ed12vcit0pnn8u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9LcRLHr9jW",
            locked: 1717780771,
            timestamp: 1717780770,
          },
        ],
        createdAt: "2024-06-07T17:19:33.051Z",
        updatedAt: "2024-06-07T17:19:33.051Z",
      },
      {
        id: "clx4yb4vy00ec12vcnjxn5kct",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "LrIqtHLhsv",
            locked: 1717780754,
            timestamp: 1717780752,
          },
        ],
        createdAt: "2024-06-07T17:19:14.303Z",
        updatedAt: "2024-06-07T17:19:14.303Z",
      },
      {
        id: "clx4yb3x900eb12vcu9fevost",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3wRmi7Y3dh",
            locked: 1717780752,
            timestamp: 1717780749,
          },
        ],
        createdAt: "2024-06-07T17:19:13.051Z",
        updatedAt: "2024-06-07T17:19:13.051Z",
      },
      {
        id: "clx4yamox00ea12vcwu1d8mqc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "YkfO7CgckY",
            locked: 1717780730,
            timestamp: 1717780728,
          },
        ],
        createdAt: "2024-06-07T17:18:50.720Z",
        updatedAt: "2024-06-07T17:18:50.720Z",
      },
      {
        id: "clx4yagmp00e912vckv6hh3vw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "He4pLqsEPa",
            locked: 1717780722,
            timestamp: 1717780720,
          },
        ],
        createdAt: "2024-06-07T17:18:42.865Z",
        updatedAt: "2024-06-07T17:18:42.865Z",
      },
      {
        id: "clx4ya7wf00e812vcu3zmzeno",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; SM-S901U Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 510dpi; 1080x2106; samsung; SM-S901U; r0q; qcom; en_US; 606558446)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Nh4EuKmzZB",
            locked: 1717780711,
            timestamp: 1717780710,
          },
        ],
        createdAt: "2024-06-07T17:18:31.549Z",
        updatedAt: "2024-06-07T17:18:31.549Z",
      },
      {
        id: "clx4y9n5e00e712vc3wxxl3cb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0Dis6sIraF",
            locked: 1717780684,
            timestamp: 1717780683,
          },
        ],
        createdAt: "2024-06-07T17:18:04.658Z",
        updatedAt: "2024-06-07T17:18:04.658Z",
      },
      {
        id: "clx4y9hx500e612vcljaqdo8j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lyvszWo4gt",
            locked: 1717780676,
            timestamp: 1717780674,
          },
        ],
        createdAt: "2024-06-07T17:17:57.878Z",
        updatedAt: "2024-06-07T17:17:57.878Z",
      },
      {
        id: "clx4y8zux00e512vcqwm3jyhe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Kbf5KuuJsX",
            locked: 1717780654,
            timestamp: 1717780653,
          },
        ],
        createdAt: "2024-06-07T17:17:34.471Z",
        updatedAt: "2024-06-07T17:17:34.471Z",
      },
      {
        id: "clx4y8tzd00e412vch7krjg1k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vbZ8WPikQD",
            locked: 1717780646,
            timestamp: 1717780644,
          },
        ],
        createdAt: "2024-06-07T17:17:26.858Z",
        updatedAt: "2024-06-07T17:17:26.858Z",
      },
      {
        id: "clx4y8q4z00e312vcxo09vaws",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_4_1; en_GB; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "7rJ7Lt6XSL",
            locked: 1717780641,
            timestamp: 1717780640,
          },
        ],
        createdAt: "2024-06-07T17:17:21.875Z",
        updatedAt: "2024-06-07T17:17:21.875Z",
      },
      {
        id: "clx4y8n3q00e212vcarohebej",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,2; iOS 16_7_8; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gTuYIYl1r2",
            locked: 1717780637,
            timestamp: 1717780635,
          },
        ],
        createdAt: "2024-06-07T17:17:17.942Z",
        updatedAt: "2024-06-07T17:17:17.942Z",
      },
      {
        id: "clx4y8jcy00e112vcigilctgl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_4_1; en_GB; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CGPH5WzYKv",
            locked: 1717780632,
            timestamp: 1717780631,
          },
        ],
        createdAt: "2024-06-07T17:17:13.086Z",
        updatedAt: "2024-06-07T17:17:13.086Z",
      },
      {
        id: "clx4y87ho00e012vct25y0rb3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "12jMlIQzOW",
            locked: 1717780617,
            timestamp: 1717780616,
          },
        ],
        createdAt: "2024-06-07T17:16:57.706Z",
        updatedAt: "2024-06-07T17:16:57.706Z",
      },
      {
        id: "clx4y7y3a00dz12vc246h9la6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G998U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2181; samsung; SM-G998U; p3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MFaAJ03Aae",
            locked: 1717780604,
            timestamp: 1717780602,
          },
        ],
        createdAt: "2024-06-07T17:16:45.526Z",
        updatedAt: "2024-06-07T17:16:45.526Z",
      },
      {
        id: "clx4y7tgb00dy12vcrpzlm75c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2KjXNZo1bD",
            locked: 1717780598,
            timestamp: 1717780597,
          },
        ],
        createdAt: "2024-06-07T17:16:39.512Z",
        updatedAt: "2024-06-07T17:16:39.512Z",
      },
      {
        id: "clx4y7gyk00dx12vcr7a0afb3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "PjdQItyJRZ",
            locked: 1717780583,
            timestamp: 1717780582,
          },
        ],
        createdAt: "2024-06-07T17:16:23.325Z",
        updatedAt: "2024-06-07T17:16:23.325Z",
      },
      {
        id: "clx4y7ce900dw12vcaiog5056",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "DtyVOqaiFT",
            locked: 1717780576,
            timestamp: 1717780574,
          },
        ],
        createdAt: "2024-06-07T17:16:17.409Z",
        updatedAt: "2024-06-07T17:16:17.409Z",
      },
      {
        id: "clx4y7aak00dv12vcfwxm9iey",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "dyB2bzLTwj",
            locked: 1717780574,
            timestamp: 1717780573,
          },
        ],
        createdAt: "2024-06-07T17:16:14.684Z",
        updatedAt: "2024-06-07T17:16:14.684Z",
      },
      {
        id: "clx4y78n000du12vcdpbbfvci",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "UHiLY4lNPT",
            locked: 1717780570,
            timestamp: 1717780569,
          },
        ],
        createdAt: "2024-06-07T17:16:12.540Z",
        updatedAt: "2024-06-07T17:16:12.540Z",
      },
      {
        id: "clx4y70on00dt12vc5156hvp8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rXD0rHqaHV",
            locked: 1717780559,
            timestamp: 1717780557,
          },
        ],
        createdAt: "2024-06-07T17:16:02.228Z",
        updatedAt: "2024-06-07T17:16:02.228Z",
      },
      {
        id: "clx4y6lkq00ds12vcnrbn5hrx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A536U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.83 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2177; samsung; SM-A536U; a53x; s5e8825; en_US; 604247854)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MtqKZUo6Ar",
            locked: 1717780540,
            timestamp: 1717780528,
          },
        ],
        createdAt: "2024-06-07T17:15:42.647Z",
        updatedAt: "2024-06-07T17:15:42.647Z",
      },
      {
        id: "clx4y64g400dr12vcykf9vjzc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6nFUaOD0B7",
            locked: 1717780519,
            timestamp: 1717780518,
          },
        ],
        createdAt: "2024-06-07T17:15:20.450Z",
        updatedAt: "2024-06-07T17:15:20.450Z",
      },
      {
        id: "clx4y55yu00dq12vc0vo70l1b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cjABgiiOrT",
            locked: 1717780475,
            timestamp: 1717780473,
          },
        ],
        createdAt: "2024-06-07T17:14:35.761Z",
        updatedAt: "2024-06-07T17:14:35.761Z",
      },
      {
        id: "clx4y4gop00dp12vcq1m4cazg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "x4MZiNVQUh",
            locked: 1717780443,
            timestamp: 1717780441,
          },
        ],
        createdAt: "2024-06-07T17:14:02.997Z",
        updatedAt: "2024-06-07T17:14:02.997Z",
      },
      {
        id: "clx4y3po900do12vcdhatzqg2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "9a44lG9USR",
            locked: 1717780407,
            timestamp: 1717780406,
          },
        ],
        createdAt: "2024-06-07T17:13:27.993Z",
        updatedAt: "2024-06-07T17:13:27.993Z",
      },
      {
        id: "clx4y3nr200dn12vcf67umb74",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "An5Fe9Bc2E",
            locked: 1717780404,
            timestamp: 1717780403,
          },
        ],
        createdAt: "2024-06-07T17:13:25.502Z",
        updatedAt: "2024-06-07T17:13:25.502Z",
      },
      {
        id: "clx4y3mpa00dm12vc3i1o3du6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Sr22BjaCJ7",
            locked: 1717780403,
            timestamp: 1717780401,
          },
        ],
        createdAt: "2024-06-07T17:13:24.142Z",
        updatedAt: "2024-06-07T17:13:24.142Z",
      },
      {
        id: "clx4y3gu200dl12vckk782ga8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "r5GzV3kJD5",
            locked: 1717780396,
            timestamp: 1717780394,
          },
        ],
        createdAt: "2024-06-07T17:13:16.535Z",
        updatedAt: "2024-06-07T17:13:16.535Z",
      },
      {
        id: "clx4y2rd000dk12vc21k4vbjs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MOvbtRCed2",
            locked: 1717780362,
            timestamp: 1717780361,
          },
        ],
        createdAt: "2024-06-07T17:12:43.522Z",
        updatedAt: "2024-06-07T17:12:43.522Z",
      },
      {
        id: "clx4y2egu00dj12vciiix4801",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xbndPqcjM5",
            locked: 1717780346,
            timestamp: 1717780344,
          },
        ],
        createdAt: "2024-06-07T17:12:26.815Z",
        updatedAt: "2024-06-07T17:12:26.815Z",
      },
      {
        id: "clx4y24zt00di12vc8ej0rs2p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,2; iOS 16_7_8; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "BqhGQPYNSR",
            locked: 1717780333,
            timestamp: 1717780331,
          },
        ],
        createdAt: "2024-06-07T17:12:14.535Z",
        updatedAt: "2024-06-07T17:12:14.535Z",
      },
      {
        id: "clx4y1dbf00dh12vcn4nkgofd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F79 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5; en_CA; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "m6tPMtHz41",
            locked: 1717780298,
            timestamp: 1717780297,
          },
        ],
        createdAt: "2024-06-07T17:11:38.665Z",
        updatedAt: "2024-06-07T17:11:38.665Z",
      },
      {
        id: "clx4y136x00dg12vcjw2j3y6o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F79 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5; en_CA; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "x7jNdFR8yJ",
            locked: 1717780285,
            timestamp: 1717780284,
          },
        ],
        createdAt: "2024-06-07T17:11:25.546Z",
        updatedAt: "2024-06-07T17:11:25.546Z",
      },
      {
        id: "clx4y0vcc00df12vcups1mteb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5EsWFkZa3l",
            locked: 1717780274,
            timestamp: 1717780261,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "A40pOdrK8c",
            locked: 1717780274,
            timestamp: 1717780266,
          },
        ],
        createdAt: "2024-06-07T17:11:15.370Z",
        updatedAt: "2024-06-07T17:11:15.370Z",
      },
      {
        id: "clx4xzx5q00de12vcgwkcxcl0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "AH1eA7fFvo",
            locked: 1717780230,
            timestamp: 1717780228,
          },
        ],
        createdAt: "2024-06-07T17:10:31.071Z",
        updatedAt: "2024-06-07T17:10:31.071Z",
      },
      {
        id: "clx4xzqko00dd12vczub0q505",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zv7DvwDuOe",
            locked: 1717780222,
            timestamp: 1717780221,
          },
        ],
        createdAt: "2024-06-07T17:10:22.536Z",
        updatedAt: "2024-06-07T17:10:22.536Z",
      },
      {
        id: "clx4xzqk300dc12vcpdepi7bc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "4X0uECi388",
            locked: 1717780222,
            timestamp: 1717780221,
          },
        ],
        createdAt: "2024-06-07T17:10:22.516Z",
        updatedAt: "2024-06-07T17:10:22.516Z",
      },
      {
        id: "clx4xzmcb00db12vcj1hz5qj4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UchRU5gr7D",
            locked: 1717780216,
            timestamp: 1717780214,
          },
        ],
        createdAt: "2024-06-07T17:10:17.049Z",
        updatedAt: "2024-06-07T17:10:17.049Z",
      },
      {
        id: "clx4xyudb00da12vcmv4wofqo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 14_8_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/18H107 Instagram 313.0.2.9.339 (iPhone13,4; iOS 14_8_1; en_FR; en; scale=3.00; 1284x2778; 553462334)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7DMiFWofE8",
            locked: 1717780180,
            timestamp: 1717780179,
          },
        ],
        createdAt: "2024-06-07T17:09:40.800Z",
        updatedAt: "2024-06-07T17:09:40.800Z",
      },
      {
        id: "clx4xyp0x00d912vcagvdkh56",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone12,1; iOS 17_5_1; es_US; es; scale=2.00; 750x1624; 604797909) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EKQAomyQlP",
            locked: 1717780173,
            timestamp: 1717780171,
          },
        ],
        createdAt: "2024-06-07T17:09:33.870Z",
        updatedAt: "2024-06-07T17:09:33.870Z",
      },
      {
        id: "clx4xyblp00d812vcv70rjitc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "47vzTkkzld",
            locked: 1717780156,
            timestamp: 1717780154,
          },
        ],
        createdAt: "2024-06-07T17:09:16.475Z",
        updatedAt: "2024-06-07T17:09:16.475Z",
      },
      {
        id: "clx4xxt9l00d712vc4q80x58w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dHFQEBIfcK",
            locked: 1717780128,
            timestamp: 1717780127,
          },
        ],
        createdAt: "2024-06-07T17:08:52.711Z",
        updatedAt: "2024-06-07T17:08:52.711Z",
      },
      {
        id: "clx4xw7yp00d612vcv3mohid3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "w6JvxUFJqw",
            locked: 1717780058,
            timestamp: 1717780057,
          },
        ],
        createdAt: "2024-06-07T17:07:38.447Z",
        updatedAt: "2024-06-07T17:07:38.447Z",
      },
      {
        id: "clx4xw0dn00d512vc71gldbjb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,5; iOS 17_5_1; en_US; en; scale=3.00; 1242x2688; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "wavS5mT7Mj",
            locked: 1717780048,
            timestamp: 1717780046,
          },
        ],
        createdAt: "2024-06-07T17:07:28.619Z",
        updatedAt: "2024-06-07T17:07:28.619Z",
      },
      {
        id: "clx4xvryt00d412vcszxx28kp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ykEKwb5Sx6",
            locked: 1717780037,
            timestamp: 1717780035,
          },
        ],
        createdAt: "2024-06-07T17:07:17.715Z",
        updatedAt: "2024-06-07T17:07:17.715Z",
      },
      {
        id: "clx4xvg6d00d312vceexb4ax0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "iWIkg14raV",
            locked: 1717780022,
            timestamp: 1717780021,
          },
        ],
        createdAt: "2024-06-07T17:07:02.438Z",
        updatedAt: "2024-06-07T17:07:02.438Z",
      },
      {
        id: "clx4xvbw400d212vcu2xp7szy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qxjLntKWGO",
            locked: 1717780016,
            timestamp: 1717780012,
          },
        ],
        createdAt: "2024-06-07T17:06:56.885Z",
        updatedAt: "2024-06-07T17:06:56.885Z",
      },
      {
        id: "clx4xv8y800d112vcz4sfme9j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CwqX0um7H1",
            locked: 1717780012,
            timestamp: 1717780009,
          },
        ],
        createdAt: "2024-06-07T17:06:53.070Z",
        updatedAt: "2024-06-07T17:06:53.070Z",
      },
      {
        id: "clx4xuu2s00d012vc4r4fjm6z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZyghEdUSEZ",
            locked: 1717779992,
            timestamp: 1717779985,
          },
        ],
        createdAt: "2024-06-07T17:06:33.794Z",
        updatedAt: "2024-06-07T17:06:33.794Z",
      },
      {
        id: "clx4xubpe00cz12vcmo74ay5f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "BOxdYmoSSw",
            locked: 1717779968,
            timestamp: 1717779966,
          },
        ],
        createdAt: "2024-06-07T17:06:09.986Z",
        updatedAt: "2024-06-07T17:06:09.986Z",
      },
      {
        id: "clx4xu94m00cy12vcijm7sqyy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "mwJjVqdiyq",
            locked: 1717779965,
            timestamp: 1717779964,
          },
        ],
        createdAt: "2024-06-07T17:06:06.644Z",
        updatedAt: "2024-06-07T17:06:06.644Z",
      },
      {
        id: "clx4xthua00cx12vcajmnh01n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "7LfZzXqvFj",
            locked: 1717779930,
            timestamp: 1717779929,
          },
        ],
        createdAt: "2024-06-07T17:05:31.283Z",
        updatedAt: "2024-06-07T17:05:31.283Z",
      },
      {
        id: "clx4xtgib00cw12vckot7dwrc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ZPjITqoXzI",
            locked: 1717779929,
            timestamp: 1717779928,
          },
        ],
        createdAt: "2024-06-07T17:05:29.553Z",
        updatedAt: "2024-06-07T17:05:29.553Z",
      },
      {
        id: "clx4xsz3q00cv12vcgtnosmht",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "JSHk3M6r1e",
            locked: 1717779906,
            timestamp: 1717779904,
          },
        ],
        createdAt: "2024-06-07T17:05:06.995Z",
        updatedAt: "2024-06-07T17:05:06.995Z",
      },
      {
        id: "clx4xsmch00cu12vcx3f6rqz1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "g8EaKHV99v",
            locked: 1717779889,
            timestamp: 1717779888,
          },
        ],
        createdAt: "2024-06-07T17:04:50.463Z",
        updatedAt: "2024-06-07T17:04:50.463Z",
      },
      {
        id: "clx4xs0nk00ct12vcvuyouc82",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "H9HsubeiWc",
            locked: 1717779862,
            timestamp: 1717779861,
          },
        ],
        createdAt: "2024-06-07T17:04:22.353Z",
        updatedAt: "2024-06-07T17:04:22.353Z",
      },
      {
        id: "clx4xs0dk00cs12vcvzsytll4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QbfmNnR6Lk",
            locked: 1717779861,
            timestamp: 1717779860,
          },
        ],
        createdAt: "2024-06-07T17:04:21.992Z",
        updatedAt: "2024-06-07T17:04:21.992Z",
      },
      {
        id: "clx4xrxlx00cr12vcajbphyyi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "uWfQtd2ejG",
            locked: 1717779857,
            timestamp: 1717779856,
          },
        ],
        createdAt: "2024-06-07T17:04:18.403Z",
        updatedAt: "2024-06-07T17:04:18.403Z",
      },
      {
        id: "clx4xqi3000cq12vcb88t05o2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "qqoPNwps8U",
            locked: 1717779791,
            timestamp: 1717779790,
          },
        ],
        createdAt: "2024-06-07T17:03:11.626Z",
        updatedAt: "2024-06-07T17:03:11.626Z",
      },
      {
        id: "clx4xpfz400cp12vcnkf931s1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "b98vMee0Nz",
            locked: 1717779742,
            timestamp: 1717779741,
          },
        ],
        createdAt: "2024-06-07T17:02:22.241Z",
        updatedAt: "2024-06-07T17:02:22.241Z",
      },
      {
        id: "clx4xpa1m00co12vcf11eut2v",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VoNTzrmGTU",
            locked: 1717779733,
            timestamp: 1717779730,
          },
        ],
        createdAt: "2024-06-07T17:02:14.552Z",
        updatedAt: "2024-06-07T17:02:14.552Z",
      },
      {
        id: "clx4xocww00cn12vc25r54fw2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "su3dW8WkkW",
            locked: 1717779690,
            timestamp: 1717779689,
          },
        ],
        createdAt: "2024-06-07T17:01:31.614Z",
        updatedAt: "2024-06-07T17:01:31.614Z",
      },
      {
        id: "clx4xo2kq00cm12vc7u49pv7r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "WxJ7zx5A3f",
            locked: 1717779677,
            timestamp: 1717779676,
          },
        ],
        createdAt: "2024-06-07T17:01:18.218Z",
        updatedAt: "2024-06-07T17:01:18.218Z",
      },
      {
        id: "clx4xnum600cl12vcivbc7l85",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kaoHV17OiH",
            locked: 1717779661,
            timestamp: 1717779660,
          },
        ],
        createdAt: "2024-06-07T17:01:07.899Z",
        updatedAt: "2024-06-07T17:01:07.899Z",
      },
      {
        id: "clx4xnagq00ck12vck8zksmlo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "XzTGjGoFqx",
            locked: 1717779650,
            timestamp: 1717779649,
          },
        ],
        createdAt: "2024-06-07T17:00:41.787Z",
        updatedAt: "2024-06-07T17:00:41.787Z",
      },
      {
        id: "clx4xn94v00cj12vc36oobgcf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LpxGIJHAbl",
            locked: 1717779648,
            timestamp: 1717779646,
          },
        ],
        createdAt: "2024-06-07T17:00:40.061Z",
        updatedAt: "2024-06-07T17:00:40.061Z",
      },
      {
        id: "clx4xmtv400ci12vcvu23kv3l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 325.0.3.34.90 (iPhone14,5; iOS 16_1_1; en_US; en; scale=3.00; 1170x2532; 584303227)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "gQ8pyFAPS2",
            locked: 1717779619,
            timestamp: 1717779618,
          },
        ],
        createdAt: "2024-06-07T17:00:20.272Z",
        updatedAt: "2024-06-07T17:00:20.272Z",
      },
      {
        id: "clx4xmmpl00ch12vcswn54rbm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YBaP9Jb5rN",
            locked: 1717779610,
            timestamp: 1717779608,
          },
        ],
        createdAt: "2024-06-07T17:00:11.000Z",
        updatedAt: "2024-06-07T17:00:11.000Z",
      },
      {
        id: "clx4xmjd100cg12vc00wgy3c1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B101 Instagram 325.0.3.34.90 (iPhone14,5; iOS 16_1_1; en_US; en; scale=3.00; 1170x2532; 584303227)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2KC9Wsb4tT",
            locked: 1717779605,
            timestamp: 1717779604,
          },
        ],
        createdAt: "2024-06-07T17:00:06.662Z",
        updatedAt: "2024-06-07T17:00:06.662Z",
      },
      {
        id: "clx4xmbl800cf12vcci52o1lk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "oe067lqfk1",
            locked: 1717779595,
            timestamp: 1717779594,
          },
        ],
        createdAt: "2024-06-07T16:59:56.588Z",
        updatedAt: "2024-06-07T16:59:56.588Z",
      },
      {
        id: "clx4xm99i00ce12vcek5r7m7o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XR0GVUCYeo",
            locked: 1717779592,
            timestamp: 1717779590,
          },
        ],
        createdAt: "2024-06-07T16:59:53.572Z",
        updatedAt: "2024-06-07T16:59:53.572Z",
      },
      {
        id: "clx4xltbq00cd12vcg6l12okw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XInmy04fO4",
            locked: 1717779571,
            timestamp: 1717779564,
          },
        ],
        createdAt: "2024-06-07T16:59:32.917Z",
        updatedAt: "2024-06-07T16:59:32.917Z",
      },
      {
        id: "clx4xlnm000cc12vc7ur83dk2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone14,2; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "wapgGAPZFq",
            locked: 1717779565,
            timestamp: 1717779563,
          },
        ],
        createdAt: "2024-06-07T16:59:25.512Z",
        updatedAt: "2024-06-07T16:59:25.512Z",
      },
      {
        id: "clx4xln0100cb12vcx4d919a6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "LeUz99bB4v",
            locked: 1717779564,
            timestamp: 1717779563,
          },
        ],
        createdAt: "2024-06-07T16:59:24.722Z",
        updatedAt: "2024-06-07T16:59:24.722Z",
      },
      {
        id: "clx4xle1q00ca12vc3a0360qg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone14,2; iOS 17_4_1; en_IN; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "C2JcxPcUwZ",
            locked: 1717779552,
            timestamp: 1717779551,
          },
        ],
        createdAt: "2024-06-07T16:59:13.116Z",
        updatedAt: "2024-06-07T16:59:13.116Z",
      },
      {
        id: "clx4xl4fw00c912vcss06mi1h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "0btPFOgOQE",
            locked: 1717779540,
            timestamp: 1717779538,
          },
        ],
        createdAt: "2024-06-07T16:59:00.669Z",
        updatedAt: "2024-06-07T16:59:00.669Z",
      },
      {
        id: "clx4xl2dz00c812vcque4bxr7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kpKMcIicUl",
            locked: 1717779537,
            timestamp: 1717779530,
          },
        ],
        createdAt: "2024-06-07T16:58:58.006Z",
        updatedAt: "2024-06-07T16:58:58.006Z",
      },
      {
        id: "clx4xkztq00c712vcpy0am2y2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AKT9rRJ4zU",
            locked: 1717779534,
            timestamp: 1717779532,
          },
        ],
        createdAt: "2024-06-07T16:58:54.686Z",
        updatedAt: "2024-06-07T16:58:54.686Z",
      },
      {
        id: "clx4xkyqs00c612vccflkx8cq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "BuOacP5Gs8",
            locked: 1717779532,
            timestamp: 1717779531,
          },
        ],
        createdAt: "2024-06-07T16:58:53.285Z",
        updatedAt: "2024-06-07T16:58:53.285Z",
      },
      {
        id: "clx4xkt7r00c512vcc9kngtrj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qzJpgAbXGe",
            locked: 1717779524,
            timestamp: 1717779521,
          },
        ],
        createdAt: "2024-06-07T16:58:46.119Z",
        updatedAt: "2024-06-07T16:58:46.119Z",
      },
      {
        id: "clx4xkph900c412vchrpilhzb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "cCDMDbxy8s",
            locked: 1717779521,
            timestamp: 1717779520,
          },
        ],
        createdAt: "2024-06-07T16:58:41.276Z",
        updatedAt: "2024-06-07T16:58:41.276Z",
      },
      {
        id: "clx4xkitq00c312vcr5dcrmwe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WQ7jL3NS6M",
            locked: 1717779512,
            timestamp: 1717779510,
          },
        ],
        createdAt: "2024-06-07T16:58:32.654Z",
        updatedAt: "2024-06-07T16:58:32.654Z",
      },
      {
        id: "clx4xke9r00c212vc00u8welh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "04q4rZ9F2r",
            locked: 1717779506,
            timestamp: 1717779505,
          },
        ],
        createdAt: "2024-06-07T16:58:26.752Z",
        updatedAt: "2024-06-07T16:58:26.752Z",
      },
      {
        id: "clx4xkcfp00c112vc9um7vv7d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ciTQ5GfqEK",
            locked: 1717779503,
            timestamp: 1717779496,
          },
        ],
        createdAt: "2024-06-07T16:58:24.373Z",
        updatedAt: "2024-06-07T16:58:24.373Z",
      },
      {
        id: "clx4xk9b500c012vc3el0kj9z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fyl1ivsGyi",
            locked: 1717779499,
            timestamp: 1717779498,
          },
        ],
        createdAt: "2024-06-07T16:58:20.319Z",
        updatedAt: "2024-06-07T16:58:20.319Z",
      },
      {
        id: "clx4xj1hm00bz12vcig48kxa3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3as2nU40Pb",
            locked: 1717779442,
            timestamp: 1717779440,
          },
        ],
        createdAt: "2024-06-07T16:57:23.531Z",
        updatedAt: "2024-06-07T16:57:23.531Z",
      },
      {
        id: "clx4xiyb800by12vc9lsgzq14",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ygKVa50pPJ",
            locked: 1717779438,
            timestamp: 1717779437,
          },
        ],
        createdAt: "2024-06-07T16:57:19.412Z",
        updatedAt: "2024-06-07T16:57:19.412Z",
      },
      {
        id: "clx4xitzy00bx12vc53zq8fq7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FuBIo0RYzh",
            locked: 1717779433,
            timestamp: 1717779428,
          },
        ],
        createdAt: "2024-06-07T16:57:13.819Z",
        updatedAt: "2024-06-07T16:57:13.819Z",
      },
      {
        id: "clx4xihlq00bw12vcnk00ud1n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rwoGXhfp8A",
            locked: 1717779416,
            timestamp: 1717779414,
          },
        ],
        createdAt: "2024-06-07T16:56:57.756Z",
        updatedAt: "2024-06-07T16:56:57.756Z",
      },
      {
        id: "clx4xhibs00bv12vc4bwn879t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6GZmgT0d6R",
            locked: 1717779371,
            timestamp: 1717779370,
          },
        ],
        createdAt: "2024-06-07T16:56:12.038Z",
        updatedAt: "2024-06-07T16:56:12.038Z",
      },
      {
        id: "clx4xh21t00bu12vcl3qg1b02",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "iYmWJPD2Os",
            locked: 1717779350,
            timestamp: 1717779349,
          },
        ],
        createdAt: "2024-06-07T16:55:50.943Z",
        updatedAt: "2024-06-07T16:55:50.943Z",
      },
      {
        id: "clx4xgkgw00bt12vcue971tjn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lsjM4MTO0V",
            locked: 1717779327,
            timestamp: 1717779325,
          },
        ],
        createdAt: "2024-06-07T16:55:28.160Z",
        updatedAt: "2024-06-07T16:55:28.160Z",
      },
      {
        id: "clx4xgk0w00bs12vc06q4195z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "vB4y0pvGcX",
            locked: 1717779327,
            timestamp: 1717779325,
          },
        ],
        createdAt: "2024-06-07T16:55:27.583Z",
        updatedAt: "2024-06-07T16:55:27.583Z",
      },
      {
        id: "clx4xgcg600br12vcwb1vhaq6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "oSobaJ2Oet",
            locked: 1717779317,
            timestamp: 1717779316,
          },
        ],
        createdAt: "2024-06-07T16:55:17.766Z",
        updatedAt: "2024-06-07T16:55:17.766Z",
      },
      {
        id: "clx4xg9mz00bq12vccgernfxc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "MzdbmgDdWr",
            locked: 1717779313,
            timestamp: 1717779312,
          },
        ],
        createdAt: "2024-06-07T16:55:14.123Z",
        updatedAt: "2024-06-07T16:55:14.123Z",
      },
      {
        id: "clx4xg3ry00bp12vclfq65ene",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dYheYAH1fu",
            locked: 1717779303,
            timestamp: 1717779302,
          },
        ],
        createdAt: "2024-06-07T16:55:06.525Z",
        updatedAt: "2024-06-07T16:55:06.525Z",
      },
      {
        id: "clx4xfx7c00bo12vcbmo2v0sx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 450dpi; 1080x2106; samsung; SM-S918U; dm3q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "9RNB9rK0OX",
            locked: 1717779297,
            timestamp: 1717779289,
          },
        ],
        createdAt: "2024-06-07T16:54:58.008Z",
        updatedAt: "2024-06-07T16:54:58.008Z",
      },
      {
        id: "clx4xfv5d00bn12vcfb9d9ldm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21C66 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_2_1; fr_ES; fr; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "9fqeGrbBkj",
            locked: 1717779295,
            timestamp: 1717779294,
          },
        ],
        createdAt: "2024-06-07T16:54:55.345Z",
        updatedAt: "2024-06-07T16:54:55.345Z",
      },
      {
        id: "clx4xfoxt00bm12vc5kgnosrq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "3SlHQ5v80A",
            locked: 1717779287,
            timestamp: 1717779286,
          },
        ],
        createdAt: "2024-06-07T16:54:47.296Z",
        updatedAt: "2024-06-07T16:54:47.296Z",
      },
      {
        id: "clx4xfhfk00bl12vcnjw6kkgo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TpCW0MpMwt",
            locked: 1717779277,
            timestamp: 1717779275,
          },
        ],
        createdAt: "2024-06-07T16:54:37.568Z",
        updatedAt: "2024-06-07T16:54:37.568Z",
      },
      {
        id: "clx4xfgiu00bk12vch36l7twz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2115; samsung; SM-S918U; dm3q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HuZvWDCqhe",
            locked: 1717779276,
            timestamp: 1717779274,
          },
        ],
        createdAt: "2024-06-07T16:54:36.390Z",
        updatedAt: "2024-06-07T16:54:36.390Z",
      },
      {
        id: "clx4xfc5600bj12vc3qh7079t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_GB; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Otu9PqUIAV",
            locked: 1717779270,
            timestamp: 1717779267,
          },
        ],
        createdAt: "2024-06-07T16:54:30.715Z",
        updatedAt: "2024-06-07T16:54:30.715Z",
      },
      {
        id: "clx4xf9m800bi12vclh8oenxw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_GB; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "a1wku58Ggu",
            locked: 1717779267,
            timestamp: 1717779266,
          },
        ],
        createdAt: "2024-06-07T16:54:27.440Z",
        updatedAt: "2024-06-07T16:54:27.440Z",
      },
      {
        id: "clx4xf86100bh12vciz81zltu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_GB; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Yj4dVaiXSI",
            locked: 1717779265,
            timestamp: 1717779264,
          },
        ],
        createdAt: "2024-06-07T16:54:25.560Z",
        updatedAt: "2024-06-07T16:54:25.560Z",
      },
      {
        id: "clx4xf2fh00bg12vc7qxrpbn8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_GB; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "fHZR4nw0Lk",
            locked: 1717779257,
            timestamp: 1717779256,
          },
        ],
        createdAt: "2024-06-07T16:54:18.125Z",
        updatedAt: "2024-06-07T16:54:18.125Z",
      },
      {
        id: "clx4xeze500bf12vc4ts8cy9f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "l37Ares6vL",
            locked: 1717779253,
            timestamp: 1717779251,
          },
        ],
        createdAt: "2024-06-07T16:54:14.189Z",
        updatedAt: "2024-06-07T16:54:14.189Z",
      },
      {
        id: "clx4xez4200be12vck4lcspbx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21C66 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_2_1; fr_ES; fr; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "A4QaQMm4NT",
            locked: 1717779253,
            timestamp: 1717779251,
          },
        ],
        createdAt: "2024-06-07T16:54:13.826Z",
        updatedAt: "2024-06-07T16:54:13.826Z",
      },
      {
        id: "clx4xewkb00bd12vco2g3c6n3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "8wpSvcjjPT",
            locked: 1717779250,
            timestamp: 1717779249,
          },
        ],
        createdAt: "2024-06-07T16:54:10.523Z",
        updatedAt: "2024-06-07T16:54:10.523Z",
      },
      {
        id: "clx4xeu8x00bc12vcmazxpr1g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cVYC24AWS9",
            locked: 1717779247,
            timestamp: 1717779245,
          },
        ],
        createdAt: "2024-06-07T16:54:07.521Z",
        updatedAt: "2024-06-07T16:54:07.521Z",
      },
      {
        id: "clx4xejx400bb12vc61mq2sur",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iBrQLXhTjW",
            locked: 1717779233,
            timestamp: 1717779228,
          },
        ],
        createdAt: "2024-06-07T16:53:54.133Z",
        updatedAt: "2024-06-07T16:53:54.133Z",
      },
      {
        id: "clx4xe07800ba12vckwt1baji",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "y6CMJwUcUC",
            locked: 1717779208,
            timestamp: 1717779206,
          },
        ],
        createdAt: "2024-06-07T16:53:28.580Z",
        updatedAt: "2024-06-07T16:53:28.580Z",
      },
      {
        id: "clx4xe07000b912vc5vgkupi6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/106.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "iKFaF5RYPn",
            locked: 1717779205,
            timestamp: 1717779204,
          },
        ],
        createdAt: "2024-06-07T16:53:28.571Z",
        updatedAt: "2024-06-07T16:53:28.571Z",
      },
      {
        id: "clx4xdxcw00b812vca7udmlz1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "fdqMLCwFD6",
            locked: 1717779203,
            timestamp: 1717779202,
          },
        ],
        createdAt: "2024-06-07T16:53:24.896Z",
        updatedAt: "2024-06-07T16:53:24.896Z",
      },
      {
        id: "clx4xdofz00b712vcfitu516j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "qDV1g0bnL8",
            locked: 1717779193,
            timestamp: 1717779192,
          },
        ],
        createdAt: "2024-06-07T16:53:13.344Z",
        updatedAt: "2024-06-07T16:53:13.344Z",
      },
      {
        id: "clx4xdn5q00b612vc66iu4wbj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "d3k8Z8CEvg",
            locked: 1717779191,
            timestamp: 1717779189,
          },
        ],
        createdAt: "2024-06-07T16:53:11.674Z",
        updatedAt: "2024-06-07T16:53:11.674Z",
      },
      {
        id: "clx4xcu7g00b512vcee7q7vqs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "RjTsx46EIQ",
            locked: 1717779153,
            timestamp: 1717779152,
          },
        ],
        createdAt: "2024-06-07T16:52:34.156Z",
        updatedAt: "2024-06-07T16:52:34.156Z",
      },
      {
        id: "clx4xcrap00b412vcm0pdbv2f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dbU8hk0rgL",
            locked: 1717779149,
            timestamp: 1717779148,
          },
        ],
        createdAt: "2024-06-07T16:52:30.382Z",
        updatedAt: "2024-06-07T16:52:30.382Z",
      },
      {
        id: "clx4xc7fo00b312vcjhje2yvi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 331.1.3.29.90 (iPhone14,7; iOS 17_5_1; es_MX; es; scale=3.00; 1170x2532; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "mvVOSnv0np",
            locked: 1717779124,
            timestamp: 1717779123,
          },
        ],
        createdAt: "2024-06-07T16:52:04.644Z",
        updatedAt: "2024-06-07T16:52:04.644Z",
      },
      {
        id: "clx4xc6r600b212vcfpil0a4w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "s8gwuVAZIg",
            locked: 1717779123,
            timestamp: 1717779121,
          },
        ],
        createdAt: "2024-06-07T16:52:03.763Z",
        updatedAt: "2024-06-07T16:52:03.763Z",
      },
      {
        id: "clx4xc5ks00b112vcsonneut7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 331.1.3.29.90 (iPhone14,7; iOS 17_5_1; es_MX; es; scale=3.00; 1170x2532; 599297333)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hm1crZtSph",
            locked: 1717779121,
            timestamp: 1717779120,
          },
        ],
        createdAt: "2024-06-07T16:52:02.237Z",
        updatedAt: "2024-06-07T16:52:02.237Z",
      },
      {
        id: "clx4xc26000b012vcdavyjkce",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/120.0.6099.119 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "WSSJKY1cWe",
            locked: 1717779117,
            timestamp: 1717779115,
          },
        ],
        createdAt: "2024-06-07T16:51:57.815Z",
        updatedAt: "2024-06-07T16:51:57.815Z",
      },
      {
        id: "clx4xbv7x00az12vcgozi0l3u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "0edqaWI7D0",
            locked: 1717779108,
            timestamp: 1717779107,
          },
        ],
        createdAt: "2024-06-07T16:51:48.814Z",
        updatedAt: "2024-06-07T16:51:48.814Z",
      },
      {
        id: "clx4xbn2z00ay12vcbpyimfng",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "dgT0U3ItaE",
            locked: 1717779098,
            timestamp: 1717779096,
          },
        ],
        createdAt: "2024-06-07T16:51:38.265Z",
        updatedAt: "2024-06-07T16:51:38.265Z",
      },
      {
        id: "clx4xashu00ax12vctev2b6wy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "X7hzp5L8HJ",
            locked: 1717779058,
            timestamp: 1717779057,
          },
        ],
        createdAt: "2024-06-07T16:50:58.626Z",
        updatedAt: "2024-06-07T16:50:58.626Z",
      },
      {
        id: "clx4xasbn00aw12vcdmei8098",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "51U2wh3KxV",
            locked: 1717779058,
            timestamp: 1717779057,
          },
        ],
        createdAt: "2024-06-07T16:50:58.403Z",
        updatedAt: "2024-06-07T16:50:58.403Z",
      },
      {
        id: "clx4xaoqo00av12vctcb1yl9s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wuOCx0E1B4",
            locked: 1717779053,
            timestamp: 1717779051,
          },
        ],
        createdAt: "2024-06-07T16:50:53.760Z",
        updatedAt: "2024-06-07T16:50:53.760Z",
      },
      {
        id: "clx4xanz900au12vc1xzrcezq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QVRf1z4IHW",
            locked: 1717779050,
            timestamp: 1717779049,
          },
        ],
        createdAt: "2024-06-07T16:50:52.770Z",
        updatedAt: "2024-06-07T16:50:52.770Z",
      },
      {
        id: "clx4xa35e00at12vctodp4x5o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20E252 Instagram 334.0.4.32.98 (iPhone11,2; iOS 16_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "U4OJLv92vQ",
            locked: 1717779025,
            timestamp: 1717779024,
          },
        ],
        createdAt: "2024-06-07T16:50:25.777Z",
        updatedAt: "2024-06-07T16:50:25.777Z",
      },
      {
        id: "clx4xa1bc00as12vc7pf5wixy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6.6 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "LEkNx6jP6L",
            locked: 1717779022,
            timestamp: 1717779018,
          },
        ],
        createdAt: "2024-06-07T16:50:23.400Z",
        updatedAt: "2024-06-07T16:50:23.400Z",
      },
      {
        id: "clx4x9vu400ar12vcwaega7xp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20E252 Instagram 334.0.4.32.98 (iPhone11,2; iOS 16_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "29B3EbTGZX",
            locked: 1717779015,
            timestamp: 1717779014,
          },
        ],
        createdAt: "2024-06-07T16:50:16.299Z",
        updatedAt: "2024-06-07T16:50:16.299Z",
      },
      {
        id: "clx4x9u6q00aq12vcqb9qe52c",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "M38MCRLVCJ",
            locked: 1717779013,
            timestamp: 1717779012,
          },
        ],
        createdAt: "2024-06-07T16:50:14.162Z",
        updatedAt: "2024-06-07T16:50:14.162Z",
      },
      {
        id: "clx4x9t1q00ap12vcf2zna2qn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "NP2h6aYe5E",
            locked: 1717779012,
            timestamp: 1717779011,
          },
        ],
        createdAt: "2024-06-07T16:50:12.686Z",
        updatedAt: "2024-06-07T16:50:12.686Z",
      },
      {
        id: "clx4x9rqm00ao12vcckduo1qw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SOotULNZIt",
            locked: 1717779010,
            timestamp: 1717779009,
          },
        ],
        createdAt: "2024-06-07T16:50:10.990Z",
        updatedAt: "2024-06-07T16:50:10.990Z",
      },
      {
        id: "clx4x9nqk00an12vc7bvw57lt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "x62U5Xjkxa",
            locked: 1717779004,
            timestamp: 1717779002,
          },
        ],
        createdAt: "2024-06-07T16:50:05.804Z",
        updatedAt: "2024-06-07T16:50:05.804Z",
      },
      {
        id: "clx4x9iqz00am12vcjwfrkurn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jD6yXMZqYS",
            locked: 1717778998,
            timestamp: 1717778997,
          },
        ],
        createdAt: "2024-06-07T16:49:59.336Z",
        updatedAt: "2024-06-07T16:49:59.336Z",
      },
      {
        id: "clx4x94li00al12vcn5v9a9g4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "AVqSqaNUQK",
            locked: 1717778980,
            timestamp: 1717778979,
          },
        ],
        createdAt: "2024-06-07T16:49:40.996Z",
        updatedAt: "2024-06-07T16:49:40.996Z",
      },
      {
        id: "clx4x8wqd00ak12vc7r9w5vkf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "BgQvBNXlcj",
            locked: 1717778969,
            timestamp: 1717778961,
          },
        ],
        createdAt: "2024-06-07T16:49:30.805Z",
        updatedAt: "2024-06-07T16:49:30.805Z",
      },
      {
        id: "clx4x8pg700aj12vcsyq8fv5d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Zrmpm1rx1x",
            locked: 1717778961,
            timestamp: 1717778960,
          },
        ],
        createdAt: "2024-06-07T16:49:21.366Z",
        updatedAt: "2024-06-07T16:49:21.366Z",
      },
      {
        id: "clx4x8kpp00ai12vc5shh9ck6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RFhoFRz2L6",
            locked: 1717778955,
            timestamp: 1717778953,
          },
        ],
        createdAt: "2024-06-07T16:49:15.229Z",
        updatedAt: "2024-06-07T16:49:15.229Z",
      },
      {
        id: "clx4x8i8t00ah12vcmtsrm3v0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Ak4Nt0tBOk",
            locked: 1717778951,
            timestamp: 1717778950,
          },
        ],
        createdAt: "2024-06-07T16:49:12.030Z",
        updatedAt: "2024-06-07T16:49:12.030Z",
      },
      {
        id: "clx4x8bbt00ag12vc21sskpx7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 324.0.4.26.52 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 580117525)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "b4k0KYfNFh",
            locked: 1717778942,
            timestamp: 1717778941,
          },
        ],
        createdAt: "2024-06-07T16:49:03.062Z",
        updatedAt: "2024-06-07T16:49:03.062Z",
      },
      {
        id: "clx4x7xjk00af12vco5fzbgdl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "2YSRvb0QcD",
            locked: 1717778924,
            timestamp: 1717778916,
          },
        ],
        createdAt: "2024-06-07T16:48:45.201Z",
        updatedAt: "2024-06-07T16:48:45.201Z",
      },
      {
        id: "clx4x7pl200ae12vc10ow8gmi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gtKrKlkssZ",
            locked: 1717778914,
            timestamp: 1717778912,
          },
        ],
        createdAt: "2024-06-07T16:48:34.884Z",
        updatedAt: "2024-06-07T16:48:34.884Z",
      },
      {
        id: "clx4x7a6i00ad12vcj946m2b4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Sh71uGtBAn",
            locked: 1717778894,
            timestamp: 1717778893,
          },
        ],
        createdAt: "2024-06-07T16:48:14.921Z",
        updatedAt: "2024-06-07T16:48:14.921Z",
      },
      {
        id: "clx4x76kw00ac12vc27mi3t7b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nidX6oOUri",
            locked: 1717778889,
            timestamp: 1717778887,
          },
        ],
        createdAt: "2024-06-07T16:48:10.257Z",
        updatedAt: "2024-06-07T16:48:10.257Z",
      },
      {
        id: "clx4x6xao00ab12vcu05ons0h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 334.0.4.32.98 (iPhone13,2; iOS 15_6_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "I1SF3jOAE7",
            locked: 1717778878,
            timestamp: 1717778877,
          },
        ],
        createdAt: "2024-06-07T16:47:58.224Z",
        updatedAt: "2024-06-07T16:47:58.224Z",
      },
      {
        id: "clx4x6vcj00aa12vcdh9cwvq3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.4951.67 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Man6sF06BM",
            locked: 1717778873,
            timestamp: 1717778867,
          },
        ],
        createdAt: "2024-06-07T16:47:55.696Z",
        updatedAt: "2024-06-07T16:47:55.696Z",
      },
      {
        id: "clx4x6l5d00a912vcbji99j2e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 Instagram 334.0.4.32.98 (iPhone13,2; iOS 15_6_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "TsMOVvFCNC",
            locked: 1717778862,
            timestamp: 1717778859,
          },
        ],
        createdAt: "2024-06-07T16:47:42.481Z",
        updatedAt: "2024-06-07T16:47:42.481Z",
      },
      {
        id: "clx4x6gpj00a812vcgwfuy71n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G998B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2283; samsung; SM-G998B; p3s; exynos2100; en_GB; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "XvQmtuYPZB",
            locked: 1717778856,
            timestamp: 1717778854,
          },
        ],
        createdAt: "2024-06-07T16:47:36.727Z",
        updatedAt: "2024-06-07T16:47:36.727Z",
      },
      {
        id: "clx4x6f9t00a712vc78i9vdru",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3qLnPbQovB",
            locked: 1717778854,
            timestamp: 1717778853,
          },
        ],
        createdAt: "2024-06-07T16:47:34.865Z",
        updatedAt: "2024-06-07T16:47:34.865Z",
      },
      {
        id: "clx4x6f1u00a612vc0r6nmqhs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "D0CyhGmEBM",
            locked: 1717778856,
            timestamp: 1717778855,
          },
        ],
        createdAt: "2024-06-07T16:47:34.578Z",
        updatedAt: "2024-06-07T16:47:34.578Z",
      },
      {
        id: "clx4x6emq00a512vcuu8yws5z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Vik3GMhCub",
            locked: 1717778855,
            timestamp: 1717778853,
          },
        ],
        createdAt: "2024-06-07T16:47:34.034Z",
        updatedAt: "2024-06-07T16:47:34.034Z",
      },
      {
        id: "clx4x6ayt00a412vcobqse1aa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G998B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2283; samsung; SM-G998B; p3s; exynos2100; en_GB; 606558434)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tEHmEySGlp",
            locked: 1717778848,
            timestamp: 1717778844,
          },
        ],
        createdAt: "2024-06-07T16:47:29.283Z",
        updatedAt: "2024-06-07T16:47:29.283Z",
      },
      {
        id: "clx4x5z6v00a312vcxbn36nnk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HrFhUGlmfB",
            locked: 1717778833,
            timestamp: 1717778831,
          },
        ],
        createdAt: "2024-06-07T16:47:14.023Z",
        updatedAt: "2024-06-07T16:47:14.023Z",
      },
      {
        id: "clx4x5vlm00a212vc8bw1ckku",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "F2DTk0k0nh",
            locked: 1717778829,
            timestamp: 1717778828,
          },
        ],
        createdAt: "2024-06-07T16:47:09.369Z",
        updatedAt: "2024-06-07T16:47:09.369Z",
      },
      {
        id: "clx4x5qg300a112vcs2mcs1hn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pmovkKVhzx",
            locked: 1717778822,
            timestamp: 1717778820,
          },
        ],
        createdAt: "2024-06-07T16:47:02.691Z",
        updatedAt: "2024-06-07T16:47:02.691Z",
      },
      {
        id: "clx4x5g3800a012vc3dltkoi2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4OvQwo0aq8",
            locked: 1717778807,
            timestamp: 1717778806,
          },
        ],
        createdAt: "2024-06-07T16:46:49.265Z",
        updatedAt: "2024-06-07T16:46:49.265Z",
      },
      {
        id: "clx4x4u2r009z12vcdkd5pdoa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "8tdDkB43d8",
            locked: 1717778778,
            timestamp: 1717778771,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Nn8fZw9Ldi",
            locked: 1717778778,
            timestamp: 1717778775,
          },
        ],
        createdAt: "2024-06-07T16:46:20.739Z",
        updatedAt: "2024-06-07T16:46:20.739Z",
      },
      {
        id: "clx4x4p49009y12vcz9wckz7r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S916U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2131; samsung; SM-S916U; dm2q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8tAn1jFv24",
            locked: 1717778773,
            timestamp: 1717778772,
          },
        ],
        createdAt: "2024-06-07T16:46:14.310Z",
        updatedAt: "2024-06-07T16:46:14.310Z",
      },
      {
        id: "clx4x3cnd009x12vc2na8gpls",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "6lfRBWYUJH",
            locked: 1717778711,
            timestamp: 1717778710,
          },
        ],
        createdAt: "2024-06-07T16:45:11.498Z",
        updatedAt: "2024-06-07T16:45:11.498Z",
      },
      {
        id: "clx4x3at6009w12vcksis8my3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "7UgTtRedjR",
            locked: 1717778709,
            timestamp: 1717778708,
          },
        ],
        createdAt: "2024-06-07T16:45:09.114Z",
        updatedAt: "2024-06-07T16:45:09.114Z",
      },
      {
        id: "clx4x38gd009v12vc8q2rdhlv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "9f8mwQEKMJ",
            locked: 1717778705,
            timestamp: 1717778704,
          },
        ],
        createdAt: "2024-06-07T16:45:06.059Z",
        updatedAt: "2024-06-07T16:45:06.059Z",
      },
      {
        id: "clx4x2xa3009u12vccc69c13z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad7,11; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711) NW/1",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AljRveciSS",
            locked: 1717778691,
            timestamp: 1717778687,
          },
        ],
        createdAt: "2024-06-07T16:44:51.580Z",
        updatedAt: "2024-06-07T16:44:51.580Z",
      },
      {
        id: "clx4x2vjc009t12vcmw1wohal",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qIjs7uxqVt",
            locked: 1717778689,
            timestamp: 1717778686,
          },
        ],
        createdAt: "2024-06-07T16:44:49.316Z",
        updatedAt: "2024-06-07T16:44:49.316Z",
      },
      {
        id: "clx4x2pi8009s12vcflpy1rd0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QSJhPrITsy",
            locked: 1717778681,
            timestamp: 1717778679,
          },
        ],
        createdAt: "2024-06-07T16:44:41.505Z",
        updatedAt: "2024-06-07T16:44:41.505Z",
      },
      {
        id: "clx4x2h4c009r12vch01yrplc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPad7,11; iPadOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "oyUw9h4DTx",
            locked: 1717778669,
            timestamp: 1717778665,
          },
        ],
        createdAt: "2024-06-07T16:44:30.634Z",
        updatedAt: "2024-06-07T16:44:30.634Z",
      },
      {
        id: "clx4x2570009q12vc4izyouj0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "BgzeCceF4Q",
            locked: 1717778654,
            timestamp: 1717778652,
          },
        ],
        createdAt: "2024-06-07T16:44:15.180Z",
        updatedAt: "2024-06-07T16:44:15.180Z",
      },
      {
        id: "clx4x1zku009p12vc00a8dodx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "lVeCYCB0B3",
            locked: 1717778647,
            timestamp: 1717778645,
          },
        ],
        createdAt: "2024-06-07T16:44:07.901Z",
        updatedAt: "2024-06-07T16:44:07.901Z",
      },
      {
        id: "clx4x1s82009o12vchu79oxy6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "d5h1ZoKZhB",
            locked: 1717778638,
            timestamp: 1717778637,
          },
        ],
        createdAt: "2024-06-07T16:43:58.371Z",
        updatedAt: "2024-06-07T16:43:58.371Z",
      },
      {
        id: "clx4x1lj5009n12vc1vcq2rr5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "z4bVcC5t6r",
            locked: 1717778629,
            timestamp: 1717778628,
          },
        ],
        createdAt: "2024-06-07T16:43:49.697Z",
        updatedAt: "2024-06-07T16:43:49.697Z",
      },
      {
        id: "clx4x1i29009m12vco7aqzns5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kt3p6Co99l",
            locked: 1717778624,
            timestamp: 1717778623,
          },
        ],
        createdAt: "2024-06-07T16:43:45.199Z",
        updatedAt: "2024-06-07T16:43:45.199Z",
      },
      {
        id: "clx4x0obc009l12vcg7h18r8a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 600349675) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ns9xBZpu55",
            locked: 1717778586,
            timestamp: 1717778583,
          },
        ],
        createdAt: "2024-06-07T16:43:06.648Z",
        updatedAt: "2024-06-07T16:43:06.648Z",
      },
      {
        id: "clx4x0klj009k12vcna20w567",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "oQ4jNZeHps",
            locked: 1717778581,
            timestamp: 1717778580,
          },
        ],
        createdAt: "2024-06-07T16:43:01.831Z",
        updatedAt: "2024-06-07T16:43:01.831Z",
      },
      {
        id: "clx4x0d7x009j12vcx6p487pe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "NapiBQEnoA",
            locked: 1717778571,
            timestamp: 1717778570,
          },
        ],
        createdAt: "2024-06-07T16:42:52.268Z",
        updatedAt: "2024-06-07T16:42:52.268Z",
      },
      {
        id: "clx4wz8rk009i12vclyg0z5zj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ugM0HtJnxr",
            locked: 1717778519,
            timestamp: 1717778518,
          },
        ],
        createdAt: "2024-06-07T16:41:59.840Z",
        updatedAt: "2024-06-07T16:41:59.840Z",
      },
      {
        id: "clx4wz55l009h12vcijzzsyyh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "mLZnDlKg3W",
            locked: 1717778515,
            timestamp: 1717778514,
          },
        ],
        createdAt: "2024-06-07T16:41:55.161Z",
        updatedAt: "2024-06-07T16:41:55.161Z",
      },
      {
        id: "clx4wyyjw009g12vcb6sgwba6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1TsPL8Vq6I",
            locked: 1717778506,
            timestamp: 1717778505,
          },
        ],
        createdAt: "2024-06-07T16:41:46.602Z",
        updatedAt: "2024-06-07T16:41:46.602Z",
      },
      {
        id: "clx4wyeew009f12vczrykfero",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "DyUyPtGgXb",
            locked: 1717778480,
            timestamp: 1717778479,
          },
        ],
        createdAt: "2024-06-07T16:41:20.504Z",
        updatedAt: "2024-06-07T16:41:20.504Z",
      },
      {
        id: "clx4wyee1009e12vclnlc5fp1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "a3wZKxegNb",
            locked: 1717778480,
            timestamp: 1717778478,
          },
        ],
        createdAt: "2024-06-07T16:41:20.471Z",
        updatedAt: "2024-06-07T16:41:20.471Z",
      },
      {
        id: "clx4wy6ay009d12vcbg4us51p",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bHA248ci6j",
            locked: 1717778469,
            timestamp: 1717778467,
          },
        ],
        createdAt: "2024-06-07T16:41:09.995Z",
        updatedAt: "2024-06-07T16:41:09.995Z",
      },
      {
        id: "clx4wy2fk009c12vcajjlmmtx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "sU889LmC2m",
            locked: 1717778464,
            timestamp: 1717778461,
          },
        ],
        createdAt: "2024-06-07T16:41:04.974Z",
        updatedAt: "2024-06-07T16:41:04.974Z",
      },
      {
        id: "clx4wx64e009b12vcrpp5yc9d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HeDsIH5rkf",
            locked: 1717778422,
            timestamp: 1717778421,
          },
        ],
        createdAt: "2024-06-07T16:40:23.102Z",
        updatedAt: "2024-06-07T16:40:23.102Z",
      },
      {
        id: "clx4wx565009a12vckxvxw5w3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-G990B; r9q; qcom; en_GB; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "8uWgrkLJyb",
            locked: 1717778422,
            timestamp: 1717778421,
          },
        ],
        createdAt: "2024-06-07T16:40:21.869Z",
        updatedAt: "2024-06-07T16:40:21.869Z",
      },
      {
        id: "clx4wx3t3009912vcxga20fpp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G990B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2097; samsung; SM-G990B; r9q; qcom; en_GB; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LFTITnjseB",
            locked: 1717778420,
            timestamp: 1717778417,
          },
        ],
        createdAt: "2024-06-07T16:40:20.100Z",
        updatedAt: "2024-06-07T16:40:20.100Z",
      },
      {
        id: "clx4wwhte009812vcjb3br4kq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PC59hGwCbZ",
            locked: 1717778304,
            timestamp: 1717778303,
          },
        ],
        createdAt: "2024-06-07T16:39:51.601Z",
        updatedAt: "2024-06-07T16:39:51.601Z",
      },
      {
        id: "clx4ww7ne009712vcb4lmtd8k",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "UuosFCBg2f",
            locked: 1717778378,
            timestamp: 1717778377,
          },
        ],
        createdAt: "2024-06-07T16:39:38.426Z",
        updatedAt: "2024-06-07T16:39:38.426Z",
      },
      {
        id: "clx4wvyvt009612vc1ce94529",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "dQNPLPEuAl",
            locked: 1717778366,
            timestamp: 1717778365,
          },
        ],
        createdAt: "2024-06-07T16:39:27.066Z",
        updatedAt: "2024-06-07T16:39:27.066Z",
      },
      {
        id: "clx4wvyqo009512vccylnzhcm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "M6BOVwMGLV",
            locked: 1717778304,
            timestamp: 1717778303,
          },
        ],
        createdAt: "2024-06-07T16:39:26.879Z",
        updatedAt: "2024-06-07T16:39:26.879Z",
      },
      {
        id: "clx4wvwum009412vc8jkex27l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_4_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "L99mvG4nZf",
            locked: 1717778363,
            timestamp: 1717778362,
          },
        ],
        createdAt: "2024-06-07T16:39:24.430Z",
        updatedAt: "2024-06-07T16:39:24.430Z",
      },
      {
        id: "clx4wvmdb009312vcl8r65neo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Ie9FsFcQS1",
            locked: 1717778350,
            timestamp: 1717778349,
          },
        ],
        createdAt: "2024-06-07T16:39:10.846Z",
        updatedAt: "2024-06-07T16:39:10.846Z",
      },
      {
        id: "clx4wvka4009212vcgvp6yqpo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "afzXGZW9bg",
            locked: 1717778347,
            timestamp: 1717778346,
          },
        ],
        createdAt: "2024-06-07T16:39:08.141Z",
        updatedAt: "2024-06-07T16:39:08.141Z",
      },
      {
        id: "clx4wvk84009112vc7qztc6kk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "5gxfLmExP1",
            locked: 1717778304,
            timestamp: 1717778303,
          },
        ],
        createdAt: "2024-06-07T16:39:08.068Z",
        updatedAt: "2024-06-07T16:39:08.068Z",
      },
      {
        id: "clx4wvj1s009012vcocgllwtg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "ITEpBPx4H7",
            locked: 1717778346,
            timestamp: 1717778345,
          },
        ],
        createdAt: "2024-06-07T16:39:06.545Z",
        updatedAt: "2024-06-07T16:39:06.545Z",
      },
      {
        id: "clx4wvdka008z12vchd4iu4ny",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "IXoLE6GgJr",
            locked: 1717778304,
            timestamp: 1717778303,
          },
        ],
        createdAt: "2024-06-07T16:38:59.434Z",
        updatedAt: "2024-06-07T16:38:59.434Z",
      },
      {
        id: "clx4wvch4008y12vcht5vmkna",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "sfD4wYXIuq",
            locked: 1717778337,
            timestamp: 1717778335,
          },
        ],
        createdAt: "2024-06-07T16:38:58.024Z",
        updatedAt: "2024-06-07T16:38:58.024Z",
      },
      {
        id: "clx4wv91i008x12vcs8egvqcs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ox0ZaU8G25",
            locked: 1717778304,
            timestamp: 1717778303,
          },
        ],
        createdAt: "2024-06-07T16:38:53.572Z",
        updatedAt: "2024-06-07T16:38:53.572Z",
      },
      {
        id: "clx4wur8q008w12vcqgjqe53q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "AUgbQWQcpM",
            locked: 1717778309,
            timestamp: 1717778308,
          },
        ],
        createdAt: "2024-06-07T16:38:30.506Z",
        updatedAt: "2024-06-07T16:38:30.506Z",
      },
      {
        id: "clx4wuqlb008v12vcn40cntzp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MHdccUKa8t",
            locked: 1717778308,
            timestamp: 1717778307,
          },
        ],
        createdAt: "2024-06-07T16:38:29.662Z",
        updatedAt: "2024-06-07T16:38:29.662Z",
      },
      {
        id: "clx4wumsv008u12vcitjkyg9e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S901B; r0s; s5e9925; sl_SI; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "RvrBwIR14Q",
            locked: 1717778300,
            timestamp: 1717778299,
          },
        ],
        createdAt: "2024-06-07T16:38:24.751Z",
        updatedAt: "2024-06-07T16:38:24.751Z",
      },
      {
        id: "clx4wughu008t12vclez3dsgm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.3 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "LlNTaN4Xge",
            locked: 1717778296,
            timestamp: 1717778294,
          },
        ],
        createdAt: "2024-06-07T16:38:16.579Z",
        updatedAt: "2024-06-07T16:38:16.579Z",
      },
      {
        id: "clx4wufq1008s12vcx336t9r7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ox3SiuC5Nu",
            locked: 1717778294,
            timestamp: 1717778293,
          },
        ],
        createdAt: "2024-06-07T16:38:15.577Z",
        updatedAt: "2024-06-07T16:38:15.577Z",
      },
      {
        id: "clx4wufhd008r12vc35v1ouvz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S901B; r0s; s5e9925; sl_SI; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Oq2COLQxDe",
            locked: 1717778291,
            timestamp: 1717778287,
          },
        ],
        createdAt: "2024-06-07T16:38:15.265Z",
        updatedAt: "2024-06-07T16:38:15.265Z",
      },
      {
        id: "clx4wuch6008q12vcezty9fan",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/92.0.4512.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CAt5Vm3VUA",
            locked: 1717778291,
            timestamp: 1717778288,
          },
        ],
        createdAt: "2024-06-07T16:38:11.370Z",
        updatedAt: "2024-06-07T16:38:11.370Z",
      },
      {
        id: "clx4wuc4s008p12vc3cjyn8th",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/92.0.4512.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "aK7iCNnj4G",
            locked: 1717778290,
            timestamp: 1717778288,
          },
        ],
        createdAt: "2024-06-07T16:38:10.923Z",
        updatedAt: "2024-06-07T16:38:10.923Z",
      },
      {
        id: "clx4wu8q7008o12vch1r5t5l1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "5rOqK0PmRu",
            locked: 1717778286,
            timestamp: 1717778285,
          },
        ],
        createdAt: "2024-06-07T16:38:06.511Z",
        updatedAt: "2024-06-07T16:38:06.511Z",
      },
      {
        id: "clx4wu75b008n12vcczi2vg2h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 324.0.4.26.52 (iPhone15,4; iOS 17_4_1; en_CA; en; scale=3.00; 1179x2556; 580117525)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "AeBUEbrDv5",
            locked: 1717778284,
            timestamp: 1717778282,
          },
        ],
        createdAt: "2024-06-07T16:38:04.464Z",
        updatedAt: "2024-06-07T16:38:04.464Z",
      },
      {
        id: "clx4wu5i8008m12vc31dooamv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 324.0.4.26.52 (iPhone15,4; iOS 17_4_1; en_CA; en; scale=3.00; 1179x2556; 580117525)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fjimxeensT",
            locked: 1717778281,
            timestamp: 1717778280,
          },
        ],
        createdAt: "2024-06-07T16:38:02.337Z",
        updatedAt: "2024-06-07T16:38:02.337Z",
      },
      {
        id: "clx4wu3x4008l12vclg326724",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "clcoponAvN",
            locked: 1717778279,
            timestamp: 1717778277,
          },
        ],
        createdAt: "2024-06-07T16:38:00.281Z",
        updatedAt: "2024-06-07T16:38:00.281Z",
      },
      {
        id: "clx4wu203008k12vcj2las8p4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vSJx3XzEvy",
            locked: 1717778276,
            timestamp: 1717778273,
          },
        ],
        createdAt: "2024-06-07T16:37:57.796Z",
        updatedAt: "2024-06-07T16:37:57.796Z",
      },
      {
        id: "clx4wu139008j12vc0mts1p4w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "3CewIV4swC",
            locked: 1717778276,
            timestamp: 1717778275,
          },
        ],
        createdAt: "2024-06-07T16:37:56.614Z",
        updatedAt: "2024-06-07T16:37:56.614Z",
      },
      {
        id: "clx4wtzr3008i12vc69zqqvyq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LSBA2lVILt",
            locked: 1717778274,
            timestamp: 1717778272,
          },
        ],
        createdAt: "2024-06-07T16:37:54.879Z",
        updatedAt: "2024-06-07T16:37:54.879Z",
      },
      {
        id: "clx4wtzib008h12vccne33zwd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.3 Safari/605.1.15",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "dhEpHrCA9G",
            locked: 1717778274,
            timestamp: 1717778271,
          },
        ],
        createdAt: "2024-06-07T16:37:54.563Z",
        updatedAt: "2024-06-07T16:37:54.563Z",
      },
      {
        id: "clx4wtx8p008g12vcghyt2xx0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tMfakK4c37",
            locked: 1717778268,
            timestamp: 1717778266,
          },
        ],
        createdAt: "2024-06-07T16:37:51.624Z",
        updatedAt: "2024-06-07T16:37:51.624Z",
      },
      {
        id: "clx4wtw52008f12vcasmzj9i8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SCs0Zj0fAh",
            locked: 1717778269,
            timestamp: 1717778268,
          },
        ],
        createdAt: "2024-06-07T16:37:50.198Z",
        updatedAt: "2024-06-07T16:37:50.198Z",
      },
      {
        id: "clx4wtn14008e12vcfar86mr9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "AZIrMZE3Gp",
            locked: 1717778255,
            timestamp: 1717778254,
          },
        ],
        createdAt: "2024-06-07T16:37:38.392Z",
        updatedAt: "2024-06-07T16:37:38.392Z",
      },
      {
        id: "clx4wtkvj008d12vc2dqc59ix",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.2 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VgBVX8bFQT",
            locked: 1717778255,
            timestamp: 1717778253,
          },
        ],
        createdAt: "2024-06-07T16:37:35.597Z",
        updatedAt: "2024-06-07T16:37:35.597Z",
      },
      {
        id: "clx4wt1gb008c12vc38nibcuh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "npvLSVw4MM",
            locked: 1717778230,
            timestamp: 1717778228,
          },
        ],
        createdAt: "2024-06-07T16:37:10.427Z",
        updatedAt: "2024-06-07T16:37:10.427Z",
      },
      {
        id: "clx4wsx2m008b12vcj2zbsacv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Nyxl6VWVJG",
            locked: 1717778224,
            timestamp: 1717778222,
          },
        ],
        createdAt: "2024-06-07T16:37:04.751Z",
        updatedAt: "2024-06-07T16:37:04.751Z",
      },
      {
        id: "clx4wsvl4008a12vcc6kqv5p0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "qMiUvotVwQ",
            locked: 1717778223,
            timestamp: 1717778220,
          },
        ],
        createdAt: "2024-06-07T16:37:02.824Z",
        updatedAt: "2024-06-07T16:37:02.824Z",
      },
      {
        id: "clx4wsrrr008912vczq36c0e0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "iI7JWL4jMM",
            locked: 1717778218,
            timestamp: 1717778216,
          },
        ],
        createdAt: "2024-06-07T16:36:57.876Z",
        updatedAt: "2024-06-07T16:36:57.876Z",
      },
      {
        id: "clx4wsddj008712vcsl8oapoh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pGjlxYgQy8",
            locked: 1717778196,
            timestamp: 1717778193,
          },
        ],
        createdAt: "2024-06-07T16:36:39.221Z",
        updatedAt: "2024-06-07T16:36:39.221Z",
      },
      {
        id: "clx4wsdhw008812vc7it9mpa6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "uTHV5RcnhV",
            locked: 1717778198,
            timestamp: 1717778197,
          },
        ],
        createdAt: "2024-06-07T16:36:39.221Z",
        updatedAt: "2024-06-07T16:36:39.221Z",
      },
      {
        id: "clx4wrksw008612vczsmg9sh4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "gral3JsrFZ",
            locked: 1717778162,
            timestamp: 1717778161,
          },
        ],
        createdAt: "2024-06-07T16:36:02.193Z",
        updatedAt: "2024-06-07T16:36:02.193Z",
      },
      {
        id: "clx4wrhva008512vcernturdv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "s4Ty1LWJHY",
            locked: 1717778158,
            timestamp: 1717778155,
          },
        ],
        createdAt: "2024-06-07T16:35:58.387Z",
        updatedAt: "2024-06-07T16:35:58.387Z",
      },
      {
        id: "clx4wr6w6008412vc7x9yt0po",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "axfYjbsag4",
            locked: 1717778142,
            timestamp: 1717778136,
          },
        ],
        createdAt: "2024-06-07T16:35:44.166Z",
        updatedAt: "2024-06-07T16:35:44.166Z",
      },
      {
        id: "clx4wqwtj008312vcg0a0ge3n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "laO0RqN9Jx",
            locked: 1717778130,
            timestamp: 1717778128,
          },
        ],
        createdAt: "2024-06-07T16:35:31.108Z",
        updatedAt: "2024-06-07T16:35:31.108Z",
      },
      {
        id: "clx4wqe9k008212vcvf5u7113",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qhfKVEtvSo",
            locked: 1717778103,
            timestamp: 1717778102,
          },
        ],
        createdAt: "2024-06-07T16:35:07.064Z",
        updatedAt: "2024-06-07T16:35:07.064Z",
      },
      {
        id: "clx4wqajc008112vcnxrzwuoq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "l5rGJOmFCY",
            locked: 1717778102,
            timestamp: 1717778101,
          },
        ],
        createdAt: "2024-06-07T16:35:02.232Z",
        updatedAt: "2024-06-07T16:35:02.232Z",
      },
      {
        id: "clx4wq6zi008012vcp2a8o3gh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "85hCxozcEE",
            locked: 1717778097,
            timestamp: 1717778095,
          },
        ],
        createdAt: "2024-06-07T16:34:57.627Z",
        updatedAt: "2024-06-07T16:34:57.627Z",
      },
      {
        id: "clx4wpojn007z12vc47t4ipvk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.5060.134 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "171qLoj79P",
            locked: 1717778066,
            timestamp: 1717778053,
          },
        ],
        createdAt: "2024-06-07T16:34:33.729Z",
        updatedAt: "2024-06-07T16:34:33.729Z",
      },
      {
        id: "clx4wp4qt007y12vc0ojwbtfh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "RYNFweJsyG",
            locked: 1717778047,
            timestamp: 1717778046,
          },
        ],
        createdAt: "2024-06-07T16:34:08.068Z",
        updatedAt: "2024-06-07T16:34:08.068Z",
      },
      {
        id: "clx4wp246007x12vcom4wnyq6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SllsF3fpRk",
            locked: 1717778043,
            timestamp: 1717778042,
          },
        ],
        createdAt: "2024-06-07T16:34:04.662Z",
        updatedAt: "2024-06-07T16:34:04.662Z",
      },
      {
        id: "clx4woqsc007w12vcyvqevxvu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "FC26nBNAph",
            locked: 1717778029,
            timestamp: 1717778028,
          },
        ],
        createdAt: "2024-06-07T16:33:49.978Z",
        updatedAt: "2024-06-07T16:33:49.978Z",
      },
      {
        id: "clx4wogsp007v12vcbp5ic2zh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "OIykLkHUV6",
            locked: 1717778016,
            timestamp: 1717778015,
          },
        ],
        createdAt: "2024-06-07T16:33:37.033Z",
        updatedAt: "2024-06-07T16:33:37.033Z",
      },
      {
        id: "clx4wob4h007u12vcqnflyyin",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xQayqHjG5w",
            locked: 1717778007,
            timestamp: 1717777989,
          },
        ],
        createdAt: "2024-06-07T16:33:29.681Z",
        updatedAt: "2024-06-07T16:33:29.681Z",
      },
      {
        id: "clx4wo6nc007t12vcy0ketq9h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.198 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "utpgDYg7Mx",
            locked: 1717777997,
            timestamp: 1717777987,
          },
        ],
        createdAt: "2024-06-07T16:33:23.878Z",
        updatedAt: "2024-06-07T16:33:23.878Z",
      },
      {
        id: "clx4wnxl5007s12vcp5rpvlfs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "7StzV8gn70",
            locked: 1717777991,
            timestamp: 1717777977,
          },
        ],
        createdAt: "2024-06-07T16:33:12.138Z",
        updatedAt: "2024-06-07T16:33:12.138Z",
      },
      {
        id: "clx4wnqhv007r12vcdse3eb1w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "T5DyfQtqDi",
            locked: 1717777982,
            timestamp: 1717777981,
          },
        ],
        createdAt: "2024-06-07T16:33:02.945Z",
        updatedAt: "2024-06-07T16:33:02.945Z",
      },
      {
        id: "clx4wnjyc007q12vcqif23jyb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gwMFq4UgPE",
            locked: 1717777973,
            timestamp: 1717777971,
          },
        ],
        createdAt: "2024-06-07T16:32:54.468Z",
        updatedAt: "2024-06-07T16:32:54.468Z",
      },
      {
        id: "clx4wnf3a007p12vcmiw9m3uf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "KUlE1V5dPr",
            locked: 1717777962,
            timestamp: 1717777961,
          },
        ],
        createdAt: "2024-06-07T16:32:48.166Z",
        updatedAt: "2024-06-07T16:32:48.166Z",
      },
      {
        id: "clx4wnc32007o12vckny8f9t2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36 Edg/112.0.1722.58",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kBUPezGFjG",
            locked: 1717777963,
            timestamp: 1717777960,
          },
        ],
        createdAt: "2024-06-07T16:32:44.268Z",
        updatedAt: "2024-06-07T16:32:44.268Z",
      },
      {
        id: "clx4wn1iu007n12vcqfd2xmve",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) EdgiOS/125.0.2535.72 Version/16.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hFUBp7i9Av",
            locked: 1717777950,
            timestamp: 1717777948,
          },
        ],
        createdAt: "2024-06-07T16:32:30.583Z",
        updatedAt: "2024-06-07T16:32:30.583Z",
      },
      {
        id: "clx4wmzrr007m12vcwhh2bxj2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8KA35TaLXO",
            locked: 1717777942,
            timestamp: 1717777941,
          },
        ],
        createdAt: "2024-06-07T16:32:28.311Z",
        updatedAt: "2024-06-07T16:32:28.311Z",
      },
      {
        id: "clx4wmxb1007l12vczdma49h2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "rYjEy6MBjL",
            locked: 1717777945,
            timestamp: 1717777944,
          },
        ],
        createdAt: "2024-06-07T16:32:25.118Z",
        updatedAt: "2024-06-07T16:32:25.118Z",
      },
      {
        id: "clx4wmw2c007k12vcv838mhsg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AYPYew6OqM",
            locked: 1717777943,
            timestamp: 1717777941,
          },
        ],
        createdAt: "2024-06-07T16:32:23.508Z",
        updatedAt: "2024-06-07T16:32:23.508Z",
      },
      {
        id: "clx4wmvy5007j12vco6ihnqdx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Pvaszd508x",
            locked: 1717777943,
            timestamp: 1717777942,
          },
        ],
        createdAt: "2024-06-07T16:32:23.357Z",
        updatedAt: "2024-06-07T16:32:23.357Z",
      },
      {
        id: "clx4wmrl5007i12vc1zayaul1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "D0hJIRZhhj",
            locked: 1717777937,
            timestamp: 1717777935,
          },
        ],
        createdAt: "2024-06-07T16:32:17.705Z",
        updatedAt: "2024-06-07T16:32:17.705Z",
      },
      {
        id: "clx4wmqxh007h12vck9gt1if3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.5005.63 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VuWMWmb5OJ",
            locked: 1717777930,
            timestamp: 1717777901,
          },
        ],
        createdAt: "2024-06-07T16:32:16.850Z",
        updatedAt: "2024-06-07T16:32:16.850Z",
      },
      {
        id: "clx4wmi02007g12vcwpepokoy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FolHbB68T4",
            locked: 1717777924,
            timestamp: 1717777903,
          },
        ],
        createdAt: "2024-06-07T16:32:05.283Z",
        updatedAt: "2024-06-07T16:32:05.283Z",
      },
      {
        id: "clx4wmgj4007f12vcyz0i0lxr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/92.0.4515.159 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "qOnz84cUBV",
            locked: 1717777923,
            timestamp: 1717777922,
          },
        ],
        createdAt: "2024-06-07T16:32:03.377Z",
        updatedAt: "2024-06-07T16:32:03.377Z",
      },
      {
        id: "clx4wmgev007e12vcflohsen9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/92.0.4515.159 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zU75t1iUv1",
            locked: 1717777921,
            timestamp: 1717777919,
          },
        ],
        createdAt: "2024-06-07T16:32:03.224Z",
        updatedAt: "2024-06-07T16:32:03.224Z",
      },
      {
        id: "clx4wmcu0007d12vcs09wrzc2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/91.0.4472.77 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bBt3yzkv9J",
            locked: 1717777917,
            timestamp: 1717777915,
          },
        ],
        createdAt: "2024-06-07T16:31:58.584Z",
        updatedAt: "2024-06-07T16:31:58.584Z",
      },
      {
        id: "clx4wm7f7007c12vclrate7y5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 13; moto g stylus 5G (2022) Build/T2SDS33.75-38-8-5; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (33/13; 400dpi; 1080x2225; motorola; moto g stylus 5G (2022); milanf; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ckkpx7EXw3",
            locked: 1717777911,
            timestamp: 1717777907,
          },
        ],
        createdAt: "2024-06-07T16:31:51.569Z",
        updatedAt: "2024-06-07T16:31:51.569Z",
      },
      {
        id: "clx4wm4we007b12vcvwjyvs12",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "m3IHpGpIHN",
            locked: 1717777907,
            timestamp: 1717777906,
          },
        ],
        createdAt: "2024-06-07T16:31:48.302Z",
        updatedAt: "2024-06-07T16:31:48.302Z",
      },
      {
        id: "clx4wlvi0007a12vcpyd8pi9q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,5; iOS 16_7_8; en_US; en; scale=3.00; 1125x2001; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yZbg2vlZB1",
            locked: 1717777894,
            timestamp: 1717777892,
          },
        ],
        createdAt: "2024-06-07T16:31:36.117Z",
        updatedAt: "2024-06-07T16:31:36.117Z",
      },
      {
        id: "clx4wliru007912vci5obe4ta",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/92.0.4515.159 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Yu7DvnwhNk",
            locked: 1717777879,
            timestamp: 1717777878,
          },
        ],
        createdAt: "2024-06-07T16:31:19.627Z",
        updatedAt: "2024-06-07T16:31:19.627Z",
      },
      {
        id: "clx4wliis007812vcj3a9s4fo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/92.0.4515.159 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RSXEa0Uj42",
            locked: 1717777877,
            timestamp: 1717777875,
          },
        ],
        createdAt: "2024-06-07T16:31:19.300Z",
        updatedAt: "2024-06-07T16:31:19.300Z",
      },
      {
        id: "clx4wlgwi007712vce4l5kakx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "dGhv8XAaqd",
            locked: 1717777877,
            timestamp: 1717777876,
          },
        ],
        createdAt: "2024-06-07T16:31:17.194Z",
        updatedAt: "2024-06-07T16:31:17.194Z",
      },
      {
        id: "clx4wl90q007612vc1gv1zxyr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Zz7zSXArvZ",
            locked: 1717777862,
            timestamp: 1717777847,
          },
        ],
        createdAt: "2024-06-07T16:31:06.986Z",
        updatedAt: "2024-06-07T16:31:06.986Z",
      },
      {
        id: "clx4wl8ts007512vcy3fpowbr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "oKjRL2qYht",
            locked: 1717777865,
            timestamp: 1717777864,
          },
        ],
        createdAt: "2024-06-07T16:31:06.736Z",
        updatedAt: "2024-06-07T16:31:06.736Z",
      },
      {
        id: "clx4wl0kd007412vceti4f2is",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tdpNEBmN18",
            locked: 1717777855,
            timestamp: 1717777850,
          },
        ],
        createdAt: "2024-06-07T16:30:56.028Z",
        updatedAt: "2024-06-07T16:30:56.028Z",
      },
      {
        id: "clx4wkxof007312vcpa1egc57",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPad; CPU OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) EdgiOS/123.0.2420.74 Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "tablet",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PGPKvX4YdW",
            locked: 1717777851,
            timestamp: 1717777847,
          },
        ],
        createdAt: "2024-06-07T16:30:52.288Z",
        updatedAt: "2024-06-07T16:30:52.288Z",
      },
      {
        id: "clx4wkmhq007212vc1gkaui3w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0.0; Win64; x64; ) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.6261.156 Not(A:Brand/24 YaBrowser/24.4.4.1168 Yowser/2.5  Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tgPJ0NNvH1",
            locked: 1717777708,
            timestamp: 1717777701,
          },
        ],
        createdAt: "2024-06-07T16:30:37.788Z",
        updatedAt: "2024-06-07T16:30:37.788Z",
      },
      {
        id: "clx4wk8ur007112vcr224yb2z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent: "iOS/17.5.1 (21F90) dataaccessd/1.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YTxup7Oa83",
            locked: 1717777819,
            timestamp: 1717777815,
          },
        ],
        createdAt: "2024-06-07T16:30:20.116Z",
        updatedAt: "2024-06-07T16:30:20.116Z",
      },
      {
        id: "clx4wk1dp007012vc0iqgrnbw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "F8s5IyfVI5",
            locked: 1717777809,
            timestamp: 1717777808,
          },
        ],
        createdAt: "2024-06-07T16:30:10.430Z",
        updatedAt: "2024-06-07T16:30:10.430Z",
      },
      {
        id: "clx4wjxp5006z12vcgksu3z1a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "PocketCasts/1.0 (Pocket Casts Feed Parser; +http://pocketcasts.com/)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bCDd2A3JdQ",
            locked: 1717777805,
            timestamp: 1717777803,
          },
        ],
        createdAt: "2024-06-07T16:30:05.654Z",
        updatedAt: "2024-06-07T16:30:05.654Z",
      },
      {
        id: "clx4wj8xe006y12vcylrwgvn6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D47 Instagram 327.1.6.30.88 (iPhone14,2; iOS 16_3; en_US; en; scale=3.00; 1170x2532; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "2WRPyKRaTi",
            locked: 1717777773,
            timestamp: 1717777772,
          },
        ],
        createdAt: "2024-06-07T16:29:33.555Z",
        updatedAt: "2024-06-07T16:29:33.555Z",
      },
      {
        id: "clx4wj6jr006x12vck9pp4qu9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "2EAMWa8fcU",
            locked: 1717777770,
            timestamp: 1717777769,
          },
        ],
        createdAt: "2024-06-07T16:29:30.471Z",
        updatedAt: "2024-06-07T16:29:30.471Z",
      },
      {
        id: "clx4wj5yy006w12vc5citzh9h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VI0xZXGQ2H",
            locked: 1717777769,
            timestamp: 1717777765,
          },
        ],
        createdAt: "2024-06-07T16:29:29.722Z",
        updatedAt: "2024-06-07T16:29:29.722Z",
      },
      {
        id: "clx4wj4vw006v12vcazju8csb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "fqkh9qNju8",
            locked: 1717777767,
            timestamp: 1717777766,
          },
        ],
        createdAt: "2024-06-07T16:29:28.315Z",
        updatedAt: "2024-06-07T16:29:28.315Z",
      },
      {
        id: "clx4wiz2m006u12vcwbpkr6ew",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "nDxFjKINTi",
            locked: 1717777760,
            timestamp: 1717777759,
          },
        ],
        createdAt: "2024-06-07T16:29:20.783Z",
        updatedAt: "2024-06-07T16:29:20.783Z",
      },
      {
        id: "clx4wiwv2006t12vcisexosbv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PBuyoT9a8O",
            locked: 1717777757,
            timestamp: 1717777756,
          },
        ],
        createdAt: "2024-06-07T16:29:17.918Z",
        updatedAt: "2024-06-07T16:29:17.918Z",
      },
      {
        id: "clx4wiwrp006s12vchg7xw6fr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20D47 Instagram 327.1.6.30.88 (iPhone14,2; iOS 16_3; en_US; en; scale=3.00; 1170x2532; 588348860)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "mvN0o27mXm",
            locked: 1717777757,
            timestamp: 1717777756,
          },
        ],
        createdAt: "2024-06-07T16:29:17.798Z",
        updatedAt: "2024-06-07T16:29:17.798Z",
      },
      {
        id: "clx4wiojd006r12vc2j4549rs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "IMWojcdTv0",
            locked: 1717777746,
            timestamp: 1717777745,
          },
        ],
        createdAt: "2024-06-07T16:29:07.126Z",
        updatedAt: "2024-06-07T16:29:07.126Z",
      },
      {
        id: "clx4wgg61006q12vcd9dj0mn0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21D61 Instagram 323.0.3.23.54 (iPhone14,5; iOS 17_3_1; en_CA; en; scale=3.00; 1170x2532; 577210397)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EGTYPbcAKp",
            locked: 1717777642,
            timestamp: 1717777641,
          },
        ],
        createdAt: "2024-06-07T16:27:22.970Z",
        updatedAt: "2024-06-07T16:27:22.970Z",
      },
      {
        id: "clx4wges7006p12vcvey34q7i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Bg3teLAeKd",
            locked: 1717777641,
            timestamp: 1717777640,
          },
        ],
        createdAt: "2024-06-07T16:27:21.176Z",
        updatedAt: "2024-06-07T16:27:21.176Z",
      },
      {
        id: "clx4wg76t006o12vc8wzuoqb9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "o72TRPKlXw",
            locked: 1717777630,
            timestamp: 1717777628,
          },
        ],
        createdAt: "2024-06-07T16:27:11.330Z",
        updatedAt: "2024-06-07T16:27:11.330Z",
      },
      {
        id: "clx4wf6m7006n12vcohnmzjg1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xTcP6VzABJ",
            locked: 1717777584,
            timestamp: 1717777580,
          },
        ],
        createdAt: "2024-06-07T16:26:23.932Z",
        updatedAt: "2024-06-07T16:26:23.932Z",
      },
      {
        id: "clx4weoq0006m12vc22eiphia",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "14oWC9GyPh",
            locked: 1717777560,
            timestamp: 1717777557,
          },
        ],
        createdAt: "2024-06-07T16:26:00.745Z",
        updatedAt: "2024-06-07T16:26:00.745Z",
      },
      {
        id: "clx4wenon006l12vc1klqsqjl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "eaocd16dGm",
            locked: 1717777558,
            timestamp: 1717777552,
          },
        ],
        createdAt: "2024-06-07T16:25:59.399Z",
        updatedAt: "2024-06-07T16:25:59.399Z",
      },
      {
        id: "clx4wenjq006k12vcrilj23la",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cGkSmxwvxX",
            locked: 1717777558,
            timestamp: 1717777556,
          },
        ],
        createdAt: "2024-06-07T16:25:59.219Z",
        updatedAt: "2024-06-07T16:25:59.219Z",
      },
      {
        id: "clx4wefzz006j12vcuh9f2axl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "WGaUumzf5z",
            locked: 1717777549,
            timestamp: 1717777548,
          },
        ],
        createdAt: "2024-06-07T16:25:49.439Z",
        updatedAt: "2024-06-07T16:25:49.439Z",
      },
      {
        id: "clx4we96j006i12vcfzrjswnk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bNZnKm1a9i",
            locked: 1717777540,
            timestamp: 1717777538,
          },
        ],
        createdAt: "2024-06-07T16:25:40.600Z",
        updatedAt: "2024-06-07T16:25:40.600Z",
      },
      {
        id: "clx4wdp6c006h12vct42ga0pd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-G991U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 540dpi; 1080x2158; samsung; SM-G991U; o1q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "79voaa9nIm",
            locked: 1717777514,
            timestamp: 1717777511,
          },
        ],
        createdAt: "2024-06-07T16:25:14.674Z",
        updatedAt: "2024-06-07T16:25:14.674Z",
      },
      {
        id: "clx4wccza006g12vcfi4lhpfz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cnd75bYQ3H",
            locked: 1717777451,
            timestamp: 1717777450,
          },
        ],
        createdAt: "2024-06-07T16:24:12.211Z",
        updatedAt: "2024-06-07T16:24:12.211Z",
      },
      {
        id: "clx4w9o8n006f12vcjl2m51pe",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_5_1; en_US; en; scale=3.00; 1290x2796; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "BtVfCZICfr",
            locked: 1717777326,
            timestamp: 1717777324,
          },
        ],
        createdAt: "2024-06-07T16:22:06.837Z",
        updatedAt: "2024-06-07T16:22:06.837Z",
      },
      {
        id: "clx4w93l9006e12vcs3bq66hq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1624; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FIflo74q8A",
            locked: 1717777299,
            timestamp: 1717777297,
          },
        ],
        createdAt: "2024-06-07T16:21:40.074Z",
        updatedAt: "2024-06-07T16:21:40.074Z",
      },
      {
        id: "clx4w7vkf006d12vcndbdpn7a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "1fRvqpUfC6",
            locked: 1717777242,
            timestamp: 1717777241,
          },
        ],
        createdAt: "2024-06-07T16:20:43.021Z",
        updatedAt: "2024-06-07T16:20:43.021Z",
      },
      {
        id: "clx4w6ric006c12vc64pzb1nb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "z9zYbfMorz",
            locked: 1717777190,
            timestamp: 1717777189,
          },
        ],
        createdAt: "2024-06-07T16:19:51.105Z",
        updatedAt: "2024-06-07T16:19:51.105Z",
      },
      {
        id: "clx4w58zt006b12vczdoug1uf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "pHrvXci54w",
            locked: 1717777119,
            timestamp: 1717777117,
          },
        ],
        createdAt: "2024-06-07T16:18:40.455Z",
        updatedAt: "2024-06-07T16:18:40.455Z",
      },
      {
        id: "clx4w4uw6006a12vcukjf5wqh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21C5046c Instagram 332.0.2.29.94 (iPhone13,4; iOS 17_2; en_ZA; en; scale=3.00; 1284x2778; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RIw9rObFXI",
            locked: 1717777100,
            timestamp: 1717777099,
          },
        ],
        createdAt: "2024-06-07T16:18:22.179Z",
        updatedAt: "2024-06-07T16:18:22.179Z",
      },
      {
        id: "clx4w3n7n006912vcrnlp9tgd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "5kd9CDiRgU",
            locked: 1717777044,
            timestamp: 1717777043,
          },
        ],
        createdAt: "2024-06-07T16:17:25.572Z",
        updatedAt: "2024-06-07T16:17:25.572Z",
      },
      {
        id: "clx4w3i9s006812vcgxsx4vnb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "i35rHi9Ww7",
            locked: 1717777037,
            timestamp: 1717777031,
          },
        ],
        createdAt: "2024-06-07T16:17:19.164Z",
        updatedAt: "2024-06-07T16:17:19.164Z",
      },
      {
        id: "clx4w35we006712vcjcsn6q3r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "iD5Mb84XwH",
            locked: 1717777022,
            timestamp: 1717777021,
          },
        ],
        createdAt: "2024-06-07T16:17:03.135Z",
        updatedAt: "2024-06-07T16:17:03.135Z",
      },
      {
        id: "clx4w33az006612vcxgo0rd0d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "H9eaZ8qJHH",
            locked: 1717777018,
            timestamp: 1717777017,
          },
        ],
        createdAt: "2024-06-07T16:16:59.771Z",
        updatedAt: "2024-06-07T16:16:59.771Z",
      },
      {
        id: "clx4w2zj0006512vckocj28r6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "qmsTyeRFm5",
            locked: 1717777014,
            timestamp: 1717777013,
          },
        ],
        createdAt: "2024-06-07T16:16:54.873Z",
        updatedAt: "2024-06-07T16:16:54.873Z",
      },
      {
        id: "clx4w2nxs006412vc2d9x6skh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "uqRY3B7PDe",
            locked: 1717776999,
            timestamp: 1717776998,
          },
        ],
        createdAt: "2024-06-07T16:16:39.857Z",
        updatedAt: "2024-06-07T16:16:39.857Z",
      },
      {
        id: "clx4w2mh5006312vc5pnuh2dd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "CfBzKiMnet",
            locked: 1717776996,
            timestamp: 1717776995,
          },
        ],
        createdAt: "2024-06-07T16:16:37.961Z",
        updatedAt: "2024-06-07T16:16:37.961Z",
      },
      {
        id: "clx4w2l1a006212vcufkedkst",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "KEksO8utAZ",
            locked: 1717776995,
            timestamp: 1717776992,
          },
        ],
        createdAt: "2024-06-07T16:16:36.095Z",
        updatedAt: "2024-06-07T16:16:36.095Z",
      },
      {
        id: "clx4w2iri006112vcrnii2du7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "tlWS0w59B8",
            locked: 1717776991,
            timestamp: 1717776989,
          },
        ],
        createdAt: "2024-06-07T16:16:33.146Z",
        updatedAt: "2024-06-07T16:16:33.146Z",
      },
      {
        id: "clx4w1s6b006012vca8bq8xjy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 [FBAN/FBIOS;FBAV/460.0.0.31.103;FBBV/588708950;FBDV/iPhone14,2;FBMD/iPhone;FBSN/iOS;FBSV/17.4.1;FBSS/3;FBID/phone;FBLC/en_US;FBOP/5;FBRV/592229855]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "rtWqRxT1IV",
            locked: 1717776958,
            timestamp: 1717776956,
          },
        ],
        createdAt: "2024-06-07T16:15:58.689Z",
        updatedAt: "2024-06-07T16:15:58.689Z",
      },
      {
        id: "clx4w167f005z12vcwcd5hnby",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2NAu52eoRu",
            locked: 1717776929,
            timestamp: 1717776927,
          },
        ],
        createdAt: "2024-06-07T16:15:30.220Z",
        updatedAt: "2024-06-07T16:15:30.220Z",
      },
      {
        id: "clx4w13h0005y12vcx4x4vt98",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S906U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2140; samsung; SM-S906U; g0q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kOfH1dWnck",
            locked: 1717776925,
            timestamp: 1717776921,
          },
        ],
        createdAt: "2024-06-07T16:15:26.676Z",
        updatedAt: "2024-06-07T16:15:26.676Z",
      },
      {
        id: "clx4w11ys005x12vc1dx8qdnw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "0pglAr7ksO",
            locked: 1717776924,
            timestamp: 1717776923,
          },
        ],
        createdAt: "2024-06-07T16:15:24.722Z",
        updatedAt: "2024-06-07T16:15:24.722Z",
      },
      {
        id: "clx4w0r4h005w12vceiwmljvn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_GB; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "n65uIzVS8W",
            locked: 1717776910,
            timestamp: 1717776909,
          },
        ],
        createdAt: "2024-06-07T16:15:10.674Z",
        updatedAt: "2024-06-07T16:15:10.674Z",
      },
      {
        id: "clx4w0j8t005v12vcwnh549eh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 [FBAN/FBIOS;FBAV/460.0.0.31.103;FBBV/588708950;FBDV/iPhone14,2;FBMD/iPhone;FBSN/iOS;FBSV/17.4.1;FBSS/3;FBID/phone;FBLC/en_US;FBOP/5;FBRV/592229855]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "RsHzChkQ1T",
            locked: 1717776899,
            timestamp: 1717776897,
          },
        ],
        createdAt: "2024-06-07T16:15:00.460Z",
        updatedAt: "2024-06-07T16:15:00.460Z",
      },
      {
        id: "clx4w0cr8005u12vcfccz5wrx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S926U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2127; samsung; SM-S926U; e2q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nmtZdbNvx6",
            locked: 1717776891,
            timestamp: 1717776885,
          },
        ],
        createdAt: "2024-06-07T16:14:52.053Z",
        updatedAt: "2024-06-07T16:14:52.053Z",
      },
      {
        id: "clx4w06wq005t12vcg2oh1493",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Nu0fADTZdN",
            locked: 1717776883,
            timestamp: 1717776882,
          },
        ],
        createdAt: "2024-06-07T16:14:44.474Z",
        updatedAt: "2024-06-07T16:14:44.474Z",
      },
      {
        id: "clx4w05qk005s12vccd61vya5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "q6ygPSXH6l",
            locked: 1717776882,
            timestamp: 1717776881,
          },
        ],
        createdAt: "2024-06-07T16:14:42.953Z",
        updatedAt: "2024-06-07T16:14:42.953Z",
      },
      {
        id: "clx4vz9qj005r12vclks0f6w0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 329.0.6.27.88 (iPhone14,7; iOS 17_5_1; en_GB; en; scale=3.00; 1170x2532; 593314425)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "TMWpyVNzJF",
            locked: 1717776841,
            timestamp: 1717776840,
          },
        ],
        createdAt: "2024-06-07T16:14:01.483Z",
        updatedAt: "2024-06-07T16:14:01.483Z",
      },
      {
        id: "clx4vz7ho005q12vczzint4oh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 329.0.6.27.88 (iPhone14,7; iOS 17_5_1; en_GB; en; scale=3.00; 1170x2532; 593314425)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zpYr60gZXS",
            locked: 1717776838,
            timestamp: 1717776837,
          },
        ],
        createdAt: "2024-06-07T16:13:58.569Z",
        updatedAt: "2024-06-07T16:13:58.569Z",
      },
      {
        id: "clx4vynaf005p12vcuadi9jm2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "yH4pJUesBA",
            locked: 1717776812,
            timestamp: 1717776811,
          },
        ],
        createdAt: "2024-06-07T16:13:32.392Z",
        updatedAt: "2024-06-07T16:13:32.392Z",
      },
      {
        id: "clx4vym0h005o12vcrtvbrc8i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "TJkFuHYgOa",
            locked: 1717776810,
            timestamp: 1717776809,
          },
        ],
        createdAt: "2024-06-07T16:13:30.735Z",
        updatedAt: "2024-06-07T16:13:30.735Z",
      },
      {
        id: "clx4vy6wy005n12vc43ie8grv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "gcHywupdoY",
            locked: 1717776790,
            timestamp: 1717776787,
          },
        ],
        createdAt: "2024-06-07T16:13:11.168Z",
        updatedAt: "2024-06-07T16:13:11.168Z",
      },
      {
        id: "clx4vwofa005m12vcf0d4ufxr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "q4nzntOZoZ",
            locked: 1717776714,
            timestamp: 1717776711,
          },
        ],
        createdAt: "2024-06-07T16:12:00.550Z",
        updatedAt: "2024-06-07T16:12:00.550Z",
      },
      {
        id: "clx4vwhux005l12vc69y23hyq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lASn5EZZ2t",
            locked: 1717776711,
            timestamp: 1717776710,
          },
        ],
        createdAt: "2024-06-07T16:11:52.039Z",
        updatedAt: "2024-06-07T16:11:52.039Z",
      },
      {
        id: "clx4vvyte005k12vcvf2v8v0m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; es_US; es; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "3PQdYx4R2j",
            locked: 1717776686,
            timestamp: 1717776685,
          },
        ],
        createdAt: "2024-06-07T16:11:27.362Z",
        updatedAt: "2024-06-07T16:11:27.362Z",
      },
      {
        id: "clx4vvord005j12vcbdan61rm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; es_US; es; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "94cQftPw90",
            locked: 1717776673,
            timestamp: 1717776672,
          },
        ],
        createdAt: "2024-06-07T16:11:14.326Z",
        updatedAt: "2024-06-07T16:11:14.326Z",
      },
      {
        id: "clx4vuxxj005i12vc83iqx4nd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FDg03ytOMs",
            locked: 1717776638,
            timestamp: 1717776636,
          },
        ],
        createdAt: "2024-06-07T16:10:39.559Z",
        updatedAt: "2024-06-07T16:10:39.559Z",
      },
      {
        id: "clx4vus6f005h12vczsql7jqs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/106.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9hOPQh5ATP",
            locked: 1717776629,
            timestamp: 1717776626,
          },
        ],
        createdAt: "2024-06-07T16:10:32.101Z",
        updatedAt: "2024-06-07T16:10:32.101Z",
      },
      {
        id: "clx4vttw8005g12vcjrdibsr6",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 333.1.4.32.95 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SvQ3E7oF3c",
            locked: 1717776587,
            timestamp: 1717776584,
          },
        ],
        createdAt: "2024-06-07T16:09:47.669Z",
        updatedAt: "2024-06-07T16:09:47.669Z",
      },
      {
        id: "clx4vss0d005f12vc65i99jwx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) EdgiOS/125.0.2535.72 Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "FXs2PKR8dO",
            locked: 1717776537,
            timestamp: 1717776536,
          },
        ],
        createdAt: "2024-06-07T16:08:58.574Z",
        updatedAt: "2024-06-07T16:08:58.574Z",
      },
      {
        id: "clx4vss09005e12vcrp63059d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) EdgiOS/125.0.2535.72 Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UKHFgxW6Xf",
            locked: 1717776535,
            timestamp: 1717776534,
          },
        ],
        createdAt: "2024-06-07T16:08:58.567Z",
        updatedAt: "2024-06-07T16:08:58.567Z",
      },
      {
        id: "clx4vshnr005d12vchyh0roou",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "PoosdmuqXc",
            locked: 1717776524,
            timestamp: 1717776523,
          },
        ],
        createdAt: "2024-06-07T16:08:45.160Z",
        updatedAt: "2024-06-07T16:08:45.160Z",
      },
      {
        id: "clx4vse7g005c12vc6uvczvzj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bRcgsBQ0nC",
            locked: 1717776518,
            timestamp: 1717776517,
          },
        ],
        createdAt: "2024-06-07T16:08:40.682Z",
        updatedAt: "2024-06-07T16:08:40.682Z",
      },
      {
        id: "clx4vqc1o005b12vc2t6jk1yg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone16,2; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VEdyjwt2vj",
            locked: 1717776424,
            timestamp: 1717776422,
          },
        ],
        createdAt: "2024-06-07T16:07:04.570Z",
        updatedAt: "2024-06-07T16:07:04.570Z",
      },
      {
        id: "clx4vp5ri005a12vc22akvoz5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "icvNS4GWPr",
            locked: 1717776369,
            timestamp: 1717776368,
          },
        ],
        createdAt: "2024-06-07T16:06:09.774Z",
        updatedAt: "2024-06-07T16:06:09.774Z",
      },
      {
        id: "clx4vp2xn005912vc0mjuu9vd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "PZ1iJ9fLqK",
            locked: 1717776365,
            timestamp: 1717776364,
          },
        ],
        createdAt: "2024-06-07T16:06:06.104Z",
        updatedAt: "2024-06-07T16:06:06.104Z",
      },
      {
        id: "clx4voe8s005812vcbdbmfzkn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36 Edg/124.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "pqt8X2MURF",
            locked: 1717776333,
            timestamp: 1717776332,
          },
        ],
        createdAt: "2024-06-07T16:05:34.108Z",
        updatedAt: "2024-06-07T16:05:34.108Z",
      },
      {
        id: "clx4voa0t005712vcaq8tk2en",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36 Edg/124.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "iPJF8udgKM",
            locked: 1717776327,
            timestamp: 1717776326,
          },
        ],
        createdAt: "2024-06-07T16:05:28.635Z",
        updatedAt: "2024-06-07T16:05:28.635Z",
      },
      {
        id: "clx4vns2c005612vci83uk9uc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "HqpoXXmazt",
            locked: 1717776311,
            timestamp: 1717776310,
          },
        ],
        createdAt: "2024-06-07T16:05:05.365Z",
        updatedAt: "2024-06-07T16:05:05.365Z",
      },
      {
        id: "clx4vnpss005512vckdsgwrjf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone13,1; iOS 16_6_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "liSimdlVk5",
            locked: 1717776302,
            timestamp: 1717776300,
          },
        ],
        createdAt: "2024-06-07T16:05:02.416Z",
        updatedAt: "2024-06-07T16:05:02.416Z",
      },
      {
        id: "clx4vnc5n005412vcjwo3ckqk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bYU8Z4F4Sm",
            locked: 1717776284,
            timestamp: 1717776276,
          },
        ],
        createdAt: "2024-06-07T16:04:44.747Z",
        updatedAt: "2024-06-07T16:04:44.747Z",
      },
      {
        id: "clx4vn3rk005312vctxcc4ie7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone11,8; iOS 16_1_2; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "8V1jHFwsAk",
            locked: 1717776273,
            timestamp: 1717776271,
          },
        ],
        createdAt: "2024-06-07T16:04:33.869Z",
        updatedAt: "2024-06-07T16:04:33.869Z",
      },
      {
        id: "clx4vmk0b005212vcuh2sr30g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 265.0.0.13.302 (iPhone14,5; iOS 16_5_1; en_US; en-US; scale=3.00; 1170x2532; 435784589)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "naWbEdt8wQ",
            locked: 1717776248,
            timestamp: 1717776247,
          },
        ],
        createdAt: "2024-06-07T16:04:08.265Z",
        updatedAt: "2024-06-07T16:04:08.265Z",
      },
      {
        id: "clx4vm1mi005112vcz6w12yrx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 265.0.0.13.302 (iPhone14,5; iOS 16_5_1; en_US; en-US; scale=3.00; 1170x2532; 435784589)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QN1QClt88D",
            locked: 1717776223,
            timestamp: 1717776222,
          },
        ],
        createdAt: "2024-06-07T16:03:44.443Z",
        updatedAt: "2024-06-07T16:03:44.443Z",
      },
      {
        id: "clx4vm0tl005012vc04rcewt8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "yeUr0jfwa2",
            locked: 1717776222,
            timestamp: 1717776215,
          },
        ],
        createdAt: "2024-06-07T16:03:43.399Z",
        updatedAt: "2024-06-07T16:03:43.399Z",
      },
      {
        id: "clx4vleng004z12vcikzif5na",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "N3rbkycFLD",
            locked: 1717776193,
            timestamp: 1717776191,
          },
        ],
        createdAt: "2024-06-07T16:03:14.666Z",
        updatedAt: "2024-06-07T16:03:14.666Z",
      },
      {
        id: "clx4vk6jt004y12vcqjh1h9bw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "Ut1o7DZJOv",
            locked: 1717776137,
            timestamp: 1717776136,
          },
        ],
        createdAt: "2024-06-07T16:02:17.511Z",
        updatedAt: "2024-06-07T16:02:17.511Z",
      },
      {
        id: "clx4vjr9t004x12vcqv9fs5qm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NxzlWcb2Xm",
            locked: 1717776117,
            timestamp: 1717776116,
          },
        ],
        createdAt: "2024-06-07T16:01:57.711Z",
        updatedAt: "2024-06-07T16:01:57.711Z",
      },
      {
        id: "clx4virms004w12vcs1ubbj65",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/25.0 Chrome/121.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "bdrhfOpGKK",
            locked: 1717776070,
            timestamp: 1717776068,
          },
        ],
        createdAt: "2024-06-07T16:01:11.522Z",
        updatedAt: "2024-06-07T16:01:11.522Z",
      },
      {
        id: "clx4viavl004v12vc1t5ory65",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,4; iOS 17_5_1; en_FR; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "g9mU3k6KCe",
            locked: 1717776049,
            timestamp: 1717776048,
          },
        ],
        createdAt: "2024-06-07T16:00:49.809Z",
        updatedAt: "2024-06-07T16:00:49.809Z",
      },
      {
        id: "clx4vi9mc004u12vcmncdbp1b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,4; iOS 17_5_1; en_FR; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "YjCKFP3Adu",
            locked: 1717776047,
            timestamp: 1717776046,
          },
        ],
        createdAt: "2024-06-07T16:00:48.178Z",
        updatedAt: "2024-06-07T16:00:48.178Z",
      },
      {
        id: "clx4vhpkd004t12vch2nvqagb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "NVDYpYt2on",
            locked: 1717776022,
            timestamp: 1717776021,
          },
        ],
        createdAt: "2024-06-07T16:00:22.190Z",
        updatedAt: "2024-06-07T16:00:22.190Z",
      },
      {
        id: "clx4vhiwd004s12vcrbyy4o4j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nE9QtP32XC",
            locked: 1717776013,
            timestamp: 1717776011,
          },
        ],
        createdAt: "2024-06-07T16:00:13.547Z",
        updatedAt: "2024-06-07T16:00:13.547Z",
      },
      {
        id: "clx4vh2hn004r12vcqjtf678f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S918U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 450dpi; 1080x2106; samsung; SM-S918U; dm3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dvNieqixFG",
            locked: 1717775991,
            timestamp: 1717775986,
          },
        ],
        createdAt: "2024-06-07T15:59:52.284Z",
        updatedAt: "2024-06-07T15:59:52.284Z",
      },
      {
        id: "clx4vh1jv004q12vcqcexav6u",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "5RLh7Rsgtv",
            locked: 1717775991,
            timestamp: 1717775990,
          },
        ],
        createdAt: "2024-06-07T15:59:51.068Z",
        updatedAt: "2024-06-07T15:59:51.068Z",
      },
      {
        id: "clx4vgvd9004p12vc80rwsdzm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3fpnkgGcWR",
            locked: 1717775982,
            timestamp: 1717775981,
          },
        ],
        createdAt: "2024-06-07T15:59:43.050Z",
        updatedAt: "2024-06-07T15:59:43.050Z",
      },
      {
        id: "clx4vggdy004o12vc8psp917j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "xLnoA5U27q",
            locked: 1717775963,
            timestamp: 1717775962,
          },
        ],
        createdAt: "2024-06-07T15:59:23.638Z",
        updatedAt: "2024-06-07T15:59:23.638Z",
      },
      {
        id: "clx4vgddb004n12vcvii92flt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RURBt0TrSQ",
            locked: 1717775959,
            timestamp: 1717775958,
          },
        ],
        createdAt: "2024-06-07T15:59:19.727Z",
        updatedAt: "2024-06-07T15:59:19.727Z",
      },
      {
        id: "clx4vg4ve004m12vc070pc1q8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "JiCCbVhsHn",
            locked: 1717775948,
            timestamp: 1717775947,
          },
        ],
        createdAt: "2024-06-07T15:59:08.711Z",
        updatedAt: "2024-06-07T15:59:08.711Z",
      },
      {
        id: "clx4vfgjw004l12vc1bufn4z4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2138; samsung; SM-S928U1; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "YD1lDZ1444",
            locked: 1717775917,
            timestamp: 1717775916,
          },
        ],
        createdAt: "2024-06-07T15:58:37.196Z",
        updatedAt: "2024-06-07T15:58:37.196Z",
      },
      {
        id: "clx4vffbd004k12vcxwcgpzq4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2138; samsung; SM-S928U1; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "WHyLNLFLd7",
            locked: 1717775915,
            timestamp: 1717775910,
          },
        ],
        createdAt: "2024-06-07T15:58:35.592Z",
        updatedAt: "2024-06-07T15:58:35.592Z",
      },
      {
        id: "clx4vfah9004j12vc2961c1pl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "lqsPhpfsAQ",
            locked: 1717775909,
            timestamp: 1717775908,
          },
        ],
        createdAt: "2024-06-07T15:58:29.326Z",
        updatedAt: "2024-06-07T15:58:29.326Z",
      },
      {
        id: "clx4vf7ns004i12vcbakzw5aq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pCguowWZ8S",
            locked: 1717775905,
            timestamp: 1717775903,
          },
        ],
        createdAt: "2024-06-07T15:58:25.672Z",
        updatedAt: "2024-06-07T15:58:25.672Z",
      },
      {
        id: "clx4vf4fb004h12vcxdwa7i4g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "XghgKvTIzf",
            locked: 1717775901,
            timestamp: 1717775900,
          },
        ],
        createdAt: "2024-06-07T15:58:21.480Z",
        updatedAt: "2024-06-07T15:58:21.480Z",
      },
      {
        id: "clx4vf2fg004g12vc3vc54bkc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "4KbgCmnhVM",
            locked: 1717775897,
            timestamp: 1717775896,
          },
        ],
        createdAt: "2024-06-07T15:58:18.891Z",
        updatedAt: "2024-06-07T15:58:18.891Z",
      },
      {
        id: "clx4vex4x004f12vc5vdlji9q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kG7oa5a39T",
            locked: 1717775890,
            timestamp: 1717775887,
          },
        ],
        createdAt: "2024-06-07T15:58:12.034Z",
        updatedAt: "2024-06-07T15:58:12.034Z",
      },
      {
        id: "clx4venow004e12vcjupko7qj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "rps9fgh3y6",
            locked: 1717775879,
            timestamp: 1717775877,
          },
        ],
        createdAt: "2024-06-07T15:57:59.790Z",
        updatedAt: "2024-06-07T15:57:59.790Z",
      },
      {
        id: "clx4ve7st004d12vcf0ucnyiu",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_CA; en; scale=3.00; 1290x2796; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gI4IyDe3ZB",
            locked: 1717775858,
            timestamp: 1717775857,
          },
        ],
        createdAt: "2024-06-07T15:57:39.198Z",
        updatedAt: "2024-06-07T15:57:39.198Z",
      },
      {
        id: "clx4vdxn0004c12vc4ufi624o",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 8.0.0; LG-H870DS Build/OPR1.170623.032) AppleWebKit/537.37 (KHTML, like Gecko) Chrome/68.0.3440.91 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "XRuptMbwWE",
            locked: 1717775845,
            timestamp: 1717775843,
          },
        ],
        createdAt: "2024-06-07T15:57:26.025Z",
        updatedAt: "2024-06-07T15:57:26.025Z",
      },
      {
        id: "clx4vdc8j004b12vcvvmzyleo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "UExP1geltc",
            locked: 1717775817,
            timestamp: 1717775805,
          },
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "2IUWaAICeZ",
            locked: 1717775817,
            timestamp: 1717775810,
          },
        ],
        createdAt: "2024-06-07T15:56:58.288Z",
        updatedAt: "2024-06-07T15:56:58.288Z",
      },
      {
        id: "clx4vbz1t004a12vc6lhxvm5t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lz4rA8pc6H",
            locked: 1717775753,
            timestamp: 1717775752,
          },
        ],
        createdAt: "2024-06-07T15:55:54.542Z",
        updatedAt: "2024-06-07T15:55:54.542Z",
      },
      {
        id: "clx4vbgco004912vc8y3e9aox",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ba0lNinOEN",
            locked: 1717775724,
            timestamp: 1717775712,
          },
        ],
        createdAt: "2024-06-07T15:55:30.309Z",
        updatedAt: "2024-06-07T15:55:30.309Z",
      },
      {
        id: "clx4vawh9004812vcq85ypm6a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "wHBfjk55Iz",
            locked: 1717775702,
            timestamp: 1717775688,
          },
        ],
        createdAt: "2024-06-07T15:55:04.557Z",
        updatedAt: "2024-06-07T15:55:04.557Z",
      },
      {
        id: "clx4vausp004712vcar3ti4vb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "F7FkVjL7TJ",
            locked: 1717775702,
            timestamp: 1717775701,
          },
        ],
        createdAt: "2024-06-07T15:55:02.377Z",
        updatedAt: "2024-06-07T15:55:02.377Z",
      },
      {
        id: "clx4vauao004612vc4e968k7b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NVzgMzkzOZ",
            locked: 1717775701,
            timestamp: 1717775699,
          },
        ],
        createdAt: "2024-06-07T15:55:01.728Z",
        updatedAt: "2024-06-07T15:55:01.728Z",
      },
      {
        id: "clx4vau91004512vcdmkq6f9i",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "VtddUuyzYW",
            locked: 1717775701,
            timestamp: 1717775700,
          },
        ],
        createdAt: "2024-06-07T15:55:01.669Z",
        updatedAt: "2024-06-07T15:55:01.669Z",
      },
      {
        id: "clx4vapvv004412vcqqvu8w7r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "CxC182JFKe",
            locked: 1717775695,
            timestamp: 1717775677,
          },
        ],
        createdAt: "2024-06-07T15:54:56.012Z",
        updatedAt: "2024-06-07T15:54:56.012Z",
      },
      {
        id: "clx4vap0h004312vceui2g5o8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Mobile/15E148 Safari/604.1 Ddg/16.3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "SfG4Vu4l4K",
            locked: 1717775694,
            timestamp: 1717775693,
          },
        ],
        createdAt: "2024-06-07T15:54:54.882Z",
        updatedAt: "2024-06-07T15:54:54.882Z",
      },
      {
        id: "clx4vam1k004212vc7kovjagz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "HF3r7o6KtB",
            locked: 1717775690,
            timestamp: 1717775688,
          },
        ],
        createdAt: "2024-06-07T15:54:51.029Z",
        updatedAt: "2024-06-07T15:54:51.029Z",
      },
      {
        id: "clx4vaavr004112vc8oh4lkyp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GQtEbodd5T",
            locked: 1717775676,
            timestamp: 1717775674,
          },
        ],
        createdAt: "2024-06-07T15:54:36.567Z",
        updatedAt: "2024-06-07T15:54:36.567Z",
      },
      {
        id: "clx4va90x004012vcqxgv5qop",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Mobile/15E148 Safari/604.1 Ddg/16.3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "hXZPYqahPv",
            locked: 1717775672,
            timestamp: 1717775671,
          },
        ],
        createdAt: "2024-06-07T15:54:34.160Z",
        updatedAt: "2024-06-07T15:54:34.160Z",
      },
      {
        id: "clx4va7tf003z12vccauz02ka",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent: "iOS/16.2 (20C65) dataaccessd/1.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "U8YXBl7AUV",
            locked: 1717775672,
            timestamp: 1717775669,
          },
        ],
        createdAt: "2024-06-07T15:54:32.596Z",
        updatedAt: "2024-06-07T15:54:32.596Z",
      },
      {
        id: "clx4va4xs003y12vcdire6rei",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 6.1; WOW64) SkypeUriPreview Preview/0.5 skype-url-preview@microsoft.com",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zV5Z3vNQzv",
            locked: 1717775668,
            timestamp: 1717775666,
          },
        ],
        createdAt: "2024-06-07T15:54:28.864Z",
        updatedAt: "2024-06-07T15:54:28.864Z",
      },
      {
        id: "clx4va2oi003x12vcli2eg8pz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xUcj5D9X3W",
            locked: 1717775665,
            timestamp: 1717775661,
          },
        ],
        createdAt: "2024-06-07T15:54:25.938Z",
        updatedAt: "2024-06-07T15:54:25.938Z",
      },
      {
        id: "clx4va1r8003w12vcp1zf7b9r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iADyVHpfkC",
            locked: 1717775664,
            timestamp: 1717775662,
          },
        ],
        createdAt: "2024-06-07T15:54:24.741Z",
        updatedAt: "2024-06-07T15:54:24.741Z",
      },
      {
        id: "clx4va0xz003v12vcrozvewns",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "FPCnbwxaqh",
            locked: 1717775662,
            timestamp: 1717775651,
          },
        ],
        createdAt: "2024-06-07T15:54:23.688Z",
        updatedAt: "2024-06-07T15:54:23.688Z",
      },
      {
        id: "clx4v9x1x003u12vc2ayrq0ig",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "BrcZcxzm2j",
            locked: 1717775655,
            timestamp: 1717775635,
          },
        ],
        createdAt: "2024-06-07T15:54:18.644Z",
        updatedAt: "2024-06-07T15:54:18.644Z",
      },
      {
        id: "clx4v9rpd003t12vcuw88wt8z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent: "Java/1.8.0_332",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "K2xalOF7Yz",
            locked: 1717775651,
            timestamp: 1717775649,
          },
        ],
        createdAt: "2024-06-07T15:54:11.714Z",
        updatedAt: "2024-06-07T15:54:11.714Z",
      },
      {
        id: "clx4v9jjp003s12vc46sfy14r",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent: "Java/17.0.7",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "X9OqUfes4K",
            locked: 1717775640,
            timestamp: 1717775638,
          },
        ],
        createdAt: "2024-06-07T15:54:01.141Z",
        updatedAt: "2024-06-07T15:54:01.141Z",
      },
      {
        id: "clx4v9jg2003r12vc48l57brl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "U5KhgCxAgH",
            locked: 1717775640,
            timestamp: 1717775638,
          },
        ],
        createdAt: "2024-06-07T15:54:01.009Z",
        updatedAt: "2024-06-07T15:54:01.009Z",
      },
      {
        id: "clx4v9ho1003q12vcdbdz4zzh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36 Edg/116.0.1938.76",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "3OwoS3104Q",
            locked: 1717775638,
            timestamp: 1717775636,
          },
        ],
        createdAt: "2024-06-07T15:53:58.706Z",
        updatedAt: "2024-06-07T15:53:58.706Z",
      },
      {
        id: "clx4v9gvt003p12vcj2asdxpx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "zYHZ4UYb1w",
            locked: 1717775637,
            timestamp: 1717775635,
          },
        ],
        createdAt: "2024-06-07T15:53:57.690Z",
        updatedAt: "2024-06-07T15:53:57.690Z",
      },
      {
        id: "clx4v9fso003o12vc3wjcvgqq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ZWZAPtIBT9",
            locked: 1717775635,
            timestamp: 1717775634,
          },
        ],
        createdAt: "2024-06-07T15:53:56.281Z",
        updatedAt: "2024-06-07T15:53:56.281Z",
      },
      {
        id: "clx4v9bv5003n12vcaeite3l1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.3 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6OoM6THmEt",
            locked: 1717775630,
            timestamp: 1717775627,
          },
        ],
        createdAt: "2024-06-07T15:53:51.186Z",
        updatedAt: "2024-06-07T15:53:51.186Z",
      },
      {
        id: "clx4v9708003m12vcpqq0ilqi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36 Edg/125.0.0.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "kno57upVf2",
            locked: 1717775623,
            timestamp: 1717775622,
          },
        ],
        createdAt: "2024-06-07T15:53:44.887Z",
        updatedAt: "2024-06-07T15:53:44.887Z",
      },
      {
        id: "clx4v92yy003l12vc7mkqpa8a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.4951.67 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NuW6YSoKCJ",
            locked: 1717775612,
            timestamp: 1717775601,
          },
        ],
        createdAt: "2024-06-07T15:53:39.658Z",
        updatedAt: "2024-06-07T15:53:39.658Z",
      },
      {
        id: "clx4v92yq003k12vcciy3d32d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.4951.67 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NuW6YSoKCJ",
            locked: 1717775612,
            timestamp: 1717775601,
          },
        ],
        createdAt: "2024-06-07T15:53:39.651Z",
        updatedAt: "2024-06-07T15:53:39.651Z",
      },
      {
        id: "clx4v8ujp003j12vcf7922w6l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36 Edg/112.0.1722.39",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lLwQTv9uNO",
            locked: 1717775608,
            timestamp: 1717775606,
          },
        ],
        createdAt: "2024-06-07T15:53:28.742Z",
        updatedAt: "2024-06-07T15:53:28.742Z",
      },
      {
        id: "clx4v8tg9003i12vchx9ddtcz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.105 Safari/537.36 OPR/70.0.3728.119 (Edition Yx 03)",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "D6i3xmgTzL",
            locked: 1717775607,
            timestamp: 1717775605,
          },
        ],
        createdAt: "2024-06-07T15:53:27.318Z",
        updatedAt: "2024-06-07T15:53:27.318Z",
      },
      {
        id: "clx4v8eq4003h12vcvpuuspyl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,6; iOS 16_7_8; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "b7bmz6uytx",
            locked: 1717775587,
            timestamp: 1717775585,
          },
        ],
        createdAt: "2024-06-07T15:53:08.233Z",
        updatedAt: "2024-06-07T15:53:08.233Z",
      },
      {
        id: "clx4v7dyz003g12vcbyrqwcgg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nwjygtJjML",
            locked: 1717775539,
            timestamp: 1717775535,
          },
        ],
        createdAt: "2024-06-07T15:52:20.600Z",
        updatedAt: "2024-06-07T15:52:20.600Z",
      },
      {
        id: "clx4v5hvj003f12vcl0wufzod",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 330.0.3.29.91 (iPhone14,7; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 595323416)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "14VGL56pYU",
            locked: 1717775451,
            timestamp: 1717775449,
          },
        ],
        createdAt: "2024-06-07T15:50:52.348Z",
        updatedAt: "2024-06-07T15:50:52.348Z",
      },
      {
        id: "clx4v4dqv003e12vcxiv8s2yl",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zCZ01gM739",
            locked: 1717775399,
            timestamp: 1717775397,
          },
        ],
        createdAt: "2024-06-07T15:50:00.341Z",
        updatedAt: "2024-06-07T15:50:00.341Z",
      },
      {
        id: "clx4v3syt003d12vcrmgcaaoi",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "V4dEaARcFh",
            locked: 1717775374,
            timestamp: 1717775373,
          },
        ],
        createdAt: "2024-06-07T15:49:33.413Z",
        updatedAt: "2024-06-07T15:49:33.413Z",
      },
      {
        id: "clx4v3nqo003c12vclhzuiriz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "N22dqpW6WC",
            locked: 1717775367,
            timestamp: 1717775365,
          },
        ],
        createdAt: "2024-06-07T15:49:26.637Z",
        updatedAt: "2024-06-07T15:49:26.637Z",
      },
      {
        id: "clx4v326w003b12vcugdvniy5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "kcwW6VGqsI",
            locked: 1717775338,
            timestamp: 1717775336,
          },
        ],
        createdAt: "2024-06-07T15:48:58.712Z",
        updatedAt: "2024-06-07T15:48:58.712Z",
      },
      {
        id: "clx4v2tjr003a12vc4orsh7or",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone15,2; iOS 17_4_1; en_US; en; scale=3.00; 1179x2556; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "71CfwUcpPH",
            locked: 1717775326,
            timestamp: 1717775325,
          },
        ],
        createdAt: "2024-06-07T15:48:47.512Z",
        updatedAt: "2024-06-07T15:48:47.512Z",
      },
      {
        id: "clx4v2s8o003912vcbd1w1loa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Dt2nnts25C",
            locked: 1717775325,
            timestamp: 1717775324,
          },
        ],
        createdAt: "2024-06-07T15:48:45.813Z",
        updatedAt: "2024-06-07T15:48:45.813Z",
      },
      {
        id: "clx4v2g3g003812vcszluo03w",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "AVB2Cby8T0",
            locked: 1717775309,
            timestamp: 1717775307,
          },
        ],
        createdAt: "2024-06-07T15:48:30.073Z",
        updatedAt: "2024-06-07T15:48:30.073Z",
      },
      {
        id: "clx4v1v6h003712vcixobzdpv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "XtqAbTrn9c",
            locked: 1717775279,
            timestamp: 1717775230,
          },
        ],
        createdAt: "2024-06-07T15:48:02.966Z",
        updatedAt: "2024-06-07T15:48:02.966Z",
      },
      {
        id: "clx4uzmbn003612vcf66oatsh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 9; moto e6 Build/PCBS29.73-81-13; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.53 Mobile Safari/537.36 Instagram 330.0.0.40.92 Android (28/9; 320dpi; 720x1344; motorola; moto e6; surfna; qcom; en_US; 596227217)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xemmZYt8fm",
            locked: 1717775175,
            timestamp: 1717775146,
          },
        ],
        createdAt: "2024-06-07T15:46:18.177Z",
        updatedAt: "2024-06-07T15:46:18.177Z",
      },
      {
        id: "clx4uyufs003512vc97tmqe3z",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 333.1.4.32.95 (iPhone14,3; iOS 17_4_1; en_TH; en; scale=3.00; 1284x2778; 604797909)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "waQNIz3OU6",
            locked: 1717775141,
            timestamp: 1717775140,
          },
        ],
        createdAt: "2024-06-07T15:45:42.041Z",
        updatedAt: "2024-06-07T15:45:42.041Z",
      },
      {
        id: "clx4uyjs2003412vc7pm0ynkc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "vuxkgX3iys",
            locked: 1717775127,
            timestamp: 1717775125,
          },
        ],
        createdAt: "2024-06-07T15:45:28.223Z",
        updatedAt: "2024-06-07T15:45:28.223Z",
      },
      {
        id: "clx4uy5vh003312vcbiiaq0gv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,3; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "1CQKrck6OG",
            locked: 1717775109,
            timestamp: 1717775108,
          },
        ],
        createdAt: "2024-06-07T15:45:10.202Z",
        updatedAt: "2024-06-07T15:45:10.202Z",
      },
      {
        id: "clx4uxri9003212vcbm2h4343",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,4; iOS 17_4_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Bk46u6KvNV",
            locked: 1717775091,
            timestamp: 1717775088,
          },
        ],
        createdAt: "2024-06-07T15:44:51.583Z",
        updatedAt: "2024-06-07T15:44:51.583Z",
      },
      {
        id: "clx4uxcpv003112vco8yn1htm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 332.0.2.29.94 (iPhone14,3; iOS 17_4_1; en_US; en; scale=3.00; 1284x2778; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "NVwxHnNNlO",
            locked: 1717775071,
            timestamp: 1717775069,
          },
        ],
        createdAt: "2024-06-07T15:44:32.416Z",
        updatedAt: "2024-06-07T15:44:32.416Z",
      },
      {
        id: "clx4uuzqt003012vck5arqdpa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "4zFtZ1dQUf",
            locked: 1717774962,
            timestamp: 1717774960,
          },
        ],
        createdAt: "2024-06-07T15:42:42.293Z",
        updatedAt: "2024-06-07T15:42:42.293Z",
      },
      {
        id: "clx4uuyib002z12vcwk59wkdx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "HTesAVmflU",
            locked: 1717774960,
            timestamp: 1717774959,
          },
        ],
        createdAt: "2024-06-07T15:42:40.691Z",
        updatedAt: "2024-06-07T15:42:40.691Z",
      },
      {
        id: "clx4uuvlt002y12vcti5hqjwd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "9ObpoVUkmp",
            locked: 1717774956,
            timestamp: 1717774955,
          },
        ],
        createdAt: "2024-06-07T15:42:36.926Z",
        updatedAt: "2024-06-07T15:42:36.926Z",
      },
      {
        id: "clx4utjn9002x12vcvbkkogcn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-G970F Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/466.1.0.57.85;]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ID6nN59jDs",
            locked: 1717774894,
            timestamp: 1717774890,
          },
        ],
        createdAt: "2024-06-07T15:41:34.771Z",
        updatedAt: "2024-06-07T15:41:34.771Z",
      },
      {
        id: "clx4urwjd002w12vczzoiisq2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "zd4ZynIU3X",
            locked: 1717774818,
            timestamp: 1717774817,
          },
        ],
        createdAt: "2024-06-07T15:40:18.169Z",
        updatedAt: "2024-06-07T15:40:18.169Z",
      },
      {
        id: "clx4urr4b002v12vcbzzcyzrf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GcJwhhIGbT",
            locked: 1717774810,
            timestamp: 1717774809,
          },
        ],
        createdAt: "2024-06-07T15:40:11.145Z",
        updatedAt: "2024-06-07T15:40:11.145Z",
      },
      {
        id: "clx4uquwx002u12vcigrai55m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "VdG7TCt52G",
            locked: 1717774768,
            timestamp: 1717774767,
          },
        ],
        createdAt: "2024-06-07T15:39:29.406Z",
        updatedAt: "2024-06-07T15:39:29.406Z",
      },
      {
        id: "clx4uoonf002t12vceqctwqf4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Fujx0w5IHA",
            locked: 1717785461,
            timestamp: 1717785460,
          },
        ],
        createdAt: "2024-06-07T15:37:47.977Z",
        updatedAt: "2024-06-07T15:37:47.977Z",
      },
      {
        id: "clx4umxaf002s12vcfw4tgwsj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EDlUA55xUI",
            locked: 1717774585,
            timestamp: 1717774582,
          },
        ],
        createdAt: "2024-06-07T15:36:25.860Z",
        updatedAt: "2024-06-07T15:36:25.860Z",
      },
      {
        id: "clx4umq21002r12vcdar0tiah",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 [FBAN/FBIOS;FBAV/466.0.0.36.107;FBBV/604687626;FBDV/iPhone15,3;FBMD/iPhone;FBSN/iOS;FBSV/17.4.1;FBSS/3;FBID/phone;FBLC/en_US;FBOP/5;FBRV/607971658]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "QBJt84zzcI",
            locked: 1717774575,
            timestamp: 1717774574,
          },
        ],
        createdAt: "2024-06-07T15:36:16.489Z",
        updatedAt: "2024-06-07T15:36:16.489Z",
      },
      {
        id: "clx4ummob002q12vc6phuuhb8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "NxSlSXP8LX",
            locked: 1717774572,
            timestamp: 1717774571,
          },
        ],
        createdAt: "2024-06-07T15:36:12.107Z",
        updatedAt: "2024-06-07T15:36:12.107Z",
      },
      {
        id: "clx4umi5u002p12vcxzw4lrkh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Qi16Xz0GxU",
            locked: 1717774566,
            timestamp: 1717774564,
          },
        ],
        createdAt: "2024-06-07T15:36:06.255Z",
        updatedAt: "2024-06-07T15:36:06.255Z",
      },
      {
        id: "clx4ulxk4002o12vc0oldsslz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-A536U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 480dpi; 1080x2168; samsung; SM-A536U; a53x; s5e8825; en_US; 604247760)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3BSAd4YeGH",
            locked: 1717774540,
            timestamp: 1717774535,
          },
        ],
        createdAt: "2024-06-07T15:35:39.553Z",
        updatedAt: "2024-06-07T15:35:39.553Z",
      },
      {
        id: "clx4ul7jg002n12vcg34wx0hg",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "t4H9u8HiP1",
            locked: 1717774503,
            timestamp: 1717774498,
          },
        ],
        createdAt: "2024-06-07T15:35:05.834Z",
        updatedAt: "2024-06-07T15:35:05.834Z",
      },
      {
        id: "clx4ukd3z002m12vc91619c74",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "fSzyuSGtQb",
            locked: 1717774466,
            timestamp: 1717774465,
          },
        ],
        createdAt: "2024-06-07T15:34:26.399Z",
        updatedAt: "2024-06-07T15:34:26.399Z",
      },
      {
        id: "clx4uka98002l12vcoy40home",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "P7n56grljg",
            locked: 1717774461,
            timestamp: 1717774459,
          },
        ],
        createdAt: "2024-06-07T15:34:22.697Z",
        updatedAt: "2024-06-07T15:34:22.697Z",
      },
      {
        id: "clx4uj43b002k12vcg1qjry9a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "PKC0YqsQk8",
            locked: 1717774407,
            timestamp: 1717774406,
          },
        ],
        createdAt: "2024-06-07T15:33:28.056Z",
        updatedAt: "2024-06-07T15:33:28.056Z",
      },
      {
        id: "clx4uixg4002j12vc7rgb07a2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_7_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20H343 Instagram 334.0.4.32.98 (iPhone10,5; iOS 16_7_8; en_US; en; scale=3.00; 1242x2208; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "yAXoAg2aAZ",
            locked: 1717774398,
            timestamp: 1717774396,
          },
        ],
        createdAt: "2024-06-07T15:33:19.442Z",
        updatedAt: "2024-06-07T15:33:19.442Z",
      },
      {
        id: "clx4uds45002i12vc54fibxhz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "L19b2GrMiC",
            locked: 1717774159,
            timestamp: 1717774158,
          },
        ],
        createdAt: "2024-06-07T15:29:19.253Z",
        updatedAt: "2024-06-07T15:29:19.253Z",
      },
      {
        id: "clx4udrk1002h12vc1bdn6t9j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_CA; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PE0GDwcv35",
            locked: 1717774158,
            timestamp: 1717774157,
          },
        ],
        createdAt: "2024-06-07T15:29:18.526Z",
        updatedAt: "2024-06-07T15:29:18.526Z",
      },
      {
        id: "clx4ucppv002g12vckystma38",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "vl3lBOW63Q",
            locked: 1717774109,
            timestamp: 1717774108,
          },
        ],
        createdAt: "2024-06-07T15:28:29.491Z",
        updatedAt: "2024-06-07T15:28:29.491Z",
      },
      {
        id: "clx4ucoal002f12vcsqhmb4kq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "H7pJLlHegH",
            locked: 1717774107,
            timestamp: 1717774102,
          },
        ],
        createdAt: "2024-06-07T15:28:27.646Z",
        updatedAt: "2024-06-07T15:28:27.646Z",
      },
      {
        id: "clx4ucjmx002e12vcayshyc8x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "usFdbzbulJ",
            locked: 1717774101,
            timestamp: 1717774099,
          },
        ],
        createdAt: "2024-06-07T15:28:21.607Z",
        updatedAt: "2024-06-07T15:28:21.607Z",
      },
      {
        id: "clx4ubuy0002d12vcpzgn5u6l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "cjisMx4YlR",
            locked: 1717774068,
            timestamp: 1717774065,
          },
        ],
        createdAt: "2024-06-07T15:27:49.586Z",
        updatedAt: "2024-06-07T15:27:49.586Z",
      },
      {
        id: "clx4ub2px002c12vc13efgl6b",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,4; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "wERKmZcDEC",
            locked: 1717774032,
            timestamp: 1717774031,
          },
        ],
        createdAt: "2024-06-07T15:27:13.026Z",
        updatedAt: "2024-06-07T15:27:13.026Z",
      },
      {
        id: "clx4ua0rv002b12vc0iy3cv4y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "8ThZ6VuLZS",
            locked: 1717773968,
            timestamp: 1717773967,
          },
        ],
        createdAt: "2024-06-07T15:26:23.849Z",
        updatedAt: "2024-06-07T15:26:23.849Z",
      },
      {
        id: "clx4u9nbc002a12vcslfn82ki",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "HQFU2t6f6c",
            locked: 1717773950,
            timestamp: 1717773949,
          },
        ],
        createdAt: "2024-06-07T15:26:06.406Z",
        updatedAt: "2024-06-07T15:26:06.406Z",
      },
      {
        id: "clx4u91dr002912vc3id4yepc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S901U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 480dpi; 1080x2115; samsung; SM-S901U; r0q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ah3RSS9itT",
            locked: 1717773937,
            timestamp: 1717773934,
          },
        ],
        createdAt: "2024-06-07T15:25:37.983Z",
        updatedAt: "2024-06-07T15:25:37.983Z",
      },
      {
        id: "clx4u8pxx002812vc2rwa6l5l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S908U Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.54 Mobile Safari/537.36 Instagram 333.0.0.42.91 Android (34/14; 540dpi; 1080x2079; samsung; SM-S908U; b0q; qcom; en_US; 604247845)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "gJofSMJjJk",
            locked: 1717773922,
            timestamp: 1717773908,
          },
        ],
        createdAt: "2024-06-07T15:25:23.155Z",
        updatedAt: "2024-06-07T15:25:23.155Z",
      },
      {
        id: "clx4u7yxc002712vcbaph617q",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "qPbe8YK828",
            locked: 1717773887,
            timestamp: 1717773885,
          },
        ],
        createdAt: "2024-06-07T15:24:48.142Z",
        updatedAt: "2024-06-07T15:24:48.142Z",
      },
      {
        id: "clx4u7und002612vcnty0bwdq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "i0exWHDdwR",
            locked: 1717773881,
            timestamp: 1717773878,
          },
        ],
        createdAt: "2024-06-07T15:24:42.601Z",
        updatedAt: "2024-06-07T15:24:42.601Z",
      },
      {
        id: "clx4u7n6w002512vc8f39re01",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,8; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Y8UM7YP2tl",
            locked: 1717773872,
            timestamp: 1717773871,
          },
        ],
        createdAt: "2024-06-07T15:24:32.933Z",
        updatedAt: "2024-06-07T15:24:32.933Z",
      },
      {
        id: "clx4u6hyx002412vc76kowqvm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:126.0) Gecko/20100101 Firefox/126.0",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jsL4ajjapO",
            locked: 1717784612,
            timestamp: 1717784610,
          },
        ],
        createdAt: "2024-06-07T15:23:39.510Z",
        updatedAt: "2024-06-07T15:23:39.510Z",
      },
      {
        id: "clx4u5k3c002312vck48mhhx8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 12; SM-F711U1 Build/SP2A.220305.013; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.148 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (32/12; 480dpi; 1080x2402; samsung; SM-F711U1; b2q; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RoSPUuUK28",
            locked: 1717773774,
            timestamp: 1717773770,
          },
        ],
        createdAt: "2024-06-07T15:22:55.605Z",
        updatedAt: "2024-06-07T15:22:55.605Z",
      },
      {
        id: "clx4u517x002212vcmy1eazq1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "EICj5ePAQZ",
            locked: 1717773750,
            timestamp: 1717773747,
          },
        ],
        createdAt: "2024-06-07T15:22:31.147Z",
        updatedAt: "2024-06-07T15:22:31.147Z",
      },
      {
        id: "clx4u2yiu002112vc4jkx600s",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone13,4; iOS 16_6_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "91OxC70s0T",
            locked: 1717773653,
            timestamp: 1717773651,
          },
        ],
        createdAt: "2024-06-07T15:20:54.343Z",
        updatedAt: "2024-06-07T15:20:54.343Z",
      },
      {
        id: "clx4u2nmp002012vc4ytuho3x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 [FBAN/FBIOS;FBAV/466.0.0.36.107;FBBV/604687626;FBDV/iPhone12,5;FBMD/iPhone;FBSN/iOS;FBSV/17.5.1;FBSS/3;FBID/phone;FBLC/en_US;FBOP/5;FBRV/607971658]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "SeU7m006UI",
            locked: 1717773639,
            timestamp: 1717773638,
          },
        ],
        createdAt: "2024-06-07T15:20:40.222Z",
        updatedAt: "2024-06-07T15:20:40.222Z",
      },
      {
        id: "clx4u20hi001z12vcxs7w1zhm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "1iRopIv8Yd",
            locked: 1717773609,
            timestamp: 1717773608,
          },
        ],
        createdAt: "2024-06-07T15:20:10.230Z",
        updatedAt: "2024-06-07T15:20:10.230Z",
      },
      {
        id: "clx4u1z6p001y12vckp7fag2g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "nYPY2LDnCS",
            locked: 1717773607,
            timestamp: 1717773605,
          },
        ],
        createdAt: "2024-06-07T15:20:08.542Z",
        updatedAt: "2024-06-07T15:20:08.542Z",
      },
      {
        id: "clx4u1kwh001x12vcj1x6tj5g",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "z0HmUy8cbY",
            locked: 1717773589,
            timestamp: 1717773588,
          },
        ],
        createdAt: "2024-06-07T15:19:50.034Z",
        updatedAt: "2024-06-07T15:19:50.034Z",
      },
      {
        id: "clx4u1gqm001w12vcazsr7svx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,8; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "nGoAkrjk5m",
            locked: 1717773584,
            timestamp: 1717773582,
          },
        ],
        createdAt: "2024-06-07T15:19:44.639Z",
        updatedAt: "2024-06-07T15:19:44.639Z",
      },
      {
        id: "clx4u1gk1001v12vcjfx5tvt4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "xMozz6qAq1",
            locked: 1717773583,
            timestamp: 1717773578,
          },
        ],
        createdAt: "2024-06-07T15:19:44.398Z",
        updatedAt: "2024-06-07T15:19:44.398Z",
      },
      {
        id: "clx4u0zmx001u12vcesaktoek",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 14; SM-S928U1 Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.136 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (34/14; 420dpi; 1080x2138; samsung; SM-S928U1; e3q; qcom; en_US; 606558538)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "H9k6faGxBp",
            locked: 1717773561,
            timestamp: 1717773559,
          },
        ],
        createdAt: "2024-06-07T15:19:22.469Z",
        updatedAt: "2024-06-07T15:19:22.469Z",
      },
      {
        id: "clx4u0lg4001t12vck7fwv55d",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_AU; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "vfs5QKbEOG",
            locked: 1717773543,
            timestamp: 1717773542,
          },
        ],
        createdAt: "2024-06-07T15:19:04.082Z",
        updatedAt: "2024-06-07T15:19:04.082Z",
      },
      {
        id: "clx4u0anm001s12vcvp8s9vhn",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/125.0.6422.80 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "jddlRJqGeJ",
            locked: 1717773529,
            timestamp: 1717773528,
          },
        ],
        createdAt: "2024-06-07T15:18:50.098Z",
        updatedAt: "2024-06-07T15:18:50.098Z",
      },
      {
        id: "clx4u0749001r12vcoyriedf3",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_AU; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OHCn2J81Fc",
            locked: 1717773524,
            timestamp: 1717773523,
          },
        ],
        createdAt: "2024-06-07T15:18:45.511Z",
        updatedAt: "2024-06-07T15:18:45.511Z",
      },
      {
        id: "clx4tzuba001q12vcaq2o2yy5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/319.0.638705450 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Uvp806Z9xd",
            locked: 1717773508,
            timestamp: 1717773507,
          },
        ],
        createdAt: "2024-06-07T15:18:28.915Z",
        updatedAt: "2024-06-07T15:18:28.915Z",
      },
      {
        id: "clx4tufad001p12vc9lwwplvb",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,6; iOS 17_5_1; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "F5IHO7qlF9",
            locked: 1717773255,
            timestamp: 1717773254,
          },
        ],
        createdAt: "2024-06-07T15:14:16.162Z",
        updatedAt: "2024-06-07T15:14:16.162Z",
      },
      {
        id: "clx4ttih1001o12vcw8k5m6cj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20B110 Instagram 334.0.4.32.98 (iPhone12,1; iOS 16_1_2; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tfWMfZ8A5a",
            locked: 1717773213,
            timestamp: 1717773210,
          },
        ],
        createdAt: "2024-06-07T15:13:33.637Z",
        updatedAt: "2024-06-07T15:13:33.637Z",
      },
      {
        id: "clx4ttfhz001n12vcus58q245",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "L631p0fmdF",
            locked: 1717773209,
            timestamp: 1717773206,
          },
        ],
        createdAt: "2024-06-07T15:13:29.782Z",
        updatedAt: "2024-06-07T15:13:29.782Z",
      },
      {
        id: "clx4tt81t001m12vc5tzv0hcj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-G960U Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 480dpi; 1080x2076; samsung; SM-G960U; starqltesq; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "wqUUCD1FrZ",
            locked: 1717773199,
            timestamp: 1717773198,
          },
        ],
        createdAt: "2024-06-07T15:13:20.129Z",
        updatedAt: "2024-06-07T15:13:20.129Z",
      },
      {
        id: "clx4tsz56001l12vcm1vqcofy",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Linux; Android 10; SM-G960U Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.180 Mobile Safari/537.36 Instagram 334.0.0.42.95 Android (29/10; 480dpi; 1080x2076; samsung; SM-G960U; starqltesq; qcom; en_US; 606558551)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "mBG6ziTWuE",
            locked: 1717773187,
            timestamp: 1717773185,
          },
        ],
        createdAt: "2024-06-07T15:13:08.583Z",
        updatedAt: "2024-06-07T15:13:08.583Z",
      },
      {
        id: "clx4tqfkw001k12vcjrnabdgs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,7; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "46ZxJng0fo",
            locked: 1717773068,
            timestamp: 1717773067,
          },
        ],
        createdAt: "2024-06-07T15:11:09.918Z",
        updatedAt: "2024-06-07T15:11:09.918Z",
      },
      {
        id: "clx4tph6y001j12vc0i37f6wd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,2; iOS 17_5_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "3CmgbWnV98",
            locked: 1717773024,
            timestamp: 1717773023,
          },
        ],
        createdAt: "2024-06-07T15:10:25.351Z",
        updatedAt: "2024-06-07T15:10:25.351Z",
      },
      {
        id: "clx4tokf4001i12vcq6dqtk7m",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "30kpdQdMtY",
            locked: 1717772982,
            timestamp: 1717772979,
          },
        ],
        createdAt: "2024-06-07T15:09:42.880Z",
        updatedAt: "2024-06-07T15:09:42.880Z",
      },
      {
        id: "clx4toh3n001h12vc4p52lhr0",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Mcj4cZluX3",
            locked: 1717772978,
            timestamp: 1717772976,
          },
        ],
        createdAt: "2024-06-07T15:09:38.579Z",
        updatedAt: "2024-06-07T15:09:38.579Z",
      },
      {
        id: "clx4to9di001g12vc4avatly7",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,3; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "koad1hMyc2",
            locked: 1717772967,
            timestamp: 1717772966,
          },
        ],
        createdAt: "2024-06-07T15:09:28.564Z",
        updatedAt: "2024-06-07T15:09:28.564Z",
      },
      {
        id: "clx4tnyno001f12vcpxaljsqh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.198 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bHxxfw3isV",
            locked: 1717772951,
            timestamp: 1717772942,
          },
        ],
        createdAt: "2024-06-07T15:09:14.676Z",
        updatedAt: "2024-06-07T15:09:14.676Z",
      },
      {
        id: "clx4tntxj001e12vcfk552ugv",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "Ibqxb21WSX",
            locked: 1717772948,
            timestamp: 1717772947,
          },
        ],
        createdAt: "2024-06-07T15:09:08.548Z",
        updatedAt: "2024-06-07T15:09:08.548Z",
      },
      {
        id: "clx4tmapi001d12vca09nf4ty",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 313.0.2.9.339 (iPhone14,2; iOS 17_5_1; en_US; en; scale=3.00; 960x2079; 553462334)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "n9Xejvgc2K",
            locked: 1717772876,
            timestamp: 1717772875,
          },
        ],
        createdAt: "2024-06-07T15:07:56.979Z",
        updatedAt: "2024-06-07T15:07:56.979Z",
      },
      {
        id: "clx4tlcuc001c12vcgcdzi276",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LY27e7fxNw",
            locked: 1717772832,
            timestamp: 1717772831,
          },
        ],
        createdAt: "2024-06-07T15:07:13.089Z",
        updatedAt: "2024-06-07T15:07:13.089Z",
      },
      {
        id: "clx4tkh7w001b12vcxixuylgf",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "VOWQnXFNvT",
            locked: 1717772792,
            timestamp: 1717772791,
          },
        ],
        createdAt: "2024-06-07T15:06:32.105Z",
        updatedAt: "2024-06-07T15:06:32.105Z",
      },
      {
        id: "clx4tjrxa001a12vcbsd929ps",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/20G81 Instagram 334.0.4.32.98 (iPhone14,8; iOS 16_6_1; en_US; en; scale=3.00; 1284x2778; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "tIpQUlhW8v",
            locked: 1717772758,
            timestamp: 1717772757,
          },
        ],
        createdAt: "2024-06-07T15:05:59.324Z",
        updatedAt: "2024-06-07T15:05:59.324Z",
      },
      {
        id: "clx4tji6j001912vcj969fgur",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "dWUzVsTP9v",
            locked: 1717772746,
            timestamp: 1717772744,
          },
        ],
        createdAt: "2024-06-07T15:05:46.699Z",
        updatedAt: "2024-06-07T15:05:46.699Z",
      },
      {
        id: "clx4tjfh4001812vcpg3pka94",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jmoqu30DEV",
            locked: 1717772749,
            timestamp: 1717772747,
          },
        ],
        createdAt: "2024-06-07T15:05:43.192Z",
        updatedAt: "2024-06-07T15:05:43.192Z",
      },
      {
        id: "clx4tj7du001712vcvarairoj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone13,1; iOS 17_5_1; en_US; en; scale=3.00; 1125x2436; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "6IUZsPkJnZ",
            locked: 1717772729,
            timestamp: 1717772728,
          },
        ],
        createdAt: "2024-06-07T15:05:32.705Z",
        updatedAt: "2024-06-07T15:05:32.705Z",
      },
      {
        id: "clx4tj012001612vchpbld1zj",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone14,5; iOS 17_4_1; en_US; en; scale=3.00; 1170x2532; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "ZNFLS5trhp",
            locked: 1717772722,
            timestamp: 1717772721,
          },
        ],
        createdAt: "2024-06-07T15:05:23.174Z",
        updatedAt: "2024-06-07T15:05:23.174Z",
      },
      {
        id: "clx4titr3001512vcyrswvxnt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "MaMcHDVnr1",
            locked: 1717772714,
            timestamp: 1717772713,
          },
        ],
        createdAt: "2024-06-07T15:05:15.037Z",
        updatedAt: "2024-06-07T15:05:15.037Z",
      },
      {
        id: "clx4ti2bg001412vcn40ugw9a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 [FBAN/FBIOS;FBAV/447.0.0.43.106;FBBV/570778047;FBDV/iPhone14,5;FBMD/iPhone;FBSN/iOS;FBSV/16.1;FBSS/3;FBCR/;FBID/phone;FBLC/nl;FBOP/80]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "SGHaw3DjaJ",
            locked: 1717772679,
            timestamp: 1717772678,
          },
        ],
        createdAt: "2024-06-07T15:04:39.485Z",
        updatedAt: "2024-06-07T15:04:39.485Z",
      },
      {
        id: "clx4thuyq001312vcwewshazp",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone11,8; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "ptoW4O2pfg",
            locked: 1717772669,
            timestamp: 1717772667,
          },
        ],
        createdAt: "2024-06-07T15:04:29.954Z",
        updatedAt: "2024-06-07T15:04:29.954Z",
      },
      {
        id: "clx4tht4e001212vc75hym4r5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 [FBAN/FBIOS;FBAV/447.0.0.43.106;FBBV/570778047;FBDV/iPhone14,5;FBMD/iPhone;FBSN/iOS;FBSV/16.1;FBSS/3;FBCR/;FBID/phone;FBLC/nl;FBOP/80]",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "QDGZAG8Mau",
            locked: 1717772667,
            timestamp: 1717772666,
          },
        ],
        createdAt: "2024-06-07T15:04:27.563Z",
        updatedAt: "2024-06-07T15:04:27.563Z",
      },
      {
        id: "clx4tgp91001112vcrezz0ik2",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Ce825LU25U",
            locked: 1717772615,
            timestamp: 1717772614,
          },
        ],
        createdAt: "2024-06-07T15:03:35.891Z",
        updatedAt: "2024-06-07T15:03:35.891Z",
      },
      {
        id: "clx4tgche001012vcb1nfs63l",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "d91Q7oZCs2",
            locked: 1717772598,
            timestamp: 1717772597,
          },
        ],
        createdAt: "2024-06-07T15:03:19.344Z",
        updatedAt: "2024-06-07T15:03:19.344Z",
      },
      {
        id: "clx4tf6e6000z12vcln1tp28h",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 298.0.0.19.114 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 509296496)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "zFwDqf8bqu",
            locked: 1717772544,
            timestamp: 1717772542,
          },
        ],
        createdAt: "2024-06-07T15:02:24.796Z",
        updatedAt: "2024-06-07T15:02:24.796Z",
      },
      {
        id: "clx4tefkz000y12vcvc10vrwx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711) NW/3",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "s7k6XFnzF3",
            locked: 1717772509,
            timestamp: 1717772508,
          },
        ],
        createdAt: "2024-06-07T15:01:50.049Z",
        updatedAt: "2024-06-07T15:01:50.049Z",
      },
      {
        id: "clx4td9li000x12vcuc3n5tuc",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21E236 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_4_1; en_US; en; scale=2.00; 828x1792; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "6oG9kOkRc1",
            locked: 1717772455,
            timestamp: 1717772451,
          },
        ],
        createdAt: "2024-06-07T15:00:55.635Z",
        updatedAt: "2024-06-07T15:00:55.635Z",
      },
      {
        id: "clx4tc6l9000w12vculpketcw",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "aiJO3IfjFr",
            locked: 1717772404,
            timestamp: 1717772403,
          },
        ],
        createdAt: "2024-06-07T15:00:05.086Z",
        updatedAt: "2024-06-07T15:00:05.086Z",
      },
      {
        id: "clx4tc5cq000v12vcbaw48set",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "SNUOvrJOuo",
            locked: 1717772402,
            timestamp: 1717772401,
          },
        ],
        createdAt: "2024-06-07T15:00:03.482Z",
        updatedAt: "2024-06-07T15:00:03.482Z",
      },
      {
        id: "clx4tc3y0000u12vcn8cuxgsm",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "GbDkrlVEkj",
            locked: 1717772401,
            timestamp: 1717772400,
          },
        ],
        createdAt: "2024-06-07T15:00:01.657Z",
        updatedAt: "2024-06-07T15:00:01.657Z",
      },
      {
        id: "clx4tc2dz000t12vcx0oz101e",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "lTfAMbRViW",
            locked: 1717772399,
            timestamp: 1717772397,
          },
        ],
        createdAt: "2024-06-07T14:59:59.640Z",
        updatedAt: "2024-06-07T14:59:59.640Z",
      },
      {
        id: "clx4tc1tm000s12vcosyzr9os",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "Qk9WN8xGwv",
            locked: 1717772398,
            timestamp: 1717772397,
          },
        ],
        createdAt: "2024-06-07T14:59:58.906Z",
        updatedAt: "2024-06-07T14:59:58.906Z",
      },
      {
        id: "clx4tc0ui000r12vcemaehjwq",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "bdbj6sYizl",
            locked: 1717772395,
            timestamp: 1717772394,
          },
        ],
        createdAt: "2024-06-07T14:59:57.640Z",
        updatedAt: "2024-06-07T14:59:57.640Z",
      },
      {
        id: "clx4tbi1h000q12vcu1lfliz5",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone12,1; iOS 17_5_1; en_US; en; scale=2.00; 828x1792; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2Up9yK0JzO",
            locked: 1717772372,
            timestamp: 1717772371,
          },
        ],
        createdAt: "2024-06-07T14:59:33.269Z",
        updatedAt: "2024-06-07T14:59:33.269Z",
      },
      {
        id: "clx4tbd3n000p12vce480nq2f",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "KCATDzPSCE",
            locked: 1717772367,
            timestamp: 1717772366,
          },
        ],
        createdAt: "2024-06-07T14:59:26.868Z",
        updatedAt: "2024-06-07T14:59:26.868Z",
      },
      {
        id: "clx4tbcnc000o12vc0jnzacka",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "jeKzmBRpQe",
            locked: 1717772366,
            timestamp: 1717772364,
          },
        ],
        createdAt: "2024-06-07T14:59:26.278Z",
        updatedAt: "2024-06-07T14:59:26.278Z",
      },
      {
        id: "clx4t9gf3000n12vcibhqhpxo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Xst41IESh2",
            locked: 1717772277,
            timestamp: 1717772275,
          },
        ],
        createdAt: "2024-06-07T14:57:57.852Z",
        updatedAt: "2024-06-07T14:57:57.852Z",
      },
      {
        id: "clx4t8oag000m12vcmdq267t1",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "VnVyhQThpF",
            locked: 1717772241,
            timestamp: 1717772239,
          },
        ],
        createdAt: "2024-06-07T14:57:21.400Z",
        updatedAt: "2024-06-07T14:57:21.400Z",
      },
      {
        id: "clx4t8mwc000l12vclf3z28ch",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 15_8_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19H384 Instagram 334.0.4.32.98 (iPhone9,3; iOS 15_8_2; en_US; en; scale=2.00; 750x1334; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "zjrsoPL2nV",
            locked: 1717772238,
            timestamp: 1717772229,
          },
        ],
        createdAt: "2024-06-07T14:57:19.597Z",
        updatedAt: "2024-06-07T14:57:19.597Z",
      },
      {
        id: "clx4t8fm5000k12vc1ogsrfvt",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "Ajpnojc8Of",
            locked: 1717772229,
            timestamp: 1717772227,
          },
        ],
        createdAt: "2024-06-07T14:57:10.155Z",
        updatedAt: "2024-06-07T14:57:10.155Z",
      },
      {
        id: "clx4t5csm000j12vc1awc85nz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 309.0.1.24.108 (iPhone15,4; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 536373149)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "GE1OdaYzpE",
            locked: 1717772085,
            timestamp: 1717772084,
          },
        ],
        createdAt: "2024-06-07T14:54:46.532Z",
        updatedAt: "2024-06-07T14:54:46.532Z",
      },
      {
        id: "clx4t4x4d000i12vcckay2q1x",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 332.0.2.29.94 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 600349675)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "yDKP9CM1zu",
            locked: 1717772064,
            timestamp: 1717772063,
          },
        ],
        createdAt: "2024-06-07T14:54:26.221Z",
        updatedAt: "2024-06-07T14:54:26.221Z",
      },
      {
        id: "clx4t4qvy000h12vchrk9wl8y",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "776z8DrP2T",
            locked: 1717772057,
            timestamp: 1717772056,
          },
        ],
        createdAt: "2024-06-07T14:54:18.142Z",
        updatedAt: "2024-06-07T14:54:18.142Z",
      },
      {
        id: "clx4t4nby000g12vcyxf2vc26",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "LdTbYdcCYy",
            locked: 1717772052,
            timestamp: 1717772051,
          },
        ],
        createdAt: "2024-06-07T14:54:13.532Z",
        updatedAt: "2024-06-07T14:54:13.532Z",
      },
      {
        id: "clx4t42l4000f12vcutp8ewxo",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "pa9qD0pfVr",
            locked: 1717772026,
            timestamp: 1717772025,
          },
        ],
        createdAt: "2024-06-07T14:53:46.648Z",
        updatedAt: "2024-06-07T14:53:46.648Z",
      },
      {
        id: "clx4t40mm000e12vcf6xq1kel",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "RDfyCPYC2v",
            locked: 1717772023,
            timestamp: 1717772019,
          },
        ],
        createdAt: "2024-06-07T14:53:44.110Z",
        updatedAt: "2024-06-07T14:53:44.110Z",
      },
      {
        id: "clx4t3wn9000d12vc0r2ky850",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "N4GxTOlmJm",
            locked: 1717772018,
            timestamp: 1717772017,
          },
        ],
        createdAt: "2024-06-07T14:53:38.949Z",
        updatedAt: "2024-06-07T14:53:38.949Z",
      },
      {
        id: "clx4t3v0q000c12vcsftohilx",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "OmetOHY5oG",
            locked: 1717772016,
            timestamp: 1717772015,
          },
        ],
        createdAt: "2024-06-07T14:53:36.839Z",
        updatedAt: "2024-06-07T14:53:36.839Z",
      },
      {
        id: "clx4t34eb000b12vchl6g2jkr",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "eK5FoC7eOz",
            locked: 1717771981,
            timestamp: 1717771980,
          },
        ],
        createdAt: "2024-06-07T14:53:02.336Z",
        updatedAt: "2024-06-07T14:53:02.336Z",
      },
      {
        id: "clx4t2084000a12vctzhtvqb9",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone14,3; iOS 17_5_1; en_US; en; scale=3.00; 1284x2778; 605596711) NW/1",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "UrAZZfcYtY",
            locked: 1717771930,
            timestamp: 1717771928,
          },
        ],
        createdAt: "2024-06-07T14:52:10.277Z",
        updatedAt: "2024-06-07T14:52:10.277Z",
      },
      {
        id: "clx4t1tqy000912vctexcdh4n",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "HSEShpeQwP",
            locked: 1717771921,
            timestamp: 1717771920,
          },
        ],
        createdAt: "2024-06-07T14:52:01.881Z",
        updatedAt: "2024-06-07T14:52:01.881Z",
      },
      {
        id: "clx4t1pa7000812vclbh4c0nh",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["665d9489640742db3fb162c7"],
            },
            type: "click",
            value: 0,
            lockId: "kUVqayoPj5",
            locked: 1717771916,
            timestamp: 1717771915,
          },
        ],
        createdAt: "2024-06-07T14:51:56.096Z",
        updatedAt: "2024-06-07T14:51:56.096Z",
      },
      {
        id: "clx4t1p25000712vc53rm3mxs",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "pH73hADCOH",
            locked: 1717771915,
            timestamp: 1717771913,
          },
        ],
        createdAt: "2024-06-07T14:51:55.806Z",
        updatedAt: "2024-06-07T14:51:55.806Z",
      },
      {
        id: "clx4t1fd4000612vcffdjjvgz",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone16,1; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "xLonFQCzjJ",
            locked: 1717771902,
            timestamp: 1717771901,
          },
        ],
        createdAt: "2024-06-07T14:51:43.237Z",
        updatedAt: "2024-06-07T14:51:43.237Z",
      },
      {
        id: "clx4t01od000512vcgms6jok4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "osPt7pIXmr",
            locked: 1717771838,
            timestamp: 1717771837,
          },
        ],
        createdAt: "2024-06-07T14:50:38.846Z",
        updatedAt: "2024-06-07T14:50:38.846Z",
      },
      {
        id: "clx4szzmk000412vcezc8tu4a",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "iqoLlMM9Qa",
            locked: 1717771835,
            timestamp: 1717771833,
          },
        ],
        createdAt: "2024-06-07T14:50:36.185Z",
        updatedAt: "2024-06-07T14:50:36.185Z",
      },
      {
        id: "clx4szpe5000312vctcnii2cd",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            goal: {
              _id: "6659c4fee393609d8c62e42d",
              variations: ["6659c441e393609d8c62e3ad"],
            },
            type: "click",
            value: 0,
            lockId: "tw1NUtdr7o",
            locked: 1717771822,
            timestamp: 1717771821,
          },
        ],
        createdAt: "2024-06-07T14:50:22.925Z",
        updatedAt: "2024-06-07T14:50:22.925Z",
      },
      {
        id: "clx4szhb6000212vc0hvtio0j",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AyV0hrlNuC",
            locked: 1717771811,
            timestamp: 1717771810,
          },
        ],
        createdAt: "2024-06-07T14:50:12.449Z",
        updatedAt: "2024-06-07T14:50:12.449Z",
      },
      {
        id: "clx4sze7r000112vc2mxct4f8",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 334.0.4.32.98 (iPhone15,2; iOS 17_5_1; en_US; en; scale=3.00; 1179x2556; 605596711)",
            device_type: "mobile",
            visitor_type: "new",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "AAYWF3fYVe",
            locked: 1717771807,
            timestamp: 1717771806,
          },
        ],
        createdAt: "2024-06-07T14:50:08.440Z",
        updatedAt: "2024-06-07T14:50:08.440Z",
      },
      {
        id: "clx4sz46l000012vclmesh0ut",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "6659c441e393609d8c62e3ad",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "6659c441e393609d8c62e3ad",
            lockId: "2GcarzeEtA",
            locked: 1717771794,
            timestamp: 1717771792,
          },
        ],
        createdAt: "2024-06-07T14:49:55.438Z",
        updatedAt: "2024-06-07T14:49:55.438Z",
      },
      {
        id: "clx4svhgp0001dqibag4njzrk",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PZD03G6036",
            locked: 1717500485,
            timestamp: 1717500482,
          },
        ],
        createdAt: "2024-06-07T14:47:06.026Z",
        updatedAt: "2024-06-07T14:47:06.026Z",
      },
      {
        id: "clx4sv6us0000dqib6k5ufsf4",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PZD03G6036",
            locked: 1717500485,
            timestamp: 1717500482,
          },
        ],
        createdAt: "2024-06-07T14:46:52.154Z",
        updatedAt: "2024-06-07T14:46:52.154Z",
      },
      {
        id: "clx4oe5sw00014b4rx8s9u7ea",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PZD03G6036",
            locked: 1717500485,
            timestamp: 1717500482,
          },
        ],
        createdAt: "2024-06-07T12:41:39.085Z",
        updatedAt: "2024-06-07T12:41:39.085Z",
      },
      {
        id: "clx4odc4j00004b4rnl2ywssa",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PZD03G6036",
            locked: 1717500485,
            timestamp: 1717500482,
          },
        ],
        createdAt: "2024-06-07T12:41:00.835Z",
        updatedAt: "2024-06-07T12:41:00.835Z",
      },
      {
        id: "clx4904jf0000mbschh32yc30",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PZD03G6036",
            locked: 1717500485,
            timestamp: 1717500482,
          },
        ],
        createdAt: "2024-06-07T05:30:50.043Z",
        updatedAt: "2024-06-07T05:30:50.043Z",
      },
      {
        id: "clx1r7x5l000012e7bku6ji7t",
        projectId: "6659c37af71f150dd1e07901",
        source: "codebase-snippet",
        snippetVersion: "1.0.0",
        user: {
          attributes: {
            url: "",
            user_agent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
            device_type: "desktop",
            visitor_type: "returning",
          },
          experimentsBucket: {
            "6659c441e393609d8c62e3ac": "665d9489640742db3fb162c7",
          },
        },
        events: [
          {
            type: "bucketing",
            value: "665d9489640742db3fb162c7",
            lockId: "PZD03G6036",
            locked: 1717500485,
            timestamp: 1717500482,
          },
        ],
        createdAt: "2024-06-05T11:37:28.473Z",
        updatedAt: "2024-06-05T11:37:28.473Z",
      },
    ],
    total: 3301,
    skip: 0,
    take: "all",
  },
};
