// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smart-container {
  max-width: 1920px;
  padding: 0px 30px;
  margin: 0px auto;
}

@media (max-width: 768px) {
  .smart-container {
    padding: 0px 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/MiroComponents/SmartContainer/SmartContainer.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AAEA;EACI;IACI,iBAAA;EACN;AACF","sourcesContent":[".smart-container{\n    max-width: 1920px;\n    padding: 0px 30px;\n    margin: 0px auto;\n}\n\n@media (max-width: 768px){\n    .smart-container{\n        padding: 0px 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
