import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import Select from "react-select";
import { CLIENTTESTSREPORTS, MYTESTS } from "../../../Graphql/Queries";
import MyLoader from "../../MiroComponents/PreLoader";
import { ClientEndedAndDraftTests } from "../../Shared/ClientEndedAndDraftTests";
import { ClientOverallResults } from "../../Shared/ClientOverallResults";
import { ModalWithTableData } from "../../Shared/ModalWithTableData";
export function ClientDashboard() {
  const location = useLocation();
  const [myProfile, is_client] = useOutletContext();
  const [testsBroughMonthlyRevenue, setTestsBroughMonthlyRevenue] =
    useState(null);
  const [client, setClient] = useState(null);
  const [clientProperties, setClientProperties] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [testData, setTestData] = useState([]);
  const [myTests, setMyTests] = useState([]);
  const [statusFilter, setStatusFilter] = useState("ended");
  const [loader, setLoader] = useState(false);
  const [clientTestReports] = useLazyQuery(CLIENTTESTSREPORTS, {
    variables: {
      viewId: selectedProperty?.value,
    },
  });
  const [myTestsQuery] = useLazyQuery(MYTESTS);

  useEffect(async () => {
    if (statusFilter !== "ended") {
      setLoader(true);
      (async () => {
        const { data } = await myTestsQuery({
          variables: {
            id: clientId,
            propertyId: selectedProperty?.value,
            testFilters: [{ status: statusFilter }],
            active: null,
            page: null,
            size: null,
          },
        });
        setMyTests(data?.mytests?.tests);
        setLoader(false);
      })();
    }
  }, [statusFilter]);

  useEffect(() => {
    if (location?.state?.clientId && location?.state.propertyId) {
      // const clients = data?.clientsWithTests?.clients;
      const c = myProfile?.client?.find(
        (clnt) => clnt?._id === location?.state?.clientId
      );
      const clientobj = JSON.stringify({
        _id: c?._id,
        tests: c?.tests,
        gaproperties: c?.gaproperties,
        currency: c?.currency,
      });
      setClient({
        label: c?.name,
        value: clientobj,
      });
      setClientId(c?._id);
      setClientProperties(c?.gaproperties);
      const _selectedProperty = c?.gaproperties?.find(
        (prp) => prp?.viewId === location?.state.propertyId
      );
      setSelectedProperty({
        label: _selectedProperty?.propertyname,
        value: _selectedProperty?.viewId,
      });
    }
  }, [myProfile]);
  const filters = [
    {
      label: "Reports / Ended Tests",
      value: "ended",
    },
    {
      label: "Draft / Upcoming Tests",
      value: "draft",
    },
    {
      label: "Tests Timeline",
      value: "timeline",
    },
  ];

  useEffect(() => {
    if (clientId && selectedProperty && statusFilter === "ended") {
      setLoader(true);
      (async () => {
        const { data } = await clientTestReports();
        setTestData(data?.clientTestReports);
        setLoader(false);
      })();
    }
  }, [clientId, selectedProperty, statusFilter]);

  const ClientSelector = () => {
    return (
      <div className="fields-wrapper">
        <label className="">Clients </label>
        <Select
          onChange={(client) => {
            setClient(client);
            setClientProperties(JSON.parse(client?.value)?.gaproperties);
            setClientId(JSON.parse(client?.value)?._id);
            setSelectedProperty(null);
            setTestData([]);
            setStatusFilter("ended");
          }}
          value={client}
          classNamePrefix="acc-select"
          className={client ? `active-select` : ``}
          placeholder="Select Store"
          options={myProfile?.client?.map((c) => {
            const clientobj = JSON.stringify({
              _id: c?._id,
              tests: c?.tests,
              gaproperties: c?.gaproperties,
              currency: c?.currency,
              logo: c?.logo,
            });
            return {
              label: c?.name,
              value: clientobj,
            };
          })}
        />
      </div>
    );
  };

  const PropertySelector = ({ clientProperties }) => {
    return (
      <div className="fields-wrapper">
        <label className="">Properties </label>
        <Select
          onChange={(propertyvalue) => {
            setSelectedProperty(propertyvalue);
          }}
          isDisabled={!client ? true : false}
          value={selectedProperty}
          classNamePrefix="acc-select"
          className={selectedProperty ? `active-select` : ``}
          placeholder="Select Property"
          options={clientProperties?.map((c, i) => {
            return {
              label: c?.propertyname,
              value: c?.viewId,
            };
          })}
        />
      </div>
    );
  };

  const columnstests = [
    {
      name: "Test Name",
      selector: (row) => row?.name,
      wrap: true,
    },
    {
      name: "Additional Revenue (per month)",
      cell: (row) => (
        <>
          {" "}
          {row?.additionalrevenuepermonth} (
          {JSON.parse(client?.value)?.currency})
        </>
      ),
    },
    {
      name: "Additional Revenue (per year)",
      cell: (row) => (
        <>
          {" "}
          {row?.additionalrevenueperyear} ({JSON.parse(client?.value)?.currency}
          )
        </>
      ),
    },
  ];

  if (!myProfile) return <MyLoader />;
  return (
    <>
      <div className="d-flex align-items-center fields-parent-50 justify-content-between">
        <ClientSelector />
        <PropertySelector clientProperties={clientProperties} />
      </div>
      {loader ? (
        <MyLoader />
      ) : (
        <>
          {Object.keys(testData).length > 0 && (
            <>
              <ClientOverallResults
                clientLogo={JSON.parse(client?.value)?.logo}
                clientTestReports={testData}
                setTestsBroughMonthlyRevenue={setTestsBroughMonthlyRevenue}
                currency={JSON.parse(client?.value)?.currency}
              />

              <ul
                className="nav nav-tabs client-dashboard-tabs"
                id="myTab"
                role="tablist"
              >
                {filters?.map((btn, i) => (
                  <li className="nav-item" role="presentation" key={i}>
                    <button
                      className={`nav-link ${
                        btn.value === statusFilter ? `active` : ``
                      }`}
                      id={`${btn?.value.toLowerCase()}-tab`}
                      type="button"
                      onClick={(_) => {
                        setLoader(true);
                        setTimeout(function () {
                          setStatusFilter(btn.value);
                        }, 200);
                      }}
                    >
                      {btn?.label}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="tab-content pt-2" id="myTabContent">
                <ClientEndedAndDraftTests
                  reports={testData?.report}
                  upcomingTest={myTests}
                  statusFilter={statusFilter}
                  client={client}
                />
              </div>
              {testsBroughMonthlyRevenue && (
                <ModalWithTableData
                  closeModal={setTestsBroughMonthlyRevenue}
                  data={testsBroughMonthlyRevenue}
                  columns={columnstests}
                  maxWidth={80}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
