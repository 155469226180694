// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  border-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Reset.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEE;EACE,SAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;EACA,aAAA;EACA,wBAAA;EACA,kCAAA;EACA,mCAAA;EACA,sBAAA;AACJ;;AAEE;EACE,cAAA;AACJ;;AAEE;EACE,cAAA;AACJ;;AAEE;EACE,gBAAA;AACJ;;AAEE;EACE,YAAA;AACJ;;AAEE;EACE,WAAA;EACA,aAAA;AACJ;;AAEE;EACE,yBAAA;EACA,iBAAA;AACJ;;AAEE;EAEE,gBAAA;AAAJ","sourcesContent":["*, *:before, *:after{\n    box-sizing: border-box;\n  }\n\n  html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{\n    margin: 0;\n    padding: 0;\n    border: 0;\n    font-size: 100%;\n    font: inherit;\n    vertical-align: baseline;\n    text-rendering: optimizeLegibility;\n    -webkit-font-smoothing: antialiased;\n    text-size-adjust: none;\n  }\n  \n  footer, header, nav, section, main{\n    display: block;\n  }\n  \n  body{\n    line-height: 1;\n  }\n  \n  ol, ul{\n    list-style: none;\n  }\n  \n  blockquote, q{\n    quotes: none;\n  }\n  \n  blockquote:before, blockquote:after, q:before, q:after{\n    content: '';\n    content: none;\n  }\n  \n  table{\n    border-collapse: collapse;\n    border-spacing: 0;\n  }\n\n  input{\n    // -webkit-appearance: none;\n    border-radius: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
