import { useQuery } from "@apollo/client";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { FEEDBACKS } from "../../../Graphql/Queries";
import MyLoader from "../../MiroComponents/PreLoader";
import { Pagination } from "../../Shared/Paginations";
import parse from "html-react-parser";

export const ClientFeedbacks = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [clientId, setClientId] = useState(null);
  const { data, loading, error } = useQuery(FEEDBACKS, {
    variables: {
      page,
      size,
      clientId,
    },
  });

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      wrap: true,
    },
    {
      name: "Client",
      cell: (row) => {
        return <div>{row?.client?.name}</div>;
      },
      wrap: true,
    },
    {
      name: "Details",
      cell: (row) => {
        return (
          <div className="p-2">
            {parse(row?.details)}
            {/* <button
              className="badge bg-dark border-0 text-white"
              onClick={(_) => {
                console.log(row);
              }}
            >
              Details
            </button> */}
          </div>
        );
      },
      right: true,
      wrap: true,
    },
  ];

  if (loading) return <MyLoader />;
  return (
    <div>
      <DataTable
        data={data?.feedbacks?.feedbacks}
        columns={columns}
        progressComponent={<MyLoader />}
        progressPending={loading}
      />
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        totalcount={data?.feedbacks?.total}
      />
    </div>
  );
};
