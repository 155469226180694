import { differenceInCalendarDays } from "date-fns";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ReportDetails } from "./ReportDetails";
import { Scrubber } from "./Scrubber";

export const ClientEndedAndDraftTests = ({
  reports,
  upcomingTest,
  statusFilter,
  client,
}) => {
  const [reportDetails, setReportDetails] = useState(null);
  const [scrubber, showScrubber] = useState(null);
  const [activeLocalFilter, setActiveLocalFilter] = useState("ALL");
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [scrubber]);
  const unsetDetails = () => {
    setReportDetails(null);
  };
  const columns = [
    {
      name: "Test Details",
      selector: (row) => row?.test?.name,
      wrap: true,
    },
    {
      name: "+/-",
      selector: (row) => {
        const { reportDetails } = row;
        return (
          <div>
            {reportDetails?.experimentResults.map(
              ({ controlvariant, uplift }, i) => {
                return (
                  <div
                    className={`mb-1 ${
                      uplift < 0 ? `text-dark` : `text-success`
                    }`}
                    key={i}
                  >
                    <span className="fw-light">{controlvariant}:</span> {uplift}
                  </div>
                );
              }
            )}
          </div>
        );
      },
      wrap: true,
    },
    {
      name: "Tested By",
      selector: (row) => row?.createdBy?.name,
      wrap: true,
    },
    {
      name: "Duration",
      // selector: (row) => row?.test?.name,
      cell: (row) => {
        const days =
          Number(
            differenceInCalendarDays(new Date(row?.to), new Date(row?.from))
          ) + 1;
        return <div>{days} Days</div>;
      },
      wrap: true,
    },
    {
      name: "Result",
      selector: (row) => row?.conclusion,
      cell: (row) => {
        return (
          <div>
            <span style={{ textTransform: "uppercase" }}>
              {row?.conclusion}
            </span>
          </div>
        );
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Details",
      cell: (row, i) => {
        return (
          <div>
            <button
              className={`btn btn-sm btn-dark ${
                i === 0 ? `ended-test-detail` : ``
              }`}
              onClick={(_) => setReportDetails(row)}
            >
              Details
            </button>
          </div>
        );
      },
      wrap: true,
    },
  ];

  const columnstests = [
    {
      name: "Test Name",
      selector: (row) => row?.name,
      wrap: true,
    },
    {
      name: "C vs V",
      cell: (row) => {
        return (
          <div>
            <button
              className="badge text-dark"
              disabled={
                !row?.controlimage && !row?.variationimage ? true : false
              }
              onClick={(_) => {
                showScrubber({
                  control: row.controlimage,
                  variation: row.variationimage,
                });
              }}
            >
              Compare
            </button>
          </div>
        );
      },
      wrap: true,
    },
    {
      name: "Details",
      cell: (row) => {
        return (
          <div>
            <button
              className="btn btn-sm btn-dark"
              onClick={(_) => setReportDetails(row)}
            >
              Details
            </button>
          </div>
        );
      },
      wrap: true,
    },
  ];
  const timeLineTests = useCallback(() => {
    if (statusFilter === "timeline") {
      return upcomingTest;
      // return upcomingTest
      //   .filter((test) => test.livedate)
      //   .sort(
      //     (a, b) =>
      //       Date.parse(new Date(a?.livedate)) -
      //       Date.parse(new Date(b?.livedate))
      //   );
    }
  }, [statusFilter, upcomingTest]);

  // const conditionalRowStyles = [
  //   {
  //     when: (row) => testsBroughtMonthlyRevenue.includes(row?.test?._id),
  //     style: {
  //       backgroundColor: "#f1f1f1",
  //     },
  //   },
  // ];

  const localFilters = ["ALL", "Winner", "Neutral", "No Uplift"];
  return (
    <>
      {statusFilter === "ended" && (
        <>
          <div className="d-flex justify-content-end">
            {localFilters?.map((rle, i) => (
              <button
                className={`btn me-1 btn-sm ${
                  activeLocalFilter === rle
                    ? `bg-primary text-white`
                    : `bg-light`
                }`}
                key={i}
                onClick={(e) => {
                  setActiveLocalFilter(rle);
                }}
              >
                {rle}
              </button>
            ))}
          </div>
          <DataTable
            columns={columns}
            data={
              activeLocalFilter !== "ALL"
                ? reports.filter(
                    (rps) => rps?.conclusion === activeLocalFilter.toLowerCase()
                  )
                : reports
            }
            // conditionalRowStyles={conditionalRowStyles}
          />
        </>
      )}
      {statusFilter === "draft" && (
        <DataTable
          columns={columnstests}
          data={upcomingTest}
          // conditionalRowStyles={conditionalRowStyles}
        />
      )}
      {statusFilter === "timeline" && (
        <section>
          <div className="container py-5">
            <div className="main-timeline-2">
              {timeLineTests()?.map((timeline, i) => (
                <div className="timeline-2" key={i}>
                  <div className="card">
                    <div className="card-body p-4">
                      <h5 className="fw-bold mb-2">
                        {timeline?.name}
                        <span
                          className={`fw-normal ${
                            timeline?.status === "ended"
                              ? "text-danger"
                              : "text-primary"
                          }`}
                          style={{ fontSize: "12px" }}
                        >
                          ({timeline.status})
                        </span>
                      </h5>
                      {timeline?.livedate ? (
                        <p className="mb-1">
                          Live Date:{" "}
                          <span className="text-muted fw-bold">
                            {moment(timeline?.livedate).format("ll")}
                          </span>{" "}
                        </p>
                      ) : (
                        <p className="text-muted mb-2">
                          Live Date not set for test.
                        </p>
                      )}

                      {timeline?.report && (
                        <>
                          <p className="mb-2">
                            End Date:{" "}
                            <span className="text-muted fw-bold">
                              {moment(timeline?.report?.to).format("ll")}
                            </span>{" "}
                          </p>
                          <p className="mb-2">
                            Conclusion:{" "}
                            <span className="text-muted fw-bold">
                              {timeline.report?.conclusion} (
                              {timeline.report?.segmentName})
                            </span>
                          </p>
                        </>
                      )}
                      {timeline?.report ? (
                        <button
                          className="badge text-white bg-success"
                          onClick={(_) => {
                            const reportDetails = {
                              reportDetails: timeline?.report?.reportDetails,
                              segmentName: timeline?.report?.segmentName,
                              from: timeline?.report?.from,
                              to: timeline?.report?.to,
                              test: {
                                hypothesis: timeline?.hypothesis?._id,
                                property: timeline?.property,
                                name: timeline?.name,
                                urltargeting: [],
                                controlimage: timeline?.controlimage,
                                variationimage: timeline?.variationimage,
                              },
                            };
                            setReportDetails(reportDetails);
                          }}
                        >
                          Report Details
                        </button>
                      ) : (
                        <button
                          className="badge text-dark"
                          disabled={
                            !timeline?.controlimage && !timeline?.variationimage
                              ? true
                              : false
                          }
                          onClick={(_) => {
                            showScrubber({
                              control: timeline.controlimage,
                              variation: timeline.variationimage,
                            });
                          }}
                        >
                          Control vs Variant
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {scrubber && <Scrubber scrubber={scrubber} showScrubber={showScrubber} />}
      {reportDetails && (
        <ReportDetails
          unsetDetails={unsetDetails}
          reportDetails={reportDetails}
          client={client}
        />
      )}
    </>
  );
};
