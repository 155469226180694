export const ClientOverallResults = ({
  clientTestReports,
  currency,
  clientLogo,
  setTestsBroughMonthlyRevenue,
}) => {
  const {
    endedTest,
    liveTest,
    draftTest,
    successRate,
    averageUpliftSuccessfullTests,
    monthlyRevenue,
    yearlyRevenue,
    testsBroughRevenue,
  } = clientTestReports?.statistics;

  // const liveTests = clientTestReports?.statistics?.liveTest;
  // const draftTests = clientTestReports?.statistics?.draftTest;
  // const successRate = clientTestReports?.statistics?.successRate;
  // const averageUpliftSuccessfullTests =
  //   clientTestReports?.statistics?.averageUpliftSuccessfullTests;

  return (
    <>
      <div className="client-overall-results-wrapper shadow-1 bg-light">
        <div className="client-overall-results-inner">
          {clientLogo && (
            <img
              src={clientLogo}
              className="ms-auto me-auto"
              alt="client logo"
            />
          )}

          <div className="overall-box">
            <div className="left">
              <div className="suc-rate">
                <p> Success Rate</p>
                <p className="numb">{successRate} %</p>
                <p>
                  of all tests were successful and adviced to be implemented
                </p>
              </div>
            </div>
            <div className="Right">
              <h4> Overall Results</h4>

              <div className="our-stats">
                <div>
                  <p> Ended Tests</p>
                  <span>{endedTest}</span>
                </div>
                <div>
                  <p> Live Tests</p>
                  <span>{liveTest}</span>
                </div>
                <div>
                  <p> Draft Tests</p>
                  <span>{draftTest}</span>
                </div>
                <div>
                  <p> Average Uplift on conv. rate of successful tests</p>
                  <span>{averageUpliftSuccessfullTests} %</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="client-revenue-wrapper">
        <div className="client-revenue-inner">
          <div
            className="revenue-box"
            onClick={(_) => {
              setTestsBroughMonthlyRevenue(testsBroughRevenue);
            }}
          >
            <label>
              Revenue generated <br />
              <span> (monthly)</span>
            </label>
            <h3>
              {Number(monthlyRevenue).toLocaleString()}

              <span className="text-dark" style={{ fontSize: "80%" }}>
                &nbsp; ({currency})
              </span>
            </h3>
          </div>
          <div className="revenue-box">
            <label>
              Revenue generated <br />
              <span> (yearly)</span>
            </label>
            <h3>
              {Number(yearlyRevenue).toLocaleString()}
              <span className="text-dark" style={{ fontSize: "80%" }}>
                &nbsp;({currency})
              </span>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};
