import { LiveTestsReporting } from "../LiveTestsReporting";

function ClientTest() {
  return (
    <div>
      <LiveTestsReporting />
    </div>
  );
}

export default ClientTest;
